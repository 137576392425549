import React, { useEffect, useLayoutEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory, NavLink,  withRouter } from 'react-router-dom';
import { MDBCardImage, MDBIcon } from 'mdbreact';

import $ from 'jquery';

import { setBreadcrumb } from '../../redux/action';
import Logo from '../../styles/images/bg/logo_white.png';

const Menu = React.memo(function Menu(props) {
  const history = useHistory();
  const [data, setData] = React.useState([]);

  useLayoutEffect(()=>{
    if(data){
      if(props.location.pathname === '/my-profile'){
        handleChangeMenu({ _id: 1, name: window.I18N('my_profile'), nameWithMultiLang: { en: '' , vi: ''}})
      }else{
        initialBreadcrumb(data)
      }
    }
  },[props.location.pathname, props.language.code])

  useLayoutEffect(() => {
    window.addEventListener('resize', updateSlideNav);
    setTimeout(() => {
      initDataMenu();
    }, 100);
    return () => {
      window.removeEventListener('resize', updateSlideNav);
    };
    // eslint-disable-next-line
  }, []);

  const initMenu = () => {
    $('.collapsible-header').on('click', function () {
      $('#slide-out').find('.collapsible-body[data-toggle="toggled"]').slideToggle();
      $('#slide-out').find('.collapsible-header').removeClass('active');
      if ($(this).parent().find('.collapsible-body').attr('data-toggle') === undefined || $(this).parent().find('.collapsible-body').attr('data-toggle') === '') {
        $('#slide-out').find('.collapsible-body').attr('data-toggle', '');
        $(this).parent().find('.collapsible-body').slideToggle();
        $(this).parent().find('.collapsible-body').attr('data-toggle', 'toggled');
        $(this).addClass('active');
      } else {
        $(this).parent().find('.collapsible-body').attr('data-toggle', '');
      }
    });
            
    $('.button-collapse').on('click', function () {
      $('#slide-out').css({
        transform: 'translateX(0)'
      });
      $('body').append('<div id="sidenav-overlay"></div>');
      $('#sidenav-overlay').on('click', function () {
        $('#slide-out').css({
          transform: 'translateX(-105%)'
        });
        $('#sidenav-overlay').remove();
      });
    });
        
    if ($('#slide-out').find('.active').attr('data-level') === '2') {
      $('#slide-out').find('.active').parent().parent().parent().parent().find('.collapsible-header').trigger('click');
    }
  };

  const updateSlideNav = () => {
    if ($('.button-collapse').is(':visible')) {
      $('#slide-out').css({
        transform: 'translateX(-105%)'
      });
    } else {
      $('#sidenav-overlay').remove();
      $('#slide-out').css({
        transform: 'translateX(0)'
      });
    }
  };

  const initDataMenu = () => {
    let data = localStorage.getItem(window.CONSTANT.DATA_ROLE);
    if (data) {
      data = JSON.parse(data);
      if(props.location.pathname === '/my-profile'){
        handleChangeMenu({ _id: 1, name: window.I18N('my_profile'), nameWithMultiLang: { en: '' , vi: ''}})
      }else{
        initialBreadcrumb(data);
      }
      setData([...data]);
      initMenu();
    }
  };

  const getParentNameAndNameMenu = (item) => {
    const parentName = item.parent ? handleMenuNameWithMultiLang(item.parent) : ''
    const name = handleMenuNameWithMultiLang(item)

    return { parentName, name }
  }

  const initialBreadcrumb = (data) => {
    const action = history.location && history.location.pathname.replace(/\//g, '');
    let item = null;
    for (let i = 0; i < data.length; i++) {
      const element = data[i];
      if (element.action === action) {
        item = element;
        break;
      }
      for (let j = 0; j < element.children.length; j++) {
        const child = element.children[j];
        if (child.action === action) {
          item = child;
          break;
        }
      }
    }
    if(!window.COMMON.isEmpty(item)){
      const { name: displayName, parentName } = getParentNameAndNameMenu(item)
      props.setBreadcrumb({...item, name: displayName, parent: item.parent ? {...item.parent, name: parentName} : null});
    } else{
      props.setBreadcrumb(item);
    }
  };

  const handleChangeMenu = (item) => {
    const { name: displayName, parentName } = getParentNameAndNameMenu(item)
    props.setBreadcrumb({...item, name: displayName, parent: item.parent ? parentName : null});
  };

  const validLangCode = () => {
    const DEFAULT_LANG_CODE = 'en'
    const langCode = props.language?.code?.toLowerCase()
    const currentLangCodes = ['en', 'vi']
    if(!currentLangCodes.includes(langCode)){
      return DEFAULT_LANG_CODE
    }else{
      return langCode
    }
  }

  const handleMenuNameWithMultiLang = (item) => {
    const langCode = validLangCode()
    const { name, nameWithMultiLang: { en: enName, vi: viName } } = item
    const displayName = {
      en: window.COMMON.isEmpty(enName) ? name : enName,
      vi: window.COMMON.isEmpty(viName) ? name : viName
    }
    return displayName[langCode]
  }

  const generateMenu = (item, index) => {
    const displayName = handleMenuNameWithMultiLang(item)
    return <li key={index}>
      {
        (item.action && item.action !== '#' && item.action !== '/') ? <NavLink exact={true} to={item.action} className="collapsible-header waves-effect arrow-r" data-level="1" activeClassName="active" onClick={() => handleChangeMenu(item)}>
          <MDBIcon fa="true" icon={item.icon} className="fa-fw fa-lg mr-2"></MDBIcon> 
          <span className="sv-normal is-mini">{displayName}</span>
          {item.children.length > 0 ? <i className="fas fa-fw fa-angle-down rotate-icon is-mini"></i> : null}
        </NavLink> : <NavLink exact={true} to={item.action} className="collapsible-header waves-effect arrow-r" data-level="1" activeClassName="">
          <MDBIcon fa="true" icon={item.icon} className="fa-fw fa-lg mr-2"></MDBIcon> 
          <span className="sv-normal is-mini">{displayName}</span>
          {item.children.length > 0 ? <i className="fas fa-fw fa-angle-down rotate-icon is-mini"></i> : null}
        </NavLink>
      }
      { item.children.length > 0 ? 
        <div className="collapsible-body">
          <ul>
            {item.children.map((child, i) => generateSubMenu(child, i))}
          </ul>
        </div> : null
      }
    </li>;
  };

  const generateSubMenu = (item, index) => {
    const displayName = handleMenuNameWithMultiLang(item)
    return <li key={index}>
      {
        item.action.indexOf('http') > -1 || item.action.indexOf('https') > -1 ? 
          <NavLink exact={true} to={item.action} className="waves-effect" activeClassName="active" data-level="2" target="_blank" onClick={(event) => {event.preventDefault(); window.open(item.action + '?email=' + localStorage.getItem(window.CONSTANT.EMAIL));}}>
            <MDBIcon fa="true" icon={item.icon} className="fa-fw fa-lg mr-2"></MDBIcon> 
            <span className="sv-normal is-mini">{displayName}</span>
          </NavLink> : 
          <NavLink exact={true} to={item.action} className="waves-effect" activeClassName="active" data-level="2" onClick={() => handleChangeMenu(item)}>
            <MDBIcon fa="true" icon={item.icon} className="fa-fw fa-lg mr-2"></MDBIcon> 
            <span className="sv-normal is-mini">{displayName}</span>
          </NavLink>
      }
    </li>;
  };

  if (typeof window === 'undefined') {
    return null;
  }
  return (
    <>
      <div id="slide-out" className="side-nav fixed wide sn-bg-4 wow fadeIn animated" data-wow-delay="0.5s">
        <ul className="custom-scrollbar">
          <li>
            <div className="logo-wrapper sn-ad-avatar-wrapper">
              <NavLink exact={true} to="/dashboard" className="waves-effect">
                <img src={Logo} alt="" />
                <span className="is-mini">GOPY</span>
              </NavLink>
            </div>
          </li>
          <li>
            <hr className="horizontal light mt-0"></hr>
          </li>
          <li>
            <ul className="collapsible collapsible-accordion mt-0">
              <li>
                <NavLink exact={true} to={'#'} className="collapsible-header waves-effect arrow-r" data-level="1" activeClassName="">
                  <MDBCardImage fade="true" cascade waves src={window.COMMON.setValueImage(localStorage.getItem(window.CONSTANT.AVATAR))} className="icon-avatar m-0 mr-2"/>
                  <span className="sv-normal is-mini">{localStorage.getItem(window.CONSTANT.FULL_NAME)}</span>
                  <i className="fas fa-fw fa-angle-down rotate-icon is-mini"></i>
                </NavLink>
                <div className="collapsible-body">
                  <ul>
                    {/* <li>
                      <NavLink exact={true} to={'#'} className="waves-effect" activeClassName="" data-level="2">
                        <i className="fas fa-fw fa-user-circle"></i> My Profile
                      </NavLink>
                    </li> */}
                    <li>
                      <NavLink exact={true} to="my-profile" className="collapsible-header waves-effect arrow-r" data-level="1" activeClassName="active" onClick={() => handleChangeMenu({ _id: 1, name: window.I18N('my_profile'), nameWithMultiLang: { en: '', vi: '' } })}>
                        <MDBIcon fa="true" icon="user-circle" className="fa-fw fa-lg mr-2"></MDBIcon> 
                        <span className="sv-normal is-mini">{window.I18N('my_profile')}</span>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink exact={true} to={'#'} className="waves-effect" activeClassName="" data-level="2" onClick={window.changePassword}>
                        <i className="fas fa-fw fa-key"></i> {window.I18N('change_password')}
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </li>
          <li>
            <hr className="horizontal light"></hr>
          </li>
          <li>
            <ul className="collapsible collapsible-accordion mt-0">
              {data.map((item, i) => generateMenu(item, i))}
            </ul>
          </li>
        </ul>
        <div className="sidenav-bg rgba-blue-strong"></div>
        <div className="text-center menu-footer is-mini">
          <hr className="horizontal light mb-0"></hr>
          <div className="footer-copyright text-center py-3">© 2019 - {new Date().getFullYear()} Copyright by
            <a href="https://www.google.com/"> CSR TECH JSC </a>
          </div>
        </div>
      </div>
    </>
  );
});

const mapStateToProps = state => ({
  language: state.language.value,
  breadcrumb: state.breadcrumb.value
});
const mapDispatchToProps = { setBreadcrumb };

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Menu));