import React, { useState, useCallback, useLayoutEffect, useEffect } from 'react';
import { MDBRow, MDBCol, MDBInputGroup, MDBCardImage, MDBBtn, MDBIcon } from 'mdbreact';

import Panel from '../../components/Panel';
import RadioBox from '../../components/RadioBox';
import TableCustomize from '../../components/TableCustomize';
import Pagination from '../../components/Pagination';

import FanpageGRAPHQL from '../../../graphql/Fanpage';
import { useTracking } from 'react-tracking';
import eventName from '../../../common/events';

export default function FanpagePage(props) {
  const types = [
    { value: 'PUBLIC', label: window.I18N('public_groups'), icon: 'globe-asia' },
    { value: 'PRIVATE', label: window.I18N('private_groups'), icon: 'lock' }
  ];
  const config = [
    window.COMMON.createCol(window.I18N('no'), '5%', '', '', '', 'INDEX'),
    window.COMMON.createCol(window.I18N('name'), '35%', 'p-0 pl-1', '', 'image,name', 'IMAGE_TEXT'),
    window.COMMON.createCol(window.I18N('owner'), '20%', '', '', 'createdBy.avatar,createdBy.nickname', 'IMAGE_TEXT'),
    window.COMMON.createCol(window.I18N('number_of_members'), '10%', '', '', 'countMember', 'TEXT'),
    window.COMMON.createCol(window.I18N('status'), '10%', '', '', 'active', 'STATUS'),
    window.COMMON.createCol(window.I18N('created_date'), '10%', '', '', 'createdDate', 'TEXT'),
    window.COMMON.createCol(window.I18N('action'), '10%', '', '', '0', 'BUTTON')
  ];

  const [filter, setFilter] = useState({radio: 'PUBLIC', text: ''});
  const [pagination, setPagination] = useState({ total: 0, pageSize: 10, currentPage: 0 });
  const [object, setObject] = useState({ index: -1, item: null });
  const [data, setData] = useState([]);
  const [dataMember, setDataMember] = useState([]);

  const { trackEvent } = useTracking();

  useLayoutEffect(() => {
    setTimeout(() => {
      getDataFanpage();
    }, 100);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  },[filter.text])

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      doSearchData();
    }
  }

  // create tracking event
  const createTrackingEvent = (event) => {
    return trackEvent({
      name: event,
      createdAt: new Date().toISOString()
    });
  };

  const handleFilterChange = (event) => {
    const { value } = event.target
    if(!window.COMMON.validateTextInput(value)){
      return
    }
    setFilter((prev) => ({ ...prev, text: value }));
  };

  const handleRadio = useCallback((value) => {
    filter.radio = value;
    setFilter({ ...filter });
    doSearchData();
    // eslint-disable-next-line
  }, [filter]);

  const getDataFanpage = async () => {
    try {
      const params = {
        type: filter.radio,
        text: filter.text.trim(),
        pageSize: pagination.pageSize,
        page: pagination.currentPage + 1
      };
      const result = await window.COMMON.query(FanpageGRAPHQL.QUERY_FANPAGE, params);
      let data = [];
      if (result && result.data && result.data.getFanpages) {
        data = window.COMMON.formatDate(result.data.getFanpages.data, 'createdDate'); 
        pagination.total = result.data.getFanpages.total;
      }
      setPagination(pagination);
      setData(data);
    } catch (error) {
      window.COMMON.showErrorLogs('FanpagePage.getDataFanpage');
    }
  };

  const getDataMembersInFanpage = async (id) => {
    try {
      const params = {
        id,
        pageSize: 9999,
        page: 1
      };
      const result = await window.COMMON.query(FanpageGRAPHQL.QUERY_MEMBER_IN_FANPAGE, params);
      let data = [];
      if (result && result.data && result.data.findMembersInFanpage) {
        data = window.COMMON.formatDate(result.data.findMembersInFanpage.data, 'createdDate'); 
      }
      setDataMember(data);
    } catch (error) {
      window.COMMON.showErrorLogs('FanpagePage.getDataMembersInFanpage');
    }
  };

  const changePage = useCallback((index) => {
    pagination.currentPage = index;
    setPagination({ ...pagination });
    getDataFanpage();
    // eslint-disable-next-line
  }, [object, pagination, filter]);

  const doSearchData = () => {
    pagination.currentPage = 0;
    window.COMMON.hideFilter();
    getDataFanpage();
  };

  const showContainerFanpage = useCallback(async (index) => {
    window.COMMON.showContainerSave();
    object.index = index;
    object.item = data[index];
    getDataMembersInFanpage(object.item._id);
    setObject({ ...object });
    // eslint-disable-next-line
  }, [object, data]);

  const updateStatus = async () => {
    try {
      const params = {
        id: data[object.index]._id,
        active: !data[object.index].active
      };
      const result = await window.COMMON.mutation(FanpageGRAPHQL.MUTATION_UPDATE_STATUS_FANPAGE, params);
      if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_STATUS)) {
        window.COMMON.backContainerData();
        getDataFanpage();
        createTrackingEvent(eventName.UPDATE_STATUS_FANPAGE);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('FanpagePage.updateStatus');
    }
  };

  if (typeof window === 'undefined') {
    return null;
  }
  return (
    <div className="container-row">
      <Panel id="container-data" icon="list-alt">
        <div className="container-top d-flex align-items-center justify-content-end wow fadeInDown animated" data-wow-delay="0.5s" style={{ zIndex: 1 }}>
          <div className="d-inline-block ml-auto mr-2">
            <RadioBox data={types} value={filter.radio} className="m-0" handleChange={handleRadio}></RadioBox>
          </div>
          <div style={{ width: '350px' }}>
            <MDBInputGroup type="text" value={filter.text} hint={window.I18N('search_name')} onChange={handleFilterChange} maxLength="100" pattern="\S(.*\S)?" required
              append={
                <span className="input-group-text cursor-pointer" onClick={() => doSearchData()}>
                  <MDBIcon fa="true" icon="search" className="fa-fw"></MDBIcon>
                </span>
              }
            />
          </div>
        </div>
        <div className="container-table wow fadeIn animated" data-wow-delay="1s">
          <TableCustomize id="table-data" className="table" config={config} data={data} handleRowDetailClick={showContainerFanpage}></TableCustomize>
          <Pagination total={pagination.total} pageSize={pagination.pageSize} currentPage={pagination.currentPage} changePage={changePage}></Pagination>
        </div>
      </Panel>

      <Panel id="container-save" title={window.I18N('view_details')} icon="file" style={{ display: 'none' }}>
        <div className="container-btn wow fadeInDown animated" data-wow-delay="0.5s">
          <MDBBtn color="" onClick={window.COMMON.backContainerData}>
            <MDBIcon fa="true" icon="arrow-left" className="fa-fw" /> {window.I18N('btn_back')}
          </MDBBtn>
          {
            object.item && object.item.active ? <MDBBtn color="danger" type="button" data-toggle="modal" data-backdrop="static" data-target="#modal-save" onClick={() => { window.saveMethod = updateStatus; }}>
              <MDBIcon fa="true" icon="ban" className="fa-fw" /> {window.I18N('btn_inactive')}
            </MDBBtn> : <MDBBtn color="success" type="button" data-toggle="modal" data-backdrop="static" data-target="#modal-save" onClick={() => { window.saveMethod = updateStatus; }}>
              <MDBIcon fa="true" icon="check" className="fa-fw" /> {window.I18N('btn_active')}
            </MDBBtn>
          }
        </div>

        <div className="container-top wow fadeInUp animated" data-wow-delay="0.5s">
          <form id="form" className="needs-validation" noValidate>
            <MDBRow>
              <MDBCol xs="12" md="6">
                <h5 className="font-weight-bold mb-2">{window.I18N('general_information')}</h5>
                <div className="d-flex align-items-start">
                  <MDBCardImage cascade waves src={window.COMMON.getValueFromAttr(object.item, 'image')} style={{ width: '150px' }} className="mr-2"/>
                  <div className="d-flex flex-column">
                    <p className="font-weight-bold mb-1">{ window.COMMON.getValueFromAttr(object.item, 'name') }</p>
                    <pre className="mb-0" dangerouslySetInnerHTML={{__html: window.COMMON.getValueFromAttr(object.item, 'description')}}></pre>
                    <p className="mb-1 text-muted">
                      { window.I18N('created_date') + ': ' + window.COMMON.getValueFromAttr(object.item, 'createdDate') + ' · '}
                      <span className="font-weight-bold">{ window.COMMON.getValueFromAttr(object.item, 'countMember', 0) }</span>
                      { ' ' + window.I18N('members').toLowerCase() }
                    </p>
                    <div>
                      <MDBIcon fa="true" icon={ window.COMMON.getValueFromAttr(object.item, 'type', 'PUBLIC') === 'PUBLIC' ? 'globe-asia' : 'lock' } className="fa-fw mr-2"></MDBIcon>
                      <span>{window.I18N(window.COMMON.getValueFromAttr(object.item, 'type', 'PUBLIC') === 'PUBLIC' ? 'public_groups' : 'private_groups')}</span>
                    </div>
                  </div>
                </div>
              </MDBCol>
              <MDBCol xs="12" md="3">
                <h5 className="font-weight-bold mb-2">{window.I18N('fanpage_policy')}</h5>
                {
                  window.COMMON.getValueFromAttr(object.item, 'fields', []).map((item, i) => 
                    <div className="list-group-item list-group-item-action p-2" key={i}>
                      <MDBIcon fa="true" icon="check-circle" className="fa-fw mr-2"></MDBIcon>
                      <span>{window.COMMON.getValueFromAttr(item, 'value', '')}</span>
                    </div>
                  )
                }
              </MDBCol>
              <MDBCol xs='12' md='3'>
                <h5 className="font-weight-bold mb-2">{window.I18N('members')}</h5>
                {
                  dataMember.map((item, i) => 
                    <div className="list-group-item list-group-item-action p-2" key={i}>
                      <div className="media">
                        <MDBCardImage zoom cascade waves src={window.COMMON.setValueImage(window.COMMON.getValueFromAttr(item, 'user.avatar'))} className="image-icon rounded-circle mr-2"/>
                        <div className="media-body media-middle">
                          <p className="mb-0 font-weight-bold">{window.COMMON.getValueFromAttr(item, 'user.nickname')}</p>
                          <p className="mb-0 text-muted">{window.I18N('joined_from') + ' ' + window.COMMON.getValueFromAttr(item, 'createdDate') + ' · '} <span className="font-weight-bold">{window.COMMON.getValueFromAttr(item, 'role') === window.CONSTANT.EVENT_ROLE.OWNER ? window.I18N('owner') : window.I18N('members')}</span></p>
                        </div>
                      </div>
                    </div>
                  )
                }
              </MDBCol>
            </MDBRow>
          </form>
        </div>
      </Panel>
    </div>
  );
}