import React, { useState, useCallback, useLayoutEffect, useRef, useImperativeHandle, useEffect, useMemo } from 'react';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import { MDBRow, MDBCol, MDBInput, MDBIcon } from 'mdbreact';

import RadioBox from '../../components/RadioBox';
import TaskScheduleGRAPHQL from '../../../graphql/TaskSchedule';

const TIME_IN_PAST = -1


const SurveySchedule = React.forwardRef((props, ref) => {
  const { groupQA } = props;

  const DEFAULT_ALL_COMPANY = useMemo(() => {
    return {
      value: [],
      label: window.I18N('all_companies'),
      code: 'DEFAULT'
    }
  },[])
  
  const DEFAULT_ALL_ACCOUNT_TYPES = useMemo(() => {
    return { 
      code: '',
      finalConclusion: '',
      image: '',
      label: window.I18N('all_account_types'),
      type: '',
      value: []
    }
  },[])

  const DEFAULT_ALL_NATION = useMemo(() => {
    return { value: null, label: window.I18N('all_countries') }
  },[])

  const COMPANY_TYPE = localStorage.getItem(window.CONSTANT.COMPANY_TYPE);

  const typeOptions = [
    { value: 'ONE', label: window.I18N('one_time') },
    { value: 'WEEKLY', label: window.I18N('weekly') },
    { value: 'MONTHLY', label: window.I18N('monthly') },
    { value: 'YEARLY', label: window.I18N('yearly') }
  ];

  const genderOptions = [
    { value: -1, label: window.I18N('all_gender') },
    { value: 0, label: window.I18N('female') },
    { value: 1, label: window.I18N('male') }
  ];

  const maritalStatusOptions = [
    { value: -1, label: window.I18N('all_marital_status') },
    { value: 0, label: window.I18N('single') },
    { value: 1, label: window.I18N('married') }
  ];

  const daysInWeekOptions = [
    { value: 0, label: window.I18N('sun') },
    { value: 1, label: window.I18N('mon') },
    { value: 2, label: window.I18N('tue') },
    { value: 3, label: window.I18N('wed') },
    { value: 4, label: window.I18N('thu') },
    { value: 5, label: window.I18N('fri') },
    { value: 6, label: window.I18N('sat') }
  ];

  const monthsInYearOptions = [
    { value: 1, label: window.I18N('jan') },
    { value: 2, label: window.I18N('feb') },
    { value: 3, label: window.I18N('mar') },
    { value: 4, label: window.I18N('apr') },
    { value: 5, label: window.I18N('may') },
    { value: 6, label: window.I18N('jun') },
    { value: 7, label: window.I18N('jul') },
    { value: 8, label: window.I18N('aug') },
    { value: 9, label: window.I18N('sep') },
    { value: 10, label: window.I18N('oct') },
    { value: 11, label: window.I18N('nov') },
    { value: 12, label: window.I18N('dec') }
  ];

  const daysInMonthOptions = [];
  for (let i = 0; i < 31; i++) {
    daysInMonthOptions.push({ value: i + 1, label: i + 1 });
  }

  const types = [
    { value: 'COMPANY', label: window.I18N('companies'), icon: 'building' },
    /* { value: 'LOCATION', label: window.I18N('location'), icon: 'map-marker' }, */
    { value: 'GROUP_CHAT', label: window.I18N('group_chat'), icon: 'user-friends' }
  ];
  const [object, setObject] = useState({ index: -1, status: 1, isShowCompany: false, radio: 'COMPANY' });
  const [dataSave, setDataSave] = useState({});
  const [date, setDate] = useState({ startDate: new Date(), endDate: window.COMMON_DATE.addDaysFromNow(30) });
  const [select, setSelect] = useState({ type: { value: 'ONE', label: window.I18N('one_time') } });
  const [options, setOptions] = useState({
    nation: [],
    city: [],
    state: [],
    companies: []
  });
  const [isInitData, setIsInitData] = useState(false);

  const formRef = useRef();

  const handleChange = (event) => {
    dataSave[event.target.name] = event.target.value;
    setDataSave({ ...dataSave });
  };

  useImperativeHandle(ref, () => ({
    handleValidForm,
    handleSaveForm,
    getSurveyScheduleFormData
  }));



  //dataSave
  //date
  //select
  //options
  //object
  const getSurveyScheduleFormData = () => {
    return {
      dataSave,
      date,
      select,
      options,
      object
    };
  };

  const removeSelectAllData = (data) => {
    const index = data.findIndex(item => window.COMMON.isEmpty(item.value))
    if(index !== -1 && index === 0){
      data.splice(index, 1)
    }
    if(index > 0 && window.COMMON.isEmpty(data[data.length - 1].value)){
      data.splice(0, index)
      data.splice(index, data.length - 1)
    }
    return data
  }

  const handleSelect = (event, id, attr) => {
    window.COMMON.checkSelectValidation(id, event);
    if(Array.isArray(event) && event.length > 1 && event.some(item => !window.COMMON.isEmpty(item.value))){
      removeSelectAllData(event)
    }
    select[attr] = event;
    if (attr === 'nation' || attr === 'state' || attr === 'city' || attr === 'companyIndustry') {
      filterCompanyFollowSelect()
    }
    if (attr === 'type') {
      select.days = [];
      select.months = [];
      setSelect({ ...select });
    } else if (attr === 'nation' || attr === 'state' || attr === 'city') {
      getDataLocationOptions(attr, event.code);
    } else {
      setSelect({ ...select });
    }
  };

  const filterCompanyFollowSelect = () => {
    let newCompanies = []
    if (!window.COMMON.checkRoleIsSystem()) {
      newCompanies = props.companiesFullData.filter(com => {
        if (select.companyIndustry?.value && com.companyIndustry._id !== select.companyIndustry?.value) {
          return false
        }
        if (select.nation?.value && com.nation._id !== select.nation.value) {
          return false
        }
        if (select.state?.value && com.state._id !== select.state.value) {
          return false
        }
        if (select.city?.value && com.city._id !== select.city.value) {
          return false
        }
        return true
      })
    } else {
      newCompanies = props.companiesFullData?.filter(com => {
        if (select.companyIndustry?.value && com.information.companyIndustry?._id !== select.companyIndustry?.value) {
          return false
        }
        if (select.nation?.value && com.information.nation._id !== select.nation.value) {
          return false
        }
        if (select.state?.value && com.information.state._id !== select.state.value) {
          return false
        }
        if (select.city?.value && com.information.city._id !== select.city.value) {
          return false
        }
        return true
      })
    }
      if (!window.COMMON.checkRoleIsSystem()) {     
        options.companies = window.COMMON.createDataSelectWithGroup(newCompanies, 'id', 'name', 'accountType', 'accountType')
      } else {
        options.companies = window.COMMON.createDataSelectWithGroup(newCompanies, '_id', 'information.name', 'type', 'type')
      }
      setOptions({...options})
    }

  const handleDatePicker = (event, attr) => {
    date[attr] = event;
    setDate({ ...date });
  };

  const handleRadio = useCallback((value) => {
    object.radio = value;
    select.companies = DEFAULT_ALL_COMPANY
    select.accountTypes = DEFAULT_ALL_ACCOUNT_TYPES
    select.groupChats = [];
    select.companyIndustry = null;
    select.gender = genderOptions[0];
    select.maritalStatus = maritalStatusOptions[0];
    select.age = props.options.age && props.options.age.length > 0 ? props.options.age[0] : null;
    select.occupation = props.options.occupation && props.options.occupation.length > 0 ? props.options.occupation[0] : null;
    select.educationBackground = props.options.educationBackground && props.options.educationBackground.length > 0 ? props.options.educationBackground[0] : null;
    select.income = props.options.income && props.options.income.length > 0 ? props.options.income[0] : null;
    select.nation = options.nation[0]
    select.state = { value: null, label: window.I18N('all_states') }
    select.city = { value: null, label: window.I18N('all_cities') }
    options.state = [];
    options.city = [];
    if (value === 'COMPANY') {
      select.companyIndustry = props.options.companyIndustry && props.options.companyIndustry.length > 0 ? props.options.companyIndustry[0] : null;
    }
    filterCompanyFollowSelect()
    setSelect(select);
    setOptions(options);
    setObject({ ...object });
  }, [props.options, select, options, object, genderOptions, maritalStatusOptions]);

  // LOCATION
  const getDataLocationOptions = async (attr, parent) => {
    // if (props.surveyScheduleFormData) {
    //   return;
    // }
    if (attr === '-1') {
      options.nation = await window.COMMON.getDataLocation(parent);
      options.nation.unshift(DEFAULT_ALL_NATION);
      if (!props.surveyScheduleFormData) {
        options.state = [];
        options.city = [];
        select.nation = DEFAULT_ALL_NATION;
        select.state = { value: null, label: window.I18N('all_states') };
        select.city = { value: null, label: window.I18N('all_cities') };
      }
    } else if (attr === 'nation') {
      options.state = await window.COMMON.getDataLocation(parent);
      options.state.unshift({ value: null, label: window.I18N('all_states') });
      options.city = [];
      select.state = { value: null, label: window.I18N('all_states') };
      select.city = { value: null, label: window.I18N('all_cities') };
    } else if (attr === 'state') {
      options.city = await window.COMMON.getDataLocation(parent);
      options.city.unshift({ value: null, label: window.I18N('all_cities') });
      select.city = { value: null, label: window.I18N('all_cities') };
    }
    setOptions(options);
    setSelect((current) => {
      return {
        ...current,
        ...select
      };
    });
  };

  const showContainerSave = async (index) => {
    object.index = index;
    object.status = 0;
    const dataSave = {};

    const surveyScheduleFormData = props.surveyScheduleFormData;

    if (surveyScheduleFormData) {
      setObject(() => {
        const newObject = surveyScheduleFormData.object;
        return newObject;
      });
      setSelect(() => {
        const newSelect = surveyScheduleFormData.select;
        return { ...newSelect };
      });
      setOptions(() => {
        const newOptions = surveyScheduleFormData.options;
        return newOptions;
      });
      setDate(() => {
        const newDate = {};
        Object.keys(surveyScheduleFormData.date).forEach((key) => {
          const date = surveyScheduleFormData.date[key];
          newDate[key] = new Date(date);
        });
        return newDate;
      });
      setDataSave(() => {
        return surveyScheduleFormData.dataSave;
      });
      return;

    }
    else {

      if (index === -1) {
        object.isShowCompany = groupQA.company === null || COMPANY_TYPE === window.CONSTANT.INFLUENCER;
        object.radio = 'COMPANY';
        dataSave.name = '';
        dataSave.code = window.COMMON.generateCode('TS');
        dataSave.description = '';
        select.group = groupQA._id;
        select.type = { value: 'ONE', label: window.I18N('one_time') };
        select.companies = [];
        if (object.isShowCompany) {
          select.companies = DEFAULT_ALL_COMPANY
        } else {
          select.companies = window.COMMON.setDataMultiSelectWithGroup(props.options.companies, [props.filter.company && props.filter.company.value]);
        }
        select.groupChats = [];
        select.accountTypes = DEFAULT_ALL_ACCOUNT_TYPES
        select.months = [];
        select.days = [];
        select.companyIndustry = props.options.companyIndustry && props.options.companyIndustry.length > 0 ? props.options.companyIndustry[0] : null;
        select.gender = genderOptions[0];
        select.maritalStatus = maritalStatusOptions[0];
        select.age = !window.COMMON.isEmpty(props.options.age) ? props.options.age[0] : null;
        select.occupation = props.options.occupation && props.options.occupation.length > 0 ? props.options.occupation[0] : null;
        select.educationBackground = props.options.educationBackground && props.options.educationBackground.length > 0 ? props.options.educationBackground[0] : null;
        select.income = props.options.income && props.options.income.length > 0 ? props.options.income[0] : null;
        date.startDate = new Date();
        date.endDate = window.COMMON_DATE.addDaysFromNow(30);
        options.state = [];
        options.city = [];
        if (!window.COMMON.checkRoleIsSystem()) {
          options.companies = window.COMMON.createDataSelectWithGroup(props.companiesFullData, 'id', 'name', 'accountType', 'accountType')
        } else {
          options.companies = window.COMMON.createDataSelectWithGroup(props.companiesFullData, '_id', 'information.name', 'type', 'type')
        }
        select.nation = options.nation[0];
        select.state = { value: null, label: window.I18N('all_states') };
        select.city = { value: null, label: window.I18N('all_cities') };


      } else {
        const obj = props.object;
        object.isShowCompany = groupQA.company === null || COMPANY_TYPE === window.CONSTANT.INFLUENCER;
        object.radio = window.COMMON.getValueFromAttr(obj, 'grantType');
        object.status = window.COMMON.getValueFromAttr(obj, 'status');
        dataSave._id = window.COMMON.getValueFromAttr(obj, '_id', null);
        dataSave.name = window.COMMON.getValueFromAttr(obj, 'name');
        dataSave.code = window.COMMON.getValueFromAttr(obj, 'code');
        dataSave.description = window.COMMON.getValueFromAttr(obj, 'description');
        select.group = window.COMMON.setDataSelect(props.options.group, window.COMMON.getValueFromAttr(obj, 'group._id'));
        select.type = window.COMMON.setDataSelect(typeOptions, window.COMMON.getValueFromAttr(obj, 'type'));
        if (object.isShowCompany) {
          select.companies = window.COMMON.setDataMultiSelectWithGroup(props.options.companies, window.COMMON.getValueFromAttr(obj, 'companies'));
        } else {
          select.companies = window.COMMON.setDataMultiSelectWithGroup(props.options.companies, [window.COMMON.getValueFromAttr(obj, 'company._id', null)]);
        }
        select.groupChats = window.COMMON.setDataMultiSelectWithGroup(props.options.groupChats, window.COMMON.getValueFromAttr(obj, 'groupChats'));
        select.companyIndustry = window.COMMON.setDataSelect(props.options.companyIndustry, window.COMMON.getValueFromAttr(obj, 'companyIndustry'));
        select.type = window.COMMON.setDataSelect(typeOptions, window.COMMON.getValueFromAttr(obj, 'type'));
        select.accountTypes = window.COMMON.setDataMultiSelect(props.options.role, window.COMMON.getValueFromAttr(obj, 'accountTypes'));
        select.months = window.COMMON.setDataMultiSelect(monthsInYearOptions, window.COMMON.getValueFromAttr(obj, 'months'));
        select.days = window.COMMON.setDataMultiSelect(select.type.value === 'WEEKLY' ? daysInWeekOptions : daysInMonthOptions, window.COMMON.getValueFromAttr(obj, 'days'));
        select.gender = window.COMMON.setDataSelect(genderOptions, window.COMMON.getValueFromAttr(obj, 'gender', -1));
        select.maritalStatus = window.COMMON.setDataSelect(maritalStatusOptions, window.COMMON.getValueFromAttr(obj, 'maritalStatus', -1));
        select.age = window.COMMON.setDataSelect(props.options.age, window.COMMON.getValueFromAttr(obj, 'age'));
        select.occupation = window.COMMON.setDataSelect(props.options.occupation, window.COMMON.getValueFromAttr(obj, 'occupation'));
        select.educationBackground = window.COMMON.setDataSelect(props.options.educationBackground, window.COMMON.getValueFromAttr(obj, 'educationBackground'));
        select.income = window.COMMON.setDataSelect(props.options.income, window.COMMON.getValueFromAttr(obj, 'income'));
        date.startDate = window.COMMON_DATE.convertStringToDate(window.COMMON.getValueFromAttr(obj, 'startDate'), '/');
        date.endDate = window.COMMON_DATE.convertStringToDate(window.COMMON.getValueFromAttr(obj, 'endDate'), '/');
        select.nation = window.COMMON.setDataSelect(options.nation, window.COMMON.getValueFromAttr(obj, 'nation'));
        if (select.nation) {
          options.state = await window.COMMON.getDataLocation(select.nation.code);
          select.state = window.COMMON.setDataSelect(options.state, window.COMMON.getValueFromAttr(obj, 'state'));
          if (select.state) {
            options.city = await window.COMMON.getDataLocation(select.state.code);
            select.city = window.COMMON.setDataSelect(options.city, window.COMMON.getValueFromAttr(obj, 'city'));
          }
        }
      }
      setObject(object);
      setSelect(select);
      setOptions(options);
      setDate(date);
      setDataSave({ ...dataSave });
    }
    // eslint-disable-next-line
  };

 

  useEffect(() => {
    object.isShowCompany = groupQA.company === null || COMPANY_TYPE === window.CONSTANT.INFLUENCER;
    select.accountTypes = DEFAULT_ALL_ACCOUNT_TYPES
    if (object.isShowCompany) {
      select.companies = DEFAULT_ALL_COMPANY
      select.companyIndustry = props.options.companyIndustry && props.options.companyIndustry.length > 0 ? props.options.companyIndustry[0] : null;
    } else {
      object.radio = 'COMPANY';
      select.companies = window.COMMON.setDataMultiSelectWithGroup(props.options.companies, [props.filter.company && props.filter.company.value]) 
      select.groupChats = [];
      select.companyIndustry = props.options.companyIndustry && props.options.companyIndustry.length > 0 ? props.options.companyIndustry[0] : null;
      options.state = [];
      options.city = [];
      select.nation = DEFAULT_ALL_NATION;
      select.state = { value: null, label: window.I18N('all_states') };
      select.city = { value: null, label: window.I18N('all_cities') };
    }
    setObject((current) => {
      return {
        ...current,
        ...object
      };
    });
    setSelect((current) => {
      return {
        ...current,
        ...select
      };
    });
    setOptions((current) => {
      return {
        ...current,
        ...options
      };
    });
    filterCompanyFollowSelect()
  }, [groupQA.company, props.options]);

  useEffect(() => {

    if (props.isCompletedGetDraftData === true) {
      getDataLocationOptions('-1', '-1');
    }

    if (props.isCompletedGetDraftData === true) {
      showContainerSave(props.index);
      // !window.COMMON.isEmpty(props.options) && setIsInitData(true);
    }
  // eslint-disable-next-line
}, [props.index, props.groupId, props.options, isInitData, props.isCompletedGetDraftData]);

  const handleValidForm = () => {
    window.COMMON.trimData(dataSave)
    setDataSave({...dataSave})
    const checkForm = window.COMMON.checkFormValidation('form-schedule', formRef.current);
    let check = false;
    if (!checkForm) {
      check = true;
    }
    if (object.radio === 'GROUP_CHAT') {
      const checkGroupChats = window.COMMON.checkSelectValidation('select-group-chats', select.groupChats);
      if (!checkGroupChats) {
        check = true;
      }
    }
    if (select.type.value !== 'ONE') {
      const checkDays = window.COMMON.checkSelectValidation('select-days', select.days);
      if (!checkDays) {
        check = true;
      }
    }
    if (select.type.value === 'YEARLY') {
      const checkMonths = window.COMMON.checkSelectValidation('select-months', select.months);
      if (!checkMonths) {
        check = true;
      }
    }
    if (check) {
      return false;
    }
  };
  const handleSaveForm = async (groupQAData) => {
    try {
      dataSave.startDate = window.COMMON_DATE.getStrDateFilter(date.startDate, -1);
      dataSave.endDate = window.COMMON_DATE.getStrDateFilter(date.endDate, 1);
      dataSave.grantType = object.radio;
      const keys = Object.keys(select);
      for (let i = 0; i < keys.length; i++) {
        const element = keys[i];
        const check = element === 'accountTypes' || element === 'companies' || element === 'groupChats' || element === 'days' || element === 'months';
        dataSave[element] = window.COMMON.getDataSelect(select[element], check);
      }
      const companyId = groupQAData.company || null;
      dataSave.group = groupQAData._id;
      dataSave.company = companyId;
      if(select.companies === null || !Array.isArray(select.companies) && Array.isArray(select.companies?.value) || select.companies?.find(type => Array.isArray(type?.value))){
        dataSave.companies = []
      }
      if (object.isShowCompany === false && companyId) {
        dataSave.companies = [companyId]
      }
      if(select.accountTypes === null || !Array.isArray(select.accountTypes) && Array.isArray(select.accountTypes?.value) || select.accountTypes?.find(type => Array.isArray(type?.value))){
        dataSave.accountTypes = []
      }
      const params = {
        input: dataSave
      };
      window.COMMON.trimData(params);
      const result = await window.COMMON.mutation(TaskScheduleGRAPHQL.MUTATION_ADD_TASK_SCHEDULE, params);
      if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_INSERT)) {
        return true;
      }
    } catch (error) {
      console.log(error);
      window.COMMON.showErrorLogs('SurveySchedule.saveData');
    }
    return false;
  };


  if (typeof window === 'undefined') {
    return null;
  }

  return <>
    <form id="form-schedule" className="needs-validation mt-2" noValidate ref={formRef}>
      <MDBRow>
        <MDBCol sm="6">
          <MDBInput containerClass='mt-0' outline type="text" label={window.I18N('schedule_name') + ' *'} name="name" value={dataSave.name} onChange={handleChange} maxLength="100" pattern="\S(.*\S)?" required>
            <div className="invalid-feedback">{window.I18N('MSG_CODE_029')}</div>
          </MDBInput>
          <MDBRow>
            <MDBCol>
              <MDBInput outline containerClass="m-0" type="text" label={window.I18N('code') + ' *'} name="code" value={dataSave.code} onChange={handleChange} maxLength="50" pattern="\S(.*\S)?" required>
                <div className="invalid-feedback">{window.I18N('MSG_CODE_029')}</div>
              </MDBInput>
            </MDBCol>
            <MDBCol>
              <div className="input-group input-group-transparent">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <MDBIcon fa="true" icon="calendar-alt" className="fa-fw" />
                  </span>
                </div>
                <DatePicker 
                  placeholderText={window.I18N('start_date') + ' *'} 
                  className="form-control" 
                  dateFormat="dd/MM/yyyy" 
                  selected={date.startDate} 
                  startDate={date.startDate}
                  minDate={new Date()} 
                  maxDate={new Date(date.endDate)}
                  endDate={date.endDate}
                  onChange={(event) => handleDatePicker(event, 'startDate')} 
                  showYearDropdown 
                  showMonthDropdown 
                  selectsStart 
                  required 
                />
              </div>
            </MDBCol>
            <MDBCol>
              <div className="input-group input-group-transparent">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <MDBIcon fa="true" icon="calendar-alt" className="fa-fw" />
                  </span>
                </div>
                <DatePicker 
                  placeholderText={window.I18N('end_date') + ' *'} 
                  className="form-control" 
                  dateFormat="dd/MM/yyyy" 
                  selected={date.endDate} 
                  startDate={date.startDate} 
                  minDate={window.COMMON_DATE.compareWithNow(date.startDate, true) === TIME_IN_PAST ? new Date() : new Date(date.startDate)}
                  endDate={date.endDate}
                  onChange={(event) => handleDatePicker(event, 'endDate')} 
                  showYearDropdown 
                  showMonthDropdown 
                  selectsEnd 
                  required               
                />
              </div>
            </MDBCol>
          </MDBRow>
          <MDBInput outline containerClass="mb-0" type="textarea" label={window.I18N('description')} name="description" value={dataSave.description} onChange={handleChange} maxLength="5000"></MDBInput>
        </MDBCol>
        <MDBCol sm="6">
          <Select id="select-type" className="md-form mt-0" value={select.type} placeholder={window.I18N('schedule_type') + ' *'} options={typeOptions} onChange={(event) => handleSelect(event, 'select-type', 'type')} isSearchable />
          {
            select.type.value === 'WEEKLY' ?
              <Select id="select-days" className="md-form" value={select.days} placeholder={window.I18N('days_in_week') + ' *'} options={daysInWeekOptions} onChange={(event) => handleSelect(event, 'select-days', 'days')} isSearchable isMulti closeMenuOnSelect={false} />
              : select.type.value === 'MONTHLY' ?
                <Select id="select-days" className="md-form" value={select.days} placeholder={window.I18N('days_in_month') + ' *'} options={daysInMonthOptions} onChange={(event) => handleSelect(event, 'select-days', 'days')} isSearchable isMulti closeMenuOnSelect={false} />
                : select.type.value === 'YEARLY' ? <>
                  <Select id="select-months" className="md-form" value={select.months} placeholder={window.I18N('months_in_year') + ' *'} options={monthsInYearOptions} onChange={(event) => handleSelect(event, 'select-months', 'months')} isSearchable isMulti closeMenuOnSelect={false} />
                  <Select id="select-days" className="md-form" value={select.days} placeholder={window.I18N('days_in_month') + ' *'} options={daysInMonthOptions} onChange={(event) => handleSelect(event, 'select-days', 'days')} isSearchable isMulti closeMenuOnSelect={false} />
                </> : <></>
          }
          <div className="mt-1">
            <RadioBox data={types} value={object.radio} className="m-0" handleChange={handleRadio}></RadioBox>
          </div>
          {
            !object.isShowCompany || object.radio !== 'COMPANY' ? <></> :
              <>
              <MDBRow>
                <MDBCol>
                  <Select id="select-company-industry" value={select.companyIndustry} options={props.options.companyIndustry} className="md-form" onChange={(event) => handleSelect(event, 'select-company-industry', 'companyIndustry')} isSearchable />
                  <Select id="select-state" value={select.state} options={options.state} className="md-form" placeholder={window.I18N('all_states')} onChange={(event) => handleSelect(event, 'select-state', 'state')} isSearchable />
                </MDBCol>
                <MDBCol>
                  <Select id="select-nation" value={select.nation} options={options.nation} className="md-form" placeholder={window.I18N('all_countries')} onChange={(event) => handleSelect(event, 'select-nation', 'nation')} isSearchable />
                  <Select id="select-city" value={select.city} options={options.city} className="md-form" placeholder={window.I18N('all_cities')} onChange={(event) => handleSelect(event, 'select-city', 'city')} isSearchable />
                </MDBCol>
                </MDBRow>
                <Select 
                  id="select-companies" 
                  value={select.companies} 
                  options={[{
                      label: window.I18N('all_companies'),
                      options: [DEFAULT_ALL_COMPANY]
                    },...options.companies]
                  } 
                  placeholder={window.I18N('all_organizations')} 
                  onChange={(event) => handleSelect(event, 'select-companies', 'companies')} 
                  isSearchable 
                  isMulti 
                  closeMenuOnSelect={false} 
                />
            </>
          }
          {
            object.radio !== 'GROUP_CHAT' ? <></> :
              <Select id="select-group-chats" value={select.groupChats} options={props.options.groupChats} placeholder={window.I18N('group_chat') + ' *'} className="md-form" onChange={(event) => handleSelect(event, 'select-group-chats', 'groupChats')} isSearchable isMulti closeMenuOnSelect={false} />
          }
          {
            object.radio !== 'COMPANY' ? <></> :
              <Select 
                id="select-account-types" 
                value={select.accountTypes} 
                options={[DEFAULT_ALL_ACCOUNT_TYPES,...props.options.role]} 
                placeholder={window.I18N('all_account_types')} 
                className="md-form" 
                onChange={(event) => handleSelect(event, 'select-account-types', 'accountTypes')} 
                isSearchable 
                isMulti 
                closeMenuOnSelect={false} 
              />
          }
          {
            object.radio === 'GROUP_CHAT' ? <></> : <>
              <MDBRow>
                <MDBCol>
                  <Select 
                    id="select-gender" 
                    value={select.gender} 
                    options={genderOptions} 
                    className="md-form mt-0" 
                    onChange={(event) => handleSelect(event, 'select-gender', 'gender')} 
                    isSearchable 
                  />
                  <Select 
                    id="select-age" 
                    value={select.age} 
                    options={props.options.age} 
                    className="md-form" 
                    label={window.I18N('age') + ' *'} 
                    onChange={(event) => handleSelect(event, 'select-age', 'age')} 
                    isSearchable 
                  />
                  <Select 
                    id="select-occupation" 
                    value={select.occupation} 
                    options={props.options.occupation} 
                    className="md-form" 
                    onChange={(event) => handleSelect(event, 'select-occupation', 'occupation')} 
                    isSearchable 
                  />
                </MDBCol>
                <MDBCol>
                  <Select 
                    id="select-marital-status"
                    value={select.maritalStatus}
                    options={maritalStatusOptions}
                    className="md-form mt-0"
                    onChange={(event) => handleSelect(event, 'select-marital-status', 'maritalStatus')}
                    isSearchable
                  />
                  <Select 
                    id="select-income"
                    value={select.income}
                    options={props.options.income}
                    className="md-form"
                    onChange={(event) => handleSelect(event, 'select-income', 'income')}
                    isSearchable                 
                  />
                  <Select 
                    id="select-education-background"
                    value={select.educationBackground}
                    options={props.options.educationBackground} 
                    className="md-form" 
                    onChange={(event) => handleSelect(event, 'select-education-background', 'educationBackground')}
                    isSearchable 
                  />
                </MDBCol>
              </MDBRow>
            </>
          }
        </MDBCol>
      </MDBRow>
    </form>
  </>;
});

export default React.memo(SurveySchedule);