import React, { useImperativeHandle, useMemo } from 'react';
import { MDBBtn, MDBIcon } from 'mdbreact';
import { IconButton } from '@material-ui/core';

// MIME types (audio)
const audioMimeTypes = [
  "audio/mpeg",      // MPEG Audio
  "audio/wav",       // WAV
  "audio/ogg",       // Ogg Vorbis
  "audio/mp3",       // MP3
  "audio/midi",      // MIDI
  "audio/x-midi",    // MIDI (Alternative)
  "audio/aac",       // AAC
  "audio/x-ms-wma",  // WMA
  "audio/webm",      // WebM Audio
  "audio/3gpp",      // 3GPP Audio
  "audio/3gpp2",     // 3GPP2 Audio
  "audio/basic",     // Basic Audio
  "audio/adpcm",     // ADPCM
  "audio/ac3",       // AC3 Audio
  "audio/mp4",       // MP4 Audio
  "audio/amr",       // AMR Audio
  "audio/x-aiff"     // X-AIFF
];

// MIME types (image)
const imageMimeTypes = [
  "image/jpeg",           // JPEG
  "image/png",            // PNG
  "image/gif",            // GIF
  "image/bmp",            // BMP
  "image/webp",           // WebP
  "image/tiff",           // TIFF
  "image/svg+xml",        // SVG
  "image/x-icon",         // ICO
  "image/heic",           // HEIC
  "image/heif",           // HEIF
  "image/avif",           // AVIF
  "image/x-pcx",          // PCX
  "image/x-png",          // X-PNG
  "image/vnd.adobe.photoshop", // Photoshop
  "image/x-portable-anymap",   // PNM
  "image/x-cmu-raster",    // X-CMU-Raster
  "image/x-xbitmap",       // X-Windows-Bitmap
  "image/x-xpixmap"        // X-PixMap
];

// MIME types video
const videoMimeTypes = [
  "video/mp4",       // MP4
  "video/mpeg",      // MPEG
  "video/ogg",       // Ogg
  "video/webm",      // WebM
  "video/3gpp",      // 3GPP
  "video/3gpp2",     // 3GPP2
  "video/x-msvideo", // AVI
  "video/quicktime", // QuickTime
  "video/x-flv",     // FLV
  "video/x-matroska" // Matroska (MKV)
];

// MIME types (documents)
const documentMimeTypes = [
  "application/pdf",                          // PDF
  "application/msword",                       // Microsoft Word
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // Word (docx)
  "application/vnd.ms-excel",                 // Microsoft Excel
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // Excel (xlsx)
  "application/vnd.ms-powerpoint",            // Microsoft PowerPoint
  "application/vnd.openxmlformats-officedocument.presentationml.presentation", // PowerPoint (pptx)
  "text/plain",                               // Text file (txt)
  "application/rtf",                          // RTF
  "application/vnd.oasis.opendocument.text",  // OpenDocument Text (odt)
  "application/vnd.oasis.opendocument.spreadsheet", // OpenDocument Spreadsheet (ods)
  "application/vnd.oasis.opendocument.presentation", // OpenDocument Presentation (odp)
  "text/csv"                                 // CSV
];

// MIME types (archive)
const archiveMimeTypes = [
  "application/zip",              // ZIP
  "application/x-rar-compressed", // RAR
  "application/x-7z-compressed",  // 7-Zip
  "application/x-tar",            // Tarball
  "application/gzip",             // Gzip
  "application/x-bzip",           // Bzip
  "application/x-bzip2",          // Bzip2
  "application/vnd.ms-cab-compressed" // CAB
];

// Mảng các MIME types cho text (văn bản)
const textMimeTypes = [
  "text/plain",          // Plain text
  "text/html",           // HTML
  "text/css",            // CSS
  "text/javascript",     // JavaScript
  "application/json",    // JSON
  "application/xml",     // XML
  "text/markdown",       // Markdown
  "application/x-httpd-php", // PHP
  "application/x-sh",    // Shell script
  "application/sql",     // SQL
  "application/typescript" // TypeScript
];



const ACCEPT_RULES = {
  'image/*': imageMimeTypes,
  'audio/*': audioMimeTypes,
  'video/*': videoMimeTypes,
  'application/*': [...documentMimeTypes, ...archiveMimeTypes, ...textMimeTypes],
  'text/*': [...documentMimeTypes, ...archiveMimeTypes, ...textMimeTypes],
  'all': [
    ...imageMimeTypes,
    ...audioMimeTypes,
    ...videoMimeTypes,
    ...documentMimeTypes,
    ...archiveMimeTypes,
    ...textMimeTypes
  ]
}

const InputFile = React.forwardRef((props, ref) => {

  const MAX_FILES_SIZE = useMemo(() => {
    const dataConfig = JSON.parse(localStorage.getItem(window.CONSTANT.DATA_CONFIG))
    const maxFileSize = window.COMMON.getValueFromAttr(window.COMMON.getObjectInArrs('MAX_FILE_SIZE', dataConfig, 'name'), 'value')
    const maxVideoSize = window.COMMON.getValueFromAttr(window.COMMON.getObjectInArrs('MAX_VIDEO_SIZE', dataConfig, 'name'), 'value')
    const maxAudioSize = window.COMMON.getValueFromAttr(window.COMMON.getObjectInArrs('MAX_AUDIO_SIZE', dataConfig, 'name'), 'value')
    return {
      'image/*': maxFileSize, //100MB
      'audio/*': maxAudioSize, //50MB
      'video/*': maxVideoSize, //200MB
      'application/*': maxFileSize, //100MB
      'text/*': maxFileSize, //100MB
      'all': maxFileSize //100MB
    }
  }, [])

  const openImportFile = () => {
    document.getElementById(props.id).value = null;
    document.getElementById(props.id).click();
  };

  const isOverFileSize = (files) => {
    if(window.COMMON.isEmpty(files)){
      return false
    }
    const totalSize = Array.from(files).reduce((acc, file) => acc += file.size , 0)
    const convertedToMB = totalSize / (1024 * 1024) //convert to MB
    const isOverSize = convertedToMB >= MAX_FILES_SIZE[props.accept]
    if(isOverSize){
      window.COMMON.showMessage('warning', 'MSG_CODE_090', window.I18N('MSG_CODE_090', { maxSize: MAX_FILES_SIZE[props.accept] }));
    }
    return isOverSize
  }

  const handleChange = async (e) => {
    window.showLoading();
    const files = e.target.files;
    const result = [];
    const queueResizeImage = []
    if(isOverFileSize(files)){
      props.handleFile(result);
      window.hideLoading();
      return
    }
    for (let i = 0; i < files.length; i++) {
      if(props.accept && ACCEPT_RULES[props.accept] && !ACCEPT_RULES[props.accept].includes(files[i].type)){
        window.COMMON.showMessage('warning', 'MSG_CODE_064', window.I18N('MSG_CODE_064'));
        break
      }
      if (files[i].type.match(/image.*/) && files[i].type.indexOf('gif') === -1) {
        queueResizeImage.push(resizeImage(files[i]))
      }else {
        result.push(files[i]);
      }
    }
    if(!window.COMMON.isEmpty(queueResizeImage)){
      const listImageAfterResize = await Promise.all(queueResizeImage)
      for (let i = 0; i < listImageAfterResize.length; i++) { 
          const file = listImageAfterResize[i]
          file.name = files[i].name;
          result.push(file);
      }
    }
    if (result && result.length > 0) {
      if (props.isMulti) {
        props.handleFile(result);
      } else {
        props.handleFile(result[0]);
      }
    }
    window.hideLoading();
  };

  const resizeImage = async (file) => {
    const dataConfig = JSON.parse(localStorage.getItem(window.CONSTANT.DATA_CONFIG));
    const maxWidth = window.COMMON.getValueFromAttr(window.COMMON.getObjectInArrs("WIDTH_IMAGE",dataConfig,'name'), 'value', 2000);
    const maxHeight = window.COMMON.getValueFromAttr(window.COMMON.getObjectInArrs("HEIGHT_IMAGE", dataConfig, 'name'), 'value', 1500);
    const reader = new FileReader();
    const image = new Image();
    const canvas = document.createElement('canvas');
    const dataURItoBlob = function (dataURI) {
      const bytes = dataURI.split(',')[0].indexOf('base64') >= 0 ? atob(dataURI.split(',')[1]) : unescape(dataURI.split(',')[1]);
      const mime = dataURI.split(',')[0].split(':')[1].split(';')[0];
      const max = bytes.length;
      const ia = new Uint8Array(max);
      for (let i = 0; i < max; i++) {
        ia[i] = bytes.charCodeAt(i);
      } 
      return new Blob([ia], {
        type: mime
      });
    };
    const resize = function () {
      let srcWidth = image.width;
      let srcHeight = image.height;
      var ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);
      canvas.width = srcWidth*ratio;
      canvas.height = srcHeight*ratio;
      canvas.getContext('2d').drawImage(image, 0, 0, srcWidth*ratio, srcHeight*ratio);
      const dataUrl = canvas.toDataURL(file.type || 'image/png');
      return dataURItoBlob(dataUrl);
    };
    return new Promise(function (ok, no) {
      if (!file.type.match(/image.*/)) {
        no(new Error('Not an image'));
        return;
      }
      reader.onload = function (readerEvent) {
        image.onload = function () {
          return ok(resize());
        };
        image.src = readerEvent.target.result;
      };
      reader.readAsDataURL(file);
    });
  };

  useImperativeHandle(ref, () => ({
    openImportFile,
    isOverFileSize
  }))

  if (props.children) {
    return <div className="d-inline-block text-center">
      <IconButton aria-label="add picture" disabled={props.disabled} onClick={props.disabled ? () => {} : () => openImportFile()}>
        { props.children }
      </IconButton>
      <input type="file" className="form-control file-upload" id={props.id} accept={props.accept} multiple={props.isMulti} onChange={handleChange} />
      <br></br> {props.description}
    </div>;
  }
  if (typeof window === 'undefined') {
    return null;
  }
  return <div className="d-inline-block text-center">
    <MDBBtn color={window.COMMON.getValue(props.color)} className={'btn-upload ' + props.className} type="button" disabled={props.disabled} onClick={props.disabled ? () => {} : () => openImportFile()}>
      <MDBIcon fa="true" icon={props.icon} className="fa-fw"/> {props.label}
    </MDBBtn>
    <input type="file" className="form-control file-upload" id={props.id} accept={props.accept} multiple={props.isMulti} onChange={handleChange} />
    <br></br> {props.description}
  </div>;
});

export default React.memo(InputFile);