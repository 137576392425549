import React from 'react';
import { NavLink } from 'react-router-dom';
import { MDBRow, MDBCol, MDBIcon } from 'mdbreact';

const GridItemShareSelfAssessmentAdmin = React.memo(function GridItemShareSelfAssessmentAdmin(props) {

  const handleAssign = (event, assessmentId) => {
    event.stopPropagation();
    if (props.handleOpenAssign && assessmentId) {
      const filterData = filterGroupByAssessment(assessmentId);
      props.handleOpenAssign(filterData);
    }
  };

  const filterGroupByAssessment = (assessmentId) => {
    if (!props.shareList || !assessmentId) return [];
    return props.shareList?.filter(child => child.selfAssessment?._id === assessmentId);
  };

  const canBeAssigned = (assessmentId) => {
    let result = false;
    if (!props.shareList || !assessmentId) return false;

    const filterData = filterGroupByAssessment(assessmentId);
    if (filterData.length === 0) return false;
    filterData.map(item => {
      if (!item.leader) result = true;
    });
    return result;
  };

  const companyName = (assessmentId) => {
    const company = new Set();
    props.shareList.forEach(el => {
      if (el.selfAssessment?._id === assessmentId) {
        company.add(el.company.information.name)
      }
    })
    return Array.from(company).map((el, index) => index === 0 ? el : ` ; ${el}`)
  }

  if (!props.data || props.data.length === 0) {
    return <li className="list-group-item p-3 text-center">{window.I18N('empty_table')}</li>;
  }
  return (
    <>
      {
        props.data.map((item, i) =>
          <li className="list-group-item p-3" key={i}>
            <MDBRow className={(item.status === -2 ? ' disabled' : '')}>
              <MDBCol>
                <p className="font-weight-bold m-0">
                  {window.COMMON.getValueFromAttr(item, 'name')}
                </p>
                <p className="mb-3">
                  <MDBIcon fa="true" icon="qrcode" className="fa-fw mr-1"></MDBIcon>
                  <small className="text-muted">{window.COMMON.getValueFromAttr(item, 'code')}</small>
                </p>
                <div>
                  <MDBIcon fa="true" icon="building" className="fa-fw mr-1 mb-2"></MDBIcon>
                  <small className="text-muted">{companyName(item._id)}</small>
                </div>
                {
                  !canBeAssigned(item._id) && <label className="label-box label-info p-1 m-0">{window.I18N('assigned')} - {item.leader.name}</label>
                }
                <p className="mt-1 mb-0">
                  <MDBIcon fa="true" icon="clock" className="fa-fw mr-1"></MDBIcon>
                  <small className="text-muted">{window.COMMON.formatUnixDateSingleData(item, 'startDate') + ' - ' + window.COMMON.formatUnixDateSingleData(item, 'endDate')}</small>
                </p>
              </MDBCol>
              <MDBCol>
                {
                  filterGroupByAssessment(item._id).map((child, j) => <div className={'d-flex align-items-start flex-column bd-highlight' + (j === 0 ? '' : ' mt-2')} key={i * j + j}>
                    <div className="w-100 d-flex align-items-center">
                      <p className="mb-0">
                        <MDBIcon fa="true" icon="circle" className="fa-fw mr-1"></MDBIcon>
                        {window.COMMON.getValueFromAttr(child, 'nameLanguage')}
                      </p>
                    </div>
                  </div>)
                }
              </MDBCol>
              <MDBCol xs="2" sm="1" md="1" className="text-right">
                <div className="dropdown">
                  <NavLink to="#" className="" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-boundary="viewport">
                    <MDBIcon fa="true" icon="ellipsis-v" className="fa-fw black-text"></MDBIcon>
                  </NavLink>
                  <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton" data-boundary="viewport">
                    <li className={`dropdown-item ${canBeAssigned(item._id) ? '' : 'disabled'}`} onClick={(e) => handleAssign(e, item._id)}>
                      <MDBIcon fa="true" icon="user-plus" className="fa-fw"></MDBIcon> {window.I18N('assign_leader_assessor')}
                    </li>
                  </ul>
                </div>
              </MDBCol>
            </MDBRow>
          </li>
        )
      }
    </>
  );
});

export default GridItemShareSelfAssessmentAdmin;