import React, { PureComponent } from 'react';
import { MDBRow, MDBCol, MDBCardImage, MDBIcon } from 'mdbreact';
import Slide from './Slide';

class List extends PureComponent {

  clickItem(event, index) {
    event.stopPropagation();
    if (this.props.clickItem) {
      this.props.clickItem(index);
    }
  }

  clickItemList(event, index, code) {
    event.stopPropagation();
    if (this.props.clickItemList) {
      this.props.clickItemList(index, code);
    }
  }

  render() {
    let html = null;
    if (this.props.isSlide) {
      html = this.props.data.map((item, i) => 
        <div className={window.COMMON.getValue(this.props.classNameItem)} key={i}>
          <div className="item" data-index={i} data-id={item[this.props.attr_id]} onClick={(event) => this.clickItem(event, i)}>
            <div className="media">
              <div className="media-left media-middle pb-0">
                <Slide id={'container-slide-' + i} data={window.COMMON.getValueFromAttr(item, this.props.attr_image)} style={{ height: '160px', width: '240px' }}></Slide>
              </div>
              <div className="media-body media-middle">
                <div className="content" dangerouslySetInnerHTML={{__html: window.COMMON.getValueFromAttr(item, this.props.attr_content).replace(/\n/g, '<br>')}}></div>
                <div className="caption">
                  <div className="media d-flex align-items-center">
                    {
                      window.COMMON.getValueFromAttr(item, this.props.attr_status) 
                        ? <MDBIcon fa="true" className="fa-fw fa-lg text-success mr-1" style={{ fontSize: '30px' }} icon="globe-asia"/>
                        : <MDBIcon fa="true" className="fa-fw fa-lg text-primary mr-1" style={{ fontSize: '30px' }} icon="user-circle"/>
                    }                                        
                    <div className="media-body">
                      <h4 className="txt-status">
                        {
                          window.COMMON.getValueFromAttr(item, this.props.attr_status) ? <span>All users</span> : <span> {
                            item.people.map((item, j) => <div className="d-inline-flex align-items-center mr-1" key={j}>
                              <MDBCardImage zoom cascade waves src={window.COMMON.setValueImage(item.avatar)} className="small-icon rounded-circle mr-1"/>
                              <span>{item.nickname}</span>
                            </div>
                            )
                          }
                          </span>
                        }
                      </h4>
                      <h4 className="txt-time">{window.COMMON.getValueFromAttr(item, this.props.attr_sub)}</h4>
                    </div>
                  </div>
                  { this.props.isPost ? <h4 className="txt-icon" >
                    <MDBIcon fa="true" icon="thumbs-up" style={{ color: '#007bff' }} className="fa-fw"/> <span className="mr-3">{item.like.length}</span>
                    <MDBIcon fa="true" icon="thumbs-down" style={{ color: 'red' }} className="fa-fw"/> <span className="mr-3">{item.dislike.length}</span>
                    <MDBIcon fa="true" icon="comment" style={{ color: 'orange' }} className="fa-fw"/> <span className="mr-3">{item.comment.length}</span>
                  </h4> : null }
                </div>
              </div>
            </div>
            <div className="button">
              {this.props.icons.map((icon, j) => (
                <MDBIcon key={j} fa="true" icon={icon.icon} style={{ color: icon.color }}  onClick={(event) => this.clickItemList(event, i, icon.code)}/>
              ))}
            </div>
          </div>
        </div>
      );
    } else {
      html = this.props.data.map((item, i) => 
        <MDBCol size="12" key={i}>
          <div className="item wow fadeInUp animated" data-index={i} data-id={item[this.props.attr_id]} onClick={(event) => this.clickItem(event, i)}>
            <div className="caption">
              <div className="media">
                <MDBCardImage zoom cascade waves src={window.COMMON.setValueImage(window.COMMON.getValueFromAttr(item, this.props.attr_avatar))} className="image-icon rounded-circle mr-2"/>
                <div className="media-body media-middle">
                  <h4 className="txt-name m-0" onClick={this.props.onNameClick?()=>this.props.onNameClick(item):()=>{} }>{window.COMMON.getValueFromAttr(item, this.props.attr_text)}</h4>
                  {item?.postId?.isPublic === false && !window.COMMON.checkRoleIsSystem() ? 
                  <div className="content font-italic disabled" dangerouslySetInnerHTML={{__html: this.props.privateTitle}}></div>:
                  <div className="content" dangerouslySetInnerHTML={{__html: window.COMMON.getValueFromAttr(item, this.props.attr_content).replace(/\n/g, '<br>')}}></div>
                  }
                  <small className="txt-time">{window.COMMON.getValueFromAttr(item, this.props.attr_sub)}</small>
                </div>
                <div className="media-body media-middle">
                  {window.COMMON.getValueFromAttr(item, this.props.attr_deletedBy) && <div className="content" dangerouslySetInnerHTML={{__html: this.props.deleteTitle}}></div>}
                  <h4 className="txt-name m-0">{window.COMMON.getValueFromAttr(item, this.props.attr_deletedBy)}</h4>
                  <small className="txt-time">{window.COMMON.getValueFromAttr(item, this.props.attr_deletedDate)}</small>
                </div>
              </div>
              { this.props.isPost ? <h4 className="txt-icon" >
                <MDBIcon fa="true" icon="thumbs-up" style={{ color: '#007bff' }} className="fa-fw"/> <span className="mr-3">{item.like.length}</span>
                <MDBIcon fa="true" icon="thumbs-down" style={{ color: 'red' }} className="fa-fw"/> <span className="mr-3">{item.dislike.length}</span>
                <MDBIcon fa="true" icon="comment" style={{ color: 'orange' }} className="fa-fw"/> <span className="mr-3">{item.comment.length}</span>
              </h4> : null }
            </div>
            {!window.COMMON.getValueFromAttr(item, this.props.attr_deletedBy) && <div className="button">
              {this.props.icons.map((icon, j) => (
                <MDBIcon key={j} fa="true" icon={icon.icon} style={{ color: icon.color }} className="fa-fw" onClick={(event) => this.clickItemList(event, i, icon.code)} />
              ))}
            </div>}
          </div>
        </MDBCol>
      );
    }
    return (
      <div className={'container-list w-100 mb-2 ' + this.props.className}>
        <div className="title">{this.props.title}</div>
        <div className="w-100">
          <MDBRow className='w-100 d-flex align-items-center justify-content-center'>{window.COMMON.isEmpty(html) ? window.I18N('empty_table') : html}</MDBRow>
        </div>
      </div>
    );
  }
}

export default List;