import React, { useState, useCallback, useLayoutEffect, useEffect } from 'react';
import { MDBInputGroup, MDBIcon, MDBBtn } from 'mdbreact';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Panel from '../../components/Panel';
import List from '../../components/List';
import Pagination from '../../components/Pagination';

import CommentGRAPHQL from '../../../graphql/Comment';
import { useTracking } from 'react-tracking';
import eventName from '../../../common/events';
import Select from 'react-select';
import RadioBox from '../../components/RadioBox';


function CommentPage(props) {
  const DATE_MODE = [
    { value: -30, label: window.I18N('last_1_month') },
    { value: -91, label: window.I18N('last_3_month') },
    { value: -183, label: window.I18N('last_6_month') },
    { value: -365, label: window.I18N('last_1_year') }
  ]

  const allCompaniesOption = { value: null, label: window.I18N('all_companies') }
  const { trackEvent } = useTracking();
  const history = useHistory();

  const icons = [
    {
      icon: 'trash-alt',
      color: 'red',
      code: 'DEL'
    }
  ];
  const commentStatus = [
    { value: 1, label: window.I18N('active') },
    { value: 2, label: window.I18N('hidden') }
  ];
  const [filter, setFilter] = useState({
    text: '',
    status: 1,
    company: allCompaniesOption,
    dateRange : DATE_MODE[0]
  });
  const [object, setObject] = useState({ index: -1 });
  const [pagination, setPagination] = useState({ pageSize: 30, currentPage: 0 });
  const [data, setData] = useState([]);
  const [dataList, setDataList] = useState([]);
  const [options, setOptions] = useState({
    company: [],
  });

  useLayoutEffect(() => {
    setTimeout(() => {
      getDataCompany();
      searchData()
    }, 100);  
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  },[filter.text])

  useEffect(() => {
    if (!props.language.code) {
      return
    }
    filter.dateRange = DATE_MODE.find(el => el.value === filter.dateRange.value)
    options.company[0] = allCompaniesOption
    if (filter.company.value === null) {
      filter.company = allCompaniesOption
    }
    setFilter({ ...filter })
    setOptions({...options})
  },[props.language.code])

  const getDataCompany = async () => {
    if (window.COMMON.checkRoleIsSystem()) {
      await window.COMMON.getDataAllCompany();
      const dataCompany = JSON.parse(localStorage.getItem(window.CONSTANT.DATA_ALL_COMPANY));
      if (dataCompany && dataCompany.length > 0) {
        options.company = window.COMMON.createDataSelectWithGroup(dataCompany, '_id', 'information.name', 'type', 'type');
      }
      options.company.unshift(allCompaniesOption);
      setOptions({...options});
    }
  }

  const createTrackingEvent = (event) => {
    return trackEvent({
      name: event,
      createdAt: new Date().toISOString()
    });
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      searchData()
    }
  };

  const handleFilterChange = (event) => {
    const { value } = event.target
    if(!window.COMMON.validateTextInput(value)){
      return
    }
    setFilter((prev) => ({ ...prev, text: value }));
  };

  const handleSelectCompany = (event) => {
    filter.company = event;
    setFilter({ ...filter });
  };

  const handleSelectStatus = (event) => {
    filter.status = event
    setFilter({ ...filter });
  }

  const handleSelectDateRange = (event) => {
    filter.dateRange = event
    setFilter({ ...filter });
  }
  const searchData = async () => {
    /* if (!window.COMMON.checkValue(filter.text)) {
      window.COMMON.showMessage('warning', 'MSG_CODE_018', window.MSG.MSG_CODE_018);
      return;
    } */
    try {
      const params = {
        input: {
          text: filter.text.trim(),
          startDate: window.COMMON_DATE.getStrDateFilter(window.COMMON_DATE.addDaysFromNow(filter.dateRange.value), -1),
          endDate: window.COMMON_DATE.getStrDateFilter(new Date(), 1),
          status : filter.status === 1? true : false
        }
      };
      if (window.COMMON.checkRoleIsSystem() && filter.company.value) {
        params.input.companyId = filter.company.value
      }
      const result = await window.COMMON.query(CommentGRAPHQL.QUERY_FIND_COMMENT, params);
      if (result && result.data) {
        const data = window.COMMON.formatMultiDate(result.data.findComments, 'createdDate,deletedDate', 'DD/MM/YYYY hh:mm:ss');
        setData(data);
        loadData(0, data);
      } else {
        window.COMMON.showMessage('warning', 'MSG_CODE_019', window.MSG.MSG_CODE_019);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('CommentPage.searchData');
    }
  };

  const loadData = (index, items) => {
    const dataList = [];
    for (let i = pagination.pageSize * index; i < pagination.pageSize * (index + 1); i++) {
      if (i > (items ? items.length : data.length) - 1) {
        break;
      }
      const obj = items ? items[i] : data[i];
      if (obj) {
        dataList.push(obj);
      }
    }
    pagination.currentPage = index;
    setDataList(dataList);
    setPagination({ ...pagination });
  };

  // eslint-disable-next-line
  const clickItemList = useCallback((index, code) => {
    object.index = index;
    setObject(object);
    window.COMMON.showModal('#modal-delete');
    window.deleteMethod = deleteData;
  });

  const deleteData = async () => {
    try {
      const params = {
        id: data[object.index]._id
      };
      const result = await window.COMMON.mutation(CommentGRAPHQL.MUTATION_DEL_COMMENT, params);
      if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_DELETE)) {
        data.splice(object.index, 1);
        if (dataList.length === 1) {
          object.currentPage--;
        }
        loadData(object.currentPage);
        createTrackingEvent(eventName.DEL_COMMENT);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('CommentPage.deleteData');
    }
  };

  if (typeof window === 'undefined') {
    return null;
  }
  return (
    <div className="container-row">
      <Panel id="container-data" title={window.I18N('comment')} icon="list-alt">
        <div className="container-top d-flex align-items-center justify-content-around wow fadeInDown animated" data-wow-delay="0.5s">
          <div className="bd-highlight mr-2" style={{ width: '250px' }}>
              <div className="input-group input-group-transparent">
                <div className="input-group-prepend">
                  <RadioBox data={commentStatus} value={filter.status} className="m-0 ml-auto" handleChange={handleSelectStatus}></RadioBox>
                </div>
              </div>
          </div>
          
          <div className="bd-highlight mr-2" style={{ width: '250px' }}>
              <div className="input-group input-group-transparent">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <MDBIcon fa="true" icon="calendar-alt" className="fa-fw" />
                  </span>
                </div>
                <Select className="md-form m-0" placeholder={window.I18N('choose_option')} value={filter.dateRange} options={DATE_MODE} onChange={handleSelectDateRange}  isSearchable />
              </div>
            </div>
          {!window.COMMON.checkRoleIsSystem() ?
            <div></div> :
            <div className="bd-highlight mr-2" style={{ width: '250px' }}>
              <div className="input-group input-group-transparent">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <MDBIcon fa="true" icon="building" className="fa-fw" />
                  </span>
                </div>
                <Select className="md-form m-0" placeholder={window.I18N('choose_option')} value={filter.company} options={options.company} onChange={handleSelectCompany}  isSearchable />
              </div>
            </div>
          }
          <MDBInputGroup type="text" style={{ width: '250px' }} value={filter.text} hint={window.I18N('search_content_comment')} onChange={handleFilterChange} maxLength="100" pattern="\S(.*\S)?" required
          />
          <div  >
          <MDBBtn color="primary" onClick={searchData}>
            <MDBIcon fa="true" icon="search" className="fa-fw"></MDBIcon> {window.I18N('btn_search')}
          </MDBBtn>
        </div>
        </div>
        
        <div className="container-table wow fadeIn animated" data-wow-delay="1s">
          <List
            data={dataList}
            attr_id="_id"
            attr_content="text"
            attr_text="createdBy.nickname"
            attr_avatar="createdBy.avatar"
            attr_sub="createdDate"
            attr_deletedBy="deletedBy.nickname"
            attr_deletedDate="deletedDate"
            title={window.I18N('result_list') + ' (' + data.length + ')'}
            deleteTitle={window.I18N('hidden_by')}
            privateTitle={"[ " + window.I18N('private_comment')+ " ]"}
            icons={window.COMMON.checkRoleIsSystem() ? icons : []}
            clickItemList={window.COMMON.checkRoleIsSystem() ? clickItemList : () => { }}
            onNameClick={(paramItem)=>{history.push('/profile?id=' + paramItem.createdBy._id);}}
          >
          </List>
          <Pagination total={data.length} pageSize={pagination.pageSize} currentPage={pagination.currentPage} changePage={loadData}></Pagination>
        </div>
      </Panel>
    </div>
  );
}

const mapStateToProps = state => ({
  language: state.language.value
});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CommentPage);