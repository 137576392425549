import React, { useCallback } from 'react'

const useDownLoadImage = ({ downLoadRef, imageName = 'download_image', width = null, height = null }) => {
    const downloadImage = useCallback(() => {
        // Get the SVG element from the referenced container
        const svg = downLoadRef.current.querySelector('svg')
        if (!svg) return

        // Serialize the SVG element into an XML string
        const serializer = new XMLSerializer()
        const svgData = serializer.serializeToString(svg)

        // Create a canvas element
        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d')

        // Set canvas dimensions based on the provided width and height or the SVG size
        const svgSize = svg.getBoundingClientRect()
        const canvasWidth = window.COMMON.isEmpty(width) ? svgSize.width : width // Default to SVG width if no width is provided
        const canvasHeight = window.COMMON.isEmpty(height) ? svgSize.height : height // Default to SVG height if no height is provided
        canvas.width = canvasWidth
        canvas.height = canvasHeight

        // Convert SVG to an image
        const img = new Image()
        img.onload = () => {
            // Ensure the image is centered within the canvas
            const imgSize = Math.min(canvasWidth, canvasHeight, img.width, img.height)
            const offsetX = (canvasWidth - imgSize) / 2
            const offsetY = (canvasHeight - imgSize) / 2

            // Fill the canvas background with white
            ctx.fillStyle = 'white'
            ctx.fillRect(0, 0, canvasWidth, canvasHeight)

            // Draw the image on the canvas at the calculated position
            ctx.drawImage(img, offsetX, offsetY, imgSize, imgSize)

            // Convert the canvas content to a PNG image and trigger the download
            const pngData = canvas.toDataURL('image/png')
            const link = document.createElement('a')
            link.href = pngData
            link.download = `${imageName}.png` // Use the provided image name for the file
            link.click()
        }

        // Set the image source as the serialized SVG data
        img.src = `data:image/svg+xml;base64,${btoa(svgData)}`

        // Display a success message
        window.COMMON.showMessage('success', 'MSG_CODE_092', window.I18N('MSG_CODE_092'))
    }, [downLoadRef, imageName])

    return { downloadImage }
}

export default useDownLoadImage
