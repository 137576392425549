import React, { useLayoutEffect } from 'react';
import { GroupAdd, CheckCircle } from '@material-ui/icons';
import { Grid, ListItem, ListItemAvatar, ListItemText, ListItemSecondaryAction, Avatar, Menu, Tooltip, IconButton } from '@material-ui/core';
import { MDBInputGroup, MDBIcon } from 'mdbreact';
import $ from 'jquery';

const SelectAccount = React.memo(function SelectAccount(props) {
  let timeout = null;
  const [content, setContent] = React.useState('');
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selected, setSelected] = React.useState([]);
  const [data, setData] = React.useState([]);

  useLayoutEffect(() => {
    setData(props.options);
    setSelected(props.selected);
  }, [props.options, props.selected]);

  const handleChange = (event) => {
    setContent(event.target.value);
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }
    timeout = setTimeout(() => {
      if (event.target.value === '') {
        setData([...props.options]);
      } else {
        const data = [];
        props.options.forEach(element => {
          if (element.label.toLowerCase().indexOf(event.target.value.trim().toLowerCase()) > -1) {
            data.push(element);
          }
        });
        setData([...data]);
      }
    }, 500);
  };

  const handleClickListItem = (event) => {
    $(document).off('focusin.modal')
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (event, item) => {
    const check = checkIsSelected(item.value);
    if (check) {
      selected.splice(selected.indexOf(item), 1);
    } else {
      selected.push(item);
    }
    setSelected([ ...selected ]);
  };

  const handleClose = () => {
    setAnchorEl(null);
    if (props.handleClose) {
      props.handleClose(selected);
    }
  };

  const checkIsSelected = (value) => {
    const obj = window.COMMON.getObjectInArrs(value, selected, 'value');
    return obj ? true : false;
  };

  return (
    <div className="d-inline-block text-center">
      <div className="d-flex flex-row align-items-center">
        <IconButton aria-label="add anyone" aria-haspopup="true" color="primary" className="mr-4" disabled={props.disabled} onClick={handleClickListItem}>
          <GroupAdd />
        </IconButton>
        {selected.map((item, index) => (
          <div key={item.value} className="container-item-person" xs={3}>
            <Tooltip title={window.COMMON.getValueFromAttr(item, 'label')}>
              {
                index < 3 ? <Avatar src={window.COMMON.getValueFromAttr(item, 'image')} className="icon-avatar" style={{ zIndex: 4 - index }}></Avatar>
                  : (index === 3 ? <Avatar className="icon-avatar icon-last" style={{ zIndex: 1 }}>+{selected.length - 3}</Avatar> : <div></div>)
              }
            </Tooltip>
          </div>
        ))}
      </div>
      <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose} PaperProps={{
        style: {
          minHeight: '500px',
          maxHeight: '500px',
          width: '500px'
        }
      }}>
        <Grid container>
          <Grid item xs={12} className="p-3">
            <MDBInputGroup type="text" containerClassName="input-group-transparent" className="m-0" value={content} hint={window.I18N('search_nickname')} onChange={handleChange} maxLength="100" pattern="\S(.*\S)?" required
              append={
                <span className="input-group-text">
                  <MDBIcon fa="true" icon="search" className="fa-fw"></MDBIcon>
                </span>
              }
            />
          </Grid>
          <Grid item xs={12}>
            <hr className="m-0"></hr>
          </Grid>
          <Grid item xs={12}>
            {data.map((option, index) => (<div  key={index}>
              <ListItem style={{ minWidth: '400px', cursor: 'pointer' }} selected={checkIsSelected(option.value)} onClick={(event) => handleMenuItemClick(event, option)}>
                <ListItemAvatar>
                  <Avatar src={window.COMMON.getValueFromAttr(option, 'image')} className="icon-avatar"></Avatar>
                </ListItemAvatar>
                <ListItemText primary={option.label}/>
                {
                  checkIsSelected(option.value) ? <ListItemSecondaryAction>
                    <IconButton edge="end" aria-label="active">
                      <CheckCircle style={{ color: '#1e7e34' }} />
                    </IconButton>
                  </ListItemSecondaryAction> : <ListItemSecondaryAction></ListItemSecondaryAction>
                }
              </ListItem>
            </div>))}
          </Grid>
        </Grid>
      </Menu>
    </div>
  );
});

export default SelectAccount;