import gql from 'graphql-tag';

const QUERY_FANPAGE = gql`
  query($type: String!, $text: String, $page: Int, $pageSize: Int) {
    getFanpages(type: $type, text: $text, page: $page, pageSize: $pageSize) {
      total
      page
      pageSize
      data {
        _id
        name
        image
        description
        fields {
          _id
          value
        }
        countMember
        active
        isHide
        type
        createdDate
        createdBy {
          _id
          avatar
          name
          nickname
        }
      }
    }
  }
`;

const QUERY_MEMBER_IN_FANPAGE = gql`
  query($id: String!, $status: String, $page: Int, $pageSize: Int) {
    findMembersInFanpage(id: $id, status: $status, page: $page, pageSize: $pageSize) {
      total
      page
      pageSize
      data {
        _id
        status
        role
        user {
          _id
          avatar
          name
          nickname
          bio
          company {
            information {
              name
              avatar
            }
          }
        }
        createdDate
        updatedDate
        subQuestion1
        subQuestion2
        subQuestion3
        subAnswer1
        subAnswer2
        subAnswer3
      }
    }
  }
`;

const QUERY_FANPAGE_DETAIL = gql`
    query($id: String!) {
      getFanpageDetail(id: $id) {
        _id
        name
        image
        description
        fields {
          _id
          value
        }
        countMember
        active
        isHide
        isApproveMember
        isApprovePost
        gopy
        type
        createdDate
        createdBy {
          _id
          avatar
          name
          nickname
        }
        subQuestion1
        subQuestion2
        subQuestion3
      }
    }
  `;

  const QUERY_FIND_FANPAGE = gql`
    query($text: String!, $page: Int, $pageSize: Int) {
      findFanpages(text: $text, page: $page, pageSize: $pageSize) {
        total
        page
        pageSize
        data {
          _id
          name
          image
          description
          fields {
            _id
            value
          }
          countMember
          active
          isHide
          isApproveMember
          isApprovePost
          gopy
          type
          createdDate
          createdBy {
            _id
            avatar
            name
            nickname
          }
          subQuestion1
          subQuestion2
          subQuestion3
        }
      }
    }
  `;

  const QUERY_FANPAGE_RELATIONSHIPS_BY_USER = gql`
    query($status: String, $isOwner: Boolean!, $isJoin: Boolean!, $page: Int, $pageSize: Int) {
      getFanpageRelationships(status: $status, isOwner: $isOwner, isJoin: $isJoin, page: $page, pageSize: $pageSize) {
        total
        page
        pageSize
        data {
          _id
          status
          role
          gopy
          fanpage {
            _id
            name
            image
            description
            countMember
            isHide
            isApproveMember
            isApprovePost
            gopy
            active
            type
            createdDate
          }
          createdDate
        }
      }
    }
  `;
//query fanpage relationship của user trong list với fanpage
  const QUERY_FRIEND_FANPAGE_RELATIONSHIPS_BY_USER = gql`
    query($id: String!, $userIds: [String!], $page: Int, $pageSize: Int) {
      getFriendFanpageRelationships(id: $id, userIds: $userIds, page: $page, pageSize: $pageSize) {
        total
        page
        pageSize
        data {
          _id
          status
          gopy
          user {
            _id
          }
        }
      }
    }
  `;

  const QUERY_FANPAGE_RELATIONSHIP_BY_USER = gql`
    query($id: String!) {
      findFanpageRelationship(id: $id) {
        _id
        status
        role
        gopy
        user {
          _id
        }
        fanpage {
          _id
        }
        createdDate
      }
    }
  `;

  const QUERY_POST_IN_ALL_FANPAGES = gql`
    query($sortType: String, $page: Int, $pageSize: Int) {
      getMyPostsInAllFapages(sortType: $sortType, page: $page, pageSize: $pageSize) {
        _id
        text
        image
        audio
        video
        type
        isPublic
        like {
          createdBy {
            _id
          }
        }
        dislike {
          createdBy {
            _id
          }
        }
        bookmark {
          createdBy {
            _id
          }
        }
        comment {
          _id
        }
        background {
          _id
          background
          color
          isGif
        }
        extractURL {
          url
          hostname
          icon
          title
          image
          content
        }
        emotion {
          _id
          content {
            code
            text
          }
          icon
        }
        tag {
          _id
          nickname
          avatar
        }
        answer {
          _id
          text
        }
        answerUser {
          answerId
          createdBy {
            _id
          }
        }
        fanpage {
          _id
          name
          image
        }
        createdDate
        createdBy {
          _id
          nickname
          avatar
        }
        postParent {
          _id
          text
          image
          audio
          video
          isPublic
          background {
            _id
            background
            color
            isGif
          }
          extractURL {
            url
            hostname
            icon
            title
            image
            content
          }
          fanpage {
            _id
            name
            image
          }
          createdBy {
            _id
            nickname
            avatar
          }
          createdDate
        }
      }
    }
  `;

  const QUERY_MY_POST_IN_FANPAGE = gql`
    query($status: Boolean!, $fanpage: String!, $page: Int, $pageSize: Int) {
      getMyPostsInFapage(status: $status, fanpage: $fanpage, page: $page, pageSize: $pageSize) {
        total
        page
        pageSize
        data {
          _id
          text
          image
          audio
          video
          type
          isPublic
          like {
            createdBy {
              _id
            }
          }
          dislike {
            createdBy {
              _id
            }
          }
          bookmark {
            createdBy {
              _id
            }
          }
          comment {
            _id
          }
          background {
            _id
            background
            color
            isGif
          }
          extractURL {
            url
            hostname
            icon
            title
            image
            content
          }
          emotion {
            _id
            content {
              code
              text
            }
            icon
          }
          tag {
            _id
            nickname
            avatar
          }
          answer {
            _id
            text
          }
          answerUser {
            answerId
            createdBy {
              _id
            }
          }
          fanpage {
            _id
            name
            image
          }
          createdDate
          createdBy {
            _id
            nickname
            avatar
          }
        }
      }
    }
  `;

const MUTATION_UPDATE_STATUS_FANPAGE = gql`
  mutation($id: String!, $active: Boolean!) {
    updateStatusFanpage(id: $id, active: $active)
  }
`;

const QUERY_COUNT_POSTS_IN_FANPAGE = gql`
    query($fanpage: String!) {
      countPostsInFanpage(fanpage: $fanpage) {
        countApproval
        countPending
      }
    }
  `;

  const MUTATION_ADD_POST = gql`
    mutation($input: PostInput!) {
      addPost(input: $input) {
        _id
        gopy
      }
    }
  `;

  const MUTATION_EDIT_POST = gql`
    mutation($input: PostInput!) {
      editPost(input: $input) {
        _id
      }
    }
  `;

  const MUTATION_DEL_POST = gql`
    mutation($id: String!) {
      deletePost(id: $id) {
        _id
      }
    }
  `;

  const MUTATION_LIKE_POST = gql`
    mutation($input: LikeInput!) {
      likePost(input: $input) {
        _id
        gopy
      }
    }
  `;

  const MUTATION_DISLIKE_POST = gql`
    mutation($input: LikeInput!) {
      dislikePost(input: $input) {
        _id
        gopy
      }
    }
  `;

  const MUTATION_SHARE_POST = gql`
    mutation($input: ShareInput!) {
      sharePost(input: $input) {
        _id
        gopy
      }
    }
  `;

  const MUTATION_BOOKMARK_POST = gql`
    mutation($postId: String!, $mode: Int) {
      bookmarkPost(postId: $postId, mode: $mode)
    }
  `;

  const MUTATION_APPROVE_OR_REJECT_POST = gql`
    mutation($postId: String!, $mode: Int) {
      approveOrRejectPost(postId: $postId, mode: $mode)
    }
  `;

  const MUTATION_REPORT_POST = gql`
    mutation($postId: String!, $reason: String!) {
      reportPost(postId: $postId, reason: $reason)
    }
  `;


  const MUTATION_EDIT_INFO_FANPAGE = gql`
    mutation($id: String!, $name: String!, $description: String, $subQuestion1: String, $subQuestion2: String, $subQuestion3: String){
      editInfoFanpage(id: $id, name: $name, description: $description, subQuestion1: $subQuestion1, subQuestion2: $subQuestion2, subQuestion3: $subQuestion3)
    }
  `;

  const MUTATION_EDIT_IMAGE_FANPAGE = gql`
    mutation($id: String!, $image: String!){
      editImageFanpage(id: $id, image: $image)
    }
  `;

  const MUTATION_EDIT_HIDE_FANPAGE = gql`
    mutation($id: String!, $isHide: Boolean!){
      editHideFanpage(id: $id, isHide: $isHide)
    }
  `;

  const MUTATION_EDIT_APPROVE_MEMBER_FANPAGE = gql`
    mutation($id: String!, $isApproveMember: Boolean!){
      editApproveMemberFanpage(id: $id, isApproveMember: $isApproveMember)
    }
  `;

  const MUTATION_EDIT_APPROVE_POST_FANPAGE = gql`
  mutation($id: String!, $isApprovePost: Boolean!){
    editApprovePostFanpage(id: $id, isApprovePost: $isApprovePost)
  }
  `;
  

  const MUTATION_EDIT_GOPY_FANPAGE = gql`
    mutation($id: String!, $type: String!, $gopy: Int){
      editTypeFanpage(id: $id, type: $type, gopy: $gopy)
    }
  `;

  const MUTATION_INVITE_FANPAGE = gql`
    mutation($id: String!, $userId: String!, $name: String!) {
      inviteFanpage(id: $id, userId: $userId, name: $name)
    }
  `;
  

  const MUTATION_REQUEST_FANPAGE_RELATIONSHIP = gql`
    mutation($input: FanpageRelationshipInput!) {
      requestFanpageRelationship(input: $input)
    }
  `;
  
  const MUTATION_ACCEPT_FANPAGE_RELATIONSHIP = gql`
    mutation($id: String!, $users: [String!]) {
      acceptFanpageRelationship(id: $id, users: $users)
    }
  `;
  
  const MUTATION_DECLINE_FANPAGE_RELATIONSHIP = gql`
    mutation($id: String!, $users: [String!]) {
      declineFanpageRelationship(id: $id, users: $users)
    }
  `;
  
  const MUTATION_DELETE_FANPAGE_RELATIONSHIP = gql`
    mutation($id: String!, $users: [String!]) {
      removeFanpageRelationship(id: $id, users: $users)
    }
  `;
  
  const MUTATION_LEAVE_FANPAGE_RELATIONSHIP = gql`
    mutation($id: String!) {
      leaveFanpageRelationship(id: $id)
    }
  `;

  const MUTATION_ADD_FANPAGE = gql`
    mutation($input: FanpageInput!) {
      addFanpage(input: $input)
    }
  `;
const FanpageGRAPHQL = {
  QUERY_FANPAGE,
  QUERY_MEMBER_IN_FANPAGE,
  QUERY_FANPAGE_DETAIL,
  QUERY_FIND_FANPAGE,
  QUERY_FRIEND_FANPAGE_RELATIONSHIPS_BY_USER,
  QUERY_FANPAGE_RELATIONSHIPS_BY_USER,
  QUERY_FANPAGE_RELATIONSHIP_BY_USER,
  QUERY_POST_IN_ALL_FANPAGES,
  QUERY_MY_POST_IN_FANPAGE,
  MUTATION_UPDATE_STATUS_FANPAGE,
  QUERY_COUNT_POSTS_IN_FANPAGE,
  MUTATION_ADD_POST,
  MUTATION_EDIT_POST,
  MUTATION_DEL_POST,
  MUTATION_LIKE_POST,
  MUTATION_DISLIKE_POST,
  MUTATION_SHARE_POST,
  MUTATION_BOOKMARK_POST,
  MUTATION_APPROVE_OR_REJECT_POST,
  MUTATION_REPORT_POST,
  MUTATION_EDIT_INFO_FANPAGE,
  MUTATION_EDIT_IMAGE_FANPAGE,
  MUTATION_EDIT_HIDE_FANPAGE,
  MUTATION_EDIT_APPROVE_MEMBER_FANPAGE,
  MUTATION_EDIT_APPROVE_POST_FANPAGE,
  MUTATION_EDIT_GOPY_FANPAGE,
  MUTATION_INVITE_FANPAGE,
  MUTATION_REQUEST_FANPAGE_RELATIONSHIP,
  MUTATION_ACCEPT_FANPAGE_RELATIONSHIP,
  MUTATION_DECLINE_FANPAGE_RELATIONSHIP,
  MUTATION_DELETE_FANPAGE_RELATIONSHIP,
  MUTATION_LEAVE_FANPAGE_RELATIONSHIP,
  MUTATION_ADD_FANPAGE,
};


export default FanpageGRAPHQL;