import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Grid, Collapse, Avatar, IconButton, Menu, MenuItem, Tooltip } from '@material-ui/core'
import { Card, CardHeader, CardContent, CardActions } from '@material-ui/core'
import { List } from '@material-ui/core'
import {
    ThumbUp,
    ThumbDown,
    Comment,
    Share,
    ExpandMore,
    MoreVert,
    AddPhotoAlternate,
    EmojiEmotions,
    DeleteForever,
    NotificationImportant,
    Report,
    Lock
} from '@material-ui/icons'
import { MDBInputGroup, MDBCardImage, MDBIcon } from 'mdbreact'
import ReactPlayer from 'react-player/lazy'

import InputFile from './InputFile'
import ItemComment from './ItemComment'

import MetadataGRAPHQL from '../../graphql/Metadata'
import CommentGRAPHQL from '../../graphql/Comment'
import PostGRAPHQL from '../../graphql/Post'
import { useTracking } from 'react-tracking'
import eventName from '../../common/events'
import ImagesGrid from './ImagesGrid'

const MAX_TEXT_LENGTH = 8000

let timeout = null

const SurVeyPost = ({ item }) => {
    const USER_ID = localStorage.getItem(window.CONSTANT.ID)
    const [answer, setAnswer] = useState(item.answer)
    const [answerUser, setAnswerUser] = useState(item.answerUser)
    const { trackEvent } = useTracking()
    const createTrackingEvent = (event) => {
        return trackEvent({
            name: event,
            createdAt: new Date().toISOString()
        })
    }

    useEffect(() => {
        if (!window.COMMON.isEmpty(item)) {
            setAnswer(item.answer)
            setAnswerUser(item.answerUser)
        }
    }, [item])

    const refetchDataPost = async (postId) => {
        try {
            const params = {
                id: postId
            }
            const result = await window.COMMON.query(PostGRAPHQL.QUERY_POST, params, false)
            if (result && result.data) {
                const post = result.data.getPost
                setAnswer(post.answer)
                setAnswerUser(post.answerUser)
            }
        } catch (error) {
            console.error('🚀 ~ refetchDataPost ~ error:', error)
            window.COMMON.showErrorLogs('MyPostPage.refetchDataPost')
        }
    }

    const handleAnswerPost = async (answerId) => {
        //TODO: handles finding the answer from the answers in the database and notifying the user to choose a different answer
        const foundMyAnswerExisted = answerUser.find(
            (userAns) => userAns.answerId === answerId && userAns.createdBy._id === USER_ID
        )
        if (foundMyAnswerExisted) {
            window.COMMON.showMessage('warning', 'MSG_CODE_093', window.I18N('MSG_CODE_093'))
            return
        }
        //TODO: handle saving the answer to database
        try {
            const params = {
                postId: item._id,
                answerId
            }
            const result = await window.COMMON.mutation(PostGRAPHQL.MUTATION_ANSWER_POST, params, false)
            if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_INSERT)) {
                //TODO: handle refetching data and create tracking
                refetchDataPost(item._id)
                createTrackingEvent(eventName.ANSWER_SURVEY_POST)
            }
        } catch (error) {
            console.error('🚀 ~ handleAnswerPost ~ error:', error)
            window.COMMON.showErrorLogs('ItemPost.handleAnswerPost')
        }
    }

    const calcPercentageForEachAnswer = (ans) => {
        const ansId = ans._id
        const filterUserWithAns = answerUser.filter((userAns) => userAns.answerId === ansId)
        if (window.COMMON.isEmpty(filterUserWithAns)) {
            return 0
        }
        //TODO: Divide the number of user answer by the total answer - return percentage. Example: 50%
        const result = (filterUserWithAns.length / answerUser.length) * 100
        const roundResult = Number.isInteger(result) ? result : result.toFixed(2) // handle for decimal number
        return roundResult
    }

    const handleCheckMyAnswer = (ans) => {
        const ansId = ans._id
        const filterUserWithAns = answerUser.filter((userAns) => userAns.answerId === ansId)
        if (window.COMMON.isEmpty(filterUserWithAns)) {
            return false
        }
        const foundMyAnswer = filterUserWithAns.find((ans) => ans.createdBy._id === USER_ID)
        if (!window.COMMON.isEmpty(foundMyAnswer)) {
            return true
        }
        return false
    }

    return (
        <div className="mt-2 w-100 d-flex flex-column align-items-start justify-content-start" style={{ gap: '8px' }}>
            {answer.map((ans) => (
                <div
                    key={ans._id}
                    className="w-100 px-2 d-flex align-items-center justify-content-start survey-post-item"
                    onClick={() => handleAnswerPost(ans._id)}
                >
                    <div
                        className="percentage-bar"
                        style={{
                            borderRadius: `${calcPercentageForEachAnswer(ans) === 100 ? '8px' : '8px 0 0 8px'}`,
                            backgroundColor: `${handleCheckMyAnswer(ans) ? '#fdc101' : '#607c88'}`,
                            width: `${calcPercentageForEachAnswer(ans)}%`
                        }}
                    ></div>
                    <div className="d-flex align-items-center justify-content-center percentage-bar-number">
                        {`${calcPercentageForEachAnswer(ans)}%`}
                    </div>
                    {handleCheckMyAnswer(ans) ? (
                        <MDBIcon far={true} icon="check-circle" className="fa-fw fa-lg icon-center" />
                    ) : (
                        <MDBIcon far={true} icon="circle" className="fa-fw fa-lg icon-center" />
                    )}
                    <span className="font-weight-bold" style={{ fontSize: '0.8rem' }}>
                        {ans.text}
                    </span>
                </div>
            ))}
        </div>
    )
}

const ItemPost = React.memo(function ItemPost(props) {
    const USER_ID = localStorage.getItem(window.CONSTANT.ID)
    const COMPANY_ID = localStorage.getItem(window.CONSTANT.COMPANY_ID)
    const COMPANY_TYPE = localStorage.getItem(window.CONSTANT.COMPANY_TYPE)

    const history = useHistory()
    const [expanded, setExpanded] = React.useState(false)
    const [background, setBackground] = React.useState(null)
    const [images, setImages] = React.useState([])
    const [itemIndex, setItemIndex] = React.useState(0)
    const [anchorEl, setAnchorEl] = React.useState(null)
    const [showTagMembers, setShowTagMembers] = React.useState(null)
    const [like, setLike] = React.useState(false)
    const [dislike, setDislike] = React.useState(false)
    const [isFirstLoadComment, setIsFirstLoadComment] = React.useState(false)
    const [dataComment, setDataComment] = React.useState([])
    const [dataSave, setDataSave] = React.useState({})
    const [reply, setReply] = React.useState(null)
    const [dataSticker, setDataSticker] = React.useState([])
    const [codeSticker, setCodeSticker] = React.useState(null)

    useLayoutEffect(() => {
        setLike(checkLikeOrDislike('like'))
        setDislike(checkLikeOrDislike('dislike'))
        if (props.item && props.item.background) {
            const item = window.COMMON.getValueFromAttr(props.item, 'background')
            if (item.isGif) {
                const images = [item.background]
                setImages(images)
                setBackground(null)
            } else {
                setBackground(item)
            }
        } else {
            setImages(window.COMMON.getValueFromAttr(props.item, props.attr_image, []))
        }
        if (props.categorySticker && props.categorySticker.length > 0) {
            const code = window.COMMON.getValueFromAttr(props.categorySticker, '0.code')
            setCodeSticker(code)
            getDataSticker(code)
        }
        // eslint-disable-next-line
    }, [props.item, props.attr_image, props.categorySticker])

    const { trackEvent } = useTracking()
    // create tracking event
    const createTrackingEvent = (event) => {
        return trackEvent({
            name: event,
            createdAt: new Date().toISOString()
        })
    }

    const handleExpandClick = () => {
        setExpanded(!expanded)
        if (!isFirstLoadComment) {
            setIsFirstLoadComment(true)
            getDataComment()
        }
    }

    const handleClickListItem = (event, index) => {
        setAnchorEl(event.currentTarget)
        setItemIndex(index)
    }

    const handleMenuItemClick = (event, type) => {
        event.preventDefault()
        event.stopPropagation()
        setAnchorEl(null)
        if (type === 'DELETE' && props.handleDeletePost && props.item.createdBy._id === USER_ID) {
            props.handleDeletePost(itemIndex)
        }
        if (type === 'PUSH_NOTIFICATION' && props.handlePushNotificationPost) {
            props.handlePushNotificationPost(itemIndex)
        }
        if (type === 'REPORT_POST' && props.handleReportPost && props.item.createdBy._id !== USER_ID) {
            props.handleReportPost(itemIndex)
        }
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const checkLikeOrDislike = (attr) => {
        const arrs = window.COMMON.getValueFromAttr(props.item, attr)
        if (arrs && arrs.length > 0) {
            const obj = window.COMMON.getObjectInArrs(USER_ID, arrs, 'createdBy._id')
            if (obj) {
                return true
            }
        }
        return false
    }

    // TODO like or dislike
    const handleLikeOrDislike = (attr) => {
        const check = checkLikeOrDislike(attr)
        const arrs = window.COMMON.getValueFromAttr(props.item, attr)
        if (check) {
            window.COMMON.removeObjectInArrs(USER_ID, arrs, 'createdBy._id')
        } else {
            const obj = {
                createdBy: { _id: USER_ID }
            }
            arrs.push(obj)
        }
        if (attr === 'like') {
            const dislikes = window.COMMON.getValueFromAttr(props.item, 'dislike')
            window.COMMON.removeObjectInArrs(USER_ID, dislikes, 'createdBy._id')
            setLike(!check)
            setDislike(false)
        } else {
            const likes = window.COMMON.getValueFromAttr(props.item, 'like')
            window.COMMON.removeObjectInArrs(USER_ID, likes, 'createdBy._id')
            setDislike(!check)
            setLike(false)
        }
        if (timeout) {
            clearTimeout(timeout)
            timeout = null
        }
        timeout = setTimeout(() => {
            if (attr === 'like') {
                likePost(!check)
            } else {
                dislikePost(!check)
            }
        }, 500)
    }

    const likePost = async (check) => {
        try {
            const params = {
                input: {
                    postId: props.item._id,
                    company: COMPANY_TYPE === window.CONSTANT.COMPANY ? window.COMMON.getValue(COMPANY_ID) : null,
                    union: COMPANY_TYPE === window.CONSTANT.UNION ? window.COMMON.getValue(COMPANY_ID) : null,
                    influencer: COMPANY_TYPE === window.CONSTANT.INFLUENCER ? window.COMMON.getValue(COMPANY_ID) : null,
                    mode: check ? 1 : -1
                }
            }
            await window.COMMON.mutation(PostGRAPHQL.MUTATION_LIKE_POST, params, false)
            createTrackingEvent(eventName.LIKE_POST)
        } catch (error) {
            window.COMMON.showErrorLogs('ItemPost.likePost')
        }
    }

    const dislikePost = async (check) => {
        try {
            const params = {
                input: {
                    postId: props.item._id,
                    company: COMPANY_TYPE === window.CONSTANT.COMPANY ? window.COMMON.getValue(COMPANY_ID) : null,
                    union: COMPANY_TYPE === window.CONSTANT.UNION ? window.COMMON.getValue(COMPANY_ID) : null,
                    influencer: COMPANY_TYPE === window.CONSTANT.INFLUENCER ? window.COMMON.getValue(COMPANY_ID) : null,
                    mode: check ? 1 : -1
                }
            }
            await window.COMMON.mutation(PostGRAPHQL.MUTATION_DISLIKE_POST, params, false)
            createTrackingEvent(eventName.DISLIKE_POST)
        } catch (error) {
            window.COMMON.showErrorLogs('ItemPost.dislikePost')
        }
    }

    // TODO share
    const doShare = () => {
        if (props.handleSharePost) {
            props.handleSharePost(props.item)
        }
    }

    // TODO comment
    const doComment = () => {
        document.getElementById(`input-comment-${props.item._id}`).focus()
    }

    const removeReply = () => {
        setReply(null)
    }

    const handleReply = useCallback((reply) => {
        doComment()
        setReply(reply)
        // eslint-disable-next-line
    }, [])

    const handleHideComment = useCallback(async (item) => {
        try {
            // TODO save data
            const params = {
                id: item._id
            }
            const result = await window.COMMON.mutation(CommentGRAPHQL.MUTATION_DEL_COMMENT, params)
            if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_DELETE)) {
                getDataComment()
                createTrackingEvent(eventName.DEL_COMMENT)
            }
        } catch (error) {
            window.COMMON.showErrorLogs('ItemPost.hideCommentPost')
        }
        // eslint-disable-next-line
    }, [])

    const handleChange = (event) => {
        const text = event.target.value
        if (text.length >= MAX_TEXT_LENGTH) {
            window.COMMON.showMessage(
                'warning',
                'MSG_CODE_083',
                window.I18N('MSG_CODE_083', { length: MAX_TEXT_LENGTH })
            )
            setDataSave((prev) => ({ ...prev, text: text.slice(0, MAX_TEXT_LENGTH) }))
            return
        }
        setDataSave((prev) => ({ ...prev, text }))
    }

    const handleFile = (file) => {
        dataSave.file = file
        dataSave.image = window.COMMON.createBlobImage(file)
        dataSave.sticker = ''
        setDataSave({ ...dataSave })
    }

    const chooseCategorySticker = (value) => {
        setCodeSticker(value)
        getDataSticker(value)
    }

    const chooseSticker = (value) => {
        dataSave.file = null
        dataSave.image = ''
        dataSave.sticker = value
        document.getElementById(`file-comment-${props.item._id}`).value = null
        window.COMMON.hidePopup(`#btn-sticker-${props.item._id}`)
        setDataSave({ ...dataSave })
    }

    const removeImage = () => {
        dataSave.file = null
        dataSave.image = ''
        document.getElementById(`file-comment-${props.item._id}`).value = null
        setDataSave({ ...dataSave })
    }

    const removeSticker = () => {
        dataSave.sticker = ''
        setDataSave({ ...dataSave })
    }

    const goToAccountDetail = (item) => {
        const creator = window.COMMON.getValueFromAttr(item, 'createdBy._id')
        if (props.enableGoToAccount && USER_ID !== creator) {
            history.push('/profile?id=' + creator)
        }
    }

    const goToFanpageDetail = (item) => {
        const fanpage = window.COMMON.getValueFromAttr(item, 'fanpage._id')
        if (fanpage) {
            history.push('/my-fanpage?' + fanpage)
        }
    }

    const checkSendComment = () => {
        if (dataSave.text || dataSave.file || dataSave.sticker) {
            return true
        }
        return false
    }

    const getDataComment = async () => {
        try {
            const params = {
                postId: props.item._id
            }
            const result = await window.COMMON.query(CommentGRAPHQL.QUERY_COMMENT, params, false)
            if (result && result.data) {
                const data = window.COMMON.formatDate(result.data.getComments, 'createdDate', 'DD MMM YYYY')
                setDataComment(data)
            }
        } catch (error) {
            window.COMMON.showErrorLogs('ItemPost.getDataComment')
        }
    }

    // STICKER
    const getDataSticker = async (code) => {
        try {
            const params = {
                parentCode: code
            }
            const result = await window.COMMON.query(MetadataGRAPHQL.QUERY_STICKERS, params, false)
            let data = []
            if (result && result.data) {
                data = result.data.getStickers
            }
            setDataSticker(data)
        } catch (error) {
            window.COMMON.showErrorLogs('ItemPost.getDataSticker')
        }
    }

    const commentPost = async () => {
        let dataFiles = []
        try {
            // TODO upload file
            if (dataSave.file) {
                dataFiles = await window.uploadFile([dataSave.file])
                if (dataFiles.length > 0) {
                    dataSave.image = window.COMMON.getNewPathFile(dataFiles[0]._id)
                }
            }
            dataSave.file = null
            // TODO save data
            const params = {
                input: {
                    company: COMPANY_TYPE === window.CONSTANT.COMPANY ? window.COMMON.getValue(COMPANY_ID) : null,
                    union: COMPANY_TYPE === window.CONSTANT.UNION ? window.COMMON.getValue(COMPANY_ID) : null,
                    influencer: COMPANY_TYPE === window.CONSTANT.INFLUENCER ? window.COMMON.getValue(COMPANY_ID) : null,
                    postId: props.item._id,
                    commentId: reply ? reply.parentId : null,
                    text: dataSave.text,
                    image: dataSave.image,
                    sticker: dataSave.sticker
                }
            }
            const result = await window.COMMON.mutation(CommentGRAPHQL.MUTATION_ADD_COMMENT, params)
            if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_INSERT)) {
                dataSave.text = ''
                dataSave.image = ''
                dataSave.file = null
                dataSave.sticker = ''
                document.getElementById(`file-comment-${props.item._id}`).value = null
                const comments = window.COMMON.getValueFromAttr(props.item, 'comment')
                comments.push({ _id: result.data.addComment.id })
                setDataSave(dataSave)
                setReply(null)
                getDataComment()
                createTrackingEvent(eventName.ADD_COMMENT)
            }
        } catch (error) {
            window.UPLOAD_API.deleteMultiFile(dataFiles.map(item => item._id))           
            window.COMMON.showErrorLogs('ItemPost.commentPost')
        }
    }

    const htmlReplyComment = (reply, parentId) => {
        if (reply && reply.length > 0) {
            const data = window.COMMON.formatDate(reply, 'createdDate', 'DD MMM YYYY')
            return (
                <div className="container-list-reply">
                    {data.map((item) => (
                        <ItemComment
                            item={item}
                            key={item._id}
                            postId={props.item._id}
                            postCreator={window.COMMON.getValueFromAttr(props.item, 'createdBy._id')}
                            parentId={parentId}
                            handleReply={handleReply}
                            handleHideComment={handleHideComment}
                        ></ItemComment>
                    ))}
                </div>
            )
        }
        return <div></div>
    }

    const htmlComment = () => {
        return (
            <List className="item-comment">
                {dataComment.map((item) => (
                    <ItemComment
                        item={item}
                        key={item._id}
                        postId={props.item._id}
                        postCreator={window.COMMON.getValueFromAttr(props.item, 'createdBy._id')}
                        parentId={item._id}
                        handleReply={handleReply}
                        handleHideComment={handleHideComment}
                    >
                        {htmlReplyComment(item.reply, item._id)}
                    </ItemComment>
                ))}
            </List>
        )
    }

    const generateContentComment = (item) => {
        if (item.image) {
            return (
                <div>
                    <div className="w-100">
                        <MDBCardImage
                            cascade
                            waves
                            src={window.COMMON.setValueImage(item.image)}
                            className="image-comment mt-2"
                        />
                    </div>
                    <span>{window.COMMON.getValueFromAttr(item, 'text')}</span>
                </div>
            )
        }
        if (item.sticker) {
            return (
                <div>
                    <div className="w-100">
                        <MDBCardImage
                            cascade
                            waves
                            src={window.COMMON.setValueImage(item.sticker)}
                            className="icon-sticker mt-2"
                        />
                    </div>
                    <span>{window.COMMON.getValueFromAttr(item, 'text')}</span>
                </div>
            )
        }
        return <span>{window.COMMON.getValueFromAttr(item, 'text')}</span>
    }

    if (typeof window === 'undefined') {
        return null
    }
    return (
        <>
            <div className={'item-post ' + window.COMMON.getValue(props.className)} style={{ zIndex: 10 }}>
                <Card style={{ boxShadow: props.isView && props.fanpagePost ? 'none' : '' }}>
                    <CardHeader
                        avatar={
                            <Avatar
                                aria-label="recipe"
                                src={window.COMMON.getValueFromAttr(props.item, props.attr_avatar)}
                                className="icon-avatar"
                            ></Avatar>
                        }
                        action={
                            props.isShare || props.isView ? (
                                <></>
                            ) : (
                                <>
                                    <IconButton
                                        aria-controls={`menu-item-post-${props.index}`}
                                        aria-label="settings"
                                        aria-haspopup="true"
                                        onClick={(event) => handleClickListItem(event, props.index)}
                                    >
                                        <MoreVert />
                                    </IconButton>
                                    <Menu
                                        id={`menu-item-post-${props.index}`}
                                        keepMounted
                                        anchorEl={anchorEl}
                                        open={Boolean(anchorEl)}
                                        onClose={handleClose}
                                    >
                                        {!props.fanpagePost && (
                                            <MenuItem
                                                onClick={(event) => handleMenuItemClick(event, 'PUSH_NOTIFICATION')}
                                            >
                                                <NotificationImportant /> {window.I18N('push_notification')}
                                            </MenuItem>
                                        )}
                                        {props.fanpagePost && props.item.createdBy._id !== USER_ID && (
                                            <MenuItem onClick={(event) => handleMenuItemClick(event, 'REPORT_POST')}>
                                                <Report />
                                                &nbsp; {window.I18N('report')}
                                            </MenuItem>
                                        )}
                                        {props.item.createdBy._id === USER_ID && (
                                            <MenuItem onClick={(event) => handleMenuItemClick(event, 'DELETE')}>
                                                <DeleteForever /> {window.I18N('btn_delete')}
                                            </MenuItem>
                                        )}
                                    </Menu>
                                </>
                            )
                        }
                        title={
                            window.COMMON.getValueFromAttr(props.item, 'fanpage.name', '') ? (
                                <span
                                    style={{ fontWeight: 500 }}
                                    className="cursor-pointer"
                                    onClick={() => goToFanpageDetail(props.item)}
                                >
                                    {window.COMMON.getValueFromAttr(props.item, 'fanpage.name', '')}
                                </span>
                            ) : (
                                <>
                                    <div>
                                        <span
                                            className="font-weight-bold mb-0 cursor-pointer"
                                            onClick={() => goToAccountDetail(props.item)}
                                        >
                                            {window.COMMON.getValueFromAttr(props.item, props.attr_name)}
                                        </span>
                                        {window.COMMON.getValueFromAttr(props.item, 'tag', []).length === 1 && (
                                            <span className="">
                                                {` ${window.I18N('with')} `}
                                                <strong
                                                    className="mb-0 cursor-pointer"
                                                    onClick={() =>
                                                        goToAccountDetail({
                                                            createdBy: {
                                                                _id: window.COMMON.getValueFromAttr(
                                                                    props.item,
                                                                    'tag',
                                                                    []
                                                                )[0]._id
                                                            }
                                                        })
                                                    }
                                                >
                                                    {window.COMMON.getValueFromAttr(props.item, 'tag', [])[0]?.nickname}
                                                </strong>
                                            </span>
                                        )}
                                        {!window.COMMON.isEmpty(
                                            window.COMMON.getValueFromAttr(props.item, 'tag', [])
                                        ) &&
                                            window.COMMON.getValueFromAttr(props.item, 'tag', []).length > 1 && (
                                                <span className="">
                                                    {` ${window.I18N('with')} `}
                                                    <strong
                                                        className="mb-0 cursor-pointer"
                                                        onClick={() =>
                                                            goToAccountDetail({
                                                                createdBy: {
                                                                    _id: window.COMMON.getValueFromAttr(
                                                                        props.item,
                                                                        'tag',
                                                                        []
                                                                    )[0]._id
                                                                }
                                                            })
                                                        }
                                                    >
                                                        {
                                                            window.COMMON.getValueFromAttr(props.item, 'tag', [])[0]
                                                                ?.nickname
                                                        }
                                                    </strong>
                                                    {` ${window.I18N('and')} `}
                                                    <strong
                                                        aria-controls={`menu-tag-members-${props.index}`}
                                                        aria-label="settings"
                                                        aria-haspopup="true"
                                                        className="mb-0 cursor-pointer"
                                                        onClick={(event) => setShowTagMembers(event.currentTarget)}
                                                    >{`${
                                                        window.COMMON.getValueFromAttr(props.item, 'tag', []).length - 1
                                                    } ${window.I18N('tag_others')}`}</strong>
                                                </span>
                                            )}
                                    </div>
                                    <Menu
                                        id={`menu-tag-members-${props.index}`}
                                        keepMounted
                                        anchorEl={showTagMembers}
                                        open={Boolean(showTagMembers)}
                                        onClose={() => setShowTagMembers(null)}
                                    >
                                        {window.COMMON.getValueFromAttr(props.item, 'tag', []).map(
                                            (tag, index) =>
                                                index > 0 && (
                                                    <MenuItem
                                                        key={index}
                                                        onClick={() =>
                                                            goToAccountDetail({ createdBy: { _id: tag._id } })
                                                        }
                                                    >
                                                        <Avatar
                                                            alt={tag.nickname}
                                                            src={tag.avatar}
                                                            style={{ width: '28px', height: '28px' }}
                                                        />
                                                        <span className="ml-2">{tag.nickname}</span>
                                                    </MenuItem>
                                                )
                                        )}
                                    </Menu>
                                </>
                            )
                        }
                        subheader={
                            window.COMMON.getValueFromAttr(props.item, 'fanpage.name', '') ? (
                                <>
                                    <span
                                        style={{ fontSize: '0.8rem' }}
                                        className=" font-weight-bolder cursor-pointer"
                                        onClick={() => goToAccountDetail(props.item)}
                                    >
                                        {' '}
                                        {window.COMMON.getValueFromAttr(props.item, props.attr_name) + ' · '}
                                    </span>
                                    {window.COMMON_DATE.isValidDate(
                                        window.COMMON.getValueFromAttr(props.item, props.attr_time)
                                    )
                                        ? window.COMMON_DATE.formatDate(
                                              window.COMMON.getValueFromAttr(props.item, props.attr_time),
                                              'DD-MM-YYYY HH:mm:ss'
                                          )
                                        : window.COMMON.getValueFromAttr(props.item, props.attr_time)}
                                    {props.item?.isPublic === false && (
                                        <Lock
                                            color="disabled"
                                            style={{ fontSize: '14px' }}
                                            className="mb-1 ml-1"
                                        ></Lock>
                                    )}
                                </>
                            ) : (
                                <>
                                    {window.COMMON_DATE.isValidDate(
                                        window.COMMON.getValueFromAttr(props.item, props.attr_time)
                                    )
                                        ? window.COMMON_DATE.formatDate(
                                              window.COMMON.getValueFromAttr(props.item, props.attr_time),
                                              'DD-MM-YYYY HH:mm:ss'
                                          )
                                        : window.COMMON.getValueFromAttr(props.item, props.attr_time)}
                                    {props.item?.isPublic === false && (
                                        <Lock
                                            color="disabled"
                                            style={{ fontSize: '14px' }}
                                            className="mb-1 ml-1"
                                        ></Lock>
                                    )}
                                </>
                            )
                        }
                        /* title={
            <p className="font-weight-bold mb-0 cursor-pointer" onClick={() => goToAccountDetail(props.item)}>
              {window.COMMON.getValueFromAttr(props.item, props.attr_name) + (window.COMMON.getValueFromAttr(props.item, 'fanpage.name', '') ? ' · ' + window.COMMON.getValueFromAttr(props.item, 'fanpage.name', '') : '')}
            </p>
          }
          subheader={window.COMMON_DATE.formatDate(window.COMMON.getValueFromAttr(props.item, props.attr_time), 'DD MMM YYYY HH:mm:ss')} */
                    />
                    <CardContent className="pt-2 pb-2">
                        {background ? (
                            <div className="content mb-2 position-relative" style={{ height: '300px' }}>
                                <div className="position-absolute top-0 lef-0 w-100 h-100">
                                    <img
                                        src={background.background}
                                        alt={background.name}
                                        className="w-100 h-100"
                                    ></img>
                                </div>
                                <div
                                    className="position-absolute d-flex align-items-center justify-content-center w-100 h-100 bg-transparent text-center p-4"
                                    style={{ zIndex: 1 }}
                                >
                                    <p
                                        className="font-weight-bold m-0"
                                        dangerouslySetInnerHTML={{
                                            __html: window.COMMON.getValueHTML(props.item, props.attr_content)
                                        }}
                                        style={{ color: background.color, fontSize: '18px' }}
                                    ></p>
                                </div>
                            </div>
                        ) : (
                            <div
                                className="content mb-2"
                                dangerouslySetInnerHTML={{
                                    __html: window.COMMON.getValueHTML(props.item, props.attr_content)
                                }}
                            ></div>
                        )}
                        {props.item && props.item.audio ? (
                            <div className="d-flex w-100 justify-content-center">
                                <ReactPlayer url={props.item.audio} controls={true} height={60}></ReactPlayer>
                            </div>
                        ) : (
                            <></>
                        )}
                        {props.item && props.item.video ? (
                            <div className="d-flex w-100 justify-content-center">
                                <ReactPlayer id="video" url={props.item.video} controls={true}></ReactPlayer>
                            </div>
                        ) : (
                            <ImagesGrid images={images} />
                        )}
                        {props.item && props.item.type === 'SHARE' ? (
                            <ItemPost
                                item={window.COMMON.getValueFromAttr(props.item, 'postParent')}
                                index={0}
                                isShare={true}
                                className="mt-3 mb-0"
                                attr_id="_id"
                                attr_content="text"
                                attr_image="image"
                                attr_avatar="createdBy.avatar"
                                attr_name="createdBy.nickname"
                                attr_time="createdDate"
                            ></ItemPost>
                        ) : (
                            <></>
                        )}
                        {/* Add survey post */}
                        {props.item.type === 'SURVEY_POST' && <SurVeyPost item={props.item} />}
                    </CardContent>
                    {props.isShare ? (
                        <></>
                    ) : (
                        <CardActions disableSpacing>
                            <Grid container>
                                <Grid item xs={12}>
                                    <div className="d-flex flex-row align-items-center pl-2 pr-2">
                                        <IconButton
                                            aria-label="like"
                                            onClick={props.isView ? () => {} : () => handleLikeOrDislike('like')}
                                            className="mr-xl-3"
                                        >
                                            <ThumbUp color={like ? 'primary' : 'disabled'} fontSize="small" />{' '}
                                            <small className="ml-1 grey-text" style={{ fontSize: '14px' }}>
                                                {props.item.like.length}
                                            </small>
                                        </IconButton>
                                        <IconButton
                                            aria-label="dislike"
                                            onClick={props.isView ? () => {} : () => handleLikeOrDislike('dislike')}
                                            className="mr-xl-3"
                                        >
                                            <ThumbDown color={dislike ? 'primary' : 'disabled'} fontSize="small" />{' '}
                                            <small className="ml-1 grey-text" style={{ fontSize: '14px' }}>
                                                {props.item.dislike.length}
                                            </small>
                                        </IconButton>
                                        <IconButton
                                            aria-label="comment"
                                            onClick={props.isView ? () => {} : () => doComment()}
                                            className="mr-xl-3"
                                        >
                                            <Comment color="disabled" fontSize="small" />{' '}
                                            <small className="ml-1 grey-text" style={{ fontSize: '14px' }}>
                                                {props.item.comment.length}
                                            </small>
                                        </IconButton>
                                        {props.isView ? (
                                            <></>
                                        ) : (
                                            <IconButton
                                                aria-label="share"
                                                className="mr-auto"
                                                onClick={() => doShare()}
                                            >
                                                <Share color="disabled" fontSize="small" />
                                            </IconButton>
                                        )}
                                        {window.COMMON.getValueFromAttr(props.item, 'people', []).map((person, j) => (
                                            <div key={j * props.index + j} className="container-item-person" xs={3}>
                                                <Tooltip title={window.COMMON.getValueFromAttr(person, 'nickname')}>
                                                    {j < 3 ? (
                                                        <Avatar
                                                            src={window.COMMON.getValueFromAttr(person, 'avatar')}
                                                            className="icon-avatar"
                                                            style={{ zIndex: 4 - j }}
                                                        ></Avatar>
                                                    ) : j === 3 ? (
                                                        <Avatar className="icon-avatar icon-last" style={{ zIndex: 1 }}>
                                                            +
                                                            {window.COMMON.getValueFromAttr(props.item, 'people', [])
                                                                .length - 3}
                                                        </Avatar>
                                                    ) : (
                                                        <div></div>
                                                    )}
                                                </Tooltip>
                                            </div>
                                        ))}
                                        {props.isView ? (
                                            <></>
                                        ) : (
                                            <IconButton
                                                onClick={handleExpandClick}
                                                aria-expanded={expanded}
                                                aria-label="show more"
                                            >
                                                <ExpandMore />
                                            </IconButton>
                                        )}
                                    </div>
                                </Grid>
                            </Grid>
                        </CardActions>
                    )}
                    {props.isShare || props.isView ? (
                        <></>
                    ) : (
                        <Collapse in={expanded} timeout="auto" unmountOnExit>
                            <p className="mb-0 pl-4">
                                <small className="font-weight-bold">
                                    {window.I18N('comments') + ' (' + dataComment.length + ')'}
                                </small>
                            </p>
                            <CardContent className="p-0 pl-3 pr-3">{htmlComment()}</CardContent>
                        </Collapse>
                    )}
                    {props.isShare || props.isView ? (
                        <></>
                    ) : (
                        <Grid item xs={12} className="p-3 pt-2">
                            <div className="d-flex bd-highlight">
                                <div className="bd-highlight">
                                    <InputFile
                                        id={`file-comment-${props.item._id}`}
                                        icon="upload"
                                        handleFile={handleFile}
                                        accept="image/*"
                                    >
                                        <AddPhotoAlternate color="primary" />
                                    </InputFile>
                                    <div className="d-inline-block container-filter container-filter-sticker">
                                        <IconButton
                                            aria-label="add emoji"
                                            id={'btn-sticker-' + props.item._id}
                                            data-toggle="collapse"
                                            data-target={'#collapse-' + props.item._id}
                                            aria-expanded="false"
                                            aria-controls={'collapse-' + props.item._id}
                                        >
                                            <EmojiEmotions color="primary" />
                                        </IconButton>
                                        <div
                                            className="collapse container-filter-content"
                                            style={{ width: '400px' }}
                                            id={'collapse-' + props.item._id}
                                        >
                                            <div style={{ height: '240px', overflow: 'auto' }}>
                                                <Grid container className="pl-2 pr-2">
                                                    {dataSticker.map((item) => (
                                                        <Grid
                                                            item
                                                            xs={2}
                                                            key={item._id}
                                                            className="text-center"
                                                            onClick={() => chooseSticker(item.icon)}
                                                        >
                                                            <MDBCardImage
                                                                cascade
                                                                waves
                                                                src={item.icon}
                                                                className="icon-sticker-thumb"
                                                            />
                                                        </Grid>
                                                    ))}
                                                </Grid>
                                            </div>
                                            <hr className="mb-2"></hr>
                                            <div className="d-flex flex-wrap align-items-center p-2">
                                                {props.categorySticker.map((item) => (
                                                    <div
                                                        className={
                                                            'item-category-sticker text-center m-1' +
                                                            (item.code === codeSticker ? ' active' : '')
                                                        }
                                                        key={item._id}
                                                        onClick={() => chooseCategorySticker(item.code)}
                                                    >
                                                        <MDBCardImage
                                                            cascade
                                                            waves
                                                            src={item.icon}
                                                            className="icon-sticker-small-thumb"
                                                        />
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                    <br></br>
                                </div>
                                <div className="p-1 flex-fill bd-highlight">
                                    <MDBInputGroup
                                        id={`input-comment-${props.item._id}`}
                                        type="text"
                                        className="m-0"
                                        hint={window.I18N('write_your_comment')}
                                        value={dataSave.text}
                                        onChange={handleChange}
                                        maxLength="5000"
                                        pattern="\S(.*\S)?"
                                        required
                                        append={
                                            <span
                                                className="input-group-text"
                                                onClick={checkSendComment() ? () => commentPost() : () => {}}
                                            >
                                                <MDBIcon
                                                    fa="true"
                                                    icon="paper-plane"
                                                    className="fa-fw"
                                                    style={{ color: checkSendComment() ? '#8457FF' : '#c2c2c2' }}
                                                ></MDBIcon>
                                            </span>
                                        }
                                    />
                                    {dataSave.image ? (
                                        <div
                                            className="position-relative item-image-comment mt-2"
                                            style={{ width: '130px' }}
                                        >
                                            <MDBCardImage
                                                cascade
                                                waves
                                                src={window.COMMON.setValueImage(dataSave.image)}
                                                className="image-comment-thumb"
                                            ></MDBCardImage>
                                            <div className="container-icon-center">
                                                <MDBIcon
                                                    fa="true"
                                                    icon="trash-alt"
                                                    className="fa-fw fa-lg icon-center"
                                                    onClick={() => removeImage()}
                                                />
                                            </div>
                                        </div>
                                    ) : (
                                        <div></div>
                                    )}
                                    {dataSave.sticker ? (
                                        <div
                                            className="position-relative item-image-comment mt-2"
                                            style={{ width: '65px' }}
                                        >
                                            <MDBCardImage
                                                cascade
                                                waves
                                                src={window.COMMON.setValueImage(dataSave.sticker)}
                                                className="icon-sticker-thumb"
                                            />
                                            <div className="container-icon-center">
                                                <MDBIcon
                                                    fa="true"
                                                    icon="trash-alt"
                                                    className="fa-fw fa-lg icon-center"
                                                    onClick={() => removeSticker()}
                                                />
                                            </div>
                                        </div>
                                    ) : (
                                        <div></div>
                                    )}
                                    {reply ? (
                                        <div className="container-reply w-100 p-2 mt-1">
                                            <div className="d-flex w-100 mb-1">
                                                <div className="mr-auto">
                                                    <span>
                                                        <MDBIcon fa="true" icon="share" className="fa-fw mr-1" />
                                                        <span>{window.I18N('reply_to')} </span>
                                                        <span className="font-weight-bold">
                                                            {window.COMMON.getValueFromAttr(
                                                                reply,
                                                                'createdBy.nickname'
                                                            )}
                                                        </span>
                                                    </span>
                                                </div>
                                                <MDBIcon
                                                    fa="true"
                                                    icon="times"
                                                    className="fa-fw cursor-pointerer"
                                                    style={{ color: 'gray' }}
                                                    onClick={() => removeReply()}
                                                />
                                            </div>
                                            <div className="media d-flex align-items-center">
                                                <Avatar
                                                    alt=""
                                                    src={window.COMMON.getValueFromAttr(reply, 'createdBy.avatar')}
                                                    className="icon-avatar mr-2"
                                                />
                                                <div className="media-body media-middle">
                                                    {generateContentComment(reply)}
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div></div>
                                    )}
                                </div>
                            </div>
                        </Grid>
                    )}
                </Card>
            </div>
        </>
    )
})

const mapStateToProps = (state) => ({
    language: state.language.value
})
const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(ItemPost)
