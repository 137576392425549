import React, { useEffect, useImperativeHandle, useState } from 'react'
import ReactSelect from 'react-select'
import AccountGRAPHQL from '../../../graphql/Account'

const SelectOrganization = React.forwardRef((props, ref) => {
    const [options, setOptions] = useState({
        companies: []
    })
    const [select, setSelect] = useState({ companies: { value: null, code: null, label: null },  })

    useEffect(() => {
        const getAccountInfoCompany = async () => {
            const ACCOUNT_ID = localStorage.getItem(window.CONSTANT.ID)
            await window.COMMON.requestGraphQL({
                type: 'query', 
                apiName: AccountGRAPHQL.QUERY_ACCOUNT_BY_ID, 
                params: { id: ACCOUNT_ID }, 
                successCallback: (result) => {
                    const dataCompany = result.data.findAccountById
                    const companiyFormat = {
                        _id: dataCompany.company?._id || dataCompany.influencer?._id || dataCompany.union?._id,
                        name: dataCompany.name,
                        accountType: dataCompany.accountType
                    }
                    const group_companies = window.COMMON.createDataSelectWithGroup([companiyFormat], '_id', 'name', 'accountType', 'accountType');
                    options.companies = group_companies
                    select.companies = [group_companies[0].options[0]]
                    setOptions({ ...options })
                    setSelect({ ...select })
                }, 
                logMessage: "🚀 ~ getAccountsGoverment ~ getAccountInfoCompany"
            })
        }
        const getAccountsGoverment = async () => {
            await window.COMMON.requestGraphQL({
                type: 'query', 
                apiName: AccountGRAPHQL.QUERY_COMPANY_BY_GOVERMENT, 
                params: { input: {} }, 
                successCallback: (result) => {
                    const formatData = result?.data?.getCompanyAccountsByGoverment.map(account => ({
                        _id: account.company?._id || account.influencer?._id || account.union?._id,
                        name: account.name,
                        accountType: account.accountType
                    }))
                    const allCompanies = { value: 'all', label: window.I18N('all_companies'), code: window.I18N('all_companies') }
                    const group_companies = [{label: window.I18N('all_companies'), value: 'all', options: [allCompanies]}, ...window.COMMON.createDataSelectWithGroup(formatData, '_id', 'name', 'accountType', 'accountType')]
                    options.companies = group_companies
                    select.companies = [group_companies[0].options[0]]
                    setOptions({ ...options })    
                    setSelect({ ...select })
                }, 
                logMessage: "🚀 ~ getAccountsGoverment ~ getAccountsGoverment"
            })
        }
        window.COMMON.checkRoleIsSystemOrInfluencer() ? getAccountsGoverment() : getAccountInfoCompany()      
  }, [])

  const handleSelect = (event) => {
    const allValueIndex = event ? event.findIndex((item) => item.value === 'all') : -1;
      let newEvent;
      if (allValueIndex >= 0) {
        if (allValueIndex === 0 && event.length > 1) {
          event.splice(allValueIndex, 1);
          newEvent = event;
        }
        else if (allValueIndex > 0) {
          newEvent = [event[allValueIndex]];
        }
        else {
          newEvent = event;
        }
        select.companies = newEvent;
      }else{
        select.companies = event
      }
      setSelect((current) => {
        return {
          ...current,
          ...select
        };
      });   
  }


  useImperativeHandle(ref, () => ({
    getDataSelect: () => {
        const isAllCompanies = select.companies.find(data => data.value === 'all')
        if(!window.COMMON.isEmpty(isAllCompanies)) {
            return []
        }
        return select.companies.map(data => data.value)
    },
    resetDataSelect: () => {
        setSelect({ companies: [options.companies[0].options[0]]})
    },
    setDataSelectOutSide: (companies) => {
        if(!window.COMMON.isEmpty(companies)){
            const companiesSet = options.companies.map(data => data.options).flat(2)
            select.companies = companiesSet.filter(company => companies.includes(company.value))
            setSelect({ ...select })
        }
    }
  }), [select, options])

  return (
    <>
         <div className="mt-2">     
            <ReactSelect value={select.companies} options={options.companies} className="md-form m-0" placeholder={window.I18N('choose_option')}  isSearchable isMulti closeMenuOnSelect={false} onChange={handleSelect}/>
        </div>
    </> 
  )
}) 

export default SelectOrganization
