import React, {useLayoutEffect} from 'react';
import { Provider } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';

import 'bootstrap';
import Waves from 'node-waves';
// import 'froala-editor/js/froala_editor.pkgd.min.js';

import LoginPage from './pages/views/LoginPage';
import ForgotPasswordPage from './pages/views/ForgotPasswordPage';
import SetNewPasswordPage from './pages/views/SetNewPasswordPage';
import RegisterPage from './pages/views/RegisterPage';
import Layout from './pages/layouts/Layout';

import Loading from './pages/components/Loading';
import Notification from './pages/components/Notification';
import Upload from './pages/components/Upload';
import Constant from './utils/Constant';
import Message from './utils/Message';
import Common from './utils/Common';
import CommonDate from './utils/CommonDate';
import UploadAPI from './utils/UploadAPI';
import MailAPI from './utils/MailAPI';
import store from './redux/store';
import Tracking from './tracking/Tracking';
// CSS
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-notifications/lib/notifications.css';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import 'react-folder-tree/dist/style.css';
import 'node-waves/dist/waves.min.css';
// import 'froala-editor/css/froala_style.min.css';
// import 'froala-editor/css/froala_editor.pkgd.min.css';
import './styles/css/mdb.min.css';
import './styles/css/app.css';
import './styles/css/style.css';

function App(props) {

  useLayoutEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }
    
    Waves.attach('.btn');
    Waves.attach('.page-link');
    Waves.attach('.waves-effect', ['waves-button', 'waves-float']);
    Waves.init();
    window.COMMON.initWOW();
    window.COMMON.initCommon();
  }, []);

  if (!window) {
    return null;
  }
  window.MSG = Message;
  window.CONSTANT = Constant;
  window.COMMON = Common;
  window.COMMON_DATE = CommonDate;
  window.I18N = props.t;
  window.UPLOAD_API = UploadAPI;
  window.MAIL_API = MailAPI;
  return (
    <>
      <Provider store={store}>
        <Tracking>
          <Switch>
            <Route exact path="/">
              <Redirect to="/login" />
            </Route>
            <Route exact path="/login" component={LoginPage} />
            <Route exact path="/forgot-password" component={ForgotPasswordPage} />
            <Route exact path="/reset-password" component={SetNewPasswordPage} />
            <Route exact path="/signup" component={RegisterPage} />
            <Route exact component={localStorage.getItem(window.CONSTANT.IS_LOGIN) === 'true' ? Layout : LoginPage} />
          </Switch>
          <Loading></Loading>
          <Notification></Notification>
          <Upload></Upload>
        </Tracking>
      </Provider>
    </>
  );
}

export default withRouter(withNamespaces()(App));
