import React, { useState, useLayoutEffect, useRef, useCallback } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import { MDBCol, MDBRow, MDBBtn, MDBIcon } from 'mdbreact';

import Panel from '../../components/Panel';
import Filter from '../../components/Filter';
import ExportExcel from '../../components/ExportExcel';
import TableCustomize from '../../components/TableCustomize';
import Pagination from '../../components/Pagination';

import RewardGRAPHQL from '../../../graphql/Reward';

function HistoryRewardPage(props) {
  const config = [
    window.COMMON.createCol(window.I18N('no'), '5%', '', '', '', 'INDEX'),
    window.COMMON.createCol(window.I18N('created_date'), '12.5%', '', '', 'createdDate', 'TEXT'),
    window.COMMON.createCol(window.I18N('organization'), '20%', '', '', 'organizationName', 'TEXT'),
    window.COMMON.createCol(window.I18N('user'), '20%', 'p-0 pl-1', '', 'createdBy.avatar,createdBy.nickname', 'IMAGE_TEXT'),
    window.COMMON.createCol(window.I18N('reward'), '20%', '', '', 'rewardName', 'TEXT'),
    window.COMMON.createCol(window.I18N('code'), '12.5%', '', '', 'code', 'TEXT'),
    window.COMMON.createCol(window.I18N('point'), '10%', '', 'right', 'point', 'TEXT')
  ];
  const language = useRef();
  const [filter, setFilter] = useState({
    rewardIds: [],
    startDate: window.COMMON_DATE.addDaysFromNow(-30),
    endDate: new Date()
  });
  const [pagination, setPagination] = useState({ total: 0, pageSize: 10, currentPage: 0 });
  const [data, setData] = useState([]);
  const [options, setOptions] = useState({
    reward: []
  });

  useLayoutEffect(() => {
    if (!props.language.code) {
      return;
    }
    language.current = props.language.code;
    setTimeout(() => {
      getDataReward();
    }, 100);
    // eslint-disable-next-line
  }, [props.language]);

  const handleFilterSelect = (event, attr) => {
    filter[attr] = event;
    setFilter({ ...filter });
  };

  const handleDatePicker = (event, attr) => {
    if(window.COMMON.isEmpty(event)){
      return
    }
    setFilter((prev) => ({ ...prev, [attr]: event }))
  };

  const getDataReward = async () => {
    try {
      const params = {
        status: true
      };
      const result = await window.COMMON.query(RewardGRAPHQL.QUERY_REWARD, params, false);
      if (result && result.data) {
        const data = result.data.getRewards;
        data.forEach(item => {
          item.rewardName = window.COMMON.getValueWithLanguage(item, 'name', language.current);
          item.categoryName = window.COMMON.getValueWithLanguage(item, 'category.name', language.current);
          item.organizationName = item.company ? window.COMMON.getValueFromAttr(item.company, 'information.name') : (item.influencer ? window.COMMON.getValueFromAttr(item.influencer, 'information.name') : '');
        });
        options.reward = window.COMMON.createDataSelectHaveGroup(result.data.getRewards, '_id', 'rewardName', 'category._id', 'categoryName');
        setOptions(options);
        getDataHistoryReward();
      }
    } catch (error) {
      window.COMMON.showErrorLogs('HistoryRewardPage.getDataReward');
    }
  };

  const getDataHistoryReward = async () => {
    try {
      const params = {
        input: {
          rewardIds: window.COMMON.getDataSelect(filter.rewardIds, true),
          startDate: window.COMMON_DATE.getStrDateFilter(filter.startDate, -1),
          endDate: window.COMMON_DATE.getStrDateFilter(filter.endDate, 1),
          pageSize: pagination.pageSize,
          page: pagination.currentPage + 1
        }
      };
      const result = await window.COMMON.query(RewardGRAPHQL.QUERY_HISTORY_REWARD, params);
      let data = [];
      if (result && result.data && result.data.getHistoryReward) {
        data = window.COMMON.formatDate(result.data.getHistoryReward.data, 'createdDate', 'DD/MM/YYYY hh:mm');
        data.forEach(item => {
          item.rewardName = window.COMMON.getValueWithLanguage(item, 'reward.name', language.current);
          item.categoryName = window.COMMON.getValueWithLanguage(item, 'category.name', language.current);
        });
        pagination.total = result.data.getHistoryReward.total;
      }
      setPagination(pagination);
      setData(data);
    } catch (error) {
      window.COMMON.showErrorLogs('HistoryRewardPage.getDataHistoryReward');
    }
  };

  const getDataExport = async () => {
    window.showLoading();
    const dataExport = [];
    try {
      const params = {
        input: {
          rewardIds: window.COMMON.getDataSelect(filter.rewardIds, true),
          startDate: window.COMMON_DATE.getStrDateFilter(filter.startDate, -1),
          endDate: window.COMMON_DATE.getStrDateFilter(filter.endDate, 1),
          pageSize: pagination.pageSize,
          page: 1
        }
      };
      const result = await window.COMMON.query(RewardGRAPHQL.QUERY_HISTORY_REWARD, params, false);
      if (result && result.data && result.data.getHistoryReward) {
        generateDataExport(result.data.getHistoryReward.data, dataExport);
        const total = result.data.getHistoryReward.total;
        const page = total < pagination.pageSize ? 1 : Math.ceil(total / pagination.pageSize);
        for (let i = 1; i < page; i++) {
          await new Promise(resolve => setTimeout(resolve, 2000));
          params.input.page = i + 1;
          const resultItem = await window.COMMON.query(RewardGRAPHQL.QUERY_HISTORY_REWARD, params, false);
          if (resultItem && resultItem.data && resultItem.data.getHistoryReward) {
            generateDataExport(resultItem.data.getHistoryReward.data, dataExport);
          }
        }
      }
    } catch (error) {
      window.COMMON.showErrorLogs('HistoryRewardPage.getDataExport');
    }
    window.hideLoading();
    return dataExport;
  };

  const generateDataExport = async (data, dataExport) => {
    data.forEach(item => {
      item.createdDate = window.COMMON_DATE.formatDate(item.createdDate, 'DD/MM/YYYY hh:mm');
      item.rewardName = window.COMMON.getValueWithLanguage(item, 'reward.name', language.current);
      item.categoryName = window.COMMON.getValueWithLanguage(item, 'category.name', language.current);
      dataExport.push(item);
    });    
  };

  const changePage = useCallback((index) => {
    pagination.currentPage = index;
    setPagination({ ...pagination });
    getDataHistoryReward();
    // eslint-disable-next-line
  }, [filter, pagination]);

  const doSearchData = () => {
    pagination.currentPage = 0;
    window.COMMON.hideFilter();
    getDataHistoryReward();
  };

  if (typeof window === 'undefined') {
    return null;
  }
  return (
    <div className="container-row">
      <Panel id="container-data" title={window.I18N('history_reward')} icon="list-alt">
        <div className="container-btn wow fadeInDown animated" data-wow-delay="0.5s">
          <Filter id="container-filter" className="col-md-8">
            <MDBRow>
              <MDBCol sm="12" className="mb-2">
                <strong>{window.I18N('reward')}</strong>
                <div className="input-group input-group-transparent mt-2">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <MDBIcon fa="true" icon="award" className="fa-fw" />
                    </span>
                  </div>
                  <Select className="md-form m-0" placeholder={window.I18N('all_rewards')} value={filter.rewardIds} options={options.reward} onChange={(event) => handleFilterSelect(event, 'rewardIds')} isSearchable isMulti closeMenuOnSelect={false}/>
                </div>
              </MDBCol>
              <MDBCol sm="6" className="mb-2">
                <strong>{window.I18N('from_date')}</strong>
                <div className="input-group input-group-transparent mt-2">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <MDBIcon fa="true" icon="calendar-alt" className="fa-fw" />
                    </span>
                  </div>
                  <DatePicker 
                    className="form-control" 
                    dateFormat="dd/MM/yyyy" 
                    maxDate={filter.endDate}
                    selected={filter.startDate} 
                    onChange={(event) => handleDatePicker(event, 'startDate')} 
                    showYearDropdown 
                    showMonthDropdown
                  />
                </div>              
              </MDBCol>
              <MDBCol sm="6" className="mb-2">
                <strong>{window.I18N('to_date')}</strong>
                <div className="input-group input-group-transparent mt-2">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <MDBIcon fa="true" icon="calendar-alt" className="fa-fw" />
                    </span>
                  </div>
                  <DatePicker 
                    className="form-control" 
                    dateFormat="dd/MM/yyyy" 
                    minDate={filter.startDate}
                    selected={filter.endDate} 
                    onChange={(event) => handleDatePicker(event, 'endDate')} 
                    showYearDropdown 
                    showMonthDropdown
                  />
                </div>
              </MDBCol>
              <MDBCol sm="12 text-right">
                <MDBBtn color="primary" onClick={doSearchData.bind(this)}>
                  <MDBIcon fa="true" icon="sync-alt" className="fa-fw" /> {window.I18N('apply')}
                </MDBBtn>
              </MDBCol>
            </MDBRow>
          </Filter>
          <ExportExcel label={window.I18N('export')} isTemplate={false} dataKey="REWARD_REPORT" handleData={getDataExport}></ExportExcel>
        </div>
        <div className="container-table wow fadeIn animated" data-wow-delay="1s">
          <TableCustomize id="table-data" className="table" config={config} data={data}></TableCustomize>
          <Pagination total={pagination.total} pageSize={pagination.pageSize} currentPage={pagination.currentPage} changePage={changePage}></Pagination>
        </div>
      </Panel>
    </div>
  );
}

const mapStateToProps = state => ({
  language: state.language.value
});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(HistoryRewardPage);