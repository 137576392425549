import React, { Fragment, useEffect, useMemo } from 'react';
import { connect } from 'react-redux'

import { CustomGroupChatItem } from '../../../../../styles/styled-components/Chat/ChatPage';
import CircleAvatar from '../../../../components/CircleAvatar';
import AccountGRAPHQL from '../../../../../graphql/Account';

export const checkReadMessage = (message) => {
  //get current user id
  const currentUserId = localStorage.getItem(window.CONSTANT.ID);
  
	
  const reader = message.reader;
  if (!Array.isArray(reader) || reader.includes(currentUserId)) return false;
  return true;
};
const GroupChatItem = ({ groupchat, onClick, isSelected = false, language }) => {
  const [userNameValue, setUserNameValue] = React.useState('');
  const currentUserId = localStorage.getItem(window.CONSTANT.ID);
  const groupChatToShow = useMemo(() => window.COMMON.getGroupChatInforToShow(groupchat), [groupchat]);
  const getUser = async (id) => {
    if(!id) return;
    const userNickName =  await window.COMMON.query(AccountGRAPHQL.QUERY_ACCOUNT_BY_ID, { id: id }, false);
    setUserNameValue(userNickName?.data?.findAccountById?.nickname || '');
  };
  useEffect(() => {
    if (!groupchat) return '';
    if (groupchat.type.includes('GROUP')) {getUser(groupchat.lastMessage?.sender); } else {
      const message = groupchat.lastMessage;
      const user = (message.sender === currentUserId) ? `${window.I18N('You')}` : `${(groupchat.members.map((index) => index.id === message.sender ? index.nickname : null)).filter((index) => index !== null)[0]}`;
      setUserNameValue(user);
    }
  },[groupchat]);

  const convertMessageContent = (message, user) => {
    if (!message) return '';
    const messageType = message.type;
    const content = message.content;
    if (messageType === 0 || messageType === 2) {
      return (message.sender === currentUserId) ? `${window.I18N('You')}: ${content}` : `${user}: ${content}`;
    }
    if (messageType === 1) {
      return user + " " + window.I18N('sent_an_image');
    }
    if (messageType === 3) {
      return user + " " + window.I18N('sent_a_file');
    }
    if (messageType === 4) {
      return user + " " + window.I18N('sent_an_audio');
	  }
    if (messageType === 6) {
      if (message.user === content[content.length - 1]) {
        return user + " " + window.I18N('joined');
      }
      return user + " " + window.I18N('added') + ' ' + content[content.length - 1];
    }
    if (messageType === 7) {
      return user + ' ' + content;
    }
    if (messageType === 8) {
      return (content[content.length - 1] || user) + ' ' + window.I18N('exited_group');
    }
    return message.content;

  };

  return <div className="py-2">
    <CustomGroupChatItem onClick={() => { onClick(groupchat); }} className={`${isSelected === true ? 'active' : ''}`}>
      <CircleAvatar src={groupChatToShow.image} size={'48px'} showDot={groupchat.isOnline === 'ONLINE'}/>
      <div className="content">
        <div className="top-content">
          <span className="first">{`${groupChatToShow?.groupName === '' ? groupchat?.members[1]?.nickname : groupChatToShow.groupName }`}</span>
          {/* <div className="second message-count">1</div> */}
        </div>
        <div className="bottom-content">
          {groupchat.lastMessage && (
            <Fragment>
              <span className={`first message ${checkReadMessage(groupchat.lastMessage) && 'font-weight-bold'}`}>
                {convertMessageContent(groupchat.lastMessage,userNameValue)}
              </span> -{' '}
              <span className={`time ${checkReadMessage(groupchat.lastMessage) && 'font-weight-bold'}`}>
                { window.COMMON_DATE.formatAgoTime(+groupchat.lastMessage?.timestamp, language.code) || '' }
              </span>
            </Fragment>
          )}
        </div>
      </div>
      {/* <div className="menu">
        <div className="dropdown dropleft">
          <NavLink to="#" className="" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-boundary="viewport">
            <MDBIcon fa="true" icon="ellipsis-v" className="fa-fw black-text" />
          </NavLink>
          <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton" data-boundary="viewport">
            <li className="dropdown-item" onClick={(event) => { }}>
              <MDBIcon fa="true" icon="trash" className="fa-fw"></MDBIcon> {window.I18N('hide_group_chat')}
            </li>
          </ul>
        </div>
      </div> */}
    </CustomGroupChatItem>
  </div>;
};

const mapStateToProps = (state) => ({
  language: state.language.value
})
const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(GroupChatItem);
