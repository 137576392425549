import React, {  Fragment } from 'react';
import { MDBTooltip, MDBIcon } from 'mdbreact';
import CircleAvatar from '../../../../components/CircleAvatar';
import { CustomMessage, CustomArrayMessage, MessageReply, MessageContent } from '../../../../../styles/styled-components/Chat/ChatPage';
import ReactAudioPlayer from 'react-audio-player';
import { checkReadMessage } from '../../ChatLeftSide/BodyChatLeftSide/GroupChatItem';
import { convertSticker } from '../../../../../utils/sticker';

export const checkMessageIsCurrentUser = (message) => {
  if (message.isCurrentUser === true) {
    return true;
  }
  if (message.createdBy && message.createdBy._id === localStorage.getItem(window.CONSTANT.ID)) {
    return true;
  }
  return false;
};

const Message = ({ message, onReply, allStickers }) => {  
  const checkIsShowUserWithMessage = (type) => {
    const canShowType = [0, 1, 2, 3, 4];
    if (canShowType.includes(type)) {
      return true;
    }
    return false;
  };



  const convertContentInArrayMessage = (type, user, content) => {
    if (type === 6) {
      return `${user || ''} ${window.I18N('added')} ${content}`;
    }
    if (type === 8) {
      return `${content} ${window.I18N('exited_group')}`;
    }
  };

  const downFile = async (url, name) => {
    try {
      fetch(url, {
        method: 'GET',
        mode: 'no-cors',
        headers: {
          'Content-Type': 'application/pdf'
        }
      })
        .then((response) => response.blob())
        .then(() => {
          // Create blob link to download
          // const url = window.URL.createObjectURL(
          //   new Blob([blob]),
          // );
          const link = document.createElement('a');
          link.href = url;
          link.download = name;
          // Append to html link element page
          document.body.appendChild(link);

          // Start download
          link.click();

          // Clean up and remove the link
          link.parentNode.removeChild(link);
        })
        .catch(error => {

        });
    }
    catch (error) {

    }
  };

  const showFile = (url) => {
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute(
      'target',
      '_blank',
    );
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  };

  const handleFileClick = async (url, name) => {
    const extension = window.COMMON.getFileExtension(name);
    if (extension.toLowerCase() === 'pdf') {
      showFile(url);
    }
    else {
      downFile(url, name);
    }
  };

  const createFileIcon = (fileName) => {
    const extension = window.COMMON.getFileExtension(fileName);
    if (extension === 'doc' || extension === 'docx') {
      return 'file-word';
    }
    if (extension === 'pdf') {
      return 'file-pdf';
    }
    if (extension === 'xls' || extension === 'xlsx') {
      return 'file-excel';
    }
    if (extension === 'ppt' || extension === 'pptx') {
      return 'file-powerpoint';
    }
    return 'file';
  };



  const isCurrentUser = checkMessageIsCurrentUser(message);
  checkMessageIsCurrentUser(message);
  return <Fragment>

    {(!checkIsShowUserWithMessage(message.type) &&
      window.COMMON.convertMessageDataType(message.type) === window.CONSTANT.ARRAY) && 
	    Array.isArray(message.content) && (<CustomArrayMessage>
	  {message.content.map((item, index) => {
	    if (message.type === 6 && message.user === item ) return null; //user add themselves to group => hide
	    return <div key={`${item._id} - ${index} - array-mess-item`}>
	      <MDBTooltip placement="right" domElement>
	        <div>{convertContentInArrayMessage(message.type, message.user, item)}</div>
	        <span>{window.COMMON.formatDateSingleData(message, 'createdAt', 'hh:mm DD/MM/YYYY')}</span>
	      </MDBTooltip>
	    </div>;
	  })}

    </CustomArrayMessage>
    )}

    {(!checkIsShowUserWithMessage(message.type) &&
      window.COMMON.convertMessageDataType(message.type) === window.CONSTANT.TEXT) && <CustomArrayMessage>
      {message.groupsMessage.map((item, index) => {
        return <div key={`${item._id} - ${index} - array-mess-item`}>
          <MDBTooltip placement="right" domElement>
            <div>{`${message.user} ${item.content}`}</div>
            <span>{window.COMMON.formatDateSingleData(item, 'createdAt', 'hh:mm DD/MM/YYYY')}</span>
          </MDBTooltip>
        </div>;
      })}

    </CustomArrayMessage>
    }


    {checkIsShowUserWithMessage(message.type) && <CustomMessage className={`${isCurrentUser ? 'me' : ''}`}>
      <CircleAvatar src={message.createdBy.avatar} size={'32px'} />
      <div className={`content ${isCurrentUser ? 'me' : ''}`}>
        <div className="top-content">
          <div className="first">{message.createdBy.nickname}</div>
          {/* <div>12:00</div> */}
        </div>
        <div className={`bottom-content ${isCurrentUser ? 'me' : ''}`}>
          {message.groupsMessage.map((item, index) => {

            return <div className="mt-2" key={`${index} - groupmessage-content`}>
			  {item.reply && (
                <>
                  <div className={`mt-2 d-flex ${isCurrentUser ? 'flex-row-reverse' : 'flex-row'}`} style={{ margin: '0 5px' }}>
                    <div>
				  	<MDBIcon fa="true" icon="reply" className="fa-fw mr-2 text-muted"/>
                      {(item.createdBy?._id === item.reply.createdBy?._id) ? 
					  (isCurrentUser ? `${window.I18N('you')} ${window.I18N('replied_yourself')}` : `${item.createdBy?.name} ${window.I18N('replied_themselves')}`)
                        : `
					  ${isCurrentUser ? `${window.I18N('you')}` : item.createdBy?.name} ${window.I18N('replied_to')} ${' '}
                      ${checkMessageIsCurrentUser(item.reply) ? `${window.I18N('you')}` : item.reply.createdBy?.name}
					`}
                    </div>
				  </div>
                  <div className={`d-flex ${isCurrentUser ? 'flex-row-reverse' : 'flex-row'}`}>
                    <div className="reply-container">
                      <div className='pb-2'> {/*to space between content reply vs content message*/}
                        <div className="reply-content">{
                          item.reply.type === 1 ? <span><MDBIcon fa="true" icon="image" className="fa-fw mr-1" /> Image</span> 
                          : item.reply.type === 3 ? <span><MDBIcon fa="true" icon="link" className="fa-fw mr-1" /> File</span> 
                          : item.reply.type === 4 ? <span><MDBIcon fa="true" icon="microphone" className="fa-fw mr-1" /> Audio</span>
                          : <span>{item.reply.content}</span>
                        }</div>
                      </div>
                    </div>
                  </div>
                </>
			  )}
              <MDBTooltip placement="right" domElement>
                <MessageContent className={`d-flex ${isCurrentUser ? 'flex-row-reverse' : 'flex-row'}`}>
                  <div className={`d-inline-flex align-items-center ${isCurrentUser ? 'flex-row' : 'flex-row-reverse'}`}>
                    <MessageReply onClick={() => onReply(item.timestamp)}>
                      <MDBIcon fa="true" icon="reply" className="fa-fw" />
                    </MessageReply>
                    {item.type === 0 && 
					  <div className={`
						message-content 
						${item.isDraft === true ? 'draft-message' : ''} ${isCurrentUser ? 'me' : ''} 
						${(checkReadMessage(item) && !isCurrentUser) ? 'font-weight-bold' : ''}
					  `}>
			  			{item.content}
					  </div>
                    }
                    {item.type === 1 && 
					  <div className={`message-image ${item.isDraft === true ? 'draft-message' : ''} ${isCurrentUser ? 'me' : ''}`}>
				  		<img src={item.content} />
					  </div>
                    }
                    {item.type === 2 && 
					  <div className={`message-content ${item.isDraft === true ? 'draft-message' : ''} icon`}>
				  		<img src= {convertSticker(allStickers, item.content)} />
					  </div>
                    }
                    {item.type === 3 && (
                      <div
                        className={`message-content file ${item.isDraft === true ? 'draft-message' : ''} ${isCurrentUser ? 'me' : ''}`}
                        onClick={() => handleFileClick(item.content, item.fileName)}
                      >
                        <MDBIcon size="lg" icon={createFileIcon(item.fileName)} className="fas fa-fw" />
                        {item.fileName}
                      </div>
		  		    )}
                    {item.type === 4 && (
                      <ReactAudioPlayer
                        src={item.content}
                        controls
                      /> 
		  		    )}
                  </div>
                </MessageContent>
			          <span>{window.COMMON.formatDateSingleData(item, 'createdAt', 'hh:mm DD/MM/YYYY')}</span>
			        </MDBTooltip>
            </div>;
          })}


        </div>

      </div>
    </CustomMessage>}
  </Fragment>;
};
export default Message;