import React, { useState, useRef, useLayoutEffect } from 'react';
import { MDBRow, MDBCol, MDBInput, MDBCardImage } from 'mdbreact';

import Modal from './Modal';

import PostGRAPHQL from '../../graphql/Post';

const BackgroundPost = React.memo(function BackgroundPost (props) {
  const timeout = useRef(null);
  const [text, setText] = useState('');
  const [data, setData] = useState([]);
  const [dataSearch, setDataSearch] = useState([]);

  useLayoutEffect(() => {
    const resetTextSearch = ''
    setText(resetTextSearch)
    setDataSearch([])
    setTimeout(() => {
      getDataBackgroundPost(resetTextSearch)
    }, 100)
    // eslint-disable-next-line
  }, [props.isGif]);

  const handleChange = (event) => {
    const str = event.target.value;
    if(!window.COMMON.validateTextInput(str)) {
      return
    }
    setText(str);
    if (timeout.current) {
      clearTimeout(timeout.current);
      timeout.current = null;
    }
    timeout.current = setTimeout(() => {
      if (str) {
        const arrs = data.filter((item) => item.hashtag.some(hashtag => hashtag.toLowerCase().includes(str.trim().toLowerCase())) || item.name.toLowerCase().includes(str.trim().toLowerCase()))
        setDataSearch(arrs);
      } else {
        setDataSearch(data);
      }
    }, 1000);
  };

  const getDataBackgroundPost = async () => {
    try {
      const params = {
        input: {
          isGif: props.isGif,
          pageSize: 9999,
          page: 1
        }
      };
      const result = await window.COMMON.query(PostGRAPHQL.QUERY_BACKGROUND_POST, params);
      let data = [];
      if (result && result.data && result.data.getBackgroundPosts) {
        data = result.data.getBackgroundPosts.data; 
      }
      setData(data);
      setDataSearch(data);
    } catch (error) {
      window.COMMON.showErrorLogs('BackgroundPost.getDataBackgroundPost');
    }
  };

  const chooseBackground = (item) => {
    if (props.handleBackground) {
      props.handleBackground(item);
    }
  };

  if (typeof window === 'undefined') {
    return null;
  }
  return (
    <Modal id="modal-background-my-post" className="modal-lg" classModalContent="h-100" title={window.I18N(props.isGif ? 'choose_gif' : 'choose_background')} saveBtnProps={{ disabled: window.COMMON.isEmpty(dataSearch) }}>
      <MDBRow>
        <MDBCol sm="12">
          <MDBInput outline id="input-share-text" type="input" containerClass="m-0" hint={window.I18N('input_hashtag')} value={text} onChange={(event) => handleChange(event)} maxLength="100" pattern="\S(.*\S)?">
            <div className="invalid-feedback">
              {window.I18N('MSG_CODE_029')}
            </div>
          </MDBInput>
        </MDBCol>
        { 
          dataSearch.map((item, i) => <MDBCol sm="6" lg="4" className="mt-3" key={i}>
            <div className="card border p-0" style={{ backgroundColor: '#fbfbfb' }} onClick={() => chooseBackground(item)}>
              <div className="w-100 text-center">
                <MDBCardImage zoom cascade waves src={window.COMMON.setValueImage(item.background)} style={{ height: '120px', maxWidth: 'calc(100% - 10px)' }}/>
              </div>
            </div>          
          </MDBCol>) 
        }
      </MDBRow>
    </Modal>
  );
});

export default BackgroundPost;