import React, { useState, useLayoutEffect } from 'react';
import { MDBRow, MDBCol, MDBInput, MDBBtn, MDBIcon } from 'mdbreact';

import AccountGRAPHQL from '../../graphql/Account';
import { useTracking } from 'react-tracking';
import eventName from '../../common/events';

const ChangePassword = React.memo(function Menu(props) {
  const { trackEvent } = useTracking();

  const [dataSave, setDataSave] = useState({});

  useLayoutEffect(() => {
    window.changePassword = openChangePasswordDialog;
    // eslint-disable-next-line
  }, []);

  const createTrackingEvent = (event) => {
    return trackEvent({
      name: event,
      createdAt: new Date().toISOString()
    });
  };

  const handleChange = (event) => {
    dataSave[event.target.name] = event.target.value;
    setDataSave({ ...dataSave });
  };

  const openChangePasswordDialog = () => {
    window.COMMON.showModal('#modal-password');
    dataSave.oldPassword = '';
    dataSave.newPassword = '';
    dataSave.rePassword = '';
    setDataSave({ ...dataSave });
  };

  const changePassword = async (event) => {
    const checkForm = window.COMMON.checkFormValidation('form-password', event);
    if (!checkForm) {
      return;
    }
    if (dataSave.oldPassword === dataSave.newPassword) {
      window.COMMON.showMessage('warning', 'MSG_CODE_031', window.I18N('MSG_CODE_031'));
      return false;
    }
    if (dataSave.newPassword !== dataSave.rePassword) {
      window.COMMON.showMessage('warning', 'MSG_CODE_031', window.I18N('MSG_CODE_031'));
      return false;
    }
    try {
      const params = {
        oldPassword: dataSave.oldPassword,
        newPassword: dataSave.newPassword
      };
      window.COMMON.trimData(params);
      const result = await window.COMMON.mutation(AccountGRAPHQL.MUTATION_CHANGE_PASSWORD, params);
      if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_UPDATE)) {
        window.COMMON.hideModal('#modal-password');
        createTrackingEvent(eventName.CHANGE_PASSWORD);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('ChangePassword.changePassword');
    }
  };

  if (typeof window === 'undefined') {
    return null;
  }
  return (
    <div className="modal fade" id="modal-password" tabIndex="-1" role="dialog" aria-hidden="true">
      <div className="modal-dialog cascading-modal modal-avatar" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <img src={window.COMMON.setValueImage(localStorage.getItem(window.CONSTANT.AVATAR))} alt="avatar" className="rounded-circle img-responsive"/>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close" style={{ position: 'absolute',  right: '0px', top: '20px'}}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body text-center">
            <h5 className="mt-1 mb-1">{localStorage.getItem(window.CONSTANT.FULL_NAME)}</h5>
            <form id="form-password" className="needs-validation" noValidate>
              <MDBRow>
                <MDBCol>
                  <MDBInput outline type="password" label={window.I18N('current_password') + ' *'} name="oldPassword" value={dataSave.oldPassword} onChange={handleChange} maxLength="50" pattern="\S(.*\S)?" required>
                    <div className="invalid-feedback">{window.I18N('MSG_CODE_029')}</div>
                  </MDBInput>
                  <MDBInput outline type="password" label={window.I18N('new_password') + ' *'} name="newPassword" value={dataSave.newPassword} onChange={handleChange} minLength="8" maxLength="50" pattern="\S(.*\S)?" required>
                    <div className="invalid-feedback">{window.I18N('MSG_CODE_039')}</div>
                  </MDBInput>
                  <MDBInput outline type="password" label={window.I18N('re_password') + ' *'} name="rePassword" value={dataSave.rePassword} onChange={handleChange} minLength="8" maxLength="50" pattern="\S(.*\S)?" required>
                    <div className="invalid-feedback">{window.I18N('MSG_CODE_039')}</div>
                  </MDBInput>
                  <MDBBtn outline color="" className="btn blue-gradient btn-block btn-rounded z-depth-1a mb-3" onClick={changePassword}>
                    <MDBIcon fa="true" icon="save" className="fa-fw" /> {window.I18N('change_password')}
                  </MDBBtn>
                </MDBCol>
              </MDBRow>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
});

export default ChangePassword;