import React, { useImperativeHandle, useState } from 'react';
import { MDBIcon, MDBInputGroup, MDBBtn } from 'mdbreact';
import Select from 'react-select';
import RadioBox from '../../../components/RadioBox';
import { CustomHeaderChatLeftSide, CustomListFriends } from '../../../../styles/styled-components/Chat/ChatPage';
import CircleAvatar from '../../../components/CircleAvatar';


const HeaderChatLeftSide = React.forwardRef(({ options, filter, onFilter, onSearchUser, onSearchGroup, onNewClick, chatLeftSideHeaderRadioData, onFilterRadio, friends, onSearchUserResultClick }, ref) => {
  const [searchUserValue, setSearchUserValue] = useState('');
  const [searchGroupValue, setSearchGroupValue] = useState('');
  useImperativeHandle(ref, () => {
    return {
      resetSearchUserInput,
      resetSearchGroupInput
    };
  });
  const resetSearchUserInput = () => {
    setSearchUserValue('');

  };
  const resetSearchGroupInput = () => {
    setSearchGroupValue('');
  };

  const handleChangeInputSearch = (event) => {
    const { value } = event.target
    if(!window.COMMON.validateTextInput(value)){
      return
    }
    setSearchUserValue(value);
    onSearchUser(value);
  }

  return <CustomHeaderChatLeftSide>
    <div className="d-flex justify-content-between w-100 mb-2 mt-1">
      <RadioBox data={chatLeftSideHeaderRadioData} value={filter.radio} className="m-0" handleChange={onFilterRadio}></RadioBox>
      {filter.radio === 1 && <div className="mb-2 d-flex justify-content-end">
        <MDBBtn className="m-0 send-btn" color="primary" onClick={onNewClick}>
          <MDBIcon fa="true" icon="plus-circle" className="fa-fw" />
        </MDBBtn>
      </div>}
    </div>
    {filter.radio === 0 && <div className="search-individual">
	  {Array.isArray(friends.data) && (
        <CustomListFriends>
          {friends.data.map((item, index) => (
            <div key={index} 
              className="mx-2 d-flex flex-column align-items-center cursor-pointer" 
              style={{ maxWidth: 60 }}
              onClick={() => onSearchUserResultClick(item)}
            >
              <CircleAvatar src={item.avatar} size={'48px'}/>
			  <div className='mt-1 text-center line-clamp-2'>{item.nickname}</div>
            </div>
          ))}
        </CustomListFriends>
	  )}

      {(window.COMMON.checkRoleIsSystemOrInfluencer()) && <div className="input-group input-group-transparent mb-2">
        <div className="input-group-prepend">
          <span className="input-group-text">
            <MDBIcon fa="true" icon="filter" className="fa-fw" />
          </span>
        </div>
        <Select value={filter.company} options={options.companies} className="md-form m-0" placeholder={window.I18N('companies')} onChange={(event) => { onFilter(event, 'company'); }} isSearchable closeMenuOnSelect={true} />
      </div>}

      <MDBInputGroup
        value={searchUserValue}
        id={'search_user'}
        name={'search_user'}
        type="text"
        hint={window.I18N('search_user')}
        onChange={handleChangeInputSearch}
        prepend={
          <span className="input-group-text" id="btn-search">
            <MDBIcon fa="true" icon="search" className="fa-fw"></MDBIcon>
          </span>
        }
      />
     

    </div>}
    {filter.radio === 1 && <div className="search-group">
      <MDBInputGroup
        type="text"
        value={searchGroupValue}
        hint={window.I18N('seach_group')}
        onChange={(event) => { 
          setSearchGroupValue(event.target.value);
          onSearchGroup(event.target.value);
        }}
        prepend={
          <span className="input-group-text" id="btn-search">
            <MDBIcon fa="true" icon="search" className="fa-fw"></MDBIcon>
          </span>
        }
      />

    </div>}
  </CustomHeaderChatLeftSide>;
});


export default HeaderChatLeftSide;