import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react'
import { MDBCol, MDBIcon, MDBRow, MDBInput } from 'mdbreact'
import { connect } from 'react-redux'
import InfiniteScroll from 'react-infinite-scroll-component'
import Avatar from '@material-ui/core/Avatar'
import AvatarGroup from '@material-ui/lab/AvatarGroup'
import QRCode from 'react-qr-code'

import EventGRAPHQL from '../../../../../graphql/Event'

import Carousel from '../../../../components/Carousel'
import styles from './EventDetail.module.css'
import Button from '../../../../components/Button'
import Modal from '../../../../components/Modal'
import EventForm from '../EventForm'
import ReminderView from '../ReminderView'
import JoinOrLeaveEvent from '../JoinOrLeaveEvent'
import EventDetailLoading from '../Loading/EventDetailLoading'
import RejectAccessEvent from '../RejectAccessEvent/RejectAccessEvent'
import Image from '../../../../components/Image'
import useClickOutSide from '../../../../../hooks/useClickOutSide'
import useDownLoadImage from '../../../../../hooks/useDownLoadImage'

const PAGE_SIZE = 50
const DEFAULT_PAGE = 1
const PAGE_REJECTED_ACCESS = -1

const EventDetail = ({
    eventId,
    onBackEventList = () => {},
    refetchData = () => {},
    refetchDataForJoinOrLeave = () => {},
    language
}) => {
    const USER_ID = localStorage.getItem(window.CONSTANT.ID)
    const [dataEventDetail, setDataEventDetail] = useState({
        fanpageEvent: {
            _id: '',
            images: [],
            eventDate: '',
            name: '',
            description: '',
            countMember: 0,
            gopy: 0,
            createdBy: { nickname: '' },
            limitMember: 0,
            fanpage: '',
            reminders: [],
            status: window.CONSTANT.EVENT_STATUS.OPEN
        },
        role: '',
        relationId: ''
    })
    const {
        fanpageEvent: {
            _id,
            images,
            eventDate,
            name,
            description,
            countMember,
            gopy,
            createdBy,
            limitMember,
            fanpage,
            reminders,
            status
        },
        role
    } = dataEventDetail

    const [dataMembers, setDataMembers] = useState({})
    const [dataScanedMembers, setDataScanedMembers] = useState([])
    const [dataSelectedScanedMembers, setDataSelectedScanedMembers] = useState([])
    const [isStillMore, setIsStillMore] = useState(false)
    const [isRejectedAccessEvent, setIsRejectedAccess] = useState(false)
    const [isOpenCustomEvent, setIsOpenCustomEvent] = useState(false)
    const dataEditEvent = useMemo(() => {
        if (!window.COMMON.isEmpty(dataEventDetail)) {
            return {
                _id,
                fanpage,
                images,
                eventDate,
                name,
                description,
                gopy,
                limitMember,
                reminders
            }
        }
    }, [dataEventDetail])

    const editEventFormRef = useRef()
    const customEventRef = useRef()
    const QRCodeRef = useRef()

    const { downloadImage: downLoadQRCode } = useDownLoadImage({
        downLoadRef: QRCodeRef,
        imageName: `qr_attendance_event_${name}`,
        width: 500,
        height: 500
    })

    const handleChangeScanedMembers = (event, id) => {
        let arrs = JSON.parse(JSON.stringify(dataSelectedScanedMembers))
        if (event.target.checked) {
            arrs.push(id)
        } else {
            arrs = arrs.filter((item) => item !== id)
        }
        setDataSelectedScanedMembers([...arrs])
    }

    const checkPermissionFanpageEvent = async () => {
        try {
            const params = {
                eventId
            }
            const result = await window.COMMON.query(EventGRAPHQL.QUERY_CHECK_PERMISSION_FANPAGE_EVENT, params, false)
            return result.data.checkPermissionFanpageEvent
        } catch (error) {
            window.COMMON.showErrorLogs(`EventDetail.checkPermissionFanpageEvent ::: ${error}`)
        }
    }

    const getDataDetailEvent = async () => {
        try {
            const params = {
                id: eventId
            }

            const [event, relationshipEvent, members] = await Promise.all([
                window.COMMON.query(EventGRAPHQL.QUERY_DETAIL_FANPAGE_EVENT, params, false),
                window.COMMON.query(EventGRAPHQL.QUERY_DETAIL_RELATIONSHIP_FANPAGE_EVENT, params, false),
                window.COMMON.query(
                    EventGRAPHQL.QUERY_USER_IN_FANPAGE_EVENT,
                    { ...params, page: DEFAULT_PAGE, pageSize: PAGE_SIZE },
                    false
                )
            ])
            if (event && relationshipEvent && members) {
                const dataEvent = event.data.getFanpageEventDetail
                const relationshipDetailEvent = relationshipEvent.data.findFanpageEventRelationshipDetail
                const membersEvent = members.data.findMembersInFanpageEvent.data
                const totalMembers = members.data.findMembersInFanpageEvent.total
                membersEvent.length >= totalMembers ? setIsStillMore(false) : setIsStillMore(true)
                const arrsScanMembers = window.COMMON.getArrsInArrs(true, membersEvent, 'isScanedQRCode')
                setDataEventDetail({
                    fanpageEvent: dataEvent,
                    role: relationshipDetailEvent.role,
                    relationId: relationshipDetailEvent._id
                })
                setDataMembers(membersEvent)
                setDataScanedMembers(arrsScanMembers)
            }
        } catch (error) {
            window.COMMON.showErrorLogs(`EventDetail.getDataDetailEvent ::: ${error}`)
        }
    }

    const getMoreMembers = async () => {
        try {
            const params = {
                id: eventId,
                page: parseInt(dataMembers.length / PAGE_SIZE) + DEFAULT_PAGE,
                pageSize: PAGE_SIZE
            }
            const members = await window.COMMON.query(EventGRAPHQL.QUERY_USER_IN_FANPAGE_EVENT, params, false)
            const totalMembers = members.data.findMembersInFanpageEvent.total
            const membersEvent = members.data.findMembersInFanpageEvent.data
            if (!window.COMMON.isEmpty(membersEvent)) {
                setDataMembers((prev) => [...prev, ...membersEvent])
            }
            dataMembers.length + membersEvent.length >= totalMembers ? setIsStillMore(false) : setIsStillMore(true)
        } catch (error) {
            window.COMMON.showErrorLogs(`EventDetail.getMoreMembers ::: ${error}`)
        }
    }

    const openEditEventPopup = () => {
        window.COMMON.showModal('#modal-edit-event')
    }

    const closeEditEventPopup = () => {
        window.COMMON.hideModal('#modal-edit-event')
    }

    const openScanEventPopup = () => {
        const arrs = []
        if (dataEventDetail.fanpageEvent.membersAllowScan && dataEventDetail.fanpageEvent.membersAllowScan.length > 0) {
            dataEventDetail.fanpageEvent.membersAllowScan.forEach((element) => {
                arrs.push(element._id)
            })
        }
        setDataSelectedScanedMembers([...arrs])
        window.COMMON.showModal('#modal-scan-event')
    }

    const handleCloseCreateEventPopup = () => {
        const isChangeData = editEventFormRef.current.isChangeData()
        if (isChangeData) {
            window.COMMON.showModal('#modal-confirm')
            window.confirmMethod = () => {
                closeEditEventPopup()
                editEventFormRef.current.resetFormForEdit()
            }
            window.closeConfirmMethod = () => {
                openEditEventPopup()
            }
        } else {
            closeEditEventPopup()
        }
    }

    const handleOpenListMemberPopup = () => {
        window.COMMON.showModal('#modal-list-member')
    }

    const handleCloseListMemberPopup = () => {
        window.COMMON.hideModal('#modal-list-member')
    }

    const handleOpenListMemberScanedPopup = () => {
        window.COMMON.showModal('#modal-list-scaned-member')
    }

    const handleCloseListMemberScanedPopup = () => {
        window.COMMON.hideModal('#modal-list-scaned-member')
    }

    const editEventFanpage = () => {
        editEventFormRef.current.submitForm('edit', () => {
            getDataDetailEvent()
            closeEditEventPopup()
        })
    }

    const handleUpdateScanMembers = async () => {
        try {
            const params = {
                id: eventId,
                membersAllowScan: dataSelectedScanedMembers
            }
            const result = await window.COMMON.mutation(EventGRAPHQL.MUTATION_UPDATE_SCAN_MEMBER_FANPAGE_EVENT, params)
            if (window.COMMON.checkResultData(result, window.CONSTANT.SUCCESSFULLY)) {
                getDataDetailEvent()
            }
        } catch (error) {
            window.COMMON.showErrorLogs(`EventDetail.handleUpdateScanMembers ::: ${error}`)
        }
    }

    const handleMembersScanEvent = () => {
        !window.COMMON.isEmpty(dataSelectedScanedMembers) && handleUpdateScanMembers()
        window.COMMON.hideModal('#modal-scan-event')
    }

    const deleteEventFanpage = async () => {
        try {
            const params = {
                id: eventId
            }
            const result = await window.COMMON.mutation(EventGRAPHQL.MUTATION_DELETE_FANPAGE_EVENT, params)
            if (window.COMMON.checkResultData(result, window.CONSTANT.SUCCESSFULLY)) {
                refetchData()
            }
        } catch (error) {
            window.COMMON.showErrorLogs(`EventDetail.deleteEventFanpage ::: ${error}`)
        }
    }

    const handleEditEvent = () => {
        const isValidForm = editEventFormRef.current.validateForm()
        if (isValidForm) {
            window.COMMON.showModal('#modal-confirm')
            window.confirmMethod = editEventFanpage
        }
    }

    const handleDeleteEvent = () => {
        window.COMMON.showModal('#modal-delete')
        window.deleteMethod = deleteEventFanpage
    }

    const handleToggleCustomEvent = () => {
        setIsOpenCustomEvent(true)
    }

    const handleCloseCustomEvent = () => {
        setIsOpenCustomEvent(false)
    }

    useEffect(() => {
        const fetchDataDetailEvent = async () => {
            const result = await checkPermissionFanpageEvent()
            if(result !== PAGE_REJECTED_ACCESS){
                getDataDetailEvent()
            }
            setIsRejectedAccess(result)
        }
        fetchDataDetailEvent()
    }, [eventId])

    useClickOutSide({
        ref: customEventRef,
        handleOutside: handleCloseCustomEvent
    })

    if (isRejectedAccessEvent === PAGE_REJECTED_ACCESS) {
        return <RejectAccessEvent onBackEventList={onBackEventList} />
    }

    return (
        <>
            {window.COMMON.isEmpty(dataMembers) || window.COMMON.isEmpty(dataEventDetail) ? (
                <div className="bg-white rounded-lg wow fadeIn" style={{ height: '100vh' }}>
                    <EventDetailLoading />
                </div>
            ) : (
                <div className="wow fadeIn">
                    <div style={{ height: '50vh' }}>
                        <Carousel
                            data={images.map((image) => ({ imageUrl: image }))}
                            imageVariant="rounded-top"
                            showControls={images.length > 1}
                        />
                    </div>
                    <div className="bg-white border-bottom">
                        {/* Day in calendar */}
                        <div className={`${styles['calendar-box']}`}>
                            <div className={`${styles['calendar-header']}`}></div>
                            <div className={`${styles['calendar-body']}`}>{`${new Date(+eventDate).getDate()}`}</div>
                        </div>
                        <div className={`${styles['event-date-time']}`}>
                            {window.COMMON.formatDateForEvent(eventDate, language.code)}
                        </div>
                        <div className={`${styles['event-name']}`}>{name}</div>
                    </div>
                    <div className="p-3 rounded-bottom bg-white d-flex align-items-center justify-content-between">
                        <div>
                            <Button variant="light" iconLeft="long-arrow-alt-left" onClick={onBackEventList}>
                                <span style={{ fontSize: '0.9rem', fontWeight: 500 }}>{window.I18N('btn_back')}</span>
                            </Button>
                        </div>
                        <div
                            className={`d-flex align-items-center ${
                                role === window.CONSTANT.EVENT_ROLE.OWNER
                                    ? 'justify-content-end'
                                    : 'justify-content-between'
                            }`}
                            style={{ flexBasis: '40%', gap: '8px' }}
                        >
                            {role === window.CONSTANT.EVENT_ROLE.OWNER && (
                                <div style={{ position: 'relative' }}>
                                    <Button variant="light" iconLeft="ellipsis-h" onClick={handleToggleCustomEvent}>
                                        <span style={{ fontSize: '0.9rem', fontWeight: 500 }}>
                                            {window.I18N('customized')}
                                        </span>
                                    </Button>
                                    {isOpenCustomEvent && (
                                        <div className={styles['menu-custom-event']} ref={customEventRef}>
                                            {/* Set host members scan QR code for check in event */}
                                            <Button
                                                variant="menu-item"
                                                iconLeft="qrcode"
                                                onClick={openScanEventPopup}
                                                style={{ borderRadius: '4px 4px 0px 0px' }}
                                            >
                                                <span
                                                    className="text-nowrap"
                                                    style={{ fontSize: '0.9rem', fontWeight: 500 }}
                                                >
                                                    {window.I18N('members_scan_qrcode')}
                                                </span>
                                            </Button>
                                            {/* Edit Event */}
                                            <Button
                                                variant="menu-item"
                                                iconLeft="pencil-alt"
                                                onClick={openEditEventPopup}
                                                disabled={
                                                    status === window.CONSTANT.EVENT_STATUS.EXPIRED ||
                                                    window.COMMON_DATE.compareWithNow(new Date(+eventDate), true) ===
                                                        window.CONSTANT.TIME_IN_PAST
                                                }
                                            >
                                                <span style={{ fontSize: '0.9rem', fontWeight: 500 }}>
                                                    {window.I18N('btn_edit')}
                                                </span>
                                            </Button>
                                            {/* Delete Event */}
                                            <Button
                                                variant="menu-item"
                                                iconLeft="trash-alt"
                                                onClick={handleDeleteEvent}
                                                style={{ borderRadius: '0 0 4px 4px' }}
                                            >
                                                <span style={{ fontSize: '0.9rem', fontWeight: 500 }}>
                                                    {window.I18N('delete_event')}
                                                </span>
                                            </Button>
                                        </div>
                                    )}
                                </div>
                            )}
                            {(role === null || role === window.CONSTANT.EVENT_ROLE.MEMBER) && (
                                <JoinOrLeaveEvent
                                    key={eventId}
                                    eventDate={eventDate}
                                    eventId={eventId}
                                    status={role}
                                    refetchData={() => {
                                        getDataDetailEvent()
                                        refetchDataForJoinOrLeave()
                                    }}
                                />
                            )}
                        </div>
                    </div>
                    <MDBRow>
                        <MDBCol className="h-full mb-3" md="7">
                            <div className="h-100 mt-3 p-3 bg-white rounded-lg">
                                <h6 style={{ fontSize: '1rem', fontWeight: 700 }}>{window.I18N('detail')}</h6>
                                <div
                                    className="w-100 d-flex align-items-start justify-content-start"
                                    style={{ gap: '8px' }}
                                >
                                    <MDBIcon fas icon="users" style={{ fontSize: '20px', color: '#c7c8ca' }} />
                                    <div className="w-100">
                                        <div className="w-100 d-flex align-items-center justify-content-between">
                                            <span
                                                style={{ fontSize: '15px', fontWeight: 500 }}
                                            >{`${countMember} ${window.I18N('will_participate')}`}</span>
                                            <span
                                                style={{ color: '#0064d1', fontWeight: 500, cursor: 'pointer' }}
                                                onClick={handleOpenListMemberPopup}
                                            >
                                                {window.I18N('view_details')}
                                            </span>
                                        </div>
                                        {/* Avatar members */}
                                        <AvatarGroup className="mt-2" max={8}>
                                            {dataMembers?.map((item) => (
                                                <Avatar
                                                    key={item.user._id}
                                                    alt={item.user.nickname}
                                                    src={item.user.avatar}
                                                />
                                            ))}
                                        </AvatarGroup>
                                    </div>
                                </div>

                                <div
                                    className="w-100 d-flex align-items-start justify-content-start mt-3"
                                    style={{ gap: '8px' }}
                                >
                                    <MDBIcon
                                        fas
                                        icon="qrcode"
                                        style={{ fontSize: '22px', width: '27px', color: '#c7c8ca' }}
                                    />
                                    <div className="w-100">
                                        <div className="w-100 d-flex align-items-center justify-content-between">
                                            <span style={{ fontSize: '15px', fontWeight: 500 }}>{`${
                                                dataScanedMembers?.length
                                            } ${window.I18N('scan_qr_code')}`}</span>
                                            {!window.COMMON.isEmpty(dataScanedMembers) ? (
                                                <span
                                                    style={{ color: '#0064d1', fontWeight: 500, cursor: 'pointer' }}
                                                    onClick={handleOpenListMemberScanedPopup}
                                                >
                                                    {window.I18N('view_details')}
                                                </span>
                                            ) : (
                                                <></>
                                            )}
                                        </div>
                                        {/* Avatar members */}
                                        {!window.COMMON.isEmpty(dataScanedMembers) && (
                                            <AvatarGroup className="mt-2" max={8}>
                                                {dataScanedMembers?.map((item) => (
                                                    <Avatar
                                                        key={item.user._id}
                                                        alt={item.user.nickname}
                                                        src={item.user.avatar}
                                                    />
                                                ))}
                                            </AvatarGroup>
                                        )}
                                    </div>
                                </div>

                                <div
                                    className="mt-3 d-flex align-items-center justify-content-start"
                                    style={{ gap: '8px' }}
                                >
                                    <MDBIcon
                                        fas
                                        icon="bell"
                                        style={{ fontSize: '20px', width: '25px', color: '#c7c8ca' }}
                                    />
                                    <div
                                        className="d-flex align-items-start justify-content-start"
                                        style={{ gap: '4px' }}
                                    >
                                        <span style={{ fontSize: '15px', fontWeight: 500 }}>{`${
                                            status === 1
                                                ? window.I18N('event_has_started')
                                                : window.I18N('event_coming_soon')
                                        }`}</span>
                                    </div>
                                </div>

                                <div
                                    className="mt-3 d-flex align-items-center justify-content-start"
                                    style={{ gap: '8px' }}
                                >
                                    <MDBIcon
                                        fas
                                        icon="user-alt"
                                        style={{ fontSize: '20px', width: '25px', color: '#c7c8ca' }}
                                    />
                                    <div
                                        className="d-flex align-items-start justify-content-start"
                                        style={{ gap: '4px' }}
                                    >
                                        <span style={{ fontSize: '15px', fontWeight: 500 }}>{`${window.I18N(
                                            'organized_by'
                                        )} - ${createdBy.nickname}`}</span>
                                    </div>
                                </div>

                                {/* Fanpage */}
                                <div
                                    className="mt-3 d-flex align-items-start justify-content-start"
                                    style={{ gap: '8px' }}
                                >
                                    <MDBIcon
                                        fab
                                        icon="pagelines"
                                        style={{ fontSize: '20px', width: '25px', color: '#c7c8ca' }}
                                    />

                                    <div>
                                        <span style={{ fontSize: '15px', fontWeight: 500 }}>{`${window.I18N(
                                            'event_belongs'
                                        )} fanpage`}</span>
                                        <div className="mt-2 d-flex align-items-center" style={{ gap: '8px' }}>
                                            <Avatar
                                                alt={fanpage.name}
                                                src={fanpage.image}
                                                style={{ width: '28px', height: '28px' }}
                                            />
                                            <div className="d-flex flex-column align-items-start justify-content-start">
                                                <span className={`${styles['fanpage-name']} text-center`}>
                                                    {fanpage.name}
                                                </span>
                                                <span className={`${styles['fanpage-type']} text-center`}>
                                                    {fanpage.type}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* ================================================================= */}
                                <div
                                    className="mt-3 d-flex align-items-center justify-content-start"
                                    style={{ gap: '8px' }}
                                >
                                    <div className={styles['event-description']}>{description}</div>
                                </div>
                            </div>
                        </MDBCol>
                        <MDBCol className="pl-0" md="5">
                            <div style={{ position: 'sticky', top: '80px' }}>
                                {/* Limit and cost */}
                                <div className="mt-3 bg-white p-3 rounded-lg">
                                    <h6 style={{ fontSize: '1rem', fontWeight: 700 }}>
                                        {window.I18N('limit_and_cost')}
                                    </h6>
                                    <div className="flex-center" style={{ gap: '12px', flexBasis: '50%' }}>
                                        <div className="p-3 d-flex align-items-center justify-content-center flex-column rounded-lg">
                                            <span style={{ fontSize: '1rem', fontWeight: 700 }}>{limitMember}</span>
                                            <span className="d-flex align-items-center justify-content-center text-center">
                                                {window.I18N('people_maximum')}
                                            </span>
                                        </div>
                                        <div className="p-3 d-flex align-items-center justify-content-center flex-column rounded-lg">
                                            <span style={{ fontSize: '1rem', fontWeight: 700 }}>
                                                {gopy === 0 ? window.I18N('free') : gopy}
                                            </span>
                                            <span className="d-flex align-items-center justify-content-center text-center">
                                                {window.I18N('gopy_needed')}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                {/* Schedule reminder */}
                                <div className="mt-3 bg-white p-3 rounded-lg" style={{ minHeight: '200px' }}>
                                    <h6 style={{ fontSize: '1rem', fontWeight: 700 }}>
                                        {window.I18N('schedule_reminder')}
                                    </h6>
                                    {window.COMMON.isEmpty(reminders) ? (
                                        <div
                                            className="d-flex align-items-center flex-column justify-content-center"
                                            style={{ height: '130px' }}
                                        >
                                            <Image src={window.CONSTANT.CALENDAR} alt="" loading="lazy" height={40} />
                                            <span>{window.I18N('none_reminder')}</span>
                                        </div>
                                    ) : (
                                        <ReminderView
                                            eventDate={eventDate}
                                            reminders={reminders.sort((a, b) => b - a)}
                                        />
                                    )}
                                </div>
                                {/* QR code for participate */}
                                {role === window.CONSTANT.EVENT_ROLE.MEMBER && (
                                    <div className="mt-3 bg-white p-3 rounded-lg" style={{ minHeight: '256px' }}>
                                        <div className="d-flex align-items-center justify-content-between">
                                            <h6 className="mb-0" style={{ fontSize: '1rem', fontWeight: 700 }}>
                                                {window.I18N('attendance_qr_code')}
                                            </h6>
                                            <div
                                                className="d-flex align-items-center justify-content-center"
                                                style={{ cursor: 'pointer' }}
                                                onClick={downLoadQRCode}
                                            >
                                                <MDBIcon
                                                    fas
                                                    icon="download"
                                                    style={{ fontSize: '18px', width: '25px' }}
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className="mt-3 d-flex align-items-center justify-content-center"
                                            style={{ gap: '8px' }}
                                            ref={QRCodeRef}
                                        >
                                            <QRCode
                                                size={256}
                                                style={{ height: 'auto', maxWidth: '160px', width: '160px' }}
                                                value={`${window.COMMON.getValueFromAttr(
                                                    dataEventDetail,
                                                    'relationId'
                                                )}-FANPAGE_EVENT_QRCODE`}
                                                viewBox={`0 0 256 256`}
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>
                        </MDBCol>
                    </MDBRow>
                    {/* Edit event form */}
                    <Modal
                        id="modal-edit-event"
                        title={window.I18N('update_event')}
                        icon="pencil-alt"
                        saveBtn={window.I18N('btn_edit')}
                        saveBtnProps={{
                            style: {
                                width: '100%'
                            },
                            outline: false
                        }}
                        saveEvent={handleEditEvent}
                        styleBody={{
                            padding: '0px',
                            overflow: 'overlay',
                            overscollBehavior: 'contain'
                        }}
                        handleCloseModal={handleCloseCreateEventPopup}
                    >
                        {role === window.CONSTANT.EVENT_ROLE.OWNER && status === window.CONSTANT.EVENT_STATUS.OPEN && (
                            <EventForm
                                ref={editEventFormRef}
                                allowEdit
                                dataEdit={dataEditEvent}
                                fanpageId={fanpage._id}
                            />
                        )}
                    </Modal>

                    {/* List member */}
                    <Modal
                        id="modal-list-member"
                        title={window.I18N('list_member')}
                        hideFooter
                        styleBody={{
                            overflow: 'overlay',
                            overscollBehavior: 'contain'
                        }}
                        handleCloseModal={handleCloseListMemberPopup}
                    >
                        <InfiniteScroll
                            dataLength={dataMembers.length}
                            next={getMoreMembers}
                            hasMore={isStillMore}
                            loader={<div className="text-center py-2 ">{`${window.I18N('loading')}...`}</div>}
                        >
                            <div style={{ minHeight: '250px' }}>
                                {dataMembers?.map((item) => (
                                    <div key={item.user._id} className="mb-3 d-flex" style={{ gap: '8px' }}>
                                        <Avatar alt={item.user.nickname} src={item.user.avatar} />
                                        <div className="d-flex flex-column align-items-start justify-content-start">
                                            <span className="text-center">{item.user.nickname}</span>
                                            <span className="text-center">{item.user.email}</span>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </InfiniteScroll>
                    </Modal>

                    {/* List member scan QR */}
                    <Modal
                        id="modal-list-scaned-member"
                        title={window.I18N('members_scan_qrcode')}
                        hideFooter
                        styleBody={{
                            overflow: 'overlay',
                            overscollBehavior: 'contain'
                        }}
                        handleCloseModal={handleCloseListMemberScanedPopup}
                    >
                        <InfiniteScroll
                            dataLength={dataMembers.length}
                            next={getMoreMembers}
                            hasMore={isStillMore}
                            loader={<div className="text-center py-2 ">{`${window.I18N('loading')}...`}</div>}
                        >
                            <div style={{ minHeight: '250px' }}>
                                {dataScanedMembers?.map((item) => (
                                    <div key={item.user._id} className="mb-3 d-flex" style={{ gap: '8px' }}>
                                        <Avatar alt={item.user.nickname} src={item.user.avatar} />
                                        <div className="d-flex flex-column align-items-start justify-content-start">
                                            <span className="text-center">{item.user.nickname}</span>
                                            <span className="text-center">{item.user.email}</span>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </InfiniteScroll>
                    </Modal>

                    {/* List member for set allow scan QR */}
                    <Modal
                        id="modal-scan-event"
                        title={window.I18N('members_scan_qrcode')}
                        styleBody={{
                            position: 'relative',
                            overflow: 'overlay',
                            overscollBehavior: 'contain'
                        }}
                        saveBtnProps={{
                            disabled:
                                window.COMMON.isEmpty(dataSelectedScanedMembers) ||
                                status === window.CONSTANT.EVENT_STATUS.EXPIRED
                        }}
                        saveEvent={handleMembersScanEvent}
                    >
                        {/* ===================================== */}
                        <InfiniteScroll
                            style={{ marginTop: '8px' }}
                            dataLength={dataMembers.length}
                            next={getMoreMembers}
                            hasMore={isStillMore}
                            loader={<div className="text-center py-2 ">{`${window.I18N('loading')}...`}</div>}
                        >
                            <div style={{ minHeight: '250px' }}>
                                {dataMembers?.map((item) =>
                                    window.COMMON.getValueFromAttr(item, 'user._id') !== USER_ID ? (
                                        <div
                                            key={item.user._id}
                                            className="mb-3 d-flex align-items-center"
                                            style={{ gap: '8px' }}
                                        >
                                            <MDBInput
                                                outline
                                                type="checkbox"
                                                disabled={status === window.CONSTANT.EVENT_STATUS.EXPIRED}
                                                id={'ck-scan-members-' + item.user._id}
                                                name="checkboxScanMembers"
                                                containerClass="p-0 mb-0 mr-2 mt-2"
                                                filled
                                                checked={dataSelectedScanedMembers.includes(item.user._id)}
                                                onChange={(event) => handleChangeScanedMembers(event, item.user._id)}
                                            ></MDBInput>
                                            <Avatar alt={item.user.nickname} src={item.user.avatar} />
                                            <div className="d-flex flex-column align-items-start justify-content-start">
                                                <span className="text-center">{item.user.nickname}</span>
                                                <span className="text-center">{item.user.email}</span>
                                            </div>
                                        </div>
                                    ) : (
                                        <Fragment key={Math.random()}></Fragment>
                                    )
                                )}
                            </div>
                        </InfiniteScroll>
                    </Modal>
                </div>
            )}
        </>
    )
}

const mapStateToProps = (state) => ({
    language: state.language.value
})
const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(EventDetail)
