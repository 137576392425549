import React, { useRef, useState, useCallback, useLayoutEffect } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { MDBRow, MDBCol, MDBIcon, MDBBtn } from 'mdbreact';

import Panel from '../../components/Panel';
import Modal from '../../components/Modal';
import GridItemSelfAssessment from '../../components/GridItemSelfAssessment';
import ItemQAInfluencer from '../../components/ItemQAInfluencer';
import ItemPreviewSelfAssessment from '../../components/ItemPreviewSelfAssessment';

import AccountGRAPHQL from '../../../graphql/Account';
import QuestionGRAPHQL from '../../../graphql/Question';
import CaseAssessmentGRAPHQL from '../../../graphql/CaseAssessment';
import SelfAssessmentGRAPHQL from '../../../graphql/SelfAssessment';
import { useTracking } from 'react-tracking';
import eventName from '../../../common/events';
import AttributeCompany from '../../components/AttributeCompany';
import moment from 'moment/moment';
import Constant from '../../../utils/Constant';
import { useAdminInfluencerInformation, useSelfAssessmentCreator } from './hooks';
import { useEffect } from 'react';
import ReminderDiv from '../../components/ReminderDiv';
import { Typography } from '@material-ui/core';


const viewTabs = {
  taskAssessment: 'TASK_ASSESSMENT',
  reminderTask: 'REMINDER_TASK'
}
function MySelfAssessmentInfluencerPage(props) {
  const { trackEvent } = useTracking();

  const COMPANY_ID = localStorage.getItem(window.CONSTANT.COMPANY_ID);
  const ACCOUNT_TYPE = localStorage.getItem(window.CONSTANT.ACCOUNT_TYPE);

  const language = useRef();
  const [filter, setFilter] = useState({
    company: null,
    user: null
  });
  const [object, setObject] = useState({ row: -1, col: -1, item: null, title: '', id: null, checkId: null, isSubmited: false, company: null, idAttribute: null, reminderIds: [] });
  const [data, setData] = useState([]);
  const [dataReminder, setDataReminder] = useState([]);
  const [tasksReminder, setTasksReminder] = useState([])
  const [dataQA, setDataQA] = useState([]);
  const [dataFeedback, setDataFeedback] = useState({});
  const [dataCompletedFeedback, setDataCompletedFeedback] = useState([]);
  const [options, setOptions] = useState({
    company: [],
    user: []
  });
  const [historyCompanyAnswer, setHistoryCompanyAnswer] = useState([]);
  const [historyWorkerAnswer, setHistoryWorkerAnswer] = useState([]);
  const [dataAttributeReport, setDataAttributeReport] = React.useState([]);
  const [dataAttributeReportCompany, setDataAttributeReportCompany] = React.useState({});
  const [viewTab, setViewTab] = useState(viewTabs.taskAssessment)
  const [listModalAdvisors, setModalListAdvisors] = useState([])
  const [dataModalReminders, setDataModalReminders]= useState([])

  const { creator: currentAssessmentCreator, setAssessment: setCurrentAssessment } = useSelfAssessmentCreator({});
  const { adminInfluencer: adminInfluencerOfCurrentTask, setId: setInfluencerIdOfCurrentTask } = useAdminInfluencerInformation(null);

  useLayoutEffect(() => {
    if (!props.language.code) {
      return;
    }
    language.current = props.language.code;
    setTimeout(() => {
      getDataCompany();
      getDataTaskReminder();
    }, 100);
    // eslint-disable-next-line
  }, [props.language]);

  const createTrackingEvent = (event) => {
    return trackEvent({
      name: event,
      createdAt: new Date().toISOString()
    });
  };

  const handleFilterSelect = (event, attr) => {
    filter[attr] = event;
    setFilter({ ...filter });
    if (attr === 'company') {
      getDataAccount();
      getDataTaskReminder()
    } else {
      getDataTaskSelfAssessment();
    }
  };

  const getDataCompany = async () => {
    try {
      if (!window.COMMON.checkRoleIsSystem()) {
        if (ACCOUNT_TYPE === window.CONSTANT.INFLUENCER) {
          filter.company = { value: COMPANY_ID, label: '' };
          setFilter({ ...filter });
          getDataAccount();
        } else {
          getDataTaskSelfAssessment();
        }
        return;
      }
      await window.COMMON.getDataAllCompany();
      let dataCompany = JSON.parse(localStorage.getItem(window.CONSTANT.DATA_ALL_COMPANY));
      if (dataCompany && dataCompany.length > 0) {
        dataCompany = window.COMMON.getArrsInArrs(window.CONSTANT.INFLUENCER, dataCompany, 'type');
        options.company = window.COMMON.createDataSelect(dataCompany, '_id', 'information.name');
        filter.company = options.company && options.company.length > 0 ? options.company[0] : null;
      }
      setOptions(options);
      setFilter({ ...filter });
      getDataAccount();
    } catch (error) {
      window.COMMON.showErrorLogs('QuestionReportPage.getDataCompany');
    }
  };

  const getDataAccount = async () => {
    try {
      const params = {}
      if (filter.company !== null) {
        if (window.COMMON.checkRoleIsSystem()){
          params.companyType = window.CONSTANT.INFLUENCER
          params.company =filter.company.value
        } else {
          params.company =COMPANY_ID
          params.companyType = ACCOUNT_TYPE
        }
        const result = await window.COMMON.query(AccountGRAPHQL.QUERY_NOFIFY_ACCOUNT, params);
        if (result && result.data) {
          const data = result.data.getNotifyAccounts.filter(function (item) {
            return item.accountType !== window.CONSTANT.INDIVIDUAL;
          });
          options.user = window.COMMON.createDataSelectWithGroup(data, '_id', 'name', 'accountType', 'accountType');
          filter.user = window.COMMON.getValueFromAttr(options.user, '0.options.0', null);
        }
        setFilter({ ...filter });
        setOptions(options);
        getDataTaskSelfAssessment();
      }
    } catch (error) {
      window.COMMON.showErrorLogs('MySurveyPage.getDataAccount');
    }
  };

  // TASK SELF ASSESSMENT
  const getDataTaskSelfAssessment = async () => {
    try {
      const params = { user: window.COMMON.getDataSelect(filter.user, false) };
      const result = await window.COMMON.query(SelfAssessmentGRAPHQL.QUERY_TASK_SELF_ASSESSMENT_FOR_INFLUENCER, params);
      if (result && result.data) {
        const dataTask = window.COMMON.formatDate(result.data.getTaskSelfAssessmentsForInfluencer, 'createdDate');
        dataTask.forEach(item => {
          item.isCompleted = true;
          item.time = window.COMMON_DATE.formatDate(item.selfAssessment.startDate) + ' - ' + window.COMMON_DATE.formatDate(item.selfAssessment.endDate);
          item.items.forEach(element => {
            if (!element.isCompleted) {
              item.isCompleted = false;
            }
            element.nameLanguage = window.COMMON.getValueWithLanguage(element, 'group.name', language.current);
            element.descriptionLanguage = window.COMMON.getValueWithLanguage(element, 'group.description', language.current);
          });
        });
        object.id = null;
        setObject(object);
        setDataCompletedFeedback([]);
        setData(dataTask);
      } else {
        setData([]);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('MySelfAssessmentInfluencerPage.getDataTaskSelfAssessment');
    }
  };

  //REMINDER TASK
  const getDataTaskReminder = async () => {
    try {
      const params = {}
      if (window.COMMON.checkRoleIsSystem()){
        params.influencerCompanyId = filter.company?.value
      } else {
        params.influencerCompanyId =COMPANY_ID
      }
      if (params.influencerCompanyId){
        const result = await window.COMMON.query(CaseAssessmentGRAPHQL.QUERY_REMINDERS_TASK, {input: params});
        if (result && result.data) {
          const dataReminder = window.COMMON.formatMultiDate(result.data.queryRemindersTask, 'reminderDate,eventDate,createdDate,updatedDate');
          dataReminder.forEach(task=>{
            task.assignUsers = task.assignUsers.map(advisor=>({value: advisor._id, label: advisor.name}))
          })
          setDataReminder(dataReminder)
        }
      }
    } catch (error) {
      window.COMMON.showErrorLogs('MySelfAssessmentInfluencerPage.getDataTaskReminder');
    }
  };

  // QUESTION & ANSWER
  const getDataQuestion = async () => {
    try {
      const groupId = data[object.row].items[object.col].group._id;
      const params = {
        groupId
      };
      const result = await window.COMMON.query(QuestionGRAPHQL.QUERY_QUESTION, params);
      const dataQA = [];
      if (result && result.data) {
        result.data.getQuestions.forEach(obj => {
          dataQA.push({
            uuid: window.COMMON.getValueFromAttr(obj, '_id', null),
            group: groupId,
            question: {
              _id: window.COMMON.getValueFromAttr(obj, '_id', null),
              question: window.COMMON.getDataWithLanguage(obj, 'question'),
              introduction: window.COMMON.getDataWithLanguage(obj, 'introduction'),
              type: window.COMMON.getValueFromAttr(obj, 'type'),
              code: window.COMMON.getValueFromAttr(obj, 'code'),
              important: window.COMMON.getValueFromAttr(obj, 'important')
            },
            answers: []
          });
        });
      }
      getDataAnswerQuestion(groupId, dataQA);
    } catch (error) {
      console.error("🚀 ~ getDataQuestion ~ error:", error)
      window.COMMON.showErrorLogs('MySelfAssessmentInfluencerPage.getDataQuestion');
    }
  };

  const getDataAnswerQuestion = async (groupId, dataQA) => {
    try {
      const params = {
        groupId
      };
      const result = await window.COMMON.query(QuestionGRAPHQL.QUERY_ANSWER_QUESTION, params);
      if (result && result.data) {
        result.data.getAnswerQuestions.forEach(obj => {
          const item = window.COMMON.getObjectInArrs(obj.question, dataQA, 'uuid');
          if(!window.COMMON.isEmpty(item?.answers)){
            item.answers.push({
              _id: window.COMMON.getValueFromAttr(obj, '_id', null),
              content: window.COMMON.getDataWithLanguage(obj, 'content'),
              code: window.COMMON.getValueFromAttr(obj, 'code'),
              parentCode: window.COMMON.getValueFromAttr(obj, 'parentCode', null),
              question: window.COMMON.getValueFromAttr(obj, 'question')
            });
          }
        });
      }
      setDataQA(dataQA);
      getDataFeedback();
      getCompanyHistoryAnswer();
      getWorkerHistoryAnswer();
    } catch (error) {
      console.error("🚀 ~ getDataAnswerQuestion ~ error:", error)
      window.COMMON.showErrorLogs('MySelfAssessmentInfluencerPage.getDataAnswerQuestion');
    }
  };

  const getDataFeedback = async () => {
    try {
      const dataFeedback = {};
      if (!object.item.isCompleted) {
        setDataFeedback({ ...dataFeedback });
        return;
      }
      if (object.checkId === data[object.row]._id && dataCompletedFeedback.length > 0) {
        const obj = window.COMMON.getObjectInArrs(data[object.row].items[object.col].group._id, dataCompletedFeedback, 'group._id');
        generateFeedback(obj);
        return;
      }
      object.checkId = data[object.row]._id;
      setObject(object);
      const params = {
        id: data[object.row]._id
      };
      const result = await window.COMMON.query(SelfAssessmentGRAPHQL.QUERY_FIND_TASK_SELF_ASSESSMENT_BY_ID, params);
      if (result && result.data && result.data.findTaskSelfAssessment && result.data.findTaskSelfAssessment.items) {
        const obj = window.COMMON.getObjectInArrs(data[object.row].items[object.col].group._id, result.data.findTaskSelfAssessment.items, 'group._id');
        setDataCompletedFeedback(result.data.findTaskSelfAssessment.items);
        generateFeedback(obj);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('MySurveyPage.getDataFeedback');
    }
  };

  const generateFeedback = (obj) => {
    const dataFeedback = {};
    obj.feedback && obj.feedback.forEach(item => {
      // TODO init data feedback
      const obj = {
        answerText: item.answerText,
        note: item.note,
        consultantEvaluation: item.consultantEvaluation,
        consultantNote: item.consultantNote,
        documents: item.documents
      };
      if (item.question.type === 'RADIO_GRID' || item.question.type === 'CHECKBOX_GRID') {
        obj.count = 0;
        obj.answers = {};
        item.answers.forEach(element => {
          if (element.parentCode !== null) {
            if (!obj.answers[element.parentCode]) {
              obj.answers[element.parentCode] = [];
              obj.count++;
            }
            obj.answers[element.parentCode].push(element._id);
          }
        });
      } else {
        obj.answers = [];
        item.answers.forEach(element => {
          obj.answers.push(element._id);
        });
      }
      dataFeedback[item.question._id] = obj;
    });
    setDataFeedback({ ...dataFeedback });
  };

  const getCompanyHistoryAnswer = async () => {
    try {
      const params = {
        selfAssessment: data[object.row]?.selfAssessment?._id,
        company: data[object.row]?.company?._id
      };
      const result = await window.COMMON.query(SelfAssessmentGRAPHQL.QUERY_TASK_SELF_ASSESSMENT_BY_SELF_ASSESSMENT, params);
      if (result && result.data && result.data.getTaskSelfAssessmentBySelfAssessments) {
        setHistoryCompanyAnswer(result.data.getTaskSelfAssessmentBySelfAssessments.filter(item => item.type === window.CONSTANT.COMPANY));
      }
    } catch (error) {
      window.COMMON.showErrorLogs('MySelfAssessmentInfluencerPage.getDataFeedback');
    }
  };

  const getWorkerHistoryAnswer = async () => {
    try {
      const params = {
        input: {
          group: data[object.row].items[object.col].group._id,
          companyIds: [data[object.row].company?._id],
          startDate: window.COMMON_DATE.getStrDateFilter(new Date(parseInt(data[object.row].selfAssessment?.startDate)), -1),
          endDate: window.COMMON_DATE.getStrDateFilter(new Date(parseInt(data[object.row].selfAssessment?.endDate)), 1)
        }
      };
      const result = await window.COMMON.query(QuestionGRAPHQL.QUERY_EXPORT_HISTORY_ANSWER, params);
      if (result.data?.getHistoryAnswer?.data) {
        setHistoryWorkerAnswer(result.data.getHistoryAnswer.data);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('MySelfAssessmentInfluencerPage.getDataFeedback');
    }
  };

  // eslint-disable-next-line
  const showContainerGroup = useCallback((row, col) => {
    window.COMMON.showContainerSave();
    object.row = row;
    object.col = col;
    object.item = data[row].items[col];
    object.isSubmited = data[row].status === 1 || data[row].status === 2;
    object.title = window.COMMON.getValueFromAttr(data[row], 'selfAssessment.name') + ' (' + window.COMMON.getValueFromAttr(data[row], 'time') + ')';
    object.company = data[row].company;
    object.reminderIds=[]
    setObject(object);
    getDataQuestion();
  });

  // eslint-disable-next-line
  const openSaveGroup = useCallback((dataSave, deletedFiles) => {
    const feedback = [];
    const files = [];
    const keys = Object.keys(dataSave);
    let index = 0;
    keys.forEach(key => {
      const obj = window.COMMON.getObjectInArrs(key, dataQA, 'question._id');
      const item = {};
      item.question = key;
      item.answers = [];
      item.answerText = dataSave[key].answerText;
      item.note = dataSave[key].note;
      item.consultantEvaluation = dataSave[key].consultantEvaluation;
      item.consultantNote = dataSave[key].consultantNote;
      item.documents = [];
      dataSave[key].documents.forEach(document => {
        item.documents.push({
          id: document.file ? index : document.id,
          name: document.name,
          size: document.size,
          type: document.type,
          ext: document.ext
        });
        if (document.file) {
          index++;
          files.push(document.file);
        }
      });
      if (obj.question.type === 'RADIO_GRID' || obj.question.type === 'CHECKBOX_GRID') {
        const answerKeys = Object.keys(dataSave[key].answers);
        answerKeys.forEach(answerKey => {
          const answer = window.COMMON.getObjectInArrs(answerKey, obj.answers, 'code');
          if (answer) {
            item.answers.push(answer._id);
          }
          item.answers = item.answers.concat(dataSave[key].answers[answerKey]);
        });
      } else {
        item.answers = dataSave[key].answers;
      }
      feedback.push(item);
    });
    window.COMMON.showModal('#modal-save');
    window.saveMethod = () => sendFeedback(files, feedback, deletedFiles);
  });

  const openPreviewSelfAssessment = useCallback((row) => {
    object.row = row;
    object.id = data[row]._id;
    object.isSubmited = data[row].status === 1 || data[row].status === 2;
    window.COMMON.showModal('#modal-preview');
    object.reminderIds= []
    setObject({ ...object });
  }, [object, data]);

  const openViewReminder = useCallback((reminderIds, indexDataTask) => {
    //lưu object để cập nhật lại reminder
    object.row = indexDataTask
    object.id = data[indexDataTask]._id;
    object.reminderIds = reminderIds
    setObject({ ...object });
    //tạo dữ liệu cho modal reminder
    const taskRemind = data[indexDataTask]
    const formatListAdvisors = window.COMMON.createDataSelect(taskRemind.influencers, '_id', 'name').map(el=>({label: el?.label, value: el?.value})) || []
    const listReminders = dataReminder.filter(el=>reminderIds.includes(el._id))
    setModalListAdvisors([...formatListAdvisors]);
    setDataModalReminders([...listReminders])

    window.COMMON.showModal('#modal-reminder');
  },[object, data, dataReminder])

  const sendFeedback = async (files, feedback, deletedFiles) => {
    let dataFiles = []
    try {
      // TODO delete file
      if (deletedFiles && deletedFiles.length > 0) {
        deletedFiles.forEach(file => {
          window.COMMON.deleteFile(file.id);
        });
      }
      // TODO upload documents for self assessment
      if (files.length > 0) {
        dataFiles = await window.uploadFile(files);
        if (dataFiles && dataFiles.length > 0) {
          for (let i = 0; i < dataFiles.length; i++) {
            const file = dataFiles[i];
            for (let j = 0; j < feedback.length; j++) {
              const element = feedback[j];
              const obj = window.COMMON.getObjectInArrs(i, element.documents, 'id');
              if (obj) {
                obj.id = file._id;
                obj.type = file.mime;
                break;
              }
            }
          }
        }
      }
      const params = {
        input: {
          _id: data[object.row]._id,
          group: data[object.row].items[object.col].group._id,
          feedback
        }
      };
      window.COMMON.trimData(params);
      const result = await window.COMMON.mutation(SelfAssessmentGRAPHQL.MUTATION_SEND_FEEDBACK_TASK_SELF_ASSESSMENT, params);
      if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_INSERT)) {
        window.COMMON.backContainerData();
        getDataTaskSelfAssessment();
        createTrackingEvent(eventName.SEND_FEEDBACK_TASK_SELF_ASSESSMENT);

        // clear user draft data
        clearDraftData(data[object.row]._id, data[object.row].items[object.col].group._id);
      }
      window.COMMON.hideModal('#modal-group');
    } catch (error) {
      console.error("🚀 ~ sendFeedback ~ error:", error)
      window.UPLOAD_API.deleteMultiFile(dataFiles.map(item => item._id))      
      window.COMMON.showErrorLogs('MySelfAssessmentInfluencerPage.sendFeedback');
    }
  };

  const submitTaskSelfAssessment = async () => {
    try {
      const params = {
        id: data[object.row]._id
      };
      window.COMMON.trimData(params);
      const result = await window.COMMON.mutation(SelfAssessmentGRAPHQL.MUTATION_SUBMIT_TASK_SELF_ASSESSMENT, params);
      if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_STATUS)) {
        getDataTaskSelfAssessment();
        window.COMMON.hideModal('#modal-preview');
        createTrackingEvent(eventName.SUBMIT_TASK_SELF_ASSESSMENT);
        // send mail notify
        sendSubmitTaskNotificationToCompanies(data[object.row]._id);
        sendSubmitTaskNotificationToAdminInfluencer(adminInfluencerOfCurrentTask);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('MySelfAssessmentInfluencerPage.submitTaskSelfAssessment');
    }
  };

  const closeGroupContainer = () => {
    window.COMMON.backContainerData();
    getDataTaskSelfAssessment();
  };

  const clearDraftData = (assessmentId, groupId) => {
    const userId = localStorage.getItem('id');
    const draft = JSON.parse(localStorage.getItem('draftAssessment') || '[]');

    const updateDataDraft = draft.filter(item => !(item.user === userId && item.assessment === assessmentId && item.group === groupId));
    localStorage.setItem('draftAssessment', JSON.stringify(updateDataDraft));
  };

  const getHistoryAnswerData = () => {
    const feedbackCompany = historyCompanyAnswer.map(h => {
      const result = h.items.find(item => (item.group._id === data[object.row].items[object.col].group._id && item.isCompleted));
      if (result) return result;
    }).filter(item => item);

    return {
      company: feedbackCompany,
      worker: historyWorkerAnswer
    };
  };

  const sendSubmitTaskNotificationToAdminInfluencer = useCallback(async () => {
    try {
      const userFullname = localStorage.getItem(window.CONSTANT.FULL_NAME);
      const adminInfluencerEmail = adminInfluencerOfCurrentTask.information?.email;
      const adminInfluencerName = adminInfluencerOfCurrentTask.information?.name;
      const result = await window.COMMON.sendMailNotify({
        email: adminInfluencerEmail,
        title: window.I18N('submitted_self_assessment_from_influencer'),
        body: `
            <p>Kính gửi ${adminInfluencerName}!</p>
            <p>${userFullname} vừa gửi đánh giá về <b>${data[object.row]?.selfAssessment?.name ?? ''}</b> vào <b>${moment().format('[ngày] DD [tháng] MM')}</b> cho tổ chức <b>${data[object.row].company?.information?.name}</b>. Vui lòng giúp chúng tôi xem xét và xử lý nó.</p>
            <p>Cảm ơn bạn,</p>
            <p>GOPY TEAM</p>
            <br></br>
            <p>Dear ${adminInfluencerName}!</p>
            <p>${userFullname} just submitted a self assessment <b>${data[object.row]?.selfAssessment?.name ?? ''}</b> on <b>${moment().format('MMMM DD')}</b>  <b>${data[object.row].company?.information?.name}</b>. Please help us review and process it.</p>
            <p>Thank you,</p>
            <p>THE GOPY TEAM</p>`
      });
      if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_STATUS)) {
        createTrackingEvent(eventName.SEND_MAIL);
      }
    } catch (error) {
      console.error("🚀 ~ sendSubmitTaskNotificationToAdminInfluencer ~ error:", error)
      window.COMMON.showErrorLogs('MySelfAssessmentInfluencerPage.errorTaskSelfAssessment');
    }
  }, [adminInfluencerOfCurrentTask]);

  const sendSubmitTaskNotificationToCompanies = async (id) => {
    try {
      const users = [];
      if (data[object.row].company?.information) {
        users.push({
          name: data[object.row].company.information.name,
          email: data[object.row].company.information.email
        });
      }
      if (data[object.row].selfAssessment?.createdBy) {
        users.push({
          name: data[object.row].selfAssessment.createdBy.name,
          email: data[object.row].selfAssessment.createdBy.email
        });
      }
      const userFullname = localStorage.getItem(window.CONSTANT.FULL_NAME);
      Promise.all(users.map(async (user) => {
        const result = await window.COMMON.sendMailNotify({
          email: user.email,
          title: window.I18N('submitted_self_assessment_from_influencer'),
          body: `
          <p>Kính gửi ${user.name}!</p>
          <p>${userFullname} vừa gửi đánh giá về <b>${data[object.row]?.selfAssessment?.name ?? ''}</b> vào <b>${moment().format('[ngày] DD [tháng] MM')}</b> cho tổ chức <b>${data[object.row].company?.information?.name}</b>. Vui lòng giúp chúng tôi xem xét và xử lý nó.</p>
          <p>Cảm ơn bạn,</p>
          <p>GOPY TEAM</p>
          <br></br>
          <p>Dear ${user.name}!</p>
          <p>${userFullname} just submitted a self assessment <b>${data[object.row]?.selfAssessment?.name ?? ''}</b> on <b>${moment().format('MMMM DD')}</b>  <b>${data[object.row].company?.information?.name}</b>. Please help us review and process it.</p>
          <p>Thank you,</p>
          <p>THE GOPY TEAM</p>`
        });
        if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_STATUS)) {
          createTrackingEvent(eventName.SEND_MAIL);
        }
      }));
    } catch (error) {
      console.error("🚀 ~ sendSubmitTaskNotificationToCompanies ~ error:", error)
      window.COMMON.showErrorLogs('MySelfAssessmentInfluencerPage.errorTaskSelfAssessment');
    }
  };

  // ATTRIBUTE REPORT
  const getDataAttributeReport = async () => {
    try {
      const result = await window.COMMON.query(SelfAssessmentGRAPHQL.QUERY_ATTRIBUTE_REPORT, null);
      if (result != null) {
        let index = 0;
        result.data.getAttributeReports.forEach(item => {
          item.idx = index;
          index++;
        });
        setDataAttributeReport(result.data.getAttributeReports);
      }
    } catch (error) {
      console.log(error);
      window.COMMON.showErrorLogs('MySelfAssessmentInfluencerPage.getDataAttributeReport');
    }
  };

  // ATTRIBUTE REPORT COMPANY
  const getDataAttributeReportCompany = async () => {
    try {
      const params = { company: object.company?._id };
      const result = await window.COMMON.query(SelfAssessmentGRAPHQL.QUERY_ATTRIBUTE_REPORT_COMPANY, params);
      if (result && result.data && result.data.findAttributeReportCompany) {
        const data = result.data.findAttributeReportCompany;
        object.idAttribute = data._id;
        setDataAttributeReportCompany(data);
      } else {
        object.idAttribute = null;
        setDataAttributeReportCompany({});
      }
      setObject(object);
    } catch (error) {
      window.COMMON.showErrorLogs('MySelfAssessmentInfluencerPage.getDataAttributeReportCompany');
    }
  };

  const saveAttributeCompany = async (data) => {
    try {
      const params = {
        input: {
          company: object.company?._id,
          items: data
        }
      };
      window.COMMON.trimData(params);
      if (!object.idAttribute) {
        const result = await window.COMMON.mutation(SelfAssessmentGRAPHQL.MUTATION_ADD_ATTRIBUTE_REPORT_COMPANY, params);
        if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_INSERT)) {
          getDataAttributeReportCompany();
          window.COMMON.backContainerData();
          createTrackingEvent(eventName.ADD_ATTRIBUTE_REPORT_COMPANY);
        }
      } else {
        params.input._id = object.idAttribute;
        const result = await window.COMMON.mutation(SelfAssessmentGRAPHQL.MUTATION_EDIT_ATTRIBUTE_REPORT_COMPANY, params);
        if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_UPDATE)) {
          getDataAttributeReportCompany();
          window.COMMON.backContainerData();
          createTrackingEvent(eventName.EDIT_ATTRIBUTE_REPORT_COMPANY);
        }
      }
    } catch (error) {
      window.COMMON.showErrorLogs('RewardPage.saveReward');
    }
  };

  const showAttributeReportCompany = async () => {
    await getDataAttributeReport();
    await getDataAttributeReportCompany();
    window.COMMON.showContainerSave(false, true);
  };

  const handleEditReminder = async(paramReminder) =>{
    const reminderInput = paramReminder
    const result = await window.COMMON.mutation(CaseAssessmentGRAPHQL.MUTATION_UPDATE_REMINDER_TASK, { reminderInput });    
    if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_UPDATE)) {
      //can check lai
      getDataTaskReminder();
      createTrackingEvent(eventName.UPDATE_REMIND_TASK);
    }
  }

  const handleCancelReminder = async(paramId) =>{
    const result = await window.COMMON.mutation(CaseAssessmentGRAPHQL.MUTATION_CANCEL_REMINDER_TASK, {reminderId: paramId} );
    if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_UPDATE)) {
      //can check lai
      getDataTaskReminder();
      createTrackingEvent(eventName.CANCEL_REMIND_TASK);
    }
  }

  const closeAttributeCompany = () => {
    window.COMMON.backContainerData();
    window.COMMON.showContainerSave();
  };

  // eslint-disable-next-line
  const openSaveAttributeCompany = useCallback((data) => {
    window.COMMON.showModal('#modal-save');
    window.saveMethod = () => saveAttributeCompany(data);
  });

  // fetch assessment creator on task change.
  useEffect(() => {
    if (object.row > -1) {
      const task = data[object.row];
      setCurrentAssessment(task.selfAssessment);
    }
    // eslint-disable-next-line
  }, [object.row, data]);

  // fetch influencer admin on assessment creator change;
  useEffect(() => {
    const influencerId = currentAssessmentCreator?.influencer?._id || '';
    if (influencerId) {
      setInfluencerIdOfCurrentTask(influencerId);
    }
    // eslint-disable-next-line
  }, [currentAssessmentCreator]);

  useEffect(()=>{
    if (data.length && dataReminder.length){
      let dataTaskReminder = []
      data.forEach((task ,indexData) =>{
        const listIdsReminderOfTask = dataReminder.filter(reminder=> reminder.task._id===task._id).map(reminder=>reminder._id)
        if (listIdsReminderOfTask?.length){
          const cloneDataTask = JSON.parse(JSON.stringify(task))
          cloneDataTask.reminderIds = listIdsReminderOfTask
          cloneDataTask.indexDataTask = indexData
          dataTaskReminder.push(cloneDataTask)
        }
      })
      if (object.reminderIds?.length && object.row){
        const formatListAdvisors = window.COMMON.createDataSelect(data[object.row].influencers, '_id', 'name').map(el=>({label: el?.label, value: el?.value})) || []
        const listReminders = dataReminder.filter(el=>object.reminderIds.includes(el._id))
        setModalListAdvisors([...formatListAdvisors]);
        setDataModalReminders([...listReminders])
      }
      setTasksReminder(dataTaskReminder)
    }
  },[data, dataReminder])
  
  if (typeof window === 'undefined') {
    return null;
  }

  return (
    <div className="container-row">
      <div id="container-data">
        <ul className="list-group">
          <li className="list-group-item d-flex justify-between align-items-center" style={{ zIndex: 999 }}>
            <div>
              <div className="d-flex w-auto align-items-center justify-content-start fadeInDown animated"  data-wow-delay="0.5s">
                <MDBBtn color={viewTab === viewTabs.taskAssessment ? 'primary' :''} className="mr-0" onClick={() => setViewTab(viewTabs.taskAssessment)}>
                  <MDBIcon fa="true" icon="user-tie" className="fa-fw" /> {window.I18N('task_assessment')}
                </MDBBtn>
                {<MDBBtn color={viewTab === viewTabs.reminderTask ? 'primary' : ''} className="mr-0" onClick={() => setViewTab(viewTabs.reminderTask)}>
                  <MDBIcon fa="true" icon="user-edit" className="fa-fw" /> {window.I18N('reminder_task')}
                </MDBBtn>}
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-end flex-grow-1">
            {
              window.COMMON.checkRoleIsSystem() || ACCOUNT_TYPE === window.CONSTANT.INFLUENCER ? <div className="d-flex pt-2 pb-2 align-items-center justify-content-end fadeInDown animated" data-wow-delay="0.5s" style={{ zIndex: 1 }}>
                {
                  !window.COMMON.checkRoleIsSystem() ? <div></div> : <div className="bd-highlight mr-3" style={{ width: '300px' }}>
                    <div className="input-group input-group-transparent">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <MDBIcon fa="true" icon="building" className="fa-fw" />
                        </span>
                      </div>
                      <Select className="md-form m-0" placeholder={window.I18N('choose_option')} value={filter.company} options={options.company} onChange={(event) => handleFilterSelect(event, 'company')} isSearchable />
                    </div>
                  </div>
                }
                <div className="bd-highlight" style={{ width: '300px' }}>
                  <div className="input-group input-group-transparent">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <MDBIcon fa="true" icon="user" className="fa-fw" />
                      </span>
                    </div>
                    <Select className="md-form m-0" placeholder={window.I18N('choose_option')} value={filter.user} options={options.user} onChange={(event) => handleFilterSelect(event, 'user')} isSearchable />
                  </div>
                </div>
              </div> : <div></div>
            }
            </div>
          </li>
          {viewTab === viewTabs.taskAssessment &&<GridItemSelfAssessment data={data} showCompany={true} handleItemClick={showContainerGroup} handlePreviewClick={openPreviewSelfAssessment}></GridItemSelfAssessment>}
          {viewTab === viewTabs.reminderTask &&<GridItemSelfAssessment data={tasksReminder} showCompany={true} handleItemClick={showContainerGroup} handlePreviewClick={openPreviewSelfAssessment} handleViewReminder={openViewReminder}></GridItemSelfAssessment>}

        </ul>
      </div>

      <Panel id="container-save" title={object.title} icon="question-circle" style={{ display: 'none' }}>
        <ItemQAInfluencer
          assessmentId={data[object.row]?._id}
          company={object.company}
          group={object.item}
          dataFeedback={dataFeedback}
          dataHistoryAnswer={getHistoryAnswerData()}
          isSubmited={object.isSubmited}
          data={dataQA}
          language={language.current}
          isSelfAssessment={true}
          isCompany={true}
          handleSubmit={openSaveGroup}
          handleClose={closeGroupContainer}
          handleViewFacility={showAttributeReportCompany}
        />
      </Panel>

      <Panel id="container-attribute-report" title={window.I18N('self_organization')} icon="info-circle" style={{ display: 'none' }}>
        <AttributeCompany
          data={dataAttributeReport}
          company={props.company?.info}
          companyId={object.company?._id}
          dataCompany={dataAttributeReportCompany.items || []}
          handleClose={closeAttributeCompany}
          handleSave={openSaveAttributeCompany}
          readonly={ACCOUNT_TYPE !== Constant.COMPANY || object.company?._id !== COMPANY_ID}
        />
      </Panel>

      <Modal id="modal-preview" className="modal-xl" title={window.I18N('preview_self')} hideSave={object.isSubmited || ACCOUNT_TYPE === window.CONSTANT.INFLUENCER} saveEvent={submitTaskSelfAssessment} saveBtn={window.I18N('btn_submit')}>
        <MDBRow>
          <MDBCol>
            <ItemPreviewSelfAssessment type={0} id={object.id} company={object.company} />
          </MDBCol>
        </MDBRow>
      </Modal>

      <Modal hideFooter id="modal-reminder" className="modal-lg" title={<Typography variant="h6">{window.I18N('list_reminder')}</Typography>} hideSave={true} >
        <MDBRow>
          <MDBCol>
          <div >
        {dataModalReminders.length===0 && <div style={{height:'390px'}}>
                {window.I18N('empty_data')}
        </div>}
        {dataModalReminders.map((reminder)=>
        <div key={reminder._id} className='d-flex flex-column py-2 ' >
          <ReminderDiv canModify={ACCOUNT_TYPE === window.CONSTANT.INFLUENCER || window.COMMON.checkRoleIsSystem() } listAdvisors={listModalAdvisors} reminder={reminder} handleEditReminder={handleEditReminder} handleCancelReminder={handleCancelReminder} ></ReminderDiv>
        </div>
        )}
      </div>
          </MDBCol>
        </MDBRow>
      </Modal>
    </div>
  );
}

const mapStateToProps = state => ({
  language: state.language.value
});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(MySelfAssessmentInfluencerPage);