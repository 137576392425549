import mimi1 from '../styles/images/icons_sticker/mimi1.gif'
import mimi2 from '../styles/images/icons_sticker/mimi2.gif'
import mimi3 from '../styles/images/icons_sticker/mimi3.gif'
import mimi4 from '../styles/images/icons_sticker/mimi4.gif'
import mimi5 from '../styles/images/icons_sticker/mimi5.gif'
import mimi6 from '../styles/images/icons_sticker/mimi6.gif'
import mimi7 from '../styles/images/icons_sticker/mimi7.gif'
import mimi8 from '../styles/images/icons_sticker/mimi8.gif'
import mimi9 from '../styles/images/icons_sticker/mimi9.gif'
import IconOrganization from '../styles/images/icons/ic_organization.png'
import IconEmployee from '../styles/images/icons/ic_employee.png'
import IconResponse from '../styles/images/icons/ic_response.png'
import IconQA from '../styles/images/icons/ic_qa.png'
import IconPost from '../styles/images/icons/ic_post.png'
import IconECourse from '../styles/images/icons/ic_ecourse.png'
import IconGrievance from '../styles/images/icons/ic_grievance.png'
import IconSurvey from '../styles/images/icons/ic_survey.png'
import IconAssessment from '../styles/images/icons/ic_assessment.png'
import Gcoin from '../styles/images/icons/ic_gcoin.png'
import Calendar from '../styles/images/icons/ic-calendar.png'
import Reject from '../styles/images/icons/ic-ban.png'

const Constant = {
    FROM_FILTER_DATE: -1,
    TO_FILTER_DATE: 1,
    SUCCESSFULLY: 1,
    FAILURE: -1,
    MODE_INSERT: 1,
    MODE_UPDATE: 2,
    MODE_DELETE: -1,
    MODE_IMPORT: 3,
    MODE_STATUS: 4,
    MODE_APPROVE: 5,
    MODE_DECLINE: -2,
    VALUE_PROFILE: 999999999,
    MAX_TEXT_LENGTH: 500,
    MAX_INPUT_NUMBER: 10000000,
    IS_LOGIN: 'isLogin',
    IS_SAVED_ACCOUNT: 'isSavedAccount',
    ID: 'id',
    FULL_NAME: 'fullname',
    AVATAR: 'avatar',
    EMAIL: 'email',
    AUTHEN_TOKEN: 'authenToken',
    COMPANY_ID: 'company',
    PACKAGE_ID: 'package',
    COMPANY_TYPE: 'companyType',
    ACCOUNT_TYPE: 'accountType',
    DATA_ROLE: 'dataRole',
    DATA_CONFIG: 'dataConfig',
    LANGUAGE_DEFAULT: 'EN',
    LANGUAGE: 'language',
    DATA_COMPANY: 'dataCompany',
    DATA_ALL_COMPANY: 'dataAllCompany',
    ACCOUNT_COMPANIES: 'accountCompanies',
    SYNC_INSPECTOR: 'sync_inspection',
    SUPER_ADMIN: 'SUPER_ADMIN',
    ADMIN: 'ADMIN',
    COMPANY: 'COMPANY',
    UNION: 'UNION',
    INFLUENCER: 'INFLUENCER',
    GOPY_INFLUENCER: 'GOPY_INFLUENCER',
    INDIVIDUAL: 'INDIVIDUAL',
    COMPANY_BACK: 'COMPANY_BACK',
    DRAFT_SURVEYS: 'DRAFT_SURVEYS',
    SURVEY_DRAFT_CODE: 'SURVEY_DRAFT_CODE',
    INFLUENCER_INPUT: 'INFLUENCER_INPUT',
    INFLUENCER_BACK: 'INFLUENCER_BACK',
    DRAFT_SCHEDULE_ASSESSMENT: 'DRAFT_SCHEDULE_ASSESSMENT',
    DRAFT_SCHEDULE_ASSESSMENT_CODE: 'DRAFT_SCHEDULE_ASSESSMENT_CODE',
    ONLY_CREATE: 'ONLY_CREATE',
    GROUP_QA_CLONE: 'GROUP_QA_CLONE',
    BACKGROUND_COLOR: [
        'rgba(255, 99, 132, 0.7)',
        'rgba(54, 162, 235, 0.7)',
        'rgba(255, 206, 86, 0.7)',
        'rgba(75, 192, 192, 0.7)',
        'rgba(153, 102, 255, 0.7)',
        'rgba(255, 159, 64, 0.7)',
        'rgba(255, 99, 132, 0.7)',
        'rgba(54, 162, 235, 0.7)',
        'rgba(255, 206, 86, 0.7)',
        'rgba(75, 192, 192, 0.7)',
        'rgba(153, 102, 255, 0.7)',
        'rgba(255, 159, 64, 0.7)'
    ],
    BORDER_COLOR: [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 64, 1)',
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 64, 1)'
    ],
    STICKERS: [
        { value: 'mimi1', icon: mimi1 },
        { value: 'mimi2', icon: mimi2 },
        { value: 'mimi3', icon: mimi3 },
        { value: 'mimi4', icon: mimi4 },
        { value: 'mimi5', icon: mimi5 },
        { value: 'mimi6', icon: mimi6 },
        { value: 'mimi7', icon: mimi7 },
        { value: 'mimi8', icon: mimi8 },
        { value: 'mimi9', icon: mimi9 }
    ],
    ICON_ORGANIZATION: IconOrganization,
    ICON_EMPLOYEE: IconEmployee,
    ICON_RESPONSE: IconResponse,
    ICON_QA: IconQA,
    ICON_POST: IconPost,
    ICON_ECOURSE: IconECourse,
    ICON_GRIEVANCE: IconGrievance,
    ICON_SURVEY: IconSurvey,
    ICON_ASSESSMENT: IconAssessment,
    G_COIN: Gcoin,
    CALENDAR: Calendar,
    REJECT: Reject,
    DATA_EDIT_BILL: 'Data_Edit_Bill',
    TOTAL_SIZE_SELF_ASSESSMENT: 500 * 1024 * 1024,
    DATA_TEMPLATE_PDF: [
        {
            key: 'SELF_REPORT_DATA',
            name: 'SelfAssessment_Report.pdf'
        }
    ],
    CHAT_ITEM_MENU: {
        action: 'chat',
        children: [],
        code: 'M008chat',
        icon: 'rocketchat',
        name: 'Chat',
        parent: null,
        rights: [],
        _id: '1f448f4f252ecb002ba6d418'
    },
    PRIVATE_CHAT: 'PRIVATE_CHAT',
    FRIEND: 'FRIEND',
    SEARCH_RESULT_TITLE_OBJ: {
        employees: 'employees',
        friendsInCompany: 'friends_in_company',
        friendsOutCompany: 'friends',
        userResult: 'list_users',
        groups: 'groups',
        private_groups: 'private_groups',
        public_groups: 'public_groups'
    },
    EMPLOYEE: 'EMPLOYEE',
    RECEIVER: 'RECEIVER',
    CREATE: 'CREATE',
    PENDING: 'PENDING',
    ACCEPTED: 'ACCEPTED',
    TEXT: 'TEXT',
    ARRAY: 'ARRAY',
    GROUP: 'GROUP',
    PRIVATE_GROUP: 'PRIVATE_GROUP',
    PUBLIC: 'PUBLIC',
    PRIVATE: 'PRIVATE',
    DOCUMENT_EXTENSION: ['doc', 'docx', 'odt', 'pdf', 'xls', 'xlsx', 'ods', 'ppt', 'pptx', 'txt', 'mp3', 'm4a'],
    AUDIO_EXTENSION: ['mp3', 'm4a'],
    NICK_NAME: 'nickname',
    SELF_ASSESSMENT_STATUS: {
        OPEN: 0,
        RUNNING: 1,
        EXPIRED: 2,
        COMPLETED: 3
    },
    SURVEY_STATUS: {
        NEW: 0,
        PROCESS: 1,
        FINISHED: 2,
        PAUSED: -1,
        STOPPED: -2
    },
    CASE_ASSESSMENT_STATUS: {
        PENDING: 'PENDING',
        APPROVED: 'APPROVED',
        REJECTED: 'REJECTED',
        RUNNING: 'RUNNING',
        EVALUATING: 'EVALUATING',
        CLOSED: 'CLOSED'
    },
    METADATA_TYPE: {
        COMPANY_TYPE: 'COMPANY_TYPE',
        COMPANY_SIZE: 'COMPANY_SIZE',
        COMPANY_INDUSTRY: 'COMPANY_INDUSTRY',
        TEXT_INPUT: 'TEXT_INPUT',
        COMPLAINT_TYPE: 'COMPLAINT_TYPE',
        FANPAGE_TYPE: 'FANPAGE_TYPE',
        INDIVIDUAL_AGE: 'INDIVIDUAL_AGE',
        INDIVIDUAL_OCCUPATION: 'INDIVIDUAL_OCCUPATION',
        INDIVIDUAL_JOB_TITLE: 'INDIVIDUAL_JOB_TITLE',
        INDIVIDUAL_EDUCATION_BACKGROUND: 'INDIVIDUAL_EDUCATION_BACKGROUND',
        INDIVIDUAL_INCOME: 'INDIVIDUAL_INCOME'
    },
    FANPAGE: {
        ACCEPTED: 'ACCEPTED',
        PENDING: 'PENDING',
        INVITED: 'INVITED',
        FEEDS_MODE: 'feed',
        DISCOVER_MODE: 'discover',
        DETAIL_MODE: 'detail',
        CREATE_MODE: 'new-fanpage',
        PRIVATE: 'PRIVATE',
        PUBLIC: 'PUBLIC',
        OWNER: 'OWNER',
        REASON_TYPE: 'REPORT_POST',
        TAB_POSTS: 'TAB_POSTS',
        TAB_MEMBERS: 'TAB_MEMBERS',
        TAB_MANAGE_FANPAGE: 'TAB_MANAGE_FANPAGE',
        TAB_APPROVE_POST: 'TAB_APPROVE_POST',
        TAB_EVENT_FANPAGE: 'TAB_EVENT_FANPAGE'
    },
    BILL_PAYMENT_STATUS: {
        PENDING: 0,
        PAID: 1,
        WAITING: 2,
        DENIED: 3
    },
    GROUP_DIFFERENCE_FEEDBACK: {
        '0 - 25%': 25,
        '25% - 50%': 50,
        '50% - 75%': 70,
        '75% - 100%': 100
    },
    MIN_STANDARD_OF_CATEGORY: 20,
    MAX_STANDARD_OF_CATEGORY: 80,
    EVENT_STATUS: {
        DELETED: -1,
        OPEN: 0,
        EXPIRED: 1
    },
    EVENT_ROLE: {
        MEMBER: 'MEMBER',
        OWNER: 'OWNER'
    },
    TIME_IN_PAST: -1,
    TIME_IN_FUTURE: 1
}

export default Constant
