import React, { useRef, useState, useCallback, useLayoutEffect, useEffect } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { MDBRow, MDBCol, MDBIcon, MDBBtn } from 'mdbreact';

import Panel from '../../components/Panel';
import Modal from '../../components/Modal';

import AccountGRAPHQL from '../../../graphql/Account';
import QuestionGRAPHQL from '../../../graphql/Question';
import SelfAssessmentGRAPHQL from '../../../graphql/SelfAssessment';
import { useTracking } from 'react-tracking';
import eventName from '../../../common/events';
import ItemQAInfluencer from '../../components/ItemQAInfluencer';
import GridItemShareSelfAssessmentAdmin from '../../components/GridItemShareSelfAssessmentAdmin';
import GridItemShareSelfAssessment from '../../components/GridItemShareSelfAssement';
import GridItem from '../../components/GridItem';
import ItemPreviewShareAssessment from '../../components/ItemPreviewShareAssessment';
import AttributeCompany from '../../components/AttributeCompany';
import moment from 'moment';
import { useAdminInfluencerInformation, useSelfAssessmentCreator } from './hooks';

function MySurveyPage(props) {
  const { trackEvent } = useTracking();

  const typeOptions = [
    { value: '1', label: window.I18N('periodic_assessment') },
    { value: '2', label: window.I18N('requested_assessment') }
  ];
  
  const periodOptions = [
    { value: '1', label: window.I18N('annually') },
    { value: '2', label: window.I18N('half_year') }
  ];

  const USER_ID = localStorage.getItem(window.CONSTANT.ID);
  const COMPANY_ID = localStorage.getItem(window.CONSTANT.COMPANY_ID);
  const COMPANY_TYPE = localStorage.getItem(window.CONSTANT.COMPANY_TYPE);

  const language = useRef();
  const [filter, setFilter] = useState({ company: null });
  const [object, setObject] = useState({ index: -1, item: null, company: null });
  const [dataSelfAssessment, setDataSelfAssessment] = useState([]);
  const [dataFeedback, setDataFeedback] = useState({});
  const [dataQA, setDataQA] = useState([]);
  const [select, setSelect] = useState({});
  const [options, setOptions] = useState({
    company: [],
    users: []
  });
  const [assignData, setAssignData] = useState([]);
  const [accountList, setAccountList] = useState([]);
  const [companyAnswer, setCompanyAnswer] = useState([]);
  const [advisorAnswer, setAdvisorAnswer] = useState([]);
  const [workerAnswer, setWorkerAnswer] = useState([]);
  const [viewIndex, setViewIndex] = useState(0); //0: leader view, 1: assigned view  
  const [previewData, setPreviewData] = useState({
    feedback: null,
    groupIds: null
  });
  const [dataAttributeReport, setDataAttributeReport] = React.useState([]);
  const [dataAttributeReportCompany, setDataAttributeReportCompany] = React.useState({});

  const { creator: currentAssessmentCreator, setAssessment: setCurrentAssessment } = useSelfAssessmentCreator({});
  const { adminInfluencer: adminInfluencerOfCurrentTask, setId: setInfluencerIdOfCurrentTask } = useAdminInfluencerInformation(null);

  useLayoutEffect(() => {
    if (!props.language.code) {
      return;
    }
    language.current = props.language.code;
    setTimeout(() => {
      getDataCompany();
    }, 100);
    // eslint-disable-next-line
  }, [props.language]);

  const createTrackingEvent = (event) => {
    return trackEvent({
      name: event,
      createdAt: new Date().toISOString()
    });
  };

  const handleFilterSelect = (event) => {
    filter.company = event;
    setFilter({ ...filter });
    getDataAccount();
    getDataSelfAssessment();
  };

  const handleSelect = (event, id, attr) => {
    window.COMMON.checkSelectValidation(id, event);
    select[attr] = event;
    setSelect({ ...select });
  };

  // COMPANY
  const getDataCompany = async () => {
    try {
      if (!window.COMMON.checkRoleIsSystem()) {
        filter.company = { value: COMPANY_ID, label: '', code: COMPANY_TYPE };
        setFilter({ ...filter }); 
        getDataAccount();
        getDataSelfAssessment();
        return;
      }
      await window.COMMON.getDataAllCompany();
      let dataCompany = JSON.parse(localStorage.getItem(window.CONSTANT.DATA_ALL_COMPANY));
      if (dataCompany && dataCompany.length > 0) {
        dataCompany = window.COMMON.getArrsInArrs(window.CONSTANT.INFLUENCER, dataCompany, 'type');
        options.company = window.COMMON.createDataSelect(dataCompany, '_id', 'information.name');
        filter.company = options.company && options.company.length > 0 ? options.company[0] : null;
      }
      setOptions(options);
      setFilter({ ...filter });
      getDataAccount();
      getDataSelfAssessment();
    } catch (error) {
      window.COMMON.showErrorLogs('AccountPage.getDataCompany');
    }
  };

  // ACCOUNT
  const getDataAccount = async () => {
    try {
      if (filter.company !== null) {
        const params = {
          company: filter.company.value,
          companyType: filter.company.code
        };
        const result = await window.COMMON.query(AccountGRAPHQL.QUERY_ACCOUNT, params);
        if (result && result.data) {
          const data = result.data.getAccounts.filter(function(item) {
            return item.accountType !== window.CONSTANT.INDIVIDUAL && item._id !== USER_ID;
          });
          options.users = window.COMMON.createDataSelectWithGroup(data, '_id', 'nickname', 'accountType', 'accountType');
		  setAccountList(data);
        }
      }
    } catch (error) {
      window.COMMON.showErrorLogs('MySurveyPage.getDataAccount');
    }
  };

  // SELF ASSESSMENT
  const getDataSelfAssessment = async () => {
    try {
      const params = {
        user: USER_ID
      };
      if (window.COMMON.checkRoleIsSystem()) {
        const dataCompany = JSON.parse(localStorage.getItem(window.CONSTANT.DATA_ALL_COMPANY));
        const obj = window.COMMON.getObjectInArrs(filter.company.value, dataCompany, '_id');
        params.user = window.COMMON.getValueFromAttr(obj, 'information._id');
      }
      const result = await window.COMMON.query(SelfAssessmentGRAPHQL.QUERY_TASK_SHARE_SELF_ASSESSMENT, params);
      if (result && result.data) {
        const data = window.COMMON.formatMultiDate(result.data.getTaskShareSelfAssessments, 'createdDate,updatedDate');
        data.forEach(item => {
          item.nameLanguage = window.COMMON.getValueWithLanguage(item, 'group.name', language.current);
          item.descriptionLanguage = window.COMMON.getValueWithLanguage(item, 'group.description', language.current);
        });
        setDataSelfAssessment(data);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('MySurveyPage.getDataSelfAssessment');
    }
  };

  // QUESTION & ANSWER
  const getDataQuestion = async () => {
    try {
      const params = {
        groupId: object.item.group._id
      };
      const result = await window.COMMON.query(QuestionGRAPHQL.QUERY_QUESTION, params);
      const dataQA = [];
      if (result && result.data) {
        result.data.getQuestions.forEach(obj => {
          dataQA.push({
            uuid: window.COMMON.getValueFromAttr(obj, '_id', null),
            group: object.item._id,
            question: {
              _id: window.COMMON.getValueFromAttr(obj, '_id', null),
              question: window.COMMON.getDataWithLanguage(obj, 'question'),
              introduction: window.COMMON.getDataWithLanguage(obj, 'introduction'),
              type: window.COMMON.getValueFromAttr(obj, 'type'),
              code: window.COMMON.getValueFromAttr(obj, 'code'),
              important: window.COMMON.getValueFromAttr(obj, 'important')
            },
            answers: []
          });
        });
      }
      getDataAnswerQuestion(dataQA);
    } catch (error) {
      window.COMMON.showErrorLogs('MySurveyPage.getDataQuestion');
    }
  };

  const getDataAnswerQuestion = async (dataQA) => {
    try {
      const params = {
        groupId: object.item.group._id
      };
      const result = await window.COMMON.query(QuestionGRAPHQL.QUERY_ANSWER_QUESTION, params);
      if (result && result.data) {
        result.data.getAnswerQuestions.forEach(obj => {
          const item = window.COMMON.getObjectInArrs(obj.question, dataQA, 'uuid');
          item.answers.push({
            _id: window.COMMON.getValueFromAttr(obj, '_id', null),
            content: window.COMMON.getDataWithLanguage(obj, 'content'),
            code: window.COMMON.getValueFromAttr(obj, 'code'),
            parentCode: window.COMMON.getValueFromAttr(obj, 'parentCode', null),
            question: window.COMMON.getValueFromAttr(obj, 'question')
          });
        });
      }
      setDataQA(dataQA);
      getDataFeedbackByTask();
    } catch (error) {
      window.COMMON.showErrorLogs('MySurveyPage.getDataQuestion');
    }
  };

  const getDataFeedbackByTask = async () => {
    try {
      const dataFeedback = {};
      if (!object.item.status) {
        setDataFeedback({ ...dataFeedback });
        return;
      }
      let data = [];
      const params = {
        id: object.item._id
      };
      const result = await window.COMMON.query(SelfAssessmentGRAPHQL.QUERY_TASK_SHARE_SELF_ASSESSMENT_BY_ID, params);
      if (result && result.data && result.data.findTaskShareSelfAssessment) {
        data = window.COMMON.getValueFromAttr(result.data.findTaskShareSelfAssessment, 'feedback', []);
      }
      // TODO init data feedback
      if (data && data.length > 0) {
        data.forEach(item => {
          // TODO init data feedback
          const obj = {
            answerText: item.answerText,
            note: item.note,
            documents: item.documents
          };
          if (item.question.type === 'RADIO_GRID' || item.question.type === 'CHECKBOX_GRID') {
            obj.count = 0;
            obj.answers = {};
            item.answers.forEach(element => {
              if (element.parentCode !== null) {
                if (!obj.answers[element.parentCode]) {
                  obj.answers[element.parentCode] = [];
                  obj.count++;
                }
                obj.answers[element.parentCode].push(element._id);
              }
            });
          } else {
            obj.answers = [];
            item.answers.forEach(element => {
              obj.answers.push(element._id);
            });
          }
          dataFeedback[item.question._id] = obj;
        });
      }
      setDataFeedback({ ...dataFeedback });
    } catch (error) {
      window.COMMON.showErrorLogs('MySurveyPage.getDataFeedbackByTask');
    }
  };

  //get feedback
  const getCompanyAdvisorHistoryAnswer = async () => {
    try {
      const params = {
        selfAssessment: dataSelfAssessment[object.index]?.selfAssessment?._id,
        company: dataSelfAssessment[object.index]?.company?._id
      };
      const result = await window.COMMON.query(SelfAssessmentGRAPHQL.QUERY_TASK_SELF_ASSESSMENT_BY_SELF_ASSESSMENT, params);
      if (result && result.data && result.data.getTaskSelfAssessmentBySelfAssessments) {
        setCompanyAnswer(result.data.getTaskSelfAssessmentBySelfAssessments.filter(item => item.type === window.CONSTANT.COMPANY));
        setAdvisorAnswer(result.data.getTaskSelfAssessmentBySelfAssessments.filter(item => item.type === window.CONSTANT.INFLUENCER));
      }
    } catch (error) {
      window.COMMON.showErrorLogs('MySelfAssessmentInfluencerPage.getDataFeedback');
    }
  };

  const getWorkerHistoryAnswer = async () => {
    try {
      const params = {
        input: {
          companyIds: [dataSelfAssessment[object.index].company?._id], 
          group: dataSelfAssessment[object.index]?.group?._id,
          startDate: window.COMMON_DATE.getStrDateFilter(new Date(parseInt(dataSelfAssessment[object.index]?.selfAssessment?.startDate)), -1),
          endDate: window.COMMON_DATE.getStrDateFilter(new Date(parseInt(dataSelfAssessment[object.index]?.selfAssessment?.endDate)), 1)
        }
      };
      const result = await window.COMMON.query(QuestionGRAPHQL.QUERY_EXPORT_HISTORY_ANSWER, params);
      if (result.data?.getHistoryAnswer?.data) {
        setWorkerAnswer(result.data.getHistoryAnswer.data);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('MySelfAssessmentInfluencerPage.getDataFeedback');
    }
  };

  const getLeadData = () => {
    return dataSelfAssessment.filter(item => item.leader?._id === USER_ID);
  };

  const getAssignedData = () => {
    return dataSelfAssessment.filter(item => !(item.leader?._id === USER_ID));
  };

  // eslint-disable-next-line
  const showContainerGroup = useCallback((index, data) => {
    const dataSelectId = data[index]?._id;
    const dataIndex = dataSelfAssessment.findIndex(item => item._id === dataSelectId);
    if (dataIndex > -1) {
      window.COMMON.showContainerSave();
      object.index = dataIndex;
      object.item = dataSelfAssessment[dataIndex];
      object.company = dataSelfAssessment[dataIndex].company;
      object.isSubmited = dataSelfAssessment[dataIndex].status;
      setObject(object);
      getDataQuestion();
      getCompanyAdvisorHistoryAnswer();
      getWorkerHistoryAnswer();
    }
  });

  const openGrantAccount = useCallback((index, data) => {
    const dataSelectId = data[index]?._id;
    const dataIndex = dataSelfAssessment.findIndex(item => item._id === dataSelectId);
    if (dataIndex > -1) {
      object.index = dataIndex;
      object.item = dataSelfAssessment[dataIndex];
      select.users = window.COMMON.setDataMultiSelectWithGroup(options.users, window.COMMON.getValueFromAttr(object.item, 'users', []), '_id');
      setObject(object);
      setSelect({ ...select });
      window.COMMON.showModal('#modal-grant');
    }
  }, [options, select, object, dataSelfAssessment]);

  // eslint-disable-next-line
  const openSaveGroup = useCallback((dataSave, deletedFiles) => {
    const feedback = [];
    const files = [];
    const keys = Object.keys(dataSave);
    let index = 0;
    keys.forEach(key => {
      const obj = window.COMMON.getObjectInArrs(key, dataQA, 'question._id');
      const item = {};
      item.question = key;
      item.answers = [];
      item.answerText = dataSave[key].answerText;
      item.note = dataSave[key].note;
      item.documents = [];
      dataSave[key].documents.forEach(document => {
        item.documents.push({
          id: document.file ? index : document.id,
          name: document.name,
          size: document.size,
          type: document.type,
          ext: document.ext
        });
        if (document.file) {
          index++;
          files.push(document.file);
        }
      });
      if (obj.question.type === 'RADIO_GRID' || obj.question.type === 'CHECKBOX_GRID') {
        const answerKeys = Object.keys(dataSave[key].answers);
        answerKeys.forEach(answerKey => {
          const answer = window.COMMON.getObjectInArrs(answerKey, obj.answers, 'code');
          if (answer) {
            item.answers.push(answer._id);
          }
          item.answers = item.answers.concat(dataSave[key].answers[answerKey]);
        });
      } else {
        item.answers = dataSave[key].answers;
      }
      feedback.push(item);
    });
    window.COMMON.showModal('#modal-save');
    window.saveMethod = () => sendFeedbackSelfAssessment(files, feedback, deletedFiles);
  });

  const grantTask = async () => {
    try {
      const checkUsers = window.COMMON.checkSelectValidation('select-users', select.users);
      if (!checkUsers) {
        window.COMMON.showMessage('warning', 'MSG_CODE_012', window.MSG.MSG_CODE_012);
        return;
      }
      const params = {
        id: object.item._id,
        users: window.COMMON.getDataSelect(select.users, true)
      };
      window.COMMON.trimData(params);
      const result = await window.COMMON.mutation(SelfAssessmentGRAPHQL.MUTATION_ASSIGN_TASK_SHARE_SELF_ASSESSMENT, params);
      if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_INSERT)) {
        getDataSelfAssessment();
        createTrackingEvent(eventName.ASSIGN_TASK_SELF_ASSESSMENT);

        //send mail notify
        let email = '';
        const selectedUsers = accountList.filter(item => params.users.includes(item._id));
        selectedUsers.map(item => { return email+= `${item.email},`;});
        sendMailNotify({
          receiver: [],
          text: '',
          email,
          title: window.I18N('self_assessment_assigned_title'),
          body: `
          <p>Tự đánh giá <b>${window.COMMON.getValueFromAttr(object.item, 'nameLanguage')}</b> vừa được giao cho bạn. Vui lòng kiểm tra chi tiết trên GOPY.</p>
          <br></br>
          <p>Assessment <b>${window.COMMON.getValueFromAttr(object.item, 'nameLanguage')}</b> is assigned to you. Please check it out.</p>`,
          type: 'GRI_NOTIFY_MESSAGE'
        });
      }
      window.COMMON.hideModal('#modal-grant');
    } catch (error) {
      window.COMMON.showErrorLogs('MySurveyPage.grantTask');
    }
  };

  const sendFeedbackSelfAssessment = async (files, feedback, deletedFiles) => {
    let dataFiles = []
    try {
      // TODO delete file
      if (deletedFiles && deletedFiles.length > 0) {
        deletedFiles.forEach(file => {
          window.COMMON.deleteFile(file.id);
        });
      }
      // TODO upload documents for self assessment
      if (files.length > 0) {
        dataFiles = await window.uploadFile(files);
        if (dataFiles && dataFiles.length > 0) {
          for (let i = 0; i < dataFiles.length; i++) {
            const file = dataFiles[i];
            for (let j = 0; j < feedback.length; j++) {
              const element = feedback[j];
              const obj = window.COMMON.getObjectInArrs(i, element.documents, 'id');
              if (obj) {
                obj.id = file._id;
                obj.type = file.mime;
                break;
              }
            }
          }
        }
      }
      const params = {
        input: {
          _id: object.item._id,
          feedback
        }
      };
      window.COMMON.trimData(params);
      const result = await window.COMMON.mutation(SelfAssessmentGRAPHQL.MUTATION_SAVE_TASK_SHARE_SELF_ASSESSMENT, params);
      if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_INSERT)) {
        window.COMMON.backContainerData();
        getDataSelfAssessment();
        createTrackingEvent(eventName.SAVE_TASK_SHARE_SELF_ASSESSMENT);
        sendMailNotify({
          receiver: [],
          text: '',
          email: dataSelfAssessment[object.index]?.leader?.email,
          title: window.I18N('self_assessment_finished_title_leader'),
          body: `
          <p>Tự đánh giá <b>${window.COMMON.getValueFromAttr(object.item, 'nameLanguage')}</b> đã hoàn tất. Vui lòng kiểm tra chi tiết trên GOPY.</p>
          <br></br>
          <p>Assessment <b>${window.COMMON.getValueFromAttr(object.item, 'nameLanguage')}</b> is finished. Please check it out.</p>`,
          type: 'GRI_NOTIFY_MESSAGE'
        });
        clearDraftData(dataSelfAssessment[object.index]?.selfAssessment?._id, dataSelfAssessment[object.index]?.group?._id);
      }
    } catch (error) {
      console.error("🚀 ~ sendFeedbackSelfAssessment ~ error:", error)
      window.UPLOAD_API.deleteMultiFile(dataFiles.map(item => item._id))      
      window.COMMON.showErrorLogs('MySurveyPage.sendFeedbackSelfAssessment');
    }
  };

  const getSelfAssessmentsData = (data) => {
    //get list self assessment from data 
    const selfAssessments = new Set();
    data.forEach(item => selfAssessments.add(item.selfAssessment));
    const newselfAssessments= Array.from(selfAssessments).map(el => (
      {
        ...el,
        users: data.find(elChild => elChild.selfAssessment?._id === el?._id)?.users,
        leader: data.find(elChild => elChild.selfAssessment?._id === el?._id)?.leader,
      }))
    //convert self assessment data 
    const result = Array.from(newselfAssessments).map(item => {
      return {
        ...item,
        typeName: window.COMMON.getObjectInArrs(item?.type, typeOptions, 'value')?.label,
        periodName: window.COMMON.getObjectInArrs(item?.period, periodOptions, 'value')?.label
      };
    });
    return result;
  };

  //set list question from assessment
  const selectAssessmentToAssign = (data) => {
    setAssignData(data);
    window.COMMON.showModal('#modal-grant-admin');
  };

  const assignLeader = async () => {
    try {
      const checkUsers = window.COMMON.checkSelectValidation('admin-select-users', select.users);
      if (!checkUsers) {
        window.COMMON.showMessage('warning', 'MSG_CODE_012', window.MSG.MSG_CODE_012);
        return;
      }
      assignData.map(async (data) => {
        const params = {
          id: data._id,
          leader: window.COMMON.getDataSelect(select.users, false)
        };
        window.COMMON.trimData(params);
        const result = await window.COMMON.mutation(SelfAssessmentGRAPHQL.MUTATION_ASSIGN_LEADER_TASK_SHARE_SELF_ASSESSMENT, params);
        if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_INSERT)) {
          getDataSelfAssessment();
          createTrackingEvent(eventName.ASSIGN_LEADER_TASK_SELF_ASSESSMENT);

          //send notify to assessor leader
          const user = accountList.find(item => params.leader === item._id);
          if (user) {
            const result = await window.COMMON.sendMailNotify({
              email: user.email,
              title: window.I18N('share_self_assessment_assigned_title'),
              body: `
              <p>Tự đánh giá <b>${window.COMMON.getValueFromAttr(data, 'nameLanguage')}</b> được giao cho bạn. Vui lòng kiểm tra chi tiết trên GOPY.</p>
              <br></br>
              <p>Assessment <b>${window.COMMON.getValueFromAttr(data, 'nameLanguage')}</b> is assigned to you. Please check it out.</p>`
            });
            if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_STATUS)) {
              createTrackingEvent(eventName.SEND_MAIL);
            }
          }
        }
      });
      setAssignData([]);
      window.COMMON.hideModal('#modal-grant-admin');
    } catch (error) {
      window.COMMON.showErrorLogs('MySurveyPage.assignLeader');
    }
  };

  const closeGroupContainer = () => {
    window.COMMON.backContainerData();
    getDataSelfAssessment();
  };

  const sendMailNotify = async (params) => {
    try {
      const result = await window.COMMON.sendMailNotify(params);
      if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_STATUS)) {
		  createTrackingEvent(eventName.SEND_MAIL);
      }  
    } catch (error) {
      window.COMMON.showErrorLogs('MySurveyPage.sendNotify');
    }
  };

  const clearDraftData = (assessmentId, groupId) => {
    const userId = localStorage.getItem('id');
    const draft = JSON.parse(localStorage.getItem('draftAssessment') || '[]');

    const updateDataDraft = draft.filter(item => !(item.user === userId && item.assessment === assessmentId && item.group === groupId));
    localStorage.setItem('draftAssessment', JSON.stringify(updateDataDraft));
  };

  const getFeedback = (data) => {
    const result = data.map(h => {
      const result = h.items.find(item => (item.group._id === dataSelfAssessment[object.index]?.group?._id && item.isCompleted));
      if (result) return result;
    }).filter(item => item);
    return result;
  };

  const getHistoryAnswerData = () => {
    return {
      advisor: getFeedback(advisorAnswer),
      company: getFeedback(companyAnswer),
      worker: workerAnswer
    };
  };

  const openPreviewSelfAssessment = useCallback((data) => {
    setPreviewData({
      feedback: data,
      groupIds: data.map(item => item.group?._id),
      assessment: data[0]?.selfAssessment
    });
    window.COMMON.showModal('#modal-preview');
  }, []);

  const handleApproveEvent = (isApproved) => {
    try {
      if (previewData.feedback && Array.isArray(previewData.feedback)) {
        previewData.feedback.map(async (item, index) => {
          const params = {
            input: isApproved ? {
              _id: item._id,
              isApproved
            } : {
              _id: item._id,
              isApproved,
              status: false	
            }
          };
          const result = await window.COMMON.mutation(SelfAssessmentGRAPHQL.MUTATION_UPDATE_TASK_SHARE_SELF_ASSESSMENT, params);
          if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_STATUS) && index+1 === previewData.feedback.length) {
            window.COMMON.backContainerData();
            getDataSelfAssessment();
            window.COMMON.hideModal('#modal-preview');
            createTrackingEvent(eventName.UPDATE_TASK_SHARE_SELF_ASSESSMENT);

            //send mail notify if approved
            if (isApproved) {
              // send mail to admin influencer.
              const adminInfluencerEmail = adminInfluencerOfCurrentTask.information?.email;
              sendMailNotify({
                email: adminInfluencerEmail,
                title: window.I18N('task_share_self_assessment_finished_title'),
                body: `
                <p>Tự đánh giá <b>${previewData.feedback[0].selfAssessment?.name}</b> vào <b>${moment().format('[ngày] DD [tháng] MM')}</b> đã kết thúc. Vui lòng kiểm tra chi tiết trên GOPY.</p>
                <br></br>
                <p>Assessment <b>${previewData.feedback[0].selfAssessment?.name}</b> on <b>${moment().format('MMMM DD')}</b>. Please check it out.</p>`,
                type: 'GRI_NOTIFY_MESSAGE'
              });
            } else {
              if (Array.isArray(previewData.feedback[0]?.users)) {
                let email = '';
                previewData.feedback[0].users.map(item => { return email+= `${item.email},`;});
                sendMailNotify({
                  email,
                  title: window.I18N('redo_task_share_self_assessment_title'),
                  body: `
                  <p>Bạn đã được yêu cầu thực hiện lại đánh giá <b>${previewData.feedback[0].selfAssessment?.name}</b> vào <b>${moment().format('[ngày] DD [tháng] MM')}</b>. Vui lòng giúp chúng tôi kiểm tra và thực hiện lại</p>
                  <br></br>
                  <p>You were requested to redo assessment <b>${previewData.feedback[0].selfAssessment?.name}</b> on <b>${moment().format('MMMM DD')}</b>. Please help us check and do it again.</p>`,
                  type: 'GRI_NOTIFY_MESSAGE'
                });
              }
            }
          }
        });
      }
    } catch (error) {
      window.COMMON.showErrorLogs('MySurveyPage.errorHandleApproveEvent');
    }
  };

  const isApproved = () => {
    if (!previewData.feedback || (Array.isArray(previewData.feedback) && previewData.feedback.length === 0)) return false;

    let result = true;
    previewData.feedback.map(item => {
      if (!item.isApproved) result = false;
    });
	
    return result;
  };
  
  // ATTRIBUTE REPORT
  const getDataAttributeReport = async () => {
    try {
      const result = await window.COMMON.query(SelfAssessmentGRAPHQL.QUERY_ATTRIBUTE_REPORT, null);
      if (result !=null) {
        let index = 0;
        result.data.getAttributeReports.forEach(item => {
          item.idx = index;
          index++;
        });
        setDataAttributeReport(result.data.getAttributeReports);
      }
    } catch (error) {
      console.log(error);
      window.COMMON.showErrorLogs('MySelfAssessmentInfluencerPage.getDataAttributeReport');
    }
  };

  // ATTRIBUTE REPORT COMPANY
  const getDataAttributeReportCompany = async () => {
    try {
      const params = { company: object.company?._id };
      const result = await window.COMMON.query(SelfAssessmentGRAPHQL.QUERY_ATTRIBUTE_REPORT_COMPANY, params);
      if (result && result.data && result.data.findAttributeReportCompany) {
        const data = result.data.findAttributeReportCompany;
        object.idAttribute = data._id;
        setDataAttributeReportCompany(data);
      } else {
        object.idAttribute = null;
        setDataAttributeReportCompany({});
      }
      setObject(object);
    } catch (error) {
      window.COMMON.showErrorLogs('MySelfAssessmentInfluencerPage.getDataAttributeReportCompany');
    }
  };

  const saveAttributeCompany = async (data) => {
    try {
      const params = {
        input: {
          company: object.company?._id,
          items: data
        }
      };
      window.COMMON.trimData(params);
      if (!object.idAttribute) {
        const result = await window.COMMON.mutation(SelfAssessmentGRAPHQL.MUTATION_ADD_ATTRIBUTE_REPORT_COMPANY, params);
        if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_INSERT)) {
          getDataAttributeReportCompany();
          window.COMMON.backContainerData();
		  createTrackingEvent(eventName.ADD_ATTRIBUTE_REPORT_COMPANY);
        }
      } else {
        params.input._id = object.idAttribute;
        const result = await window.COMMON.mutation(SelfAssessmentGRAPHQL.MUTATION_EDIT_ATTRIBUTE_REPORT_COMPANY, params);
        if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_UPDATE)) {
          getDataAttributeReportCompany();
          window.COMMON.backContainerData();
		  createTrackingEvent(eventName.EDIT_ATTRIBUTE_REPORT_COMPANY);
        }
      }
    } catch (error) {
      window.COMMON.showErrorLogs('RewardPage.saveReward');
    }
  };

  const showAttributeReportCompany = async () => {
    await getDataAttributeReport();
    await getDataAttributeReportCompany();
    window.COMMON.showContainerSave(false, true);
  };

  const closeAttributeCompany = () => {
    window.COMMON.backContainerData(); 
    window.COMMON.showContainerSave();
  };

  // eslint-disable-next-line
  const openSaveAttributeCompany = useCallback((data) => {
    window.COMMON.showModal('#modal-save');
    window.saveMethod = () => saveAttributeCompany(data);
  });

  // fetch assessment creator on task change.
  useEffect(() => {
    if (previewData.assessment) {
      setCurrentAssessment(previewData.assessment);
    }
  // eslint-disable-next-line
  }, [previewData]);

  // fetch influencer admin on assessment creator change;
  useEffect(() => {
    const influencerId = currentAssessmentCreator?.influencer?._id || '';
    if (influencerId) {
      setInfluencerIdOfCurrentTask(influencerId);
    }
  // eslint-disable-next-line
  }, [currentAssessmentCreator]);

  if (typeof window === 'undefined') {
    return null;
  }

  return (
    <div className="container-row">
      <div id="container-data">
        {
          !window.COMMON.checkRoleIsSystem() ? <div></div> : 
            <div className="card mb-3 p-3 wow fadeInDown animated" data-wow-delay="0.5s" style={{ zIndex:1 }}>
              <div className="d-flex align-items-center justify-content-end">
                <div className="bd-highlight" style={{ width: '300px' }}>
                  <div className="input-group input-group-transparent">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <MDBIcon fa="true" icon="building" className="fa-fw" />
                      </span>
                    </div>
                    <Select className="md-form m-0" placeholder={window.I18N('choose_option')} value={filter.company} options={options.company} onChange={handleFilterSelect} isSearchable/>
                  </div>
                </div>
              </div>
            </div>
        }
        <div className="p-0 pt-2">
          {window.COMMON.checkRoleIsSystemOrOrganization() ? 
		  	<GridItemShareSelfAssessmentAdmin 
			  data={getSelfAssessmentsData(dataSelfAssessment)}
			  shareList={dataSelfAssessment}
			  handleOpenAssign={selectAssessmentToAssign}
            /> :
            <div>
              <div className="d-flex align-items-center justify-content-start fadeInDown animated"  data-wow-delay="0.5s">
                <MDBBtn color={viewIndex === 0 ? 'primary' :''} className="mr-0" onClick={() => setViewIndex(0)}>
                  <MDBIcon fa="true" icon="user-tie" className="fa-fw" /> {window.I18N('leader')}
                </MDBBtn>
                <MDBBtn color={viewIndex === 1 ? 'primary' : ''} className="mr-0" onClick={() => setViewIndex(1)}>
                  <MDBIcon fa="true" icon="user-edit" className="fa-fw" /> {window.I18N('assigned')}
                </MDBBtn>
              </div>
			  {viewIndex === 0 && (
                <GridItemShareSelfAssessment 
                  data={getSelfAssessmentsData(getLeadData())} 
                  shareList={getLeadData()}
                  handleItemClick={(index) => showContainerGroup(index, getLeadData())} 
                  handlePreviewClick={openPreviewSelfAssessment} 
                  handleItemEditClick={(index) => openGrantAccount(index, getLeadData())}
                />
			  )}
			   {viewIndex === 1 && (
                <GridItem 
                  id="container-grid-survey-completed" 
                  data={getAssignedData()} 
                  isShare={true} 
                  isSurvey={true} 
                  isDisableEdit={true}  //hide users select
                  handleItemClick={(index) => showContainerGroup(index, getAssignedData())} 
                //   handleItemEditClick={(index) => openGrantAccount(index, 2)}
                />
              )}
            </div>
          }
        </div>
      </div>
      
      <Panel id="container-save" title={window.I18N('survey')} icon="question-circle" style={{ display: 'none' }}>
        <MDBRow>
          <MDBCol>
            <ItemQAInfluencer 
              assessmentId={dataSelfAssessment[object.index]?.selfAssessment?._id}
              group={object.item} 
              dataFeedback={dataFeedback}
              dataHistoryAnswer={getHistoryAnswerData()} 
              isSubmited={object.isSubmited} 
              data={dataQA} 
              language={language.current} 
              isSelfAssessment={true} 
              isCompany={true} 
              handleSubmit={openSaveGroup}
              handleClose={closeGroupContainer}
			        handleViewFacility={showAttributeReportCompany}
            />
          </MDBCol>
        </MDBRow>
      </Panel>

	  <Panel id="container-attribute-report" title={window.I18N('self_organization')} icon="info-circle" style={{ display: 'none' }}>
        <AttributeCompany 
          data={dataAttributeReport} 
          company={object.company} 
          dataCompany={dataAttributeReportCompany.items || []}
          handleClose={closeAttributeCompany}
          handleSave={openSaveAttributeCompany}
          companyId={object.company?._id} 
        />
      </Panel>

      <Modal id="modal-grant" className="modal-lg" isNotScroll={true} title={window.I18N('assign_assessor')} saveEvent={grantTask}>
        <Select id="select-users" className="md-form" placeholder={window.I18N('select_accounts')} value={select.users} options={options.users} onChange={(event) => handleSelect(event, 'select-users', 'users')} isSearchable isMulti closeMenuOnSelect={false}/>
      </Modal>

	  <Modal id="modal-grant-admin" className="modal-lg" isNotScroll={true} title={window.I18N('assign_assessor')} saveEvent={assignLeader}>
        <Select 
          id="admin-select-users" 
          className="md-form" 
          placeholder={window.I18N('select_accounts')} 
          value={select.users} 
          options={options.users} 
          onChange={(event) => handleSelect(event, 'admin-select-users', 'users')} 
          isSearchable 
        />
      </Modal>

	  <Modal 
	  	id="modal-preview" className="modal-xl" 
        title={window.I18N('preview_self')} 
        hideSave={isApproved()} hideReject={isApproved()} 
        saveEvent={() => handleApproveEvent(true)} 
        rejectEvent={() => handleApproveEvent(false)} 
	  	saveBtn={window.I18N('btn_approve')} icon="check-circle"
      >
        <MDBRow>
          <MDBCol>
            <ItemPreviewShareAssessment data={previewData} />
		  </MDBCol>
        </MDBRow>
      </Modal>
    </div>
  );
}

const mapStateToProps = state => ({
  language: state.language.value
});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(MySurveyPage);