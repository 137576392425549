import React, { useState, useCallback, useLayoutEffect } from 'react';
import { MDBRow, MDBCol, MDBInputGroup, MDBBtn, MDBIcon } from 'mdbreact';
import Select from 'react-select';

import Panel from '../../components/Panel';
import TableCustomize from '../../components/TableCustomize';
import Pagination from '../../components/Pagination';
import Filter from '../../components/Filter';
import AddCompany from '../../components/AddCompany';

import MetadataGRAPHQL from '../../../graphql/Metadata';
import RoleGRAPHQL from '../../../graphql/Role';
import AccountGRAPHQL from '../../../graphql/Account';

const MAX_TEXT_LENGTH = 500
export default function CompanyPage(props) {
  const config = [
    window.COMMON.createCol(window.I18N('no'), '5%', '', '', '', 'INDEX'),
    window.COMMON.createCol(window.I18N('name'), '25%', 'p-0 pl-1', '', 'avatar,name', 'IMAGE_TEXT'),
    window.COMMON.createCol(window.I18N('phone_number'), '10%', '', '', 'phone', 'TEXT'),
    window.COMMON.createCol(window.I18N('headcounts'), '10%', '', '', 'companySize.value', 'TEXT'),
    window.COMMON.createCol(window.I18N('industry'), '15%', '', '', 'companyIndustry.value', 'TEXT'),
    window.COMMON.createCol(window.I18N('country'), '10%', '', '', 'nation.name', 'TEXT'),
    window.COMMON.createCol(window.I18N('status'), '10%', '', '', 'status', 'STATUS'),
    window.COMMON.createCol(window.I18N('view_more'), '10%', '', '', '2', 'BUTTON')
  ];
  const configAccount = [
    window.COMMON.createCol(window.I18N('no'), '5%', '', '', '', 'INDEX'),
    window.COMMON.createCol(window.I18N('full_name'), '25%', 'p-0 pl-1', '', 'avatar,name', 'IMAGE_TEXT'),
    window.COMMON.createCol(window.I18N('email'), '20%', '', '', 'email', 'TEXT'),
    window.COMMON.createCol(window.I18N('phone_number'), '10%', '', '', 'phone', 'TEXT'),
    window.COMMON.createCol(window.I18N('gender'), '10%', '', '', 'genderText', 'TEXT'),
    window.COMMON.createCol(window.I18N('status'), '10%', '', '', 'status', 'STATUS'),
    window.COMMON.createCol(window.I18N('created_date'), '10%', '', '', 'createdDate', 'TEXT'),
    window.COMMON.createCol(window.I18N('view_more'), '10%', '', '', '2', 'BUTTON')
  ];
  const gendersFilter = [
    { value: null, label: window.I18N('all_gender') },
    { value: 0, label: window.I18N('female') },
    { value: 1, label: window.I18N('male') }
  ];
  const statusFilter = [
    { value: null, label: window.I18N('all_status') },
    { value: true, label: window.I18N('active') },
    { value: false, label: window.I18N('inactive') }
  ];
  const [filter, setFilter] = useState({});
  const [object, setObject] = useState({ index: -1, isOrganizationRole: false });
  const [pagination, setPagination] = useState({ total: 0, pageSize: 10, currentPage: 0 });
  const [data, setData] = useState([]);
  const [role, setRole] = useState(null);
  const [options, setOptions] = useState({
    companySizeFilter: [],
    companyIndustryFilter: [],
    nationFilter: [],
    stateFilter: [],
    companyType: [],
    companySize: [],
    companyIndustry: [],
    nation: [],
    city: [],
    state: [],
    role: []
  });

  useLayoutEffect(() => {
    setTimeout(() => {
      getDataMetadata();
      getDataLocationFilterOptions('-1', '-1');
      getDataRole();
      getPackageData();
    }, 100);
    // eslint-disable-next-line
  }, []);

  const handleFilterChange = (event) => {
    const text = event.target.value
    if(text.startsWith(' ') || text.length >= MAX_TEXT_LENGTH) {
      return
    }
    setFilter((prev) => ({ ...prev, text }));
  };

  const handleFilterSelect = (event, attr) => {
    filter[attr] = event;
    if (attr === 'role') {
      const isOrganizationRole = object.isOrganizationRole;
      const organizationRoleArr = [
        window.CONSTANT.COMPANY, 
        window.CONSTANT.UNION, 
        window.CONSTANT.INFLUENCER, 
        window.CONSTANT.GOPY_INFLUENCER 
      ]
      object.isOrganizationRole = !organizationRoleArr.includes(event.code);
      if (isOrganizationRole !== object.isOrganizationRole) {
        filter.companyIndustry = { value: null, label: window.I18N('all_industries') };
        filter.companySize = { value: null, label: window.I18N('all_headcounts') };
        filter.nation = { value: null, label: window.I18N('all_countries') };
        filter.state = { value: null, label: window.I18N('all_states') };
        filter.gender = { value: null, label: window.I18N('all_gender') };
        filter.status = { value: null, label: window.I18N('all_status') };
      }
      pagination.currentPage = 0;
      setObject(object);
      setPagination(pagination);
    }
    if (attr === 'nation' || attr === 'state') {
      getDataLocationFilterOptions(attr, event.code);
    } else {
      setFilter({ ...filter });
    }
    if (attr === 'role') {
      getDataOrganization();
    }
  };

  // COMMON
  const getDataMetadata = async () => {
    try {
      const params = {
        type: ['COMPANY_TYPE', 'COMPANY_SIZE', 'COMPANY_INDUSTRY']
      };
      const result = await window.COMMON.query(MetadataGRAPHQL.QUERY_SHORT_METADATA, params, false);
      if (result && result.data) {
        const data = result.data.getMetadatas;
        const dataType = window.COMMON.getArrsInArrs('COMPANY_TYPE', data, 'type');
        const dataSize = window.COMMON.getArrsInArrs('COMPANY_SIZE', data, 'type');
        const dataIndustry = window.COMMON.getArrsInArrs('COMPANY_INDUSTRY', data, 'type');
        options.companyType = window.COMMON.createDataSelect(dataType, '_id', 'value');
        options.companySize = window.COMMON.createDataSelect(dataSize, '_id', 'value');
        options.companyIndustry = window.COMMON.createDataSelect(dataIndustry, '_id', 'value');

        options.companyIndustryFilter = JSON.parse(JSON.stringify(options.companyIndustry));
        options.companyIndustryFilter.unshift({ value: null, label: window.I18N('all_industries') });
        filter.companyIndustry = { value: null, label: window.I18N('all_industries') };
        options.companySizeFilter = JSON.parse(JSON.stringify(options.companySize));
        options.companySizeFilter.unshift({ value: null, label: window.I18N('all_industries') });
        filter.companySize = { value: null, label: window.I18N('all_headcounts') };
        setFilter(filter);
        setOptions(options);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('ComapnyPage.getDataMetadata');
    }
  };

  const getPackageData = async () => {
    try {
      const resCompanyPackages = await window.COMMON.query(RoleGRAPHQL.QUERY_PACKAGE, { filter: { role: 'COMPANY' } });
      const resOrganizationPackages = await window.COMMON.query(RoleGRAPHQL.QUERY_PACKAGE, { filter: { role: 'INFLUENCER' } });
      const resGopyInfluencerPackages = await window.COMMON.query(RoleGRAPHQL.QUERY_PACKAGE, { filter: { role: 'GOPY_INFLUENCER' } });
      if (resCompanyPackages?.data.queryPackage) {
        options.companyPackages = window.COMMON.createDataSelect(resCompanyPackages.data.queryPackage, '_id', 'name','default');
      }
      if (resOrganizationPackages?.data.queryPackage) {
        options.organizationPackages = window.COMMON.createDataSelect(resOrganizationPackages.data.queryPackage, '_id', 'name','default');
      }
      if (resGopyInfluencerPackages?.data.queryPackage) {
        options.gopyInfluencerPackages = window.COMMON.createDataSelect(resGopyInfluencerPackages.data.queryPackage, '_id', 'name','default');
      }
      setOptions(options);
    } catch (err) {
      window.COMMON.showErrorLogs('ComapnyPage.getPackageData');
    }
  };

  const getDataLocationFilterOptions = async (attr, parent) => {
    if (attr === '-1') {
      options.nationFilter = await window.COMMON.getDataLocation(parent);
      options.nation = JSON.parse(JSON.stringify(options.nationFilter));
      options.nationFilter.unshift({ value: null, label: window.I18N('all_countries') });
      options.stateFilter = [{ value: null, label: window.I18N('all_states') }];
      filter.nation = { value: null, label: window.I18N('all_countries') };
      filter.state = { value: null, label: window.I18N('all_states') };
    } else if (attr === 'nation') {
      options.stateFilter = await window.COMMON.getDataLocation(parent);
      options.stateFilter.unshift({ value: null, label: window.I18N('all_states') });
      filter.state = { value: null, label: window.I18N('all_states') };
    }
    setOptions(options);
    setFilter({ ...filter });
  };

  const getDataRole = async () => {
    try {
      const params = {
        company: null
      };
      const result = await window.COMMON.query(RoleGRAPHQL.QUERY_ROLE, params, false);
      if (result && result.data) {
        options.role = window.COMMON.createDataSelect(result.data.getRoles, '_id', 'name', 'code');
        filter.role = options.role && options.role.length > 0 ? options.role[0] : null;
      }
      setOptions(options);
      setFilter({ ...filter });
      getDataOrganization();
    } catch (error) {
      window.COMMON.showErrorLogs('ComapnyPage.getDataRole');
    }
  };

  // COMPANY
  const getDataOrganization = async () => {
    try {
      if (filter.role) {
        const params = {
          input: {
            accountType: filter.role.code,
            companySize: window.COMMON.getDataSelect(filter.companySize, false),
            companyIndustry: window.COMMON.getDataSelect(filter.companyIndustry, false),
            nation: window.COMMON.getDataSelect(filter.nation, false),
            state: window.COMMON.getDataSelect(filter.state, false),
            gender: window.COMMON.getDataSelect(filter.gender, false),
            status: window.COMMON.getDataSelect(filter.status, false),
            name: filter?.text?.trim(),
            pageSize: pagination.pageSize,
            page: pagination.currentPage + 1
          }
        };
        const result = await window.COMMON.query(AccountGRAPHQL.QUERY_ORGANIZATION_WITH_FILTER, params);
        let data = [];
        if (result && result.data && result.data.getAccountsWithFilter) {
          data = window.COMMON.formatMultiDate(result.data.getAccountsWithFilter.data, 'createdDate,birthday,establishedDate');
          data.forEach(item => {
            item.genderText = item.gender === 0 ? window.I18N('female') : window.I18N('male');
          });
          pagination.total = result.data.getAccountsWithFilter.total;
        }
        setPagination(pagination);
        setData(data);
      }
    } catch (error) {
      window.COMMON.showErrorLogs(`CompanyPage.getDataOrganization ${error}`);
    }
  };

  const changePage = useCallback((index) => {
    pagination.currentPage = index;
    setPagination({ ...pagination });
    getDataOrganization();
    // eslint-disable-next-line
  }, [object, pagination, filter]);

  const handleSearchWithEnter = (event) => {
    if (event.key === 'Enter') {
      pagination.currentPage = 0
      getDataOrganization()
    }
  }

  const doSearchData = () => {
    pagination.currentPage = 0;
    window.COMMON.hideFilter();
    getDataOrganization();
  };

  const showContainerCompany = useCallback(async (index) => {
    window.COMMON.showContainerSave();
    object.index = index;
    object.item = index === -1 ? null : data[index];
    setRole(filter.role);
    setObject({ ...object });
    // eslint-disable-next-line
  }, [data, object]);

  if (typeof window === 'undefined') {
    return null;
  }
  return (
    <div className="container-row">
      <Panel id="container-data" icon="list-alt">
        <div className="container-btn wow fadeInDown animated" data-wow-delay="0.5s">
          <div className="d-flex align-items-center justify-content-end">
            <div className="bd-highlight mr-2" style={{ width: '300px' }}>
              <div className="input-group input-group-transparent">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <MDBIcon fa="true" icon="filter" className="fa-fw" />
                  </span>
                </div>
                <Select value={filter.role} options={options.role} className="md-form m-0 text-left" onChange={(event) => handleFilterSelect(event, 'role')} isSearchable/>       
              </div>
            </div>
            <div className="mr-2 ml-auto" style={{ width: '350px' }}>
              <MDBInputGroup type="text" value={filter.text} hint={window.I18N('search_name')} onChange={handleFilterChange} onKeyDown={handleSearchWithEnter} maxLength="100" pattern="\S(.*\S)?" required
                append={
                  <span className="input-group-text cursor-pointer" onClick={getDataOrganization}>
                    <MDBIcon fa="true" icon="search" className="fa-fw"></MDBIcon>
                  </span>
                }
              />
            </div>
            <Filter id="container-filter" className="col-md-8">
              <MDBRow>
                {
                  !object.isOrganizationRole ? <>
                    <MDBCol sm="6" className="mb-2 pr-sm-2">
                      <strong>{window.I18N('industry')}</strong>
                      <div className="input-group input-group-transparent mt-1">
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            <MDBIcon fa="true" icon="filter" className="fa-fw" />
                          </span>
                        </div>
                        <Select value={filter.companyIndustry} options={options.companyIndustryFilter} className="md-form m-0" onChange={(event) => handleFilterSelect(event, 'companyIndustry')} isSearchable/>       
                      </div>
                    </MDBCol>
                    <MDBCol sm="6" className="mb-2 pl-sm-2">
                      <strong>{window.I18N('headcounts')}</strong>
                      <div className="input-group input-group-transparent mt-1">
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            <MDBIcon fa="true" icon="filter" className="fa-fw" />
                          </span>
                        </div>
                        <Select noOptionsMessage={() => 'Không còn lựa chọn'} value={filter.companySize} options={options.companySizeFilter} className="md-form m-0" onChange={(event) => handleFilterSelect(event, 'companySize')} isSearchable/>       
                      </div>
                    </MDBCol>                 
                  </> : <>
                    <MDBCol sm="6" className="mb-2 pr-sm-2">
                      <strong>{window.I18N('gender')}</strong>
                      <div className="input-group input-group-transparent mt-1">
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            <MDBIcon fa="true" icon="filter" className="fa-fw" />
                          </span>
                        </div>
                        <Select value={filter.gender} options={gendersFilter} className="md-form m-0" onChange={(event) => handleFilterSelect(event, 'gender')} isSearchable/>       
                      </div>
                    </MDBCol>
                    <MDBCol sm="6" className="mb-2 pl-sm-2">
                      <strong>{window.I18N('status')}</strong>
                      <div className="input-group input-group-transparent mt-1">
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            <MDBIcon fa="true" icon="filter" className="fa-fw" />
                          </span>
                        </div>
                        <Select value={filter.status} options={statusFilter} className="md-form m-0" onChange={(event) => handleFilterSelect(event, 'status')} isSearchable/>       
                      </div>
                    </MDBCol>
                  </>
                }
                <MDBCol sm="6" className="mb-2 pr-sm-2">
                  <strong>{window.I18N('country')}</strong>
                  <div className="input-group input-group-transparent mt-1">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <MDBIcon fa="true" icon="filter" className="fa-fw" />
                      </span>
                    </div>
                    <Select value={filter.nation} options={options.nationFilter} className="md-form m-0" onChange={(event) => handleFilterSelect(event, 'nation')} isSearchable/>       
                  </div>
                </MDBCol>
                <MDBCol sm="6" className="mb-2 pl-sm-2">
                  <strong>{window.I18N('state_city')}</strong>
                  <div className="input-group input-group-transparent mt-1">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <MDBIcon fa="true" icon="filter" className="fa-fw" />
                      </span>
                    </div>
                    <Select noOptionsMessage={({ inputValue }) => inputValue ? window.I18N('no_result_for', { value: inputValue }) : window.I18N('no_options')} value={filter.state} options={options.stateFilter || []} className="md-form m-0" onChange={(event) => handleFilterSelect(event, 'state')} isSearchable/>       
                  </div>
                </MDBCol>
                <MDBCol sm="12 text-right">
                  <MDBBtn color="primary" onClick={doSearchData}>
                    <MDBIcon fa="true" icon="sync-alt" className="fa-fw" /> {window.I18N('apply')}
                  </MDBBtn>
                </MDBCol>
              </MDBRow>
            </Filter>
            <div className="bd-highlight">
              <MDBBtn color="primary" onClick={() => showContainerCompany(-1)}>
                <MDBIcon fa="true" icon="plus-circle" className="fa-fw" /> {window.I18N('btn_add')}
              </MDBBtn>
            </div>
          </div>
        </div>
        <div className="container-table wow fadeIn animated" data-wow-delay="1s">
          <TableCustomize id="table-data" className="table" config={object.isOrganizationRole ? configAccount : config} data={data} handleRowEditClick={showContainerCompany}></TableCustomize>
          <Pagination total={pagination.total} pageSize={pagination.pageSize} currentPage={pagination.currentPage} changePage={changePage}></Pagination>
        </div>
      </Panel>

      <AddCompany options={options} index={object.index} item={object.item} role={role} isOrganizationRole={object.isOrganizationRole} callback={getDataOrganization} isProfile={false}></AddCompany>
    </div>
  );
}