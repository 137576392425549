import React, { useState, useEffect, useLayoutEffect } from 'react';
import { connect } from 'react-redux';
import FanpageGRAPHQL from '../../../graphql/Fanpage';
import { Button, Grid, InputAdornment, InputBase, makeStyles } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import image from '../../../styles/images/icons/ic_of_facebook.png'
import FanpageDiv from '../../components/FanpageDiv';
import { useHistory } from 'react-router-dom';
import FanpageContent from '../../components/FanpageContent';
import Constant from '../../../utils/Constant';
import InfiniteScroll from 'react-infinite-scroll-component';
import LoadingSpinner from '../../components/LoadingSpinner';

const PAGE_SIZE = 8
const PAGE_SIZE_OWNER = 3
const MyFanpagePage = (props) => {
    const [active, setActive] = useState(Constant.FANPAGE.FEEDS_MODE)
    const history = useHistory();
    const useStyle = makeStyles((theme) => ({
        focused: {
            borderColor: "#33b5e5 !important",
            '& :focus': {
                boxShadow: "none !important"
            } 
        },
        root: {
            margin: "0.5rem 0 1rem 0",
            width: "100%",
            padding: "0.5rem 0.5rem 0.5rem 1rem",
            border: "solid 1px #dee2e6", 
            borderRadius: "20px"
        },
        hover: {
            '&:hover': {
                textDecoration: "underline",
            }
        }
    }));
    const classes = useStyle()
    const [searchText, setSearchText]= useState('')
    const [dataFanpages, setDataFanpages] = useState({ owner: [], member: [] })
    const [total, setTotal] = useState({fanpageOwner: 0, fanpageMember: 0})
    const [isStillMore, setIsStillMore] = useState(false)

    const handleChangeText = (event) => {
        setSearchText(event.target.value)
    }


    useLayoutEffect(() => {
        setTimeout(() => {
            getDataFanpage();
        }, 100);
    }, []);

    useEffect(() => {
        if (props?.location?.search === '') {
            setActive(Constant.FANPAGE.FEEDS_MODE)
        } else {
            setActive(props?.location?.search.slice(1))
        }
    },[props?.location?.search])

    const getDataFanpage = async () => {
        try {
            const paramsOwner = {
                status: Constant.FANPAGE.ACCEPTED,
                isOwner: true,
                isJoin: false,
                pageSize: PAGE_SIZE_OWNER
            }
            const paramsMember = {
                status: Constant.FANPAGE.ACCEPTED,
                isOwner: false,
                isJoin: true,
                pageSize: PAGE_SIZE
            }
            const [resultOwner, resultMember] = await Promise.all([
                window.COMMON.query(FanpageGRAPHQL.QUERY_FANPAGE_RELATIONSHIPS_BY_USER, paramsOwner),
                window.COMMON.query(FanpageGRAPHQL.QUERY_FANPAGE_RELATIONSHIPS_BY_USER, paramsMember),
            ]);
            if (resultOwner?.data && resultMember?.data) {
                setDataFanpages({
                    owner: resultOwner.data.getFanpageRelationships.data,
                    member: resultMember.data.getFanpageRelationships.data,
                })
                setTotal({ fanpageOwner: resultOwner.data.getFanpageRelationships.total, fanpageMember: resultMember.data.getFanpageRelationships.total })
                if (resultMember.data.getFanpageRelationships.data.length === resultMember.data.getFanpageRelationships.total) {
                    setIsStillMore(false)
                } else {
                    setIsStillMore(true)
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    const onLoadMoreOwnerFanpage = async () => { 
        try {
            const paramsOwner = {
                status: Constant.FANPAGE.ACCEPTED,
                isOwner: true,
                isJoin: false,
                pageSize: 9999,
            }
            const result = await window.COMMON.query(FanpageGRAPHQL.QUERY_FANPAGE_RELATIONSHIPS_BY_USER, paramsOwner)
            if (result?.data) {
                dataFanpages.owner = result.data.getFanpageRelationships.data
                setDataFanpages({...dataFanpages})
            }
        } catch (error) {
            console.log(error)
        }
    }

    const fetchMoreData = async () => {
        try {
            const params = {
                status: Constant.FANPAGE.ACCEPTED,
                isOwner: false,
                isJoin: true,
                pageSize: PAGE_SIZE,
                page: parseInt(dataFanpages.member.length/PAGE_SIZE) +1
            }
			const result = await window.COMMON.query(FanpageGRAPHQL.QUERY_FANPAGE_RELATIONSHIPS_BY_USER, params)
            if (result?.data?.getFanpageRelationships?.data?.length >0) {
                if ((result?.data.getFanpageRelationships.data.length + dataFanpages.member.length) >= total.fanpageMember) {
                    setIsStillMore(false)
                } else {
                    setIsStillMore(true)
				}
                setTimeout(() => {
                    dataFanpages.member = [...dataFanpages.member,...result?.data.getFanpageRelationships.data]
                    setDataFanpages({...dataFanpages})
                }, 500)
            }
        } catch (error) {
            window.COMMON.showErrorLogs('MyFanpagePage.fetchMoreData');
        }
	}

    return (
        <Grid container >
            <div className='left-block-fanpage' >
                <div id="left-div-control" className='bg-white p-2 rounded container-list-question left-div-control'>
                    <Grid container>
                        <InputBase
                            value={searchText}
                            onChange={handleChangeText}
                            classes={{root: classes.root, focused:classes.focused }}
                            placeholder='Search'
                            endAdornment={
                                <InputAdornment position='end'>
                                    <Search color='disabled' />
                                </InputAdornment>
                            }
                        >
                        </InputBase>
                    </Grid>
                    <div  onClick={()=>{history.push('/my-fanpage')}} className={`${active ===Constant.FANPAGE.FEEDS_MODE ?'active':''} item section-div-fanpage`}>
                        <i style={{ backgroundImage: `url(${image})`, backgroundPosition: "0px -344px", backgroundSize: "25px 496px"}}
                            className='icon-fanpange'
                        ></i>
                        {window.I18N("fanpage_feeds")}
                    </div>
                    <div onClick={() => { history.push('/my-fanpage?discover') }} className={`${active ===Constant.FANPAGE.DISCOVER_MODE?'active':''} item section-div-fanpage item`}>
                        <i style={{ backgroundImage: `url(${image})`, backgroundPosition: "0px -280px", backgroundSize: "25px 496px"}}
                        className='icon-fanpange'></i>
                        {window.I18N("discover")}
                    </div>
                    <Grid container className=' flex justify-content-center mt-2 mb-4'>
                        <Button className='text-capitalize' color="primary" variant='contained' onClick={() => { history.push('/my-fanpage?new-fanpage') }}>{window.I18N("create_fanpage")}</Button>
                    </Grid>
                    
                    <hr className='mb-2'></hr>
                    <Grid container className='section-div-fanpage'>{`${window.I18N("fanpages_manage")} (${total.fanpageOwner})`}</Grid>
                    {dataFanpages.owner.map((fanpage,i) =>
                        fanpage?.fanpage?.name.toLowerCase().includes(searchText.toLowerCase())&&<FanpageDiv active={active===fanpage.fanpage._id} key={i} data={fanpage.fanpage}></FanpageDiv>

                    )}
                    {dataFanpages.owner.length < total.fanpageOwner&&<div className='text-center'>
                        <span onClick={onLoadMoreOwnerFanpage} className={`text-primary cursor-pointer mt-1 ${classes.hover}`}>{window.I18N('load_more') }</span>
                    </div>}
                    <hr className='mb-2'></hr>
                    <Grid container className='section-div-fanpage'>{`${window.I18N("fanpages_joined")} (${total.fanpageMember})`}</Grid>
                    
                    <InfiniteScroll
                        dataLength={dataFanpages.member.length}
                        next={fetchMoreData}
                        hasMore={isStillMore && searchText===''}
                        scrollableTarget="left-div-control"
                        loader={<div className='text-center py-2 '><LoadingSpinner /></div>}
                        endMessage={
                            <p className='text-center mt-2 mb-0 grey-text'>
                                <span>{window.I18N('no_more_fanpage')}</span>
                            </p>
                        }
                    >
                        {dataFanpages.member.map((fanpage, i) =>
                        fanpage?.fanpage?.name.toLowerCase().includes(searchText.toLowerCase())&&<FanpageDiv active={active===fanpage.fanpage._id} key={i} data={fanpage.fanpage}></FanpageDiv>
                    )}
                    </InfiniteScroll>
                </div>
            </div>
            <div className='px-2 right-block-fanpage' >
                <FanpageContent reloadParent={getDataFanpage} language={props?.language?.code}></FanpageContent>
            </div>
        </Grid>
    )
}

const mapStateToProps = state => ({
    language: state.language.value
});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(MyFanpagePage);
