const postEvent = {
  ADD_POST: 'ADD_POST',
  DEL_POST: 'DEL_POST',
  SHARE_POST: 'SHARE_POST',
  LIKE_POST: 'LIKE_POST',
  DISLIKE_POST: 'DISLIKE_POST',
  REVIEW_POST: 'REVIEW_POST',
  HIDE_POST: 'HIDE_POST',
  UNHIDE_POST: 'UNHIDE_POST',
  ACCEPT_REPORT_POST: 'ACCEPT_REPORT_POST',
  DECLINE_REPORT_POST: 'DECLINE_REPORT_POST',
  ANSWER_SURVEY_POST: 'ANSWER_SURVEY_POST'
};

export default postEvent;