import React, { useEffect, useRef, useState } from 'react'
import { MDBCarousel, MDBCarouselInner, MDBCarouselItem } from 'mdbreact'

import styles from './Carousel.module.css'
import Image from '../Image'

const MIN_ZOOM = 1
const MAX_ZOOM = 5
const STEP_SIZE = 0.1
const ZOOM_IN = 1
const ZOOM_OUT = -1
const FIRST_ACTIVE_INDEX = 1
let currentZoom = MIN_ZOOM
const Carousel = ({
    data = [],
    imageVariant = 'no-rounded',
    showControls = true,
    activeItem = FIRST_ACTIVE_INDEX,
    canZoomImage = false,
    ...passProps
}) => {
    const [activeIndex, setActiveIndex] = useState(activeItem)
    const [dataImages, setDataImages] = useState(data)
    const [isMouseMoving, setIsMouseMoving] = useState(false)
    const [percentOrigin, setPercentOrigin] = useState({
        originX: 0,
        originY: 0
    })
    const imagesRef = useRef({})
    const imageContainerRef = useRef()

    useEffect(() => {
        const nextButton = document.querySelector('.carousel-control-next')
        const prevButton = document.querySelector('.carousel-control-prev')
        const LAST_ACTIVE_INDEX = dataImages.length

        const handleNext = () => {
            if (activeIndex === LAST_ACTIVE_INDEX) {
                setActiveIndex(FIRST_ACTIVE_INDEX)
            }

            if (activeIndex < LAST_ACTIVE_INDEX) {
                setActiveIndex((prev) => prev + 1)
            }
            handleResetZoomImage()
        }
        const handlePrev = () => {
            if (activeIndex === FIRST_ACTIVE_INDEX) {
                setActiveIndex(LAST_ACTIVE_INDEX)
            }
            if (activeIndex > FIRST_ACTIVE_INDEX) {
                setActiveIndex((prev) => prev - 1)
            }
            handleResetZoomImage()
        }
        if (nextButton && nextButton) {
            nextButton.addEventListener('click', handleNext)
            prevButton.addEventListener('click', handlePrev)
        }
        return () => {
            if (nextButton && nextButton) {
                nextButton.removeEventListener('click', handleNext)
                prevButton.removeEventListener('click', handlePrev)
            }
        }
    }, [data, activeIndex])

    useEffect(() => {
        setDataImages(data)
        // Clean up the effect
        return () => {
            setDataImages([])
        }
    }, [data])

    useEffect(() => {
        const zoomImageCallback = function (event) {
            setIsMouseMoving(true)
            let direction = event.deltaY > 0 ? ZOOM_OUT : ZOOM_IN
            const container = imageContainerRef.current
            const rect = container.getBoundingClientRect()
            const offsetX = event.clientX - rect.left
            const offsetY = event.clientY - rect.top

            // Set transform-origin to zoom according to mouse position
            const originX = (offsetX / container.offsetWidth) * 100
            const originY = (offsetY / container.offsetHeight) * 100
            handleZoomImage(direction, { offsetX: percentOrigin.originX, offsetY: percentOrigin.originY })
            setPercentOrigin({ originX, originY })
        }
        if (canZoomImage) {
            imageContainerRef.current.addEventListener('wheel', zoomImageCallback)
        }

        return () => imageContainerRef.current.removeEventListener('wheel', zoomImageCallback)
    }, [activeIndex, percentOrigin])

    const handleReverseOrigin = ({ mouseX, mouseY }) => {
        const endX = mouseX
        const endY = mouseY

        const container = imageContainerRef.current
        const rect = container.getBoundingClientRect()
        const containerWidth = rect.width
        const containerHeight = rect.height

        // Calculate mouse position relative to the container
        const relativeX = Math.min(Math.max(endX - rect.left, 0), containerWidth)
        const relativeY = Math.min(Math.max(endY - rect.top, 0), containerHeight)

        // Calculate percent for transform-origin
        const originXPercent = (relativeX / containerWidth) * 100
        const originYPercent = (relativeY / containerHeight) * 100

        return {
            originX: originXPercent,
            originY: originYPercent
        }
    }

    const handleDragging = (event) => {
        if (!isMouseMoving || !canZoomImage) return
        if (event.clientX === 0 && event.clientY === 0) return
        const container = imageContainerRef.current
        const rect = container.getBoundingClientRect()
        if (event.clientX > rect.right || event.clientY > rect.bottom) {
            return
        }
        let image = imagesRef.current[activeIndex]
        requestAnimationFrame(() => {
            image.style.transformOrigin = `${percentOrigin.originX}% ${percentOrigin.originY}%`
        })
        const { originX, originY } = handleReverseOrigin({ mouseX: event.clientX, mouseY: event.clientY })
        setPercentOrigin({ originX, originY })
    }

    const handleMouseEnter = () => {
        setIsMouseMoving(true)
    }

    const handleMouseMove = (event) => {
        event.preventDefault()
        event.stopPropagation()
        if (isMouseMoving) {
            handleDragging(event)
        }
    }

    const handleResetZoomImage = () => {
        currentZoom = MIN_ZOOM
        let image = imagesRef.current[activeIndex]
        image.style.transform = 'none'
    }

    const handleZoomImage = (direction, { offsetX, offsetY }) => {
        let newZoom = currentZoom + direction * STEP_SIZE

        // Limit the zoom level to the minimum and maximum
        // values
        if (newZoom < MIN_ZOOM || newZoom > MAX_ZOOM) {
            return
        }
        newZoom === MIN_ZOOM && setIsMouseMoving(false)
        currentZoom = newZoom

        // Update the CSS transform of the image to scale it
        let image = imagesRef.current[activeIndex]
        if (direction === ZOOM_IN) {
            image.style.transformOrigin = `${offsetX}% ${offsetY}%`
        }
        image.style.transform = 'scale(' + currentZoom + ')'
    }

    return (
        <div
            className="w-100 h-100"
            ref={imageContainerRef}
            onMouseEnter={handleMouseEnter}
            onMouseMove={handleMouseMove}
            style={{ userSelect: 'none' }}
        >
            <MDBCarousel
                activeItem={activeItem}
                length={dataImages.length}
                showControls={showControls}
                showIndicators={false}
                slide={true}
                className={`w-100 h-100 ${styles['container']}`}
                {...passProps}
            >
                <MDBCarouselInner>
                    {dataImages.map((item, index) => (
                        <MDBCarouselItem key={item.imageUrl} itemId={index + 1}>
                            <Image
                                className={`w-100 h-100 ${styles[imageVariant]}`}
                                src={item.imageUrl}
                                alt=""
                                ref={(el) => (imagesRef.current[`${index + 1}`] = el)}
                                style={{
                                    userSelect: 'none',
                                    userDrag: 'none',
                                    cursor: canZoomImage ? (isMouseMoving ? 'grabbing' : 'grab') : "auto",
                                    transition: canZoomImage ? (isMouseMoving ? 'none' : 'transform 0.2s ease') : 'none'
                                }}
                            />
                        </MDBCarouselItem>
                    ))}
                </MDBCarouselInner>
            </MDBCarousel>
        </div>
    )
}

export default Carousel
