import React, { useState, useLayoutEffect, useCallback } from 'react';
import { NavLink } from 'react-router-dom';
import { MDBRow, MDBCol, MDBIcon, MDBBtn } from 'mdbreact';

import Pagination from './Pagination';

import AccountGRAPHQL from '../../graphql/Account';

const TableNetwork = React.memo(function TableNetwork(props) {
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({ total: 0, pageSize: 10, currentPage: 0 });

  useLayoutEffect(() => {
    setTimeout(() => {
      if (props.companyIndustry) {
        getDataCompanyByIndustry(props.companyIndustry);
      }
    }, 100)
  }, [props.companyIndustry]);

  const getDataCompanyByIndustry = async () => {
    try {
      const params = {
        input: {
          companyIndustry: props.companyIndustry,
          pageSize: pagination.pageSize,
          page: pagination.currentPage + 1
        }
      };
      const result = await window.COMMON.query(AccountGRAPHQL.QUERY_COMPANY_BY_INDUSTRY, params);
      let data = [];
      if (result && result.data && result.data.getCompanyByIndustry) {
        data = result.data.getCompanyByIndustry.data;
        data = window.COMMON.formatMultiDate(data, 'createdDate,establishedDate,birthday');
        data.forEach(element => {
          let address = window.COMMON.getValueFromAttr(element, 'address', '');
          const nation = window.COMMON.getValueFromAttr(element, 'nation.name', '');
          const state = window.COMMON.getValueFromAttr(element, 'state.name', '');
          const city = window.COMMON.getValueFromAttr(element, 'city.name', '');
          address = address + (city ? ', ' + city : '') + (state ? ', ' + state : '') + (nation ? ', ' + nation : '');
          element.addressText = address;
        });
        pagination.total = result.data.getCompanyByIndustry.total - 1;
      }
      setPagination(pagination);
      setData(data);
    } catch (error) {
      window.COMMON.showErrorLogs('TableNetwork.getDataCompanyByIndustry');
    }
  };

  const changePage = useCallback((index) => {
    pagination.currentPage = index;
    setPagination({ ...pagination });
    getDataCompanyByIndustry();
    // eslint-disable-next-line
  }, [pagination]);

  const doAddFriend = (user) => {
    if (props.doAddFriend) (
      props.doAddFriend(user)
    )
  }

  const doSendMessage = (user) => {
    if (props.doSendMessage) (
      props.doSendMessage(user)
    )
  }

  return (
    <div className="w-100">
      <ul className="list-group">
        <li className="list-group-item pt-3">
          <Pagination total={pagination.total} pageSize={pagination.pageSize} currentPage={pagination.currentPage} changePage={changePage}></Pagination>
        </li>
        {
          data.map((item, i) => 
            <li className="list-group-item p-3" key={i}>
              <MDBRow>
                <MDBCol>
                  <p className="font-weight-bold mb-1">
                    {window.COMMON.getValueFromAttr(item, 'name')}
                  </p>
                  <div className="d-flex align-items-center mb-1">
                    <span>
                      <small className="text-muted">{window.I18N('address')}</small>:&nbsp;
                      <small className="font-weight-bold">{window.COMMON.getValueFromAttr(item, 'addressText')}</small>
                    </span>
                  </div>
                  <div className="d-flex align-items-center mb-1">
                    <span>
                      <small className="text-muted">{window.I18N('tax_id')}</small>:&nbsp;
                      <small className="font-weight-bold">{window.COMMON.getValueFromAttr(item, 'taxCode')}</small>
                    </span>
                  </div>
                  <div className="d-flex align-items-center mb-1">
                    <span>
                      <small className="text-muted">{window.I18N('website')}</small>:&nbsp;
                      <small className="font-weight-bold">{window.COMMON.getValueFromAttr(item, 'website')}</small>
                    </span>
                  </div>
                </MDBCol>
                <MDBCol>
                  <div className="d-flex align-items-center mb-1">
                    <span>
                      <small className="text-muted">{window.I18N('work_email_address')}</small>:&nbsp;
                      <small className="font-weight-bold">{window.COMMON.getValueFromAttr(item, 'email')}</small>
                    </span>
                  </div>
                  <div className="d-flex align-items-center mb-1">
                    <span>
                      <small className="text-muted">{window.I18N('industry')}</small>:&nbsp;
                      <small className="font-weight-bold">{window.COMMON.getValueFromAttr(item, 'companyIndustry.value')}</small>
                    </span>
                  </div>
                  <div className="mb-1">
                    <small className="text-muted">{window.I18N('customers')}</small>:&nbsp;
                    <ul className="m-0 pl-3" dangerouslySetInnerHTML={{__html: window.COMMON.getStringArrsWithLanguage(item, 'mainCustomers', 'value', props.language)}}>
                    </ul>
                  </div>
                  <div className="mb-1">
                    <small className="text-muted">{window.I18N('market')}</small>:&nbsp;
                    <ul className="m-0 pl-3" dangerouslySetInnerHTML={{__html: window.COMMON.getStringArrsWithLanguage(item, 'markets', 'value', props.language)}}>
                    </ul>
                  </div>
                </MDBCol>
                <MDBCol>
                  <div className="d-flex align-items-center mb-1">
                    <span>
                      <small className="text-muted">{window.I18N('phone_number')}</small>:&nbsp;
                      <small className="font-weight-bold">{window.COMMON.getValueFromAttr(item, 'phone')}</small>
                    </span>
                  </div>
                  <div className="d-flex align-items-center mb-1">
                    <span>
                      <small className="text-muted">{window.I18N('headcount')}</small>:&nbsp;
                      <small className="font-weight-bold">{window.COMMON.getValueFromAttr(item, 'companySize.value')}</small>
                    </span>
                  </div>
                  <div className="mb-1">
                    <small className="text-muted">{window.I18N('main_industry')}</small>:&nbsp;
                    <ul className="m-0 pl-3" dangerouslySetInnerHTML={{__html: window.COMMON.getStringArrsWithLanguage(item, 'mainIndustries', 'value', props.language)}}>
                    </ul>
                  </div>
                  <div className="mb-1">
                    <small className="text-muted">{window.I18N('main_product')}</small>:&nbsp;
                    <ul className="m-0 pl-3" dangerouslySetInnerHTML={{__html: window.COMMON.getStringArrsWithLanguage(item, 'mainProducts', 'value', props.language)}}>
                    </ul>
                  </div>
                </MDBCol>
                <MDBCol xs="2" sm="1" md="1" className="text-right">
                  <div className="dropdown">
                    <NavLink to="#" className="" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-boundary="viewport">
                      <MDBIcon fa="true" icon="ellipsis-v" className="fa-fw black-text"></MDBIcon>
                    </NavLink>
                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton" data-boundary="viewport">
                      {item.friendStatus === '' ? <li className="dropdown-item" onClick={() => doAddFriend(item)}>
                        <MDBIcon fa="true" icon="user-plus" className="fa-fw mr-1"></MDBIcon> {
                          window.I18N('add_friend')
                        }
                      </li> : <li className="dropdown-item disabled">
                        <MDBIcon fa="true" icon="user-plus" className="fa-fw mr-2"></MDBIcon> 
                        {
                          item.friendStatus === window.CONSTANT.PENDING ? window.I18N('pending') : item.friendStatus === window.CONSTANT.ACCEPTED ? window.I18N('friends') : window.I18N('blocked')
                        }
                      </li> }
                      <li className="dropdown-item" onClick= {() => doSendMessage(item)}>
                        <MDBIcon fa="true" icon="paper-plane" className="fa-fw mr-1"></MDBIcon> {window.I18N('send_message')}
                      </li>
                    </ul>
                  </div>
                </MDBCol>
              </MDBRow>
              { !props.checkNoFacilities(item) && <div>
                  <MDBBtn color="" className="m-0 mt-2" onClick={() => props.onShowFacilities(item)}>
                    <MDBIcon fa="true" icon="info-circle" className="fa-fw" /> {window.I18N('facility_info')}
                  </MDBBtn>
              </div>}
            </li>
          )
        }
      </ul>
    </div>
  );
});
export default TableNetwork;