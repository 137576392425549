import gql from 'graphql-tag';

const QUERY_METADATA = gql`
  query($type: [String!]) {
    getMetadatas(type: $type) {
      _id
      value
      code
      type
      createdDate
      createdBy {
        name
      }
    }
  }
`;

const QUERY_SHORT_METADATA = gql`
  query($type: [String!]) {
    getMetadatas(type: $type) {
      _id
      value
      code
      type
    }
  }
`;

const QUERY_SUB_METADATA = gql`
  query($type: [String!], $parent: String) {
    getSubMetadatas(type: $type, parent: $parent) {
      _id
      value {
        code
        text
      }
      code
      type
      description {
        code
        text
      }
      parent {
        _id
      }
      createdDate
      createdBy {
        name
        nickname
      }
    }
  }
`;

const QUERY_SHORT_SUB_METADATA = gql`
  query($type: [String!], $parent: String) {
    getSubMetadatas(type: $type, parent: $parent) {
      _id
      value {
        code
        text
      }
      code
      type
      description {
        code
        text
      }
    }
  }
`;

const QUERY_APPS_CONNECT = gql`
  query($type: [String!], $isActive: [Boolean!]) {
    getApps(type: $type, isActive: $isActive) {
      _id
      code
      image
      type
      name {
        code
        text
      }
      description {
        code
        text
      }
      content
      index
      isActive
      createdDate
      createdBy {
        name
        nickname
      }
    }
  }
`;

const QUERY_LOCATION = gql`
  query {
    getLocations {
      _id
      name
      code
      parent
    }
  }
`;

const QUERY_REASON = gql`
  query($type: String!) {
    getReasons(type: $type, isActive: [true]) {
      _id
      code
      content {
        code
        text
      }
    }
  }
`;

const QUERY_ORGANIZATION_DRIVE = gql`
  query($organization: String!) {
    getAllOrganizationDrives(organization: $organization) {
      _id
      name
      parent
      isHide
      isFile
      dataFile {
        id
        name
        ext
        type
        size
      }
      organization {
        _id
      }
      createdDate
      createdBy {
        name
      }
      updatedDate
      updatedBy {
        name
      }
    }
  }
`;

const QUERY_CONFIG = gql`
  query {
    getConfigs {
      _id
      code
      name
      value
      createdDate
      createdBy {
        name
      }
    }
  }
`;

const QUERY_STICKERS = gql`
  query($parentCode: String!) {
    getStickers(parentCode: $parentCode) {
      _id
      code
      name
      icon
      createdDate
      createdBy {
        name
      }
    }
  }
`;

const MUTATION_ADD_METADATA = gql`
  mutation($input: MetadataInput!) {
    addMetadata(input: $input)
  }
`;

const MUTATION_EDIT_METADATA = gql`
  mutation($input: MetadataInput!) {
    editMetadata(input: $input)
  }
`;

const MUTATION_DEL_METADATA = gql`
  mutation($id: String!) {
    deleteMetadata(id: $id)
  }
`;

const MUTATION_ADD_SUB_METADATA = gql`
  mutation($input: SubMetadataInput!) {
    addSubMetadata(input: $input)
  }
`;

const MUTATION_EDIT_SUB_METADATA = gql`
  mutation($input: SubMetadataInput!) {
    editSubMetadata(input: $input)
  }
`;

const MUTATION_DEL_SUB_METADATA = gql`
  mutation($id: String!) {
    deleteSubMetadata(id: $id)
  }
`;

const MUTATION_ADD_APPS_CONNECT = gql`
  mutation($input: AppsInput!) {
    addApps(input: $input)
  }
`;

const MUTATION_EDIT_APPS_CONNECT = gql`
  mutation($input: AppsInput!) {
    editApps(input: $input)
  }
`;

const MUTATION_UPDATE_STATUS_APPS_CONNECT = gql`
  mutation($id: String!, $isActive: Boolean!) {
    updateStatusApps(id: $id, isActive: $isActive)
  }
`;

const MUTATION_DELETE_STATUS_APPS_CONNECT = gql`
  mutation($id: String!) {
    deleteStatusApps(id: $id)
  }
`;

const MUTATION_ADD_ORGANIZATION_DRIVE = gql`
  mutation($input: OrganizationDriveInput!) {
    addOrganizationDrive(input: $input)
  }
`;

const MUTATION_EDIT_ORGANIZATION_DRIVE = gql`
  mutation($input: OrganizationDriveInput!) {
    editOrganizationDrive(input: $input)
  }
`;

const MUTATION_DEL_ORGANIZATION_DRIVE = gql`
  mutation($id: String!) {
    deleteOrganizationDrive(id: $id)
  }
`;

const MUTATION_ADD_CONFIG = gql`
  mutation($input: ConfigInput!) {
    addConfig(input: $input)
  }
`;

const MUTATION_EDIT_CONFIG = gql`
  mutation($input: ConfigInput!) {
    editConfig(input: $input)
  }
`;

const MUTATION_DEL_CONFIG = gql`
  mutation($id: String!) {
    deleteConfig(id: $id)
  }
`;

const MUTATION_ADD_STICKERS = gql`
  mutation ($input: [StickerInput!]) {
    addSticker(input: $input)
  }
`;

const MUTATION_EDIT_STICKER = gql`
  mutation ($input: StickerInput!) {
    editSticker(input: $input)
  }
`;

const MUTATION_DEL_STICKER = gql`
  mutation ($id: String!) {
    deleteSticker(id: $id)
  }
`;

const MUTATION_CHECK_IMPORT_SUB_METADATA = gql`
  mutation($input: [String!], $type: String!, $parent: String) {
    checkImportSubMetadata(input: $input, type: $type, parent: $parent)
  }
`;

const MUTATION_IMPORT_SUB_METADATA = gql`
  mutation($input: [SubMetadataInput!]) {
    importSubMetadata(input: $input)
  }
`;

const MetadataGRAPHQL = {
  QUERY_METADATA,
  QUERY_SHORT_METADATA,
  QUERY_SUB_METADATA,
  QUERY_SHORT_SUB_METADATA,
  QUERY_APPS_CONNECT,
  QUERY_LOCATION,
  QUERY_REASON,
  QUERY_CONFIG,
  QUERY_ORGANIZATION_DRIVE,
  QUERY_STICKERS,
  MUTATION_ADD_METADATA,
  MUTATION_EDIT_METADATA,
  MUTATION_DEL_METADATA,
  MUTATION_ADD_SUB_METADATA,
  MUTATION_EDIT_SUB_METADATA,
  MUTATION_DEL_SUB_METADATA,
  MUTATION_ADD_APPS_CONNECT,
  MUTATION_EDIT_APPS_CONNECT,
  MUTATION_UPDATE_STATUS_APPS_CONNECT,
  MUTATION_DELETE_STATUS_APPS_CONNECT,
  MUTATION_ADD_ORGANIZATION_DRIVE,
  MUTATION_EDIT_ORGANIZATION_DRIVE,
  MUTATION_DEL_ORGANIZATION_DRIVE,
  MUTATION_ADD_CONFIG,
  MUTATION_EDIT_CONFIG,
  MUTATION_DEL_CONFIG,
  MUTATION_ADD_STICKERS,
  MUTATION_EDIT_STICKER,
  MUTATION_DEL_STICKER,
  MUTATION_CHECK_IMPORT_SUB_METADATA,
  MUTATION_IMPORT_SUB_METADATA
};

export default MetadataGRAPHQL;