import gql from 'graphql-tag';

const QUERY_COMMENT = gql`
    query($postId: String!) {
      getComments(postId: $postId) {
        _id
        text
        image
        sticker
        postId
        reply {
          _id
          text
          image
          sticker
          postId
          like {
            createdBy {
              _id
            }
          }
          dislike {
            createdBy {
              _id
            }
          }
          createdDate
          createdBy {
            _id
            nickname
            avatar
          }
        }
        like {
          createdBy {
            _id
          }
        }
        dislike {
          createdBy {
            _id
          }
        }
        createdDate
        createdBy {
          _id
          nickname
          avatar
        }
      }
    }
  `;

const QUERY_FIND_COMMENT = gql`
  query($input: FindCommentsInput!) {
    findComments(input: $input) {
      _id
      text
      postId {
        isPublic
      }
      createdDate
      createdBy {
        _id
        avatar
        nickname
        name
      }
      deletedDate
      deletedBy {
        _id
        avatar
        nickname
        name
      }
      status
    }
  }
`;

const QUERY_EMOTION = gql`
  query {
    getEmotions {
      _id
      content {
        code
        text
      }
      code
      icon
      createdDate
      createdBy {
        _id
        name    
      }
    }
  }
`;

const MUTATION_ADD_COMMENT = gql`
   mutation ($input: CommentInput!) {
     addComment(input: $input) {
       _id
       gopy
     }
   }
 `;

const MUTATION_DEL_COMMENT = gql`
  mutation($id: String!) {
    deleteComment(id: $id)
  }
`;

const  MUTATION_LIKE_COMMENT = gql`
  mutation($input: LikeCommentInput!) {
    likeComment(input: $input) {
      _id
      gopy
    }
  }
`;

const  MUTATION_DISLIKE_COMMENT = gql`
  mutation($input: LikeCommentInput!) {
    dislikeComment(input: $input) {
      _id
      gopy
    }
  }
`;

const MUTATION_ADD_EMOTION = gql`
  mutation($input: EmotionInput!) {
    addEmotion(input: $input)
  }
`;

const MUTATION_EDIT_EMOTION = gql`
  mutation($input: EmotionInput!) {
    editEmotion(input: $input)
  }
`;

const MUTATION_DEL_EMOTION = gql`
  mutation($id: String!) {
    deleteEmotion(id: $id)
  }
`;

const CommentGRAPHQL = {
  QUERY_COMMENT,
  QUERY_FIND_COMMENT,
  QUERY_EMOTION,
  MUTATION_ADD_COMMENT,
  MUTATION_DEL_COMMENT,
  MUTATION_LIKE_COMMENT,
  MUTATION_DISLIKE_COMMENT,
  MUTATION_ADD_EMOTION,
  MUTATION_EDIT_EMOTION,
  MUTATION_DEL_EMOTION
};

export default CommentGRAPHQL;
