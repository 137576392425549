import React, { useMemo, useState } from 'react'
import styles from './ImagesGrid.module.css'
import { Modal } from '@material-ui/core'
import Carousel from '../Carousel/Carousel'
import Image from '../Image'

const DEFAULT_MAX_ITEMS = 5
const START_POSTION_SLICE = 0
const ImagesGrid = ({ images = [] }) => {
    const [viewDetailImages, setViewDetailImages] = useState({
        activeImage: null,
        isView: false
    })

    const IMAGES_LENGTH = useMemo(() => {
        const imagesLength = images.length
        return imagesLength > DEFAULT_MAX_ITEMS ? DEFAULT_MAX_ITEMS : imagesLength
    }, [images])

    const handleOpenViewDetailImages = (index) => {
        setViewDetailImages({
            activeImage: index,
            isView: true
        })
    }

    const handleCloseViewDetailImages = () => {
        setViewDetailImages({
            activeImage: null,
            isView: false
        })
    }

    if (window.COMMON.isEmpty(images)) {
        return null
    }

    return (
        <>
            {/* Grid layout with quantity items - max 5 items */}
            <div className={styles[`image-grid-${IMAGES_LENGTH}-items`]}>
                {images.slice(START_POSTION_SLICE, IMAGES_LENGTH).map((image, index) => (
                    <div
                        key={index}
                        className={`${styles['image-item']} ${
                            styles[`image-item-grid-${IMAGES_LENGTH}-items-child-${index + 1}`]
                        }`}
                        onClick={() => handleOpenViewDetailImages(index + 1)}
                    >
                        <Image src={image} alt={`image-${index}`} />
                        {/* Overlay for last item, display remaining quantity items */}
                        {index === DEFAULT_MAX_ITEMS - 1 && images.length > DEFAULT_MAX_ITEMS && (
                            <div className={styles.overlay}>+{images.length - DEFAULT_MAX_ITEMS}</div>
                        )}
                    </div>
                ))}
            </div>
            {/* Showing items image with carousel */}
            <Modal
                className="d-flex align-items-center justify-content-center"
                open={viewDetailImages.isView}
                onClose={handleCloseViewDetailImages}
            >
                <div className="d-flex align-items-center justify-content-center" style={{ margin: '20%' }}>
                    <Carousel
                        data={images.map((image) => ({ imageUrl: image }))}
                        activeItem={viewDetailImages.activeImage}
                        imageVariant="rounded-full"
                        showControls={images.length > 1}
                        canZoomImage
                        interval={9999999999}
                    />
                </div>
            </Modal>
        </>
    )
}

export default ImagesGrid
