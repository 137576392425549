import React, { useState, useCallback, useLayoutEffect } from 'react';
import { MDBRow, MDBCol, MDBInputGroup, MDBInput, MDBBtn, MDBIcon, MDBCardImage } from 'mdbreact';

import Panel from '../../components/Panel';
import Modal from '../../components/Modal';
import RadioBox from '../../components/RadioBox';
import Pagination from '../../components/Pagination';
import InputFile from '../../components/InputFile';
import ListOption from '../../components/ListOption';
import ColorPicker from '../../components/ColorPicker';
import GridItemBackground from '../../components/GridItemBackground';

import PostGRAPHQL from '../../../graphql/Post';
import eventName from '../../../common/events';
import { useTracking } from 'react-tracking';

export default function BackgroundPostPage(props) {
  const { trackEvent } = useTracking();

  const types =  [
    { value: false, label: window.I18N('background')},
    { value: true, label:  window.I18N('gif') }
  ];
  const [filter, setFilter] = useState({radio: false, text: ''});
  const [pagination, setPagination] = useState({ total: 0, pageSize: 20, currentPage: 0 });
  const [object, setObject] = useState({ index: -1 });
  const [data, setData] = useState([]);
  const [dataSave, setDataSave] = useState({});

  useLayoutEffect(() => {
    setTimeout(() => {
      getDataBackgroundPost();
    }, 100);
    // eslint-disable-next-line
  }, []);

  const handleSearchWithEnter = (event) => {
    if (event.key === 'Enter') {
      getDataBackgroundPost();
    }
  }
 
  const createTrackingEvent = (event) => {
    return trackEvent({
      name: event,
      createdAt: new Date().toISOString()
    });
  };

  const handleFilterChange = (event) => {
    const text = event.target.value;
    if(!window.COMMON.validateTextInput(text)){
      return
    }
    setFilter((prev) => ({ ...prev, text }));
  };

  const handleChange = (event) => {
    if (event.target.type === 'checkbox') {
      dataSave[event.target.name] = event.target.checked;
    } else {
      dataSave[event.target.name] = event.target.value;
    }
    setDataSave({ ...dataSave });
  };

  const handleColor = (color) => {
    dataSave.color = color;
    setDataSave({ ...dataSave });
  };

  const receiveHashtag = useCallback((data) => {
    dataSave.hashtag = data;
    setDataSave({ ...dataSave });
  }, [dataSave]);

  const handleChangeMulti = (event, index) => {
    if (event.target.type === 'checkbox') {
      dataSave.input[index][event.target.name] = event.target.checked;
    } else {
      dataSave.input[index][event.target.name] = event.target.value;
    }
    setDataSave({ ...dataSave });
  };

  const handleColorMulti = (color, index) => {
    dataSave.input[index].color = color;
    setDataSave({ ...dataSave });
  };

  const receiveHashtagMulti = useCallback((data, index) => {
    dataSave.input[index].hashtag = data;
    setDataSave({ ...dataSave });
  }, [dataSave]);

  const handleRadio = useCallback((value) => {
    filter.radio = value;
    setFilter({ ...filter });
    doSearchData();
    // eslint-disable-next-line
  }, [filter]);

  const handleFile = (file) => {
    dataSave.file = file;
    dataSave.background = window.COMMON.createBlobImage(file);
    setDataSave({ ...dataSave });
  };

  const handleFiles = (files) => {
    const input = [];
    let index = 0;
    files.forEach(file => {
      index++;
      const ext = file.name.split('.').pop();
      const blob = file.slice(0, file.size, file.type); 
      const newFile = new File([blob], window.COMMON.generateCode('background') + '-' + index + '.' + ext, {type: file.type});
      const obj = {};
      obj.name = window.COMMON.generateCode('background') + '-' + index;
      obj.isGif = ext.toLowerCase().indexOf('gif') > -1;
      obj.hashtag = [];
      obj.color = '#000000';
      obj.background = newFile.name;
      obj.url = window.COMMON.createBlobImage(file);
      obj.file = newFile;
      input.push(obj);
    });
    dataSave.input = input;
    setDataSave({ ...dataSave });
  };

  const getDataBackgroundPost = async () => {
    try {
      const params = {
        input: {
          name: filter.text.trim(),
          isGif: filter.radio,
          pageSize: pagination.pageSize,
          page: pagination.currentPage + 1
        }
      };
      const result = await window.COMMON.query(PostGRAPHQL.QUERY_BACKGROUND_POST, params);
      let data = [];
      if (result && result.data && result.data.getBackgroundPosts) {
        data = window.COMMON.formatDate(result.data.getBackgroundPosts.data, 'createdDate'); 
        pagination.total = result.data.getBackgroundPosts.total;
      }
      setPagination(pagination);
      setData(data);
    } catch (error) {
      window.COMMON.showErrorLogs('BackgroundPostPage.getDataBackgroundPost');
    }
  };

  const doSearchData = () => {
    pagination.currentPage = 0;
    getDataBackgroundPost();
  };

  const changePage = useCallback((index) => {
    pagination.currentPage = index;
    setPagination({ ...pagination });
    getDataBackgroundPost();
    // eslint-disable-next-line
  }, [object, pagination, filter]);

  const openAddDialog = useCallback((index) => {
    window.COMMON.showModal('#modal-add-background-post');
    object.index = index;
    const dataSave = {};
    setObject(object);
    setDataSave({ ...dataSave });
  }, [object, data]);

  const deleteItem = (index) => {
    dataSave.input.splice(index, 1);
    setDataSave({ ...dataSave });
  };

  const openSaveDialog = useCallback((index) => {
    window.COMMON.showModal('#modal-background-post');
    object.index = index;
    const dataSave = { file: null };
    const obj = data[index];
    dataSave._id = window.COMMON.getValueFromAttr(obj, '_id', null);
    dataSave.name = window.COMMON.getValueFromAttr(obj, 'name');
    dataSave.background = window.COMMON.getValueFromAttr(obj, 'background');
    dataSave.color = window.COMMON.getValueFromAttr(obj, 'color');
    dataSave.isGif = window.COMMON.getValueFromAttr(obj, 'isGif');
    dataSave.hashtag = window.COMMON.getValueFromAttr(obj, 'hashtag');
    setObject(object);
    setDataSave({ ...dataSave });
  }, [object, data]);

  const openDeleteDialog = useCallback((index) => {
    object.index = index;
    setObject(object);
    window.COMMON.showModal('#modal-delete');
    window.deleteMethod = deleteData;
    // eslint-disable-next-line
  }, [object, data]);

  const addData = async (event) => {
    let dataFiles = []
    try {
      if (!window.COMMON.checkFormValidation('form-add-background-post', event)) {
        return;
      }
      const files = [];
      dataSave.input.forEach(element => {
        files.push(element.file);
      });
      dataFiles = await window.uploadFile(files);
      if (dataFiles.length > 0) {
        dataFiles.forEach(file => {
          const obj = window.COMMON.getObjectInArrs(file.originalFilename, dataSave.input, 'background');
          if (obj) {
            obj.background = window.COMMON.getNewPathFile(file._id);
            delete obj.file;
            delete obj.url;
          }
        });
      }
      let params = {
        input: dataSave.input || []
      };
      window.COMMON.trimData(params);
      if (object.index === -1) {
        const result = await window.COMMON.mutation(PostGRAPHQL.MUTATION_ADD_BACKGROUND_POSTS, params);
        if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_INSERT)) {
          getDataBackgroundPost();
		      createTrackingEvent(eventName.ADD_METADATA);
        }
      }
      window.COMMON.hideModal('#modal-add-background-post');
    } catch (error) {
      console.error("🚀 ~ addData ~ error:", error)
      window.UPLOAD_API.deleteMultiFile(dataFiles.map(item => item._id))      
      window.COMMON.showErrorLogs('BackgroundPostPage.addData');
    }
  };

  const saveData = async (event) => {
    let dataFiles = []
    try {
      if (!window.COMMON.checkFormValidation('form-background-post', event)) {
        return;
      }
      if (dataSave.file) {
        dataFiles = await window.uploadFile([dataSave.file]);
        if (dataFiles && dataFiles.length > 0) {
          dataSave.background = window.COMMON.getNewPathFile(dataFiles[0]._id);
        }
      }
      delete dataSave.file;
      const params = {
        input: dataSave
      };
      window.COMMON.trimData(params);
      const result = await window.COMMON.mutation(PostGRAPHQL.MUTATION_EDIT_BACKGROUND_POST, params);
      if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_UPDATE)) {
        getDataBackgroundPost();
		    createTrackingEvent(eventName.EDIT_METADATA);
      }
      window.COMMON.hideModal('#modal-background-post');
    } catch (error) {
      console.error("🚀 ~ saveData ~ error:", error)
      window.UPLOAD_API.deleteMultiFile(dataFiles.map(item => item._id))      
      window.COMMON.showErrorLogs('BackgroundPostPage.saveData');
    }
  };

  const deleteData = async () => {
    try {
      const params = {
        id: data[object.index]._id
      };
      const result = await window.COMMON.mutation(PostGRAPHQL.MUTATION_DEL_BACKGROUND_POST, params);
      if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_DELETE)) {
        getDataBackgroundPost();
        createTrackingEvent(eventName.DEL_METADATA);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('BackgroundPostPage.deleteData');
    }
  };



  if (typeof window === 'undefined') {
    return null;
  }
  return (
    <div className="container-row">
      <Panel id="container-data" icon="list-alt">
        <div className="container-top d-flex align-items-center justify-content-end wow fadeInDown animated" data-wow-delay="0.5s" style={{ zIndex: 1 }}>
          <div className="d-inline-block mr-auto">
            <RadioBox data={types} value={filter.radio} className="m-0" handleChange={handleRadio}></RadioBox>
          </div>
          <div style={{ width: '350px' }} className="mr-2">
            <MDBInputGroup type="text" value={filter.text} hint={window.I18N('search_name')} onKeyDown={handleSearchWithEnter} onChange={handleFilterChange} maxLength="100" pattern="\S(.*\S)?" required
              append={
                <span className="input-group-text cursor-pointer" onClick={() => doSearchData()}>
                  <MDBIcon fa="true" icon="search" className="fa-fw"></MDBIcon>
                </span>
              }
            />
          </div>
          <MDBBtn color="primary" className="mr-0" onClick={() => openAddDialog(-1)}>
            <MDBIcon fa="true" icon="plus-circle" className="fa-fw" /> {window.I18N('btn_add')}
          </MDBBtn>
        </div>
        <div className="container-table wow fadeIn animated" data-wow-delay="1s">
          <GridItemBackground data={data} handleEdit={openSaveDialog} handleDelete={openDeleteDialog}></GridItemBackground>
          <Pagination total={pagination.total} pageSize={pagination.pageSize} currentPage={pagination.currentPage} changePage={changePage}></Pagination>
        </div>
      </Panel>

      <Modal id="modal-add-background-post" title={window.I18N('create_update_data')} className="modal-xl" saveEvent={addData}>
        <form id="form-add-background-post" className="needs-validation" noValidate>
          <div className="text-center mt-3 mb-3">
            <InputFile id="files" icon="upload" handleFile={handleFiles} isMulti={true} accept="image/*" description={ `${window.I18N('image')} (360 x 240) pixels`}></InputFile>
          </div>
          <MDBRow>
            {
              window.COMMON.getValueFromAttr(dataSave, 'input', []).map((item, i) => <MDBCol key={i} lg="6" md="12" className="p-2">
                <div className="card border p-2 pl-3 pr-3" style={{ backgroundColor: '#fbfbfb' }}>
                  <div className="position-absolute top-0">
                    <MDBIcon fa="true" icon="trash-alt" style= {{ color: 'red' }} className="fa-fw cursor-pointer" onClick={() => deleteItem(i)} />
                  </div>
                  <div className="w-100 text-center mb-3">
                    <MDBCardImage zoom cascade waves src={window.COMMON.setValueImage(item.url)} style={{ height: '120px', width: '180px' }} className="mb-3"/>
                  </div>
                  <MDBInput outline containerClass="mt-0" type="text" label={window.I18N('name') + ' *'} name="name" value={item.name} onChange={(event) => handleChangeMulti(event, i)} maxLength="100" pattern="\S(.*\S)?" required>
                    <div className="invalid-feedback">{window.I18N('MSG_CODE_029')}</div>
                  </MDBInput>
                  <div className="w-100 d-flex align-items-center mb-3">
                    <MDBInput outline type="checkbox" id={'ck-gif-' + i} name="isGif" label={'GIF'} containerClass="p-0 mb-0 mt-2 mr-3" filled checked={item.isGif} onChange={(event) => handleChangeMulti(event, i)}></MDBInput>
                    <ColorPicker color={item.color} handleChange={(value) => handleColorMulti(value, i)}></ColorPicker>
                  </div>
                  <ListOption data={item.hashtag} isFullWidth={true} placeholder={window.I18N('input_hashtag')} handleReceiveData={(value) => receiveHashtagMulti(value, i)}></ListOption>
                </div>
              </MDBCol>
              )
            }
          </MDBRow>
        </form>
      </Modal>

      <Modal id="modal-background-post" title={window.I18N('create_update_data')} saveEvent={saveData}>
        <form id="form-background-post" className="needs-validation" noValidate>
          <MDBRow>
            <MDBCol>
              <div className="w-100 text-center mb-3">
                <MDBCardImage zoom cascade waves src={window.COMMON.setValueImage(dataSave.background)} style={{ height: '120px', width: '180px' }} className="mb-3 img-fluid"/>
              </div>
              <div className="text-center mb-3">
                <InputFile id="file" icon="upload" handleFile={handleFile} accept="image/*" description="Image (360 x 240) pixels"></InputFile>
              </div>
              <MDBInput outline containerClass="mt-0" type="text" label={window.I18N('name') + ' *'} name="name" value={dataSave.name} onChange={handleChange} maxLength="100" pattern="\S(.*\S)?" required>
                <div className="invalid-feedback">{window.I18N('MSG_CODE_029')}</div>
              </MDBInput>
              <div className="w-100 d-flex align-items-center mb-3">
                <MDBInput outline type="checkbox" id="ck-gif" name="isGif" label={'GIF'} containerClass="p-0 mb-0 mt-2 mr-3" filled checked={dataSave.isGif} onChange={handleChange}></MDBInput>
                <ColorPicker color={dataSave.color} handleChange={handleColor}></ColorPicker>
              </div>
              <ListOption data={dataSave.hashtag} isFullWidth={true} placeholder={window.I18N('input_hashtag')} handleReceiveData={receiveHashtag}></ListOption>
            </MDBCol>
          </MDBRow>
        </form>
      </Modal>
    </div>
  );
}