import gql from 'graphql-tag';

const QUERY_MENU = gql`
  query {
    getMenus {
      _id
      name
      nameWithMultiLang {
        en
        vi
      }
      code
      icon
      action
      index
      price
      parent {
        _id
        name
        nameWithMultiLang {
          en
          vi
        }
        code
        icon
      }
      rights {
        _id
        code
        name
      }
      createdDate
      createdBy {
        name
      }
    }
  }
`;

const MUTATION_ADD_MENU = gql`
  mutation($input: MenuInput!) {
    addMenu(input: $input)
  }
`;

const MUTATION_EDIT_MENU = gql`
  mutation($input: MenuInput!) {
    editMenu(input: $input)
  }
`;

const MUTATION_DEL_MENU = gql`
  mutation($id: String!) {
    deleteMenu(id: $id)
  }
`;

const QUERY_ROLE = gql`
  query ($company: String) {
    getRoles (company: $company) {
      _id
      name
      code
      description
      isPublic
      menus {
        menu {
          _id
          name
          nameWithMultiLang {
            en
            vi
          }
          code
          action
          icon
          parent {
            nameWithMultiLang {
              en
              vi
            }
            _id
          }
        }
        rights {
          _id
          code
          name
        }
      }
      createdDate
      createdBy {
        name
      }
    }
  }
`;

const QUERY_PACKAGE= gql`
  query($filter: PackageFilter){
    queryPackage(filter: $filter){
      _id
      name
      code
      storage
      description
      isPublic
      company
      menus {
        menu {
          _id
          name
          nameWithMultiLang {
            en
            vi
          }
          code
          action
          icon
          price
          parent {
            _id
            name
            nameWithMultiLang {
              en
              vi
            }
            code
            action
            icon
            index
          }
        }
        rights {
          _id
          code
          name
        }
      }
      role
      default
      createdDate
      createdBy{
        _id
      }
    }
  }
`


const QUERY_PUBLIC_ROLE = gql`
  query {
    getPublicRoles {
      _id
      name
      code
    }
  }
`;

const QUERY_ROLE_BY_CODE = gql`
  query($code: String!, $company: String) {
    findRoleByCode(code: $code, company: $company) {
      _id
      name
      code
      menus {
        menu {
          _id
          name 
          nameWithMultiLang {
            en
            vi
          }
          code
          action
          icon
          index
          parent {
            _id
            name
            nameWithMultiLang {
              en
              vi
            }
            code
            action
            icon
            index
          }
        }
        rights {
          _id
          code
          name
        }
      }
    }
  }
`;

const MUTATION_ADD_ROLE = gql`
  mutation($input: RoleInput!) {
    addRole(input: $input)
  }
`;

const MUTATION_EDIT_ROLE = gql`
  mutation($input: RoleInput!) {
    editRole(input: $input)
  }
`;

const MUTATION_DEL_ROLE = gql`
  mutation($id: String!) {
    deleteRole(id: $id)
  }
`;

const MUTATION_ADD_PACKAGE = gql`
  mutation($input: PackageInput!) {
    addPackage(input: $input)
  }
`;

const MUTATION_EDIT_PACKAGE = gql`
  mutation($input: PackageInput!){
    editPackage(input: $input)
}
`;

const MUTATION_DEL_PACKAGE = gql`
  mutation($id: String!) {
    deletePackage(id: $id){
        ... on DeletePackageSuccess {
            status
            message
        }
        ... on DeletePackageError {
            status
            message
            relatedCompanies {
                id
                name
            }
        }
    }
  }
  `;

const SET_DEFAULT_PACKAGE = gql`
  mutation($id: String!, $role: String!) {
    setDefaultPackage(id: $id, role: $role)
  }
`;

const QUERY_RIGHT = gql`
  query {
    getRights {
      _id
      name
      code
      description
      createdDate
      createdBy {
        name
      }
    }
  }
`;

const MUTATION_ADD_RIGHT = gql`
  mutation($input: RightInput!) {
    addRight(input: $input)
  }
`;

const MUTATION_EDIT_RIGHT = gql`
  mutation($input: RightInput!) {
    editRight(input: $input)
  }
`;

const MUTATION_DEL_RIGHT = gql`
  mutation($id: String!) {
    deleteRight(id: $id)
  }
`;

const RoleGRAPHQL = {
  QUERY_MENU,
  MUTATION_ADD_MENU,
  MUTATION_EDIT_MENU,
  MUTATION_DEL_MENU,
  QUERY_ROLE,
  QUERY_PUBLIC_ROLE,
  QUERY_ROLE_BY_CODE,
  MUTATION_ADD_ROLE,
  MUTATION_EDIT_ROLE,
  MUTATION_DEL_ROLE,
  QUERY_RIGHT,
  MUTATION_ADD_RIGHT,
  MUTATION_EDIT_RIGHT,
  MUTATION_DEL_RIGHT,
  QUERY_PACKAGE,
  MUTATION_ADD_PACKAGE,
  MUTATION_EDIT_PACKAGE,
  SET_DEFAULT_PACKAGE,
  MUTATION_DEL_PACKAGE
};

export default RoleGRAPHQL;