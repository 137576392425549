import React, { useState, useLayoutEffect } from 'react';
import { MDBCardImage, MDBInput, MDBBtn } from 'mdbreact';

const ListCheck = React.memo(function ListAccount(props) {
  const [checked, setChecked] = useState({});

  useLayoutEffect(() => {
    setChecked({});
  }, []);

  const handleItemClick = (event, index) => {
    event.stopPropagation();
    if (props.handleItemClick) {
      props.handleItemClick(index);
    }
  };

  const handleViewDetail = (event, index) => {
    event.stopPropagation();
    if (props.handleViewDetail) {
      props.handleViewDetail(index);
    }
  };

  const handleChange = (event, index, id) => {
    event.stopPropagation();
    checked[id] = !checked[id];
    setChecked({ ...checked });
    props.handleCheckChange(index, event.target.checked);
  };

  const html = props.data.map((item, i) => 
    <div className="list-group-item list-group-item-action" key={i} onClick={(event) => handleViewDetail(event, i)}>
      <div className="media">
        <MDBCardImage zoom cascade waves src={window.COMMON.setValueImage(window.COMMON.getValueFromAttr(item, props.attr_image))} className="input-image-icon rounded-circle mr-3"/>
        <div className="media-body media-middle">
          <h6><b>{window.COMMON.getValueFromAttr(item, props.attr_content)}</b></h6>
          <p className="mb-1" style={{ fontWeight: 'bold', color : 'black' }}>{window.COMMON.getValueFromAttr(item, props.attr_sub)}</p>
          <p className="mb-1">{window.COMMON.getValueFromAttr(item, props.attr_desc)}</p>
        </div>
        <div className="media-right d-flex flex-row justify-content-center align-items-center mt-3">
          <MDBBtn outline color="primary" className={props.statusButton ? '' : 'd-none'} onClick={(event) => handleItemClick(event, i)}>{window.I18N('view_details')}</MDBBtn>
          <MDBInput id={window.COMMON.getValueFromAttr(item, props.attr_id)} containerClass="mt-2" type="checkbox" checked={checked[item._id] || false} filled onChange={(event) => handleChange(event, i, item._id)}></MDBInput>
        </div>
      </div>
    </div>
  );

  return (
    <div className={'list-group ' + window.COMMON.getValue(props.className)}>
      {props.children}
      {html}
    </div>
  );
});

export default ListCheck;