import React, { useState, useCallback, useLayoutEffect } from 'react';
import { MDBRow, MDBCol, MDBInput, MDBIcon, MDBCardImage } from 'mdbreact';
import Select from 'react-select';
import FolderTree from 'react-folder-tree';

import Panel from '../../components/Panel';
import Modal from '../../components/Modal';
import InputFile from '../../components/InputFile';

import MetadataGRAPHQL from '../../../graphql/Metadata';
import eventName from '../../../common/events';
import { useTracking } from 'react-tracking';

export default function DocumentOrganizationPage(props) {
  const { trackEvent } = useTracking();
  const COMPANY_TYPE = localStorage.getItem(window.CONSTANT.COMPANY_TYPE);
  const COMPANY_ID = window.COMMON.checkValue(localStorage.getItem(window.CONSTANT.COMPANY_ID)) ? localStorage.getItem(window.CONSTANT.COMPANY_ID) : null;
  const [filter, setFilter] = useState({company: null});
  const [object, setObject] = useState({ index: -1 });
  const [data, setData] = useState({});
  const [dataSave, setDataSave] = useState({ isFile: false });
  const [file, setFile] = useState(null);
  const [options, setOptions] = useState({
    company: []
  });

  useLayoutEffect(() => {
    setTimeout(() => {
      getDataCompany();
    }, 100);
    // eslint-disable-next-line
  }, []);

  const createTrackingEvent = (event) => {
    return trackEvent({
      name: event,
      createdAt: new Date().toISOString()
    });
  };

  const FolderIcon = ({ onClick: defaultOnClick, nodeData }) => {
    const handleClick = () => {
      defaultOnClick();
    };
    return <MDBIcon fa="true" icon="folder" className="fa-solid cursor-pointer fa-lg" onClick={ handleClick } />;
  };

  const FolderOpenIcon = ({ onClick: defaultOnClick, nodeData }) => {
    const handleClick = () => {
      defaultOnClick();
    };
    return <MDBIcon fa="true" icon="folder-open" className="fa-solid cursor-pointer fa-lg" onClick={ handleClick } />;
  };

  const FileIcon = ({ onClick: defaultOnClick, nodeData }) => {
    const handleClick = () => {
      defaultOnClick();
    };
    return <MDBIcon fa="true" icon="file-alt" className="fa-solid cursor-pointer fa-lg" onClick={ handleClick } />;
  };

  const EditIcon = ({ onClick: defaultOnClick, nodeData }) => {
    const handleClick = () => {
      if (nodeData.id === null) {
        return;
      }
      openDocumentOrganizationDialog(0, nodeData, nodeData.isFile || false);
    };
    return <MDBIcon fa="true" icon="pen" className={`fa-solid cursor-pointer ml-3 ${nodeData.id === null ? 'disabled' : ''}`} onClick={ handleClick } />;
  };

  const DeleteIcon = ({ onClick: defaultOnClick, nodeData }) => {
    const handleClick = () => {
      if (nodeData.id === null) {
        return;
      }
      openDeleteDocumentOrganizationDialog(nodeData);
    };
    return <MDBIcon fa="true" icon="trash" className={`fa-solid cursor-pointer ml-3 ${nodeData.id === null ? 'disabled' : ''}`} onClick={ handleClick } />;
  };

  const CancelIcon = ({ onClick: defaultOnClick, nodeData }) => {
    const handleClick = () => {
      defaultOnClick();
    };
    return <MDBIcon fa="true" icon="times" className="fa-solid cursor-pointer ml-3" onClick={ handleClick } />;
  };

  const OKIcon = ({ onClick: defaultOnClick, nodeData }) => {
    const handleClick = () => {
      defaultOnClick();
    };
    return <MDBIcon fa="true" icon="check" className="fa-solid cursor-pointer ml-3" onClick={ handleClick } />;
  };

  const AddFileIcon = ({ onClick: defaultOnClick, nodeData }) => {
    const handleClick = () => {
      if (nodeData.id === null) {
        return;
      }
      openDocumentOrganizationDialog(-1, nodeData, true);
    };
    return <MDBIcon fa="true" icon="plus-circle" className={`fa-solid cursor-pointer ml-3 ${nodeData.id === null ? 'disabled' : ''}`} onClick={ handleClick } />;
  };

  const AddFolderIcon = ({ onClick: defaultOnClick, nodeData }) => {
    const handleClick = () => {
      openDocumentOrganizationDialog(-1, nodeData, false);
    };
    return <MDBIcon fa="true" icon="folder-plus" className="fa-solid cursor-pointer ml-3" onClick={ handleClick } />;
  };

  const handleFilterSelect = (event, attr) => {
    filter[attr] = event;
    if (attr === 'company') {
      getDataDocumentOrganization();
    }
  };

  const handleChange = (event) => {
    dataSave[event.target.name] = event.target.value;
    setDataSave({ ...dataSave });
  };

  const handleFile = (file) => {
    dataSave.name = file.name;
    setFile(file);
    setDataSave({ ...dataSave });
  };

  const onTreeStateChange = (state, event) => {
  };

  const getDataCompany = async () => {
    try {
      if (COMPANY_TYPE !== window.CONSTANT.ADMIN) {
        filter.company = { value: COMPANY_ID, label: '', code: COMPANY_TYPE };
        setFilter({ ...filter }); 
        getDataDocumentOrganization();
        return;
      }
      await window.COMMON.getDataAllCompany();
      const dataCompany = JSON.parse(localStorage.getItem(window.CONSTANT.DATA_ALL_COMPANY));
      if (dataCompany && dataCompany.length > 0) {
        options.company = window.COMMON.createDataSelectWithGroup(dataCompany, '_id', 'information.name', 'type', 'type');
        filter.company = window.COMMON.getValueFromAttr(options.company, '0.options.0', null);  
      }
      setOptions(options);
      setFilter({ ...filter });
      getDataDocumentOrganization();
    } catch (error) {
      window.COMMON.showErrorLogs('AccountPage.getDataCompany');
    }
  };

  const getDataDocumentOrganization = async () => {
    try {
      const params = {
        organization: window.COMMON.getDataSelect(filter.company, false)
      };
      const result = await window.COMMON.query(MetadataGRAPHQL.QUERY_ORGANIZATION_DRIVE, params);
      const data = {
        name: window.I18N('my_organization'),
        id: null,
        checked: 0,
        isOpen: true,
        parent: null,
        isFile: false,
        file: null,
        children: []
      };
      if (result && result.data) {
        const items = result.data.getAllOrganizationDrives;
        setDataTree(data, null, items);
      }
      setData({...data});
    } catch (error) {
      window.COMMON.showErrorLogs('DocumentOrganizationPage.getDataDocumentOrganization');
    }
  };

  const setDataTree = (item, parent, items) => {
    let arrs = items.filter(child => child.parent === parent);
    arrs = window.COMMON.sortData(arrs, 'isFile');
    arrs.forEach(element => {
      const obj = {};
      obj.id = window.COMMON.getValueIdFromAttr(element, '_id');
      obj.name = window.COMMON.getValueFromAttr(element, 'name');
      obj.parent = window.COMMON.getValueFromAttr(element, 'parent');
      obj.isFile = window.COMMON.getValueFromAttr(element, 'isFile', false);
      obj.dataFile = !obj.isFile ? null : {
        id: window.COMMON.getValueFromAttr(element, 'dataFile.id'),
        name: window.COMMON.getValueFromAttr(element, 'dataFile.name'),
        size: window.COMMON.getValueFromAttr(element, 'dataFile.size'),
        type: window.COMMON.getValueFromAttr(element, 'dataFile.type'),
        ext: window.COMMON.getValueFromAttr(element, 'dataFile.ext')
      };
      obj.checked = 0;
      obj.isOpen = false;
      if (!element.isFile) {
        obj.children = [];
        setDataTree(obj, obj.id, items);
      }
      item.children.push(obj);
    });
  };

  const openDocumentOrganizationDialog = useCallback((index, node, isFile) => {
    window.COMMON.showModal('#modal-organization-drive');
    object.index = index;
    const dataSave = { 
      organization: window.COMMON.getDataSelect(filter.company, false),
      isFile: isFile || false
    };
    if (object.index === -1) {
      dataSave.name = '';
      dataSave.parent = window.COMMON.getValueFromAttr(node, 'id', null);
    } else {
      dataSave._id = window.COMMON.getValueFromAttr(node, 'id', null);
      dataSave.name = window.COMMON.getValueFromAttr(node, 'name');
      dataSave.parent = window.COMMON.getValueFromAttr(node, 'parent', null);
      dataSave.dataFile = window.COMMON.getValueFromAttr(node, 'dataFile', null);
    }
    setObject(object);
    setFile(null);
    setDataSave({ ...dataSave });
  }, [object, data]);

  const openDeleteDocumentOrganizationDialog = useCallback((node) => {
    object.id = window.COMMON.getValueFromAttr(node, 'id', null);
    setObject(object);
    window.COMMON.showModal('#modal-delete');
    window.deleteMethod = deleteDocumentOrganization;
    // eslint-disable-next-line
  }, [object]);

  const saveDocumentOrganization = async (event) => {
    let dataFileAfterUpload = []
    try {
      const checkForm = window.COMMON.checkFormValidation('form-organization-drive', event);
      if (!checkForm || (dataSave.isFile && !dataSave.dataFile && !file)) {
        window.COMMON.showMessage('warning', 'MSG_CODE_042', window.MSG.MSG_CODE_042);
        return;
      }
      if (!window.COMMON.checkFormValidation('form-organization-drive', event)) {
        return;
      }
      if (file) {
        let dataFile = null;
        const dataFiles = await window.uploadFile([file]);
        dataFileAfterUpload = dataFiles;
        if (dataFiles && dataFiles.length > 0) {
          dataFile = {
            id: dataFiles[0]._id,
            name: dataFiles[0].name,
            size: dataFiles[0].size,
            type: dataFiles[0].mime,
            ext: dataFiles[0].ext
          };
        }
        if (!dataFile) {
          return;
        }
        dataSave.dataFile = dataFile;
      }
      const params = {
        input: dataSave
      };
      window.COMMON.trimData(params);
      if (object.index === -1) {
        const result = await window.COMMON.mutation(MetadataGRAPHQL.MUTATION_ADD_ORGANIZATION_DRIVE, params);
        if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_INSERT)) {
          getDataDocumentOrganization();
		      createTrackingEvent(eventName.ADD_ORGANIZATION_DRIVE);
        }
      } else {
        const result = await window.COMMON.mutation(MetadataGRAPHQL.MUTATION_EDIT_ORGANIZATION_DRIVE, params);
        if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_UPDATE)) {
          getDataDocumentOrganization();
		      createTrackingEvent(eventName.EDIT_ORGANIZATION_DRIVE);
        }
      }
      window.COMMON.hideModal('#modal-organization-drive');
    } catch (error) {
      console.error("🚀 ~ saveDocumentOrganization ~ error:", error)
      window.UPLOAD_API.deleteMultiFile(dataFileAfterUpload.map(item => item._id))      
      window.COMMON.showErrorLogs('DocumentOrganizationPage.saveDocumentOrganization');
    }
  };

  const deleteDocumentOrganization = async () => {
    try {
      const params = {
        id: object.id
      };
      const result = await window.COMMON.mutation(MetadataGRAPHQL.MUTATION_DEL_ORGANIZATION_DRIVE, params);
      if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_DELETE)) {
        getDataDocumentOrganization();
        createTrackingEvent(eventName.DEL_ORGANIZATION_DRIVE);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('DocumentOrganizationPage.deleteDocumentOrganization');
    }
  };

  if (typeof window === 'undefined') {
    return null;
  }
  return (
    <div className="container-row">
      <Panel id="container-data" icon="list-alt">
        {
          COMPANY_TYPE !== window.CONSTANT.ADMIN ? <div></div> : <div className="container-top d-flex align-items-center justify-content-end wow fadeInDown animated" data-wow-delay="0.5s">
            <div className="bd-highlight" style={{ width: '300px' }}>
              <div className="input-group input-group-transparent">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <MDBIcon fa="true" icon="building" className="fa-fw" />
                  </span>
                </div>
                <Select className="md-form m-0" placeholder={window.I18N('choose_option')} value={filter.company} options={options.company} onChange={(event) => handleFilterSelect(event, 'company')} isSearchable />
              </div>
            </div>
          </div>
        }
        <div className="container-table wow fadeIn animated" data-wow-delay="1s">
          <FolderTree
            initOpenStatus='custom'
            data={ data }
            onChange={ onTreeStateChange }
            iconComponents={ {FolderIcon, FolderOpenIcon, FileIcon, AddFolderIcon, AddFileIcon, EditIcon, DeleteIcon, OKIcon, CancelIcon} }
          />
        </div>
      </Panel>

      <Modal id="modal-organization-drive" title={window.I18N('create_update_data')} saveEvent={saveDocumentOrganization}>
        <form id="form-organization-drive" className="needs-validation" noValidate>
          <MDBRow>
            <MDBCol>
              <MDBInput outline containerClass="mt-0" value={dataSave.name} name="name" onChange={handleChange} type="text" label={window.I18N('name') + ' *'} maxLength="100" pattern="\S(.*\S)?" required>
                <div className="invalid-feedback">{window.I18N('MSG_CODE_029')}</div>
              </MDBInput>
              {
                dataSave.isFile && !dataSave.dataFile ? <div className="text-center">
                  {
                    file ? <div className="d-flex align-items-center">
                      <MDBCardImage zoom cascade waves src={window.COMMON.getFileType(file.name.split('.').pop())}  className="image-icon mr-2"/>
                      <small className="text-muted">{dataSave.name}</small>
                      <small className="ml-auto">{window.COMMON.bytesToSize(file.size)}</small>
                    </div> : <div></div>
                  }
                  <InputFile id="file" icon="upload" handleFile={handleFile}></InputFile>
                </div> : <div></div>
              }
              {
                dataSave.isFile && dataSave && dataSave.dataFile ? <div className="d-flex align-items-center">
                  <MDBCardImage zoom cascade waves src={window.COMMON.getFileType(dataSave.dataFile.ext)}  className="image-icon mr-2"/>
                  <small className="text-muted">{dataSave.name}</small>
                  <small className="ml-auto">{window.COMMON.bytesToSize(dataSave.dataFile.size)}</small>
                  {
                    file != null ? <></> : <MDBIcon fa="true" icon="download" className="fa-solid cursor-pointer ml-2" onClick={() => window.COMMON.downloadFile(dataSave.dataFile.id, dataSave.name)}></MDBIcon>
                  }
                </div> : <div></div>
              }
            </MDBCol>
          </MDBRow>
        </form>
      </Modal>
    </div>
  );
}