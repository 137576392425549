import gql from 'graphql-tag';

const QUERY_COMPANY = gql`
  query ($type: String) {
    getCompanies (type: $type) {
      _id
      type
      information {
        _id
        avatar
        email
        name
        nickname
        companyType {
          _id
          value
        }
        companySize {
          _id
          value
        }
        companyIndustry {
          _id
          code
          value
        }
        nation {
          _id
          code
          name
        }
        city {
          _id
          code
          name
        }
        state {
          _id
          code
          name
        }
        phone
        address
        bio
        taxCode
        organizationCode
        zipcode
        website
        representative
        representativeEmail
        representativePhone
        representativePosition
        package {
          _id,
          storage
        }
      }
      rating {
        star
      }
      gopy
      subQuestion1
      subQuestion2
      subQuestion3
      createdDate
    }
  }
`;

const QUERY_COMPANY_BY_PACKAGE = gql`
  query ($package: String!) {
    getCompanyByPackage (package: $package) {
      _id
      type
      information {
        _id
        avatar
        email
        name
        nickname
        companyType {
          _id
          value
        }
        companySize {
          _id
          value
        }
        companyIndustry {
          _id
          code
          value
        }
        nation {
          _id
          code
          name
        }
        city {
          _id
          code
          name
        }
        state {
          _id
          code
          name
        }
        phone
        address
        bio
        taxCode
        organizationCode
        zipcode
        website
        representative
        representativeEmail
        representativePhone
        representativePosition
        package {
          _id,
          storage
        }
      }
      rating {
        star
      }
      gopy
      subQuestion1
      subQuestion2
      subQuestion3
      createdDate
    }
  }
`;


const QUERY_COMPANY_AND_REALTIONSHIP = gql`
  query ($influencer: String!, $accountType: String!, $status: String, $text: String, $page: Int, $pageSize: Int) {
    getCompaniesAndRelationshipInfluencer (influencer: $influencer, accountType: $accountType, status: $status, text: $text, page: $page, pageSize: $pageSize) {
      total
      data {
        _id
        avatar
        email
        name
        nickname
        phone
        address
        company {
          _id
        }
        union {
          _id
        }
        createdDate
      }
    }
  }
`;

const QUERY_COMPANY_BY_IDS = gql`
  query ($companyIds: [String!]) {
    getCompaniesByIds (companyIds: $companyIds) {
      _id
      type
      information {
        _id
        avatar
        email
        name
        nickname
        companyType {
          _id
          value
        }
        companySize {
          _id
          value
        }
        companyIndustry {
          _id
          code
          value
        }
        nation {
          _id
          code
          name
        }
        city {
          _id
          code
          name
        }
        state {
          _id
          code
          name
        }
        phone
        address
        bio
        taxCode
        organizationCode
        zipcode
        website
        representative
        representativeEmail
        representativePhone
        representativePosition
      }
      rating {
        star
      }
      createdDate
    }
  }
`;

const QUERY_COMPANY_BY_ID = gql`
  query ($companyId: String!) {
    getCompaniesById (companyId: $companyId) {
      _id
      type
      information {
        _id
      }
      gopy
      subQuestion1
      subQuestion2
      subQuestion3
      createdDate
    }
  }
`;


const QUERY_BUSINESS_AWARD_COMPANY = gql`
  query($company: String!) {
    getBusinessAwardByCompany(company: $company) {
      _id
      company {
        _id
      }
      name
      image
      organization
      description
      receivedDate
      createdDate
    }
  }
`;

const QUERY_RELATION_COMPANY = gql`
  query($company: String!, $type: String!, $status: [String!]) {
    findRelationshipCompany(company: $company, type: $type, status: $status) {
      _id
      user {
        _id
        avatar
        email
        phone
        name
        nickname
        birthday
        gender
        address
      }
      type
      status
      createdDate
    }
  }
`;

const QUERY_RELATION_ORGANIZATION = gql`
  query($organization: String!) {
    findRelationshipOrganization(organization: $organization) {
      _id
      user {
        _id
        avatar
        email
        phone
        name
        nickname
        birthday
        gender
        address
      }
      type
      status
      createdDate
    }
  }
`;

const QUERY_RELATION_COMPANY_BY_ACCOUNT = gql`
  query($user: String!, $type: String!, $status: [String!]) {
    findRelationshipCompanyByAccount(user: $user, type: $type, status: $status) {
      _id
      company {
        _id
        type
        information {
          avatar
          name
        }
      }
      type
      status
      createdDate
    }
  }
`;

const QUERY_RELATION_INFLUENCER = gql`
  query ($influencer: String) {
    findRelationshipInfluencer (influencer: $influencer) {
      _id
      company {
        _id
      }
      user {
        _id
      }
      gopy
      subQuestion1
      subQuestion2
      subQuestion3
      subAnswer1
      subAnswer2
      subAnswer3
      type
      status
      createdDate
    }
  }
`;

const QUERY_EMPLOYEE_COMPANY = gql`
  query($company: String!) {
    findEmployeesInCompany(company: $company) {
      _id
      user {
        avatar
        nickname
        bio
        birthday
        gender
      }
      type
      status
      updatedDate
    }
  }
`;

const MUTATION_ACCEPT_COMPANY_RELATIONSHIP = gql`
  mutation($input: RequestJoinInput!) {
    acceptRequestJoinCompany(input: $input)
  }
`;

const MUTATION_DECLINE_COMPANY_RELATIONSHIP = gql`
  mutation($input: RequestJoinInput!) {
    declineRequestJoinCompany(input: $input)
  }
`;

const MUTATION_ACCEPT_COMPANY_RELATIONSHIP_FOR_INFLUENCER = gql`
  mutation($input: [RequestJoinInput!]) {
    acceptRequestJoinCompanyByInfluencer(input: $input)
  }
`;

const MUTATION_DECLINE_COMPANY_RELATIONSHIP_FOR_INFLUENCER = gql`
  mutation($input: [RequestJoinInput!]) {
    declineRequestJoinCompanyByInfluencer(input: $input)
  }
`;

const MUTATION_ACCEPT_INFLUENCER_RELATIONSHIP_FOR_COMPANY = gql`
  mutation($influencer: String!, $company: String!, $companyUser: String!) {
    acceptRequestJoinInfluencerByInCompany(influencer: $influencer, company: $company, companyUser: $companyUser)
  }
`;

const MUTATION_ADD_BUSINESS_AWARD = gql`
  mutation ($input: BusinessAwardInput!) {
    addBusinessAward(input: $input)
  }
`;

const MUTATION_EDIT_BUSINESS_AWARD = gql`
  mutation ($input: BusinessAwardInput!) {
    editBusinessAward(input: $input)
  }
`;

const MUTATION_DEL_BUSINESS_AWARD = gql`
  mutation ($id: String!) {
    deleteBusinessAward(id: $id)
  }
`;

const MUTATION_UPDATE_GOPY_AND_SUB_QUESTION = gql`
  mutation ($input: CompanyGopyAndSubQuestionInput!) {
    updateGopyAndSubQuestion(input: $input)
  }
`;

const CompanyGRAPHQL = {
  QUERY_COMPANY,
  QUERY_COMPANY_AND_REALTIONSHIP,
  QUERY_COMPANY_BY_IDS,
  QUERY_COMPANY_BY_ID,
  QUERY_BUSINESS_AWARD_COMPANY,
  QUERY_RELATION_COMPANY,
  QUERY_EMPLOYEE_COMPANY,
  QUERY_RELATION_COMPANY_BY_ACCOUNT,
  QUERY_RELATION_INFLUENCER,
  QUERY_COMPANY_BY_PACKAGE,
  QUERY_RELATION_ORGANIZATION,
  MUTATION_ACCEPT_COMPANY_RELATIONSHIP,
  MUTATION_DECLINE_COMPANY_RELATIONSHIP,
  MUTATION_ACCEPT_COMPANY_RELATIONSHIP_FOR_INFLUENCER,
  MUTATION_DECLINE_COMPANY_RELATIONSHIP_FOR_INFLUENCER,
  MUTATION_ACCEPT_INFLUENCER_RELATIONSHIP_FOR_COMPANY,
  MUTATION_ADD_BUSINESS_AWARD,
  MUTATION_EDIT_BUSINESS_AWARD,
  MUTATION_DEL_BUSINESS_AWARD,
  MUTATION_UPDATE_GOPY_AND_SUB_QUESTION
};

export default CompanyGRAPHQL;
