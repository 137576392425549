import React, { useState, useCallback, useLayoutEffect, useRef } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { MDBRow, MDBCol, MDBCardImage, MDBInput, MDBBtn, MDBIcon } from 'mdbreact';

import Table from '../../components/Table';
import TableTree from '../../components/TableTree';
import Tab from '../../components/Tab';
import Modal from '../../components/Modal';
import InputFile from '../../components/InputFile';
import ImportExcel from '../../components/ImportExcel';
import ExportExcel from '../../components/ExportExcel';
import RadioBox from '../../components/RadioBox';

import LearningGRAPHQL from '../../../graphql/Learning';
import eventName from '../../../common/events';
import { useTracking } from 'react-tracking';

function LearningPage(props) {
  const { trackEvent } = useTracking();
  const COMPANY_TYPE = localStorage.getItem(window.CONSTANT.COMPANY_TYPE);
  const COMPANY_ID = localStorage.getItem(window.CONSTANT.COMPANY_ID);

  const dataTabs = [
    { icon: 'fa-th', name: window.I18N('learning') },
    { icon: 'fa-th', name: window.I18N('menu_learning') },
    { icon: 'fa-th', name: window.I18N('survey') }
  ];
  const types = [
    { value: 'NORMAL', label: window.I18N('normal'), icon: 'text-width' },
    { value: 'SURVEY', label: window.I18N('survey'), icon: 'question-circle' }
  ];
  const menuTypes = [
    { value: 'NORMAL', label: window.I18N('normal'), icon: 'text-width' },
    { value: 'PDF', label: 'PDF', icon: 'file-pdf' },
    { value: 'WEBSITE', label: window.I18N('website'), icon: 'blog' },
    { value: 'IMAGE', label: window.I18N('image'), icon: 'image' },
    { value: 'SURVEY', label: window.I18N('survey'), icon: 'question-circle' }
  ];
  const config = [
    window.COMMON.createCol(window.I18N('no'), '5%', '', '', '', 'INDEX'),
    window.COMMON.createCol(window.I18N('icon'), '10%', 'p-0 pl-1', '', 'icon', 'IMAGE'),
    window.COMMON.createCol(window.I18N('code'), '10%', '', '', 'code', 'TEXT'),
    window.COMMON.createCol(window.I18N('name'), '45%', '', '', 'nameLanguage', 'TEXT'),
    window.COMMON.createCol(window.I18N('type'), '10%', '', '', 'type', 'TEXT'),
    window.COMMON.createCol(window.I18N('created_date'), '10%', '', '', 'createdDate', 'TEXT'),
    window.COMMON.createCol(window.I18N('action'), '10%', '', '', '3', 'BUTTON')
  ];
  const configMenu = [
    window.COMMON.createCol(window.I18N('name'), '30%', '', '', 'nameLanguage', 'COLLAPSE'),
    window.COMMON.createCol(window.I18N('icon'), '10%', 'p-0 pl-1', '', 'icon', 'IMAGE'),
    window.COMMON.createCol(window.I18N('code'), '15%', '', '', 'code', 'TEXT'),
    window.COMMON.createCol(window.I18N('index'), '10%', '', '', 'index', 'TEXT'),
    window.COMMON.createCol(window.I18N('type'), '15%', '', '', 'type', 'TEXT'),
    window.COMMON.createCol(window.I18N('created_date'), '10%', '', '', 'createdDate', 'TEXT'),
    window.COMMON.createCol(window.I18N('action'), '10%', '', '', '3', 'BUTTON')
  ];
  const configQuestion = [
    window.COMMON.createCol(window.I18N('no'), '5%', '', '', '', 'INDEX'),
    window.COMMON.createCol(window.I18N('code'), '10%', '', '', 'code', 'TEXT'),
    window.COMMON.createCol(window.I18N('question'), '35%', '', '', 'questionLanguage', 'TEXT'),
    window.COMMON.createCol(window.I18N('answer'), '25%', '', '', 'answerLanguage', 'TEXT'),
    window.COMMON.createCol(window.I18N('correct_answer'), '10%', '', '', 'correctAnswer', 'TEXT'),
    window.COMMON.createCol(window.I18N('created_date'), '10%', '', '', 'createdDate', 'TEXT'),
    window.COMMON.createCol(window.I18N('action'), '5%', '', '', '-1', 'BUTTON')
  ];
  const language = useRef();
  const [filter, setFilter] = useState({ company: null, learning: null, menu: null });
  const [object, setObject] = useState({ index: -1, indexTab: 0, status: {} });
  const [data, setData] = useState([]);
  const [dataMenu, setDataMenu] = useState([]);
  const [dataQuestion, setDataQuestion] = useState([]);
  const [dataImport, setDataImport] = useState({});
  const [dataSave, setDataSave] = useState({});
  const [options, setOptions] = useState({
    company: [],
    category: []
  });

  useLayoutEffect(() => {
    if (!props.language.code) {
      return;
    }
    language.current = props.language.code;
    setTimeout(() => {
      getDataCompany();
    }, 100);
    // eslint-disable-next-line
  }, [props.language]);

  const createTrackingEvent = (event) => {
    return trackEvent({
      name: event,
      createdAt: new Date().toISOString()
    });
  };

  const getDataInTab = () => {
    if (object.indexTab === 0) {
      setTimeout(() => getDataLearning(), 500);
    } else if (object.indexTab === 1) {
      setTimeout(() => getDataMenu(), 500);
    } else {
      setTimeout(() => getDataQuestion(), 500);
    }
  }

  const changeTab = useCallback((index) => {
    if (index === 0) {
      getDataLearning()
    } else if (index === 1) {
      getDataMenu()
    } else {
      getDataQuestion()
    }
    object.indexTab = index;
    setObject(object);
  },[filter])

  const handleFilterSelect = (event, attr) => {
    filter[attr] = event;
    setFilter({ ...filter });
    getDataInTab();
  };

  const handleChange = (event) => {
    if (event.target.className.indexOf('input-number') > -1) {
      const parseValueToNumber = parseInt(event.target.value)
      if(parseValueToNumber > window.CONSTANT.MAX_INPUT_NUMBER){
        return
      }
      dataSave[event.target.name] = parseValueToNumber
    } else {
      dataSave[event.target.name] = event.target.value;
    }
    setDataSave({ ...dataSave });
  };

  const handleChangeWithLanguage = (event) => {
    window.COMMON.setValueWithLanguage(dataSave, event.target.name, language.current, event.target.value);
    setDataSave({ ...dataSave });
  };

  const handleRadio = useCallback((attr, value) => {
    dataSave[attr] = value;
    // if (value === 'SURVEY') {
    //   dataSave.content = [];
    // }
    setDataSave({ ...dataSave });
  }, [dataSave]);

  const handleFile = (file) => {
    dataSave.file = file;
    dataSave.icon = window.COMMON.createBlobImage(file);
    setDataSave({ ...dataSave });
  };

  const handleFileImport = (dataImport) => {
    checkImportData(dataImport);
    window.COMMON.showModal('#modal-import');
  };

  const getDataCompany = async () => {
    try {
      if (COMPANY_TYPE !== window.CONSTANT.ADMIN) {
        filter.company = { value: COMPANY_ID, label: '', code: COMPANY_TYPE };
        setFilter({ ...filter }); 
        getDataInTab();
        return;
      }
      await window.COMMON.getDataAllCompany();
      const dataCompany = JSON.parse(localStorage.getItem(window.CONSTANT.DATA_ALL_COMPANY));
      if (dataCompany && dataCompany.length > 0) {
        options.company = window.COMMON.createDataSelectWithGroup(dataCompany, '_id', 'information.name', 'type', 'type');
        options.company.unshift({ value: null, label: window.I18N('my_organization'), code: COMPANY_TYPE });
        filter.company = window.COMMON.getValueFromAttr(options.company, '0', null);  
      }
      setOptions(options);
      setFilter({ ...filter });
      getDataInTab();
    } catch (error) {
      window.COMMON.showErrorLogs('LearningPage.getDataCompany');
    }
  };

  // LEARNING
  const getDataLearning = async () => {
    try {
      window.resetDataTable('table-data');
      const params = {
        company: filter.company.value
      }
      const result = await window.COMMON.query(LearningGRAPHQL.QUERY_LEARNING, params);
      if (result && result.data) {
        const data = window.COMMON.formatDate(result.data.getLearnings, 'createdDate');
        data.forEach(item => {
          item.nameLanguage = window.COMMON.getValueWithLanguage(item, 'name', language.current);
        });
        const arrs = window.COMMON.getArrsInArrs('SURVEY', data, 'type');
        options.learning = window.COMMON.createDataSelectWithLanguage(arrs, '_id', 'name', language.current, false);
        filter.learning = options.learning && options.learning.length > 0 ? options.learning[0] : null;
        setFilter(filter);
        setOptions(options);
        setData(data);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('LearningPage.getDataLearning');
    }
    window.initDataTable('table-data');
  };

  const openLearningDialog = useCallback((index) => {
    window.COMMON.showModal('#modal-learning');
    object.index = index;
    const dataSave = { file: null, company: filter.company.value };
    if (index === -1) {
      dataSave.name = [];
      dataSave.content = [];
      dataSave.description = [];
      dataSave.code = window.COMMON.generateCode('LE');
      dataSave.type = 'NORMAL';
      dataSave.icon = '';
    } else {
      const obj = data[index];
      dataSave._id = window.COMMON.getValueFromAttr(obj, '_id', null);
      dataSave.name = window.COMMON.getDataWithLanguage(obj, 'name');
      dataSave.content = window.COMMON.getDataWithLanguage(obj, 'content');
      dataSave.description = window.COMMON.getDataWithLanguage(obj, 'description');
      dataSave.code = window.COMMON.getValueFromAttr(obj, 'code');
      dataSave.type = window.COMMON.getValueFromAttr(obj, 'type');
      dataSave.icon = window.COMMON.getValueFromAttr(obj, 'icon');
    }
    document.getElementById('file-learning').value = null;
    setObject(object);
    setDataSave({ ...dataSave });
  }, [data, object]);

  // eslint-disable-next-line
  const openDeleteLearningDialog = useCallback((index) => {
    object.index = index;
    setObject(object);
    window.COMMON.showModal('#modal-delete');
    window.deleteMethod = deleteLearning;
  });

  const saveLearning = async (event) => {
    let dataFiles = [];
    try {
      if (!window.COMMON.checkFormValidation('form-learning', event)) {
        return;
      }
      // TODO upload file
      if (dataSave.file) {
        dataFiles = await window.uploadFile([dataSave.file]);
        if (dataFiles.length > 0) {
          dataSave.icon = window.COMMON.getNewPathFile(dataFiles[0]._id);
        }
      }
      dataSave.file = null;
      // TODO save data
      const params = {
        input: {
          ...dataSave,
          content: dataSave.type === 'SURVEY' ? [] : dataSave.content
        }
      };
      window.COMMON.trimData(params);
      if (object.index === -1) {
        const result = await window.COMMON.mutation(LearningGRAPHQL.MUTATION_ADD_LEARNING, params);
        if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_INSERT)) {
          getDataLearning();
		      createTrackingEvent(eventName.ADD_LEARNING);
        }
      } else {
        const result = await window.COMMON.mutation(LearningGRAPHQL.MUTATION_EDIT_LEARNING, params);
        if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_UPDATE)) {
          getDataLearning();
		      createTrackingEvent(eventName.EDIT_LEARNING);
        }
      }
      window.COMMON.hideModal('#modal-learning');
    } catch (error) {
      console.error("🚀 ~ saveLearning ~ error:", error)
      window.UPLOAD_API.deleteMultiFile(dataFiles.map(item => item._id))      
      window.COMMON.showErrorLogs('LearningPage.saveLearning');
    }
  };

  const deleteLearning = async () => {
    try {
      const params = {
        id: data[object.index]._id
      };
      const result = await window.COMMON.mutation(LearningGRAPHQL.MUTATION_DEL_LEARNING, params);
      if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_DELETE)) {
        getDataLearning();
        createTrackingEvent(eventName.DEL_LEARNING);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('LearningPage.deleteLearning');
    }
  };

  // MENU LEARNING
  const getDataMenu = async () => {
    try {
      object.status = {};
      setObject({ ...object });
      window.resetDataTableTree('table-menu');
      if (filter.learning && filter.learning.value) {
        const params = {
          parentId: filter.learning.value
        };
        const result = await window.COMMON.query(LearningGRAPHQL.QUERY_MENU_LEARNING, params);
        if (result && result.data) {
          const data = window.COMMON.formatDate(result.data.getMenuLearnings, 'createdDate');
          for (let i = 0; i < data.length; i++) {
            data[i].idx = i;
            data[i].nameLanguage = window.COMMON.getValueWithLanguage(data[i], 'name', language.current);
          }      
          const arrs = window.COMMON.getArrsInArrs('SURVEY', data, 'type');
          options.menu = window.COMMON.createDataSelectWithLanguage(arrs, '_id', 'name', language.current, true);
          window.COMMON.createDataTableTree(data, data, 'parent._id', 1);
          setFilter((prev) => ({...prev, menu: options.menu && options.menu.length > 0 ? options.menu[0] : null}));
          setOptions(options);
          setDataMenu([ ...data ]);
          window.initDataTableTree('table-menu');
        }
      } else {
        options.menu = [];
        setOptions(options);
        setDataMenu([]);
        window.initDataTableTree('table-menu');
      }
    } catch (error) {
      window.COMMON.showErrorLogs('LearningPage.getDataMenu');
    }
  };

  const openAddMenuDialog = useCallback((index) => {
    if (!filter.learning || !filter.learning.value) {
      return;
    }
    window.COMMON.showModal('#modal-menu');
    let parent = null;
    if (index !== -1) {
      parent = window.COMMON.getValueFromAttr(dataMenu[index], '_id', null);
    }
    object.index = -1;
    const dataSave = { file: null, company: filter.company.value };
    dataSave.name = [];
    dataSave.content = [];
    dataSave.code = window.COMMON.generateCode('ML');
    dataSave.icon = '';
    dataSave.type = 'NORMAL';
    dataSave.index = 0;
    dataSave.parent = parent;
    dataSave.learning = filter.learning.value;
    document.getElementById('file-menu').value = null;
    setObject(object);
    setDataSave({ ...dataSave });
  }, [object, filter, dataMenu]);

  const openEditMenuDialog = useCallback((index) => {
    window.COMMON.showModal('#modal-menu');
    object.index = index;
    const dataSave = { file: null, company: filter.company.value };
    const obj = dataMenu[index];
    dataSave._id = window.COMMON.getValueFromAttr(obj, '_id', null);
    dataSave.name = window.COMMON.getDataWithLanguage(obj, 'name');
    dataSave.content = window.COMMON.getDataWithLanguage(obj, 'content');
    dataSave.code = window.COMMON.getValueFromAttr(obj, 'code');
    dataSave.index = window.COMMON.getValueFromAttr(obj, 'index');
    dataSave.type = window.COMMON.getValueFromAttr(obj, 'type');
    dataSave.icon = window.COMMON.getValueFromAttr(obj, 'icon');
    dataSave.parent = window.COMMON.getValueFromAttr(obj, 'parent._id', null);
    dataSave.learning = filter.learning.value;
    document.getElementById('file-menu').value = null;
    setObject(object);
    setDataSave({ ...dataSave });
  }, [object, filter, dataMenu]);

  // eslint-disable-next-line
  const openDeleteMenuDialog = useCallback((index) => {
    object.index = index;
    setObject(object);
    window.COMMON.showModal('#modal-delete');
    window.deleteMethod = deleteMenu;
  });

  const saveMenu = async (event) => {
    let dataFiles = [];
    try {
      if (!window.COMMON.checkFormValidation('form-menu', event)) {
        return;
      }
      // TODO upload file
      if (dataSave.file) {
        dataFiles = await window.uploadFile([dataSave.file]);
        if (dataFiles.length > 0) {
          dataSave.icon = window.COMMON.getNewPathFile(dataFiles[0]._id);
        }
      }
      dataSave.file = null;
      // TODO save data
      const params = {
        input: dataSave
      };
      window.COMMON.trimData(params);
      if (object.index === -1) {
        const result = await window.COMMON.mutation(LearningGRAPHQL.MUTATION_ADD_MENU_LEARNING, params);
        if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_INSERT)) {
          getDataMenu();
		      createTrackingEvent(eventName.ADD_MENU_LEARNING);
        }
      } else {
        const result = await window.COMMON.mutation(LearningGRAPHQL.MUTATION_EDIT_MENU_LEARNING, params);
        if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_UPDATE)) {
          getDataMenu();
		      createTrackingEvent(eventName.EDIT_MENU_LEARNING);
        }
      }
      window.COMMON.hideModal('#modal-menu');
    } catch (error) {
      console.error("🚀 ~ saveMenu ~ error:", error)
      window.UPLOAD_API.deleteMultiFile(dataFiles.map(item => item._id))      
      window.COMMON.showErrorLogs('LearningPage.saveMenu');
    }
  };

  const deleteMenu = async () => {
    try {
      const params = {
        id: dataMenu[object.index]._id
      };
      const result = await window.COMMON.mutation(LearningGRAPHQL.MUTATION_DEL_MENU_LEARNING, params);
      if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_DELETE)) {
        getDataMenu();
        createTrackingEvent(eventName.DEL_MENU_LEARNING);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('LearningPage.deleteMenu');
    }
  };

  // QUESTION LEARNING
  const getDataQuestion = async () => {
    try {
      window.resetDataTable('table-question');
      if (window.COMMON.checkValue(filter.menu)) {
        const params = {
          parentId: filter.menu.value
        };
        const result = await window.COMMON.query(LearningGRAPHQL.QUERY_QUESTION_LEARNING, params);
        let data = [];
        if (result && result.data) {
          data = window.COMMON.formatDate(result.data.getQuestionLearnings, 'createdDate');
          data.forEach(item => {
            item.questionLanguage = window.COMMON.getValueWithLanguage(item, 'question', language.current);
            item.answerLanguage = window.COMMON.getValueWithLanguage(item, 'answer', language.current);
          });
        }
        setDataQuestion(data);
      } else {
        setDataQuestion([]);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('LearningPage.getDataQuestion');
    }
    window.initDataTable('table-question');
  };

  // eslint-disable-next-line
  const openDeleteQuestionDialog = useCallback((index) => {
    object.index = index;
    setObject(object);
    window.COMMON.showModal('#modal-delete');
    window.deleteMethod = deleteQuestion;
  });

  const deleteQuestion = async () => {
    try {
      const params = {
        id: dataQuestion[object.index]._id
      };
      const result = await window.COMMON.mutation(LearningGRAPHQL.MUTATION_DEL_QUESTION_LEARNING, params);
      if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_DELETE)) {
        getDataQuestion();
        createTrackingEvent(eventName.DEL_QUESTION_LEARNING);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('LearningPage.deleteQuestion');
    }
  };

  // IMPORT
  const deleteImportRow = useCallback((index) => {
    if (dataImport.data.length > 0) {
      dataImport.data.splice(index, 1);
    }
    setDataImport({ ...dataImport });
  }, [dataImport]);

  const checkImportData = async (dataImport) => {
    try {
      if (dataImport.data.length === 0) {
        return;
      }
      const data = [];
      dataImport.data.forEach(item => {
        if (!data.includes(item.code)) {
          data.push(item.code);
        }
      });
      const params = {
        input: data
      };
      window.COMMON.trimData(params);
      const result = await window.COMMON.mutation(LearningGRAPHQL.MUTATION_CHECK_IMPORT_QUESTION_LEARNING, params);
      if (result.data && result.data.checkImportQuestionLearning) {
        for (let i = 0; i < result.data.checkImportQuestionLearning.length; i++) {
          const arrs = result.data.checkImportQuestionLearning[i].split('-.-');
          if (Number(arrs[1]) === 1) {
            const array = window.COMMON.getArrsInArrs(arrs[0], dataImport.data, 'code');
            if (array && array.length > 0) {
              array.forEach(element => {
                element.isExisted = true;
              });
            }
          }
        }
      }
      setDataImport(dataImport);
	    createTrackingEvent(eventName.CHECK_IMPORT_QUESTION_LEARNING);
    } catch (error) {
      window.COMMON.showErrorLogs('LearningPage.checkImportData');
    }
  };

  const importData = async () => {
    try {
      if (filter.menu === null) {
        window.COMMON.showMessage('warning', 'MSG_CODE_024', window.MSG.MSG_CODE_024);
        return;
      }
      const dataImported = [];
      dataImport.data.forEach(item => {
        const check = window.COMMON.getObjectInArrs(item.code, dataImported, 'code');
        if (check === null) {
          const arrs = window.COMMON.getArrsInArrs(item.code, dataImport.data, 'code');
          const obj = {};
          obj.code = item.code;
          obj.correctAnswer = item.correctAnswer;
          obj.question = [];
          obj.answer = [];
          obj.description = [];
          obj.menuLearning = filter.menu.value;
          arrs.forEach(child => {
            obj.question.push({
              code: child.languageCode,
              text: child.question
            });
            obj.answer.push({
              code: child.languageCode,
              text: child.answer
            });
            obj.description.push({
              code: child.languageCode,
              text: child.description
            });
          });
          dataImported.push(obj);
        }
      });
      const params = {
        input: dataImported
      };
      window.COMMON.trimData(params);
      const result = await window.COMMON.mutation(LearningGRAPHQL.MUTATION_IMPORT_QUESTION_LEARNING, params);
      if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_IMPORT)) {
        window.COMMON.hideModal('#modal-import');
        getDataQuestion();
        createTrackingEvent(eventName.IMPORT_QUESTION_LEARNING);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('LearningPage.importData');
    }
  };

  if (typeof window === 'undefined') {
    return null;
  }
  return (
    <div className="container-row">
      <Tab data={dataTabs} id="container-data" changeTab={changeTab}>
        <div id="tab-1" className="tab-pane fade active show">
          <div className="container-top mb-2 d-flex align-items-center justify-content-end" style={{ zIndex: 100 }}>
            {
              !window.COMMON.checkRoleIsSystem() ? <div></div> : <div className="bd-highlight mr-3" style={{ width: '300px' }}>
                <div className="input-group input-group-transparent">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <MDBIcon fa="true" icon="building" className="fa-fw" />
                    </span>
                  </div>
                  <Select className="md-form m-0" placeholder={window.I18N('choose_option')} value={filter.company} options={options.company} onChange={(event) => handleFilterSelect(event, 'company')} isSearchable />
                </div>
              </div>
            }
            <MDBBtn color="primary" className="mr-0" onClick={() => openLearningDialog(-1)}>
              <MDBIcon fa="true" icon="plus-circle" className="fa-fw" /> {window.I18N('btn_add')}
            </MDBBtn>
          </div>
          <div className="container-table wow fadeIn animated" data-wow-delay="1s">
            <Table id="table-data" className="table" config={config} data={data} handleRowEditClick={openLearningDialog} handleRowDelClick={openDeleteLearningDialog}></Table>
          </div>
        </div>
        <div id="tab-2" className="tab-pane fade">
          <div className="container-top mb-2 d-flex align-items-center justify-content-end" style={{ zIndex: 100 }}>
            <div className="bd-highlight mr-3" style={{ width: '300px' }}>
              <div className="input-group input-group-transparent">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <MDBIcon fa="true" icon="tasks" className="fa-fw" />
                  </span>
                </div>
                <Select className="md-form m-0" placeholder={window.I18N('choose_option')} value={filter.learning} options={options.learning} onChange={(event) => handleFilterSelect(event, 'learning')} isSearchable />
              </div>
            </div>
            <MDBBtn color="primary" onClick={() => openAddMenuDialog(-1)}>
              <MDBIcon fa="true" icon="plus-circle" className="fa-fw" /> {window.I18N('btn_add')}
            </MDBBtn>
          </div>
          <div className="container-table wow fadeIn animated" data-wow-delay="1s">
            <TableTree id="table-menu" className="table" config={configMenu} data={dataMenu} status={object.status} itemId="_id" handleRowAddClick={openAddMenuDialog} handleRowEditClick={openEditMenuDialog}  handleRowDelClick={openDeleteMenuDialog}></TableTree>
          </div>
        </div>
        <div id="tab-3" className="tab-pane fade">
          <div className="container-top mb-2 d-flex align-items-center justify-content-end" style={{ zIndex: 100 }}>
            <div className="bd-highlight mr-3" style={{ width: '300px' }}>
              <div className="input-group input-group-transparent">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <MDBIcon fa="true" icon="tasks" className="fa-fw" />
                  </span>
                </div>
                <Select className="md-form m-0" placeholder={window.I18N('choose_option')} value={filter.menu} options={options.menu} onChange={(event) => handleFilterSelect(event, 'menu')} isSearchable />
              </div>
            </div>
            <ExportExcel label={window.I18N('btn_template')} isTemplate={true} dataKey="QUESTION_LEARNING_TEMPLATE"></ExportExcel>
            <ImportExcel label={window.I18N('btn_import')} classNameButton="mr-0" id="file-import" dataKey="IMPORT_QUESTION_LEARNING" handleFile={handleFileImport}></ImportExcel>
          </div>
          <div className="container-table wow fadeIn animated" data-wow-delay="1s">
            <Table id="table-question" className="table" config={configQuestion} data={dataQuestion} handleRowDelClick={openDeleteQuestionDialog}></Table>
          </div>
        </div>
      </Tab>

      <Modal id="modal-learning" className="modal-lg" title={window.I18N('create_update_data')} saveEvent={saveLearning}>
        <form id="form-learning" className="needs-validation" noValidate>
          <MDBRow>
            <MDBCol>
              <MDBInput outline type="text" label={window.I18N('code') + ' *'} containerClass="mt-0" value={dataSave.code} name="code" onChange={handleChange} maxLength="50" pattern="\S(.*\S)?" required>
                <div className="invalid-feedback">{window.I18N('MSG_CODE_029')}</div>
              </MDBInput>
              <RadioBox data={types} value={dataSave.type} handleChange={(value) => handleRadio('type', value)}></RadioBox>
              <MDBInput outline type="text" label={window.I18N('name') + ' *'} name="name" value={window.COMMON.getValueWithLanguage(dataSave, 'name', language.current)} onChange={handleChangeWithLanguage} maxLength="200" pattern="\S(.*\S)?" required>
                <div className="invalid-feedback">{window.I18N('MSG_CODE_029')}</div>
              </MDBInput>
              {
                dataSave.type === 'NORMAL' ? <MDBInput outline type="text" label={window.I18N('content') + ' *'} name="content" value={window.COMMON.getValueWithLanguage(dataSave, 'content', language.current)} onChange={handleChangeWithLanguage} maxLength="200" pattern="\S(.*\S)?" required>
                  <div className="invalid-feedback">{window.I18N('MSG_CODE_029')}</div>
                </MDBInput> : null
              }
              <MDBInput outline type="textarea" label={window.I18N('description')} name="description" value={window.COMMON.getValueWithLanguage(dataSave, 'description', language.current)} onChange={handleChangeWithLanguage} maxLength="5000" pattern="\S(.*\S)?"></MDBInput>
              <div className="text-center">
                <MDBCardImage className="input-image-icon" src={window.COMMON.setValueImage(dataSave.icon)} hover overlay="white-slight"/><br></br>
                <InputFile id="file-learning" icon="upload" handleFile={handleFile} accept="image/*" description="Icon (64 x 64) pixels"></InputFile>
              </div>
            </MDBCol>
          </MDBRow>
        </form>
      </Modal>

      <Modal id="modal-menu" className="modal-lg" title={window.I18N('create_update_data')} saveEvent={saveMenu}>
        <form id="form-menu" className="needs-validation" noValidate>
          <MDBRow>
            <MDBCol>
              <MDBInput outline type="text" label={window.I18N('code') + ' *'} containerClass="mt-0" value={dataSave.code} name="code" onChange={handleChange} maxLength="50" pattern="\S(.*\S)?" required>
                <div className="invalid-feedback">{window.I18N('MSG_CODE_029')}</div>
              </MDBInput>
              <RadioBox data={menuTypes} value={dataSave.type} handleChange={(value) => handleRadio('type', value)}></RadioBox>
              <MDBInput outline type="text" label={window.I18N('name') + ' *'} name="name" value={window.COMMON.getValueWithLanguage(dataSave, 'name', language.current)} onChange={handleChangeWithLanguage} maxLength="200" pattern="\S(.*\S)?" required>
                <div className="invalid-feedback">{window.I18N('MSG_CODE_029')}</div>
              </MDBInput>
              <MDBInput outline type="number" className="input-number" label={window.I18N('index') + ' *'} value={dataSave.index} name="index" onChange={handleChange} maxLength="5" pattern="\S(.*\S)?" min="0" max="100000" required>
                <div className="invalid-feedback">{window.I18N('MSG_CODE_029')}</div>
              </MDBInput>
              {
                dataSave.type !== 'SURVEY' ? <MDBInput outline type="textarea" label={window.I18N('content')} name="content" value={window.COMMON.getValueWithLanguage(dataSave, 'content', language.current)} onChange={handleChangeWithLanguage} maxLength="5000" pattern="\S(.*\S)?"></MDBInput> : null
              }
              <div className="text-center">
                <MDBCardImage className="input-image-icon" src={window.COMMON.setValueImage(dataSave.icon)} hover overlay="white-slight"/><br></br>
                <InputFile id="file-menu" icon="upload" handleFile={handleFile} accept="image/*" description="Icon (64 x 64) pixels"></InputFile>
              </div>
            </MDBCol>
          </MDBRow>
        </form>
      </Modal>

      <Modal id="modal-import" className="modal-xl" title={window.I18N('import_data')} saveEvent={importData}>
        <Table id="table-import" className="table" config={dataImport.config} data={dataImport.data} handleRowDelClick={deleteImportRow} isImported={true}></Table>
      </Modal>
    </div>
  );
}

const mapStateToProps = state => ({
  language: state.language.value
});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(LearningPage);