import { MDBCardImage, MDBCol, MDBRow, MDBInput } from 'mdbreact';
import React, { useState, useEffect, useCallback, useLayoutEffect, useRef, useImperativeHandle } from 'react';
import Select from 'react-select';
import InputFile from '../../components/InputFile';
import ListOption from '../../components/ListOption';
import { MenuItem, Select as MuiSelect, Slider } from '@material-ui/core';
import SelectGroupsType from './GroupQuestionsTemplate/SelectGroupsType';

const SurveyForm = React.forwardRef(({ ...props }, ref) => {
  const { dataSave, setDataSave, options, companyOptionsNotGroup, questionsType, onChangeQuestionsType } = props;
  const [select, setSelect] = useState({ company: null });

  const language = useRef();
  const formRef = useRef();

  useLayoutEffect(() => {
    if (!props.language.code) {
      return;
    }
    language.current = props.language.code;
    setTimeout(() => {

      //   getDataCompany();
    }, 100);
    // eslint-disable-next-line
  }, [props.language]);

  useEffect(() => {
    if (!companyOptionsNotGroup) {
      return;
    }
    const newSelect = companyOptionsNotGroup.find((item) => item.value === dataSave.company);
    setSelect({ ...select, company: newSelect });
  }, [companyOptionsNotGroup, dataSave.company]);

  const handleChangeWithLanguage = (event) => {

    window.COMMON.setValueWithLanguage(dataSave, event.target.name, language.current, event.target.value);

    setDataSave({ ...dataSave });
  };

  const handleChange = (event) => {
    if (event.target.type === 'checkbox') {
      dataSave[event.target.name] = event.target.checked;
    } else {
      dataSave[event.target.name] = event.target.value;
    }
    setDataSave({ ...dataSave });
  };

  const receiveHashtag = useCallback((data) => {
    dataSave.hashtag = data;
    setDataSave({ ...dataSave });
  }, [dataSave]);

  const handleFile = (file) => {
    const blob = file;
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = function () {
      const base64data = reader.result;
      dataSave.file = file;
      dataSave.fileBinary = base64data;
      dataSave.image = window.COMMON.createBlobImage(file);
      setDataSave({ ...dataSave });
    };
  };
  const handleFinalConclusionTextChange = (e) => {
    const { name: level, value } = e.target;
    if(value.startsWith(' ')){
      return
    }
    window.COMMON.setValueWithLanguage(dataSave.finalConclusion, level, language.current, value);
    setDataSave({ ...dataSave });
  };

  const handleSelect = (event) => {
    setSelect({ ...select, company: event });
    setDataSave({ ...dataSave, company: event.value });
  };

  
  const handleValidForm = () => {
    const checkForm = window.COMMON.checkFormValidation('new-survey-form', formRef.current);
    const checkValidStandardOfConclusion = handleValidStandardOfConclusion()
    const isValid = checkForm && checkValidStandardOfConclusion;
    if(!checkForm){
      window.COMMON.showMessage('warning', 'MSG_CODE_010', window.I18N('MSG_CODE_010'))
    }
    return isValid
  }
  
  const handleValidStandardOfConclusion = () => {
    if(dataSave.standardOfConclusion){
      const { min, max } = dataSave.standardOfConclusion
      if(min < window.CONSTANT.MIN_STANDARD_OF_CATEGORY || max > window.CONSTANT.MAX_STANDARD_OF_CATEGORY) {
        window.COMMON.showMessage('warning', 'MSG_CODE_084', window.I18N('MSG_CODE_084', { 
          min: window.CONSTANT.MIN_STANDARD_OF_CATEGORY,
          max: window.CONSTANT.MAX_STANDARD_OF_CATEGORY
        }));
        return false
      }
      return true
    }
  }

  useImperativeHandle(ref, () => {
    return {
      handleValidForm
    };
  }, [dataSave]);

  const checkShowCompanyFilter = () => {

    if (window.COMMON.checkRoleIsSystem() && props.mode !== window.CONSTANT.ONLY_CREATE) {
      return true;
    }
    return false;
  };

  const handleChangeStandardOfConclusion = (newValue) => {
    const minValue = Math.min(...newValue)
    const maxValue = Math.max(...newValue)
    dataSave.standardOfConclusion = {
      min: minValue,
      max: maxValue
    }
    setDataSave({...dataSave})
  }

  return <div className='wow fadeInUp animated" data-wow-delay="0.5s'>
    <form id="new-survey-form" className='needs-validation-mt-2 mt-3' noValidate ref={formRef}>
      <MDBRow>
        <MDBCol sm='12'>
          <div className='media'>
            <div className='text-center mr-3'>
              <MDBCardImage className="img-fluid image-qa" src={window.COMMON.setValueImage(dataSave.image)} hover overlay="white-slight" /><br></br>
              <InputFile id="file-image" icon="upload" handleFile={handleFile} accept="image/*" description={`${window.I18N('image')} (120 x 120) pixels`}></InputFile>
            </div>
            <div className="media-body">
              <MDBInput outline containerClass="mt-0" type="text" label={`${window.I18N('title')} *`} name="name" value={window.COMMON.getValueWithLanguage(dataSave, 'name', language.current)} onChange={handleChangeWithLanguage} maxLength="200" pattern="\S(.*\S)?" required></MDBInput>
              {checkShowCompanyFilter() === true && <Select isDisabled={dataSave._id !== null} id="select-companies" value={select.company} options={options.companies} placeholder={window.I18N('companies')} className="md-form" isSearchable onChange={(event) => handleSelect(event)} />}

              <MDBInput outline value={dataSave.code} name="code" onChange={handleChange} type="text" label={`${window.I18N('code')} *`} maxLength="50" pattern="\S(.*\S)?" required></MDBInput>
              <MDBRow>
                <MDBCol sm="12" md="6" className='custom-label'>
                  <MDBInput style={{ zIndex: 0 }} outline type="checkbox" id="ck-share-survey" name="isShared" label={window.I18N('is_shared')} containerClass="p-0" filled checked={dataSave.isShared} onChange={handleChange}></MDBInput>
                </MDBCol>
                <MDBCol sm="12" md="6" className='custom-label'>
                  <MDBInput style={{ zIndex: 0 }} outline type="checkbox" id="ck-public-survey" name="isPublic" label={window.I18N('is_public_survey')} containerClass="p-0" filled checked={dataSave.isPublic} onChange={handleChange}></MDBInput>
                </MDBCol>
              </MDBRow>
              <MDBInput outline type="textarea" label={window.I18N('description')} name="description" value={window.COMMON.getValueWithLanguage(dataSave, 'description', language.current)} onChange={handleChangeWithLanguage} maxLength="5000"></MDBInput>
              <MDBRow>
                <MDBCol sm="12" md="6">
                  <ListOption data={dataSave.hashtag} placeholder={window.I18N('input_hashtag')} handleReceiveData={receiveHashtag}></ListOption>
                </MDBCol>
                <MDBCol sm="12" md="6">
                  <SelectGroupsType
                    onChangeGroupType={onChangeQuestionsType}
                    questionsType={questionsType}
                  />
                </MDBCol>
              </MDBRow>
              {/* final conclusion definition start*/}
              {questionsType === 'SOFT_KILL_QUESTION' ? (
                <MDBCol md='12' className='d-flex flex-column'>
                  <div className='d-flex align-items-center justify-content-between'>
                    <h3
                      style={{
                        fontSize: '16px',
                        fontWeight: 'bold',
                        marginTop: '30px'
                      }}
                    >
                      {window.I18N('survey_final_conclusion')}
                    </h3>
                    {/* Standard of conclusion */}
                   <div
                      style={{
                        width: '20%',
                        marginLeft: '20px'
                      }}
                      className='category-div'
                    >
                      <div>{window.I18N('standard_of_category')}</div>
                      <Slider
                            value={[dataSave?.standardOfConclusion?.min ?? window.CONSTANT.MIN_STANDARD_OF_CATEGORY , dataSave?.standardOfConclusion?.max ?? window.CONSTANT.MAX_STANDARD_OF_CATEGORY]}
                            onChange={(event, newValue) => handleChangeStandardOfConclusion(newValue)}
                            valueLabelDisplay="auto"
                            aria-labelledby="range-slider"
                      />
                      <div className='d-flex align-items-center justify-content-between' style={{ marginTop: '-10px' }}>
                        <div>{0}</div>
                        <div>{100}</div>
                      </div>
                    </div>
                  </div>

                  {/* list question */}
                  <div
                    style={{
                      marginTop: '10px',
                      marginBottom: '10px',
                      width: '100%'
                    }}
                  >
                    <div style={{ position: 'relative' }}>
                      <MDBInput
                          label={window.I18N('low')}
                          style={{
                            margin: 0,
                            minHeight: '60px'
                          }}
                          type='textarea' rows={2} outline maxLength="500"
                          name={'low'}
                          value={window.COMMON.getValueWithLanguage(dataSave.finalConclusion, 'low', language.current)}
                          onChange={e => handleFinalConclusionTextChange(e)}
                      />
                      <span style={{ position: 'absolute', right: 0, top: '-20px' }} >{`${window.COMMON.getValueWithLanguage(dataSave.finalConclusion, 'low', language.current).length}/500`}</span>
                    </div>
                    <div style={{ position: 'relative' }}>
                      <MDBInput
                        label={window.I18N('medium')}
                        style={{
                          margin: 0,
                          minHeight: '60px'
                        }}
                        type='textarea' rows={2} outline maxLength="500"
                        name={'medium'}
                        value={window.COMMON.getValueWithLanguage(dataSave.finalConclusion, 'medium', language.current)}
                        onChange={e => handleFinalConclusionTextChange(e)}
                      />
                        <span style={{ position: 'absolute', right: 0, top: '-20px' }} >{`${window.COMMON.getValueWithLanguage(dataSave.finalConclusion, 'medium', language.current).length}/500`}</span>
                    </div>
                    <div style={{ position: 'relative' }}>
                      <MDBInput
                        label={window.I18N('high')}
                        style={{
                          margin: 0,
                          minHeight: '60px'
                        }}
                        type='textarea' rows={2} outline maxLength="500"
                        name={'high'}
                        value={window.COMMON.getValueWithLanguage(dataSave.finalConclusion, 'high', language.current)}
                        onChange={e => handleFinalConclusionTextChange(e)}
                      />
                        <span style={{ position: 'absolute', right: 0, top: '-20px' }} >{`${window.COMMON.getValueWithLanguage(dataSave.finalConclusion, 'high', language.current).length}/500`}</span>
                    </div>
                  </div>
                </MDBCol>
              ) : null}
              {/* final conclusion definition end*/}
            </div>
          </div>

        </MDBCol>
      </MDBRow>
    </form>
  </div>;
});

export default SurveyForm;
