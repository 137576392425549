import React, { useState, useLayoutEffect, useRef, useCallback } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import { MDBCol, MDBRow, MDBBtn, MDBIcon } from 'mdbreact';

import Panel from '../../components/Panel';
import Filter from '../../components/Filter';
import ExportExcel from '../../components/ExportExcel';
import TableCustomize from '../../components/TableCustomize';
import Pagination from '../../components/Pagination';

import SurveyGRAPHQL from '../../../graphql/Survey';

function HistorySurveyPage(props) {
  const COMPANY_ID = localStorage.getItem(window.CONSTANT.COMPANY_ID);
  const COMPANY_TYPE = localStorage.getItem(window.CONSTANT.COMPANY_TYPE);
  const config = [
    window.COMMON.createCol(window.I18N('no'), '5%', '', '', '', 'INDEX'),
    window.COMMON.createCol(window.I18N('created_date'), '12.5%', '', '', 'createdDate', 'TEXT'),
    window.COMMON.createCol(window.I18N('organization'), '25%', '', '', 'organizationName', 'TEXT'),
    window.COMMON.createCol(window.I18N('user'), '25%', 'p-0 pl-1', '', 'createdBy.avatar,createdBy.nickname', 'IMAGE_TEXT'),
    window.COMMON.createCol(window.I18N('recommend'), '20%', '', '', 'recommendSurveyLanguage', 'TEXT'),
    window.COMMON.createCol(window.I18N('point'), '7.5%', '', 'right', 'rate', 'TEXT')
  ];
  const language = useRef();
  const [filter, setFilter] = useState({
    companyIds: [],
    menuSurvey: null,
    startDate: window.COMMON_DATE.addDaysFromNow(-30),
    endDate: new Date()
  });
  const [pagination, setPagination] = useState({ total: 0, pageSize: 10, currentPage: 0 });
  const [data, setData] = useState([]);
  const [options, setOptions] = useState({
    company: [],
    menuSurvey: []
  });

  useLayoutEffect(() => {
    if (!props.language.code) {
      return;
    }
    language.current = props.language.code;
    setTimeout(() => {
      getDataMenuSurvey();
    }, 100);
    // eslint-disable-next-line
  }, [props.language]);

  const handleFilterSelect = (event, attr) => {
    filter[attr] = event;
    setFilter({ ...filter });
  };

  const handleDatePicker = (event, attr) => {
    if(window.COMMON.isEmpty(event)){
      return
    }
    setFilter((prev) => ({ ...prev, [attr]: event }));
  };

  const getDataMenuSurvey = async () => {
    try {
      const result = await window.COMMON.query(SurveyGRAPHQL.QUERY_MENU_SURVEY, null);
      if (result && result.data) {
        const data = window.COMMON.formatDate(result.data.getMenuSurveys, 'createdDate');
        if (data.length > 0) {
          options.menuSurvey = window.COMMON.createDataSelectWithLanguage(data, '_id', 'content', language.current, true);
          filter.menuSurvey = options.menuSurvey && options.menuSurvey.length > 0 ? options.menuSurvey[0] : null;
        }
        setOptions(options);
        setFilter(filter);
      }
      getDataCompany();
    } catch (error) {
      window.COMMON.showErrorLogs('HistorySurveyPage.getDataMenuSurvey');
    }
  };

  const getDataCompany = async () => {
    try {
      if (!window.COMMON.checkRoleIsSystemOrInfluencer()) {
        if (COMPANY_TYPE === window.CONSTANT.INFLUENCER) {
          await window.COMMON.getDataCompany();
          const dataCompany = JSON.parse(localStorage.getItem(window.CONSTANT.DATA_COMPANY));
          if (dataCompany && dataCompany.length > 0) {
            options.company = window.COMMON.createDataSelectWithGroup(dataCompany, 'id', 'name', 'accountType', 'accountType');
          }
          setOptions({ ...options });
        } else {
          filter.companyIds = [{ value: COMPANY_ID, label: '', code: COMPANY_TYPE }];
          setFilter({ ...filter });
        }
        getDataHistorySurvey();
        return;
      }
      await window.COMMON.getDataAllCompany();
      const dataCompany = JSON.parse(localStorage.getItem(window.CONSTANT.DATA_ALL_COMPANY));
      if (dataCompany && dataCompany.length > 0) {
        options.company = window.COMMON.createDataSelectWithGroup(dataCompany, '_id', 'information.name', 'type', 'type');
      }
      setOptions({ ...options });
      getDataHistorySurvey();
    } catch (error) {
      window.COMMON.showErrorLogs('QuestionReportPage.getDataCompany');
    }
  };

  const getDataHistorySurvey = async () => {
    try {
      const result = await window.COMMON.query(SurveyGRAPHQL.QUERY_HISTORY_SURVEY, generateParams());
      let data = [];
      if (result && result.data && result.data.getHistorySurveys) {
        data = window.COMMON.formatDate(result.data.getHistorySurveys.data, 'createdDate', 'DD/MM/YYYY hh:mm');
        data.forEach(item => {
          item.organizationName = item.company ? window.COMMON.getValueFromAttr(item.company, 'information.name') : (item.influencer ? window.COMMON.getValueFromAttr(item.influencer, 'information.name') : '');
          item.menuSurveyLanguage = window.COMMON.getValueWithLanguage(item, 'menuSurvey.content', language.current);
          item.recommendSurveyLanguage = window.COMMON.getValueWithLanguage(item, 'recommendSurvey.content', language.current);
          item.rate = item.point + '/' + item.total;
        });
        pagination.total = result.data.getHistorySurveys.total;
      }
      setPagination(pagination);
      setData(data);
    } catch (error) {
      window.COMMON.showErrorLogs('HistorySurveyPage.getDataHistorySurvey');
    }
  };

  const generateParams = () => {
    const companyIds = [];
    const unionIds = [];
    const influencerIds = [];
    if (filter.companyIds && filter.companyIds.length > 0) {
      filter.companyIds.forEach(company => {
        if (company.code === window.CONSTANT.COMPANY) {
          companyIds.push(company.value);
        } else if (company.code === window.CONSTANT.UNION) {
          unionIds.push(company.value);
        } else {
          influencerIds.push(company.value);
        }
      });
    }
    // TODO set all company for influencer
    if ((!filter.companyIds || filter.companyIds.length === 0) && COMPANY_TYPE === window.CONSTANT.INFLUENCER) {
      influencerIds.push(COMPANY_ID);
      options.company.forEach(element => {
        element.options.forEach(company => {
          if (company.code === window.CONSTANT.COMPANY) {
            companyIds.push(company.value);
          } else {
            unionIds.push(company.value);
          }
        });
      });
    }
    const params = {
      input: {
        companyIds,
        unionIds,
        influencerIds,
        menuSurvey: window.COMMON.getDataSelect(filter.menuSurvey, false),
        startDate: window.COMMON_DATE.getStrDateFilter(filter.startDate, -1),
        endDate: window.COMMON_DATE.getStrDateFilter(filter.endDate, 1),
        pageSize: pagination.pageSize,
        page: pagination.currentPage + 1
      }
    };
    return params;
  };

  const getDataExport = async () => {
    window.showLoading();
    const questions = [];
    const dataExport = [];
    try {
      const params = generateParams();
      params.input.page = 1;
      const result = await window.COMMON.query(SurveyGRAPHQL.QUERY_EXPORT_HISTORY_SURVEY, params, false);
      if (result && result.data && result.data.getHistorySurveys) {
        generateDataExport(result.data.getHistorySurveys.data, dataExport, questions);
        const total = result.data.getHistorySurveys.total;
        const page = total < pagination.pageSize ? 1 : Math.ceil(total / pagination.pageSize);
        for (let i = 1; i < page; i++) {
          await new Promise(resolve => setTimeout(resolve, 2000));
          params.input.page = i + 1;
          const resultItem = await window.COMMON.query(SurveyGRAPHQL.QUERY_EXPORT_HISTORY_SURVEY, params, false);
          if (resultItem && resultItem.data && resultItem.data.getHistorySurveys) {
            generateDataExport(resultItem.data.getHistorySurveys.data, dataExport);
          }
        }
      }
    } catch (error) {
      window.COMMON.showErrorLogs('HistorySurveyPage.getDataExport');
    }
    window.hideLoading();
    return { questions, data: dataExport};
  };

  const generateDataExport = async (data, dataExport, questions) => {
    let index = 0;
    data.forEach(item => {
      index++;
      item.createdDate = window.COMMON_DATE.formatDate(item.createdDate, 'DD/MM/YYYY hh:mm');
      item.organizationName = item.company ? window.COMMON.getValueFromAttr(item.company, 'information.name') : (item.influencer ? window.COMMON.getValueFromAttr(item.influencer, 'information.name') : '');
      item.menuSurveyLanguage = window.COMMON.getValueWithLanguage(item, 'menuSurvey.content', language.current);
      item.recommendSurveyLanguage = window.COMMON.getValueWithLanguage(item, 'recommendSurvey.content', language.current);
      item.rate = item.point + '/' + item.total;
      item.answers = [];
      if (item.data && item.data.length > 0) {
        for (let i = 0; i < item.data.length; i++) {
          const element = item.data[i];
          if (index === 1 && questions) {
            const questionLanguage = window.COMMON.getValueWithLanguage(element, 'question.content', language.current);
            questions.push(questionLanguage);
          }
          let answerLanguage = '';
          element.answer.forEach(answer => {
            answerLanguage += window.COMMON.getValueWithLanguage(answer, 'content', language.current) + ' (' + answer.point + ' point), ';
          });
          answerLanguage = answerLanguage.trim().replace(/,$/, '');
          item.answers.push(answerLanguage);
        }
      }
      dataExport.push(item);
    });    
  };

  const changePage = useCallback((index) => {
    pagination.currentPage = index;
    setPagination({ ...pagination });
    getDataHistorySurvey();
    // eslint-disable-next-line
  }, [filter, pagination]);

  const doSearchData = () => {
    pagination.currentPage = 0;
    window.COMMON.hideFilter();
    getDataHistorySurvey();
  };

  if (typeof window === 'undefined') {
    return null;
  }
  return (
    <div className="container-row">
      <Panel id="container-data" title={window.I18N('history_survey')} icon="list-alt">
        <div className="container-btn wow fadeInDown animated" data-wow-delay="0.5s">
          <Filter id="container-filter" className="col-md-8">
            <MDBRow>
              {
                window.COMMON.checkRoleIsSystemOrInfluencer() ? 
                  <MDBCol sm="12" className="mb-2">
                    <strong>{window.I18N('organization')}</strong>
                    <div className="input-group input-group-transparent mt-2">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <MDBIcon fa="true" icon="building" className="fa-fw" />
                        </span>
                      </div>
                      <Select className="md-form m-0" value={filter.companyIds} placeholder={window.I18N('all_organizations')} options={options.company} onChange={(event) => handleFilterSelect(event, 'companyIds')} isSearchable isMulti closeMenuOnSelect={false}/>
                    </div>
                  </MDBCol> : null
              }
              <MDBCol sm="12" className="mb-2">
                <strong>{window.I18N('survey')}</strong>
                <div className="input-group input-group-transparent mt-2">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <MDBIcon fa="true" icon="tasks" className="fa-fw" />
                    </span>
                  </div>
                  <Select className="md-form m-0" value={filter.menuSurvey} placeholder={window.I18N('choose_option')} options={options.menuSurvey} onChange={(event) => handleFilterSelect(event, 'menuSurvey')} isSearchable/>
                </div>
              </MDBCol>
              <MDBCol sm="6" className="mb-2">
                <strong>{window.I18N('from_date')}</strong>
                <div className="input-group input-group-transparent mt-2">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <MDBIcon fa="true" icon="calendar-alt" className="fa-fw" />
                    </span>
                  </div>
                  <DatePicker 
                    className="form-control" 
                    dateFormat="dd/MM/yyyy" 
                    maxDate={filter.endDate}
                    selected={filter.startDate} 
                    onChange={(event) => handleDatePicker(event, 'startDate')} 
                    showYearDropdown 
                    showMonthDropdown
                  />
                </div>              
              </MDBCol>
              <MDBCol sm="6" className="mb-2">
                <strong>{window.I18N('to_date')}</strong>
                <div className="input-group input-group-transparent mt-2">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <MDBIcon fa="true" icon="calendar-alt" className="fa-fw" />
                    </span>
                  </div>
                  <DatePicker 
                    className="form-control" 
                    dateFormat="dd/MM/yyyy" 
                    minDate={filter.startDate}
                    selected={filter.endDate} 
                    onChange={(event) => handleDatePicker(event, 'endDate')} 
                    showYearDropdown 
                    showMonthDropdown
                  />
                </div>
              </MDBCol>
              <MDBCol sm="12 text-right">
                <MDBBtn color="primary" onClick={doSearchData}>
                  <MDBIcon fa="true" icon="sync-alt" className="fa-fw" /> {window.I18N('apply')}
                </MDBBtn>
              </MDBCol>
            </MDBRow>
          </Filter>
          <ExportExcel label={window.I18N('export')} isTemplate={false} dataKey="SURVEY_REPORT" handleData={getDataExport}></ExportExcel>
        </div>
        <div className="container-table wow fadeIn animated" data-wow-delay="1s">
          <TableCustomize id="table-data" className="table" config={config} data={data}></TableCustomize>
          <Pagination total={pagination.total} pageSize={pagination.pageSize} currentPage={pagination.currentPage} changePage={changePage}></Pagination>
        </div>
      </Panel>
    </div>
  );
}

const mapStateToProps = state => ({
  language: state.language.value
});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(HistorySurveyPage);