import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import QuestionGRAPHQL from '../../graphql/Question';
import { Grid, Popover, Typography } from '@material-ui/core';
import Select from 'react-select';
import { MDBBtn, MDBIcon, MDBInputGroup } from 'mdbreact';
import SingleQuestionTemplate from './SingleQuestionTemplate';
import InfiniteScroll from 'react-infinite-scroll-component';
import LoadingSpinner from './LoadingSpinner';
import $ from 'jquery';


const PAGE_SIZE= 10
const QuestionBank = React.memo(function ({ language, getQuestionsFromBank }) {
    const COMPANY_ID = localStorage.getItem(window.CONSTANT.COMPANY_ID);
    const COMPANY_TYPE = localStorage.getItem(window.CONSTANT.COMPANY_TYPE);    

    const [categories, setCategories] = useState([])
    const dataCategories = useRef([])
    const [dataQuestion, setDataQuestion] = useState([]);
    const [selectedQuestion, setSelectedQuestion] = useState([])
    const divRef = useRef()
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const [select, setSelect] = useState({
        category: null,
    });
    const [filter, setFilter] = useState({ text: "" })
    const [isStillMore, setIsStillMore]= useState(true)
    const [categoryText, setCategoryText] = useState('')
    

    function handleClick(paramIndex) {
        if (select.category?.value !== categories[paramIndex]?.value) {
            select.category = categories[paramIndex]
            setFilter({ text: "" })
            setSelect({ ...select })
            getQuestionBankData()
        }
        dataQuestion.forEach(el=>el.isCheck=false)
        setAnchorEl(divRef.current);
    }

    const handleSelectQuestion = (paramId) => {
        const indexInDataQuestion = dataQuestion.findIndex(el => el._id === paramId)
        if (indexInDataQuestion !== -1) {
            dataQuestion[indexInDataQuestion].isCheck = !dataQuestion[indexInDataQuestion].isCheck
            if (dataQuestion[indexInDataQuestion].isCheck) {
                selectedQuestion.push(dataQuestion[indexInDataQuestion])
                $("#preview-selected-question").animate({scrollTop: $('#preview-selected-question').prop("scrollHeight")}, "slow")
            } else {
                const indexInSelectQuestion = selectedQuestion.findIndex(el=>el._id === paramId)
                selectedQuestion.splice(indexInSelectQuestion,1)
            }
        } else {
            const indexInSelectQuestion = selectedQuestion.findIndex(el=>el._id === paramId)
            selectedQuestion.splice(indexInSelectQuestion,1)
        }
        setDataQuestion([...dataQuestion])
        setSelectedQuestion([...selectedQuestion])
    }

    function handleClose() {
        setAnchorEl(null);
        setSelectedQuestion([]);
    }

    const handleSelect = (event, id, attr) => {
        window.COMMON.checkSelectValidation(id, event);
        if (JSON.stringify(select[attr])  === JSON.stringify(event)){ return }
        select[attr] = event;
        getQuestionBankData()
        setSelect({ ...select });
    };

    const handleFilterText = (event) => {
        const dataSearch = event.target.value
        if(dataSearch.startsWith(' ')){
            return
        }
        setFilter({...filter, text: dataSearch})
    }

    const handleFilterCategory = (event)=>{
        const text = event.target.value
        if(text.startsWith(' ')){
            return
        }
        setCategoryText(text)
    }

    const handleSearchText = () => {
        getQuestionBankData()
    }
    
    const getQuestionBankData = async () => {
        try {
            const input = {
                order: 'ASC',
                pagination: {
                    pageSize: PAGE_SIZE,
                    page: 1
                },
                question: { }
            }
            if (select.category?.value) {
                input.question.categoryId = select.category.value
            }
            if (filter.text) {
                input.question.name = filter.text.trim()
            }
            let data = []
            const result = await window.COMMON.query(QuestionGRAPHQL.QUERY_QUESTION_BANK, {input});
            if (result?.data?.getQuestionBank) {
                data = window.COMMON.formatDate(result?.data?.getQuestionBank?.data, 'createdDate');
                data.forEach(question => {
                    const isCheck = selectedQuestion.findIndex(selected=>selected._id === question._id)!==-1
                    question.isCheck = isCheck
                });
                if (data.length === result?.data?.getQuestionBank?.total) {
                    setIsStillMore(false)
                } else {
                    setIsStillMore(true)
                }
                setDataQuestion(data);
            }
        } catch (error) {
            window.COMMON.showErrorLogs('QuestionBank.getQuestionBankData');
        }
    };

    const fetchMoreData = async () => {
        try {
            const input = {
                order: 'ASC',
                pagination: {
                    pageSize: PAGE_SIZE,
                    page: parseInt(dataQuestion.length/PAGE_SIZE) +1
                },
                question: { }
            }
            if (select.category?.value) {
                input.question.categoryId = select.category.value
            }
            if (filter.text) {
                input.question.name = filter.text
            }
            let data = []
            const result = await window.COMMON.query(QuestionGRAPHQL.QUERY_QUESTION_BANK, {input});
            if (result?.data?.getQuestionBank) {
                data = window.COMMON.formatDate(result?.data?.getQuestionBank?.data, 'createdDate');
                data.forEach(question => {
                    const isCheck = selectedQuestion.findIndex(selected=>selected._id === question._id)!==-1
                    question.isCheck = isCheck
                });
                if (data.length + dataQuestion.length >= result?.data?.getQuestionBank?.total) {
                    setIsStillMore(false)
                } else {
                    setIsStillMore(true)
                }
                setTimeout(() => { setDataQuestion([...dataQuestion, ...data]);}, 100)
                
            }
        } catch (error) {
            window.COMMON.showErrorLogs('QuestionBank.getQuestionBankData');
        }
    }

    const getTextByLanguage = (multiLanguageText) => {
        if (!multiLanguageText || multiLanguageText.length===0) return ""
        const result = multiLanguageText.find(el => el.code === language)?.text ||multiLanguageText[0]?.text || ""
        return result
    }

    const getCategorydata = async () => {
        try {
            const params = {
                company: COMPANY_ID,
                companyType: COMPANY_TYPE
            };
            const result = await window.COMMON.query(QuestionGRAPHQL.QUERY_QUESTION_CATEGORY, params);
            if (result?.data?.getAllQuestionCategories && language) {
                const allCategories = result?.data?.getAllQuestionCategories.map(el => ({ value: el._id, label: getTextByLanguage(el.title) }))
                setCategories(allCategories);
                dataCategories.current = allCategories
            }
        } catch (error) {
            console.log(error);
            window.COMMON.showErrorLogs('QuestionBank.getCategorydata');
        }
    };

    const onAddQuestion = () => {
        const arrayCloneQuestion = []
        selectedQuestion.forEach((question,index) => {
            arrayCloneQuestion.push({
                question: {
                    _id: null,
                    origin: question._id,
                    question: [{text: question.question, code: 'EN'}],
                    introduction: [{text: question.introduction, code: 'EN'}],
                    type: question.type,
                    code: window.COMMON.generateCode('Q') + '-' + index
                },
                answers: question.choices.map((answer,i) => ({
                    code: window.COMMON.generateCode('A') + '-' + index + '-' + i,
                    _id: null,
                    parentCode: null,
                    content: [{
                        text: answer.content.find(el => el.code === 'EN')?.text || '',
                        code : 'EN'
                    }]
                })),
                dataFromOrigin: {
                    question: [{text: question.question, code: 'EN'}],
                    introduction: [{text: question.introduction, code: 'EN'}],
                    type: question.type,
                    answers: question.choices.map((answer,i) => ({
                        content: [{
                            text: answer.content.find(el => el.code === 'EN')?.text || '',
                            code : 'EN'
                        }]
                    })),
                }
            })
        }) 
        getQuestionsFromBank(arrayCloneQuestion);
        setSelectedQuestion([]);
        setAnchorEl(null);
        setTimeout(() => {
            window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' })
        }, 500)
    }

    const handleScrollWindow = (e) => {
        if (!divRef.current) {
            return;
        }
        if (window.scrollY > 180) {
            divRef.current.style.top= "-170px"
        } else {
            divRef.current.style.top= `${-window.scrollY}px`
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScrollWindow);
        return () => {
            window.removeEventListener('scroll', handleScrollWindow);
        };
    },[])

    useLayoutEffect(() => {
        if (!language) {return;}
        getCategorydata()
        // eslint-disable-next-line
    }, [language]);

    useEffect(()=>{
        if (categoryText){
            let showCategories =[]
            if (dataCategories.current.length){
                showCategories = dataCategories.current.filter(category => {
                    return category.label.toLowerCase().includes(categoryText.trim().toLowerCase())
                })
            } 
            setCategories(showCategories)
        } else {
            setCategories(dataCategories.current)
        }
    },[categoryText])

    return (
        <div className={`pl-2 py-3 mt-3 rounded ${open?"invisible":""}`} style={{ background: 'rgba(85, 81, 255, 0.50)', position:"absolute", width:"100%" }} ref={divRef}>
            <div className='d-flex justify-content-between'>
                <Typography className='text-white font-weight-bold mb-2'>{window.I18N('question_bank')}</Typography>
            </div>
            <div className='d-flex justify-content-end pr-2'>
                <MDBInputGroup value={categoryText} type="text" hint={window.I18N('search')} onChange={handleFilterCategory} pattern="\S(.*\S)?" required
                    append={
                        <span className="input-group-text ">
                            <MDBIcon fa="true" icon="search" className="fa-fw"></MDBIcon>
                        </span>
                    }
                />
            </div>
            <div className='question-bank-scroll' style={{ maxHeight: "500px", overflow: "scroll" }}>    
                {categories.map((el, i) =>
                    <Grid key={i} container className='my-3 rounded d-flex justify-content-between' style={{ background: "#D9D9D9" }}>
                        <Grid xs={10} item className='d-flex align-items-center max'>
                            <Grid container >
                                <Grid className='p-3 my-auto font-weight-bold' item xs={12}>{el.label}</Grid>
                            </Grid>
                        </Grid>
                        <Grid xs={2} item  className='d-flex align-items-center'>
                            <Grid container>
                                <Grid className='my-auto' item >
                                    <MDBBtn outline className="btn btn-sm btn-transparent m-0" type="button" color="" onClick={()=>handleClick(i)}>
                                        <MDBIcon fa="true" icon="angle-right" className="fa-fw" />
                                    </MDBBtn>
                                </Grid>
                            </Grid>
                        </Grid>  
                    </Grid>
                    )}
            </div>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "left"
                }}
                transformOrigin={{
                    vertical: "top",    
                    horizontal: "left"
                }}
            >
                <Grid container className=' w-full' style={{background:"#DADCE0"}}>
                    <Grid style={{width:"620px"}} item>
                        <Grid container className='p-2 font-weight-bold'>
                            {window.I18N('question_bank')}
                        </Grid>
                        <Grid container>
                            <div className="container-tabs-content mb-1 rounded d-flex align-items-center justify-content-end" style={{ zIndex: 1 }}>
                                <div className="bd-highlight mr-4" style={{ width: '350px' }}>
                                    <div className="input-group input-group-transparent">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">
                                                <MDBIcon fa="true" icon="code-branch" className="fa-fw" />
                                            </span>
                                        </div>
                                        <Select id='select-category' className="md-form m-0" placeholder={window.I18N('choose_category')} value={select.category} options={categories} onChange={(event) => handleSelect(event, 'select-category', 'category')} isSearchable />
                                    </div>
                                </div>
                                <div className="mr-2 ml-auto" style={{ width: '400px' }}>
                                    <MDBInputGroup type="text" value={filter.text} hint={window.I18N('search_name')} onChange={handleFilterText} maxLength="100" pattern="\S(.*\S)?" required
                                        append={
                                            <span className="input-group-text cursor-pointer" onClick={handleSearchText}>
                                                <MDBIcon fa="true" icon="search" className="fa-fw"></MDBIcon>
                                            </span>
                                        }
                                    />
                                </div>
                            </div>
                        </Grid>
                        <Grid id='infinity-scroll' container style={{ height: "470px", overflow: "auto" }} className='py-2'>
                            <InfiniteScroll
                                dataLength={dataQuestion.length}
                                next={fetchMoreData}
                                hasMore={isStillMore}
                                loader={<div className='text-center py-2 '><LoadingSpinner /></div>}
                                height={450}
                                style={{ width: "620px" }}
                                scrollableTarget='infinity-scroll'
                                endMessage={
                                    <p className='text-center py-2 '>
                                        <b>{window.I18N('no_more_question')}</b>
                                    </p>
                                }
                            >
                                {
                                    dataQuestion.map((question, i) =>
                                        <SingleQuestionTemplate mode="TEMPLATE" question={question} key={`${i} ${question.isCheck}`} handleSelectQuestion={handleSelectQuestion} />)
                                }
                            </InfiniteScroll>
                        </Grid>
                    </Grid>
                    <Grid style={{ width: "400px" }} className='ml-4 overflow-scroll' item>
                        <Grid container className='p-2 font-weight-bold'>
                            {window.I18N('preview')}
                        </Grid>
                        <Grid id='preview-selected-question' container style={{ maxHeight: "500px", overflowY: "scroll" }} className='py-2'>
                            {selectedQuestion.map((question,i) =>
                            <SingleQuestionTemplate mode="PREVIEW" question={question} key={i} handleSelectQuestion={handleSelectQuestion} />)}
                        </Grid>
                        <Grid container className='p-2 d-flex justify-content-center'>
                            
                            <MDBBtn color="primary" type="button" onClick={onAddQuestion} >
                                <MDBIcon fa="true" icon="plus" className="fa-fw" /> {window.I18N('add_question')}
                            </MDBBtn>
                        </Grid>
                    </Grid>
                </Grid>
            </Popover>
        </div>
    )
})
export default QuestionBank