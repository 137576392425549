import React, { useState } from 'react';
import { connect } from 'react-redux';

import { Save, Public, Lock } from '@material-ui/icons';
import { Button, FormControl, FormControlLabel, Grid, Radio, RadioGroup, Switch, TextField } from '@material-ui/core';
import { MDBCardImage } from 'mdbreact';
import InputFile from './InputFile';
import FanpageGRAPHQL from '../../graphql/Fanpage';
import { useHistory } from 'react-router-dom';
import Constant from '../../utils/Constant';
import { useTracking } from 'react-tracking';
import eventName from '../../common/events';


const CreateFanpage = ({ reloadParent }) => {
    const history = useHistory();
    const [dataSave, setDataSave] = useState({ 
        name: "", 
        image: "", 
        description: "", 
        isApproveMember: false,
        isApprovePost: true,
        isHide: false, 
        gopy: 0, 
        type: Constant.FANPAGE.PUBLIC, 
        subQuestion1: '',
        subQuestion2: '',
        subQuestion3: '' 
    })
    const [file, setFile] = useState(null);
    const [validFieds, setValidFields] = useState({ name: true, image: true })
    const { trackEvent } = useTracking();

    const createTrackingEvent = (event) => {
        return trackEvent({
            name: event,
            createdAt: new Date().toISOString()
        });
    };

    const handleChangeText = (event) => {
        const { name, value } = event.target
        if(!window.COMMON.validateTextInput(value)){
            return
        }
        dataSave[name] = value
        if (name === "type" ) {
            dataSave.gopy = 0
        }
        if (!validFieds.name && name === 'name') {
            validFieds.name = true
            setValidFields({...validFieds})
        }
        setDataSave({ ...dataSave })
    }

    const toggleChecked = (name) => {
        dataSave[name] = !dataSave[name]
        setDataSave({ ...dataSave })
    };

    const handleFile = (file) => {
        dataSave.image = window.COMMON.createBlobImage(file);
        setFile(file);
        if (!validFieds.image ) {
            validFieds.image = true
            setValidFields({...validFieds})
        }
        setDataSave({ ...dataSave });
    };
    const checkValidInput = (params) => {
        let result = true;
        if (!params.name) {
            validFieds.name= false
            result = false
        }
        if (!params.image) {
            validFieds.image= false
            result = false
        }
        setValidFields({ ...validFieds })
        return result
    }
    const onSaveBtn = async() => {
        let dataFiles = [];
        try {
            const params = {
                isApproveMember: dataSave.isApproveMember,
                isApprovePost: dataSave.isApprovePost,
                isHide : dataSave.isHide,
                name : dataSave.name,
                type : dataSave.type,
                gopy: parseInt(dataSave.gopy),
                image : dataSave.image
            };
            if (dataSave.description) {
                params.description = dataSave.description
            }
            if (dataSave.subQuestion1) {
                params.subQuestion1 = dataSave.subQuestion1
            }
            if (dataSave.subQuestion2) {
                params.subQuestion2 = dataSave.subQuestion2
            }
            if (dataSave.subQuestion3) {
                params.subQuestion3 = dataSave.subQuestion3
            }
            
            window.COMMON.trimData(params);
            const checkValid = checkValidInput(params)
            if (checkValid) {
                if (file) {
                    dataFiles = await window.uploadFile([file]);
                    if (dataFiles.length > 0) {
                        dataSave.image = window.COMMON.getNewPathFile(dataFiles[0]._id);
                    }
                }
                setFile(null);
                params.image = dataSave.image
                const result = await window.COMMON.mutation(FanpageGRAPHQL.MUTATION_ADD_FANPAGE, {input: params})
                if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_INSERT)) {
                    createTrackingEvent(eventName.CREATE_FANPAGE);
                    history.push('/my-fanpage')
                    reloadParent()
                }
            } else {
                window.COMMON.showMessage('warning', 'MSG_CODE_010', window.I18N('MSG_CODE_010'));
            }
        } catch (error) {
            console.error("🚀 ~ onSaveBtn ~ error:", error)
            window.UPLOAD_API.deleteMultiFile(dataFiles.map(item => item._id))           
        }
    }


    return (
        <Grid style={{width:"80%", padding:"30px 80px"}} item className='bg-white mx-auto'>
            <Grid container component="h4" className=' justify-content-center'>{window.I18N('create_fanpage')}</Grid>
        <Grid container >
            <TextField required style={{width:"80%"}} multiline={true} className={`my-3 mx-auto ${!validFieds.name?'border border-danger':''}`} label={window.I18N('fanpage_name')} variant="outlined"  name="name" value={dataSave.name} onChange={handleChangeText}></TextField>
        </Grid>
        <Grid container >
            <TextField style={{width:"80%"}} multiline={true} minRows={3} className='my-3 mx-auto' label={window.I18N('fanpage_description')} variant="outlined"  name="description" value={dataSave.description} onChange={handleChangeText}></TextField>
        </Grid>
        <Grid container >
            <TextField style={{width:"80%"}} multiline={true} className='my-3 mx-auto' label={window.I18N('subQuestion1')} variant="outlined"  name="subQuestion1" value={dataSave.subQuestion1} onChange={handleChangeText}></TextField>
        </Grid>
        <Grid container >
            <TextField style={{width:"80%"}} multiline={true} className='my-3 mx-auto' label={window.I18N('subQuestion2')} variant="outlined"  name="subQuestion2" value={dataSave.subQuestion2} onChange={handleChangeText}></TextField>
        </Grid>
        <Grid container >
            <TextField style={{width:"80%"}} multiline={true} className='my-3 mx-auto' label={window.I18N('subQuestion3')} variant="outlined"  name="subQuestion3" value={dataSave.subQuestion3} onChange={handleChangeText}></TextField>
        </Grid>
        <Grid container className=' flex-column align-items-center'>
            <MDBCardImage style={{width:"410px", height:"200px", objectFit:"cover"}} className={`image-logo mt-3 ${!validFieds.image?'border border-danger':''}`} src={window.COMMON.setValueImage(dataSave.image)} hover overlay="white-slight" />
        </Grid>
        <Grid container className=' flex-column align-items-center mt-2'>
            <InputFile id="file-avatar" icon="upload" handleFile={handleFile} accept="image/*" description={`${window.I18N("fanpage_image")} (${window.I18N("aspect_ratio")} 2:1) *`}></InputFile>
        </Grid>
        <Grid container className=' justify-content-between mt-4 mb-3'>
            <Grid component="h6" className='my-auto font-weight-bolder' item>{window.I18N('auto_approve_member')}</Grid>
            <Switch checked={dataSave.isApproveMember} onClick={() => toggleChecked('isApproveMember')} color="primary"></Switch>
        </Grid>
        <Grid container className=' justify-content-between my-3'>
            <Grid component="h6" className='my-auto font-weight-bolder' item>{window.I18N('auto_approve_post')}</Grid>
            <Switch checked={dataSave.isApprovePost} onClick={()=>toggleChecked('isApprovePost')} color="primary"></Switch>
        </Grid>
        <Grid container className=' justify-content-between my-3'>
            <Grid component="h6" className='my-auto font-weight-bolder' item>{window.I18N('hide_fanpage')}</Grid>
            <Switch checked={dataSave.isHide} onClick={()=>toggleChecked('isHide')} color="primary"></Switch>
        </Grid>
        <Grid container className=' my-3 justify-content-between'>
                <Grid component="h6" className='font-weight-bolder pt-2' item>{window.I18N("privacy")}</Grid>
            <FormControl component="fieldset">
                <RadioGroup name="type" value={dataSave.type} onChange={handleChangeText}>
                    <FormControlLabel
                        style={{marginRight:0}}
                        labelPlacement='start'
                        value={Constant.FANPAGE.PUBLIC}
                        control={<Radio color='primary' className='mb-1'></Radio>}
                        label={<>
                            <Public className='mb-1'></Public>{window.I18N("public_fanpage")}
                        </>}>
                    </FormControlLabel>
                    <FormControlLabel
                        style={{marginRight:0}}
                        labelPlacement='start'
                        value={Constant.FANPAGE.PRIVATE}
                        control={<Radio color='primary'></Radio>}
                        label={<>
                            <Lock className='mb-1'></Lock>{window.I18N("private_fanpage")}</>
                        }>
                    </FormControlLabel>
                </RadioGroup>
            </FormControl>
        </Grid>
        {dataSave.type === Constant.FANPAGE.PRIVATE &&
            <Grid container>
                <TextField style={{ width: "50%" }} label={window.I18N("need_gopies")} type='number' className='my-4 mx-auto' variant="outlined" name="gopy" value={dataSave.gopy} onChange={handleChangeText}></TextField>
            </Grid>
        }
        <Grid container className="mx-8 mt-20 w-full justify-content-center">
            <Button
                className='ml-4 text-capitalize'
                variant="contained"
                color="primary" 
                onClick={onSaveBtn}
            >
                <Save /> &nbsp; {window.I18N("btn_save")}
            </Button>
        </Grid>
	</Grid>
    )
}

const mapStateToProps = state => ({
    language: state.language.value
});
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(CreateFanpage);
