import React from 'react'; 
import { NavLink } from 'react-router-dom';
import { MDBCardImage, MDBRow, MDBCol, MDBIcon } from 'mdbreact';

const GridItemSchedule = React.memo(function GridItemSchedule(props) {

  const translateType = (type) => {
    switch (type) {
      case 'ONE':
        return window.I18N('one_time');
      case 'WEEKLY':
        return window.I18N('weekly');
      case 'MONTHLY': 
        return window.I18N('monthly');
      case 'YEARLY': 
        return window.I18N('yearly');
      default:
        return '';
    }
  }

  const handleStart = (event, index) => {
    event.stopPropagation();
    if (props.handleStart) {
      props.handleStart(index);
    }
  };

  const handlePause = (event, index) => {
    event.stopPropagation();
    if (props.handlePause) {
      props.handlePause(index);
    }
  };

  const handleStop = (event, index) => {
    event.stopPropagation();
    if (props.handleStop) {
      props.handleStop(index);
    }
  };

  const handleEdit = (event, index) => {
    event.stopPropagation();
    if (props.handleEdit) {
      props.handleEdit(index);
    }
  };

  const handleDelete = (event, index) => {
    event.stopPropagation();
    if (props.handleDelete) {
      props.handleDelete(index);
    }
  };

  const checkBtn = (item) => {
    return window.COMMON.getValueFromAttr(item, 'status') === 0 || window.COMMON.getValueFromAttr(item, 'status') === -1;
  };

  if (!props.data || props.data.length === 0) {
    return <li className="list-group-item p-3 text-center">{window.I18N('empty_table')}</li>;
  }
  return (
    <>
      {props.data.map((item, i) => {
        const checkIsCreator = item.createdBy._id === window.COMMON.getCurrentUserInformation()._id;
        return (
          <li className="list-group-item p-3" key={i}>
            <MDBRow className={(item.status === -3 ? ' disabled' : '')}>
              <MDBCol>
                <p className="font-weight-bold mb-2">
                  {window.COMMON.getValueFromAttr(item, 'name')}
                </p>
                <p className="mb-3">
                  <span className="mr-3">
                    <MDBIcon fa="true" icon="qrcode" className="fa-fw mr-1"></MDBIcon>
                    <small className="text-muted">{window.COMMON.getValueFromAttr(item, 'code')}</small>
                  </span>
                  <span className="label-box label-select p-1 m-0">
                    {translateType(window.COMMON.getValueFromAttr(item, 'type'))}
                    {/* <MDBIcon fa="true" icon="caret-down" className="fa-fw" /> */}
                  </span>
                </p>
                <div className="d-flex align-items-center">
                  <div className="mr-3">
                    <small className="text-muted">{window.I18N('created_by')}</small>:&nbsp;
                    <MDBCardImage cascade waves src={window.COMMON.setValueImage(window.COMMON.getValueFromAttr(item, 'createdBy.avatar'))} className="small-icon rounded-circle mr-1"/>
                    <small className="font-weight-bold">{window.COMMON.getValueFromAttr(item, 'createdBy.name')}</small>
                  </div>
                  <span>
                    <small className="text-muted">{window.I18N('created_date')}</small>:&nbsp;
                    <small className="font-weight-bold">{window.COMMON.getValueFromAttr(item, 'createdDate')}</small>
                  </span>
                </div>
              </MDBCol>
              <MDBCol>
                {
                  item.status === window.CONSTANT.SURVEY_STATUS.PROCESS ? <label className="label-box label-primary p-1 m-0">{window.I18N('in_proccessing')}</label> 
                    : item.status === window.CONSTANT.SURVEY_STATUS.FINISHED ? <label className="label-box label-success p-1 m-0">{window.I18N('finished')}</label> 
                      : item.status === window.CONSTANT.SURVEY_STATUS.PAUSED ? <label className="label-box label-warning p-1 m-0">{window.I18N('paused')}</label> 
                        : item.status === window.CONSTANT.SURVEY_STATUS.STOPPED ? <label className="label-box label-danger p-1 m-0">{window.I18N('stopped')}</label> 
                          : <label className="label-box label-info p-1 m-0">{window.I18N('new')}</label>
                }
                <p className="mt-2 mb-3">
                  <MDBIcon fa="true" icon="calendar-alt" className="fa-fw mr-1"></MDBIcon>
                  <small className="text-muted">{window.COMMON.getValueFromAttr(item, 'startDate') + ' - ' + window.COMMON.getValueFromAttr(item, 'endDate')}</small>
                </p>
              </MDBCol>
              <MDBCol>
                <MDBCol>
                  <p className="card-text mb-1">
                    <MDBIcon fa="true" icon="circle" className="fa-fw mr-1" style={{ color: '#dddddd' }}></MDBIcon>
                    {window.COMMON.getValueFromAttr(item, 'groupLanguage')}
                  </p>
                  <small className="m-0 text-muted font-weight-bold">{window.I18N('group_qa')}</small>
                </MDBCol>
              </MDBCol>
              <MDBCol xs="2" sm="1" md="1" className="text-right">
                <div className="dropdown">
                  <NavLink to="#" className="" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-boundary="viewport">
                    <MDBIcon fa="true" icon="ellipsis-v" className="fa-fw black-text"></MDBIcon>
                  </NavLink>
                  <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton" data-boundary="viewport">
                    <li className={'dropdown-item' + (item.status === 2 || item.status === 1 || item.status === -2 ? ' disabled' : '')} onClick={item.status === 2 || item.status === 1 ? () => {} : (event) => handleStart(event, i)}>
                      <MDBIcon fa="true" icon="play" className="fa-fw"></MDBIcon> {window.I18N('start_schedule')}
                    </li>
                    <li className={'dropdown-item' + (item.status !== 1 ? ' disabled' : '')} onClick= {item.status !== 1 ? () => {} : (event) => handlePause(event, i)}>
                      <MDBIcon fa="true" icon="pause" className="fa-fw"></MDBIcon> {window.I18N('pause_schedule')}
                    </li>
                    <li className={'dropdown-item' + (item.status === 2 || item.status === -2 ? ' disabled' : '')} onClick= {item.status === 2 ? () => {} : (event) => handleStop(event, i)}>
                      <MDBIcon fa="true" icon="stop" className="fa-fw"></MDBIcon> {window.I18N('stop_schedule')}
                    </li>
                    <li className="dropdown-divider"></li>
                    <li className={'dropdown-item' + (item.status !== 0 || !props.canEdit || !checkIsCreator ? ' disabled' : '')} onClick={item.status !== 0 && !props.canEdit ? () => { } : (event) => handleEdit(event, i)}>
                      <MDBIcon fa="true" icon="edit" className="fa-fw"></MDBIcon> {window.I18N('btn_edit')}
                    </li>
                    <li className={'dropdown-item red-text' + (!checkBtn(item) || !checkIsCreator ? ' disabled' : '')} onClick= {!checkBtn(item) ? () => {} : (event) => handleDelete(event, i)}>
                      <MDBIcon fa="true" icon="trash-alt" className="fa-fw"></MDBIcon> {window.I18N('btn_delete')}
                    </li>
                  </ul>
                </div>
              </MDBCol>
            </MDBRow>
          </li>
        );
      })}
    </>
  );
});

export default GridItemSchedule;