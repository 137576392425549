import React, { useState, useLayoutEffect, useCallback, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import {  withRouter } from 'react-router-dom';
import FanpageGRAPHQL from '../../graphql/Fanpage';
import MetadataGRAPHQL from '../../graphql/Metadata';
import RelationshipGRAPHQL from '../../graphql/Relationship';
import ReasonGRAPHQL from '../../graphql/Reason';
import eventName from '../../common/events';
import ItemPost from './ItemPost';
import ListImage from './ListImage';
import InputFile from './InputFile';

import { Box, Button, Grid, IconButton, MenuItem, Popover, Radio, TextField } from '@material-ui/core';
import { AddPhotoAlternate, Audiotrack, VideoCall, Gif, PictureInPicture, Lock, Public, Add, MoreHoriz, Report, Save, Cancel } from '@material-ui/icons';
import { MDBInput, MDBBtn, MDBIcon, MDBCardImage } from 'mdbreact';
import ReactPlayer from 'react-player/lazy';


import { useTracking } from 'react-tracking';
import BackgroundPost from './BackgroundPost';
import FanpageMember from './FanpageMember';
import FanpageSetting from './FanpageSetting';
import FanpageManagePost from './FanpageManagePost';
import Modal from './Modal';
import ItemShare from './ItemShare';
import DiscoverFanpage from './DiscoverFanpage';
import CreateFanpage from './CreateFanpage';
import Constant from '../../utils/Constant';
import InfiniteScroll from 'react-infinite-scroll-component';
import LoadingSpinner from './LoadingSpinner';
import FanpageEvent from './FanpageEvent';


const PAGE_SIZE = 10
const FanpageContent = (props) => {
    const FANPAGE = Constant.FANPAGE
    const { trackEvent } = useTracking();
    const COMPANY_ID = localStorage.getItem(window.CONSTANT.COMPANY_ID);
    const COMPANY_TYPE = localStorage.getItem(window.CONSTANT.COMPANY_TYPE);
    const currentUser = window.COMMON.getCurrentUserInformation()

    const language = useRef();
    const [dataDetailFanpage, setDataDetailFanpage] = useState({ fanpageDetail: null, mode: null })
    const [dataPost, setDataPost] = useState([])
    const [dataCategorySticker, setDataCategorySticker] = useState([]);
    const [dataReason, setDataReason] = useState([])
    const [object, setObject] = useState({ index: -1, isLoadMore: false, isGif: false, background: null, itemShare: null });
    const [media, setMedia] = useState({ audio: null, video: null });
    const [dataConfig, setDataConfig] = useState([]);
    const [tabControl, setTabControl] = useState(FANPAGE.TAB_POSTS)
    const [userRelationShip, setUserRelationship] = useState({ status: null, role: null })
    const [anchorEl, setAnchorEl] = useState(null);
    const [reportReasonId, setReportReasonId] = useState(null)
    const [isStillMore, setIsStillMore] = useState(false)
    const [postInFanpageCount, setPostInFanpageCount] = useState(0)

    const [friends, setFriends] = useState({
        ids: [],
        data: []
    });
    const [canInviteFriends, setCanInviteFriends] = useState([])
    const [dataImage, setDataImage] = useState([]);
    const [dataSave, setDataSave] = useState({
        type: 'POST',
        answer: []
    });
    const [dataShare, setDataShare] = useState({});
    const [dataRequest, setDataRequest] = useState({ answerQuestion1: '', answerQuestion2: '', answerQuestion3: '' })


    const handleTabControl = (paramTab) => {
        if (tabControl !== paramTab) {
            setTabControl(paramTab)
        }
    }
    const openDeletePost = useCallback((index) => {
        object.index = index;
        setObject({ ...object });
        window.COMMON.showModal('#modal-delete');
        window.deleteMethod = deletePost;
    // eslint-disable-next-line
    }, [object, dataPost]);
    
    const openReportPost = useCallback((index) => {
        object.index = index;
        setObject({ ...object });
        window.COMMON.showModal('#modal-report-post');
        //window.COMMON.showModal('#modal-delete');
        //window.deleteMethod = reportPost;
    // eslint-disable-next-line
    }, [object,dataPost]);

    const handleChange = useCallback((event, attr) => {
        dataSave[attr] = event.target.value;
        if (attr === 'text' && !checkEnableBackground(event.target.value)) {
            dataSave.background = null;
            object.background = null;
            setDataImage([]);
            setObject(object);
        }
        setDataSave({ ...dataSave });
    }, [object, dataSave]);

    const handleChangeAnswerText = (event) => {
        dataRequest[event.target.name] = event.target.value
        setDataRequest({ ...dataRequest })
    }

    const onCloseModal = () => {
        setDataRequest({
            answerQuestion1: '',
            answerQuestion2: '',
            answerQuestion3: ''
        })
        window.COMMON.hideModal('#modal-join-fanpage');
    }

    const checkEnableBackground = (value) => {
        const length = value.split(/\r\n|\r|\n/).length;
        return value.length <= 200 && length <= 5;
    };
    const handleFile = (files) => {
        dataSave.files = files;
        dataSave.background = null;
        object.background = null;
        media.audio = null;
        media.video = null;
        const dataImage = files.map(file => window.COMMON.createBlobImage(file));
        setDataImage(dataImage);
        setMedia(media);
        setObject(object);
        setDataSave({ ...dataSave });
    };
    
    const handleFileVideo = (file) => {
        if (!file.type.match(/video.*/)){
            window.COMMON.showMessage('warning', 'MSG_CODE_064', window.I18N('MSG_CODE_064'));
            return;
        }
        const limit = window.COMMON.getValueFromAttr(window.COMMON.getObjectInArrs('MAX_VIDEO_SIZE', dataConfig, 'name'), 'value') || -1;
        if (file.size > Number(limit) * 1024 * 1024 && limit !== -1) {
            window.COMMON.showMessage('warning', 'MSG_CODE_069', window.MSG.MSG_CODE_069 + limit + 'MB.');
            return;
        }
        dataSave.files = null;
        dataSave.background = null;
        object.background = null;
        media.audio = null;
        media.video = file;
        setDataImage([]);
        setObject(object);
        setMedia(media);
        setDataSave({ ...dataSave });
    };

    const handleBackground = useCallback((item) => {
        dataSave.background = item._id;
        let dataImage = [];
        if (item.isGif) {
            dataImage = [item.background];
            object.background = null;
        } else {
            object.background = item;
        }
        setDataImage(dataImage);
        setObject(object);
        setDataSave({...dataSave});
        window.COMMON.hideModal('#modal-background-my-post');
    }, [object, dataSave]);

    const resetForm = () => {
        const dataSave = {
            text: '',
            type: 'POST',
            background: null,
            files: null,
            video: null,
            audio: null
        };
        object.background = null;
        const media = { audio: null, video: null };
        document.getElementById('files').value = null;
        window.COMMON.resetFormValidation('form');
        setDataImage([]);
        setMedia(media);
        setObject(object);
        setDataSave({ ...dataSave });
    };

    const handleFileAudio = (file) => {
        if (!file.type.match(/audio.*/)){
            window.COMMON.showMessage('warning', 'MSG_CODE_064',window.I18N('MSG_CODE_064'));
            return;
        }
        const limit = window.COMMON.getValueFromAttr(window.COMMON.getObjectInArrs('MAX_AUDIO_SIZE', dataConfig, 'name'), 'value') || -1;
        if (file.size > Number(limit) * 1024 * 1024 && limit !== -1) {
            window.COMMON.showMessage('warning', 'MSG_CODE_069', window.MSG.MSG_CODE_069 + limit + 'MB.');
            return;
        }
        dataSave.files = null;
        dataSave.background = null;
        object.background = null;
        media.audio = file;
        media.video = null;
        setDataImage([]);
        setMedia(media);
        setObject(object);
        setDataSave({ ...dataSave });
    };

    const captureVideoFrame = (format = 'jpeg') => {
        const videoContainer = document.getElementById('video');
        const video = videoContainer.childNodes.length > 0 ? videoContainer.childNodes[0] : null;
        const canvas = document.createElement('CANVAS');
        canvas.height = video.videoHeight;
        canvas.width = video.videoWidth;
        canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height);
        const dataUri = canvas.toDataURL('image/' + format);
        const data = dataUri.split(',')[1];
        const mimeType = dataUri.split(';')[0].slice(5);

        const bytes = window.atob(data);
        const buf = new ArrayBuffer(bytes.length);
        const arr = new Uint8Array(buf);

        for (let i = 0; i < bytes.length; i++) {
            arr[i] = bytes.charCodeAt(i);
        }
        const blob = new Blob([ arr ], { type: mimeType });
        return new File([blob], window.COMMON.genKey() + '.' + format, { type: blob.type });
    };

    const openBackgroundPost = (value) => {
        object.isGif = value;
        setObject({ ...object });
        window.COMMON.showModal('#modal-background-my-post');
    };

    const openSharePost = useCallback((item) => {
        object.itemShare = item;
        //thêm fanpage id khi share bài
        if (item.fanpage) {
            dataShare.fanpage = item.fanpage._id
        }
        dataShare.text = '';
        dataShare.postId = item._id;
        dataShare.company = COMPANY_TYPE === window.CONSTANT.COMPANY ? window.COMMON.getValue(COMPANY_ID) : null;
        dataShare.union = COMPANY_TYPE === window.CONSTANT.UNION ? window.COMMON.getValue(COMPANY_ID) : null;
        dataShare.influencer = COMPANY_TYPE === window.CONSTANT.INFLUENCER ? window.COMMON.getValue(COMPANY_ID) : null;
        setObject(object);
        setDataShare({...dataShare});
        window.COMMON.showModal('#modal-share');
    }, [COMPANY_TYPE, COMPANY_ID, object, dataShare]);
    
    const deleteImage = (index) => {
        dataImage.splice(index, 1);
        setDataImage([ ...dataImage ]);
    };

    const addPost = async (event) => {
        let dataFiles = [];
        try {
            if (!window.COMMON.checkFormValidation('form', event)) {
                return;
            }
            window.showLoading();
            dataSave.isPublic = dataDetailFanpage.fanpageDetail.type === FANPAGE.PRIVATE ? false : true
            // TODO upload file
            let videoUrl = '';
            let audioUrl = '';
            const image = [];
            if (media.video) {
                dataSave.files = [];
                const fileImage = captureVideoFrame();
                dataSave.files = [media.video, fileImage];
            }
            if (media.audio) {
                dataSave.files = [media.audio];
            }
            if (dataSave.files) {
                dataFiles = await window.uploadFile(dataSave.files);
                if (dataFiles.length > 0) {
                    dataFiles.forEach(element => {
                        const ext = element.ext.toLowerCase();
                        if (ext === 'mp4' || ext === 'mkv' || ext === 'ts' || ext === '3gp' || ext === 'mov' || ext === 'avi') {
                            videoUrl = window.COMMON.getNewPathFile(element._id);
                        } else if (ext === 'mp3' || ext === 'm4a' || ext === 'wav' || ext === 'aac' || ext === 'flac' || ext === 'webm' || ext === 'webm') {
                            audioUrl = window.COMMON.getNewPathFile(element._id);
                        } else {
                            image.push(window.COMMON.getNewPathFile(element._id));
                        }
                    });
                }
            }
            dataSave.image = image;
            dataSave.files = null;
            dataSave.video = videoUrl || null;
            dataSave.audio = audioUrl || null;
            dataSave.fanpage = dataDetailFanpage.fanpageDetail._id
            dataSave.status = dataDetailFanpage.fanpageDetail.isApprovePost || (userRelationShip.role === FANPAGE.OWNER)
            // TODO save data
            const params = {
                input: dataSave
            };
            window.COMMON.trimData(params);
            const result = await window.COMMON.mutation(FanpageGRAPHQL.MUTATION_ADD_POST, params);
            if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_INSERT)) {
                createTrackingEvent(eventName.ADD_POST);
                getDataFanpageDetail(props?.location?.search?.slice(1));
                resetForm();
            }
            window.COMMON.hideModal('#modal-post');
        } catch (error) {
            console.error("🚀 ~ addPost ~ error:", error)
            window.UPLOAD_API.deleteMultiFile(dataFiles.map(item => item._id))           
            window.COMMON.showErrorLogs('FanpageContent.addPost');
        }
        window.hideLoading();
    };
    
    const deletePost = async () =>  {
        try {
            const post = dataPost[object.index]
            const imageForDelete = post.image.map((image) => {
                const [, idImage] = image.split('file/')
                return idImage
            })
            const params = {
                id: post._id
            }
            const result = await window.COMMON.mutation(FanpageGRAPHQL.MUTATION_DEL_POST, params);
            if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_DELETE)) {
                createTrackingEvent(eventName.DEL_POST);
                window.UPLOAD_API.deleteMultiFile(imageForDelete)
                getDataFanpageDetail(props?.location?.search?.slice(1));
            }
        } catch (error) {
            window.COMMON.showErrorLogs('FanpageContent.deletePost');
        }
    };

    const reportPost = async () => {
        try {
            if (reportReasonId && dataPost[object.index]._id) {
                const params = {
                    postId: dataPost[object.index]._id,
                    reason: reportReasonId
                };
                const result = await window.COMMON.mutation(FanpageGRAPHQL.MUTATION_REPORT_POST, params);
                if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_DELETE)) {
                    createTrackingEvent(eventName.REPORT_POST);
                    window.COMMON.hideModal('#modal-report-post');
                    getDataFanpageDetail(props?.location?.search?.slice(1));
                }
            }
        } catch (error) {
            window.COMMON.showErrorLogs('FanpageContent.reportPost');
        }
    }

    const getDataSticker = async () => {
        try {
            const params = {
                parentCode: '-1'
            };
            const result = await window.COMMON.query(MetadataGRAPHQL.QUERY_STICKERS, params);
            let data = [];
            if (result && result.data) {
                data = result.data.getStickers;
            }
            setDataCategorySticker(data);
        } catch (error) {
            window.COMMON.showErrorLogs('FanpageContent.getDataSticker');
        }
    };
    
    const getDataFanpageDetail = async (mode) => {
        try {
            if (mode === FANPAGE.FEEDS_MODE || mode === '') {
                const params = {
                    page: 1,
                    pageSize: PAGE_SIZE
                }
                const result = await window.COMMON.query(FanpageGRAPHQL.QUERY_POST_IN_ALL_FANPAGES, params)
                setDataDetailFanpage({ fanpageDetail: null, mode: FANPAGE.FEEDS_MODE })
                if (result?.data?.getMyPostsInAllFapages?.length > 0) {
                    setDataPost(result.data.getMyPostsInAllFapages)
                    if (result.data.getMyPostsInAllFapages.length < PAGE_SIZE) {
                        setIsStillMore(false)
                    } else {
                        setIsStillMore(true)
                    }
                }
            } else if (mode === FANPAGE.DISCOVER_MODE || mode === FANPAGE.CREATE_MODE) {
                setDataDetailFanpage({fanpageDetail: null, mode: mode})
            } else {
                const [ userRelationShip, fanpageDetail] = await Promise.all([
                    window.COMMON.query(FanpageGRAPHQL.QUERY_FANPAGE_RELATIONSHIP_BY_USER, { id: mode }),
                    window.COMMON.query(FanpageGRAPHQL.QUERY_FANPAGE_DETAIL, { id: mode })
                ])
                if ( userRelationShip?.data && fanpageDetail?.data) {
                    fanpageDetail.data.getFanpageDetail.createdDate = window.COMMON.formatDateSingleData(fanpageDetail?.data?.getFanpageDetail, 'createdDate');
                    setDataDetailFanpage({ fanpageDetail: fanpageDetail.data.getFanpageDetail, mode: FANPAGE.DETAIL_MODE })
                    setUserRelationship({
                        status: userRelationShip?.data?.findFanpageRelationship.status,
                        role: userRelationShip?.data?.findFanpageRelationship.role
                    })
                    if (userRelationShip?.data?.findFanpageRelationship?.status === "ACCEPTED" || fanpageDetail.data.getFanpageDetail.type === "PUBLIC") {
                        const params = {
                            page: 1,
                            fanpage: mode,
                            status: true,
                            pageSize: PAGE_SIZE
                        }
                        const dataPost = await window.COMMON.query(FanpageGRAPHQL.QUERY_MY_POST_IN_FANPAGE, params)
                        if (dataPost?.data) {
                            setDataPost(dataPost.data.getMyPostsInFapage.data)
                            setPostInFanpageCount(dataPost.data.getMyPostsInFapage.total)
                            if (dataPost.data.getMyPostsInFapage.total === dataPost.data.getMyPostsInFapage.data.length) {
                                setIsStillMore(false)
                            } else {
                                setIsStillMore(true)
                            }
                        }
                    } else { setDataPost([]) }
                }
            }
        } catch (error) {
            console.error("🚀 ~ getDataFanpageDetail ~ error:", error)
        }
    }

    const fetchMoreData = async () => {
        try {
            if (dataDetailFanpage.mode === FANPAGE.DETAIL_MODE) {
                const params = {
                    page: parseInt(dataPost.length / PAGE_SIZE) + 1,
                    fanpage: dataDetailFanpage.fanpageDetail._id,
                    status: true,
                    pageSize: PAGE_SIZE
                }
                const result = await window.COMMON.query(FanpageGRAPHQL.QUERY_MY_POST_IN_FANPAGE, params)
                if (result?.data && result.data?.getMyPostsInFapage?.data?.length > 0) {
                    if ((result.data.getMyPostsInFapage.data.length + dataPost.length) >= postInFanpageCount) {
                        setIsStillMore(false)
                    } else {
                        setIsStillMore(true)
                    }
                    setTimeout(() => {
                        setDataPost([...dataPost, ...result.data.getMyPostsInFapage.data])
                    }, 500)
                }
            } else if (dataDetailFanpage.mode === FANPAGE.FEEDS_MODE) {
                const params = {
                    page: parseInt(dataPost.length / PAGE_SIZE) + 1,
                    pageSize: PAGE_SIZE
                }
                const result = await window.COMMON.query(FanpageGRAPHQL.QUERY_POST_IN_ALL_FANPAGES, params)
                if (result?.data?.getMyPostsInAllFapages?.length > 0) {
                    if (result.data.getMyPostsInAllFapages.length < PAGE_SIZE) {
                        setIsStillMore(false)
                    } else {
                        setIsStillMore(true)
                    }
                    setTimeout(() => {
                        setDataPost([...dataPost, ...result.data.getMyPostsInAllFapages])
                    }, 500)
                }
            }
        } catch (error) {
            window.COMMON.showErrorLogs('FanpageContent.fetchMoreData');
        }
    }

    const getDataFriends = async () => {
        try {
            //get relationship
            const result = await window.COMMON.query(RelationshipGRAPHQL.QUERY_FIND_FRIENDS_RELATIONSHIP_BY_USER, {}, true);
            const friends = [];
            let friendIds = [];
            const currentUserId = localStorage.getItem(window.CONSTANT.ID);
            if (result && result.data) {
                const relationships = result.data.findFriendsByUser;
                relationships.forEach((item) => {
                    let friend;
                    if (item.createdBy && item.createdBy._id !== currentUserId) {
                        friend = item.createdBy;
                    } else if (item.receiver && item.receiver._id !== currentUserId) {
                        friend = item.receiver;
                    }
                    if (friend){
                        friends.push({
                            ...friend,
                            statusFanpage: null
                        });
                    }
                });
            }
            friendIds = friends.map((item) => item._id);
            setFriends(prev => ({
                ids: [...prev.ids, ...friendIds],
                data: [...prev.data, ...friends]
            }));
        } catch (error) {
            window.COMMON.showErrorLogs('FanpageContent.getDataFriends');
        }
    };

    const getCanInviteFriends = async () => {
        if (dataDetailFanpage?.fanpageDetail?._id) {
            const params = {
                id: dataDetailFanpage.fanpageDetail._id,
                userIds: friends.ids
            }
            const result = await window.COMMON.query(FanpageGRAPHQL.QUERY_FRIEND_FANPAGE_RELATIONSHIPS_BY_USER, params)
            const cloneFriendsData = JSON.parse(JSON.stringify(friends.data))
            if (result?.data?.getFriendFanpageRelationships?.data?.length) {
                for (const relationship of result.data.getFriendFanpageRelationships.data) {
                    const friend = window.COMMON.getObjectInArrs(relationship.user._id, cloneFriendsData, '_id')
                    friend.statusFanpage = relationship.status
                }
            }
            setCanInviteFriends(cloneFriendsData.filter(el=> el.statusFanpage!=='ACCEPTED'))
        }
    }

    const reloadParent = () => {
        props.reloadParent()
        getCanInviteFriends()
    }

    const getDataReason = async () => {
        try {
            const params = {
                type: FANPAGE.REASON_TYPE,
                isActive: [true]
            };
            const result = await window.COMMON.query(ReasonGRAPHQL.QUERY_REASON, params);
            if (result && result.data) {
                const data = window.COMMON.formatDate(result.data.getReasons, 'createdDate');
                data.forEach(item => {
                    item.contentLanguage = window.COMMON.getValueWithLanguage(item, 'content', language.current);
                });
                setDataReason(data);
            }
        } catch (error) {
            window.COMMON.showErrorLogs('FanpageContent.getDataReason');
        }
    };

    const onBtnAddMemberClick = () => {
        window.COMMON.showModal('#modal-invite-member');
    }

    const inviteMember = async(index) => {
        const params = {id:dataDetailFanpage.fanpageDetail._id, userId: canInviteFriends[index]._id, name: currentUser.nickname}
        const result = await window.COMMON.mutation(FanpageGRAPHQL.MUTATION_INVITE_FANPAGE, params)
        if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_INSERT)) {
            createTrackingEvent(eventName.INVITE_FANPAGE);
            getCanInviteFriends()
        }
    }

    const handleLeaveFanpage = async () => {
        const params = { id : dataDetailFanpage.fanpageDetail._id}
        await window.COMMON.requestGraphQL({
            type: 'mutation',
            mutationMode: window.CONSTANT.MODE_DELETE,
            apiName: FanpageGRAPHQL.MUTATION_LEAVE_FANPAGE_RELATIONSHIP,
            params,
            successCallback: () => {
                createTrackingEvent(eventName.LEAVE_FANPAGE);
                getDataFanpageDetail(dataDetailFanpage.fanpageDetail._id);
                setTabControl(FANPAGE.TAB_POSTS)
                props.reloadParent()
            },
            message: {
              successful: "MSG_CODE_094",
              failed: "MSG_CODE_095"
            },
            logMessage: "FanpageContent.handleLeaveFanpage"
        })
    }

    const onBtnRequestFanpage = async () => {
        window.COMMON.showModal('#modal-join-fanpage');
    }

    const saveRequestFanpage = async () => {
            const params = {
                name: currentUser.nickname,
                id : dataDetailFanpage?.fanpageDetail?._id
            }
            if (dataDetailFanpage?.fanpageDetail?.subQuestion1) {
                params.subQuestion1 = dataDetailFanpage?.fanpageDetail?.subQuestion1
                params.subAnswer1 = dataRequest.answerQuestion1
            }
            if (dataDetailFanpage?.fanpageDetail?.subQuestion2) {
                params.subQuestion2 = dataDetailFanpage?.fanpageDetail?.subQuestion2
                params.subAnswer2 = dataRequest.answerQuestion2
            }
            if (dataDetailFanpage?.fanpageDetail?.subQuestion3) {
                params.subQuestion3 = dataDetailFanpage?.fanpageDetail?.subQuestion3
                params.subAnswer3 = dataRequest.answerQuestion3
            }
            await window.COMMON.requestGraphQL({
                type: 'mutation',
                mutationMode: window.CONSTANT.MODE_INSERT,
                apiName: FanpageGRAPHQL.MUTATION_REQUEST_FANPAGE_RELATIONSHIP,
                params: { input: params },
                successCallback: () => {
                    createTrackingEvent(eventName.JOIN_FANPAGE);
                    getDataFanpageDetail(dataDetailFanpage?.fanpageDetail?._id)
                    props.reloadParent()
                    onCloseModal()
                },
                message: {
                  successful: "MSG_CODE_096",
                  failed: "MSG_CODE_097"
                },
                logMessage: "FanpageContent.saveRequestFanpage"
            })
    }
    const onClickLeaveFanpage = () => {
        window.COMMON.showModal('#modal-delete');
        window.deleteMethod = () => {
            handleLeaveFanpage();
        };
    }

    const onClickMoreHoriz = (event) => {
		setAnchorEl(event.currentTarget);
    }
    
    const handleClose = () => {
		setAnchorEl(null);
	};

    const createTrackingEvent = (event) => {
        return trackEvent({
            name: event,
            createdAt: new Date().toISOString()
        });
    };

    useEffect(() => {
        if (dataDetailFanpage?.fanpageDetail?._id && friends?.ids.length) {
            getCanInviteFriends()
        }
    }, [dataDetailFanpage, friends])
    
    useLayoutEffect(() => {
        setTimeout(() => {
            const dataConfig = localStorage.getItem(window.CONSTANT.DATA_CONFIG);
            setDataConfig(JSON.parse(dataConfig));
            getDataSticker();
            getDataFriends();
        },100)
    }, [])

    useLayoutEffect(() => {
        if (!props.language) {
            return;
        }
        language.current = props.language;
        setTimeout(() => {
            getDataReason();
        }, 100);
    // eslint-disable-next-line
    }, [props.language]);
    
    useLayoutEffect(() => {
        setTimeout(() => {
            getDataFanpageDetail(props?.location?.search?.slice(1));
            setTabControl(FANPAGE.TAB_POSTS)
            setTimeout(() => { window.scrollTo({ top: 0, behavior: 'smooth' }) }, 300)
        }, 100);
    }, [props?.location?.search]);

    return (
        <>
            {/* background fanpage */}
            {dataDetailFanpage.mode === FANPAGE.DETAIL_MODE &&<div className='rounded' style={{
                height: "25rem",
                backgroundImage: `url(${dataDetailFanpage?.fanpageDetail?.image})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center",
            }}>
            </div>}
            {/* detail fanpage */}
            {dataDetailFanpage.mode === FANPAGE.DETAIL_MODE &&<div style={{ width: "95%", marginTop: "-5%" }} className='bg-white rounded mx-auto pt-4 px-4'>
                <Grid container className=' justify-content-between'>
                    <Grid item style={{ fontSize: "1.5rem" }} className='font-weight-bold d-flex align-items-center'>{dataDetailFanpage?.fanpageDetail?.name}
                        {dataDetailFanpage?.fanpageDetail?.type === FANPAGE.PRIVATE ? <Lock color='disabled' fontSize='small' className='ml-1' ></Lock> :
                            <Public color='disabled' fontSize='small' className='ml-1'></Public>
                        }
                    </Grid>
                    <Grid item>
                        {userRelationShip.status === "ACCEPTED" &&
                            <>
                            <Button onClick={onBtnAddMemberClick} color="primary" className='text-capitalize' variant='contained' >
                            <Add></Add>&nbsp;{window.I18N("invite")}
                            </Button>
                            {userRelationShip.role!==FANPAGE.OWNER&&<Button onClick={(event)=>onClickMoreHoriz(event)} className='ml-2'>
                                <MoreHoriz></MoreHoriz>
                            </Button>}
                            </>
                        }
                        {userRelationShip.status==="PENDING"&&<Button className='text-capitalize' disabled variant='contained' >
                            <Add></Add>&nbsp;{window.I18N("sent_request")}
                        </Button>}
                        {userRelationShip.status !== "ACCEPTED" && userRelationShip.status !== "PENDING" && <Button className='text-capitalize' onClick={onBtnRequestFanpage} color="primary" variant='contained' ><Add></Add>&nbsp;
                            {dataDetailFanpage?.fanpageDetail?.type === FANPAGE.PRIVATE && window.I18N("join") }
                            {dataDetailFanpage?.fanpageDetail?.type === FANPAGE.PUBLIC && window.I18N("follow") }
                        </Button>}
                    </Grid>
                </Grid>

                {dataDetailFanpage?.fanpageDetail?.type === FANPAGE.PRIVATE ?
                <Grid container className='my-2'>
                    <span className='my-auto'>{`${window.I18N("private_fanpage")} - ${dataDetailFanpage?.fanpageDetail?.countMember} ${window.I18N("member")} `}</span>
                </Grid> : 
                <Grid container className='my-2'>
                    <span className='my-auto'>{`${window.I18N("public_fanpage")} - ${dataDetailFanpage?.fanpageDetail?.countMember} ${window.I18N("member")} `}</span>
                </Grid>}
                <div>
                    {dataDetailFanpage?.fanpageDetail?.gopy>0 && <div className='mt-1'>{`${window.I18N("need_gopies")} : ${dataDetailFanpage?.fanpageDetail?.gopy}`}</div>}
                    <div className='mt-1'>{`${window.I18N('created_date')} : ${dataDetailFanpage?.fanpageDetail?.createdDate}`}</div>
                </div>
                {dataDetailFanpage?.fanpageDetail?.description &&<div>
                    <Grid container className='mt-2 font-weight-bold'>{window.I18N("introduce")}</Grid>
                    <Grid container style={{ color: "#0000008A", fontWeight: 400 }} className='mt-1 ml-1'>{dataDetailFanpage?.fanpageDetail?.description}</Grid>
                </div>}
                <hr className='mb-0'></hr>
                <Grid container>
                    <Grid item className={`tab-control-fanpage ${tabControl===FANPAGE.TAB_POSTS?'active':''}`} onClick={()=>handleTabControl(FANPAGE.TAB_POSTS)}> {window.I18N("posts")}</Grid>
                    {userRelationShip.status === "ACCEPTED" && <Grid item className={`tab-control-fanpage ${tabControl === FANPAGE.TAB_MEMBERS ? 'active' : ''}`} onClick={() => handleTabControl(FANPAGE.TAB_MEMBERS)}> {window.I18N("members")}</Grid>}
                    {userRelationShip.role === FANPAGE.OWNER && <Grid item className={`tab-control-fanpage ${tabControl === FANPAGE.TAB_MANAGE_FANPAGE ? 'active' : ''}`} onClick={() => handleTabControl(FANPAGE.TAB_MANAGE_FANPAGE)}> {window.I18N("manage_fanpage")}</Grid>}
                    {userRelationShip.role=== FANPAGE.OWNER &&<Grid item className={`tab-control-fanpage ${tabControl === FANPAGE.TAB_APPROVE_POST ? 'active' : ''}`} onClick={() => handleTabControl(FANPAGE.TAB_APPROVE_POST)}>{window.I18N("approve_post")}</Grid>}
                    {userRelationShip.status === "ACCEPTED" && <Grid item className={`tab-control-fanpage ${tabControl === FANPAGE.TAB_EVENT_FANPAGE ? 'active' : ''}`} onClick={() => handleTabControl(FANPAGE.TAB_EVENT_FANPAGE)}> {window.I18N("event")}</Grid>}
                </Grid>
            </div>}

            {/* posts on fanpage */}
            {dataDetailFanpage.mode !== FANPAGE.DISCOVER_MODE && dataDetailFanpage.mode !== FANPAGE.CREATE_MODE && tabControl===FANPAGE.TAB_POSTS &&<div className=' wow fadeIn animated overflow-hidden mx-auto pt-4 content-fanpage'>
                {/* new posts */}
                {dataDetailFanpage.mode === FANPAGE.DETAIL_MODE && userRelationShip.status === FANPAGE.ACCEPTED && <div className=" mb-4 wow zoomIn animated" data-wow-delay="0.5s" style={{ overflow: 'inherit', zIndex: 1 }}>
                    <Grid container className="p-3 container-form">
                        <Grid item xs={12}>
                            <form id="form" className="needs-validation" noValidate>
                                {object.background ?
                                    <div className="d-100 position-relative" style={{ height: '300px' }}>
                                        <div className="position-relative w-100 h-100">
                                            <img src={window.COMMON.getValueFromAttr(object.background, 'background')} alt="background-post" className="w-100 h-100" />
                                        </div>
                                        <div className="position-absolute w-100 h-100" style={{ left: 0, top: '50px' }}>
                                            <MDBInput outline id="input-text" className="textarea-post d-flex align-items-center" type="textarea" containerClass="m-0" hint={window.I18N('share_what_you_thinging')} value={dataSave.text} onChange={(event) => handleChange(event, 'text')} maxLength="5000" pattern="\S(.*\S)?" required>
                                                <div className="invalid-feedback">{window.I18N('MSG_CODE_029')}</div>
                                            </MDBInput>
                                        </div>
                                    </div> :
                                    <MDBInput outline id="input-text" type="textarea" containerClass="m-0" hint={window.I18N('share_what_you_thinging')} value={dataSave.text} onChange={(event) => handleChange(event, 'text')} maxLength="5000" pattern="\S(.*\S)?" required>
                                        <div className="invalid-feedback">{window.I18N('MSG_CODE_029')}</div>
                                    </MDBInput>
                                }
                                {dataImage.length > 0 ?
                                    <Grid item xs={12} className="mb-2">
                                        <p className="mb-2"><small className="font-weight-bold">{window.I18N('images')}</small></p>
                                        <ListImage data={dataImage} handleDeleteItem={deleteImage}></ListImage>
                                    </Grid> :
                                    <></>
                                }
                                {media.video ?
                                    <Grid item xs={12} className="mb-2">
                                        <p className="mb-2"><small className="font-weight-bold">Video</small></p>
                                        <div className="d-flex justify-content-center">
                                            <ReactPlayer id="video" url={URL.createObjectURL(media.video)} controls={true}></ReactPlayer>
                                        </div>
                                    </Grid> :
                                    <></>
                                }
                                {media.audio ?
                                    <Grid item xs={12} className="mb-2">
                                        <p className="mb-2"><small className="font-weight-bold">Podcast</small></p>
                                        <div className="d-flex justify-content-center">
                                            <ReactPlayer url={URL.createObjectURL(media.audio)} controls={true} height={60}></ReactPlayer>
                                        </div>
                                    </Grid> :
                                    <></>
                                }
                                <Grid item xs={12} container className="mt-2">
                                    <Grid item xs container spacing={2}>
                                        <InputFile id="files" icon="upload" handleFile={handleFile} accept="image/*" isMulti={true}>
                                            <AddPhotoAlternate color="primary" />
                                        </InputFile>
                                        <div className="d-inline-block text-center">
                                            <IconButton aria-label="add picture" disabled={!checkEnableBackground(dataSave.text || '')} onClick={!checkEnableBackground(dataSave.text || '') ? () => { } : () => openBackgroundPost(false)}>
                                                <PictureInPicture color="primary" />
                                            </IconButton>
                                        </div>
                                        <div className="d-inline-block text-center">
                                            <IconButton aria-label="add picture" onClick={() => openBackgroundPost(true)}>
                                                <Gif color="primary" />
                                            </IconButton>
                                        </div>
                                        <InputFile id="audio-files" icon="upload" handleFile={handleFileAudio} accept="audio/*">
                                            <Audiotrack color="primary" />
                                        </InputFile>
                                        <InputFile id="video-files" icon="upload" handleFile={handleFileVideo} accept="video/*">
                                            <VideoCall color="primary" />
                                        </InputFile>
                                    </Grid>
                                    <Grid item>
                                        <MDBBtn color="" onClick={resetForm}>
                                            <MDBIcon fa="true" icon="redo-alt" className="fa-fw" /> {window.I18N('btn_clear')}
                                        </MDBBtn>
                                        <MDBBtn color="primary" onClick={addPost} className="mr-0">
                                            <MDBIcon fa="true" icon="paper-plane" className="fa-fw" /> {window.I18N('btn_post')}
                                        </MDBBtn>
                                    </Grid>
                                </Grid>
                            </form>
                        </Grid>
                    </Grid>
                </div>}
                {/* data posts */}
                <InfiniteScroll
                    className='overflow-hidden'
                    dataLength={dataPost.length}
                    next={fetchMoreData}
                    hasMore={isStillMore}
                    loader={<div className='text-center py-2 '><LoadingSpinner /></div>}
                    endMessage={
                        <p className='text-center mt-2 mb-0 grey-text'>
                            <span>{window.I18N('no_more_post')}</span>
                        </p>
                    }
                >
                    {dataPost.map((item, i) =>
                        <ItemPost enableGoToAccount={true} fanpagePost={true} item={item} index={i} key={item._id} className="container-post col-md-12 p-0 mb-4" attr_id="_id" attr_content="text" attr_image="image" attr_avatar="createdBy.avatar" attr_name="createdBy.nickname" attr_time="createdDate" categorySticker={dataCategorySticker} handleDeletePost={openDeletePost} handleReportPost={openReportPost} handleSharePost={openSharePost}></ItemPost>
                    )}
                </InfiniteScroll>

            </div>}
            
            {/* manage member */}
            {dataDetailFanpage.mode === FANPAGE.DETAIL_MODE && userRelationShip.status === "ACCEPTED" && tabControl === FANPAGE.TAB_MEMBERS && <div className='bg-white overflow-hidden mx-auto mt-4 rounded content-fanpage'>
                <FanpageMember reloadParent={reloadParent} role={userRelationShip.role} fanpageId={dataDetailFanpage.fanpageDetail._id}></FanpageMember>
            </div>}

            {/* manage fanpage */}
            {dataDetailFanpage.mode === FANPAGE.DETAIL_MODE && userRelationShip.role === FANPAGE.OWNER &&  tabControl === FANPAGE.TAB_MANAGE_FANPAGE && <div className=' overflow-hidden mx-auto mt-4 content-fanpage'>
                <FanpageSetting reloadParent={props.reloadParent} reloadHeader={getDataFanpageDetail} role={userRelationShip.role} fanpageId={dataDetailFanpage.fanpageDetail._id}></FanpageSetting>
            </div>}

            {/* manage post of member */}
            {dataDetailFanpage.mode === FANPAGE.DETAIL_MODE && userRelationShip.role === FANPAGE.OWNER && tabControl === FANPAGE.TAB_APPROVE_POST && <div className='overflow-hidden mx-auto mt-4 content-fanpage'>
                <FanpageManagePost reloadPost={getDataFanpageDetail} role={userRelationShip.role} fanpageId={dataDetailFanpage.fanpageDetail._id}></FanpageManagePost>
            </div>}

            {/*event tab */}
            {dataDetailFanpage.mode === FANPAGE.DETAIL_MODE && userRelationShip.status === "ACCEPTED" && tabControl === FANPAGE.TAB_EVENT_FANPAGE && <div className='overflow-hidden mx-auto mt-4 content-fanpage'>
                <FanpageEvent role={userRelationShip.role} fanpageId={dataDetailFanpage.fanpageDetail._id}></FanpageEvent>
            </div>}
            
            {/* Show other fanpage to join */}
            {dataDetailFanpage.mode === FANPAGE.DISCOVER_MODE && <DiscoverFanpage reloadParent={props.reloadParent}></DiscoverFanpage>}

            {/* Create new Fanpage */}
            {dataDetailFanpage.mode === FANPAGE.CREATE_MODE && <CreateFanpage reloadParent={props.reloadParent}></CreateFanpage>}



            <ItemShare itemShare={object.itemShare} dataShare={dataShare}></ItemShare>
            <BackgroundPost isGif={object.isGif} handleBackground={handleBackground}></BackgroundPost>

            {/* Menu action */}
            <Popover keepMounted
                anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
                transformOrigin={{ horizontal: "left", vertical: "top" }}
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                PaperProps={{ style: { overflow: "inherit" } }}
            >
                <Box
                    style={{
                        backgroundColor: "white",
                        boxShadow: '0px 0px 10px 0px rgba(0,0,0,.25)',
                        display: "block",
                        position: "absolute",
                        width: 15,
                        height: 15,
                        top: -7,
                        transform: "rotate(45deg)",
                        left: "calc(50% - 15px)",
                    }}
                />
                <MenuItem onClick={onClickLeaveFanpage} className='action-member-div first-div' >{window.I18N("leave_fanpage")}</MenuItem>

            </Popover>
            <Modal hideFooter={true} id="modal-invite-member" title={window.I18N('invite')}>
                <div className='p-4 bg-white'>
                    {canInviteFriends.map((friend, index) =>
                        <Grid key={index} container className=' py-2 justify-content-between'>
                            <Grid item>
                                <Grid container>
                                    <MDBCardImage zoom cascade waves src={window.COMMON.setValueImage(friend.avatar)} className="member-fanpage-avatar mr-3" />
                                    <Grid item className='d-flex flex-column justify-content-center'>
                                        <div className=' font-weight-bolder'>{friend.name}</div>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item className='my-auto'>
                                {friend.statusFanpage === null ?
                                    <Button variant='contained' color="primary" onClick={() => inviteMember(index)}>
                                        <Add />{window.I18N("invite")}
                                    </Button> :
                                    <Button disabled={true} variant='contained' >
                                        {window.I18N("invited")}
                                    </Button>
                                }
                            </Grid>
                        </Grid>
                    )}
                </div>
            </Modal>
            <Modal hideFooter={true} id="modal-report-post" title={window.I18N('report_post')}>
                <Grid container className='flex-column bg-white px-6'>
                    {dataReason.map((reason, index) =>
                        <Grid key={index} container className='p-2 justify-content-between'>
                            <Grid item className='my-auto'>{reason.contentLanguage}</Grid>
                            <Grid item>
                                <Radio checked={reason._id === reportReasonId} onClick={() => { setReportReasonId(reason._id) }}></Radio>
                            </Grid>
                        </Grid>
                    )}
                    <Grid item className='text-center'>
                        <Button variant='contained' color="secondary" className='px-4 mt-2 text-capitalize' onClick={reportPost}>
                            <Report />&nbsp; {window.I18N("report")}
                        </Button>
                    </Grid>
                </Grid>
            </Modal>
            <Modal hideFooter={true} handleCloseModal={onCloseModal} hide id="modal-join-fanpage">
                <Grid component="h5" className=' pb-4 text-center justify-content-center'>{window.I18N("confirm_join") + ' "' + dataDetailFanpage?.fanpageDetail?.name + '"'}</Grid>
                {dataDetailFanpage?.fanpageDetail?.gopy > 0 &&
                    <Grid container >
                        <div className='mx-auto mb-2 modal-join-fanpage-row' >
                            {window.I18N('need_gopies') + ' : ' + dataDetailFanpage?.fanpageDetail?.gopy}
                        </div>
                    </Grid>
                }
                {(dataDetailFanpage?.fanpageDetail?.subQuestion1 ||  dataDetailFanpage?.fanpageDetail?.subQuestion2 || dataDetailFanpage?.fanpageDetail?.subQuestion3 )&&
                    <Grid container >
                        <div className='mx-auto my-2' style={{ width: "80%", fontSize: "13px" }}>{window.I18N('question_privacy')}</div>
                    </Grid>
                }
                {dataDetailFanpage?.fanpageDetail?.subQuestion1 &&
                    <Grid container >
                        <div className='mx-auto mb-1 modal-join-fanpage-row' >{dataDetailFanpage?.fanpageDetail?.subQuestion1}</div>
                        <TextField value={dataRequest.answerQuestion1} style={{ width: "80%" }} multiline={true} rows={2} className='mb-4 mx-auto' label={window.I18N('write_answer')} variant="outlined" name="answerQuestion1" onChange={handleChangeAnswerText}></TextField>
                    </Grid>
                }
                {dataDetailFanpage?.fanpageDetail?.subQuestion2 &&
                    <Grid container >
                        <div className='mx-auto mb-1 modal-join-fanpage-row' >{dataDetailFanpage?.fanpageDetail?.subQuestion2}</div>
                        <TextField value={dataRequest.answerQuestion2} style={{ width: "80%" }} multiline={true} rows={2} className='mb-4 mx-auto' label={window.I18N('write_answer')} variant="outlined" name="answerQuestion2" onChange={handleChangeAnswerText}></TextField>
                    </Grid>
                }
                {dataDetailFanpage?.fanpageDetail?.subQuestion3 &&
                    <Grid container >
                        <div className='mx-auto mb-1 modal-join-fanpage-row' >{dataDetailFanpage?.fanpageDetail?.subQuestion3}</div>
                        <TextField value={dataRequest.answerQuestion3} style={{ width: "80%" }} multiline={true} rows={2} className='mb-4 mx-auto' label={window.I18N('write_answer')} variant="outlined" name="answerQuestion3" onChange={handleChangeAnswerText}></TextField>
                    </Grid>
                }
                <Grid container className="mx-8 mt-4 w-full justify-content-end">
                    <Button
                        className='text-capitalize'
                        variant="contained"
                        color="primary"
                        onClick={onCloseModal}
                    >
                        <Cancel /> &nbsp; {window.I18N("btn_cancel")}
                    </Button>
                    <Button
                        className='ml-4 text-capitalize'
                        variant="contained"
                        color="primary"
                        onClick={saveRequestFanpage}
                    >
                        <Save /> &nbsp; {window.I18N("btn_save")}
                    </Button>
                </Grid>
            </Modal>

        </>
    )
}
const mapStateToProps = state => ({
    language: state.language.value
});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(FanpageContent));