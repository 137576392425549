import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import CustomChart from './components/CustomChart'
import ReactSelect from 'react-select'
import { MDBIcon } from 'mdbreact'
import { HorizontalBar, Pie } from 'react-chartjs-2'
import { Grid } from '@material-ui/core'

const COURSE_TRACKING_EVENT = ['VIEW_COURSE', 'ENROLL_COURSE', 'VIEW_LECTURE', 'RELEARN_COURSE', 'END_COURSE']
const COURSE_TRANS_EVENT = [
    'RATING_COURSE',
    'RATING_DISCUSSION',
    'COURSE_COMMENT',
    'QUIZ_ANSWER',
    'UPVOTE',
    'LIKE_COURSE'
]

const COURSE_STATUS = ['private', 'public', 'shared']

const DATA_COURSE = 'course'
const DATA_TRACKING = 'tracking'
const DATA_TRANSACTION = 'transaction'

const paletteColor = [
    'rgba(0, 159, 109, 0.6)',
    'rgba(190, 0, 0, 0.6)',
    'rgba(61, 0, 189, 0.6)',
    'rgba(0, 65, 175, 0.6)',
    'rgba(54, 162, 235, 0.6)',
    'rgba(153, 102, 255, 0.6)',
    'rgba(201, 203, 207, 0.6)',
    'rgba(74, 147, 124, 0.6)',
    'rgba(147, 147, 124, 0.6)',
    'rgba(147, 147, 235, 0.6)',
    'rgba(147, 196, 235, 0.6)',
    'rgba(0, 171, 184, 0.6)',
    'rgba(139, 171, 184, 0.6)',
    'rgba(139, 255, 184, 0.6)',
    'rgba(165, 197, 97, 0.6)',
    'rgba(165, 163, 152, 0.6)',
    'rgba(0, 0, 0, 0.6)',
    'rgba(0, 47, 0, 0.6)',
    'rgba(26, 47, 43, 0.6)',
    'rgba(255, 38, 214, 0.6)'
]

const calcRangeAge = (birthday) => {
    const age = new Date().getFullYear() - new Date(birthday).getFullYear()
    if (age >= 16 && age <= 25) {
        return '16-25'
    } else if (age >= 26 && age <= 40) {
        return '26-40'
    } else if (age >= 41 && age <= 65) {
        return '41-65'
    } else {
        return 'unknow'
    }
}

const countDataWithFilters = (data, filters = { dataKey: '', compareKey: '', compareData: [] }) => {
    const { dataKey, compareKey, compareData } = filters
    const dataCount = {}
    compareData.forEach((item) => {
        const countObj = {}
        const filterWithEvent = data.reduce(
            (acc, el) => (el[compareKey] === item ? [...acc, el[dataKey]] : [...acc]),
            []
        )
        for (let i of filterWithEvent) {
            countObj[i] ? countObj[i]++ : (countObj[i] = 1)
        }

        dataCount[item] = countObj[item] ? countObj[item] : 0
    })

    return dataCount
}

const InsightChartELearning = ({ companyType, companyId, companyInfo, companies, language }) => {
    const dataMonthsSelect = [
        {
            label: window.I18N('last_3_month'),
            value: '90'
        },
        {
            label: window.I18N('last_6_month'),
            value: '180'
        }
    ]
    const [dataDaysOfMonth, setDataDaysMonth] = useState(dataMonthsSelect[0])
    const [dataChart, setDataChart] = useState({ labels: [], datasets: [] })
    const [dataElearning, setDataElearning] = useState({
        course: [],
        tracking: [],
        transaction: []
    })
    const [chartMode, setChartMode] = useState(DATA_TRACKING)
    const chartRef = useRef()
    const handleSelectMonths = (value) => {
        setDataDaysMonth(value)
    }

    const groupDataforChart = (paramData, dataSetMapping = { dataSet: COURSE_TRACKING_EVENT, itemKey: 'name' }) => {
        const { dataSet, itemKey } = dataSetMapping
        let groupDataChart = window.COMMON.getDataChartInPastYear({
            data: paramData,
            numberMonths: Math.floor(+dataDaysOfMonth.value / 30),
            types: dataSet,
            itemKey,
            dateKey: 'createdAt',
            formatDateLabel: 'MM/YYYY'
        })
        const data = {
            labels: groupDataChart.labels,
            datasets: dataSet.map((el, index) => ({
                label: el,
                data: groupDataChart.data[index],
                borderColor: paletteColor[index],
                backgroundColor: 'transparent',
                lineTension: 0.2
            }))
        }
        return data
    }

    const countDataCompaies = (filterData) => {
        const countGender = countDataWithFilters(filterData, {
            compareData: ['male', 'female'],
            dataKey: 'gender',
            compareKey: 'gender'
        })
        const countCompany = countDataWithFilters(filterData, {
            compareData: companyInfo.reduce(
                (acc, company) => (company.accountType === window.CONSTANT.COMPANY ? [...acc, company.name] : acc),
                []
            ),
            dataKey: 'company',
            compareKey: 'company'
        })

        const countInfluencer = countDataWithFilters(filterData, {
            compareData: companyInfo.reduce(
                (acc, company) => (company.accountType === window.CONSTANT.INFLUENCER ? [...acc, company.name] : acc),
                []
            ),
            dataKey: 'influencer',
            compareKey: 'influencer'
        })

        const countUnion = countDataWithFilters(filterData, {
            compareData: companyInfo.reduce(
                (acc, company) => (company.accountType === window.CONSTANT.UNION ? [...acc, company.name] : acc),
                []
            ),
            dataKey: 'union',
            compareKey: 'union'
        })
        const countAgeRange = countDataWithFilters(filterData, {
            compareData: ['16-25', '26-40', '41-65', 'unknow'],
            dataKey: 'age',
            compareKey: 'age'
        })

        return {
            countGender,
            countCompany,
            countInfluencer,
            countUnion,
            countAgeRange
        }
    }

    const getDataInsightElearnTracking = async (companyParams = {}) => {
        try {
            window.showLoading()
            const result = await window.COMMON.httpRequest({
                url: `${process.env.REACT_APP_ELEARNING_API}/courses/insight-chart-elearn-tracking`,
                method: 'POST',
                input: {
                    params: {
                        startDate: window.COMMON_DATE.getStrDateFilter(
                            window.COMMON_DATE.addDaysFromNow(-+dataDaysOfMonth.value),
                            window.CONSTANT.FROM_FILTER_DATE
                        ),
                        endDate: window.COMMON_DATE.getStrDateFilter(new Date(), window.CONSTANT.TO_FILTER_DATE)
                    },
                    data: {
                        ...companyParams,
                        companyIds: companyId
                            ? [companyId]
                            : [...companies.companyIds, ...companies.influencerIds, ...companies.unionIds].filter(
                                  (item) => item
                              )
                    }
                },
                requireToken: true,
                handleError: () => {}
            })
            const modifiedData = result
                .map((data) =>
                    data.events.map((event) => ({
                        ...event,
                        createdAt: window.COMMON_DATE.getFormatDate(data.createdAt, 'MM/YYYY'),
                        user: {
                            gender: data.user.gender === 1 ? 'male' : 'female',
                            age: calcRangeAge(window.COMMON_DATE.getFormatDate(data.user.birthday, 'YYYY-MM-DD')),
                            company:
                                data.user?.company &&
                                companyInfo.find((company) => company?.company?._id === data.user.company)?.name,
                            influencer:
                                data.user?.influencer &&
                                companyInfo.find((company) => company?.influencer?._id === data.user?.influencer)?.name,
                            union:
                                data.user?.union &&
                                companyInfo.find((company) => company?.union?._id === data.user?.union)?.name
                        }
                    }))
                )
                .flat()
            const dataAfterGroup = groupDataforChart(modifiedData)
            let dataTracking = {}
            COURSE_TRACKING_EVENT.forEach((item) => {
                for (let i = 0; i < dataAfterGroup.labels.length; i++) {
                    const month = dataAfterGroup.labels[i]
                    const filterData = modifiedData.reduce(
                        (acc, el) => (el.name === item && el.createdAt === month ? [...acc, el.user] : acc),
                        []
                    )

                    if (!dataTracking[item]) {
                        dataTracking[item] = {}
                    }
                    if (!dataTracking[item][month]) {
                        dataTracking[item][month] = {}
                    }
                    dataTracking[item][month] = {
                        ...countDataCompaies(filterData)
                    }
                }
            })

            setDataElearning((prev) => ({
                ...prev,
                [chartMode]: dataTracking
            }))
            setDataChart({ ...dataAfterGroup })
            window.hideLoading()
        } catch (error) {
            console.error(error)
            window.COMMON.showErrorLogs('InsightChartELearning.getDataInsightElearnTracking')
        }
    }

    const getDataInsightElearnCourse = async (companyParams = {}) => {
        try {
            window.showLoading()
            const [categories, courses] = await Promise.all([
                getCategoryElearn(),
                window.COMMON.httpRequest({
                    url: `${process.env.REACT_APP_ELEARNING_API}/courses/insight-chart-elearn-course`,
                    method: 'POST',
                    input: {
                        params: {
                            startDate: window.COMMON_DATE.getStrDateFilter(
                                window.COMMON_DATE.addDaysFromNow(-+dataDaysOfMonth.value),
                                window.CONSTANT.FROM_FILTER_DATE
                            ),
                            endDate: window.COMMON_DATE.getStrDateFilter(new Date(), window.CONSTANT.TO_FILTER_DATE)
                        },
                        data: {
                            ...companyParams,
                            companyIds: companyId
                                ? [companyId]
                                : [...companies.companyIds, ...companies.influencerIds, ...companies.unionIds].filter(
                                      (item) => item
                                  )
                        }
                    },
                    requireToken: true,
                    handleError: () => {}
                })
            ])
            const dataCategories = {}
            if (!window.COMMON.isEmpty(categories)) {
                categories.forEach((category) => {
                    dataCategories[category._id] = category.name.find(
                        (val) => val.code === language.code.toLowerCase()
                    ).text
                })
            }

            const modifiedData =
                courses?.map((course) => ({
                    ...course,
                    createdAt: window.COMMON_DATE.getFormatDate(course.createdAt, 'MM/YYYY'),
                    user: {
                        gender: course.author.gender === 1 ? 'male' : 'female',
                        age: calcRangeAge(window.COMMON_DATE.getFormatDate(course.author.birthday, 'YYYY-MM-DD')),
                        company:
                            course.author?.company &&
                            companyInfo.find((company) => company?.company?._id === course.author.company)?.name,
                        influencer:
                            course.author?.influencer &&
                            companyInfo.find((company) => company?.influencer?._id === course.author?.influencer)?.name,
                        union:
                            course.author?.union &&
                            companyInfo.find((company) => company?.union?._id === course.author?.union)?.name
                    },
                    category:
                        course?.category && !window.COMMON.isEmpty(course.category)
                            ? dataCategories[course?.category[0]]
                            : 'others'
                })) ?? []

            const dataForChart = groupDataforChart(modifiedData, { dataSet: COURSE_STATUS, itemKey: 'status' })
            setDataChart({ ...dataForChart })
            let dataCourse = {}
            COURSE_STATUS.forEach((item) => {
                for (let i = 0; i < dataForChart.labels.length; i++) {
                    const month = dataForChart.labels[i]
                    const filterData = modifiedData.filter(
                        (course) => course.status === item && course.createdAt === month
                    )
                    const dataCount = filterData.reduce(
                        (acc, course) => {
                            return {
                                completed: (acc.completed += course?.completedCount ?? 0),
                                interact: (acc.interact += course?.interactCount ?? 0),
                                like: (acc.interact += course?.interactCount ?? 0)
                            }
                        },
                        {
                            completed: 0,
                            interact: 0,
                            like: 0
                        }
                    )
                    const countCategories = countDataWithFilters(filterData, {
                        compareData: [...Object.values(dataCategories), 'others'],
                        dataKey: 'category',
                        compareKey: 'category'
                    })

                    if (!dataCourse[item]) {
                        dataCourse[item] = {}
                    }
                    if (!dataCourse[item][month]) {
                        dataCourse[item][month] = {}
                    }
                    dataCourse[item][month] = {
                        countInteract: dataCount,
                        countCategories,
                        ...countDataCompaies(filterData.map((data) => data.user))
                    }
                }
            })
            setDataElearning((prev) => ({
                ...prev,
                [chartMode]: dataCourse
            }))
            window.hideLoading()
        } catch (error) {
            console.error(error)
            window.COMMON.showErrorLogs('InsightChartELearning.getDataInsightElearnCourse')
        }
    }

    const getDataInsightElearnTransaction = async (companyParams = {}) => {
        try {
            window.showLoading()
            const result = await window.COMMON.httpRequest({
                url: `${process.env.REACT_APP_ELEARNING_API}/courses/insight-chart-elearn-transaction`,
                method: 'POST',
                input: {
                    params: {
                        startDate: window.COMMON_DATE.getStrDateFilter(
                            window.COMMON_DATE.addDaysFromNow(-+dataDaysOfMonth.value),
                            window.CONSTANT.FROM_FILTER_DATE
                        ),
                        endDate: window.COMMON_DATE.getStrDateFilter(new Date(), window.CONSTANT.TO_FILTER_DATE)
                    },
                    data: {
                        ...companyParams,
                        companyIds: companyId
                            ? [companyId]
                            : [...companies.companyIds, ...companies.influencerIds, ...companies.unionIds].filter(
                                  (item) => item
                              )
                    }
                },
                requireToken: true,
                handleError: () => {}
            })

            const modifiedData = result.map((data) => ({
                ...data,
                createdAt: window.COMMON_DATE.getFormatDate(data.createdAt, 'MM/YYYY'),
                user: {
                    gender: data.createdBy.gender === 1 ? 'male' : 'female',
                    age: calcRangeAge(window.COMMON_DATE.getFormatDate(data.createdBy.birthday, 'YYYY-MM-DD')),
                    company:
                        data.createdBy?.company &&
                        companyInfo.find((company) => company?.company?._id === data.createdBy.company)?.name,
                    influencer:
                        data.createdBy?.influencer &&
                        companyInfo.find((company) => company?.influencer?._id === data.createdBy?.influencer)?.name,
                    union:
                        data.createdBy?.union &&
                        companyInfo.find((company) => company?.union?._id === data.createdBy?.union)?.name
                }
            }))

            const dataAfterGroup = groupDataforChart(modifiedData, { dataSet: COURSE_TRANS_EVENT, itemKey: 'type' })
            let dataTracking = {}
            COURSE_TRANS_EVENT.forEach((item) => {
                for (let i = 0; i < dataAfterGroup.labels.length; i++) {
                    const month = dataAfterGroup.labels[i]
                    const filterData = modifiedData.reduce(
                        (acc, el) => (el.type === item && el.createdAt === month ? [...acc, el.user] : acc),
                        []
                    )

                    if (!dataTracking[item]) {
                        dataTracking[item] = {}
                    }
                    if (!dataTracking[item][month]) {
                        dataTracking[item][month] = {}
                    }
                    dataTracking[item][month] = {
                        ...countDataCompaies(filterData)
                    }
                }
            })
            setDataElearning((prev) => ({
                ...prev,
                [chartMode]: dataTracking
            }))
            setDataChart({ ...dataAfterGroup })
            window.hideLoading()
        } catch (error) {
            console.error(error)
            window.COMMON.showErrorLogs('InsightChartELearning.getDataInsightElearnTransaction')
        }
    }

    const getCategoryElearn = async () => {
        try {
            window.showLoading()
            const result = await window.COMMON.httpRequest({
                url: `${process.env.REACT_APP_ELEARNING_API}/category`,
                method: 'GET',
                requireToken: true,
                handleError: () => {}
            })
            window.hideLoading()
            return result
        } catch (error) {
            console.error(error)
            window.COMMON.showErrorLogs('InsightChartELearning.getCategoryElearn')
        }
    }

    const trackingDataPopup = (hoverLabelIndex, indexDatasetPopup, hoverDatasetIndex) => {
        const month = dataChart.labels[hoverLabelIndex]
        const { countAgeRange, countGender, countCompany, countInfluencer, countUnion } =
            dataElearning.tracking[COURSE_TRACKING_EVENT[indexDatasetPopup]][month]
        const data = {
            labels: Object.keys(countAgeRange),
            datasets: [
                {
                    label: 'Number of person',
                    backgroundColor: paletteColor,
                    borderWidth: 1,
                    barPercentage: 0.5,
                    data: Object.values(countAgeRange)
                }
            ]
        }
        return (
            <div style={{ width: '300px' }}>
                <div style={{ fontSize: '13px', fontWeight: 400 }}>
                    <Grid container>
                        {hoverDatasetIndex?.map((el, index) => (
                            <Grid
                                key={index}
                                item
                                className={`tab-control-fanpage small-content ${
                                    indexDatasetPopup === el ? 'active' : ''
                                }`}
                                onClick={() => chartRef?.current?.handleSetIndexDatasetPopup(el)}
                            >
                                {' '}
                                {COURSE_TRACKING_EVENT[el]}
                            </Grid>
                        ))}
                    </Grid>
                    <div
                        style={{ width: '100%' }}
                        className="mt-1 mb-3 d-flex align-items-center justify-content-center"
                    >
                        <span
                            className="mr-2"
                            style={{
                                display: 'inline-block',
                                height: '1rem',
                                width: '1rem',
                                backgroundColor: paletteColor[indexDatasetPopup]
                            }}
                        ></span>
                        {`${window.I18N('number-of')} ${COURSE_TRACKING_EVENT[indexDatasetPopup]} ${window.I18N(
                            'in-month'
                        )} ${month}: ${dataChart.datasets[indexDatasetPopup].data[hoverLabelIndex]}`}
                    </div>
                </div>
                <div className="mb-2 d-flex align-items-center justify-content-center" style={{ maxHeight: '140px' }}>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            marginRight: '10px'
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                backgroundColor: '#F1F2F8',
                                marginBottom: '10px',
                                padding: '5px 10px'
                            }}
                        >
                            <div style={{ margin: 'auto', fontSize: '15px', fontWeight: 500 }}>{countGender.male}</div>
                            <div style={{ margin: 'auto' }}>{window.I18N('male')}</div>
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                backgroundColor: '#F1F2F8',
                                padding: '5px 10px'
                            }}
                        >
                            <div style={{ margin: 'auto', fontSize: '15px', fontWeight: 500 }}>
                                {countGender.female}
                            </div>
                            <div style={{ margin: 'auto' }}>{window.I18N('female')}</div>
                        </div>
                    </div>
                    <div className="mr-2">
                        <HorizontalBar
                            width={200}
                            height={130}
                            data={data}
                            options={{
                                legend: { display: false },
                                responsive: true,
                                maintainAspectRatio: false,
                                scales: {
                                    yAxes: [
                                        {
                                            ticks: {
                                                beginAtZero: true,
                                                stepSize: 1,
                                                fontSize: 10
                                            },
                                            gridLines: {
                                                display: false
                                            }
                                        }
                                    ],
                                    xAxes: [
                                        {
                                            ticks: {
                                                beginAtZero: true,
                                                stepSize: 1,
                                                fontSize: 10
                                            }
                                        }
                                    ]
                                },
                                tooltips: {
                                    enabled: true
                                }
                            }}
                        />
                    </div>
                </div>
                {companyId === null && (
                    <div
                        style={{
                            backgroundColor: '#F1F2F8',
                            padding: '5px 15px',
                            height: '120px',
                            width: '100%',
                            overflow: 'scroll',
                            overscrollBehavior: 'contain'
                        }}
                    >
                        <div className="mb-2" style={{ fontWeight: 500 }}>
                            {`${Object.values(countCompany).filter((val) => val > 0).length} ${window.I18N('company')}`}
                            <div className="mb-2">
                                {Object.entries(countCompany).map((data, i) => {
                                    const [key, value] = data
                                    if (value > 0) {
                                        return <li key={i} style={{ fontSize: '11px' }}>{`${key}: ${value}`}</li>
                                    }
                                })}
                            </div>
                            {`${Object.values(countInfluencer).filter((val) => val > 0).length} ${window.I18N(
                                'influencer'
                            )}`}
                            <div className="mb-2">
                                {Object.entries(countInfluencer).map((data, i) => {
                                    const [key, value] = data
                                    if (value > 0) {
                                        return <li key={i} style={{ fontSize: '11px' }}>{`${key}: ${value}`}</li>
                                    }
                                })}
                            </div>
                            {`${Object.values(countUnion).filter((val) => val > 0).length} ${window.I18N('union')}`}
                            <div className="mb-2">
                                {Object.entries(countUnion).map((data, i) => {
                                    const [key, value] = data
                                    if (value > 0) {
                                        return <li key={i} style={{ fontSize: '11px' }}>{`${key}: ${value}`}</li>
                                    }
                                })}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        )
    }

    const courseDataPopup = (hoverLabelIndex, indexDatasetPopup, hoverDatasetIndex) => {
        const month = dataChart.labels[hoverLabelIndex]
        const { countInteract, countCategories, countAgeRange, countCompany, countInfluencer, countUnion } =
            dataElearning.course[COURSE_STATUS[indexDatasetPopup]][month]
        const data = {
            labels: Object.keys(countInteract),
            datasets: [
                {
                    backgroundColor: paletteColor,
                    borderWidth: 1,
                    barPercentage: 0.5,
                    data: Object.values(countInteract)
                }
            ]
        }

        const dataCategoriesChart = {
            labels: Object.entries(countCategories).reduce((acc, data) => {
                const [key, value] = data
                if (value > 0) {
                    return [...acc, key]
                } else {
                    return acc
                }
            }, []),
            datasets: [
                {
                    data: Object.values(countCategories).filter((data) => data > 0),
                    backgroundColor: paletteColor.slice(5)
                }
            ]
        }

        return (
            <>
                <div style={{ fontSize: '13px', fontWeight: 400 }}>
                    <Grid container>
                        {hoverDatasetIndex?.map((el, index) => (
                            <Grid
                                key={index}
                                item
                                className={`tab-control-fanpage small-content ${
                                    indexDatasetPopup === el ? 'active' : ''
                                }`}
                                onClick={() => chartRef?.current?.handleSetIndexDatasetPopup(el)}
                            >
                                {COURSE_STATUS[el]}
                            </Grid>
                        ))}
                    </Grid>
                    <div
                        style={{ width: '100%' }}
                        className="mt-1 mb-3 d-flex align-items-center justify-content-center"
                    >
                        <span
                            className="mr-2"
                            style={{
                                display: 'inline-block',
                                height: '1rem',
                                width: '1rem',
                                backgroundColor: paletteColor[indexDatasetPopup]
                            }}
                        ></span>
                        {`${window.I18N('number_of')} ${window.I18N('course')} ${
                            COURSE_STATUS[indexDatasetPopup]
                        } ${window.I18N('in-month')} ${month}: ${
                            dataChart.datasets[indexDatasetPopup].data[hoverLabelIndex]
                        }`}
                    </div>
                </div>
                <div className="mb-2 d-flex align-items-center justify-content-center" style={{ maxHeight: '200px' }}>
                    <div className="mr-2">
                        <HorizontalBar
                            width={160}
                            height={150}
                            data={data}
                            options={{
                                legend: { display: false },
                                responsive: true,
                                maintainAspectRatio: true,
                                title: {
                                    display: true,
                                    text: window.I18N('number_interactions')
                                },
                                scales: {
                                    yAxes: [
                                        {
                                            ticks: {
                                                beginAtZero: true,
                                                stepSize: 1,
                                                fontSize: 10
                                            },
                                            gridLines: {
                                                display: false
                                            }
                                        }
                                    ],
                                    xAxes: [
                                        {
                                            ticks: {
                                                beginAtZero: true,
                                                stepSize: 1,
                                                fontSize: 10
                                            }
                                        }
                                    ]
                                },
                                tooltips: {
                                    enabled: true
                                }
                            }}
                        />
                    </div>
                    <div>
                        <Pie
                            data={dataCategoriesChart}
                            redraw={true}
                            width={160}
                            height={150}
                            options={{
                                legend: {
                                    position: 'bottom',
                                    labels: {
                                        boxWidth: 12,
                                        fontSize: 10
                                    }
                                },
                                title: {
                                    display: true,
                                    text: window.I18N('course_category')
                                },
                                responsive: true,
                                maintainAspectRatio: false
                            }}
                        />
                    </div>
                </div>
                {companyId === null && (
                    <div
                        style={{
                            backgroundColor: '#F1F2F8',
                            padding: '5px 15px',
                            height: '120px',
                            width: '100%',
                            overflow: 'scroll',
                            overscrollBehavior: 'contain'
                        }}
                    >
                        <div className="mb-2" style={{ fontWeight: 500 }}>
                            {`${Object.values(countCompany).filter((val) => val > 0).length} ${window.I18N('company')}`}
                            <div className="mb-2">
                                {Object.entries(countCompany).map((data, i) => {
                                    const [key, value] = data
                                    if (value > 0) {
                                        return <li key={i} style={{ fontSize: '11px' }}>{`${key}: ${value}`}</li>
                                    }
                                })}
                            </div>
                            {`${Object.values(countInfluencer).filter((val) => val > 0).length} ${window.I18N(
                                'influencer'
                            )}`}
                            <div className="mb-2">
                                {Object.entries(countInfluencer).map((data, i) => {
                                    const [key, value] = data
                                    if (value > 0) {
                                        return <li key={i} style={{ fontSize: '11px' }}>{`${key}: ${value}`}</li>
                                    }
                                })}
                            </div>
                            {`${Object.values(countUnion).filter((val) => val > 0).length} ${window.I18N('union')}`}
                            <div className="mb-2">
                                {Object.entries(countUnion).map((data, i) => {
                                    const [key, value] = data
                                    if (value > 0) {
                                        return <li key={i} style={{ fontSize: '11px' }}>{`${key}: ${value}`}</li>
                                    }
                                })}
                            </div>
                        </div>
                    </div>
                )}
            </>
        )
    }

    const transactionDataPopup = (hoverLabelIndex, indexDatasetPopup, hoverDatasetIndex) => {
        const month = dataChart.labels[hoverLabelIndex]
        const { countAgeRange, countGender, countCompany, countInfluencer, countUnion } =
            dataElearning.transaction[COURSE_TRANS_EVENT[indexDatasetPopup]][month]
        const data = {
            labels: Object.keys(countAgeRange),
            datasets: [
                {
                    label: 'Number of person',
                    backgroundColor: paletteColor,
                    borderWidth: 1,
                    barPercentage: 0.5,
                    data: Object.values(countAgeRange)
                }
            ]
        }
        return (
            <div style={{ width: '280px' }}>
                <div style={{ fontSize: '13px', fontWeight: 400 }}>
                    <Grid container>
                        {hoverDatasetIndex?.map((el, index) => (
                            <Grid
                                key={index}
                                item
                                className={`tab-control-fanpage small-content ${
                                    indexDatasetPopup === el ? 'active' : ''
                                }`}
                                onClick={() => chartRef?.current?.handleSetIndexDatasetPopup(el)}
                            >
                                {' '}
                                {COURSE_TRANS_EVENT[el]}
                            </Grid>
                        ))}
                    </Grid>
                    <div
                        style={{ width: '100%' }}
                        className="mt-1 mb-3 d-flex align-items-center justify-content-center"
                    >
                        <span
                            className="mr-2"
                            style={{
                                display: 'inline-block',
                                height: '1rem',
                                width: '1rem',
                                backgroundColor: paletteColor[indexDatasetPopup]
                            }}
                        ></span>
                        {`${window.I18N('number-of')} ${COURSE_TRANS_EVENT[indexDatasetPopup]} ${window.I18N(
                            'in-month'
                        )} ${month}: ${dataChart.datasets[indexDatasetPopup].data[hoverLabelIndex]}`}
                    </div>
                </div>
                <div className="mb-2 d-flex align-items-center justify-content-center" style={{ maxHeight: '140px' }}>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            marginRight: '10px'
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                backgroundColor: '#F1F2F8',
                                marginBottom: '10px',
                                padding: '5px 10px'
                            }}
                        >
                            <div style={{ margin: 'auto', fontSize: '15px', fontWeight: 500 }}>{countGender.male}</div>
                            <div style={{ margin: 'auto' }}>{window.I18N('male')}</div>
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                backgroundColor: '#F1F2F8',
                                padding: '5px 10px'
                            }}
                        >
                            <div style={{ margin: 'auto', fontSize: '15px', fontWeight: 500 }}>
                                {countGender.female}
                            </div>
                            <div style={{ margin: 'auto' }}>{window.I18N('female')}</div>
                        </div>
                    </div>
                    <div className="mr-2">
                        <HorizontalBar
                            width={200}
                            height={130}
                            data={data}
                            options={{
                                legend: { display: false },
                                responsive: true,
                                maintainAspectRatio: false,
                                scales: {
                                    yAxes: [
                                        {
                                            ticks: {
                                                beginAtZero: true,
                                                stepSize: 1,
                                                fontSize: 10
                                            },
                                            gridLines: {
                                                display: false
                                            }
                                        }
                                    ],
                                    xAxes: [
                                        {
                                            ticks: {
                                                beginAtZero: true,
                                                stepSize: 1,
                                                fontSize: 10
                                            }
                                        }
                                    ]
                                },
                                tooltips: {
                                    enabled: true
                                }
                            }}
                        />
                    </div>
                </div>
                {companyId === null && (
                    <div
                        style={{
                            backgroundColor: '#F1F2F8',
                            padding: '5px 15px',
                            height: '120px',
                            width: '100%',
                            overflow: 'scroll',
                            overscrollBehavior: 'contain'
                        }}
                    >
                        <div className="mb-2" style={{ fontWeight: 500 }}>
                            {`${Object.values(countCompany).filter((val) => val > 0).length} ${window.I18N('company')}`}
                            <div className="mb-2">
                                {Object.entries(countCompany).map((data, i) => {
                                    const [key, value] = data
                                    if (value > 0) {
                                        return <li key={i} style={{ fontSize: '11px' }}>{`${key}: ${value}`}</li>
                                    }
                                })}
                            </div>
                            {`${Object.values(countInfluencer).filter((val) => val > 0).length} ${window.I18N(
                                'influencer'
                            )}`}
                            <div className="mb-2">
                                {Object.entries(countInfluencer).map((data, i) => {
                                    const [key, value] = data
                                    if (value > 0) {
                                        return <li key={i} style={{ fontSize: '11px' }}>{`${key}: ${value}`}</li>
                                    }
                                })}
                            </div>
                            {`${Object.values(countUnion).filter((val) => val > 0).length} ${window.I18N('union')}`}
                            <div className="mb-2">
                                {Object.entries(countUnion).map((data, i) => {
                                    const [key, value] = data
                                    if (value > 0) {
                                        return <li key={i} style={{ fontSize: '11px' }}>{`${key}: ${value}`}</li>
                                    }
                                })}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        )
    }

    const createInnerHTMLPopup = ({ hoverLabelIndex, indexDatasetPopup, hoverDatasetIndex }) => {
        const showDataWithChartMode = {
            course: courseDataPopup,
            tracking: trackingDataPopup,
            transaction: transactionDataPopup
        }
        return showDataWithChartMode[chartMode](hoverLabelIndex, indexDatasetPopup, hoverDatasetIndex)
    }

    useEffect(() => {
        const getDataWithChartMode = {
            course: getDataInsightElearnCourse,
            tracking: getDataInsightElearnTracking,
            transaction: getDataInsightElearnTransaction
        }
        getDataWithChartMode[chartMode]()
    }, [companyId, companies, chartMode, dataDaysOfMonth])

    return (
        <div className="mt-4 mx-auto">
            <div className="mb-2 d-flex align-items-center justify-content-between">
                <h6 className="m-0 font-weight-bold" style={{ fontSize: '1rem' }}>{`E-Learning Chart Insight`}</h6>
                <div className="d-flex align-items-center justify-content-end" style={{ gap: '16px', flex: 1 }}>
                    <div className="d-flex align-items-center justify-content-center" style={{ width: '30%' }}>
                        <div className="input-group input-group-transparent">
                            <div className="input-group-prepend">
                                <span className="input-group-text">
                                    <MDBIcon fa="true" icon="calendar" className="fa-fw" />
                                </span>
                            </div>
                            <ReactSelect
                                value={dataDaysOfMonth}
                                options={dataMonthsSelect}
                                className="md-form m-0"
                                placeholder={window.I18N('choose_option')}
                                onChange={handleSelectMonths}
                            />
                        </div>
                    </div>
                    <div className="d-flex flex-row">
                        {/* Course */}
                        <button
                            className={`mode-button ${chartMode === DATA_COURSE ? 'mode-active' : ''}`}
                            onClick={() => {
                                setChartMode(DATA_COURSE)
                            }}
                            style={{ borderRadius: '4px 0 0 4px' }}
                        >
                            {window.I18N(DATA_COURSE)}
                        </button>
                        {/* Tracking */}
                        <button
                            className={`mode-button ${chartMode === DATA_TRACKING ? 'mode-active' : ''}`}
                            onClick={() => {
                                setChartMode(DATA_TRACKING)
                            }}
                        >
                            {window.I18N(DATA_TRACKING)}
                        </button>
                        {/* Transaction */}
                        <button
                            className={`mode-button ${chartMode === DATA_TRANSACTION ? 'mode-active' : ''}`}
                            onClick={() => {
                                setChartMode(DATA_TRANSACTION)
                            }}
                            style={{ borderRadius: '0 4px 4px 0' }}
                        >
                            {window.I18N(DATA_TRANSACTION)}
                        </button>
                    </div>
                </div>
            </div>
            <div className="d-flex align-items-center justify-content-center" style={{ position: 'relative' }}>
                <CustomChart
                    typeChart="Line"
                    dataChart={dataChart}
                    createInnerHTMLPopup={createInnerHTMLPopup}
                    ref={chartRef}
                    displayLegend={true}
                />
            </div>
            <div className="chart-title">{`${window.I18N('number_of')} ${window.I18N(`${chartMode}`)} ${
                dataDaysOfMonth.label
            }`}</div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    language: state.language.value
})
const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(InsightChartELearning)
