import React, { useEffect, useImperativeHandle, useRef, useState } from 'react'

import styles from './FanpageSelectWithDropdown.module.css'
import { MDBIcon } from 'mdbreact'

import FanpageGRAPHQL from '../../../../../graphql/Fanpage'
import Constant from '../../../../../utils/Constant'
import Image from '../../../../components/Image'
import useClickOutSide from '../../../../../hooks/useClickOutSide'
const PAGE_SIZE_OWNER = 9999

const FanpageSelectWithDropdown = React.forwardRef(
    ({ disabled = false, fanpageId = null, onUpdateForFirstLoad = () => {} }, ref) => {
        const [dataFanpages, setDataFanpages] = useState([])
        const [currentFanpage, setCurrentFanpage] = useState({})
        const [isDropDown, setIsDropDown] = useState(false)

        const dropdownRef = useRef()

        const handleToggleDropDown = () => {
            if (disabled) {
                return
            }
            setIsDropDown(!isDropDown)
        }

        const handleCloseDropdown = () => {
            setIsDropDown(false)
        }

        const handleChangeCurrentFanpage = (fanpage) => {
            handleCloseDropdown()
            setCurrentFanpage(fanpage)
        }

        const getFanPageByOwner = async () => {
            try {
                const paramsOwner = {
                    status: Constant.FANPAGE.ACCEPTED,
                    isOwner: true,
                    isJoin: false,
                    pageSize: PAGE_SIZE_OWNER
                }

                const result = await window.COMMON.query(
                    FanpageGRAPHQL.QUERY_FANPAGE_RELATIONSHIPS_BY_USER,
                    paramsOwner,
                    false
                )
                const fanpageOwner = result.data.getFanpageRelationships.data
                const foundCurrentFanpage = fanpageId
                    ? fanpageOwner.find((fanpage) => fanpage.fanpage._id === fanpageId)
                    : fanpageOwner[0]
                setDataFanpages(fanpageOwner)
                setCurrentFanpage(foundCurrentFanpage)
                onUpdateForFirstLoad({
                    fanpage: foundCurrentFanpage?.fanpage?._id,
                    fanpageName: foundCurrentFanpage?.fanpage?.name
                })
            } catch (error) {
                window.COMMON.showErrorLogs(`DropDownSelect.getFanPageByOwner ::: ${error}`)
            }
        }

        useEffect(() => {
            getFanPageByOwner()
        }, [])

        useImperativeHandle(ref, () => ({
            getSelectedFanpage: () => ({
                fanpage: currentFanpage?.fanpage?._id,
                fanpageName: currentFanpage?.fanpage?.name
            }),
            setDefaultCurrentFanpage: (fanpage) => {
                //TODO: delay setState when rendering component
                setTimeout(() => {
                    setCurrentFanpage(fanpage)
                }, 100)
            }
        }))

        useClickOutSide({
            ref: dropdownRef,
            handleOutside: handleCloseDropdown
        })

        return (
            <div style={{ position: 'relative' }} ref={dropdownRef}>
                <div
                    className={`${styles['selected-item']} ${disabled ? styles['selected'] : ''}`}
                    onClick={handleToggleDropDown}
                >
                    {/* Infomation container */}
                    {window.COMMON.isEmpty(dataFanpages) ? (
                        <div className={styles['info-container']}>{window.I18N('empty')}</div>
                    ) : (
                        <div className={styles['info-container']}>
                            {/* Cover image */}
                            <div className={styles['cover-img-container']}>
                                <Image src={currentFanpage?.fanpage?.image} alt="" loading="lazy" />
                            </div>

                            {/* Info */}
                            <div>
                                <h6 className={styles['name-owner']}>{currentFanpage?.fanpage?.name}</h6>
                                <span className={styles['position-owner']}>{`${window.I18N(
                                    'organizer'
                                )} - ${localStorage.getItem(Constant.NICK_NAME)}`}</span>
                            </div>
                        </div>
                    )}

                    {!disabled && (
                        <div>
                            <MDBIcon fas className={styles['dropdown-icon']} icon="caret-down" />
                        </div>
                    )}
                </div>
                <div className={`${styles['list-item']} ${isDropDown ? styles['active'] : ''}`}>
                    <h6 className={styles['name-owner']}>{window.I18N('fanpages_manage')}</h6>
                    {window.COMMON.isEmpty(dataFanpages) ? (
                        <div className="w-100 h-100 flex-center">{window.I18N('empty')}</div>
                    ) : (
                        <div className={styles['list-fanpages']}>
                            {dataFanpages.map((fanpage, index) => (
                                <div
                                    key={index}
                                    className={styles['item']}
                                    onClick={() => handleChangeCurrentFanpage(fanpage)}
                                >
                                    {/* Cover image */}
                                    <div className="d-flex align-items-center" style={{ gap: '8px' }}>
                                        <div className={styles['cover-img-container']}>
                                            <Image src={fanpage?.fanpage?.image} alt="" loading="lazy" />
                                        </div>

                                        {/* Info */}
                                        <div>
                                            <h6 className={styles['name-owner']}>{fanpage?.fanpage?.name}</h6>
                                            <span className={styles['position-owner']}>{fanpage?.fanpage?.type}</span>
                                        </div>
                                    </div>
                                    {fanpage._id === currentFanpage._id && <MDBIcon fas icon="dot-circle" />}
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </div>
        )
    }
)

export default FanpageSelectWithDropdown
