import React, { useState, useLayoutEffect } from 'react';
import { useHistory, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { MDBCardImage, MDBIcon } from 'mdbreact';

import i18n from '../../i18n/language';
import { setLanguage } from '../../redux/action';

import LanguageGRAPHQL from '../../graphql/Language';
import { endSession } from '../../tracking/Tracking';

const Header = React.memo(function Menu(props) {
  const history = useHistory();
  const [dataLanguage, setDataLanguage] = useState([]);
  const [isShow, setIsShow] = useState(true);

  useLayoutEffect(() => {
    window.addEventListener('scroll', handleScrollWindow);
    setTimeout(() => {
      getDataLanguage();
    }, 100);
    return () => {
      window.removeEventListener('scroll', handleScrollWindow);
    };
    // eslint-disable-next-line
  }, []);

  const handleScrollWindow = (e) => {
    const header = document.getElementById('navbar-header');
    if (!header) {
      return;
    }
    header.classList.remove('blur');
    header.classList.remove('shadow-blur');
    if (window.scrollY > 0) {
      header.classList.add('blur');
      header.classList.add('shadow-blur');
    }
  };

  const showHideMenu = () => {
    setIsShow(!isShow);
    const menu = document.getElementById('slide-out');
    const header = document.getElementById('navbar-header');
    const main = document.getElementById('main');
    if (!isShow) {
      menu.classList.remove('side-nav-mini');
      header.classList.remove('header-expand');
      main.classList.remove('main-expand');
    } else {
      menu.classList.add('side-nav-mini');
      header.classList.add('header-expand');
      main.classList.add('main-expand');
    }
  };

  const getDataLanguage = async () => {
    try {
      const params = {
        status: [true]
      };
      const result = await window.COMMON.query(LanguageGRAPHQL.QUERY_LANGUAGE, params, false);
      if (result && result.data) {
        const dataLanguage = result.data.getLanguages;
        const cache = localStorage.getItem(window.CONSTANT.LANGUAGE);
        if (window.COMMON.checkValue(cache)) {
          props.setLanguage(JSON.parse(cache));
          i18n.changeLanguage(JSON.parse(cache).code);
        } else {
          const language = window.COMMON.getObjectInArrs(window.CONSTANT.LANGUAGE_DEFAULT, dataLanguage, 'code');
          props.setLanguage(language);
          i18n.changeLanguage(language.code);
        }
        setDataLanguage(dataLanguage);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('Header.getDataLanguage');
    }
  };

  const changeLanguage = (item) => {
    if (item.code === props.language.code) {
      return;
    }
    props.setLanguage(item);
    i18n.changeLanguage(item.code);
    localStorage.setItem(window.CONSTANT.LANGUAGE, JSON.stringify(item));
  };

  const logout = async () => {
    endSession();
    setTimeout(() => {
      localStorage.setItem(window.CONSTANT.IS_LOGIN, false);
      localStorage.removeItem(window.CONSTANT.ID);
      localStorage.removeItem(window.CONSTANT.AVATAR);
      localStorage.removeItem(window.CONSTANT.EMAIL);
      localStorage.removeItem(window.CONSTANT.FULL_NAME);
      localStorage.removeItem(window.CONSTANT.AUTHEN_TOKEN);
      localStorage.removeItem(window.CONSTANT.COMPANY_ID);
      localStorage.removeItem(window.CONSTANT.ACCOUNT_TYPE);
      localStorage.removeItem(window.CONSTANT.DATA_ROLE);
      localStorage.removeItem(window.CONSTANT.DATA_COMPANY);
      localStorage.removeItem(window.CONSTANT.DATA_ALL_COMPANY);
      history.replace('/login');
    }, 500);
  };

  const generateBreadcrumb = (item) => {
    if (!item || !item._id) {
      return '';
    }
    if (!item.parent) {
      return <span className="active">{item.name}</span>;
    }
    return <div className="d-flex align-items-center">
      <span>{item.parent.name}</span>
      <MDBIcon fa="true" icon="chevron-right" className="fa-fw ml-2 mr-2"></MDBIcon>
      <span className="active">{item.name}</span>
    </div>;
  };

  if (typeof window === 'undefined') {
    return null;
  }
  return (
    <nav id="navbar-header" className="navbar fixed-top navbar-toggleable-md navbar-expand-lg scrolling-navbar bg-transparent double-nav wow fadeIn animated" data-wow-delay="0.5s">
      <div className="float-left">
        <NavLink exact={true} to="#" data-activates="slide-out" className="button-collapse">
          <i className="fas fa-bars"></i>
        </NavLink>
      </div>
      <div className="breadcrumb-dn d-flex align-items-center mr-auto">
        <div className="d-none d-md-block">
          <div id="btn-menu-toggle" className="btn-menu-toggle d-flex justify-content-center align-items-center mr-3" onClick={showHideMenu}>
            <MDBIcon fa="true" icon={isShow ? 'bars' : 'times'} className="fa-fw" />
          </div>
        </div>
        {generateBreadcrumb(props.breadcrumb)}
      </div>
      <ul className="nav navbar-nav nav-flex-icons align-items-center ml-auto">
        <li className="nav-item dropdown">              
          <NavLink to="#" className="nav-link" id="dropdown-language" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-boundary="viewport">
            <MDBCardImage zoom cascade waves src={window.COMMON.setValueImage(props.language.icon)} className="image-icon"/>
          </NavLink>
          <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdown-language" data-boundary="viewport">
            {
              dataLanguage.map((item, i) => <div key={i} className="dropdown-item" onClick={() => changeLanguage(item)}>
                <img src={window.COMMON.setValueImage(item.icon)} alt="" className="image-icon mr-2"></img> {item.name}
              </div>)
            }
          </div>
        </li>
        {/* <li className="nav-item dropdown">              
          <NavLink to="#" className="nav-link">
            <MDBIcon fa="true" icon="bell" className="fa-fw fa-lg"></MDBIcon>
          </NavLink>
        </li> */}
        <li className="nav-item dropdown">              
          <NavLink to="#" className="nav-link" onClick={logout}>
            <MDBIcon fa="true" icon="power-off" className="fa-fw fa-lg"></MDBIcon>
          </NavLink>
        </li>
      </ul>            
    </nav>
  );
});

const mapStateToProps = state => ({
  language: state.language.value,
  breadcrumb: state.breadcrumb.value
});
const mapDispatchToProps = { setLanguage };

export default connect(mapStateToProps, mapDispatchToProps)(Header);