import React, { useState, useCallback, useLayoutEffect } from 'react';
import { MDBRow, MDBCol, MDBInput, MDBBtn, MDBIcon } from 'mdbreact';
import { connect } from 'react-redux'
import Select from 'react-select';

import Table from '../../components/Table';
import TableTree from '../../components/TableTree';
import Tab from '../../components/Tab';
import Modal from '../../components/Modal';

import RoleGRAPHQL from '../../../graphql/Role';
import { useTracking } from 'react-tracking';
import eventName from '../../../common/events';

function RightMenuPage(props) {
  const { trackEvent } = useTracking();

  const validLangCode = () => {
    const DEFAULT_LANG_CODE = 'en'
    const langCode = props.language?.code?.toLowerCase()
    const currentLangCodes = ['en', 'vi']
    if(!currentLangCodes.includes(langCode)){
      return DEFAULT_LANG_CODE
    }else{
      return langCode
    }
  }

  const dataTabs = [
    { icon: 'fa-th', name: window.I18N('rights') },
    { icon: 'fa-th', name: window.I18N('menu') }
  ];
  const config = [
    window.COMMON.createCol(window.I18N('no'), '5%', '', '', '', 'INDEX'),
    window.COMMON.createCol(window.I18N('name'), '30%', '', '', 'name', 'TEXT'),
    window.COMMON.createCol(window.I18N('code'), '20%', '', '', 'code', 'TEXT'),
    window.COMMON.createCol(window.I18N('created_by'), '25%', '', '', 'createdBy.name', 'TEXT'),
    window.COMMON.createCol(window.I18N('created_date'), '10%', '', '', 'createdDate', 'TEXT'),
    window.COMMON.createCol(window.I18N('action'), '10%', '', '', '3', 'BUTTON')
  ];
  const configMenu = [
    window.COMMON.createCol(window.I18N('name'), '27.5%', '', '', `nameWithMultiLang.${validLangCode()}`, 'COLLAPSE'),
    window.COMMON.createCol(window.I18N('icon'), '5%', '', '', 'icon', 'ICON'),
    window.COMMON.createCol(window.I18N('code'), '12.5%', '', '', 'code', 'TEXT'),
    window.COMMON.createCol(window.I18N('action_name'), '15%', '', '', 'action', 'TEXT'),
    window.COMMON.createCol(window.I18N('created_by'), '20%', '', '', 'createdBy.name', 'TEXT'),
    window.COMMON.createCol(window.I18N('created_date'), '10%', '', '', 'createdDate', 'TEXT'),
    window.COMMON.createCol(window.I18N('action'), '10%', '', '', '3', 'BUTTON')
  ];
  const [object, setObject] = useState({ index: -1, status: {} });
  const [data, setData] = useState([]);
  const [dataMenu, setDataMenu] = useState([]);
  const [dataSave, setDataSave] = useState({ index: 0 });
  const [options, setOptions] = useState({});
  const [select, setSelect] = useState({
    rights: []
  });

  useLayoutEffect(() => {
    setTimeout(() => {
      getDataRight();
    }, 100);
    // eslint-disable-next-line
  }, []);

  const createTrackingEvent = (event) => {
    return trackEvent({
      name: event,
      createdAt: new Date().toISOString()
    });
  };

  const changeTab = (index) => {
    if (index === 0) {
      setTimeout(() => getDataRight(), 500);
    } else {
      setTimeout(() => getDataMenu(), 500);
    }
  };

  const handleChange = (event, type = null) => {
    const { name, value } = event.target
    if(!window.COMMON.validateTextInput(value)){
      return
    }
    if (type === 'nameWithMultiLang') {
      let dataSet = {}
      dataSet = dataSave.nameWithMultiLang
      dataSet[name] = value
      setDataSave((prev) => ({ ...prev, nameWithMultiLang: dataSet }))
      return
    } 
    if (type === 'number') {
      if(!window.COMMON.validateNumberInput(value)){
        return
      }
      setDataSave((prev) => ({ ...prev, [name] : value }))
    } else {
      setDataSave((prev) => ({ ...prev, [name] : value }))
    }
  };

  const handleSelect = (event, id, attr) => {
    window.COMMON.checkSelectValidation(id, event);
    select[attr] = event;
    setSelect({ ...select });
  };


  // RIGHT
  const getDataRight = async () => {
    try {
      window.resetDataTable('table-data');
      const result = await window.COMMON.query(RoleGRAPHQL.QUERY_RIGHT, null);
      if (result && result.data) {
        const data = window.COMMON.formatDate(result.data.getRights, 'createdDate');
        options.rights = window.COMMON.createDataSelect(data, '_id', 'name');
        setOptions(options);
        setData(data);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('RightMenuPage.getDataRight');
    }
    window.initDataTable('table-data');
  };

  const openRightDialog = useCallback((index) => {
    window.COMMON.showModal('#modal-right');
    object.index = index;
    const dataSave = {};
    // TODO: handle add new right
    if (index === -1) {
      dataSave.name = '';
      dataSave.code = '';
      dataSave.description = '';
    } else {
      const obj = data[index];
      dataSave._id = window.COMMON.getValueFromAttr(obj, '_id', null);
      dataSave.name = window.COMMON.getValueFromAttr(obj, 'name');
      dataSave.code = window.COMMON.getValueFromAttr(obj, 'code');
      dataSave.description = window.COMMON.getValueFromAttr(obj, 'description');
    }
    setObject(object);
    setDataSave({ ...dataSave });
  }, [object, data]);

  // eslint-disable-next-line
  const opendeleteRightDialog = useCallback((index) => {
    object.index = index;
    setObject(object);
    window.COMMON.showModal('#modal-delete');
    window.deleteMethod = deleteRight;
  });

  const saveRight = async (event) => {
    try {
      if (!window.COMMON.checkFormValidation('form-right', event)) {
        return;
      }
      const params = {
        input: {
          ...dataSave      
        }
      };
      window.COMMON.trimData(params);
      if (object.index === -1) {
        const result = await window.COMMON.mutation(RoleGRAPHQL.MUTATION_ADD_RIGHT, params);
        if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_INSERT)) {
          getDataRight();
		      createTrackingEvent(eventName.ADD_RIGHT);
        }
      } else {
        const result = await window.COMMON.mutation(RoleGRAPHQL.MUTATION_EDIT_RIGHT, params);
        if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_UPDATE)) {
          getDataRight();
		      createTrackingEvent(eventName.EDIT_RIGHT);
        }
      }
      window.COMMON.hideModal('#modal-right');
    } catch (error) {
      window.COMMON.showErrorLogs('RightMenuPage.saveRight');
    }
  };

  const deleteRight = async () => {
    try {
      const params = {
        id: data[object.index]._id
      };
      const result = await window.COMMON.mutation(RoleGRAPHQL.MUTATION_DEL_RIGHT, params);
      if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_DELETE)) {
        getDataRight();
        createTrackingEvent(eventName.DEL_RIGHT);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('RightMenuPage.deleteRight');
    }
  };

  // MENU
  const getDataMenu = async() => {
    try {
      object.status = {};
      setObject({ ...object });
      window.resetDataTableTree('table-menu');
      const result = await window.COMMON.query(RoleGRAPHQL.QUERY_MENU, null);
      if (result && result.data) {
        const data = window.COMMON.formatDate(result.data.getMenus, 'createdDate');
        for (let i = 0; i < data.length; i++) {
          data[i].idx = i;
        }
        const modifiedData = data.map(menuItem => {
          // TODO: handle add new menu
          const { name, nameWithMultiLang: { en: enName, vi: viName } } = menuItem
          return {
            ...menuItem, 
            nameWithMultiLang: { 
              en: window.COMMON.isEmpty(enName) ? name : enName, 
              vi: window.COMMON.isEmpty(viName) ? name : viName
            } 
          }
        })
        window.COMMON.createDataTableTree(modifiedData, modifiedData, 'parent._id', 1);
        setDataMenu(modifiedData);
        window.initDataTableTree('table-menu');
      }
    } catch (error) {
      window.COMMON.showErrorLogs('RightMenuPage.getDataMenu');
    }
  };

  const openAddMenuDialog = useCallback((index) => {
    window.COMMON.showModal('#modal-menu');
    let parent = null;
    if (index !== -1) {
      parent = window.COMMON.getValueFromAttr(dataMenu[index], '_id', null);
    }
    object.index = -1;
    const dataSave = {};
    dataSave.nameWithMultiLang = {
      en: '',
      vi: ''
    };
    dataSave.code = window.COMMON.generateCode('M');
    dataSave.icon = '';
    dataSave.action = '';
    dataSave.index = 0;
    dataSave.price = 0;
    dataSave.parent = parent;
    select.rights = [];
    setObject(object);
    setSelect(select);
    setDataSave({ ...dataSave });
  }, [object, select, dataMenu]);

  const openEditMenuDialog = useCallback((index) => {
    window.COMMON.showModal('#modal-menu');
    object.index = index;
    const obj = dataMenu[index];
    const dataSave = {};
    dataSave._id = window.COMMON.getValueFromAttr(obj, '_id', null);
    dataSave.nameWithMultiLang = {
      en: window.COMMON.getValueFromAttr(obj, 'nameWithMultiLang').en || window.COMMON.getValueFromAttr(obj, 'name'),
      vi: window.COMMON.getValueFromAttr(obj, 'nameWithMultiLang').vi
    }
    dataSave.code = window.COMMON.getValueFromAttr(obj, 'code');
    dataSave.icon = window.COMMON.getValueFromAttr(obj, 'icon');
    dataSave.action = window.COMMON.getValueFromAttr(obj, 'action');
    dataSave.index = window.COMMON.getValueFromAttr(obj, 'index', 0);
    dataSave.price = window.COMMON.getValueFromAttr(obj, 'price', 0);
    dataSave.parent = window.COMMON.getValueFromAttr(obj, 'parent._id', null);
    select.rights = window.COMMON.setDataMultiSelect(options.rights, window.COMMON.getValueFromAttr(obj, 'rights'), '_id');
    setObject(object);
    setSelect(select);
    setDataSave({ ...dataSave });
  }, [object, options, select, dataMenu]);

  // eslint-disable-next-line
  const openDeleteMenuDialog = useCallback((index) => {
    object.index = index;
    setObject(object);
    window.COMMON.showModal('#modal-delete');
    window.deleteMethod = deleteMenu;
  });

  const saveMenu = async (event) => {
    try {
      if (!window.COMMON.checkFormValidation('form-menu', event)) {
        return;
      }
      dataSave.rights = window.COMMON.getDataSelect(select.rights, true);
      const params = {
        input: {
          ...dataSave,
          index: +dataSave.index,
          price: +dataSave.price
        }
      };
      window.COMMON.trimData(params);
      if (object.index === -1) {
        const result = await window.COMMON.mutation(RoleGRAPHQL.MUTATION_ADD_MENU, params);
        if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_INSERT)) {
          getDataMenu();
		      createTrackingEvent(eventName.ADD_MENU);
        }
      } else {
        const result = await window.COMMON.mutation(RoleGRAPHQL.MUTATION_EDIT_MENU, params);
        if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_UPDATE)) {
          getDataMenu();
		      createTrackingEvent(eventName.EDIT_MENU);
        }
      }
      window.COMMON.hideModal('#modal-menu');
    } catch (error) {
      window.COMMON.showErrorLogs('RightMenuPage.saveMenu');
    }
  };

  const deleteMenu = async () => {
    try {
      const params = {
        id: dataMenu[object.index]._id
      };
      const result = await window.COMMON.mutation(RoleGRAPHQL.MUTATION_DEL_MENU, params);
      if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_DELETE)) {
        getDataMenu();
        createTrackingEvent(eventName.DEL_MENU);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('RightMenuPage.getDataMenu');
    }
  };

  if (typeof window === 'undefined') {
    return null;
  }
  return (
    <div className="container-row">
      <Tab data={dataTabs} id="container-data" changeTab={changeTab}>
        <div id="tab-1" className="tab-pane fade active show">
          <div className="container-btn mb-2 wow fadeInDown animated" data-wow-delay="0.5s">
            <MDBBtn color="primary" onClick={() => openRightDialog(-1)}>
              <MDBIcon fa="true" icon="plus-circle" className="fa-fw" /> {window.I18N('btn_add')}
            </MDBBtn>
          </div>
          <div className="container-table wow fadeIn animated" data-wow-delay="1s">
            <Table id="table-data" className="table" config={config} data={data} handleRowEditClick={openRightDialog} handleRowDelClick={opendeleteRightDialog}></Table>
          </div>
        </div>
        <div id="tab-2" className="tab-pane fade">
          <div className="container-btn mb-2 wow fadeInDown animated" data-wow-delay="0.5s">
            <MDBBtn color="primary" onClick={() => openAddMenuDialog(-1)}>
              <MDBIcon fa="true" icon="plus-circle" className="fa-fw" /> {window.I18N('btn_add')}
            </MDBBtn>
          </div>
          <div className="container-table wow fadeIn animated" data-wow-delay="1s">
            <TableTree id="table-menu" className="table" config={configMenu} data={dataMenu} status={object.status} itemId="_id" handleRowAddClick={openAddMenuDialog} handleRowEditClick={openEditMenuDialog} handleRowDelClick={openDeleteMenuDialog}></TableTree>
          </div>
        </div>
      </Tab>

      <Modal id="modal-menu" title={window.I18N('create_update_data')} isNotScroll={true} saveEvent={saveMenu}>
        <form id="form-menu" className="needs-validation" noValidate>
          <MDBRow>
            <MDBCol>
              {/* En name */}
              <MDBInput outline containerClass="mt-0" value={dataSave.nameWithMultiLang?.en || ''} name="en" onChange={(event) => handleChange(event, 'nameWithMultiLang')} type="text" label={window.I18N('en_name') + ' *'} maxLength="100" pattern="\S(.*\S)?" required>
                <div className="invalid-feedback">{window.I18N('MSG_CODE_029')}</div>
              </MDBInput>
              {/* Vi name */}
              <MDBInput outline containerClass="mt-0" value={dataSave.nameWithMultiLang?.vi || ''} name="vi" onChange={(event) => handleChange(event, 'nameWithMultiLang')} type="text" label={window.I18N('vi_name') + ' *'} maxLength="100" pattern="\S(.*\S)?" required>
                <div className="invalid-feedback">{window.I18N('MSG_CODE_029')}</div>
              </MDBInput>
              {/* ==================== */}
              <MDBInput outline value={dataSave.code} name="code" onChange={handleChange} type="text" label={window.I18N('code') + ' *'} maxLength="50" pattern="\S(.*\S)?" required>
                <div className="invalid-feedback">{window.I18N('MSG_CODE_029')}</div>
              </MDBInput>
              <MDBInput outline value={dataSave.action} name="action" onChange={handleChange} type="text" label={window.I18N('action_name') + ' *'} maxLength="50" pattern="\S(.*\S)?" required>
                <div className="invalid-feedback">{window.I18N('MSG_CODE_029')}</div>
              </MDBInput>
              <MDBInput outline value={dataSave.index} name="index" onChange={(e) => handleChange(e, 'number')} type="text" label={window.I18N('index') + ' *'} maxLength="50" pattern="\S(.*\S)?" required>
                <div className="invalid-feedback">{window.I18N('MSG_CODE_029')}</div>
              </MDBInput>
              <MDBInput outline value={dataSave.price} name="price" onChange={(e) => handleChange(e, 'number')} type="text" label={window.I18N('price') + ' *'} maxLength="50" required disabled={dataSave.action === "#"}>
                <div className="invalid-feedback">{window.I18N('MSG_CODE_029')}</div>
              </MDBInput>
              <MDBInput outline value={dataSave.icon} name="icon" onChange={handleChange} type="text" label={window.I18N('icon')} maxLength="50"></MDBInput>
              <Select id="select-rights" value={select.rights} options={options.rights} placeholder={window.I18N('rights') + ' *'} className="md-form mb-0" onChange={(event) => handleSelect(event, 'select-rights', 'rights')} isMulti isSearchable closeMenuOnSelect={false}/>
            </MDBCol>
          </MDBRow>
        </form>
      </Modal>

      <Modal id="modal-right" title={window.I18N('create_update_data')} saveEvent={saveRight}>
        <form id="form-right" className="needs-validation" noValidate>
          <MDBRow>
            <MDBCol>
              <MDBInput outline containerClass="mt-0" value={dataSave.name} name="name" onChange={handleChange} type="text" label={window.I18N('name') + ' *'} maxLength="100" pattern="\S(.*\S)?" required>
                <div className="invalid-feedback">{window.I18N('MSG_CODE_029')}</div>
              </MDBInput>
              <MDBInput outline value={dataSave.code} name="code" onChange={handleChange} type="text" label={window.I18N('code') + ' *'} maxLength="50" pattern="\S(.*\S)?" required>
                <div className="invalid-feedback">{window.I18N('MSG_CODE_029')}</div>
              </MDBInput>
              <MDBInput outline value={dataSave.description} type="textarea" label={window.I18N('description')}  containerClass="mb-0" name="description" onChange={handleChange} maxLength="5000" pattern="\S(.*\S)?"></MDBInput>
            </MDBCol>
          </MDBRow>
        </form>
      </Modal>
    </div>
  );
}

const mapStateToProps = (state) => ({
  language: state.language.value
})
const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(RightMenuPage);