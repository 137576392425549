import React, { useImperativeHandle, useRef } from 'react'
import { MDBRow, MDBCol, MDBIcon, MDBInput } from 'mdbreact'
import Select from 'react-select'
import DatePicker from 'react-datepicker'
const ScheduleAssessmentForm = React.forwardRef((props, ref) => {
    const {
        dataSave,
        date,
        select,
        options,
        handleChange,
        handleDatePicker,
        handleSelect,
        typeOptions,
        periodOptions
    } = props
    const formRef = useRef()
    useImperativeHandle(
        ref,
        () => {
            return {
                handleValidForm
            }
        },
        []
    )

    const handleValidForm = () => {
        const checkForm = window.COMMON.checkFormValidation('form', formRef.current)
        if (!checkForm) {
            return false
        }
        return true
    }

    return (
        <div className="container-top wow fadeInUp animated" data-wow-delay="0.5s">
            <form id="form" className="needs-validation mt-2" noValidate ref={formRef}>
                <MDBRow>
                    <MDBCol sm="6">
                        <h6 className="font-weight-bold" style={{ fontSize: '0.8rem' }}>
                            {`${window.I18N('name')} *`}
                        </h6>
                        <MDBInput
                            outline
                            containerClass="mt-0"
                            type="text"
                            name="name"
                            value={dataSave.name}
                            onChange={handleChange}
                            maxLength="100"
                            pattern="\S(.*\S)?"
                            required
                        >
                            <div className="invalid-feedback">{window.I18N('MSG_CODE_029')}</div>
                        </MDBInput>
                        <MDBRow>
                            <MDBCol>
                                <h6 className="font-weight-bold" style={{ fontSize: '0.8rem' }}>
                                    {`${window.I18N('code')} *`}
                                </h6>
                                <MDBInput
                                    outline
                                    containerClass="mt-0"
                                    type="text"
                                    name="code"
                                    value={dataSave.code}
                                    onChange={handleChange}
                                    maxLength="50"
                                    pattern="\S(.*\S)?"
                                    required
                                >
                                    <div className="invalid-feedback">{window.I18N('MSG_CODE_029')}</div>
                                </MDBInput>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol>
                                <h6 className="font-weight-bold" style={{ fontSize: '0.8rem' }}>
                                    {`${window.I18N('start_date')} *`}
                                </h6>
                                <div className="input-group input-group-transparent">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">
                                            <MDBIcon fa="true" icon="calendar-alt" className="fa-fw" />
                                        </span>
                                    </div>
                                    <DatePicker
                                        placeholderText={window.I18N('start_date') + ' *'}
                                        className="form-control"
                                        dateFormat="dd/MM/yyyy"
                                        selected={date.startDate}
                                        minDate={new Date(date.startDate)}
                                        maxDate={new Date(date.endDate)}
                                        onChange={(event) => handleDatePicker(event, 'startDate')}
                                        showYearDropdown
                                        showMonthDropdown
                                        selectsStart
                                        required
                                    />
                                </div>
                            </MDBCol>
                            <MDBCol>
                                <h6 className="font-weight-bold" style={{ fontSize: '0.8rem' }}>
                                    {`${window.I18N('end_date')} *`}
                                </h6>
                                <div className="input-group input-group-transparent">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">
                                            <MDBIcon fa="true" icon="calendar-alt" className="fa-fw" />
                                        </span>
                                    </div>
                                    <DatePicker
                                        placeholderText={window.I18N('end_date') + ' *'}
                                        className="form-control"
                                        dateFormat="dd/MM/yyyy"
                                        minDate={date.startDate}
                                        selected={date.endDate}
                                        onChange={(event) => handleDatePicker(event, 'endDate')}
                                        showYearDropdown
                                        showMonthDropdown
                                        selectsEnd
                                        required
                                    />
                                </div>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow className="mt-4">
                            <MDBCol>
                                <h6 className="font-weight-bold" style={{ fontSize: '0.8rem' }}>
                                    {`${window.I18N('description')}`}
                                </h6>
                                <MDBInput
                                    outline
                                    containerClass="m-0"
                                    type="textarea"
                                    name="description"
                                    value={dataSave.description}
                                    onChange={handleChange}
                                    maxLength="5000"
                                ></MDBInput>
                            </MDBCol>
                        </MDBRow>
                    </MDBCol>
                    <MDBCol sm="6">
                        {window.COMMON.checkRoleIsSystem() && (
                            <div>
                                <h6 className="font-weight-bold" style={{ fontSize: '0.8rem' }}>
                                    {`${window.I18N('influencer')} *`}
                                </h6>
                                <Select
                                    id="select-influencer"
                                    value={select.influencer}
                                    options={options.influencers}
                                    placeholder={window.I18N('influencer') + ' *'}
                                    className="md-form mt-0"
                                    onChange={(event) => handleSelect(event, 'select-influencer', 'influencer')}
                                    isSearchable
                                />
                            </div>
                        )}
                        <div>
                            <h6 className="font-weight-bold" style={{ fontSize: '0.8rem' }}>
                                {`${window.I18N('companies')} *`}
                            </h6>
                            <Select
                                id="select-companies"
                                value={select.companies}
                                options={options.companies}
                                placeholder={window.I18N('choose_option')}
                                className="md-form mt-0"
                                onChange={(event) => handleSelect(event, 'select-companies', 'companies')}
                                isSearchable
                                isMulti
                                closeMenuOnSelect={false}
                            />
                        </div>
                        <div>
                            <h6 className="font-weight-bold" style={{ fontSize: '0.8rem' }}>
                                {`${window.I18N('type')} *`}
                            </h6>
                            <Select
                                id="select-type"
                                className="md-form mt-0"
                                value={select.type}
                                placeholder={window.I18N('type') + ' *'}
                                options={typeOptions}
                                onChange={(event) => handleSelect(event, 'select-type', 'type')}
                                isSearchable
                            />
                        </div>
                        <div>
                            <h6 className="font-weight-bold" style={{ fontSize: '0.8rem' }}>
                                {`${window.I18N('period')} *`}
                            </h6>
                            <Select
                                id="select-period"
                                className="md-form mt-0"
                                value={select.period}
                                placeholder={window.I18N('period') + ' *'}
                                options={periodOptions}
                                onChange={(event) => handleSelect(event, 'select-period', 'period')}
                                isSearchable
                            />
                        </div>
                    </MDBCol>
                </MDBRow>
            </form>
        </div>
    )
})

export default ScheduleAssessmentForm
