import React, { useState, useCallback, useLayoutEffect } from 'react';
import { MDBInputGroup, MDBIcon } from 'mdbreact';

import Panel from '../../components/Panel';
import List from '../../components/List';
import Pagination from '../../components/Pagination';
import RadioBox from '../../components/RadioBox';
import Modal from '../../components/Modal';
import ItemPost from '../../components/ItemPost';

import PostGRAPHQL from '../../../graphql/Post';
import { useTracking } from 'react-tracking';
import eventName from '../../../common/events';

const MAX_TEXT_LENGTH = 100

export default function PostPage(props) {
  const { trackEvent } = useTracking();

  const icons = [
    {
      icon: 'trash-alt',
      color: 'red',
      code: 'DEL'
    }
  ];
  const types = [
    { value: 'DISLIKE', label: window.I18N('dislike'), icon: 'thumbs-down' },
    { value: 'LIKE', label: window.I18N('like'), icon: 'thumbs-up' },
    { value: 'COMMENT', label: window.I18N('comment'), icon: 'comments' }
  ];
  const [filter, setFilter] = useState({ text: '' });
  const [object, setObject] = useState({ index: -1, radio: 'DISLIKE', currentPage: 0 });
  const [pagination, setPagination] = useState({ pageSize: 30, currentPage: 0 });
  const [data, setData] = useState([]);
  const [dataList, setDataList] = useState([]);

  useLayoutEffect(() => {
    setTimeout(() => {
      searchData();
    }, 100);
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
    // eslint-disable-next-line
  }, []);

  const createTrackingEvent = (event) => {
    return trackEvent({
      name: event,
      createdAt: new Date().toISOString()
    });
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      document.getElementById('btn-search').click();
    }
  };

  const handleFilterChange = (event) => {
    const text = event.target.value
    if(text.length > MAX_TEXT_LENGTH) {
      return
    }
    filter.text = text
    setFilter({ ...filter });
  };

  const handleRadio = useCallback((value) => {
    object.radio = value;
    const arrs = window.COMMON.sortDescData(data, value);
    loadData(0, arrs);
    setObject({ ...object });
    // eslint-disable-next-line
  }, [object, data]);


  const searchData = async (currentPage = 0) => {
    try {
      const params = {
        text: filter.text.trim()
      };
      const result = await window.COMMON.query(PostGRAPHQL.QUERY_FIND_POST, params);
      if (result && result.data) {
        let data = window.COMMON.formatDate(result.data.findPosts, 'createdDate', 'DD-MM-YYYY HH:mm:ss');
        data.forEach(item => {
          item.LIKE = item.like.length;
          item.DISLIKE = item.dislike.length;
          item.COMMENT = item.comment.length;
        });
        data = window.COMMON.sortDescData(data, object.radio);
        setData(data);
        loadData(currentPage, data);
      } else {
        window.COMMON.showMessage('warning', 'MSG_CODE_019', window.MSG.MSG_CODE_019);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('PostPage.searchData');
    }
  };

 

  const loadData = (index, items) => {
    const dataList = [];
    for (let i = pagination.pageSize * index; i < pagination.pageSize * (index + 1); i++) {
      if (i > (items ? items.length : data.length) - 1) {
        break;
      }
      const obj = items ? items[i] : data[i];
      if (obj) {
        dataList.push(obj);
      }
    }
    pagination.currentPage = index;
    setObject((prev) => ({...prev, currentPage: index }))
    setDataList(dataList);
    setPagination({ ...pagination });
  };

  const handleReviewClick = (index) => {
    object.index = index;
    object.item = data[index];
    setObject({ ...object });
    window.COMMON.showModal('#modal-post-item');
  };

  const deleteClick = () => {
    window.COMMON.hideModal('#modal-post-item');
    object.item = null;
    setObject({ ...object });
    window.COMMON.showModal('#modal-delete');
    window.deleteMethod = deleteData;
  };

  // eslint-disable-next-line
  const clickItemList = useCallback((index, code) => {
    object.index = index;
    setObject(object);
    window.COMMON.showModal('#modal-delete');
    window.deleteMethod = deleteData;
  });

  const findItemForDelete = () => {
    const itemInDatalist = dataList[object.index]
    const itemForDelete = data.find(item => item._id === itemInDatalist._id)
    return itemForDelete._id
  }

  const deleteData = async () => {
    try {
      const params = {
        id: findItemForDelete()
      };
      const result = await window.COMMON.mutation(PostGRAPHQL.MUTATION_DEL_POST, params);
      if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_DELETE)) {
        data.splice(object.index, 1);
        if (dataList.length === 1) {
          object.currentPage--;
        }   
        searchData(object.currentPage);
        createTrackingEvent(eventName.DEL_POST);
      }
    } catch (error) {
      window.COMMON.showErrorLogs(`PostPage.deleteData ${error}`);
    }
  };

  if (typeof window === 'undefined') {
    return null;
  }
  return (
    <div className="container-row">
      <Panel id="container-data" title={window.I18N('newfeed')} icon="list-alt">
        <div className="container-top d-flex align-items-center justify-content-end wow fadeInDown animated" data-wow-delay="0.5s">
          <div className="d-inline-block mr-2">
            <RadioBox data={types} value={object.radio} className="m-0" handleChange={handleRadio}></RadioBox>
          </div>
          <MDBInputGroup type="text" containerClassName="w-30" value={filter.text} hint={window.I18N('search_content_post')} onChange={handleFilterChange} maxLength="100" pattern="\S(.*\S)?" required
            append={
              <span className="input-group-text cursor-pointer" id="btn-search" onClick={() => searchData()}>
                <MDBIcon fa="true" icon="search" className="fa-fw"></MDBIcon>
              </span>
            }
          />
        </div>
          
        <div className="container-table wow fadeIn animated" data-wow-delay="1s">
          <List data={dataList} title={window.I18N('result_list') + ' (' + data.length + ')'} isPost={true} attr_id="_id" attr_content="text" attr_text="createdBy.nickname" attr_avatar="createdBy.avatar" attr_sub="createdDate" icons={icons} clickItem={handleReviewClick} clickItemList={clickItemList}></List>
          <Pagination total={data.length} pageSize={pagination.pageSize} currentPage={pagination.currentPage} changePage={loadData}></Pagination>
        </div>
      </Panel>

      <Modal id="modal-post-item" className="modal-lg" title={window.I18N('view_details')} 
        hideSave={true} rejectIcon={'trash'} rejectBtn={window.I18N('btn_delete')} 
        hideReject={false} rejectEvent={() => deleteClick()}
        handleCloseModal={() => {
          object.item = null;
          setObject({ ...object });
        }}>
        {
          object.item ? <ItemPost item={object.item} index={object.index} key={object.item && object.item._id} isView={true} className="col-md-12 p-0" attr_id="_id" attr_content="text" attr_image="image" attr_avatar="createdBy.avatar" attr_name="createdBy.nickname" attr_time="createdDate"></ItemPost> : <></>
        }
      </Modal>
    </div>
  );
}