import React, { useState, useCallback, useLayoutEffect, useRef } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import { MDBRow, MDBCol, MDBInput, MDBBtn, MDBIcon, MDBCardImage } from 'mdbreact';

import RadioBox from './RadioBox';
import InputFile from './InputFile';
import Panel from './Panel';
import Modal from './Modal';

import MetadataGRAPHQL from '../../graphql/Metadata';
import AccountGRAPHQL from '../../graphql/Account';
import CompanyGRAPHQL from '../../graphql/Company';
import { useTracking } from 'react-tracking';
import eventName from '../../common/events';
import Constant from '../../utils/Constant';
 
const MS_DELAY_FOR_VALIDATION = 200

const {
  COMPANY,
  INFLUENCER,
  GOPY_INFLUENCER,
} = Constant;

const AddCompany = React.memo(function AddCompany(props) {
  const { trackEvent } = useTracking();
  const genders = [
    { value: 0, label: window.I18N('female'), icon: 'venus' },
    { value: 1, label: window.I18N('male'), icon: 'mars' }
  ];
  const language = useRef();
  const [object, setObject] = useState({ index: -1, organizationId: null });
  const [dataAward, setDataAward] = useState([]);
  const [dataSave, setDataSave] = useState({ gender: 0 });
  const [dataSaveAward, setDataSaveAward] = useState({});
  const [file, setFile] = useState(null);
  const [select, setSelect] = useState({});
  const [date, setDate] = useState({ date: new Date(1990, 0, 1), establishedDate: new Date() });
  const [options, setOptions] = useState({
    companyMarket: [],
    companyMainIndustry: [],
    companyMainProduct: [],
    companyCustomer: []
  });

  useLayoutEffect(() => {
    if (!props.language.code) {
      return;
    }
    language.current = props.language.code;
    if (props.role) {
      initializeForm();
    }
    // eslint-disable-next-line
  }, [props.language, props.options, props.item, props.index, props.role]);

  const getRolePackagesByOptions = (options = {}, role) => {
    return {
      [COMPANY]: options.companyPackages,
      [INFLUENCER]: options.organizationPackages,
      [GOPY_INFLUENCER]: options.gopyInfluencerPackages,
    }[role]
  }

  const initializeForm = async () => {
    const options = JSON.parse(JSON.stringify(props.options));
    const dataSave = {};
    const select = {};
    if (props.index === -1) {
      dataSave.name = '';
      dataSave.email = '';
      dataSave.avatar = '';
      dataSave.phone = '';
      dataSave.address = '';
      dataSave.taxCode = '';
      dataSave.bio = '';
      if (!props.isOrganizationRole) {
        dataSave.website = '';
        dataSave.zipcode = '';
        dataSave.organizationCode = '';
        dataSave.representative = '';
        dataSave.representativeEmail = '';
        dataSave.representativePhone = '';
        dataSave.representativePosition = '';
        if (props.role.code !== window.CONSTANT.INFLUENCER) {
          select.companyType = options.companyType && options.companyType.length > 0 ? options.companyType[0] : null;
          select.companySize = options.companySize && options.companySize.length > 0 ? options.companySize[0] : null;
          select.companyIndustry = options.companyIndustry && options.companyIndustry.length > 0 ? options.companyIndustry[0] : null;
          await getDataSubMetadata(options, options.companyIndustry[0] && options.companyIndustry[0].value);
          await getDataSubMetadataGlobal(options);
          select.markets = [];
          select.mainIndustries = [];
          select.mainProducts = [];
          select.mainCustomers = [];
        }
        
        select.package = window.COMMON.getObjectInArrs(true, getRolePackagesByOptions(options, props.role.code), 'code');
        date.establishedDate = new Date();
      } else {
        dataSave.gender = 0;
        date.birthday = new Date(1990, 0, 1);
      }
      options.state = [];
      options.city = [];
      select.nation = null;
      select.state = null;
      select.city = null;
      object.organizationId = null;
    } else {
      dataSave.name = window.COMMON.getValueFromAttr(props.item, 'name');
      dataSave.email = window.COMMON.getValueFromAttr(props.item, 'email');
      dataSave.avatar = window.COMMON.getValueFromAttr(props.item, 'avatar');
      dataSave.phone = window.COMMON.getValueFromAttr(props.item, 'phone');
      dataSave.address = window.COMMON.getValueFromAttr(props.item, 'address');
      dataSave.taxCode = window.COMMON.getValueFromAttr(props.item, 'taxCode');
      dataSave.bio = window.COMMON.getValueFromAttr(props.item, 'bio');
      if (!props.isOrganizationRole) {
        dataSave.organizationCode = window.COMMON.getValueFromAttr(props.item, 'organizationCode');
        dataSave.zipcode = window.COMMON.getValueFromAttr(props.item, 'zipcode');
        dataSave.website = window.COMMON.getValueFromAttr(props.item, 'website');
        dataSave.representative = window.COMMON.getValueFromAttr(props.item, 'representative');
        dataSave.representativeEmail = window.COMMON.getValueFromAttr(props.item, 'representativeEmail');
        dataSave.representativePhone = window.COMMON.getValueFromAttr(props.item, 'representativePhone');
        dataSave.representativePosition = window.COMMON.getValueFromAttr(props.item, 'representativePosition');
        if (props.role.code !== window.CONSTANT.INFLUENCER) {
          select.companyType = window.COMMON.setDataSelect(options.companyType, window.COMMON.getValueIdFromAttr(props.item.companyType, '_id'));
          select.companySize = window.COMMON.setDataSelect(options.companySize, window.COMMON.getValueIdFromAttr(props.item.companySize, '_id'));
          select.companyIndustry = window.COMMON.setDataSelect(options.companyIndustry, window.COMMON.getValueIdFromAttr(props.item.companyIndustry, '_id'));
          await getDataSubMetadata(options, window.COMMON.getValueIdFromAttr(props.item.companyIndustry, '_id'));
          await getDataSubMetadataGlobal(options);
          select.markets = window.COMMON.setDataMultiSelect(options.companyMarket, props.item.markets, '_id');
          select.mainIndustries = window.COMMON.setDataMultiSelect(options.companyMainIndustry, props.item.mainIndustries, '_id');
          select.mainProducts = window.COMMON.setDataMultiSelect(options.companyMainProduct, props.item.mainProducts, '_id');
          select.mainCustomers = window.COMMON.setDataMultiSelect(options.companyCustomer, props.item.mainCustomers, '_id');
        }

        select.package = window.COMMON.getObjectInArrs(props.item?.package?._id, getRolePackagesByOptions(options, props.role.code), 'value');
        date.establishedDate = window.COMMON_DATE.convertStringToDate(window.COMMON.getValueFromAttr(props.item, 'establishedDate'), '/');
      } else {
        dataSave.gender = window.COMMON.getValueFromAttr(props.item, 'gender');
        date.birthday = window.COMMON_DATE.convertStringToDate(window.COMMON.getValueFromAttr(props.item, 'birthday'), '/');
      }
      select.nation = window.COMMON.setDataSelect(options.nation, window.COMMON.getValueFromAttr(props.item, 'nation._id'));
      if (select.nation) {
        options.state = await window.COMMON.getDataLocation(select.nation.code);
        select.state = window.COMMON.setDataSelect(options.state, window.COMMON.getValueFromAttr(props.item, 'state._id'));
        if (select.state) {
          options.city = await window.COMMON.getDataLocation(select.state.code);
          select.city = window.COMMON.setDataSelect(options.city, window.COMMON.getValueFromAttr(props.item, 'city._id'));
        }
      }
      if (props.item.accountType === window.CONSTANT.COMPANY) {
        object.organizationId = window.COMMON.getValueFromAttr(props.item, 'company._id');
      } else if (props.item.accountType === window.CONSTANT.UNION) {
        object.organizationId = window.COMMON.getValueFromAttr(props.item, 'union._id');
      } else if (props.item.accountType === window.CONSTANT.INFLUENCER) {
        object.organizationId = window.COMMON.getValueFromAttr(props.item, 'influencer._id');
      }
      getDataAward(object.organizationId);
    }
    setObject(object);
    setFile(null);
    setOptions(options);
    setSelect(select);
    setDate(date);
    setDataSave({ ...dataSave });
    document.getElementById('file-avatar').value = null;
  };

  const createTrackingEvent = (event) => {
    return trackEvent({
      name: event,
      createdAt: new Date().toISOString()
    });
  };

  const getDataSubMetadata = async (options, companyIndustry) => {
    try {
      const params = {
        type: ['COMPANY_MAIN_INDUSTRY', 'COMPANY_MAIN_PRODUCT'],
        parent: companyIndustry
      };
      const result = await window.COMMON.query(MetadataGRAPHQL.QUERY_SHORT_SUB_METADATA, params, true);
      if (result && result.data) {
        const data = result.data.getSubMetadatas;
        data.forEach(item => {
          item.valueLanguage = window.COMMON.getValueWithLanguage(item, 'value', language.current);
        });
        const dataCompanyMainIndustry = window.COMMON.getArrsInArrs('COMPANY_MAIN_INDUSTRY', data, 'type');
        const dataCompanyMainProduct = window.COMMON.getArrsInArrs('COMPANY_MAIN_PRODUCT', data, 'type');
        options.companyMainIndustry = window.COMMON.createDataSelect(dataCompanyMainIndustry, '_id', 'valueLanguage');
        options.companyMainProduct = window.COMMON.createDataSelect(dataCompanyMainProduct, '_id', 'valueLanguage');
      }
    } catch (error) {
      window.COMMON.showErrorLogs('AddCompany.getDataSubMetadata');
    }
  };

  const getDataSubMetadataGlobal = async (options) => {
    try {
      const params = {
        type: ['COMPANY_MARKERT', 'COMPANY_CUSTOMER'],
        parent: null
      };
      const result = await window.COMMON.query(MetadataGRAPHQL.QUERY_SHORT_SUB_METADATA, params, true);
      if (result && result.data) {
        const data = result.data.getSubMetadatas;
        data.forEach(item => {
          item.valueLanguage = window.COMMON.getValueWithLanguage(item, 'value', language.current);
        });
        const dataCompanyMarket = window.COMMON.getArrsInArrs('COMPANY_MARKERT', data, 'type');
        const dataCompanyCustomer = window.COMMON.getArrsInArrs('COMPANY_CUSTOMER', data, 'type');
        options.companyMarket = window.COMMON.createDataSelect(dataCompanyMarket, '_id', 'valueLanguage');
        options.companyCustomer = window.COMMON.createDataSelect(dataCompanyCustomer, '_id', 'valueLanguage');
      }
    } catch (error) {
      window.COMMON.showErrorLogs('AddCompany.getDataSubMetadataGlobal');
    }
  };

  const handleChange = (event) => {
    const { value, name, type } = event.target
    if(value.startsWith(' ') && type === 'email') {
      return
    }
    setDataSave((prev) => ({ ...prev, [name]: value }))
    
  };

  const handlePhoneInput = (event) => {
    const { value, name } = event.target
    if(/^-?\d*\.?\d*$/.test(value) && name === 'phone' && !value.includes('-')) {
      setDataSave((prev) => ({ ...prev, [name]: value }))
    }
  }

  const handleSelect = async (event, id, attr) => {
    window.COMMON.checkSelectValidation(id, event);
    select[attr] = event;
    if (attr === 'nation' || attr === 'state') {
      getDataLocationOptions(attr, event.code);
    } else {
      if (attr === 'companyIndustry') {
        select.mainIndustries = [];
        select.mainProducts = [];
        await getDataSubMetadata(options, select.companyIndustry && select.companyIndustry.value);
      }
      setSelect({ ...select }); 
    }
  };

  const handleDatePicker = (event, attr) => {
    if(event === null){
      return
    }
    setDate((prev) => ({ ...prev, [attr]: event }));
  };

  const handleRadio = useCallback((value) => {
    dataSave.gender = value;
    setDataSave({ ...dataSave });
  }, [dataSave]);

  const handleFile = (file) => {
    dataSave.avatar = window.COMMON.createBlobImage(file);
    setFile(file);
    setDataSave({ ...dataSave });
  };

  const getDataLocationOptions = async (attr, parent) => {
    if (attr === '-1') {
      options.nation = await window.COMMON.getDataLocation(parent);
      options.state = [];
      options.city = [];
      select.nation = null;
      select.state = null;
      select.city = null;
    } else if (attr === 'nation') {
      options.state = await window.COMMON.getDataLocation(parent);
      options.city = [];
      select.state = null;
      select.city = null;
    } else if (attr === 'state') {
      options.city = await window.COMMON.getDataLocation(parent);
      select.city = null;
    }
    setOptions(options);
    setSelect({ ...select });
  };

  const openSaveCompanyDialog = (event) => {
    //Trim data before validating
    window.COMMON.trimData(dataSave)
    setDataSave({...dataSave})
    // Run validation after trim data successfully, using setTimeout function with MS_DELAY_FOR_VALIDATION delay 
    setTimeout(() => {
      const checkForm = window.COMMON.checkFormValidation('form', event);
      const checkNation = window.COMMON.checkSelectValidation('select-nation', select.nation);
      const checkCity = window.COMMON.checkSelectValidation('select-city', select.city);
      const checkState = window.COMMON.checkSelectValidation('select-state', select.state);
      if (props.role.code === window.CONSTANT.COMPANY || props.role.code === window.CONSTANT.UNION) {
        const checkType = window.COMMON.checkSelectValidation('select-company-type', select.companyType);
        const checkSize = window.COMMON.checkSelectValidation('select-company-size', select.companySize);
        const checkIndustry = window.COMMON.checkSelectValidation('select-company-industry', select.companyIndustry);
        if (!checkForm || !checkNation || !checkCity || !checkState || !checkType || !checkSize || !checkIndustry) {
          return;
        }
      } else {
        if (!checkForm || !checkNation || !checkCity || !checkState) {
          return;
        }
      }
    
      window.COMMON.showModal('#modal-save');
      if (!object.isOrganizationRole) {
        getLatAndLng();
      }
      window.saveMethod = saveData;
    }, MS_DELAY_FOR_VALIDATION)
  };

  const getLatAndLng = () => {
    const address = dataSave.address + ',' + select.city.label + ',' + select.state.label + ',' + select.nation.label;
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode( { address}, function(results, status) {
      if (status == window.google.maps.GeocoderStatus.OK) {
        const latitude = results[0].geometry.location.lat();
        const longitude = results[0].geometry.location.lng();
        dataSave.lat = latitude;
        dataSave.lng = longitude;
        setDataSave({ ...dataSave });
      } 
    }); 
  };

  const saveData = async () => {
    let dataFiles = [];
    try {
      const keys = Object.keys(select);
      for (let i = 0; i < keys.length; i++) {
        const element = keys[i];
        const check = element === 'markets' || element === 'mainIndustries' || element === 'mainProducts' || element === 'mainCustomers';
        dataSave[element] = window.COMMON.getDataSelect(select[element], check);
      }
      // TODO upload file
      if (file) {
        dataFiles = await window.uploadFile([file]);
        if (dataFiles.length > 0) {
          dataSave.avatar = window.COMMON.getNewPathFile(dataFiles[0]._id);
        }
      }
      setFile(null);
      // TODO save data
      const params = {
        input: {
          role: props.role.value,
          accountType: props.role.code
        }
      };
      if (props.isOrganizationRole) {
        params.input.individual = dataSave;
        params.input.individual.birthday = date.birthday.toString();
        params.input.individual.email = dataSave.email.toLowerCase();
      } else {
        params.input.company = dataSave;
        params.input.company.email = dataSave.email.toLowerCase();
        params.input.company.representativeEmail = dataSave.representativeEmail?.toLowerCase();
        params.input.company.establishedDate = date.establishedDate.toString();
      }
      window.COMMON.trimData(params);
      if (props.index === -1) {
        const result = await window.COMMON.mutation(AccountGRAPHQL.MUTATION_ADD_ACCOUNT, params);
        if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_INSERT)) {
          if (props.callback) {
            props.callback();
            window.COMMON.backContainerData();
          }
		      createTrackingEvent(eventName.ADD_ACCOUNT);
        } 
      } else {
        params.input._id = props.item._id;
        const result = await window.COMMON.mutation(AccountGRAPHQL.MUTATION_EDIT_ACCOUNT, params);
        let resultSetPackage = null;
        const paramPackage = { 
          input: {
            // update using ids for gopy_influencer || organizationIds for company, influencer.
            ...props.item.accountType === GOPY_INFLUENCER ? {
              ids: [props.item._id],
            } : {
              organizationIds: [props.item.company ? props.item.company._id : props.item.influencer._id],
            },
            packageId: params.input.company.package 
          } 
        };
        resultSetPackage = await window.COMMON.mutation(AccountGRAPHQL.MUTATION_SET_PACKAGE, paramPackage);
          
        if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_UPDATE)) {
          if (resultSetPackage && window.COMMON.checkResultData(resultSetPackage, window.CONSTANT.MODE_UPDATE)) {
            createTrackingEvent(eventName.SET_PACKAGE);
          }
          if (props.callback) {
            props.callback();
            window.COMMON.backContainerData();
          }
          createTrackingEvent(eventName.EDIT_ACCOUNT);
        }
      }
    } catch (error) {
      window.UPLOAD_API.deleteMultiFile(dataFiles.map(item => item._id))           
      window.COMMON.showErrorLogs('AddCompany.saveData');
    }
  };

  const updateStatus = async () => {
    try {
      const params = {
        id: props.item._id,
        status: !props.item.status
      };
      let result = null; 
      if (!props.isOrganizationRole) {
        result = await window.COMMON.mutation(AccountGRAPHQL.MUTATION_UPDATE_STATUS_COMPANY, params);
      } else {
        result = await window.COMMON.mutation(AccountGRAPHQL.MUTATION_UPDATE_STATUS_ACCOUNT, params);
      }
      if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_STATUS)) {
        if (props.callback) {
          props.callback();
          window.COMMON.backContainerData();
        }
        createTrackingEvent(eventName.UPDATE_STATUS_ACCOUNT);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('AddCompany.updateStatus');
    }
  };

  // AWARD
  const handleChangeAward = (event) => {
    dataSaveAward[event.target.name] = event.target.value;
    setDataSaveAward({ ...dataSaveAward });
  };

  const handleFileAward = (file) => {
    dataSaveAward.file = file;
    setDataSaveAward({ ...dataSaveAward });
  };

  const getDataAward = async (id) => {
    try {
      const params = {
        company: id
      };
      const result = await window.COMMON.query(CompanyGRAPHQL.QUERY_BUSINESS_AWARD_COMPANY, params);
      if (result && result.data) {
        const data = window.COMMON.formatMultiDate(result.data.getBusinessAwardByCompany, 'createdDate,receivedDate');
        setDataAward(data);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('AddCompany.getDataAward');
    }
  };

  const openAwardDialog = useCallback((index) => {
    window.COMMON.showModal('#modal-award');
    object.index = index;
    const dataSaveAward = { file: null };
    dataSaveAward.name = '';
    dataSaveAward.organization = '';
    dataSaveAward.description = '';
    dataSaveAward.image = '';
    date.receivedDate = new Date();
    document.getElementById('file-award').value = null;
    setObject(object);
    setDataSaveAward({ ...dataSaveAward });
  }, [date, object, dataSaveAward]);

  // eslint-disable-next-line
  const openDeleteAwardDialog = useCallback((index) => {
    object.index = index;
    setObject(object);
    window.COMMON.showModal('#modal-delete');
    window.deleteMethod = deleteAward;
  });

  const saveAward = async (event) => {
    let dataFiles = [];
    try {
      if (!window.COMMON.checkFormValidation('form-award', event)) {
        return;
      }
      // TODO upload file
      if (dataSaveAward.file) {
        dataFiles = await window.uploadFile([dataSaveAward.file]);
        if (dataFiles.length > 0) {
          dataSaveAward.image = window.COMMON.getNewPathFile(dataFiles[0]._id);
        }
      }
      delete dataSaveAward.file;
      dataSaveAward.receivedDate = date.receivedDate.toString();
      dataSaveAward.company = object.organizationId;
      // TODO save data
      const params = {
        input: dataSaveAward
      };
      window.COMMON.trimData(params);
      if (object.index === -1) {
        const result = await window.COMMON.mutation(CompanyGRAPHQL.MUTATION_ADD_BUSINESS_AWARD, params);
        if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_INSERT)) {
          getDataAward(object.organizationId);
          createTrackingEvent(eventName.ADD_BUSINESS_AWARD);
        }
      } else {
        const result = await window.COMMON.mutation(CompanyGRAPHQL.MUTATION_EDIT_BUSINESS_AWARD, params);
        if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_UPDATE)) {
          getDataAward(object.organizationId);
          createTrackingEvent(eventName.EDIT_BUSINESS_AWARD);
        }
      }
      window.COMMON.hideModal('#modal-award');
    } catch (error) {
      window.UPLOAD_API.deleteMultiFile(dataFiles.map(item => item._id))      
      window.COMMON.showErrorLogs('AddCompany.saveAward');
    }
  };

  const deleteAward = async () => {
    try {
      const params = {
        id: dataAward[object.index]._id
      };
      const result = await window.COMMON.mutation(CompanyGRAPHQL.MUTATION_DEL_BUSINESS_AWARD, params);
      if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_DELETE)) {
        getDataAward(object.organizationId);
        createTrackingEvent(eventName.DEL_BUSINESS_AWARD);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('AddCompany.deleteAward');
    }
  };

  if (typeof window === 'undefined') {
    return null;
  }
  return <><Panel id="container-save" title={window.I18N('create_update_data')} icon="file" style={{ display: 'none' }}>
    <div className="container-btn wow fadeInDown animated" data-wow-delay="0.5s">
      {
        props.index !== window.CONSTANT.VALUE_PROFILE ? <MDBBtn color="" onClick={window.COMMON.backContainerData}>
          <MDBIcon fa="true" icon="arrow-left" className="fa-fw" /> {window.I18N('btn_back')}
        </MDBBtn> : <></>
      }
      {
        props.index > -1 && props.index !== window.CONSTANT.VALUE_PROFILE ? (
          props.item && !props.item.status ? <MDBBtn color="success" type="button" data-toggle="modal" data-backdrop="static" data-target="#modal-save" onClick={() => { window.saveMethod = updateStatus; }}>
            <MDBIcon fa="true" icon="check" className="fa-fw" /> {window.I18N('btn_active')}
          </MDBBtn> : <MDBBtn color="danger" type="button" data-toggle="modal" data-backdrop="static" data-target="#modal-save" onClick={() => { window.saveMethod = updateStatus; }}>
            <MDBIcon fa="true" icon="ban" className="fa-fw" /> {window.I18N('btn_inactive')}
          </MDBBtn>
        ) : <></>
      }
      <MDBBtn color="primary" type="button" onClick={openSaveCompanyDialog}>
        <MDBIcon fa="true" icon="save" className="fa-fw" /> {window.I18N('btn_save')}
      </MDBBtn>
    </div>
    <div className="container-table wow fadeIn animated" data-wow-delay="1s">
      <form id="form" className="needs-validation">
        <MDBRow>
          <MDBCol sm="6">
            <div className="w-100">
              <h6 className="mb-0"><small className="font-weight-bold black-text">{window.I18N('account_information')}</small></h6>
              {
                props.index !== window.CONSTANT.VALUE_PROFILE ? <MDBInput outline className='text-lowercase' containerClass="mt-3" value={dataSave.email} name="email" onChange={handleChange} type="email" label={window.I18N('email') + ' *'} maxLength="200" pattern="[^\s@]+@[^\s@]+\.[^\s@]+" disabled={props.index === window.CONSTANT.VALUE_PROFILE} required>
                  <div className="invalid-feedback">{window.I18N('MSG_CODE_030')}</div>
                </MDBInput> : <MDBInput outline className='text-lowercase' containerClass="mt-3" value={dataSave.email} name="email" type="email" label={window.I18N('email')} pattern="[^\s@]+@[^\s@]+\.[^\s@]+" required disabled={props.index === window.CONSTANT.VALUE_PROFILE}></MDBInput>
              }
              <MDBInput outline value={dataSave.name} name="name" onChange={handleChange} type="text" label={(props.isOrganizationRole ? window.I18N('full_name') : window.I18N('organization_name'))  + ' *'} maxLength="200" pattern="\S(.*\S)?" required>
                <div className="invalid-feedback">{window.I18N('MSG_CODE_029')}</div>
              </MDBInput>
              {
                props.index !== window.CONSTANT.VALUE_PROFILE ? <MDBInput outline value={dataSave.phone} name="phone" onChange={handlePhoneInput} type="text" label={window.I18N('phone_number') + ' *'} required min="0">
                  <div className="invalid-feedback">{window.I18N('MSG_CODE_029')}</div>
                </MDBInput> : <MDBInput outline value={dataSave.phone} name="phone" type="number" className="input-number" label={window.I18N('phone_number')} disabled={props.index === window.CONSTANT.VALUE_PROFILE} min="0"></MDBInput>
              }
              {
                props.isOrganizationRole ? <MDBRow>
                  <MDBCol md="6">
                    <RadioBox data={genders} value={dataSave.gender} handleChange={handleRadio}></RadioBox>
                  </MDBCol>
                  <MDBCol md="6">
                    <div className="input-group input-group-transparent">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <MDBIcon fa="true" icon="calendar-alt" className="fa-fw" />
                        </span>
                      </div>
                      <DatePicker calendarClassName="12" placeholderText={window.I18N('birthday')} className="form-control" dateFormat="dd/MM/yyyy" selected={date.birthday} onChange={(event) => handleDatePicker(event, 'birthday')} showYearDropdown showMonthDropdown/>
                    </div>
                  </MDBCol>
                </MDBRow> : <></>
              }
              <div className="text-center mt-3">
                <MDBCardImage className="image-logo" src={window.COMMON.setValueImage(dataSave.avatar)} hover overlay="white-slight"/><br></br>
                <InputFile id="file-avatar" icon="upload" handleFile={handleFile} accept="image/*" description={`${window.I18N('image')} (120 x 120) pixels`}></InputFile>
              </div>
              {
                props.role && (props.role.code !== window.CONSTANT.COMPANY && props.role.code !== window.CONSTANT.UNION) ? <div></div> : <div className="w-100 mt-4">
                  <h6 className="mb-0"><small className="font-weight-bold black-text">{window.I18N('organization_information')}</small></h6>
                  <Select id="select-company-type" value={select.companyType} options={options.companyType} placeholder={window.I18N('organization_type') + ' *'} className="md-form mt-3" onChange={(event) => handleSelect(event, 'select-company-type', 'companyType')} isSearchable/>
                  <Select id="select-company-size" value={select.companySize} options={options.companySize} placeholder={window.I18N('headcounts') + ' *'} className="md-form" onChange={(event) => handleSelect(event, 'select-company-size', 'companySize')} isSearchable/>
                  <Select id="select-company-industry" value={select.companyIndustry} options={options.companyIndustry} placeholder={window.I18N('industry') + ' *'} className="md-form" onChange={(event) => handleSelect(event, 'select-company-industry', 'companyIndustry')} isSearchable/>
                  <DatePicker calendarClassName="12" placeholderText={window.I18N('established_date')} className="form-control" dateFormat="dd/MM/yyyy" selected={date.establishedDate} onChange={(event) => handleDatePicker(event, 'establishedDate')} showYearDropdown showMonthDropdown/>
                  <Select id="select-company-main-industry" value={select.mainIndustries} options={options.companyMainIndustry} placeholder={window.I18N('main_industry')} className="md-form" onChange={(event) => handleSelect(event, 'select-company-main-industry', 'mainIndustries')} isMulti isSearchable closeMenuOnSelect={false}/>
                  <Select id="select-company-main-product" value={select.mainProducts} options={options.companyMainProduct} placeholder={window.I18N('main_product')} className="md-form" onChange={(event) => handleSelect(event, 'select-company-main-product', 'mainProducts')} isMulti isSearchable closeMenuOnSelect={false}/>
                  <Select id="select-company-customer" value={select.mainCustomers} options={options.companyCustomer} placeholder={window.I18N('customers')} className="md-form" onChange={(event) => handleSelect(event, 'select-company-customer', 'mainCustomers')} isMulti isSearchable closeMenuOnSelect={false}/>
                  <Select id="select-company-market" value={select.markets} options={options.companyMarket} placeholder={window.I18N('market')} className="md-form" onChange={(event) => handleSelect(event, 'select-company-market', 'markets')} isMulti isSearchable closeMenuOnSelect={false}/>
                </div>
              }
              {
                props.role && props.role.code !== window.CONSTANT.INFLUENCER ? <div></div> : <div className="w-100 mt-4">
                  <h6 className="mb-0"><small className="font-weight-bold black-text">{window.I18N('organization_information')}</small></h6>
                  <DatePicker calendarClassName="12" placeholderText={window.I18N('established_date')} className="form-control mt-3 mb-3" dateFormat="dd/MM/yyyy" selected={date.establishedDate} onChange={(event) => handleDatePicker(event, 'establishedDate')} showYearDropdown showMonthDropdown/>
                </div>
              }
              {
                props.index > -1 && props.role && !props.isOrganizationRole ? <div>
                  <span className="mr-2">{ window.I18N('rating') }:</span>
                  <MDBBtn color={window.COMMON.checkStar(window.COMMON.getValueFromAttr(props.item, props.role.code.toLowerCase() + '.averageRating', 0))} className="btn-sm m-0">
                    <strong className="mr-1">{window.COMMON.getValueFromAttr(props.item, props.role.code.toLowerCase() + '.averageRating', 0)}</strong>
                    <MDBIcon fa="true" icon="star" className="fa-fw"></MDBIcon>
                  </MDBBtn>
                </div> : <div></div>
              }
              {
                props.index > -1 && props.role && !props.isOrganizationRole ? <div className="w-100 mt-3 mb-3">
                  <div className="mb-0 d-flex align-items-center w-100">
                    <p className="font-weight-bold black-text mb-0">{window.I18N('business_award')}</p>
                    <MDBIcon fa="true" icon="plus" className="fa-fw ml-auto cursor-pointer" onClick={() => openAwardDialog(-1)}></MDBIcon>
                  </div>
                  {
                    dataAward.map((item, i) => 
                      <div key={i} className="d-flex align-items-center w-100 border-bottom pt-3 pb-3">
                        <span className="mr-1">{ i + 1 }.</span>
                        <span>{ item.name }</span>
                        <MDBIcon fa="true" icon="download" className="fa-fw ml-auto mr-2" onClick={() => window.COMMON.downloadFileWithUrl(item.image, item.name)}></MDBIcon>
                        <MDBIcon fa="true" icon="trash" className="fa-fw text-danger cursor-pointer" onClick={() => openDeleteAwardDialog(i)}></MDBIcon>
                      </div>
                    )
                  }
                </div> : <div></div>
              }
            </div>
          </MDBCol>
          <MDBCol sm="6">
            <div className="w-100">
              <h6 className="mb-0"><small className="font-weight-bold black-text">{window.I18N('general_information')}</small></h6>
              {/* the select chon package */}
              {props.role && !props.isProfile && [COMPANY, INFLUENCER, GOPY_INFLUENCER].includes(props.role.code) ?
                <Select id="select-package"
                  value={select.package}
                  options={getRolePackagesByOptions(options, props.role.code)}
                  placeholder='Package'
                  className="md-form mt-3"
                  onChange={(event) => handleSelect(event, 'select-package', 'package')}
                  isSearchable
                /> :
                null
              }
              <MDBInput outline containerClass="mt-3" value={dataSave.address} name="address" onChange={handleChange} type="text" label={window.I18N('address_number') + ' *'} maxLength="200" pattern="\S(.*\S)?" required>
                <div className="invalid-feedback">{window.I18N('MSG_CODE_029')}</div>
              </MDBInput>
              <Select id="select-nation" value={select.nation} options={options.nation} placeholder={window.I18N('country') + ' *'} className="md-form" onChange={(event) => handleSelect(event, 'select-nation', 'nation')} isSearchable/>
              <Select id="select-state" value={select.state} options={options.state} placeholder={window.I18N('state_city') + ' *'} className="md-form" onChange={(event) => handleSelect(event, 'select-state', 'state')} isSearchable/>
              <Select id="select-city" value={select.city} options={options.city} placeholder={window.I18N('city_district') + ' *'} className="md-form" onChange={(event) => handleSelect(event, 'select-city', 'city')} isSearchable/>
              <MDBInput outline autoComplete="off" type="number" className="input-number" label={window.I18N('zip_code')} name="zipcode" value={dataSave.zipcode} onChange={handleChange} maxLength="20"></MDBInput>
              <MDBInput outline type="text" label={window.I18N('tax_id')} name="taxCode" value={dataSave.taxCode} onChange={handleChange} maxLength="20">
                <div className="invalid-feedback">{window.I18N('MSG_CODE_029')}</div>
              </MDBInput>
              {
                props.isOrganizationRole ? <div></div> : <MDBInput outline type="text" label={window.I18N('goverment_issued_id') + ' *'} name="organizationCode" value={dataSave.organizationCode} onChange={handleChange} maxLength="20" required></MDBInput>
              }
              <MDBInput outline containerClass="mb-0" type="textarea" name="bio" label={window.I18N('bio')} value={dataSave.bio} onChange={handleChange} maxLength="5000"></MDBInput>
              {
                props.isOrganizationRole ? <div></div> : <div className="w-100 mt-4">
                  <h6 className="mb-0"><small className="font-weight-bold black-text">{window.I18N('representative_information')}</small></h6>
                  <MDBInput outline containerClass="mt-3" autoComplete="off" value={dataSave.representative} name="representative" onChange={handleChange} type="text" label={window.I18N('representative_name')} maxLength="100"></MDBInput>
                  <MDBInput outline autoComplete="off" value={dataSave.representativePosition} name="representativePosition" onChange={handleChange} type="text" label={window.I18N('job_title')} maxLength="100" pattern="\S(.*\S)?"></MDBInput>
                  <MDBInput outline autoComplete="off" value={dataSave.representativePhone} name="representativePhone" onChange={handlePhoneInput} type="number" className="input-number" label={window.I18N('phone_number')} maxLength="20" pattern="\S(.*\S)?"></MDBInput>
                  <MDBInput outline autoComplete="off" value={dataSave.representativeEmail} name="representativeEmail" onChange={handleChange} className='text-lowercase' type="email" label={window.I18N('work_email_address')} maxLength="200"></MDBInput>
                  <MDBInput outline autoComplete="off" containerClass="mb-0" value={dataSave.website} name="website" onChange={handleChange} type="text" label={window.I18N('website')} maxLength="200"></MDBInput>
                </div>
              }
            </div>
          </MDBCol>
        </MDBRow>
      </form>
    </div>
  </Panel>

  <Modal id="modal-award" className="modal-lg" title={window.I18N('create_update_data')} saveEvent={saveAward}>
    <form id="form-award" className="needs-validation" noValidate>
      <MDBRow>
        <MDBCol>
          <MDBInput outline containerClass="mt-0" value={dataSaveAward.name} name="name" onChange={handleChangeAward} type="text" label={window.I18N('name') + ' *'} maxLength="200" pattern="\S(.*\S)?" required>
            <div className="invalid-feedback">{window.I18N('MSG_CODE_029')}</div>
          </MDBInput>
          <MDBInput outline containerClass="mt-0" value={dataSaveAward.organization} name="organization" onChange={handleChangeAward} type="text" label={window.I18N('organization') + ' *'} maxLength="200" pattern="\S(.*\S)?" required>
            <div className="invalid-feedback">{window.I18N('MSG_CODE_029')}</div>
          </MDBInput>
          <DatePicker calendarClassName="12" placeholderText={window.I18N('received_date')} className="form-control" dateFormat="dd/MM/yyyy" selected={date.receivedDate} onChange={(event) => handleDatePicker(event, 'receivedDate')} showYearDropdown showMonthDropdown/>
          <MDBInput outline type="textarea" label={window.I18N('description')} name="description" value={dataSaveAward.description} onChange={handleChangeAward} maxLength="5000" pattern="\S(.*\S)?"></MDBInput>
          <div className="text-center">
            {
              dataSaveAward.file ? <div className="d-flex align-items-center justify-content-center">
                <MDBCardImage zoom cascade waves src={window.COMMON.getFileType(dataSaveAward.file.name.split('.').pop())} className="image-icon mr-2"/>
                <small className="text-muted mr-2">{dataSaveAward.file.name}</small>
                <small>{window.COMMON.bytesToSize(dataSaveAward.file.size)}</small>
              </div> : <></>
            }
            <InputFile id="file-award" icon="upload" handleFile={handleFileAward}></InputFile>
          </div>
        </MDBCol>
      </MDBRow>
    </form>
  </Modal>
  </>;
});

const mapStateToProps = state => ({
  language: state.language.value
});
const mapDispatchToProps = {};
  
export default connect(mapStateToProps, mapDispatchToProps)(AddCompany);