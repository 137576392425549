import React, { useRef, useState, useCallback, useLayoutEffect } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { MDBRow, MDBCol, MDBInputGroup, MDBInput, MDBCardImage, MDBBtn, MDBIcon } from 'mdbreact';
import { Fab, Slider, Tooltip } from '@material-ui/core';
import { Add } from '@material-ui/icons';

import Panel from '../../components/Panel';
import ImportExcel from '../../components/ImportExcel';
import ExportExcel from '../../components/ExportExcel';
import ListOption from '../../components/ListOption';
import InputFile from '../../components/InputFile';
import Modal from '../../components/Modal';
import Table from '../../components/Table';
import ItemFormQuestion from '../../components/ItemFormQuestion';
import ItemPreviewQuestion from '../../components/ItemPreviewQuestion';
import RadioBox from '../../components/RadioBox';
import Tab from '../../components/Tab';
import GridItem from '../../components/GridItem';
import Pagination from '../../components/Pagination';
import AddTaskSchedule from '../../components/AddTaskSchedule';
import GridItemSchedule from '../../components/GridItemSchedule';

import RoleGRAPHQL from '../../../graphql/Role';
import MetadataGRAPHQL from '../../../graphql/Metadata';
import GroupChatGRAPHQL from '../../../graphql/GroupChat';
import QuestionGRAPHQL from '../../../graphql/Question';
import TaskScheduleGRAPHQL from '../../../graphql/TaskSchedule';
import { useTracking } from 'react-tracking';
import eventName from '../../../common/events';

import { useHistory } from 'react-router-dom';
import SumInfoFilter from '../../components/SumInfoFilter';
import { useEffect } from 'react';
import CategoryQuestionsList from '../../components/CategoryQuestionPreview/CategoryQuestionsList';
import PreviewModalCategoryQuestions from '../../components/CategoryQuestionPreview/PreviewModalCategoryQuestions';
import QuestionBank from '../../components/QuestionBank';

function QuestionPage(props) {
  const { trackEvent } = useTracking();

  const COMPANY_ID = localStorage.getItem(window.CONSTANT.COMPANY_ID);
  const COMPANY_TYPE = localStorage.getItem(window.CONSTANT.COMPANY_TYPE);
  const NEW_SURVEY_PATH_NAME = '/new-survey';
  const dataSaveTabs = [
    { icon: 'fa-th', name: window.I18N('group_qa') },
    { icon: 'fa-th', name: window.I18N('question_answer') },
    { icon: 'fa-th', name: window.I18N('task_schedule') }
  ];
  const types = [
    { value: 'QUESTION', label: window.I18N('question') },
    { value: 'ANSWER_QUESTION', label: window.I18N('answer') }
  ];
  const list = [
    { value: 0, label: window.I18N('group_qa'), icon: 'list' },
    { value: 1, label: window.I18N('task_schedule'), icon: 'calendar' }
  ];
  const language = useRef();
  const categoryListRef = useRef();
  const itemFormQuestionRef = useRef({});
  const [filter, setFilter] = useState({ company: null, radio: 0, text: '' });
  const [object, setObject] = useState({ index: -1, indexSchedule: -1, radio: 'QUESTION', status: 1, checkSchedule: false, isUpdated: false, isCloned: false, isLoadSchema: false });
  const [pagination, setPagination] = useState({ total: 0, pageSize: 10, currentPage: 0 });
  const [data, setData] = useState([]);
  const [dataShared, setDataShared] = useState([]);
  const [dataSchedule, setDataSchedule] = useState([]);
  const [dataImport, setDataImport] = useState({});
  const [dataSave, setDataSave] = useState({ isShared: false, isPublic: false });
  const [dataQA, setDataQA] = useState([]);
  const [options, setOptions] = useState({
    company: [],
    companies: [],
    groupChats: [],
    group: [],
    role: [],
    companyIndustry: [],
    age: [],
    income: [],
    educationBackground: [],
    occupation: []
  });
  const [companiesFullData, setCompaniesFulldata] = useState([])

  // data for category question 
  const [questionsGroupByCategory, setQuestionsGroupByCategory] = useState([]);

  const [filterForQuery, setFilterForQuery] = useState({
    startDate: window.COMMON_DATE.addDaysFromNow(-91),
    endDate: new Date(),
    status: undefined,
    radio: 0
  })
  const [dataSum, setDataSum] = useState({ totalQA: '', totalSchedule: '', news: '', finished: '', process: '' })
  const history = useHistory();

  useLayoutEffect(() => {
    if (!props.language.code) {
      return;
    }
    language.current = props.language.code;
    setTimeout(() => {
      getDataMetadata();
      getDataGroupChat();
      getDataCompany();
    }, 100);
    // eslint-disable-next-line
  }, [props.language]);

  useEffect(() => {
    pagination.currentPage = 0
    setFilter({ ...filter, text: '' })
    if (filterForQuery.startDate && filterForQuery.endDate && filter.company) {
      countData()
      if (filter.radio === 0) {
        getDataGroupQuestion()
      } else {
        getDataTaskSchedule('refresh');
      }
    }
  }, [filterForQuery.startDate, filterForQuery.endDate, filterForQuery.status,filter.company])

  const createTrackingEvent = (event) => {
    return trackEvent({
      name: event,
      createdAt: new Date().toISOString()
    });
  };

  const handleChange = (event) => {
    if (event.target.type === 'checkbox') {
      dataSave[event.target.name] = event.target.checked;
    } else {
      dataSave[event.target.name] = event.target.value;
    }
    setDataSave({ ...dataSave });
  };
  const handleFinalConclusionTextChange = (e) => {
    const { name: level, value } = e.target;
    window.COMMON.setValueWithLanguage(dataSave.finalConclusion, level, language.current, value);
    setDataSave({ ...dataSave });
  };

  const handleFilterSelect = (event) => {
    filter.company = event;
    setFilter({ ...filter });
    if (filter.radio === 0) {
      getDataRole();
    }
  };

  const handleFilterChange = (event) => {
    filter.text = event.target.value;
    setFilter({ ...filter });
  };

  const handleFilterRadio = (value) => {
    filter.radio = value;
    setFilter({ ...filter });
    setFilterForQuery((prev)=>({...prev, radio: value}))
    if (value === 0) {
      getDataGroupQuestion(0);
    } else {
      getDataTaskSchedule();
    }
    // eslint-disable-next-line
  };

  const handleChangeWithLanguage = (event) => {
    window.COMMON.setValueWithLanguage(dataSave, event.target.name, language.current, event.target.value);
    setDataSave({ ...dataSave });
  };

  const handleRadio = useCallback((value) => {
    object.radio = value;
    setObject({ ...object });
  }, [object]);

  const handleFile = (file) => {
    dataSave.file = file;
    dataSave.image = window.COMMON.createBlobImage(file);
    setDataSave({ ...dataSave });
  };

  const handleFileImport = (dataImport) => {
    checkImportData(dataImport);
    window.COMMON.showModal('#modal-import');
  };

  const receiveHashtag = useCallback((data) => {
    dataSave.hashtag = data;
    setDataSave({ ...dataSave });
  }, [dataSave]);

  // METADATA
  const getDataMetadata = async () => {
    try {
      const params = {
        type: ['COMPANY_INDUSTRY', 'INDIVIDUAL_AGE', 'INDIVIDUAL_OCCUPATION', 'INDIVIDUAL_EDUCATION_BACKGROUND', 'INDIVIDUAL_INCOME']
      };
      const result = await window.COMMON.query(MetadataGRAPHQL.QUERY_SHORT_METADATA, params, false);
      if (result && result.data) {
        const dataIndustry = window.COMMON.getArrsInArrs('COMPANY_INDUSTRY', result.data.getMetadatas, 'type');
        options.companyIndustry = window.COMMON.createDataSelect(dataIndustry, '_id', 'value');
        options.companyIndustry.unshift({ value: null, label: window.I18N('all_industries') });
        const dataAge = window.COMMON.getArrsInArrs('INDIVIDUAL_AGE', result.data.getMetadatas, 'type');
        options.age = window.COMMON.createDataSelect(dataAge, '_id', 'value');
        options.age.unshift({ value: null, label: window.I18N('all_age') });
        const dataOccupation = window.COMMON.getArrsInArrs('INDIVIDUAL_OCCUPATION', result.data.getMetadatas, 'type');
        options.occupation = window.COMMON.createDataSelect(dataOccupation, '_id', 'value');
        options.occupation.unshift({ value: null, label: window.I18N('all_occupation') });
        const dataEducationBackground = window.COMMON.getArrsInArrs('INDIVIDUAL_EDUCATION_BACKGROUND', result.data.getMetadatas, 'type');
        options.educationBackground = window.COMMON.createDataSelect(dataEducationBackground, '_id', 'value');
        options.educationBackground.unshift({ value: null, label: window.I18N('all_education_background') });
        const dataIncome = window.COMMON.getArrsInArrs('INDIVIDUAL_INCOME', result.data.getMetadatas, 'type');
        options.income = window.COMMON.createDataSelect(dataIncome, '_id', 'value');
        options.income.unshift({ value: null, label: window.I18N('all_income') });
        setOptions(options);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('QuestionReportPage.getDataMetadata');
    }
  };

  // GROUP CHAT
  const getDataGroupChat = async () => {
    try {
      const params = {
        type: '-1',
        organization: !window.COMMON.checkRoleIsSystem() ? COMPANY_ID : null
      };
      const result = await window.COMMON.query(GroupChatGRAPHQL.QUERY_GROUP_CHAT, params);
      if (result && result.data) {
        options.groupChats = window.COMMON.createDataSelect(result.data.getGroupChats, '_id', 'name');
        setOptions(options);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('TaskSchedulePage.getDataGroupChat');
    }
  };

  // ROLE
  const getDataRole = async () => {
    try {
      if (filter.company !== null) {
        const params = {
          company: filter.company.value
        };
        const result = await window.COMMON.query(RoleGRAPHQL.QUERY_ROLE, params);
        if (result && result.data) {
          const data = result.data.getRoles;
          if (COMPANY_TYPE === window.CONSTANT.INFLUENCER) {
            for (let i = 0; i < data.length; i++) {
              const element = data[i];
              if (element.code === window.CONSTANT.INFLUENCER) {
                data.splice(i, 1);
                break;
              }
            }
          }
          options.role = window.COMMON.createDataSelect(data, 'code', 'name');
          if (COMPANY_TYPE === window.CONSTANT.INFLUENCER) {
            options.role.push({ value: window.CONSTANT.COMPANY, label: window.I18N('company') });
            options.role.push({ value: window.CONSTANT.UNION, label: window.I18N('union') });
            setOptions(options);
          }
          options.role.push({ value: window.CONSTANT.INDIVIDUAL, label: window.I18N('individual') });
          setOptions(options);
        }
      }
    } catch (error) {
      window.COMMON.showErrorLogs('AccountPage.getDataRole');
    }
  };

  // COMPANY
  const getDataCompany = async () => {
    const backCompanyID = localStorage.getItem(window.CONSTANT.COMPANY_BACK);
    localStorage.removeItem(window.CONSTANT.COMPANY_BACK);
    try {
      if (!window.COMMON.checkRoleIsSystem()) {
        filter.company = { value: COMPANY_ID, label: '' };
        setFilter({ ...filter });
        if (COMPANY_TYPE === window.CONSTANT.INFLUENCER) {
          await window.COMMON.getDataCompany();
          const dataCompany = JSON.parse(localStorage.getItem(window.CONSTANT.DATA_COMPANY));
          if (dataCompany && dataCompany.length > 0) {
            options.companies = window.COMMON.createDataSelectWithGroup(dataCompany, 'id', 'name', 'accountType', 'accountType');
          }
          setCompaniesFulldata(dataCompany)
          setOptions(options);
        }
        getDataRole();
        return;
      }
      await window.COMMON.getDataAllCompany();
      const dataCompany = JSON.parse(localStorage.getItem(window.CONSTANT.DATA_ALL_COMPANY));
      if (dataCompany && dataCompany.length > 0) {
        options.company = window.COMMON.createDataSelectWithGroup(dataCompany, '_id', 'information.name', 'type', 'type');
        options.companies = JSON.parse(JSON.stringify(options.company));
      }
      if (window.COMMON.checkRoleIsSystem()) {
        options.company.unshift({ value: null, label: 'My group questions' });
        let isUseDefault = true;
        if (backCompanyID != null) {
          const backCompanyData = dataCompany.find((item) => item._id === backCompanyID);
          if (backCompanyData) {
            filter.company = {
              code: backCompanyData.type,
              value: backCompanyData._id,
              label: backCompanyData.information.name
            };
            isUseDefault = false;
          }
        }

        if (isUseDefault === true) {
          filter.company = window.COMMON.getValueFromAttr(options.company, '0', null);
        }
      } else {
        filter.company = window.COMMON.getValueFromAttr(options.company, '0.options.0', null);
      }
      setCompaniesFulldata(dataCompany)
      setOptions(options);
      setFilter({ ...filter });
      getDataRole();
    } catch (error) {
      window.COMMON.showErrorLogs('AccountPage.getDataCompany');
    }
  };

  const isDateInRange = (date) => {
    const { startDate, endDate } = filterForQuery
    //convert to valid date format
    const [day, month, year] = date.split("/");
    const inputDate = `${month}/${day}/${year}`

    //get time
    const targetTime = new Date(inputDate).getTime();
    const startTime = new Date(startDate).getTime();
    const endTime = new Date(endDate).getTime();
    
    //compare with filter
    return targetTime >= startTime && targetTime <= endTime;
  }

  // GROUP QUESTION
  const getDataGroupQuestion = async (mode) => {
    try {
      const params = {
        company: window.COMMON.getDataSelect(filter.company, false),
        startDate: window.COMMON_DATE.getStrDateFilter(filterForQuery.startDate, -1),
        endDate: window.COMMON_DATE.getStrDateFilter(filterForQuery.endDate, 1)
      };
      const result = await window.COMMON.query(QuestionGRAPHQL.QUERY_GROUP_QUESTION, params);
      let data = [];
      const dataForOptions = [];
      if (result && result.data) {
        data = window.COMMON.formatDate(result.data.getGroupQuestions, 'createdDate');
        //get draft data
        const draftDataString = localStorage.getItem(window.CONSTANT.DRAFT_SURVEYS);
        let draftDataFollowCompany = [];
        if (draftDataString) {
          const draftData = JSON.parse(draftDataString);
          draftDataFollowCompany = draftData.filter(item => item.surveyFormData.company === params.company && isDateInRange(item.surveyFormData.createdDate));
          draftDataFollowCompany = draftDataFollowCompany.map(item => { return { ...item.surveyFormData, countSchedule: 0, isDraft: true }; });
          data = [
            ...draftDataFollowCompany,
            ...data
          ];
        }
        data.forEach(item => {
          item.nameLanguage = window.COMMON.getValueWithLanguage(item, 'name', language.current);
          item.descriptionLanguage = window.COMMON.getValueWithLanguage(item, 'description', language.current);
          if (item.isDraft !== true) {
            dataForOptions.push(item);
          }
        });
      }
      options.group = window.COMMON.createDataSelectWithLanguage(dataForOptions, '_id', 'name', language.current, false);
      setOptions(options);
      setData(data);
      // TODO process when create group QA
      if (mode === 1) {
        object.index = 0;
        const dataSave = { file: null, company: window.COMMON.getDataSelect(filter.company, false) };
        const obj = data[0];
        object.checkSchedule = false;
        object.isUpdated = true;
        object.groupId = window.COMMON.getValueFromAttr(obj, '_id', null);
        dataSave._id = window.COMMON.getValueFromAttr(obj, '_id', null);
        dataSave.name = window.COMMON.getDataWithLanguage(obj, 'name');
        dataSave.code = window.COMMON.getValueFromAttr(obj, 'code');
        dataSave.image = window.COMMON.getValueFromAttr(obj, 'image');
        dataSave.description = window.COMMON.getDataWithLanguage(obj, 'description');
        dataSave.hashtag = window.COMMON.getValueFromAttr(obj, 'hashtag', []);
        dataSave.type = window.COMMON.getValueFromAttr(obj, 'type', 1);
        if(dataSave.type && dataSave.type === 2){
          dataSave.standardOfConclusion = window.COMMON.getValueFromAttr(obj, 'standardOfConclusion')
        }
        dataSave.finalConclusion = window.COMMON.getValueFromAttr(obj, 'finalConclusion', null);
        document.getElementById('file-image').value = null;
        setObject(object);
        setDataSave({ ...dataSave });
      }
    } catch (error) {
      console.error("🚀 ~ getDataGroupQuestion ~ error:", error)
      window.COMMON.showErrorLogs('QuestionPage.getDataGroupQuestion');
    }
  };

  // SHARED GROUP QUESTION
  const getDataGroupQuestionShared = async (mode) => {
    try {
      const result = await window.COMMON.query(QuestionGRAPHQL.QUERY_SHARED_GROUP_QUESTION, {});
      let data = [];
      if (result && result.data) {
        data = result.data.getSharedGroupQuestions;
        data.forEach(item => {
          item.nameLanguage = window.COMMON.getValueWithLanguage(item, 'name', language.current);
          item.descriptionLanguage = window.COMMON.getValueWithLanguage(item, 'description', language.current);
        });
      }
      data.unshift({ _id: '-1', descriptionLanguage: '', nameLanguage: window.I18N('empty') });
      setDataShared(data);
    } catch (error) {
      window.COMMON.showErrorLogs('QuestionPage.getDataGroupQuestionShared');
    }
  };

  // TASK SCHEDULE
  const getDataTaskSchedule = async (paramMode) => {
    try {
      const params = {
        input: {
          company: window.COMMON.getDataSelect(filter.company, false),
          pageSize: pagination.pageSize,
          page: paramMode === 'refresh' ? 1 : pagination.currentPage + 1,
          text: paramMode === 'refresh' ? "" : filter.text,
          startDate: window.COMMON_DATE.getStrDateFilter(filterForQuery.startDate, -1),
          endDate: window.COMMON_DATE.getStrDateFilter(filterForQuery.endDate, 1)
        }
      };
      if(filterForQuery.status !== undefined){
        params.input.status = filterForQuery.status
      }
      let data = [];
      const result = await window.COMMON.query(TaskScheduleGRAPHQL.QUERY_TASK_SCHEDULE, params);
      if (result && result.data && result.data.getTaskSchedules) {
        pagination.total = result.data.getTaskSchedules.total;
        data = window.COMMON.formatMultiDate(result.data.getTaskSchedules.data, 'createdDate,startDate,endDate');
        data.forEach(item => {
          item.groupLanguage = window.COMMON.getValueWithLanguage(item, 'group.name', language.current);
        });
      }
      setPagination(pagination);
      setDataSchedule(data);
    } catch (error) {
      window.COMMON.showErrorLogs('QuestionPage.getDataTaskSchedule');
    }
  };

  //COUNT_SUM
  const countData = async () => {
    try {
      const params = {
        input:
        {
          company: window.COMMON.checkRoleIsSystem() ? window.COMMON.getDataSelect(filter.company, false) : COMPANY_ID,
          startDate: window.COMMON_DATE.getStrDateFilter(filterForQuery.startDate, -1),
          endDate: window.COMMON_DATE.getStrDateFilter(filterForQuery.endDate, 1)
        }
      };
      const resultCountQA = await window.COMMON.query(QuestionGRAPHQL.QUERY_COUNT_TOTAL_QA, params);
      const resultCountSchedule = await window.COMMON.query(TaskScheduleGRAPHQL.QUERY_COUNT_TASK_SCHEDULE, params)
      if (resultCountQA && resultCountSchedule) {
        dataSum.totalQA = resultCountQA.data?.countTotalQA
        dataSum.totalSchedule = resultCountSchedule.data.countTaskSchedules.total
        dataSum.finished = resultCountSchedule.data.countTaskSchedules.finished
        dataSum.news = resultCountSchedule.data.countTaskSchedules.news
        dataSum.process = resultCountSchedule.data.countTaskSchedules.process
        dataSum.stopped = resultCountSchedule.data.countTaskSchedules.stopped
        dataSum.paused = resultCountSchedule.data.countTaskSchedules.paused
        setDataSum({ ...dataSum })
      }
    } catch (error) {
      window.COMMON.showErrorLogs('QuestionPage.countData');
    }
  }

  const searchData = () => {
    pagination.currentPage = 0;
    setPagination({ ...pagination });
    getDataTaskSchedule();
  };

  const showTemplateGroupQuestion = useCallback((index) => {
    window.COMMON.showModal('#modal-template');
    getDataGroupQuestionShared();
    // eslint-disable-next-line
  }, []);

  const showContainerGroupQuestion = useCallback((index, isTemplate) => {
    window.COMMON.hideModal('#modal-template');
    window.COMMON.initialTabs('tabs-question', 0);

    if (index === 0 && isTemplate) {//my
      history.push({ pathname: NEW_SURVEY_PATH_NAME, state: { company: filter.company.value } });
      return;
    }
    else if (isTemplate && index > 0) {
      const cloneData = {};
      const obj = dataShared[index];
      object.index = -1;
      object.groupId = dataShared[index]._id;
      object.isCloned = true;
      cloneData._id = null;
      cloneData.name = window.COMMON.getDataWithLanguage(obj, 'name');
      cloneData.code = window.COMMON.generateCode('GQ');
      cloneData.image = window.COMMON.getValueFromAttr(obj, 'image');
      cloneData.type = window.COMMON.getValueFromAttr(obj, 'type') || 1;
      cloneData.isShared = false;
      cloneData.isPublic = false;
      cloneData.description = window.COMMON.getDataWithLanguage(obj, 'description');
      cloneData.hashtag = window.COMMON.getValueFromAttr(obj, 'hashtag', []);
      cloneData.groupId = object.groupId;
      localStorage.setItem(window.CONSTANT.GROUP_QA_CLONE, JSON.stringify(cloneData));
      history.push({ pathname: NEW_SURVEY_PATH_NAME, state: { company: filter.company.value } });
      // getDataQuestion(object.groupId, true);
      return;
    }

    const dataFollowIndex = data[index];
    if (dataFollowIndex && dataFollowIndex.isDraft === true) {//my
      localStorage.setItem(window.CONSTANT.SURVEY_DRAFT_CODE, dataFollowIndex.draftcode);
      history.push(NEW_SURVEY_PATH_NAME);
      return;
    }


    window.COMMON.showContainerSave();
    object.indexSchedule = -1;
    object.isLoadSchema = true;
    object.checkSchedule = false;
    object.isUpdated = false;
    object.isCloned = false;
    const dataSave = { file: null, company: window.COMMON.getDataSelect(filter.company, false) };
    if (isTemplate && index === 0) {
      object.index = -1;
      dataSave._id = null;
      dataSave.name = [];
      dataSave.code = window.COMMON.generateCode('GQ');
      dataSave.image = '';
      dataSave.isShared = false;
      dataSave.isPublic = false;
      dataSave.description = [];
      dataSave.hashtag = [];
      dataSave.type = 1;
      setDataQA([]);
    } else if (isTemplate && index > 0) {
      const obj = dataShared[index];
      object.index = -1;
      object.groupId = dataShared[index]._id;
      object.isCloned = true;
      dataSave._id = null;
      dataSave.name = window.COMMON.getDataWithLanguage(obj, 'name');
      dataSave.code = window.COMMON.generateCode('GQ');
      dataSave.image = window.COMMON.getValueFromAttr(obj, 'image');
      dataSave.isShared = false;
      dataSave.isPublic = false;
      dataSave.description = window.COMMON.getDataWithLanguage(obj, 'description');
      dataSave.hashtag = window.COMMON.getValueFromAttr(obj, 'hashtag', []);
      getDataQuestion(object.groupId, true);
    } else {
      const obj = data[index];
      object.index = index;
      object.groupId = data[index]._id;
      object.checkSchedule = obj.countSchedule > 0;
      object.isUpdated = true;
      dataSave._id = window.COMMON.getValueFromAttr(obj, '_id', null);
      dataSave.name = window.COMMON.getDataWithLanguage(obj, 'name');
      dataSave.code = window.COMMON.getValueFromAttr(obj, 'code');
      dataSave.image = window.COMMON.getValueFromAttr(obj, 'image');
      dataSave.isShared = window.COMMON.getValueFromAttr(obj, 'isShared');
      dataSave.isPublic = window.COMMON.getValueFromAttr(obj, 'isPublic');
      dataSave.description = window.COMMON.getDataWithLanguage(obj, 'description');
      dataSave.hashtag = window.COMMON.getValueFromAttr(obj, 'hashtag', []);
      dataSave.type = window.COMMON.getValueFromAttr(obj, 'type', 1);
      if(dataSave.type && dataSave.type === 2){
        dataSave.standardOfConclusion = window.COMMON.getValueFromAttr(obj, 'standardOfConclusion')
      }
      dataSave.finalConclusion = window.COMMON.getValueFromAttr(obj, 'finalConclusion', null);
      getDataQuestion(object.groupId, false);
    }
    document.getElementById('file-image').value = null;
    setObject(object);
    setDataSave({ ...dataSave });
    // eslint-disable-next-line
  }, [data, dataShared, object, dataSave, filter]);

  const openSaveGroupQuestion = useCallback((event) => {
    const checkForm = window.COMMON.checkFormValidation('form', event);
    const validStandardOfConclusion = handleValidStandardOfConclusion()
    if (!checkForm) {
      return
    }
    if(!validStandardOfConclusion){
      window.COMMON.showMessage('warning', window.I18N('MSG_CODE_086', {
        min: window.CONSTANT.MIN_STANDARD_OF_CATEGORY,
        max: window.CONSTANT.MAX_STANDARD_OF_CATEGORY
      }))
      return
    }
    window.COMMON.showModal('#modal-save');
    window.saveMethod = saveGroupQuestion;
    // eslint-disable-next-line
  }, [object, dataSave]);

  const openDeleteGroupQuestionDialog = useCallback((index) => {
    object.index = index;
    setObject(object);
    window.COMMON.showModal('#modal-delete');
    const dataToRemove = data[index];
    if (dataToRemove.draftcode) {
      window.deleteMethod = () => {

        window.COMMON.removeDraftSurvey({ key: window.CONSTANT.DRAFT_SURVEYS, draftcode: dataToRemove.draftcode });

        setData((current) => {
          const newData = [...current];
          newData.splice(index, 1);
          return newData;
        });

      };
    }
    else {
      window.deleteMethod = deleteGroupQuestion;
    }
    // eslint-disable-next-line
  }, [object, data]);
  
  const saveGroupQuestion = async () => {
    let dataFiles = [];
    try {
      const params = {
        input: {
          ...dataSave,
          finalConclusion: {
            low: dataSave.finalConclusion.low.map(item => ({ text: item.text, code: item.code })),
            medium: dataSave.finalConclusion.medium.map(item => ({ text: item.text, code: item.code })),
            high: dataSave.finalConclusion.high.map(item => ({ text: item.text, code: item.code }))
          }
        }
      };
      if (dataSave.type === 1) {
        delete params.input.finalConclusion;
      }
      // TODO upload file
      if (dataSave.file) {
        dataFiles = await window.uploadFile([dataSave.file]);
        if (dataFiles.length > 0) {
          dataSave.image = window.COMMON.getNewPathFile(dataFiles[0]._id);
        }
      }
      dataSave.file = null;
      // TODO save data
      window.COMMON.trimData(params);
      if (object.index === -1) {
        const result = await window.COMMON.mutation(QuestionGRAPHQL.MUTATION_ADD_GROUP_QUESTION, params);
        if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_INSERT)) {
          getDataGroupQuestion(1);
          createTrackingEvent(eventName.ADD_GROUP_QUESTION);
        }
      } else {
        const result = await window.COMMON.mutation(QuestionGRAPHQL.MUTATION_EDIT_GROUP_QUESTION, params);
        if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_UPDATE)) {
          getDataGroupQuestion(0);
          createTrackingEvent(eventName.EDIT_GROUP_QUESTION);
        }
      }
      window.COMMON.hideModal('#modal-group');
    } catch (error) {
      console.error("🚀 ~ saveGroupQuestion ~ error:", error)
      window.UPLOAD_API.deleteMultiFile(dataFiles.map(item => item._id))      
      window.COMMON.showErrorLogs('QuestionPage.saveGroupQuestion');
    }
  };

  const deleteGroupQuestion = async () => {
    try {
      const params = {
        id: data[object.index]._id
      };
      const result = await window.COMMON.mutation(QuestionGRAPHQL.MUTATION_DEL_GROUP_QUESTION, params);
      if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_DELETE)) {
        getDataGroupQuestion(0);
        createTrackingEvent(eventName.DEL_GROUP_QUESTION);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('QuestionPage.deleteGroupQuestion');
    }
  };

  // QUESTION & ANSWER
  const getDataQuestion = async (id) => {
    try {
      const params = {
        groupId: id || object.groupId
      };
      const result = await window.COMMON.query(QuestionGRAPHQL.QUERY_QUESTION, params);
      const data = [];
      if (result && result.data) {
        result.data.getQuestions.forEach(obj => {
          if (!obj?.code) return;
          data.push({
            uuid: window.COMMON.getValueFromAttr(obj, '_id', null),
            group: id,
            question: {
              _id: window.COMMON.getValueFromAttr(obj, '_id', null),
              question: window.COMMON.getDataWithLanguage(obj, 'question'),
              introduction: window.COMMON.getDataWithLanguageWithDefaultValue(obj, 'introduction', null),
              category: window.COMMON.getDataWithLanguageWithDefaultValue(obj, 'category', null),
              standardLowOfCategory: window.COMMON.getValueFromAttr(obj, 'standardLowOfCategory', null),
              standardOfCategory: window.COMMON.getValueFromAttr(obj, 'standardOfCategory', null),
              recommend: window.COMMON.getValueFromAttr(obj, 'recommend', null),
              createdDate: window.COMMON.getValueFromAttr(obj, 'createdDate', null),
              type: window.COMMON.getValueFromAttr(obj, 'type'),
              code: window.COMMON.getValueFromAttr(obj, 'code'),
              important: window.COMMON.getValueFromAttr(obj, 'important', false)
            },
            answers: []
          });
        });
      }
      getDataAnswerQuestion(id, data);
    } catch (error) {
      window.COMMON.showErrorLogs('QuestionPage.getDataQuestion');
    }
  };
  
  const getDataAnswerQuestion = async (id, dataQuestion) => {
    try {
      const params = {
        groupId: id || object.groupId
      };
      const result = await window.COMMON.query(QuestionGRAPHQL.QUERY_ANSWER_QUESTION, params);
      if (result && result.data) {
        result.data.getAnswerQuestions.forEach(obj => {
          const item = window.COMMON.getObjectInArrs(obj.question, dataQuestion, 'uuid');
          item.answers.push({
            _id: window.COMMON.getValueFromAttr(obj, '_id', null),
            content: window.COMMON.getDataWithLanguage(obj, 'content'),
            code: window.COMMON.getValueFromAttr(obj, 'code'),
            parentCode: window.COMMON.getValueFromAttr(obj, 'parentCode', null),
            question: window.COMMON.getValueFromAttr(obj, 'question'),
            score: window.COMMON.getValueFromAttr(obj, 'score', null)
          });
        });
      }
      setDataQA(dataQuestion);
    } catch (error) {
      window.COMMON.showErrorLogs('QuestionPage.getDataAnswerQuestion');
    }
  };

  const getDataFromFormQA = (exceptNonEdit = false) => {
     const newDataQA = []
     dataQA.forEach((item) => {
        const { question, answers, status} = itemFormQuestionRef.current[item.uuid].getDataItems()
        if(!exceptNonEdit){
          newDataQA.push({
            ...item,
            question,
            answers,
            status
          })     
        }else{
          status && newDataQA.push({
            ...item,
            question,
            answers
          })
        }
     })

     return newDataQA
  }


  const addFormQuestion = () => {
    const newDataQA = dataSave.type === 1 ? getDataFromFormQA() : dataQA
    if (!object.isUpdated) {
      return;
    }
    newDataQA.push({
      uuid: window.COMMON.genKey(),
      group: data[object.index]._id,
      question: {
        _id: null,
        question: [],
        introduction: [],
        type: 'TEXT',
        code: window.COMMON.generateCode('Q')
      },
      answers: []
    });
    setDataQA([...newDataQA]);
    setTimeout(()=>{window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' })},500)
  };

  const openPreview = (modal_id) => {
      const newDataQA = dataSave.type === 2 ? categoryListRef.current.getDataCategoryList() : getDataFromFormQA()
      setDataQA(newDataQA);
    window.COMMON.showModal(modal_id);
  };

  const addItemQuestion = useCallback((uuid, question, answers, isCallAPI) => {
    const newDataQA = dataSave.type === 2 ? categoryListRef.current.getDataCategoryList() : getDataFromFormQA()
    if (isCallAPI) {
      getDataQuestion();
      return;
    }
    const obj = window.COMMON.getObjectInArrs(uuid, newDataQA, 'uuid');
    if (obj) {
      obj.group = dataSave._id;
      obj.question = question;
      obj.answers = answers;
    } else {
      newDataQA.push({
        uuid: window.COMMON.genKey(),
        group: dataSave._id,
        question,
        answers
      });
    }
    setDataQA([...newDataQA]);
    // eslint-disable-next-line
  }, [dataQA, dataSave]);

  const removeItemQuestion = useCallback((index, isCallAPI) => {
    const newDataQA = dataSave.type === 1 ? getDataFromFormQA() : dataQA
    if (isCallAPI) {
      getDataQuestion();
      return;
    }
    newDataQA.splice(index, 1);
    setDataQA([...newDataQA]);
    // eslint-disable-next-line
  }, [dataQA]);

  const saveMultiQA = async () => {
    try {
      const input = [];
      let index = 0;
      let indexAnswer = 0;
      dataQA.forEach(item => {
        const cloned = JSON.parse(JSON.stringify(item));
        cloned.question.code = window.COMMON.generateCode('Q') + '-' + index;
        index++;
        if (item.answers && item.answers.length > 0) {
          item.answers.forEach(answer => {
            const arrs = window.COMMON.getArrsInArrs(answer.code, item.answers, 'parentCode');
            answer.code = window.COMMON.generateCode('A') + '-' + indexAnswer;
            if (arrs.length > 0) {
              arrs.forEach(sub => {
                sub.parentCode = answer.code;
              });
            }
            indexAnswer++;
          });
        }
        const obj = {
          group: object.groupId,
          question: cloned.question,
          answers: item.answers,
          deletedAnswers: []
        };
        input.push(obj);
      });
      const params = { input };
      window.COMMON.trimData(params);
      const result = await window.COMMON.mutation(QuestionGRAPHQL.MUTATION_ADD_MULTI_QA, params);
      if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_INSERT)) {
        getDataQuestion();
        object.isCloned = false;
        setObject({ ...object });
      }
      window.COMMON.hideModal('#modal-preview');
    } catch (error) {
      window.COMMON.showErrorLogs('QuestionPage.saveQA');
    }
  };

  // IMPORT QUESTION & ANSWER
  const deleteImportRow = useCallback((index) => {
    if (dataImport.data.length > 0) {
      dataImport.data.splice(index, 1);
    }
    setDataImport({ ...dataImport });
  }, [dataImport]);

  const checkImportData = async (dataImport) => {
    try {
      if (dataImport.data.length === 0) {
        return;
      }
      const data = [];
      dataImport.data.forEach(item => {
        if (!data.includes(item.code)) {
          data.push(item.code);
        }
      });
      const params = {
        input: data
      };
      window.COMMON.trimData(params);
      let result = null;
      let dataCheck = [];
      if (object.radio === 'QUESTION') {
        result = await window.COMMON.mutation(QuestionGRAPHQL.MUTATION_CHECK_IMPORT_QUESTION, params);
        createTrackingEvent(eventName.CHECK_IMPORT_QUESTION);
      } else {
        result = await window.COMMON.mutation(QuestionGRAPHQL.MUTATION_CHECK_IMPORT_ANSWER_QUESTION, params);
        createTrackingEvent(eventName.CHECK_IMPORT_ANSWER_QUESTION);
      }
      if (object.radio === 'QUESTION' && result.data && result.data.checkImportQuestion) {
        dataCheck = result.data.checkImportQuestion;
      } else {
        if (result.data && result.data.checkImportAnswerQuestion) {
          dataCheck = result.data.checkImportAnswerQuestion;
        }
      }
      for (let i = 0; i < dataCheck.length; i++) {
        const arrs = dataCheck[i].split('-.-');
        if (Number(arrs[1]) === 1) {
          const array = window.COMMON.getArrsInArrs(arrs[0], dataImport.data, 'code');
          if (array && array.length > 0) {
            array.forEach(element => {
              element.isExisted = true;
            });
          }
        }
      }
      setDataImport(dataImport);
    } catch (error) {
      window.COMMON.showErrorLogs('QuestionPage.checkImportData');
    }
  };

  const importData = () => {
    if (dataImport.data.length === 0) {
      window.COMMON.showMessage('warning', 'MSG_CODE_021', window.MSG.MSG_CODE_021);
      return;
    }
    if (object.radio === 'QUESTION') {
      importQuestion();
    } else {
      importAnswerQuestion();
    }
  };

  const importQuestion = async () => {
    try {
      const dataImported = [];
      dataImport.data.forEach(item => {
        const check = window.COMMON.getObjectInArrs(item.code, dataImported, 'code');
        if (!check) {
          const obj = {};
          obj.code = item.code;
          obj.type = item.type;
          obj.group = data[object.index]._id;
          obj.introduction = [];
          obj.question = [];
          const arrs = window.COMMON.getArrsInArrs(item.code, dataImport.data, 'code');
          arrs.forEach(child => {
            obj.introduction.push({ code: child.languageCode, text: child.introduction });
            obj.question.push({ code: child.languageCode, text: child.question });
          });
          dataImported.push(obj);
        }
      });
      const params = {
        input: dataImported
      };
      window.COMMON.trimData(params);
      const result = await window.COMMON.mutation(QuestionGRAPHQL.MUTATION_IMPORT_QUESTION, params);
      if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_IMPORT)) {
        getDataQuestion();
        window.COMMON.hideModal('#modal-import');
        createTrackingEvent(eventName.MUTATION_IMPORT_QUESTION);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('QuestionPage.importQuestion');
    }
  };

  const importAnswerQuestion = async () => {
    try {
      const dataImported = [];
      dataImport.data.forEach(item => {
        const check = window.COMMON.getObjectInArrs(item.code, dataImported, 'code');
        const question = window.COMMON.getObjectInArrs(item.questionCode, dataQA, 'question.code');
        if (!check && question) {
          const obj = {};
          obj.code = item.code;
          obj.group = data[object.index]._id;
          obj.parentCode = item.parentCode || null;
          obj.question = question.question._id;
          obj.content = [];
          const arrs = window.COMMON.getArrsInArrs(item.code, dataImport.data, 'code');
          arrs.forEach(child => {
            obj.content.push({ code: child.languageCode, text: child.content });
          });
          dataImported.push(obj);
        }
      });
      const params = {
        input: dataImported
      };
      window.COMMON.trimData(params);
      const result = await window.COMMON.mutation(QuestionGRAPHQL.MUTATION_IMPORT_ANSWER_QUESTION, params);
      if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_IMPORT)) {
        getDataQuestion();
        window.COMMON.hideModal('#modal-import');
        createTrackingEvent(eventName.IMPORT_ANSWER_QUESTION);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('QuestionPage.importAnswerQuestion');
    }
  };

  // SCHEDULE
  const changePage = useCallback((index) => {
    pagination.currentPage = index;
    setPagination({ ...pagination });
    getDataTaskSchedule();
    // eslint-disable-next-line
  }, [object, pagination, filter]);

  const showContainerSave = useCallback((index) => {
    window.COMMON.showContainerSave();
    window.COMMON.initialTabs('tabs-question', 2);
    object.index = -1;
    object.indexSchedule = index;
    object.checkSchedule = false;
    object.isUpdated = false;
    object.isLoadSchema = true;
    object.isCloned = false;
    const dataSave = { file: null, company: window.COMMON.getDataSelect(filter.company, false) };
    if (index === -1) {
      dataSave._id = null;
      dataSave.name = [];
      dataSave.code = window.COMMON.generateCode('GQ');
      dataSave.image = '';
      dataSave.description = [];
      dataSave.hashtag = [];
      setDataQA([]);
    } else {
      let obj = null;
      for (let i = 0; i < data.length; i++) {
        const element = data[i];
        if (window.COMMON.getValueFromAttr(dataSchedule[index], 'group._id') === element._id) {
          object.index = i;
          obj = element;
          break;
        }
      }
      object.groupId = data[object.index]._id;
      object.checkSchedule = obj.countSchedule > 0;
      object.isUpdated = true;
      dataSave._id = window.COMMON.getValueFromAttr(obj, '_id', null);
      dataSave.name = window.COMMON.getDataWithLanguage(obj, 'name');
      dataSave.code = window.COMMON.getValueFromAttr(obj, 'code');
      dataSave.image = window.COMMON.getValueFromAttr(obj, 'image');
      dataSave.description = window.COMMON.getDataWithLanguage(obj, 'description');
      dataSave.hashtag = window.COMMON.getValueFromAttr(obj, 'hashtag', []);
      getDataQuestion(object.groupId);
    }
    document.getElementById('file-image').value = null;
    setObject(object);
    setDataSave({ ...dataSave });
    // eslint-disable-next-line
  }, [data, object, dataSave, dataSchedule, filter]);

  const openTaskSchedule = useCallback((index, status) => {
    object.index = index;
    object.status = status;
    setObject(object);
    window.COMMON.showModal('#modal-save');
    window.saveMethod = updateStatus;
    // eslint-disable-next-line
  }, [object, dataSchedule]);

  const openDeleteDialog = useCallback((index) => {
    object.index = index;
    setObject(object);
    window.COMMON.showModal('#modal-delete');
    window.deleteMethod = deleteData;
    // eslint-disable-next-line
  }, [object, dataSchedule]);

  const updateStatus = async () => {
    try {
      const params = {
        id: dataSchedule[object.index]._id,
        status: object.status
      };
      const result = await window.COMMON.mutation(TaskScheduleGRAPHQL.MUTATION_UPDATE_STATUS_TASK_SCHEDULE, params);
      if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_STATUS)) {
        getDataTaskSchedule();
        createTrackingEvent(eventName.UPDATE_STATUS_TASK_SCHEDULE);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('QuestionPage.updateStatus');
    }
  };

  const deleteData = async () => {
    try {
      const params = {
        id: dataSchedule[object.index]._id
      };
      const result = await window.COMMON.mutation(TaskScheduleGRAPHQL.MUTATION_DEL_TASK_SCHEDULE, params);
      if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_DELETE)) {
        getDataTaskSchedule();
        createTrackingEvent(eventName.DEL_TASK_SCHEDULE);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('TaskSchedulePage.deleteData');
    }
  };

  const addSoftSkillQuestionFromBank = (arrayCloneQuestion)=>{

    const softSkillQuestions = categoryListRef.current.getDataCategoryList()

    if(softSkillQuestions.length === 0){
      arrayCloneQuestion.forEach((question) => {
        softSkillQuestions.push({
          uuid: window.COMMON.genKey(),
          group: dataSave._id,
          question: {
            ...question.question,
            standardLowOfCategory: window.CONSTANT.MIN_STANDARD_OF_CATEGORY,
            standardOfCategory: window.CONSTANT.MAX_STANDARD_OF_CATEGORY,
            category: [
              {
                text: "",
                code: props.language.code
              }
            ],
            recommend: {
              low: [{
                text: window.I18N('low'),
                code: props.language.code
              }],
              medium: [{
                text: window.I18N('medium'),
                code: props.language.code
              }],
              high: [{
                text: window.I18N('high'),
                code: props.language.code
              }]
            },
            type: 'RADIO',
  
          },
          isFromQuestionBank: true,
          answers: question.answers.map((answer)=>({...answer, score: 10})),
          dataFromOrigin: question.dataFromOrigin,
        });
      })   
    }else{
      arrayCloneQuestion.forEach((question) => {
        softSkillQuestions.push({
          uuid: window.COMMON.genKey(),
          group: dataSave._id,
          question: {
            ...softSkillQuestions[softSkillQuestions.length - 1].question,
            ...question.question,
            type: 'RADIO' 
          },
          isFromQuestionBank: true,
          answers: question.answers.map((answer)=>({...answer, score: 10})),
          dataFromOrigin: question.dataFromOrigin,
        });
      })   
    }

    setDataQA([...softSkillQuestions]);
  }

  const addQAQuestionFromBank = (arrayCloneQuestion)=>{
    const newDataQA = getDataFromFormQA()
    arrayCloneQuestion.forEach((question) => {
      newDataQA.push({
        uuid: window.COMMON.genKey(),
        group: dataSave._id,
        question: question.question,
        answers: question.answers,
        dataFromOrigin: question.dataFromOrigin,
        isFromQuestionBank: true
      });
    })
    setDataQA([...newDataQA]);
  }
  const getQuestionsFromBank = (arrayCloneQuestion) => {
    if(dataSave?.type === 1){
      //QA
      addQAQuestionFromBank(arrayCloneQuestion)
    } else {
      //Softskill
      addSoftSkillQuestionFromBank(arrayCloneQuestion)
    } 
  }

  const handleValidStandardOfConclusion = () => {
    if(dataSave.standardOfConclusion){
      const { min, max } = dataSave.standardOfConclusion
      if(min < window.CONSTANT.MIN_STANDARD_OF_CATEGORY || max > window.CONSTANT.MAX_STANDARD_OF_CATEGORY) {
        return false
      }
    }
    return true
  }

  const handleChangeStandardOfConclusion = (newValue) => {
    const minValue = Math.min(...newValue)
    const maxValue = Math.max(...newValue)
    dataSave.standardOfConclusion = {
      min: minValue,
      max: maxValue
    }
    setDataSave({...dataSave})
  }

  if (typeof window === 'undefined') {
    return null;
  }

  return (
    <div className="container-row">
      <MDBRow id="container-data" className="wow fadeIn animated" data-wow-delay="1s">
        <MDBCol>
          <ul className="list-group">
            <li className="list-group-item">
              <div className="d-flex align-items-center w-100">
                <div className="bd-highlight mr-auto">
                  <RadioBox data={list} value={filter.radio} className="m-0" handleChange={handleFilterRadio}></RadioBox>
                </div>
                {
                  filter.radio === 1 ? <div className="mr-2" style={{ width: '350px' }}>
                    <MDBInputGroup type="text" value={filter.text} hint={window.I18N('search_schedule_name')} onChange={handleFilterChange} maxLength="100" pattern="\S(.*\S)?" required
                      append={
                        <span className="input-group-text cursor-pointer" id="btn-search" onClick={() => searchData(data)}>
                          <MDBIcon fa="true" icon="search" className="fa-fw"></MDBIcon>
                        </span>
                      }
                    />
                  </div> : <></>
                }
                {
                  !window.COMMON.checkRoleIsSystem() ? <div></div> : <div className="bd-highlight mr-2" style={{ width: '300px' }}>
                    <div className="input-group input-group-transparent">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <MDBIcon fa="true" icon="building" className="fa-fw" />
                        </span>
                      </div>
                      <Select className="md-form m-0" placeholder={window.I18N('choose_option')} value={filter.company} options={options.company} onChange={handleFilterSelect} isSearchable />
                    </div>
                  </div>
                }
                <div className="bd-highlight">
                  <MDBBtn color="primary" className="mr-0" onClick={filter.radio === 0 ? () => showTemplateGroupQuestion(-1) : () => showContainerSave(-1)}>
                    <MDBIcon fa="true" icon="plus-circle" className="fa-fw" /> {window.I18N('btn_add')}
                  </MDBBtn>
                </div>
              </div>
            </li>
            <SumInfoFilter dataSum={dataSum} filterForQuery={filterForQuery} handleFilterChange={(param) => { setFilterForQuery({ ...filterForQuery, ...param }) }} page="survey"></SumInfoFilter>
            {
              filter.radio === 0 ? <div className="mt-3"><GridItem data={data} handleItemClick={(index) => showContainerGroupQuestion(index, false)} handleItemDelClick={openDeleteGroupQuestionDialog}></GridItem></div>
                : <GridItemSchedule data={dataSchedule} handleEdit={showContainerSave} handleDelete={openDeleteDialog} 
                handleStart={(index) => openTaskSchedule(index, 1)} handlePause={(index) => openTaskSchedule(index, -1)} handleStop={(index) => openTaskSchedule(index, -2)}></GridItemSchedule>
            }
            {
              filter.radio === 0 ? <></>
                : <li className="list-group-item"><Pagination total={pagination.total} pageSize={pagination.pageSize} currentPage={pagination.currentPage} changePage={changePage}></Pagination></li>
            }
          </ul>
        </MDBCol>
      </MDBRow>

      <Panel id="container-save" title={window.I18N('group_qa')} icon="question-circle" style={{ display: 'none' }}>
        <Tab id="tabs-question" data={dataSave.isPublic ? dataSaveTabs.slice(0, 2) : dataSaveTabs}>
          <div id="tab-1" className="tab-pane fade active show">
            <div className="d-flex align-items-center justify-content-end mb-3 wow fadeInDown animated" data-wow-delay="0.5s">
              <MDBBtn color="" onClick={window.COMMON.backContainerData}>
                <MDBIcon fa="true" icon="arrow-left" className="fa-fw" /> {window.I18N('btn_back')}
              </MDBBtn>
              <MDBBtn color="primary" className="mr-0" type="button" disabled={object.checkSchedule} onClick={object.checkSchedule ? () => { } : openSaveGroupQuestion}>
                <MDBIcon fa="true" icon="save" className="fa-fw" /> {window.I18N('btn_save')}
              </MDBBtn>
            </div>
            <div className="wow fadeInUp animated" data-wow-delay="0.5s">
              <form id="form" className="needs-validation mt-2" noValidate>
                <MDBRow>
                  <MDBCol sm="12">
                    <div className="media">
                      <div className="text-center mr-3">
                        <MDBCardImage className="img-fluid image-qa" src={window.COMMON.setValueImage(dataSave.image)} hover overlay="white-slight" /><br></br>
                        <InputFile id="file-image" icon="upload" handleFile={handleFile} accept="image/*" description="Image (120 x 120) pixels"></InputFile>
                      </div>
                      <div className="media-body">
                        <MDBInput outline containerClass="mt-0" type="text" label={window.I18N('title') + ' *'} name="name" value={window.COMMON.getValueWithLanguage(dataSave, 'name', language.current)} onChange={handleChangeWithLanguage} maxLength="200" pattern="\S(.*\S)?" required></MDBInput>
                        <MDBInput outline value={dataSave.code} name="code" onChange={handleChange} type="text" label={window.I18N('code') + ' *'} maxLength="50" pattern="\S(.*\S)?" required></MDBInput>
                        <MDBRow>
                          <MDBCol sm="12" md="6">
                            <MDBInput outline type="checkbox" id="ck-share-survey" name="isShared" label={window.I18N('is_shared')} containerClass="p-0" filled checked={dataSave.isShared} onChange={handleChange}></MDBInput>
                          </MDBCol>
                          <MDBCol sm="12" md="6">
                            <MDBInput outline type="checkbox" id="ck-public-survey" name="isPublic" label={window.I18N('is_public_survey')} containerClass="p-0" filled checked={dataSave.isPublic} onChange={handleChange}></MDBInput>
                          </MDBCol>
                        </MDBRow>
                        <MDBInput outline type="textarea" label={window.I18N('description')} name="description" value={window.COMMON.getValueWithLanguage(dataSave, 'description', language.current)} onChange={handleChangeWithLanguage} maxLength="5000"></MDBInput>
                        <ListOption data={dataSave.hashtag} placeholder={window.I18N('input_hashtag')} handleReceiveData={receiveHashtag}></ListOption>
                        {/* final conclusion definition start*/}
                        {dataSave?.type === 2 ? (
                          <MDBCol md='12' className='d-flex flex-column'>
                            {/* Standard of conclusion */}
                            <div className='d-flex align-items-center justify-content-between'>
                              <h3
                                style={{
                                  fontSize: '16px',
                                  fontWeight: 'bold',
                                  marginTop: '30px'
                                }}
                              >
                                {window.I18N('survey_final_conclusion')}
                              </h3>
                              {/* Standard of conclusion */}
                              <div
                              style={{
                                width: '20%',
                                marginLeft: '20px'
                              }}
                              className='category-div'
                            >

                              <div>{window.I18N('standard_of_category')}</div>
                              <Slider
                                  value={[dataSave.standardOfConclusion.min ?? window.CONSTANT.MIN_STANDARD_OF_CATEGORY , dataSave.standardOfConclusion.max ?? window.CONSTANT.MAX_STANDARD_OF_CATEGORY ]}
                                  onChange={(event, newValue) => handleChangeStandardOfConclusion(newValue)}
                                  valueLabelDisplay="auto"
                                  aria-labelledby="range-slider"
                              />
                              <div className='d-flex align-items-center justify-content-between' style={{ marginTop: '-10px' }}>
                                <div>{0}</div>
                                <div>{100}</div>
                              </div>
                              </div>
                            </div>

                            {/* list question */}
                            <div
                              style={{
                                marginTop: '10px',
                                marginBottom: '10px',
                                width: '100%'
                              }}
                            >
                              <div style={{ position: 'relative' }}>
                                <MDBInput
                                  label={window.I18N('low')}
                                  style={{
                                    margin: 0,
                                    minHeight: '60px'
                                  }}
                                  type='textarea' rows='1' outline maxLength="500"
                                  name={'low'}
                                  value={window.COMMON.getValueWithLanguage(dataSave.finalConclusion, 'low', language.current)}
                                  onChange={e => handleFinalConclusionTextChange(e)}
                                />
                                 <span style={{ position: 'absolute', right: 0, top: '-20px' }} >{`${window.COMMON.getValueWithLanguage(dataSave.finalConclusion, 'low', language.current).length}/500`}</span>
                              </div>
                              <div style={{ position: 'relative' }}>
                                <MDBInput
                                  label={window.I18N('medium')}
                                  style={{
                                    margin: 0,
                                    minHeight: '60px'
                                  }}
                                  type='textarea' rows='1' outline maxLength="500"
                                  name={'medium'}
                                  value={window.COMMON.getValueWithLanguage(dataSave.finalConclusion, 'medium', language.current)}
                                  onChange={e => handleFinalConclusionTextChange(e)}
                                />
                                <span style={{ position: 'absolute', right: 0, top: '-20px' }} >{`${window.COMMON.getValueWithLanguage(dataSave.finalConclusion, 'medium', language.current).length}/500`}</span>
                              </div>
                              <div style={{ position: 'relative' }}>
                                <MDBInput
                                  label={window.I18N('high')}
                                  style={{
                                    margin: 0,
                                    minHeight: '60px'
                                  }}
                                  type='textarea' rows='1' outline maxLength="500"
                                  name={'high'}
                                  value={window.COMMON.getValueWithLanguage(dataSave.finalConclusion, 'high', language.current)}
                                  onChange={e => handleFinalConclusionTextChange(e)}
                                />
                                <span style={{ position: 'absolute', right: 0, top: '-20px' }} >{`${window.COMMON.getValueWithLanguage(dataSave.finalConclusion, 'high', language.current).length}/500`}</span>
                              </div>
                            </div>
                          </MDBCol>
                        ) : null}
                        {/* final conclusion definition end*/}
                      </div>
                    </div>
                  </MDBCol>
                </MDBRow>
              </form>
            </div>
          </div>
          <div id="tab-2" className="tab-pane fade">
            {
              !object.isUpdated ? <p className="mb-0"><small className="text-muted font-weight-bold">{window.I18N('MSG_CODE_045')}</small></p> : <></>
            }
            <div className="d-flex align-items-center justify-content-end mb-3 wow fadeInDown animated" data-wow-delay="0.5s">
              {dataSave?.type === 2 ? (
                <p className="mb-0 mr-auto font-weight-bold">{window.I18N('score_card')}</p>
              ) : (
                <p className="mb-0 mr-auto font-weight-bold">{window.I18N('QA')}</p>
              )}
              {
                COMPANY_TYPE === window.CONSTANT.ADMIN ? <>
                  <div className="d-inline-block">
                    <RadioBox data={types} value={object.radio} className="m-0" handleChange={handleRadio}></RadioBox>
                  </div>
                  <ExportExcel label={window.I18N('btn_template')} isTemplate={true} dataKey={object.radio + '_TEMPLATE'}></ExportExcel>
                  <ImportExcel label={window.I18N('btn_import')} id="file-import" dataKey={'IMPORT_' + object.radio} handleFile={handleFileImport}></ImportExcel>
                </> : <></>
              }
              <MDBBtn color="" type="button" onClick={() => openPreview(dataSave?.type === 2 ? '#modal-preview-category-questions' : '#modal-preview')}>
                <MDBIcon fa="true" icon="eye" className="fa-fw" /> {window.I18N('preview')}
              </MDBBtn>
              <MDBBtn color="" className="mr-0" onClick={window.COMMON.backContainerData}>
                <MDBIcon fa="true" icon="arrow-left" className="fa-fw" /> {window.I18N('btn_back')}
              </MDBBtn>
            </div>
            <MDBRow>
              {dataSave?.type === 2 ? (
                <>
                  <MDBCol size="3">
                      <div style={{position:"fixed", width:"20%"}}>
                        <QuestionBank language={props.language.code} getQuestionsFromBank={getQuestionsFromBank}></QuestionBank>
                      </div>
                  </MDBCol>
                  <MDBCol>
                    <CategoryQuestionsList
                      groupId={dataSave._id}
                      numberQuestion={dataQA.length}
                      isCloned={object.isCloned}
                      checkSchedule={object.checkSchedule}
                      group={object.groupId}
                      // language={language.current}
                      language={'EN'}
  
                      dataQA={dataQA}
                      setDataQA={setDataQA}
                      addItemQuestion={addItemQuestion}
  
                      questionsGroupByCategory={questionsGroupByCategory}
                      setQuestionsGroupByCategory={setQuestionsGroupByCategory}
                      ref={categoryListRef}
                    />
                  </MDBCol>
                </>
              ) : (
               <>
                  <MDBCol size="3">
                    <div style={{position:"fixed", width:"20%"}}>
                      <QuestionBank language={props.language.code} getQuestionsFromBank={getQuestionsFromBank}></QuestionBank>
                    </div>
                  </MDBCol>
                  <MDBCol>
                    {dataQA.map((item, i) => {
                      return (
                        <ItemFormQuestion
                          numberQuestion={dataQA.length}
                          key={item.uuid}
                          index={i}
                          id={item.uuid}
                          item={item.question}
                          dataQA = {dataQA}
                          isCloned={object.isCloned}
                          checkSchedule={object.checkSchedule}
                          answers={item.answers}
                          group={object.groupId}
                          // language={language.current}
                          language={'EN'}
                          handleSaveItem={addItemQuestion}
                          handleRemoveItem={removeItemQuestion}
                          getDataFromFormQA={getDataFromFormQA}
                          ref={(el) => itemFormQuestionRef.current[item.uuid] = el}
                          isFromQuestionBank = {item.isFromQuestionBank}
                          dataFromOrigin={item.dataFromOrigin}
                          status={item?.status ?? false}
                        />
                      );
                    }
                    )}
                  </MDBCol>
               </>
              )}
            </MDBRow>

            {(dataSave?.type !== 2) && (
              <div className="float-button wow fadeInDown animated" data-wow-delay="1s">
                <Tooltip title={window.I18N('add_question')}>
                  <Fab color="primary" aria-label="add" size="medium" disabled={object.checkSchedule || !object.isUpdated} onClick={object.checkSchedule || !object.isUpdated ? () => { } : addFormQuestion}>
                    <Add />
                  </Fab>
                </Tooltip>
              </div>
            )}
          </div>
          {!dataSave.isPublic && (
            <div id="tab-3" className="tab-pane fade">
              {
                object.isLoadSchema ? <AddTaskSchedule companiesFullData={companiesFullData} index={object.indexSchedule} object={dataSchedule[object.indexSchedule]} groupId={object.groupId} filter={filter} options={options} callback={getDataTaskSchedule}></AddTaskSchedule> : <></>
              }
            </div>
          )}
        </Tab>
      </Panel>

      <Modal id="modal-template" title={window.I18N('templates_library')} className="modal-xl" styleBody={{ backgroundColor: '#f0f2f5' }} hideSave={true}>
        <MDBRow>
          <MDBCol className="wow fadeIn animated" data-wow-delay="0.5s">
            <GridItem data={dataShared} isTemplate={true} handleItemClick={(index) => showContainerGroupQuestion(index, true)}></GridItem>
          </MDBCol>
        </MDBRow>
      </Modal>

      <Modal id="modal-import" className="modal-xl" title={window.I18N('import_data')} saveEvent={importData}>
        <Table id="table-import" className="table" config={dataImport.config} data={dataImport.data} handleRowDelClick={deleteImportRow} isImported={true}></Table>
      </Modal>
      {/* modal preview of question list */}
      <Modal id="modal-preview" title={window.I18N('preview')} className="modal-xl" hideSave={!object.isCloned || !object.isUpdated} saveBtn={window.I18N('btn_save_all')} saveEvent={saveMultiQA}>
        <MDBRow>
          <MDBCol>
            {
              dataQA.map((item, i) => <ItemPreviewQuestion key={i} index={i} id={item.uuid} item={item.question} answers={item.answers} language={language.current} />)
            }
          </MDBCol>
        </MDBRow>
      </Modal>
      {/* modal preview of category question list */}
      <PreviewModalCategoryQuestions
        questionsGroupByCategory={questionsGroupByCategory}
        language={language.current}
      />
    </div>
  );
}

const mapStateToProps = state => ({
  language: state.language.value
});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(QuestionPage);