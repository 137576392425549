import React, { useImperativeHandle, useRef, useState } from 'react'
import { MDBIcon } from 'mdbreact'

import InputFile from '../../../../components/InputFile'
import Button from '../../../../components/Button'
import Carousel from '../../../../components/Carousel'
import styles from './CoverImage.module.css'
import Image from '../../../../components/Image'
import useClickOutSide from '../../../../../hooks/useClickOutSide'

const CoverImage = React.forwardRef(({ isEdit = false }, ref) => {
    const [coverImage, setCoverImage] = useState({
        previewURL: [],
        imageFile: []
    })
    const [cacheDataForDelete, setCacheDataForDelete] = useState([])
    const [openListImgDelete, setOpenListImgDelete] = useState(false)

    const inputFileRef = useRef()
    const listImageDropdownRef = useRef()

    const handleCloseListImgDelete = () => {
        setOpenListImgDelete(false)
    }

    const handleOpenListImgDelete = (e) => {
        e.preventDefault()
        e.stopPropagation()
        if (openListImgDelete) {
            return
        }
        setOpenListImgDelete(true)
    }

    const handleFile = (files) => {
        const dataImages = files.reduce(
            (acc, file) => {
                const blobImage = window.COMMON.createBlobImage(file)
                const previewURL = { imageUrl: blobImage, rootImage: false, isDeleted: false }
                const imageFile = { file, previewMapping: blobImage, isDeleted: false }
                acc.previewURL.push(previewURL)
                acc.imageFile.push(imageFile)
                return acc
            },
            {
                previewURL: [],
                imageFile: []
            }
        )

        setCoverImage((prev) => ({
            previewURL: [...prev.previewURL, ...dataImages.previewURL],
            imageFile: [...prev.imageFile, ...dataImages.imageFile]
        }))
    }

    const handleInputFile = (e) => {
        e.preventDefault()
        e.stopPropagation()
        inputFileRef.current.openImportFile()
    }

    const handleDeleteImages = (event) => {
        event.preventDefault()
        event.stopPropagation()
        setCoverImage((prev) => ({
            imageFile: prev.imageFile.reduce(
                (acc, item) =>
                    cacheDataForDelete.includes(item.previewMapping)
                        ? [...acc, { ...item, isDeleted: true }]
                        : [...acc, item],
                []
            ),
            previewURL: prev.previewURL.reduce(
                (acc, item) =>
                    cacheDataForDelete.includes(item.imageUrl)
                        ? [...acc, { ...item, isDeleted: true }]
                        : [...acc, item],
                []
            )
        }))
        setCacheDataForDelete([])
        handleCloseListImgDelete()
        window.COMMON.showMessage('success', 'MSG_CODE_003', window.I18N('MSG_CODE_003'))
    }

    const collectIndexForDelete = (previewUrl) => {
        const isExist = cacheDataForDelete.find((item) => item === previewUrl)
        if (isExist) {
            const updateCacheData = window.COMMON.removeElementInArray(cacheDataForDelete, previewUrl)
            setCacheDataForDelete(updateCacheData)
        } else {
            setCacheDataForDelete((prev) => [...prev, previewUrl])
        }
    }

    useImperativeHandle(ref, () => ({
        getImages: () => {
            const { previewURL, imageFile } = coverImage
            if ((window.COMMON.isEmpty(previewURL) || window.COMMON.isEmpty(imageFile)) && !isEdit) {
                return null
            }
            const imagesData = {
                lastImages: previewURL.reduce(
                    (acc, item) => (!item.isDeleted && item.rootImage ? [...acc, item.imageUrl] : acc),
                    []
                ),
                imagesForUpload: imageFile.reduce((acc, item) => (!item.isDeleted ? [...acc, item.file] : acc), [])
            }
            if (isEdit) {
                return imagesData
            }
            return imagesData.imagesForUpload
        },
        setCoverImages: (images) => {
            //TODO: delay setState when rendering component
            setTimeout(() => {
                setCoverImage({
                    previewURL: images.map((image) => ({ imageUrl: image, rootImage: true, isDeleted: false })),
                    imageFile: []
                })
            }, 100)
        },
        resetImages: () => {
            setCoverImage({
                previewURL: [],
                imageFile: []
            })
        }
    }))

    useClickOutSide({
        ref: listImageDropdownRef,
        handleOutside: handleCloseListImgDelete
    })

    return (
        <div className={styles['cover-images-container']}>
            {(!window.COMMON.isEmpty(coverImage.previewURL) || !window.COMMON.isEmpty(coverImage.imageFile)) && (
                <div className="h-100">
                    <Carousel
                        data={coverImage.previewURL.filter((image) => !image.isDeleted)}
                        showControls={coverImage.previewURL.filter((image) => !image.isDeleted).length > 1}
                    />
                </div>
            )}
            <div className={styles['button-action-container']}>
                <Button iconLeft="images" variant="input" onClick={handleInputFile} type="button">
                    {window.I18N('add_images')}
                </Button>
                <Button iconLeft="trash-alt" variant="input" onClick={handleOpenListImgDelete} type="button">
                    {window.I18N('delete_images')}
                </Button>
                <div style={{ display: 'none' }}>
                    <InputFile
                        disabled={true}
                        ref={inputFileRef}
                        id={isEdit ? 'edit-event' : 'create-event'}
                        icon="upload"
                        handleFile={handleFile}
                        accept="image/*"
                        isMulti={true}
                    />
                </div>
            </div>
            <div
                className={`${styles['list-images-popup']} ${openListImgDelete ? styles['active'] : ''}`}
                ref={listImageDropdownRef}
            >
                <div className="mb-2 w-100 d-flex align-items-center justify-content-between">
                    <h6 className="m-0" style={{ fontSize: '1rem', fontWeight: 500 }}>
                        {window.I18N('choose_images_delete')}
                    </h6>
                    <div
                        className="d-flex align-items-center justify-content-center"
                        style={{ cursor: 'pointer' }}
                        onClick={handleCloseListImgDelete}
                    >
                        <MDBIcon fa="true" icon="minus" style={{ fontSize: '1rem' }} />
                    </div>
                </div>
                <div className="w-100" style={{ overflow: 'overlay', overscrollBehavior: 'contain' }}>
                    {coverImage.previewURL
                        .filter((item) => !item.isDeleted)
                        .map((item) => (
                            <div
                                key={item.imageUrl}
                                className={styles['item-image']}
                                onClick={() => collectIndexForDelete(item.imageUrl)}
                            >
                                {/* Cover image */}
                                <div
                                    className="d-flex align-items-center"
                                    style={{ gap: '8px', height: '100px', borderRadius: '8px' }}
                                >
                                    <div className={styles['cover-img-container']}>
                                        <Image
                                            className="w-100 h-100 rounded-lg"
                                            src={item.imageUrl}
                                            alt=""
                                            loading="lazy"
                                        />
                                    </div>
                                </div>
                                <div>
                                    <MDBIcon
                                        far
                                        icon={cacheDataForDelete?.includes(item.imageUrl) ? 'check-circle' : 'circle'}
                                        style={{ fontSize: '20px' }}
                                    />
                                </div>
                            </div>
                        ))}
                </div>
                <div className={styles['delete-button']}>
                    <Button
                        iconLeft="trash-alt"
                        variant="light"
                        disabled={window.COMMON.isEmpty(cacheDataForDelete)}
                        onClick={(e) => handleDeleteImages(e, cacheDataForDelete)}
                    >
                        <span>{window.I18N('btn_delete')}</span>
                    </Button>
                </div>
            </div>
        </div>
    )
})

export default CoverImage
