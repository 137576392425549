import axios from 'axios';

const API_URL = process.env.REACT_APP_UPLOAD_URL_API;

const UploadAPI = {
  UPLOAD_FILE: '/upload',
  FILE: '/file',

  async getAPI(action, params) {
    window.showLoading();
    try {
      const token = localStorage.getItem(window.CONSTANT.AUTHEN_TOKEN);
      if (token) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      }
      const result = await axios.get(API_URL.concat(action), {
        params
      });
      if (result.data && result.data.data) {
        window.hideLoading();
        return result.data.data;
      }
    } catch (error) {
      console.log(error);
    }
    window.hideLoading();
    return null;
  },

  async uploadFile(files, onUploadProgress) {
    try {
      const token = localStorage.getItem(window.CONSTANT.AUTHEN_TOKEN);
      if (token) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      }
      axios.defaults.headers.common['Content-Type'] = 'multipart/form-data';
      const formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        formData.append(file.name, file);
      }
      formData.append('public', JSON.stringify({
        permission: 'viewer', 
        status: true
      }));
      return axios.post(API_URL.concat(this.UPLOAD_FILE), formData, {
        onUploadProgress
      });
    } catch (error) {
      console.log(error);
    }
    return null;
  },

  async downloadFile(id, name) {
    try {
      const token = localStorage.getItem(window.CONSTANT.AUTHEN_TOKEN);
      if (token) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      }
      axios({
        url: API_URL.concat(this.FILE) + '/' + id,
        method: 'GET',
        responseType: 'blob'
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', name);
        document.body.appendChild(link);
        link.click();
      });
    } catch (error) {
      console.log(error);
    }
    return null;
  },

  async deleteFile(id) {
    try {
      const token = localStorage.getItem(window.CONSTANT.AUTHEN_TOKEN);
      if (token) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      }
      return axios.delete(API_URL.concat(this.FILE) + '/' + id);
    } catch (error) {
      console.log(error);
    }
    return null;
  },

  async deleteMultiFile(ids = []) {
    if(window.COMMON.isEmpty(ids)){
      return
    }
    try {
      const result = await window.COMMON.httpRequest({
        url: `${API_URL}/delete`,
        method: 'DELETE',
        requireToken: true,
        input: {
          data : {
            items: ids
          } 
        }
      })
      return result;
    } catch (error) {
      console.error("🚀 ~ deleteMultiFile ~ error:", error)
    }
    return null;
  },

};

export default UploadAPI;
