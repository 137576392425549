import { Button, Fab, Slider, Tooltip } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { MDBBtn, MDBCol, MDBIcon, MDBInput, MDBRow } from 'mdbreact';
import React, { Fragment, useCallback, useEffect, useImperativeHandle, useLayoutEffect, useRef, useState } from 'react';
import PreviewModalCategoryQuestions from '../../../components/CategoryQuestionPreview/PreviewModalCategoryQuestions';
import ExportExcel from '../../../components/ExportExcel';
import ImportExcel from '../../../components/ImportExcel';
import Modal from '../../../components/Modal';
import RadioBox from '../../../components/RadioBox';
import TableCategoryQuestion from '../../../components/TableCategoryQuestion';
import useGetSurveyQAData from '../../assessment/hooks/useGetSurveyQAData';
import FormQuestionItemWithScore from './FormQuestionItemWithScore';
import QuestionBank from '../../../components/QuestionBank';


const SoftKillQuestionForm = React.forwardRef((props, ref) => {
  const { groupQA, dataSaveGroupQuestion, setDataSaveGroupQuestion, groupQACloneData } = props;

  const types = [
    { value: 'GROUP_QUESTION_SURVEY', label: window.I18N('group_questions') },
  ];
  const { getQAData } = useGetSurveyQAData({
    groupQA
  });
  const [questionsGroupByCategory, setQuestionsGroupByCategory] = useState([]);
  const language = useRef();
  const formQuestionItemsRef = useRef({});

  const [object, setObject] = useState({ index: -1, indexSchedule: -1, radio: 'GROUP_QUESTION_SURVEY', status: 1, btnStatus: false, isCreated: false, isLoadSchema: false });
  const [dataImport, setDataImport] = useState({});
  const [previewData, setPreviewData] = useState([]);

  const handleRadio = useCallback((value) => {
    object.radio = value;
    setObject({ ...object });
  }, [object]);

  const handleValidForm = () => {
    // valid category title
    let checkCategoryTitleAndScore = true;
    questionsGroupByCategory.forEach((item) => {
      const { categoryTitle, standardOfCategory, standardLowOfCategory } = item;
      if (!categoryTitle) {
        window.COMMON.showMessage('warning', window.I18N('MSG_CODE_070'));
        checkCategoryTitleAndScore = false;
      }
      // Compare standard of category with range standard lower and upper
      if (Number(standardLowOfCategory) < window.CONSTANT.MIN_STANDARD_OF_CATEGORY || Number(standardOfCategory) > window.CONSTANT.MAX_STANDARD_OF_CATEGORY) {
        window.COMMON.showMessage('warning', window.I18N('MSG_CODE_087', {
          category: categoryTitle,
          min: window.CONSTANT.MIN_STANDARD_OF_CATEGORY,
          max: window.CONSTANT.MAX_STANDARD_OF_CATEGORY
        }))
        checkCategoryTitleAndScore = false;
      } 
    });
    // valid question
    if (formQuestionItemsRef.current) {
      const n = dataSaveGroupQuestion.length;
      if (n < 1) {
        window.COMMON.showMessage('warning', 'MSG_CODE_081', window.I18N('MSG_CODE_081'))
        return false
      }
      for (let i = 0; i < n; i++) {
        const item = dataSaveGroupQuestion[i];
        const validResult = formQuestionItemsRef.current[item.uuid].validQuestion();
        if (validResult === false) {
          return false;
        }
      }

    }

    if (!checkCategoryTitleAndScore) return false;
    return true;

  };
  const handleSaveForm = async (groupQA) => {
    if (formQuestionItemsRef.current) {
      const n = dataSaveGroupQuestion.length;
      for (let i = 0; i < n; i++) {
        const item = dataSaveGroupQuestion[i];
        await formQuestionItemsRef.current[item.uuid].saveQuestionAndAnswer(groupQA);
      }
    }
  };

  const getQuestionAndAnswerFormData = () => {
    const questionAndAnswerFormData = [];
    if (formQuestionItemsRef.current) {
      const n = dataSaveGroupQuestion.length;

      for (let i = 0; i < n; i++) {
        const item = dataSaveGroupQuestion[i];
        const data = formQuestionItemsRef.current[item.uuid].getCurrentQuestionAndAnswerData();
        if (data) {
          const { question, answers } = data;
          questionAndAnswerFormData.push({
            ...item,
            question,
            answers
          });
        }
      }
    }
    return questionAndAnswerFormData;
  };

  // IMPORT QUESTION & ANSWER
  const handleFileImport = (dataImport) => {
    processingImportData(dataImport);
    window.COMMON.showModal('#modal-import');
  };

  const deleteImportCategoryGroup = useCallback((categoryTitle) => {
    const newData = dataImport.data.filter((item) => {
      const findCategoryTitle = window.COMMON.getValueWithLanguage(item.question, 'category', props.language.code)
      return findCategoryTitle !== categoryTitle;
    });
    dataImport.data = newData;
    setDataImport({ ...dataImport });
  }, [dataImport]);

  const deleteImportQuestion = useCallback((questionId) => {
    const newData = dataImport.data.filter((item) => item.uuid !== questionId);
    dataImport.data = newData;
    setDataImport({ ...dataImport });
  }, [dataImport]);

  const processingImportData = async (dataImport) => {
    if (dataImport.data.length === 0) return;
    const processingData = [];
    const result = [];

    for (let i = 0; i < dataImport.data.length; i++) {
      const row = dataImport.data[i];
      if (row.question) {
        const obj = {
          ...row,
          category: [],
        }
        for (let k = i - 1; 0 <= k; k--) {
          const cellCategoryTitle = dataImport.data[k].category_title
          // if cell is a title NOT a number => set value for this
          if (!cellCategoryTitle && obj.category.length > 0) break;
          if (cellCategoryTitle) {
            obj.categoryCode = dataImport.data[k].categoryCode
            obj.category.push({
              text: cellCategoryTitle?.trim() || '',
              code: dataImport.data[k].languageCode
            })
          }
        }
        processingData.push(obj);
      }
    }

    processingData.forEach(item => {
      const { category, categoryCode, questionCode, introduction, question, answers, scores, questionType, languageCode } = item
      if (!question) return;
      const obj = {
        answers: [],
        group: categoryCode,
        uuid: window.COMMON.genKey()
      };
      const questionIndex = result.findIndex(item => {
        if (item.question && item.question.code === questionCode) return true
      })
      if (questionIndex < 0) {
        // processing question
        obj.question = {
          code: questionCode,
          question: [{
            text: question?.trim() || '',
            code: languageCode
          }],
          introduction: [{
            text: introduction?.trim() || '',
            code: languageCode
          }],
          category: category,
          type: questionType,
          _id: null,
          standardLowOfCategory: window.CONSTANT.MIN_STANDARD_OF_CATEGORY,
          standardOfCategory: window.CONSTANT.MAX_STANDARD_OF_CATEGORY,
          recommend: {
            low: [{
              text: window.I18N('low'),
              code: languageCode
            }],
            medium: [{
              text: window.I18N('medium'),
              code: languageCode
            }],
            high: [{
              text: window.I18N('high'),
              code: languageCode
            }]
          }
        }
        // processing answers and score
        if (answers) {
          const answersDecode = answers.replaceAll('\r', '').split('\n');
          const scoresDecode = scores.replaceAll('\r', '').split('\n');

          obj.answers = answersDecode.map((answer, index) => {
            return {
              parentCode: null,
              code: `${window.COMMON.generateCode('A')}-${window.COMMON.genKey()}`,
              question: null,
              content: [{
                text: answer?.trim() || '',
                code: languageCode
              }],
              score: Number(scoresDecode[index]) || 0,
            }
          })
        }
        result.push(obj);
      } else {
        // update language
        const findQuestion = result[questionIndex]
        findQuestion.question.question.push({
          text: question?.trim() || '',
          code: languageCode
        })
        findQuestion.question.introduction.push({
          text: introduction?.trim() || '',
          code: languageCode
        })
        findQuestion.question.category = category

        findQuestion.question.recommend.low.push({
          text: window.I18N('low'),
          code: languageCode
        })
        findQuestion.question.recommend.medium.push({
          text: window.I18N('medium'),
          code: languageCode
        })
        findQuestion.question.recommend.high.push({
          text: window.I18N('high'),
          code: languageCode
        })
        // processing answers and score
        if (answers) {
          const answersDecode = answers.replaceAll('\r', '').split('\n');

          answersDecode.forEach((answer, index) => {
            result[questionIndex].answers[index].content.push({
              text: answer?.trim() || '',
              code: languageCode
            })
          })
        }

      }
    });

    setDataImport(prev => ({
      ...prev,
      data: result
    }));
  };

  const importData = async () => {
    if (dataImport.data.length === 0) {
      window.COMMON.showMessage('warning', 'MSG_CODE_021', window.MSG.MSG_CODE_021);
      return;
    }
    // create question code
    const saveQuestionData = [];
    for (const item of dataImport.data) {
      await new Promise(resolve => setTimeout(resolve, 10));
      saveQuestionData.push({
        ...item,
        question: {
          ...item.question,
          code: window.COMMON.generateCode('Q')
        }
      });
    }
    // handle set dataImported into data in form
    setDataSaveGroupQuestion([...dataSaveGroupQuestion, ...saveQuestionData]);
    window.COMMON.hideModal('#modal-import');
  };

  const getDataCategoryList = () => {
    const newQuestionsGroupByCategory = questionsGroupByCategory.map((group) => {
      const { categoryTitle, questions } = group;

      const questionsData = questions.map((item) => {
        const { question, answers } = formQuestionItemsRef.current[item.uuid].getCurrentQuestionAndAnswerData();
        return {
          ...item,
          answers,
          question
        };
      });
      return {
        categoryTitle,
        questions: questionsData
      };
    });
    return newQuestionsGroupByCategory
  }

  const openPreview = () => {
    const newPreviewData = getDataCategoryList();

    setPreviewData(newPreviewData);
    window.COMMON.showModal('#modal-preview-category-questions');
  };

  // GROUP QUESTIONS FUNCTIONS
  const handleTitleChange = (e, groupIndex) => {
    // update title of group
    // update title for every question in group
    const groupQuestions = questionsGroupByCategory[groupIndex].questions;
    const newDataQuestions = dataSaveGroupQuestion.map(item => {
      if (groupQuestions.findIndex(q => q.uuid === item.uuid) >= 0) {
        const findCategoryTitle = item.question.category.find(c => c.code === props.language.code)
        if (!item?.question?.category?.length || !findCategoryTitle) {
          item.question.category = [
            {
              text: "",
              code: props.language.code
            }
          ]
        }
        findCategoryTitle.text = e.target.value
      }
      return item
    })
    setDataSaveGroupQuestion(newDataQuestions);

  }

  const handleChangePassScore = (newValue, groupIndex) => {
    const minValue = Math.min(...newValue)
    const maxValue = Math.max(...newValue)
    const currentQuestionGroupByCategory = getDataFromListCategory()
    const groupQuestions = questionsGroupByCategory[groupIndex].questions;
    const newDataQuestions = currentQuestionGroupByCategory.map(item => {
      if (groupQuestions.findIndex(q => q.uuid === item.uuid) >= 0) {
        item.question.standardLowOfCategory = minValue
        item.question.standardOfCategory = maxValue
      }
      return item
    })
    setDataSaveGroupQuestion(newDataQuestions);
  }


  const handleRecommendTextChange = (e, groupIndex) => {
    const { name: level, value } = e.target;
    const groupQuestions = questionsGroupByCategory[groupIndex].questions;
    const newDataQuestions = dataSaveGroupQuestion.map(item => {
      if (groupQuestions.findIndex(q => q.uuid === item.uuid) >= 0) {
        if (!item?.question.recommend) {
          item.question.recommend = {
            low: [{
              text: window.I18N('low'),
              code: props.language.code
            }],
            medium: [{
              text: window.I18N('medium'),
              code: props.language.code
            }],
            high: [{
              text: window.I18N('high'),
              code: props.language.code
            }]
          }
        }
        item.question.recommend[level].find(c => c.code === props.language.code).text = value
      }
      return item
    })
    setDataSaveGroupQuestion(newDataQuestions);
  };
  const addGroupQuestions = () => {
    setDataSaveGroupQuestion(prev => (
      [
        ...prev,
        {
          uuid: window.COMMON.genKey(),
          group: groupQA._id,
          question: {
            _id: null,
            question: [],
            standardLowOfCategory: window.CONSTANT.MIN_STANDARD_OF_CATEGORY,
            standardOfCategory: window.CONSTANT.MAX_STANDARD_OF_CATEGORY,
            introduction: [],
            type: 'RADIO',
            code: window.COMMON.generateCode('Q'),
            category: [
              {
                text: "",
                code: props.language.code
              }
            ],
            recommend: {
              low: [{
                text: window.I18N('low'),
                code: props.language.code
              }],
              medium: [{
                text: window.I18N('medium'),
                code: props.language.code
              }],
              high: [{
                text: window.I18N('high'),
                code: props.language.code
              }]
            }
          },
          answers: [],
        }
      ]
    ));
    setTimeout(()=>{window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' })},500)
  };

  const getDataFromListCategory = () => {
      const newDataSaveGroupQuestion = dataSaveGroupQuestion.map((item)=>{
          const { question, answers } = formQuestionItemsRef.current[item.uuid].getCurrentQuestionAndAnswerData();
          return {
              ...item,
              answers,
              question: {
                ...item.question,
                ...question
              }
          }
      })

      return newDataSaveGroupQuestion
  }

  const handleDeleteGroupQuestion = (categoryTitle) => {
    const newdataSaveGroupQuestion = getDataFromListCategory();
    const newData = newdataSaveGroupQuestion.filter((item) => {
      const findCategoryTitle = window.COMMON.getValueWithLanguage(item.question, 'category', props.language.code)
      return findCategoryTitle !== categoryTitle
    });
    setDataSaveGroupQuestion(newData);
  };
  const onDeleteGroupQuestion = (categoryTitle) => {
    window.COMMON.showModal('#modal-delete');
    window.deleteMethod = () => {
      handleDeleteGroupQuestion(categoryTitle);
    };
  };

  // QUESTION & ANSWER FUNCTIONS

  const removeItemQuestion = (questionId) => {

    const newData = dataSaveGroupQuestion.filter((item) => item.uuid !== questionId);
    setDataSaveGroupQuestion(newData);
  };


  const addFormQuestion = (categoryTitle, standardLowOfCategory,standardOfCategory, recommend) => {
    dataSaveGroupQuestion.push({
      uuid: window.COMMON.genKey(),
      group: groupQA._id,
      question: {
        _id: null,
        question: [],
        standardLowOfCategory,
        standardOfCategory,
        introduction: [],
        type: 'RADIO',
        code: window.COMMON.generateCode('Q'),
        category: [
          {
            text: categoryTitle,
            code: props.language.code
          }
        ],
        recommend,
      },
      answers: [],
    });
    setDataSaveGroupQuestion([...dataSaveGroupQuestion]);
  };

  useLayoutEffect(() => {
    if (!props.language.code) {
      return;
    }
    language.current = props.language.code;
  }, [props.language]);
  useImperativeHandle(ref, () => ({
    handleValidForm,
    handleSaveForm,
    getQuestionAndAnswerFormData
  }));
  useEffect(() => {
    if (!groupQACloneData) return;
    const groupId = groupQACloneData.groupId;
    if (!groupId) return;
    getQAData(groupId, setDataSaveGroupQuestion);

  }, [groupQACloneData]);

  useEffect(() => {
    if (!dataSaveGroupQuestion) return;
    const result = []
    dataSaveGroupQuestion.forEach((item) => {
      const findCategoryTitle = window.COMMON.getValueWithLanguage(item.question, 'category', props.language.code)
      const categoryIndex = result.findIndex(item => item.categoryTitle === findCategoryTitle)

      if (categoryIndex === -1) {
        result.push({
          categoryTitle: findCategoryTitle,
          standardLowOfCategory: item.question.standardLowOfCategory,
          standardOfCategory: item.question.standardOfCategory,
          recommend: item.question.recommend,
          questions: [item]
        })
      } else {
        result[categoryIndex] = {
          ...result[categoryIndex],
          questions: [
            ...result[categoryIndex].questions,
            item
          ],
        }
      }
    });
    setQuestionsGroupByCategory(result)
  }, [dataSaveGroupQuestion, props.language.code])

  const getQuestionsFromBank = (arrayCloneQuestion) => {
    if(dataSaveGroupQuestion.length === 0){
      arrayCloneQuestion.forEach((question,index) => {
        dataSaveGroupQuestion.push({
          uuid: window.COMMON.genKey(),
          group: groupQA._id,
          question: {
            ...question.question,
            standardLowOfCategory: window.CONSTANT.MIN_STANDARD_OF_CATEGORY,
            standardOfCategory: window.CONSTANT.MAX_STANDARD_OF_CATEGORY,
            category: [
              {
                text: "",
                code: props.language.code
              }
            ],
            recommend: {
              low: [{
                text: window.I18N('low'),
                code: props.language.code
              }],
              medium: [{
                text: window.I18N('medium'),
                code: props.language.code
              }],
              high: [{
                text: window.I18N('high'),
                code: props.language.code
              }]
            },
            type: 'RADIO',
  
          },
          answers: question.answers.map((answer)=>({...answer, score: 10})),
          dataFromOrigin: question.dataFromOrigin,
        });
      })   
    }else{
      arrayCloneQuestion.forEach((question,index) => {
        dataSaveGroupQuestion.push({
          uuid: window.COMMON.genKey(),
          group: groupQA._id,
          question: {
            //...dataSaveGroupQuestion[dataSaveGroupQuestion.length - 1].question,
            standardLowOfCategory: questionsGroupByCategory[questionsGroupByCategory.length - 1].standardLowOfCategory,
            standardOfCategory: questionsGroupByCategory[questionsGroupByCategory.length - 1].standardOfCategory,
            recommend: questionsGroupByCategory[questionsGroupByCategory.length - 1].recommend,
            category: [{ text: questionsGroupByCategory[questionsGroupByCategory.length - 1].categoryTitle, code: "EN" }],
            ...question.question,
            type: 'RADIO' 
          },
          answers: question.answers.map((answer)=>({...answer, score: 10})),
          dataFromOrigin: question.dataFromOrigin,
        });
      })   
    }
      setDataSaveGroupQuestion([...dataSaveGroupQuestion]);
    }
  
  return <Fragment>
    <div className="d-flex align-items-center justify-content-end mb-3 wow fadeInDown animated" data-wow-delay="0.5s">
      <div className="block mr-auto">
        <p className="mb-0 mr-auto font-weight-bold">{window.I18N('soft_skills_question')}</p>
      </div>
      {window.COMMON.checkRoleIsSystem() && <>
        <div className="d-inline-block">
          <RadioBox data={types} value={object.radio} className="m-0" handleChange={handleRadio}></RadioBox>
        </div>
        <ExportExcel label={window.I18N('btn_template')} isTemplate={true} dataKey={object.radio + '_TEMPLATE'}></ExportExcel>
        <ImportExcel label={window.I18N('btn_import')} id="file-import" dataKey={'IMPORT_' + object.radio} handleFile={handleFileImport}></ImportExcel>
      </>}

      <MDBBtn color="" type="button" onClick={openPreview}>
        <MDBIcon fa="true" icon="eye" className="fa-fw" /> {window.I18N('preview')}
      </MDBBtn>

    </div>

    {/* questions */}
    <MDBRow>
      <MDBCol size="3">
        <div style={{position:"fixed", width:"20%"}}>
          <QuestionBank 
            language={props.language.code} 
            getQuestionsFromBank={getQuestionsFromBank}>
          </QuestionBank>
        </div>
      </MDBCol>
      <MDBCol>
        {
          questionsGroupByCategory?.map((item, i) => {
            const { categoryTitle = '', questions = [], standardOfCategory, standardLowOfCategory, recommend } = item;

            return (
              <MDBRow className='group-question-container' key={`group-questions-${i}`}>
                {/* title */}
                <MDBCol md='12' className='group-question-header d-flex align-items-center justify-content-center'>
                  <div
                    style={{
                      width: '80%'
                    }}
                  >
                    <MDBInput
                      label={window.I18N('category_name') + ' *'}
                      type='text'
                      rows='2'
                      value={categoryTitle}
                      onChange={e => handleTitleChange(e, i)}
                      className='group-question-header-input'
                      required
                      outline
                      maxLength="100"
                    />
                  </div>
                  {/* standard of question in category */}
                  <div
                    style={{
                      width: '20%',
                      marginLeft: '20px'
                    }}
                    className='category-div'
                  >
                    <div>{window.I18N('standard_of_category')}</div>
                    <Slider
                        value={[standardLowOfCategory, standardOfCategory]}
                        onChange={(event, newValue) => handleChangePassScore(newValue, i)}
                        valueLabelDisplay="auto"
                        aria-labelledby="range-slider"
                        getAriaValueText={(value) => {}}
                    />
                    <div className='d-flex align-items-center justify-content-between' style={{ marginTop: '-10px' }}>
                      <div>{0}</div>
                      <div>{100}</div>
                    </div>
                  </div>
                </MDBCol>
                {/* recommend text */}
                <MDBCol md='12' className='group-question-recommend'>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      marginTop: '20px'
                    }}
                  >
                    <h3
                      style={{
                        fontSize: '16px',
                        fontWeight: 'bold',
                      }}
                    >
                      {window.I18N('recommend')}
                    </h3>
                  </div>
                  {/* list question */}
                  <div
                    style={{
                      marginBottom: '30px',
                      width: '80%'
                    }}
                  >
                    <MDBInput
                      label={window.I18N('low') + ' *'}
                      className='group-question-header-input'
                      type='text' rows='1' required outline maxLength="100"
                      name={'low'}
                      value={window.COMMON.getValueWithLanguage(recommend, 'low', props.language.code)}
                      onChange={e => handleRecommendTextChange(e, i)}
                    />
                    <MDBInput
                      label={window.I18N('medium') + ' *'}
                      className='group-question-header-input'
                      type='text' rows='1' required outline maxLength="100"
                      name={'medium'}
                      value={window.COMMON.getValueWithLanguage(recommend, 'medium', props.language.code)}
                      onChange={e => handleRecommendTextChange(e, i)}
                    />
                    <MDBInput
                      label={window.I18N('high') + ' *'}
                      className='group-question-header-input'
                      type='text' rows='1' required outline maxLength="100"
                      name={'high'}
                      value={window.COMMON.getValueWithLanguage(recommend, 'high', props.language.code)}
                      onChange={e => handleRecommendTextChange(e, i)}
                    />
                  </div>
                </MDBCol>
                {/* question */}
         
                <MDBCol>
                  {questions.map((item, j) => {
                    return (
                      <FormQuestionItemWithScore
                        key={item.uuid}
                        ref={(el) => formQuestionItemsRef.current[item.uuid] = el}
                        index={j}
                        id={item.uuid}
                        item={item.question}
                        btnStatus={object.btnStatus}
                        answers={item.answers}
                        standardLowOfCategory={standardLowOfCategory}
                        standardOfCategory={standardOfCategory}
                        group={item.group}
                        language={'EN'}
                        handleRemoveItem={removeItemQuestion}
                        dataFromOrigin={item.dataFromOrigin}
                      />
                    )
                  })
                  }

                </MDBCol>
                {/* Add */}
                <MDBCol md="12 p-3" className='group-question-actions'>
                  <Button 
                    color="primary" 
                    variant='contained' 
                    size="small" 
                    onClick={() => addFormQuestion(categoryTitle, standardLowOfCategory, standardOfCategory, recommend)}
                  >
                    {window.I18N('add_question')}
                  </Button>
                  <Button color="secondary" size="small" variant='outlined' onClick={() => onDeleteGroupQuestion(categoryTitle)}>
                    {window.I18N('delete_group')}
                  </Button>
                </MDBCol>
              </MDBRow>
            );
          })
        }
      </MDBCol>
    </MDBRow>

    <div className="float-button wow fadeInDown animated" data-wow-delay="1s">
      <Tooltip title={window.I18N('add_group_category')}>
        <Fab color="primary" aria-label="add" size="medium" onClick={addGroupQuestions}>
          <Add />
        </Fab>
      </Tooltip>
    </div>

    <Modal id="modal-import" className="modal-xl" title={window.I18N('import_data')} saveEvent={importData}>
      <TableCategoryQuestion
        id="table-import"
        className="table"
        config={dataImport.config}
        data={dataImport.data}
        onDeleteCategoryGroup={deleteImportCategoryGroup}
        onDeleteQuestion={deleteImportQuestion}
        isImported={true}
        language={language.current}
      />
    </Modal>

    <PreviewModalCategoryQuestions
      questionsGroupByCategory={previewData}
      language={language.current}
    />
  </Fragment>;
});
export default SoftKillQuestionForm;
