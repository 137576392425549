import React, { useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { StepButtonsContainer, SurveyPagePaperStyled } from '../../../../styles/styled-components/Survey/NewSurveyPageStyled';
import NewSurveyPageSteps, { NewSurveyPageStepStatus } from '../../survey/NewSurveyPageSteps';
import NewCaseForm from './NewCaseForm';
import moment from 'moment';
import Common from '../../../../utils/Common';
import Constants from '../../../../utils/Constant';
import MetadataGRAPHQL from '../../../../graphql/Metadata';
import AccountGRAPHQL from '../../../../graphql/Account';
import { MDBBtn, MDBIcon } from 'mdbreact';
import { useRef } from 'react';
import CaseAssessmentGRAPHQL from '../../../../graphql/CaseAssessment';
import CaseSelfAssessmentForm from './CaseSelfAssessmentForm';
import SelectGroupQA from '../../schedule-assessment/SelectGroupQA';
import QuestionGRAPHQL from '../../../../graphql/Question';
import { connect } from 'react-redux';
import CompanyGRAPHQL from '../../../../graphql/Company';
import MailGRAPHQL from '../../../../graphql/Mail';

const STEPS = [
  {
    id: 'step0',
    name: 'fill_case',
    status: NewSurveyPageStepStatus.PROCESSING
  },
  {
    id: 'step1',
    name: 'fill_assessment',
    status: NewSurveyPageStepStatus.WAITING
  },
  {
    id: 'step2',
    name: 'select_group_qa',
    status: NewSurveyPageStepStatus.WAITING
  }
];

const NewCaseAssessmentPage = (props) => {
  const COMPANY_ID = localStorage.getItem(Constants.COMPANY_ID);
  const CURRENT_USER_ID = localStorage.getItem(Constants.ID);
  const CURRENT_USER_NAME = localStorage.getItem(Constants.FULL_NAME);
  const history = useHistory();

  const typeOptions = [
    { value: '1', label: window.I18N('periodic_assessment') },
    { value: '2', label: window.I18N('requested_assessment') }
  ];
  const periodOptions = [
    { value: '1', label: window.I18N('annually') },
    { value: '2', label: window.I18N('half_year') }
  ];

  const [steps, setSteps] = useState(STEPS);
  const [currentStep, setCurrentStep] = useState({
    index: 0,
    isFirstStep: true,
    isLastStep: false
  });

  const caseFormRef = useRef();
  const selfAssessmentFormRef = useRef();

  const [caseData, setCaseData] = useState({
    name: '',
    code: Common.generateCode('CA'),
    reason: '',
    goal: '',
    approvalReminderDate: new Date(),
    advisors: [],
    companyIndustry: null,
    nation: null,
    city: null,
    state: null,

    assessment: null,
    companies: [],

    influencer: null
  });
  const [selfAssessmentData, setSelfAssessmentData] = useState({
    name: '',
    code: Common.generateCode('SA'),
    description: '',
    startDate: moment().toDate(),
    endDate: moment().add(30, 'd').toDate(),
    type: typeOptions[0],
    period: periodOptions[0],
    selectedGroupQA: [],
    advisors: []
  });
  const [caseOptions, setCaseOptions] = useState({
    industries: [],
    assessments: [],
    companies: [],
    nations: [],
    cities: [],
    states: [],
    companyTypes: [],
    influencers: []
  });
  const [selfAssessmentOptions, setSelfAssessmentOptions] = useState({
    types: typeOptions,
    periods: periodOptions,
    advisors: [],
    groupQA: []
  });

  const [adminInfluencer, setAdminInfluencer] = useState({});
  const [assignedCompanyAdvisors, setAssignedCompanyAdvisors] = useState([]);

  useEffect(() => {
    fetchData();
  }, [props]);

  useEffect(() => {
    if (caseData.nation) {
      const fetchStates = async () => {
        const states = await Common.getDataLocation(caseData.nation?.code);

        setCaseOptions(options => ({
          ...options,
          states
        }));
      };

      fetchStates();
    }
  }, [caseData.nation]);
  useEffect(() => {
    if (caseData.state) {
      const fetchCities = async () => {
        const cities = await Common.getDataLocation(caseData.state?.code);

        setCaseOptions(options => ({
          ...options,
          cities
        }));
      };

      fetchCities();
    }
  }, [caseData.state]);
  // Auto-change self assessment start date in case due date change
  useEffect(() => {
    setSelfAssessmentData(sa => ({
      ...sa,
      startDate: caseData.approvalReminderDate,
      endDate: caseData.approvalReminderDate > sa.endDate ? moment(caseData.approvalReminderDate).add(30, 'd').toDate() : sa.endDate
    }));
  }, [caseData.approvalReminderDate]);

  const fetchData = async () => {
    try {
      const [metadataResult, advisorResult, locationResult, groupQAResult] = await Promise.all([
        // Common.query(
        //   SelfAssessmentGRAPHQL.QUERY_RUNNING_SELF_ASSESSMENTS,
        //   { company: COMPANY_ID },
        //   true
        // ),
        Common.query(
          MetadataGRAPHQL.QUERY_SHORT_METADATA,
          { type: [Constants.METADATA_TYPE.COMPANY_INDUSTRY, Constants.METADATA_TYPE.COMPANY_TYPE] },
          true
        ),
        Common.query(
          AccountGRAPHQL.QUERY_ACCOUNT_BY_INFLUENCER,
          { influencerIds: [COMPANY_ID]},
          true
        ),
        Common.getDataLocation(),
        Common.query(
          QuestionGRAPHQL.QUERY_GROUP_QUESTION,
          { company: COMPANY_ID },
          true
        ),
        Common.getDataAllCompany()
      ]);
  
      // let assessmentOptions = [];
      // if (Common.checkResultData(selfAssessmentResult)) {
      //   assessmentOptions = selfAssessmentResult.data.getRunningSelfAssessments;
      // }
      let companyIndustryOptions = [];
      let companyTypeOptions = [];
      if (Common.checkResultData(metadataResult)) {
        companyIndustryOptions = metadataResult.data.getMetadatas.filter(item => item.type === Constants.METADATA_TYPE.COMPANY_INDUSTRY);
        companyTypeOptions = metadataResult.data.getMetadatas.filter(item => item.type === Constants.METADATA_TYPE.COMPANY_TYPE);
      }
      let advisorOptions = [];
      if (Common.checkResultData(advisorResult)) {
        // Exclude leader advisors
        advisorOptions = advisorResult.data.getAccountByInfluencers;
      }
      let groupQAOptions = [];
      if (Common.checkResultData(groupQAResult)) {
        const language = props.language?.code || null;
        groupQAOptions = groupQAResult.data.getGroupQuestions.map((item, index) => ({
          ...item,
          createdDate: Common.formatDateSingleData(item, 'createdDate'),
          nameLanguage: Common.getValueWithLanguage(item, 'name', language),
          descriptionLanguage: Common.getValueWithLanguage(item, 'description', language),
          index
        }));
      }
      
      const companyData = JSON.parse(localStorage.getItem(Constants.DATA_ALL_COMPANY)) || [];
      const nationOptions = locationResult || [];
  
      setCaseOptions({
        assessments: [],
        industries: companyIndustryOptions,
        companyTypes: companyTypeOptions,
        nations: nationOptions,
        cities: [],
        states: [],
        companies: companyData.filter(item => item.type === Constants.COMPANY),
        influencers: companyData.filter(item => item.type === Constants.INFLUENCER)
      });
      setSelfAssessmentOptions(options => ({
        ...options,
        advisors: advisorOptions,
        groupQA: groupQAOptions
      }));
    } catch (error) {
      console.error("🚀 ~ fetchData ~ error:", error)    
    }
  };

  const updateStepsStatus = (newIndex) => {
    setSteps(steps => steps.map((step, index) => {
      let status = step.status;
      if (status === NewSurveyPageStepStatus.WAITING && newIndex === index) {
        status = NewSurveyPageStepStatus.PROCESSING;
      } else if (status === NewSurveyPageStepStatus.PROCESSING && newIndex !== index) {
        status = NewSurveyPageStepStatus.COMPLETED;
      } else if (status === NewSurveyPageStepStatus.COMPLETED && newIndex === index) {
        status = NewSurveyPageStepStatus.PROCESSING;
      }

      return {
        ...step,
        status
      };
    }));
  };

  const updateCurrentStep = (newIndex) => {
    setCurrentStep({
      index: newIndex,
      isFirstStep: newIndex <= 0,
      isLastStep: newIndex >= steps.length - 1
    });
  };

  const validateCurrentFormStep = () => {
    switch (currentStep.index) {
      case 0:
        return caseFormRef.current.validateForm(caseData);
      case 1:
        return selfAssessmentFormRef.current.validateForm();
      case 2:
      default:
        return true;
    }
  }; 

  const onStepClick = (nextIndex) => {
    if (!validateCurrentFormStep()) {
      return;
    }

    updateStepsStatus(nextIndex);
    updateCurrentStep(nextIndex);
  };

  const handleCaseDataChange = (fieldName, value) => {
    if (fieldName) {
      setCaseData(data => ({
        ...data,
        [fieldName]: value
      }));
    }
  };
  const handleCaseDataSelectChange = (fieldName, value, isMultiple = false) => {
    if (fieldName) {
      setCaseData(data => ({
        ...data,
        [fieldName]: isMultiple ? value || [] : value
      }));
    }
  };
  const handleAssessmentDataChange = (fieldName, value) => {
    if (fieldName) {
      setSelfAssessmentData(data => ({
        ...data,
        [fieldName]: value
      }));
    }
  };
  const handleAssessmentDataSelectChange = (fieldName, value, isMultiple = false) => {
    if (fieldName) {
      setSelfAssessmentData(data => ({
        ...data,
        [fieldName]: isMultiple ? value || [] : value
      }));
    }
  };
  
  const handleSave = async () => {
    if (!caseFormRef.current.validateForm(caseData) || !selfAssessmentFormRef.current.validateForm()) {
      return;
    }

    const caseInput = {
      name: caseData.name,
      code: caseData.code,
      goal: caseData.goal,
      reason: caseData.reason,
      approvalReminderDate: caseData.approvalReminderDate.toISOString(),
      companyIndustry: caseData.companyIndustry ? caseData.companyIndustry._id : null,
      nation: caseData.nation ? caseData.nation.value : null,
      state: caseData.state ? caseData.state.value : null,
      city: caseData.city ? caseData.city.value : null,

      advisors: selfAssessmentData.advisors ? selfAssessmentData.advisors.map(item => item._id) : [],
      influencerCompany: caseData.influencer ? caseData.influencer._id : COMPANY_ID,
      adminInfluencer: {
        _id: adminInfluencer.information?._id,
        name: adminInfluencer.information?.name,
        email: adminInfluencer.information?.email
      }
    };

    const assessmentInput = {
      name: selfAssessmentData.name,
      code: selfAssessmentData.code,
      description: selfAssessmentData.description,
      type: selfAssessmentData.type.value,
      period: selfAssessmentData.period.value,
      companies: caseData.companies ? caseData.companies.map(item => item._id) : [],
      groups: selfAssessmentData.selectedGroupQA ? selfAssessmentData.selectedGroupQA.map(item => item._id) : [],
      company: caseData.influencer ? caseData.influencer._id : COMPANY_ID,
      startDate: selfAssessmentData.startDate.toISOString(),
      endDate: selfAssessmentData.endDate.toISOString()
    };

    const result = await Common.mutation(CaseAssessmentGRAPHQL.MUTATION_ADD_CASE_ASSESSMENT, { caseInput, assessmentInput });
    Common.checkResultData(result, Constants.MODE_INSERT);
    // back to list after create successfully.
    if (!result.isError) {
      history.replace('/case-assessment');
      // send Notify mail.
      sendNewCaseNotifyEmail(caseInput);
    }
  };

  const handleOnClickGroupQA = (index, status) => {
    if (status === 'selected') {
      const selectedItem = selfAssessmentData.selectedGroupQA[index];

      setSelfAssessmentOptions(options => ({
        ...options,
        groupQA: [
          ...options.groupQA.slice(0, selectedItem.index),
          selectedItem,
          ...options.groupQA.slice(selectedItem.index + 1)
        ]
      }));
      setSelfAssessmentData(data => ({
        ...data,
        selectedGroupQA: [
          ...data.selectedGroupQA.slice(0, index),
          ...data.selectedGroupQA.slice(index + 1)
        ]
      }));
    } else if (status === 'all') {
      const selectedItem = selfAssessmentOptions.groupQA[index];

      setSelfAssessmentData(data => ({
        ...data,
        selectedGroupQA: data.selectedGroupQA.concat(selectedItem)
      }));
      setSelfAssessmentOptions(options => ({
        ...options,
        groupQA: [
          ...options.groupQA.slice(0, index),
          ...options.groupQA.slice(index + 1)
        ]
      }));
    }
  };

  const getInfluencerAdmin = async() => {
    if (COMPANY_ID) {
      const res = await Common.query(CompanyGRAPHQL.QUERY_COMPANY_BY_IDS, {companyIds: [COMPANY_ID]});
      if (res?.data?.getCompaniesByIds?.length) {
        setAdminInfluencer(res.data.getCompaniesByIds[0]);
      }
    }
  };

  const getSelectedCompaniesAdvisors = useCallback(async() => {
    try {
      const influencerIds = caseData.companies.map(company => company?._id).filter(id => !!id);
      const result = await window.COMMON.query(AccountGRAPHQL.QUERY_ACCOUNT_BY_INFLUENCER, {
        influencerIds
      });
      const advisors = result.data.getAccountByInfluencers;
      setAssignedCompanyAdvisors(advisors);
    } catch (error) {
      window.COMMON.showErrorLogs('SelfAssessmentPage.getSelectedCompaniesAdvisors');
    }
  },[caseData.companies]);

  const sendNewCaseNotifyEmail = useCallback(async(caseInfo) => {
    // is not influencer admin 
    if (adminInfluencer.information?._id === CURRENT_USER_ID) {
      return ;
    }

    const { name } = caseInfo || {};
    const input = {
      receiver: [adminInfluencer.information?.email],
      subject: window.I18N('new_case_mail_subject'),
      body: `
      <p>Bạn đã được yêu cầu phản hồi về cuộc thanh tra đánh giá về
      <b>${name}</b>, <br></br>
      - Tạo bởi: ${ CURRENT_USER_NAME } <br></br>
      - Ngày: ${moment().format('[ngày] DD [tháng] MM')} <br></br>
      <br></br>
      <p>You were requested to reponse for new case assessment
      <b>${name}</b>, <br></br>
      - Created by: ${ CURRENT_USER_NAME } <br></br>
      - Date: ${moment().format('[ngày] DD [tháng] MM')}<br></br>
      `.replace(/\r?\n|\r/, '')
    };
    await Common.mutation(MailGRAPHQL.MUTATION_SEND_GRID_MAIL, { input }, false);
  }, [adminInfluencer]);

  useEffect(() => {
    // get influencer admin info
    getInfluencerAdmin();
  }, []);

  useEffect(() => {
    getSelectedCompaniesAdvisors();
  }, [caseData.companies]);

  return (
    <div className="container-row">
      <NewSurveyPageSteps steps={steps} onStepClick={onStepClick} />
      <SurveyPagePaperStyled backgroundColor={currentStep.index === 2 ? 'transparent' : undefined} >
        <div style={{ display: currentStep.index === 0 ? 'block' : 'none' }}>
          <NewCaseForm
            formData={caseData}
            handleChange={handleCaseDataChange}
            handleSelect={handleCaseDataSelectChange}
            options={caseOptions}
            ref={caseFormRef}
          />
        </div>
        <div style={{ display: currentStep.index === 1 ? 'block' : 'none'}}>
          <CaseSelfAssessmentForm
            data={selfAssessmentData}
            caseDueDate={caseData.approvalReminderDate}
            handleChange={handleAssessmentDataChange}
            handleSelect={handleAssessmentDataSelectChange}
            ref={selfAssessmentFormRef}
            options={{
              ...selfAssessmentOptions,
              advisors: [...selfAssessmentOptions.advisors, ...assignedCompanyAdvisors]
            }}
          />
        </div>
        <div style={{ display: currentStep.index === 2 ? 'block' : 'none'}}>
          <SelectGroupQA
            allGroupQA={selfAssessmentOptions.groupQA}
            selectedGroupQA={selfAssessmentData.selectedGroupQA}
            onClick={handleOnClickGroupQA}
          />
        </div>
      </SurveyPagePaperStyled>
      <StepButtonsContainer isFull>
        <div className='d-flex align-items-center justify-content-end w-100 mt-3'>
          {!currentStep.isFirstStep && (
            <MDBBtn color='' className='btn-back' onClick={() => onStepClick(currentStep.index - 1)}>
              <MDBIcon fa="true" icon="arrow-left" className="fa-fw" /> {window.I18N('btn_back')}
            </MDBBtn>
          )}
          {currentStep.isLastStep && (
            <MDBBtn color="primary" className="mr-0" type="button" onClick={handleSave}>
              <MDBIcon fa="true" icon="save" className="fa-fw" /> {window.I18N('btn_save')}
            </MDBBtn>
          )}
          {!currentStep.isLastStep && (
            <MDBBtn color="primary" className="mr-0" type="button" onClick={() => onStepClick(currentStep.index + 1)}>
              {window.I18N('btn_next')} <i className="fas fa-arrow-right"></i>
            </MDBBtn>
          )}
        </div>
      </StepButtonsContainer>
    </div>
  );
};

const mapStateToProps = state => ({
  language: state.language.value
});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(NewCaseAssessmentPage);
