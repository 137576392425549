import React, { useLayoutEffect } from 'react';
import { Route, Switch, useHistory, withRouter } from 'react-router-dom';

import DashboardGovermentPage from '../views/DashboardGovermentPage';
import DashboardPage from '../views/DashboardPage';

import AccountPage from '../views/resources/AccountPage';
import CompanyPage from '../views/resources/CompanyPage';
import FindNetworkPage from '../views/resources/FindNetworkPage';
import GroupChatPage from '../views/resources/GroupChatPage';
import MyProfilePage from '../views/resources/MyProfilePage';
import RequestAccountPageForInfluencer from '../views/resources/RequestAccountForInfluencerPage';
import RequestAccountPage from '../views/resources/RequestAccountPage';
import RequestInfluencerPage from '../views/resources/RequestInfluencerPage';
import RequestSystemAccountPage from '../views/resources/RequestSystemAccountPage';
import RightMenuPage from '../views/resources/RightMenuPage';
import RolePage from '../views/resources/RolePage';

import CheckQRDashboard from '../views/medical/CheckQRDashboard';
import RequestTestingPage from '../views/medical/RequestTestingPage';
import RequestVaccinationPage from '../views/medical/RequestVaccinationPage';
import VaccinationTestingInfoPage from '../views/medical/VaccinationTestingInfoPage';

import BackgroundPostPage from '../views/content/BackgroundPostPage';
import CommentPage from '../views/content/CommentPage';
import FanpagePage from '../views/content/FanpagePage';
import LearningPage from '../views/content/LearningPage';
import MyPostPage from '../views/content/MyPostPage';
import MySurveyPage from '../views/content/MySurveyPage';
import PostContentScreeningPage from '../views/content/PostContentScreeningPage';
import PostPage from '../views/content/PostPage';
import ProfilePage from '../views/content/ProfilePage';
import QuestionPage from '../views/content/QuestionPage';
import RewardPage from '../views/content/RewardPage';
import SchedulePostPage from '../views/content/SchedulePostPage';
import SurveyPage from '../views/content/SurveyPage';
import TaskSchedulePage from '../views/content/TaskSchedulePage';

import QuestionReportPage from '../views/report/QuestionReportPage';
import ReportedAccountPage from '../views/report/ReportedAccountPage';
import ReportedPostPage from '../views/report/ReportedPostPage';
import SupportPage from '../views/report/SupportPage';

import DocumentOrganizationPage from '../views/assessment/DocumentOrganizationPage';
import MySelfAssessmentInfluencerPage from '../views/assessment/MySelfAssessmentInfluencerPage';
import MySelfAssessmentPage from '../views/assessment/MySelfAssessmentPage';
import SelfAssessmentPage from '../views/assessment/SelfAssessmentPage';
import SelfAssessmentReportPage from '../views/assessment/SelfAssessmentReportPage';
import ShareSelfAssessmentPage from '../views/assessment/ShareSelfAssessmentPage';

import HistoryQAPage from '../views/log/HistoryQAPage';
import HistoryRewardPage from '../views/log/HistoryRewardPage';
import HistorySurveyPage from '../views/log/HistorySurveyPage';

import ComplaintPage from '../views/complaint';
import { ElearningCenter } from '../views/elearning';
import GrivancePage from '../views/grievance';
import AppsConnectPage from '../views/setting/AppsConnectPage';
import AttributeReportPage from '../views/setting/AttributeReportPage';
import ChangeCompanyPage from '../views/setting/ChangeCompanyPage';
import ConfigPage from '../views/setting/ConfigPage';
import EmotionPage from '../views/setting/EmotionPage';
import HelpCenterPage from '../views/setting/HelpCenterPage';
import LanguagePage from '../views/setting/LanguagePage';
import LocationPage from '../views/setting/LocationPage';
import MetadataPage from '../views/setting/MetadataPage';
import ReasonPage from '../views/setting/ReasonPage';
import SubMetadataPage from '../views/setting/SubMetadataPage';

import NotFoundPage from '../views/NotFoundPage';
import ScheduleAssessmentPage from '../views/schedule-assessment/ScheduleAssessmentPage';
import NewSurveyPage from '../views/survey/NewSurveyPage';

import ChatPage from '../views/Chat/ChatPage';
import CaseAssessmentPage from '../views/assessment/case/CaseAssessmentPage';
import NewCaseAssessmentPage from '../views/assessment/case/NewCaseAssessmentPage';
import CategoryQuestionPage from '../views/question-bank/CategoryQuestionPage';
import QuestionBankPage from '../views/question-bank/QuestionBankPage';
import QuestionBankReportPage from '../views/question-bank/QuestionBankReportPage';

import GopyBalancePage from '../views/power-dashboard/GopyBalancePage';
import PowerDashboardPage from '../views/power-dashboard/PowerDashboardPage';

import MyFanpagePage from '../views/content/MyFanpagePage';
import BuyGopiesPage from '../views/payment/BuyGopiesPage';
import PaymentManagementPage from '../views/payment/PaymentManagementPage';
import PaymentResultPage from '../views/payment/PaymentResultPage';

import InsightChartPage from '../views/content/InsightChartPage';
import EventPage from '../views/event/EventPage';
import CreateBillPage from '../views/payment/CreateBillPage';
import UserStoragePage from '../views/setting/UserStoragePage';

const Routes = (props) => {
  const history = useHistory();
  const ACCOUNT_TYPE = localStorage.getItem(window.CONSTANT.ACCOUNT_TYPE);

  useLayoutEffect(() => {
    setTimeout(() => {
      checkRolePath();
    }, 100);
    // eslint-disable-next-line
  }, []);

  const checkRolePath = () => {
    try {
      if (ACCOUNT_TYPE === window.CONSTANT.SUPER_ADMIN) {
        return true;
      }
      
      const currentPath = props.location.pathname;
      const pathNotFollowDataRole = ['/new-survey','/schedule-assessment', '/new-case', '/payment-result'];
      if (pathNotFollowDataRole.includes(currentPath)) {
        return true;
      }

      const data = JSON.parse(localStorage.getItem(window.CONSTANT.DATA_ROLE));
      let check = false;
      for (let i = 0; i < data.length; i++) {
        const element = data[i];
        if (element.action === currentPath.replace('/', '')) {
          check = true;
          break;
        }
        if (element.children && element.children.length > 0) {
          for (let j = 0; j < element.children.length; j++) {
            const child = element.children[j];
            if (child.action === currentPath.replace('/', '')) {
              check = true;
              break;
            }
          }
        }
      }
      if (!check && !currentPath.includes('/my-profile') && !currentPath.includes('/profile')) {
        history.push('/404');
      }
    } catch (error) {
      console.log('Error function Routes.checkRolePath');
    }
  };

  if (typeof window === 'undefined') {
    return null;
  }
  return (
    <Switch>
      <Route path="/dashboard" component={DashboardPage} />
      <Route path="/dashboard-goverment" component={DashboardGovermentPage} />
      <Route path="/power-dashboard" component={PowerDashboardPage} />
      <Route path="/gopy-balance" component={GopyBalancePage} />
      <Route path="/create-bill" component={CreateBillPage} />
      <Route path="/buy-gopies" component={BuyGopiesPage} />
      <Route path="/payment-result" component={PaymentResultPage} />
      <Route path="/payment-management" component={PaymentManagementPage} />
      <Route path="/my-profile" component={MyProfilePage} />
      <Route path="/company" component={CompanyPage} />
      <Route path="/users" component={AccountPage} />
      <Route path="/role" component={RolePage} />
      <Route path="/right-menu" component={RightMenuPage} />
      <Route path="/group-chat" component={GroupChatPage} />
      <Route path="/request-account" component={RequestAccountPage} />
      <Route path="/request-influencer" component={RequestInfluencerPage} />
      <Route path="/request-system-account" component={RequestSystemAccountPage} />
      <Route path="/request-account-influencer" component={RequestAccountPageForInfluencer} />
      <Route path="/find-network" component={FindNetworkPage} />

      <Route path="/check-qr-dashboard" component={CheckQRDashboard} />
      <Route path="/vaccination-request" component={RequestVaccinationPage} />
      <Route path="/testing-request" component={RequestTestingPage} />
      <Route path="/vaccination-testing-info" component={VaccinationTestingInfoPage} />

      <Route path="/question" component={QuestionPage} />
      <Route path="/reward" component={RewardPage} />
      <Route path="/newfeed" component={PostPage} />
      <Route path="/comment" component={CommentPage} />
      <Route path="/learning" component={LearningPage} />
      <Route path="/learning-center" component={ElearningCenter} />
      <Route path="/survey" component={SurveyPage} />
      <Route path="/my-post" component={MyPostPage} />
      <Route path="/profile" component={ProfilePage} />
      <Route path="/schedule-post" component={SchedulePostPage} />
      <Route path="/task-schedule" component={TaskSchedulePage} />
      <Route path="/my-survey" component={MySurveyPage} />
      <Route path="/fanpage" component={FanpagePage} />
      <Route path="/event" component={EventPage} />
      <Route path="/post-content-screening" component={PostContentScreeningPage} />
      <Route path="/background-post" component={BackgroundPostPage} />
      <Route path="/my-fanpage" component={MyFanpagePage} />

      <Route path="/reported-user" component={ReportedAccountPage} />
      <Route path="/reported-post" component={ReportedPostPage} />
      <Route path="/support" component={SupportPage} />
      <Route path="/survey-report" component={QuestionReportPage} />
      <Route path="/new-survey" component={NewSurveyPage}/>

      <Route path="/self-assessment-report" component={SelfAssessmentReportPage} />
      <Route path="/self-assessment" component={SelfAssessmentPage} />
      <Route path="/my-self-assessment" component={MySelfAssessmentPage} />
      <Route path="/my-self-assessment-influencer" component={MySelfAssessmentInfluencerPage} />
      <Route path="/shared-self-assessment" component={ShareSelfAssessmentPage} />
      <Route path={'/schedule-assessment'} component={ScheduleAssessmentPage}/>
      <Route path="/case-assessment" component={CaseAssessmentPage} />
      <Route path="/new-case" component={NewCaseAssessmentPage} />
      <Route path="/document-organization" component={DocumentOrganizationPage} />
      
      <Route path="/history-reward" component={HistoryRewardPage} />
      <Route path="/history-qa" component={HistoryQAPage} />
      <Route path="/history-survey" component={HistorySurveyPage} />

      <Route path="/metadata" component={MetadataPage} />
      <Route path="/sub-metadata" component={SubMetadataPage} />
      <Route path="/language" component={LanguagePage} />
      <Route path="/emotion" component={EmotionPage} />
      <Route path="/location" component={LocationPage} />
      <Route path="/attribute-report" component={AttributeReportPage} />
      <Route path="/change-company" component={ChangeCompanyPage} />
      <Route path="/reason" component={ReasonPage} />
      <Route path="/help-center" component={HelpCenterPage} />
      <Route path="/config" component={ConfigPage} />
      <Route path="/apps-connect" component={AppsConnectPage} />

      <Route path="/grievance" component={GrivancePage} />
	    <Route path="/complaint" component={ComplaintPage} />
      <Route path="/chat" component={ChatPage} />
      
      <Route path="/question-category" component={CategoryQuestionPage}/>
      <Route path="/question-bank" component={QuestionBankPage} />
      <Route path="/question-bank-report" component={QuestionBankReportPage} />
      <Route path="/data-insight" component={InsightChartPage} />
      <Route path="/user-storage" component={UserStoragePage} />


      <Route path='*' exact={true} component={NotFoundPage} />
      <Route exact path="/404" component={NotFoundPage} />
    </Switch>
  );
};

export default withRouter(Routes);
