import React, { useState, useLayoutEffect } from 'react';
import { useHistory, NavLink } from 'react-router-dom';
import { MDBRow, MDBCol, MDBInput, MDBCardImage, MDBBtn, MDBIcon } from 'mdbreact';
import { connect } from 'react-redux';

import i18n from '../../i18n/language';
import { setLanguage } from '../../redux/action';

import Logo from '../../styles/images/bg/logo_white.png';

import LanguageGRAPHQL from '../../graphql/Language';
import LoginGRAPHQL from '../../graphql/Login';
import RoleGRAPHQL from '../../graphql/Role';
import MetadataGRAPHQL from '../../graphql/Metadata';
import { startSession } from '../../tracking/Tracking';

function LoginPage(props) {
  const history = useHistory();
  const [dataSave, setDataSave] = useState({ isSavedAccount: false });
  const [dataLanguage, setDataLanguage] = useState([]);

  useLayoutEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    setTimeout(() => {
      getDataLanguage();
      const cached = localStorage.getItem(window.CONSTANT.IS_SAVED_ACCOUNT);
      if (cached) {
        const data = JSON.parse(cached);
        if (data) {
          dataSave.isSavedAccount = true;
          dataSave.username = data.username;
          dataSave.password = data.password;
        }
      }
    }, 100);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
    // eslint-disable-next-line
  }, []);

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      document.getElementById('btn-login').click();
    }
  };

  const handleChange = (event) => {
    if (event.target.type === 'checkbox') {
      dataSave[event.target.name] = event.target.checked;
    } else {
      dataSave[event.target.name] = event.target.value;
    }
    setDataSave({ ...dataSave });
  };

  const getDataLanguage = async () => {
    try {
      const params = {
        status: [true]
      };
      const result = await window.COMMON.query(LanguageGRAPHQL.QUERY_LANGUAGE, params);
      if (result && result.data) {
        const dataLanguage = result.data.getLanguages;
        const cache = localStorage.getItem(window.CONSTANT.LANGUAGE);
        if (window.COMMON.checkValue(cache)) {
          props.setLanguage(JSON.parse(cache));
          i18n.changeLanguage(JSON.parse(cache).code);
        } else {
          const language = window.COMMON.getObjectInArrs(window.CONSTANT.LANGUAGE_DEFAULT, dataLanguage, 'code');
          props.setLanguage(language);
          i18n.changeLanguage(language.code);
        }
        setDataLanguage(dataLanguage);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('Header.getDataLanguage');
    }
  };

  const changeLanguage = (item) => {
    if (item.code === props.language.code) {
      return;
    }
    props.setLanguage(item);
    i18n.changeLanguage(item.code);
    localStorage.setItem(window.CONSTANT.LANGUAGE, JSON.stringify(item));
  };

  const login = async (event) => {
    try {
      const checkForm = window.COMMON.checkFormValidation('form', event);
      if (!checkForm) {
        return;
      }
      window.showLoading();
      const params = {
        input: {
          username: dataSave.username.toLowerCase(),
          password: dataSave.password,
          deviceId: 'PC',
          deviceToken: 'PC'
        }
      };
      const result = await window.COMMON.queryAuth(LoginGRAPHQL.LOGIN_QUERY, params, false);
      if (result && result.data) {
        const accountType = result.data.loginAdmin.accountType;
        localStorage.setItem(window.CONSTANT.IS_LOGIN, true);
        localStorage.setItem(window.CONSTANT.ID, result.data.loginAdmin._id);
        localStorage.setItem(window.CONSTANT.AVATAR, result.data.loginAdmin.avatar);
        localStorage.setItem(window.CONSTANT.EMAIL, result.data.loginAdmin.email);
        localStorage.setItem(window.CONSTANT.FULL_NAME, result.data.loginAdmin.name);
        localStorage.setItem(window.CONSTANT.AUTHEN_TOKEN, result.data.loginAdmin.token);
        localStorage.setItem(window.CONSTANT.NICK_NAME, result.data.loginAdmin.nickname);
        localStorage.setItem(window.CONSTANT.ACCOUNT_COMPANIES, JSON.stringify(result.data.loginAdmin.companies));
        if (dataSave.isSavedAccount) {
          localStorage.setItem(window.CONSTANT.IS_SAVED_ACCOUNT, JSON.stringify({
            isSavedAccount: true,
            username: dataSave.username,
            password: dataSave.password
          }));
        } else {
          localStorage.removeItem(window.CONSTANT.IS_SAVED_ACCOUNT);
        }
        let companyId = null;
        let companyType = window.CONSTANT.ADMIN;
        if (result.data.loginAdmin.company) {
          companyId = result.data.loginAdmin.company;
          companyType = 'COMPANY';
        } else if (result.data.loginAdmin.union) {
          companyId = result.data.loginAdmin.union;
          companyType = 'UNION';
        } else if (result.data.loginAdmin.influencer) {
          companyId = result.data.loginAdmin.influencer;
          companyType = 'INFLUENCER';
        }
        localStorage.setItem(window.CONSTANT.COMPANY_ID, companyId);
        localStorage.setItem(window.CONSTANT.COMPANY_TYPE, companyType);
        localStorage.setItem(window.CONSTANT.ACCOUNT_TYPE, accountType);
        localStorage.removeItem(window.CONSTANT.DATA_COMPANY);
        let packageId = '';
        if (result.data.loginAdmin.package) {
          packageId = result.data.loginAdmin.package;
        } 
        localStorage.setItem(window.CONSTANT.PACKAGE_ID, packageId);
        const hash = JSON.stringify({
          username: dataSave.username,
          email: result.data.loginAdmin.email,
          role: result.data.loginAdmin.accountType,
          token: result.data.loginAdmin.token
        });
        localStorage.setItem(window.CONSTANT.SYNC_INSPECTOR, hash);
        //start tracking
        startSession();
        if (accountType === window.CONSTANT.SUPER_ADMIN) {
          await getDataMenu();
        } else {
          await getDataRoleMenu(accountType, companyId, packageId);
        }
        await getDataConfig();
        setTimeout(() => {
          window.COMMON.showMessage('success', 'MSG_CODE_014', window.I18N('MSG_CODE_014'));
          if(accountType === 'GOPY_INFLUENCER'){
            history.push('/my-post');
          }
          else{
            history.push('/dashboard-goverment');
          }
          window.hideLoading();
        }, 2000);
      } else {
        window.COMMON.showMessage('error', 'MSG_CODE_015', window.I18N('MSG_CODE_015'));
        window.hideLoading();
      }
    } catch (error) {
      window.COMMON.showErrorLogs('LoginPage.login');
    }
  };

  function handleMenus(paramRoleMenus, paramPackageMenus, paramAccountType) {
    if (paramPackageMenus.length ===0 && paramAccountType === 'GOPY_INFLUENCER') {
      return paramRoleMenus;
    }
    const showMenus = [];
    paramRoleMenus.forEach(childSubMenu => {
      // kiem tra tung menu trong sub role co trong menu packge khong
      const idChildSubMenu = childSubMenu.menu._id;
      const sameMenuInPackage = paramPackageMenus.find(packageMenu => packageMenu.menu._id === idChildSubMenu);
      // check lai cac rights trong tung sub menu
      if (sameMenuInPackage) {
        const rights = childSubMenu.rights.filter(childRight => window.COMMON.getObjectInArrs(childRight._id, sameMenuInPackage.rights, '_id'));
        showMenus.push({menu: childSubMenu.menu, rights, __typename: 'MenuRight'});
      }
    });
    return showMenus;
  }

  const getDataMenu = async () => {
    try {
      const result = await window.COMMON.query(RoleGRAPHQL.QUERY_MENU, null, false);
      if (result && result.data) {
        const dataMenu = result.data.getMenus;
        const data = [];
        dataMenu.forEach(item => {
          const children = window.COMMON.getArrsInArrs(item._id, dataMenu, 'parent._id');
          if (item.parent === null && (children.length > 0 || (item.action !== '#' && item !== null))) {
            item.children = children;
            data.push(item);
          }
        });
        localStorage.setItem(window.CONSTANT.DATA_ROLE, JSON.stringify(data));
      }
    } catch (error) {
      window.COMMON.showErrorLogs('LoginPage.getDataMenu');
    }
  };

  const getDataConfig = async () => {
    try {
      const result = await window.COMMON.query(MetadataGRAPHQL.QUERY_CONFIG, {});
      if (result && result.data) {
        localStorage.setItem(window.CONSTANT.DATA_CONFIG, JSON.stringify(result.data.getConfigs));
      }
    } catch (error) {
      window.COMMON.showErrorLogs('LoginPage.getDataConfig');
    }
  };

  const getDataRoleMenu = async (accountType, company, packageId) => {
    try {
      const params = {
        code: accountType,
        company: (!company || accountType === 'COMPANY' || accountType === 'UNION' || accountType === 'INFLUENCER' || accountType === 'GOPY_INFLUENCER') ? null : company
      };
      let packageMenus = [];

      //lay package theo packageId trong account hoac lay default, 
      const packageParam = { filter: { _id: packageId } };
      const packages = await window.COMMON.query(RoleGRAPHQL.QUERY_PACKAGE, packageParam);
      if (packages && packages.data) {
        packageMenus = packageId ? packages.data.queryPackage[0].menus : window.COMMON.getObjectInArrs(true, packages.data.queryPackage, 'default').menus;
      }
      const result = await window.COMMON.query(RoleGRAPHQL.QUERY_ROLE_BY_CODE, params, false);
      let dataMenu = [];
      if (result && result.data) {
        const showMenus = handleMenus(result.data.findRoleByCode.menus, packageMenus, accountType);
        showMenus.forEach(menu => {
          const item = menu.menu;
          if (item.parent) {
            const parent = item.parent;
            const check = window.COMMON.getObjectInArrs(parent.code, dataMenu, 'code');
            if (check) {
              check.children.push(createMenuObject(item));
            } else {
              const obj = createMenuObject(parent);
              obj.children.push(createMenuObject(item));
              dataMenu.push(obj);
            }
          } else {
            dataMenu.push(createMenuObject(item));
          }
        });
      }
      dataMenu = window.COMMON.sortData(dataMenu, 'index');
      localStorage.setItem(window.CONSTANT.DATA_ROLE, JSON.stringify(dataMenu));
    } catch (error) {
      window.COMMON.showErrorLogs('LoginPage.getDataRoleMenu');
    }
  };

  const createMenuObject = (item) => {
    return {
      _id: item._id,
      parent: item.parent,
      code: item.code,
      name: item.name,
      nameWithMultiLang: item.nameWithMultiLang,
      icon: item.icon,
      action: item.action,
      index: item.index,
      children: []
    };
  };

  if (typeof window === 'undefined') {
    return null;
  }
  return (
    <div className="height-100 m-0 container-bg-login">
      <div className="container-title d-flex justify-content-center">
        <MDBCol sm="6" className="container-header-login wow fadeInDown animated pt-5" data-wow-delay="1s">
          <div className="d-flex align-items-center">
            <img src={Logo} alt="" />
            <span className="white-text font-weight-bold ml-2">GOPY</span>
          </div>
          <h5 className="white-text font-weight-bold mt-3">{window.I18N('sign_in')}</h5>
          <div className="container-language-login">
            <NavLink to="#" id="dropdown-language" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-boundary="viewport">
              <MDBCardImage zoom cascade waves src={window.COMMON.setValueImage(props.language && props.language.icon ? props.language.icon : '')} className="image-icon"/>
            </NavLink>
            <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdown-language" data-boundary="viewport">
              {
                dataLanguage.map((item, i) => <div key={i} className="dropdown-item" onClick={() => changeLanguage(item)}>
                  <img src={window.COMMON.setValueImage(item.icon)} alt="" className="image-icon mr-2"></img> {item.name}
                </div>)
              }
            </div>
          </div>
        </MDBCol>
      </div>
      <div className="d-flex justify-content-center align-items-center">
        <MDBCol sm="6" className="container-login wow fadeInRight animated" data-wow-delay="1s">
          <div className="container-login-title d-flex justify-content-center align-items-center text-center">
            <h5 className="m-0 font-weight-bold">{window.I18N('greeting')}</h5>
          </div>
          <form id="form" className="form-login needs-validation" noValidate>
            <MDBInput className='text-lowercase' outline label={window.I18N('username')} icon="user" group type="user" name="username" error="wrong" success="right" value={dataSave.username} onChange={handleChange} maxLength="200" required>
              <div className="invalid-feedback">{window.I18N('MSG_CODE_030')}</div>
            </MDBInput>
            <MDBInput outline label={window.I18N('password')} icon="key" group type="password" name="password" error="wrong" success="right" value={dataSave.password} onChange={handleChange} maxLength="50" required>
              <div className="invalid-feedback">{window.I18N('MSG_CODE_029')}</div>
            </MDBInput>
            <MDBRow>
              <MDBCol>
                <MDBInput label={window.I18N('remember_me')} filled type="checkbox" name="isSavedAccount" id="ck-remember-me" containerClass="p-1 mb-2" checked={dataSave.isSavedAccount} onChange={handleChange}/>
              </MDBCol>
              <MDBCol className="text-right pt-2">
                <NavLink exact={true} to="/forgot-password">
                  {window.I18N('forgot_password')}
                </NavLink>
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol className="text-center">
                <MDBBtn id="btn-login" outline color="primary" className="w-50" onClick={login}>
                  <MDBIcon fa="true" icon="lock" className="fa-fw" /> {window.I18N('login')}
                </MDBBtn>
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol className="text-center pt-2">
                {window.I18N('do_not_account')}&nbsp;
                <NavLink exact={true} to="/signup">{window.I18N('sign_up')}</NavLink>
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol className="text-right pt-2">
                <a href={`https://helpdeskportal.online/${i18n.language.toLowerCase()==='vi'?'vi':'en'}`} target={'_blank'}>
                  {window.I18N('need_help')}
                </a>
              </MDBCol>
            </MDBRow>
          </form>
        </MDBCol>
        <nav className="navbar-footer-login mt-auto wow fadeInUp animated" data-wow-delay="1s">
          <ul className="">
            <li className="nav-item">
              <a href="https://gopy.io/term-and-condition" className="nav-link"> {window.I18N('terms')} </a>
            </li>
            <li className="nav-item">
              <a href="https://gopy.io/datapolicy" className="nav-link"> {window.I18N('privacy_policy')} </a>
            </li>
            <li className="nav-item">
              <a href="https://gopy.io/cookie-policy" className="nav-link"> Cookies </a>
            </li>
            <li className="nav-item">
              <a href="https://gopy.io" className="nav-link"> © 2019 - {new Date().getFullYear()} <b> CSR TECH JSC </b> </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
}

const mapStateToProps = state => ({
  language: state.language.value
});
const mapDispatchToProps = { setLanguage };

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);