import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import React, { useLayoutEffect, useState } from 'react';
import { storage } from '../../utils/FirebaseConfig';

const Loading = React.memo(function Loading(props) {
  const [title, setTitle] = useState('upload_title');
  const [visible, setVisible] = useState(false);
  const [progress, setProgress] = useState(0);

  useLayoutEffect(() => {
    window.uploadFile = uploadFile;
    window.uploadFirebase = uploadFirebase;
  }, []);

  const uploadFile = async (files, title) => {
    try {
      if (files.length > 0) {
        if (title) {
          setTitle(title);
        }
        setVisible(true);
        setProgress(0);
        const result = await window.UPLOAD_API.uploadFile(files, (event) => {
          setProgress(Math.round((100 * event.loaded) / event.total));
        });
        setTimeout(() => setVisible(false), 3000);
        if (result.data && result.data.files) {
          return result.data.files;
        }
      }
    } catch (error) {
      setTimeout(() => setVisible(false), 3000);
      window.hideLoading();
      window.showNotification(
        'error',
        window.I18N('error_notification'),
        window.I18N('MSG_CODE_098')
      );
    }
    return null;
  };

  const uploadFirebase = async (file, callBack) => {
    const storageRef = ref(storage, `${file.name}`);
    const uploadFiles = uploadBytesResumable(storageRef, file);

    setVisible(true);
    setProgress(0);
    uploadFiles.on('state_changed',
      (snapshot) => {
        setProgress(Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100));
	  },
      (error) => {
        console.log(error);
        setTimeout(() => setVisible(false), 3000);
        window.COMMON.showErrorLogs('Upload.uploadFile');
      },
      () => {
        setTimeout(() => setVisible(false), 3000);
        getDownloadURL(uploadFiles.snapshot.ref).then((downloadURL) => {
          if (typeof callBack === 'function') {
            callBack(downloadURL);
          }
        });
      }
    );
  };

  if (!visible) {
    return <div></div>;
  }
  return (
    <div className="container-upload">
      <h5>{window.I18N(title)}</h5>
      <div className="progress">
        <div
          className="progress-bar progress-bar-info progress-bar-striped"
          role="progressbar"
          aria-valuenow={progress}
          aria-valuemin="0"
          aria-valuemax="100"
          style={{ width: progress + '%' }}
        >
          {progress}%
        </div>
      </div>
    </div>
  );
});

export default Loading;