import React, { useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { Checkbox, FormControlLabel } from '@material-ui/core'
import { MDBIcon, MDBInput } from 'mdbreact'
import { useTracking } from 'react-tracking'
import ReactDatePicker from 'react-datepicker'

import EventGRAPHQL from '../../../../../graphql/Event'
import eventName from '../../../../../common/events'

import FanpageSelectWithDropdown from '../FanpageSelectWithDropdown'
import CoverImage from '../CoverImage'

const TIME_INTERVAL = 15 //minutes
const TIME_IN_PAST = -1 // time set is less than the current time
const TIME_IN_FUTURE = 1

const EventForm = React.forwardRef(
    ({ allowEdit = false, dataEdit = {}, fanpageId = null, language = 'EN', canChangeFanpage = false }, ref) => {
        const DEFAULT_REMINDER_VALUE = [
            {
                label: 'day',
                value: 1,
                checked: false
            },
            {
                label: 'days',
                value: 3,
                checked: false
            },
            {
                label: 'days',
                value: 5,
                checked: false
            },
            {
                label: 'days',
                value: 7,
                checked: false
            }
        ]

        const selectFanpageRef = useRef()
        const coverImageRef = useRef()
        const { trackEvent } = useTracking()

        const createTrackingEvent = (event) => {
            return trackEvent({
                name: event,
                createdAt: new Date().toISOString()
            })
        }

        const createDefaultDataEventForm = useCallback(
            (isEdit = false) => {
                const DEFAULT_DATA_EVENT_FORM = {
                    fanpage: '',
                    name: '',
                    images: null,
                    description: '',
                    limitMember: 100,
                    reminders: [],
                    gopy: 0,
                    eventDate: window.COMMON_DATE.roundToNextInterval(new Date(), TIME_INTERVAL)
                }

                if (isEdit && selectFanpageRef.current && coverImageRef.current) {
                    const DEFAULT_DATA_EDIT_EVENT_FORM = {
                        ...dataEdit,
                        eventDate: new Date(+dataEdit.eventDate)
                    }
                    selectFanpageRef.current.setDefaultCurrentFanpage({ fanpage: DEFAULT_DATA_EDIT_EVENT_FORM.fanpage })
                    coverImageRef.current.setCoverImages(DEFAULT_DATA_EDIT_EVENT_FORM.images)
                    return DEFAULT_DATA_EDIT_EVENT_FORM
                }

                return DEFAULT_DATA_EVENT_FORM
            },
            [selectFanpageRef.current, coverImageRef.current]
        )

        const [remindersValue, setRemindersValue] = useState(DEFAULT_REMINDER_VALUE)
        const [dataEvent, setDataEvent] = useState(createDefaultDataEventForm())
        const [dataEventFirst, setDataEventFirst] = useState(createDefaultDataEventForm())

        const handleCalcDisabledReminders = (reminderDays) => {
            const now = new Date()
            const eventDate = new Date(dataEvent.eventDate)
            // sync the current time with the event time
            now.setHours(eventDate.getHours())
            now.setMinutes(eventDate.getMinutes())
            const diffDateNowWithEventDate = window.COMMON_DATE.getDiffDate(now, eventDate)
            return diffDateNowWithEventDate <= reminderDays
        }

        const handleChangeDataEvent = (event, type = 'others', indexClick = null) => {
            if (type === 'eventDate') {
                if (window.COMMON.isEmpty(event)) {
                    return
                }
                setDataEvent((prev) => ({ ...prev, eventDate: event }))
            } else if (type === 'reminders') {
                const { checked } = event.target
                const reminder = remindersValue[indexClick].value
                setDataEvent((prev) => ({
                    ...prev,
                    reminders: checked
                        ? [...prev.reminders, reminder].sort()
                        : window.COMMON.removeElementInArray(prev.reminders, reminder)
                }))
                setRemindersValue((prev) =>
                    prev.map((item, rootIndex) => ({
                        ...item,
                        checked: indexClick === rootIndex ? checked : item.checked
                    }))
                )
            } else if (type === 'number') {
                const { name, value } = event.target
                const convertValueToNumber = Number(value)
                setDataEvent((prev) => ({ ...prev, [name]: convertValueToNumber < 0 ? null : convertValueToNumber }))
            } else {
                const { name, value } = event.target
                setDataEvent((prev) => ({ ...prev, [name]: value }))
            }
        }

        const validateForm = () => {
            const validationRules = {
                images: {
                    required: {
                        value: true,
                        message: window.I18N('cover_background_required')
                    }
                },
                name: {
                    required: {
                        value: true,
                        message: window.I18N('event_name_required')
                    },
                    maxLength: {
                        value: 100
                    }
                },
                fanpage: {
                    required: {
                        value: true
                    }
                },
                fanpageName: {
                    required: {
                        value: true
                    }
                },
                description: {
                    required: {
                        value: false
                    },
                    maxLength: {
                        value: 500
                    }
                },
                limitMember: {
                    min: {
                        value: 0,
                        message: window.I18N('min_limit_member').replace('X', '0')
                    },
                    max: {
                        value: 5000,
                        message: window.I18N('max_limit_member').replace('X', '5000')
                    }
                },
                gopy: {
                    min: {
                        value: 0,
                        message: window.I18N('min_gopy_involved').replace('X', '0')
                    },
                    max: {
                        value: 100000,
                        message: window.I18N('max_gopy_involved').replace('X', '100000')
                    }
                },
                eventDate: {
                    require: {
                        value: false
                    }
                },
                timeInPast: {
                    compare: {
                        value: TIME_IN_PAST,
                        message: window.I18N('time_in_past').replace('X', `${TIME_INTERVAL}`)
                    }
                }
            }
            const { images, ...others } = getDataEvent()
            const dataForValidate = {
                ...others,
                images: allowEdit && images ? [...images.lastImages, ...images.imagesForUpload] : images,
                timeInPast: window.COMMON_DATE.compareWithNow(dataEvent.eventDate, true)
            }
            window.COMMON.trimData(dataForValidate)
            const isValid = window.COMMON.validation(dataForValidate, validationRules, 'event-form', ref)
            return isValid
        }

        const handleGetDataForFirstLoad = (data) => {
            setDataEventFirst((prev) => ({ ...prev, ...data }))
        }

        const handleCheckChangeData = () => {
            const oldData = {
                ...dataEventFirst,
                eventDate: dataEvent?.eventDate?.toString()
            }
            const newData = getDataEvent()
            const isChangedData = window.COMMON.isObjectsDifferent(oldData, newData)
            return isChangedData
        }

        const getDataEvent = () => {
            return {
                ...dataEvent,
                images: coverImageRef?.current?.getImages(),
                eventDate: dataEvent?.eventDate?.toString(),
                ...selectFanpageRef?.current?.getSelectedFanpage()
            }
        }

        const resetForm = () => {
            window.COMMON.resetFormValidation('event-form')
            coverImageRef.current.resetImages()
            setRemindersValue(DEFAULT_REMINDER_VALUE)
            setDataEvent(createDefaultDataEventForm())
        }

        const handleCreateEventFanpage = async () => {
            const data = getDataEvent()
            let fileImages = []
            if (data) {
                fileImages = await window.uploadFile(data.images)
                data.images = fileImages.map((fileImage) => window.COMMON.getNewPathFile(fileImage._id))
                const params = {
                    input: data
                }
                try {
                    const addFanpageEvent = await window.COMMON.mutation(
                        EventGRAPHQL.MUTATION_ADD_FANPAGE_EVENT,
                        params
                    )
                    if (window.COMMON.checkResultData(addFanpageEvent, window.CONSTANT.MODE_INSERT)) {
                        createTrackingEvent(eventName.CREATE_EVENT)
                        resetForm()
                        return window.CONSTANT.SUCCESSFULLY
                    }
                } catch (error) {
                    window.UPLOAD_API.deleteMultiFile(fileImages.map(item => item._id))           
                    window.COMMON.showErrorLogs(`EventForm.handleCreateEventFanpage ::: ${error}`)
                    return window.CONSTANT.FAILURE
                }
            }
        }

        const handleEditEventFanpage = async () => {
            const data = getDataEvent()
            const {
                _id: id,
                name,
                description,
                images: { lastImages, imagesForUpload },
                limitMember,
                gopy
            } = data
            let fileImages = []
            if (data) {
                fileImages = !window.COMMON.isEmpty(imagesForUpload)
                    ? await window.uploadFile(imagesForUpload)
                    : []
                const listImageAfterUpload = fileImages.map((fileImage) => window.COMMON.getNewPathFile(fileImage._id))
                const params = {
                    id,
                    name,
                    description,
                    images: [...lastImages, ...listImageAfterUpload],
                    limitMember,
                    gopy
                }
                try {
                    const editFanpageEvent = await window.COMMON.mutation(
                        EventGRAPHQL.MUTATION_EDIT_FANPAGE_EVENT,
                        params
                    )
                    if (window.COMMON.checkResultData(editFanpageEvent, window.CONSTANT.MODE_UPDATE)) {
                        return window.CONSTANT.SUCCESSFULLY
                    }
                } catch (error) {
                    window.UPLOAD_API.deleteMultiFile(fileImages.map(item => item._id))           
                    window.COMMON.showErrorLogs(`EventForm.handleEditEventFanpage ::: ${error}`)
                    return window.CONSTANT.FAILURE
                }
            }
        }

        useImperativeHandle(ref, () => ({
            validateForm,
            resetForm,
            isChangeData: handleCheckChangeData,
            resetFormForEdit: () => setDataEvent(createDefaultDataEventForm(allowEdit)),
            submitForm: async (type = 'create', callback = () => {}) => {
                if (type === 'create') {
                    const result = await handleCreateEventFanpage()
                    if (result === window.CONSTANT.SUCCESSFULLY) {
                        callback()
                    }
                }
                if (type === 'edit') {
                    const result = await handleEditEventFanpage()
                    if (result === window.CONSTANT.SUCCESSFULLY) {
                        callback()
                    }
                }
            }
        }))

        //Set data for update event
        useEffect(() => {
            if (!window.COMMON.isEmpty(dataEdit) && allowEdit && selectFanpageRef.current) {
                const dataEditReminders = DEFAULT_REMINDER_VALUE.reduce(
                    (acc, reminder) =>
                        dataEdit.reminders.includes(reminder.value)
                            ? [...acc, { ...reminder, checked: true }]
                            : [...acc, reminder],
                    []
                )
                setRemindersValue(dataEditReminders)
                setDataEvent(createDefaultDataEventForm(allowEdit))
                setDataEventFirst(() => {
                    const dataSet = createDefaultDataEventForm(allowEdit)
                    return {
                        ...dataSet,
                        images: {
                            lastImages: [...dataSet.images],
                            imagesForUpload: []
                        }
                    }
                })
            }
        }, [dataEdit, selectFanpageRef])

        return (
            <form id={'event-form'} onSubmit={(e) => e.preventDefault()}>
                {/* Cover image  */}
                <CoverImage ref={coverImageRef} isEdit={allowEdit} />
                {/* Content */}
                <div
                    className="px-3"
                    style={{
                        padding: '10px 10px 10px 25px'
                    }}
                >
                    {/* Select fanpage */}
                    <div className="mt-3">
                        <h6 className="font-weight-bold" style={{ fontSize: '0.8rem' }}>
                            {window.I18N('Fanpage*')}
                        </h6>
                        <FanpageSelectWithDropdown
                            key={fanpageId}
                            ref={selectFanpageRef}
                            disabled={allowEdit || canChangeFanpage}
                            fanpageId={fanpageId}
                            onUpdateForFirstLoad={handleGetDataForFirstLoad}
                        />
                    </div>
                    {/* Name event */}
                    <div className="mt-2">
                        <h6 className="font-weight-bold" style={{ fontSize: '0.8rem' }}>
                            {`${window.I18N('event_name')}*`}
                        </h6>
                        <MDBInput
                            value={dataEvent.name}
                            name="name"
                            onChange={handleChangeDataEvent}
                            outline
                            type="text"
                            containerClass="m-0"
                            className="md-form m-0"
                            required
                        />
                    </div>
                    <div className="mt-3 d-flex align-items-center justify-content-between" style={{ gap: '8px' }}>
                        <div>
                            <h6 className="font-weight-bold" style={{ fontSize: '0.8rem' }}>
                                {`${window.I18N('start_date')}*`}
                            </h6>
                            <div className="input-group input-group-transparent">
                                <div className="input-group-prepend">
                                    <span className="input-group-text">
                                        <MDBIcon fa="true" icon="calendar-alt" className="fa-fw" />
                                    </span>
                                </div>
                                <ReactDatePicker
                                    name="eventDate"
                                    disabled={allowEdit}
                                    placeholderText={window.I18N('start_date') + ' *'}
                                    className="form-control"
                                    dateFormat="dd/MM/yyyy"
                                    selected={dataEvent.eventDate}
                                    minDate={new Date()}
                                    onChange={(event) => handleChangeDataEvent(event, 'eventDate')}
                                    showYearDropdown
                                    showMonthDropdown
                                    required
                                />
                            </div>
                        </div>

                        <div>
                            <h6 className="font-weight-bold" style={{ fontSize: '0.8rem' }}>
                                {`${window.I18N('event_time')}*`}
                            </h6>
                            <div className="input-group input-group-transparent">
                                <div className="input-group-prepend">
                                    <span className="input-group-text">
                                        <MDBIcon fa="true" icon="clock" className="fa-fw" />
                                    </span>
                                </div>
                                <ReactDatePicker
                                    name="eventDate"
                                    disabled={allowEdit}
                                    placeholderText={window.I18N('event_time') + ' *'}
                                    className="form-control"
                                    dateFormat="h:mm aa"
                                    selected={dataEvent.eventDate.getTime()}
                                    onChange={(event) => handleChangeDataEvent(event, 'eventDate')}
                                    minTime={
                                        window.COMMON_DATE.compareWithNow(dataEvent.eventDate) === TIME_IN_FUTURE
                                            ? new Date().setHours(0, 0, 0, 0)
                                            : new Date().getTime()
                                    }
                                    maxTime={new Date().setHours(23, 59, 59, 0)}
                                    timeIntervals={TIME_INTERVAL}
                                    showTimeSelect
                                    showTimeSelectOnly
                                    required
                                />
                            </div>
                        </div>
                    </div>
                    {/* Desciption */}
                    <div className="mt-3">
                        <div>
                            <h6 className="font-weight-bold" style={{ fontSize: '0.8rem' }}>
                                {window.I18N('event_description')}
                            </h6>
                            <MDBInput
                                name="description"
                                style={{
                                    margin: 0,
                                    minHeight: '160px'
                                }}
                                containerClass="m-0"
                                type="textarea"
                                rows="1"
                                outline
                                maxLength="500"
                                value={dataEvent.description}
                                onChange={handleChangeDataEvent}
                            />
                        </div>
                    </div>
                    {/*  */}
                    <div className="mt-3 d-flex align-items-center justify-content-between" style={{ gap: '8px' }}>
                        <div>
                            <h6 className="font-weight-bold" style={{ fontSize: '0.8rem' }}>
                                {window.I18N('limit_members')}
                            </h6>
                            <div className="input-group input-group-transparent">
                                <div className="input-group-prepend">
                                    <span className="input-group-text">
                                        <MDBIcon fa="true" icon="users" className="fa-fw" />
                                    </span>
                                </div>
                                <MDBInput
                                    name="limitMember"
                                    value={dataEvent.limitMember}
                                    onChange={(e) => handleChangeDataEvent(e, 'number')}
                                    outline
                                    type="number"
                                    min="0"
                                    containerClass="m-0 input-group-custom"
                                    inputRef={(el) => {
                                        el.style.borderTopLeftRadius = '0px'
                                        el.style.borderBottomLeftRadius = '0px'
                                    }}
                                    className="m-0"
                                    required
                                />
                            </div>
                        </div>
                        <div>
                            <h6 className="font-weight-bold" style={{ fontSize: '0.8rem' }}>
                                {window.I18N('gopy_needed')}
                            </h6>
                            <div className="input-group input-group-transparent">
                                <div className="input-group-prepend">
                                    <span className="input-group-text">
                                        <MDBIcon fa="true" icon="coins" className="fa-fw" />
                                    </span>
                                </div>
                                <MDBInput
                                    name="gopy"
                                    value={dataEvent.gopy}
                                    onChange={(e) => handleChangeDataEvent(e, 'number')}
                                    outline
                                    type="number"
                                    min="0"
                                    containerClass="m-0 input-group-custom"
                                    inputRef={(el) => {
                                        el.style.borderTopLeftRadius = '0px'
                                        el.style.borderBottomLeftRadius = '0px'
                                    }}
                                    className="m-0"
                                    required
                                />
                            </div>
                        </div>
                    </div>
                    {/* ============================================================ */}
                    {/* Reminder */}
                    <div className="mt-3">
                        <h6 className="font-weight-bold m-0" style={{ fontSize: '0.8rem' }}>
                            {window.I18N('reminder')}
                        </h6>
                        <div className="d-flex align-items-center justify-content-between" style={{ gap: '8px' }}>
                            {remindersValue.map((item, index) => (
                                <div key={index}>
                                    <FormControlLabel
                                        className="my-auto"
                                        control={
                                            <Checkbox
                                                disabled={allowEdit || handleCalcDisabledReminders(item.value)}
                                                checked={item.checked}
                                                onChange={(e) => handleChangeDataEvent(e, 'reminders', index)}
                                                color={
                                                    allowEdit || handleCalcDisabledReminders(item.value)
                                                        ? 'default'
                                                        : 'primary'
                                                }
                                                fontSize="small"
                                            />
                                        }
                                        label={
                                            <div className="flex-center">
                                                <span>{`${item.value} ${window.I18N(item.label).toLowerCase()}`}</span>
                                            </div>
                                        }
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </form>
        )
    }
)

export default EventForm
