import React from 'react';
import $ from 'jquery';
import moment from 'moment';
import InputMask from 'inputmask';
import axios from 'axios';

import Image from '../styles/images/no_image.png';
import IconAudio from '../styles/images/icons_file/ic_audio.png';
import IconExcel from '../styles/images/icons_file/ic_excel.png';
import IconFile from '../styles/images/icons_file/ic_file.png';
import IconImage from '../styles/images/icons_file/ic_image.png';
import IconPDF from '../styles/images/icons_file/ic_pdf.png';
import IconPPT from '../styles/images/icons_file/ic_ppt.png';
import IconVideo from '../styles/images/icons_file/ic_video.png';
import IconWinrar from '../styles/images/icons_file/ic_winrar.png';
import IconWord from '../styles/images/icons_file/ic_word.png';

import AccountGRAPHQL from '../graphql/Account';
import CompanyGRAPHQL from '../graphql/Company';
import MetadataGRAPHQL from '../graphql/Location';
import Constant from './Constant';


const FILE_URL = process.env.REACT_APP_URL_FILE;

const Common = {
  initWOW() {
    const WOW = require('wow.js');
    new WOW({
      boxClass: 'wow',
      animateClass: 'animated',
      offset: 0,
      live: true
    }).init();
  },
  initCommon() {
    const numberMask = new InputMask({ mask: 'numeric', rightAlign: false });
    numberMask.mask(document.getElementsByClassName('input-number'));
    const datetimeMask = new InputMask({
      mask: '99/99/9999 99:99',
      rightAlign: false
    });
    datetimeMask.mask(document.getElementsByClassName('input-datetime'));
    const dateMask = new InputMask({ mask: '99/99/9999', rightAlign: false });
    dateMask.mask(document.getElementsByClassName('input-date'));
    const timeMask = new InputMask({ mask: '99:99:99', rightAlign: false });
    timeMask.mask(document.getElementsByClassName('input-time'));
    $('#dropdown-sticker').on('hide.bs.dropdown', function () {
      console.log('TEST dropdown-sticker');
    });
  },
  showMessageSuccess(mode) {
    if (mode === window.CONSTANT.MODE_INSERT) {
      window.showNotification(
        'success',
        'MSG_CODE_001',
        window.I18N('MSG_CODE_001')
      );
    } else if (mode === window.CONSTANT.MODE_UPDATE) {
      window.showNotification(
        'success',
        'MSG_CODE_002',
        window.I18N('MSG_CODE_002')
      );
    } else if (mode === window.CONSTANT.MODE_DELETE) {
      window.showNotification(
        'success',
        'MSG_CODE_003',
        window.I18N('MSG_CODE_003')
      );
    } else if (mode === window.CONSTANT.MODE_IMPORT) {
      window.showNotification(
        'success',
        'MSG_CODE_016',
        window.I18N('MSG_CODE_016')
      );
    } else if (mode === window.CONSTANT.MODE_STATUS) {
      window.showNotification(
        'success',
        'MSG_CODE_032',
        window.I18N('MSG_CODE_032')
      );
    } else if (mode === window.CONSTANT.MODE_APPROVE) {
      window.showNotification(
        'success',
        'MSG_CODE_033',
        window.I18N('MSG_CODE_033')
      );
    } else if (mode === window.CONSTANT.MODE_DECLINE) {
      window.showNotification(
        'success',
        'MSG_CODE_034',
        window.I18N('MSG_CODE_034')
      );
    }
  },
  showMessageError(mode) {
    if (mode === window.CONSTANT.MODE_INSERT) {
      window.showNotification('error', 'MSG_CODE_004', window.I18N('MSG_CODE_004'))
    } else if (mode === window.CONSTANT.MODE_UPDATE) {
      window.showNotification('error', 'MSG_CODE_005', window.I18N('MSG_CODE_005'))
    } else if (mode === window.CONSTANT.MODE_DELETE) {
      window.showNotification('error', 'MSG_CODE_006', window.I18N('MSG_CODE_006'))
    } else if (mode === window.CONSTANT.MODE_IMPORT) {
      window.showNotification('error', 'MSG_CODE_017', window.I18N('MSG_CODE_017'))
    } else if (mode === window.CONSTANT.MODE_STATUS) {
      window.showNotification('error', 'MSG_CODE_035', window.I18N('MSG_CODE_035'))
    } else if (mode === window.CONSTANT.MODE_APPROVE) {
      window.showNotification('error', 'MSG_CODE_036', window.I18N('MSG_CODE_036'))
    } else if (mode === window.CONSTANT.MODE_DECLINE) {
      window.showNotification('error', 'MSG_CODE_037', window.I18N('MSG_CODE_037'))
    }
  },
  changeLocation(attr, data, value) {
    if (!this.checkValue(value)) {
      return [];
    }
    let result = [];
    if (attr === 'nation' || attr === 'state') {
      const obj = this.getObjectInArrs(value, data, '_id');
      const arrs = this.getArrsInArrs(obj.code, data, 'parent');
      result = this.createDataSelect(arrs, '_id', 'name');
    }
    return result;
  },
  async getDataCompany() {
    try {
      window.showLoading();
      const check = localStorage.getItem(window.CONSTANT.DATA_COMPANY);
      if (check && check !== 'null' && check !== '[]') {
        window.hideLoading();
        return;
      }
      const COMPANY_TYPE = localStorage.getItem(window.CONSTANT.COMPANY_TYPE);
      const result = await this.query(
        AccountGRAPHQL.QUERY_COMPANY_ACCOUNT_BY_ROLE,
        { companyType: COMPANY_TYPE },
        false
      );
      if (result && result.data) {
        const data = result.data.getCompanyAccountsByRole;
        data.forEach((item) => {
          item.id = item.accountType === window.CONSTANT.COMPANY ? this.getValueFromAttr(item, 'company._id', null) : this.getValueFromAttr(item, 'union._id', null);
        });
        localStorage.setItem(
          window.CONSTANT.DATA_COMPANY,
          JSON.stringify(data)
        );
      } else {
        localStorage.setItem(window.CONSTANT.DATA_COMPANY, JSON.stringify([]));
      }
    } catch (error) {
      this.showErrorLogs('Common.getDataCompany');
    }
    window.hideLoading();
  },
  async getDataAllCompany() {
    try {
      window.showLoading();
      const check = localStorage.getItem(window.CONSTANT.DATA_ALL_COMPANY);
      if (check && check !== 'null' && check !== '[]') {
        window.hideLoading();
        return;
      }
      const params = {
        type: null
      };
      const result = await window.COMMON.query(
        CompanyGRAPHQL.QUERY_COMPANY,
        params
      );
      if (result && result.data) {
        localStorage.setItem(
          window.CONSTANT.DATA_ALL_COMPANY,
          JSON.stringify(result.data.getCompanies)
        );
      }
    } catch (error) {
      this.showErrorLogs('Common.getDataAllCompany');
    }
    window.hideLoading();
  },
  async getDataLocation(parent = '-1') {
    let data = [];
    try {
      const params = { parent };
      const result = await window.COMMON.query(
        MetadataGRAPHQL.QUERY_LOCATION,
        params,
        false
      );
      if (result && result.data) {
        data = this.createDataSelect(
          result.data.getLocations,
          '_id',
          'name',
          'code'
        );
      }
    } catch (error) {
      this.showErrorLogs('Common.getDataLocation');
    }
    return data;
  },
  async downloadFileWithUrl(url, name, ext = 'webp') {
    try {
      const id = url.replace(FILE_URL, '');
      await window.UPLOAD_API.downloadFile(id, name + '.' + ext);
    } catch (error) {
      this.showErrorLogs('Common.downloadFileWithUrl');
    }
  },
  async downloadFile(id, name) {
    try {
      await window.UPLOAD_API.downloadFile(id, name);
    } catch (error) {
      this.showErrorLogs('Common.donwloadFile');
    }
  },
  async deleteFile(id) {
    try {
      window.UPLOAD_API.deleteFile(id);
    } catch (error) {
      this.showErrorLogs('Common.deleteFile');
    }
  },
  checkResultData(result, mode, customMessage = {}) {
    if (result.isError) {
      if (
        result.error.graphQLErrors.length > 0 &&
        result.error.graphQLErrors[0].extensions
      ) {
        if (
          result.error.graphQLErrors[0].extensions.code === 'ACCOUNT_EXISTS'
        ) {
          window.showNotification(
            'error',
            'MSG_CODE_007',
            window.I18N('MSG_CODE_007')
          )
        } else if(result.error.graphQLErrors[0].extensions.code.extensions.code === 'DUPLICATE_FIELD_ERROR') {
          window.showNotification(
            'error',
            'MSG_CODE_086',
            window.I18N('MSG_CODE_086', { field: window.I18N(result.error.graphQLErrors[0].extensions.code.extensions.fields.join(' ') )})
          )
        }
        else {
          window.showNotification(
            'error',
            'ERROR',
            result.error.graphQLErrors[0].exception.errmsg
          );
        }
      } else {
        window.showNotification('error', 'ERROR', result.error.message);
      }
      return false;
    }
    const attrs = Object.keys(result.data);
    const value = this.getValueFromAttr(result.data, attrs[0], -1);
    if (
      (typeof value === 'number' && value > 0) ||
      (typeof value === 'string' && this.checkValue(value)) ||
      (typeof value === 'object' && this.checkValue(value) && Object.keys(value).length > 0)
    ) {
       this.isEmpty(customMessage) ? this.showMessageSuccess(mode) : window.showNotification(
        'success',
        `${customMessage.successful}`,
        window.I18N(`${customMessage.successful}`)
      );
      return true;
    }
    if (result && result.data && result.data[attrs[0]] === -2) {
      window.showNotification('error', 'MSG_CODE_007', window.I18N('MSG_CODE_007'));
      return false;
    }
     this.isEmpty(customMessage) ? this.showMessageError(mode) : window.showNotification(
      'error',
      `${customMessage.failed}`,
      window.I18N(`${customMessage.failed}`)
    );
    return false;
  },
  showMessage(type, title, message) {
    window.showNotification(type, title, message);
  },
  initialTabs(id, index) {
    $('#'.concat(id)).find('.nav-tabs li').eq(index).children().trigger('click');
  },
  showModal(id) {
    $('.was-validated').removeClass('was-validated');
    $('.is-valid').removeClass('is-valid');
    $('.is-invalid').removeClass('is-invalid');
    $(id).modal({ backdrop: 'static' });
  },
  hideModal(id) {
    $(id).modal('hide');
  },
  hideFilter() {
    $('.btn-filter').trigger('click');
  },
  hidePopup(id) {
    $(id).trigger('click');
  },
  showErrorLogs(method) {
    window.hideLoading();
    window.showNotification(
      'error',
      window.I18N('error_notification'),
      window.I18N('MSG_CODE_079')
    );
    console.log('Page has error is ' + method);
  },
  showContainerSave(isSub, isAttributeReport) {
    $('.container-error').hide();
    $('.was-validated').removeClass('was-validated');
    $('.is-valid').removeClass('is-valid');
    $('.is-invalid').removeClass('is-invalid');
    $('#container-data').hide();
    $('#container-save').show();
    if (isSub) {
      $('#container-sub-save').show();
    }
    if (isAttributeReport) {
      $('#container-save').hide();
      $('#container-attribute-report').show();
    }
  },
  backContainerData() {
    $('#container-save').hide();
    $('#container-sub-save').hide();
    $('#container-attribute-report').hide();
    $('#container-data').show();
  },
  checkRoleIsSystem() {
    const COMPANY_TYPE = localStorage.getItem(window.CONSTANT.COMPANY_TYPE);
    if (COMPANY_TYPE === window.CONSTANT.ADMIN) {
      return true;
    }
    return false;
  },
  checkRoleIsSystemOrInfluencer() {
    const COMPANY_TYPE = localStorage.getItem(window.CONSTANT.COMPANY_TYPE);
    if (
      COMPANY_TYPE === window.CONSTANT.ADMIN ||
      COMPANY_TYPE === window.CONSTANT.INFLUENCER
    ) {
      return true;
    }
    return false;
  },
  isSuperAdmin() {
    const ACCOUNT_TYPE = localStorage.getItem(Constant.ACCOUNT_TYPE);
    return ACCOUNT_TYPE === Constant.SUPER_ADMIN
  },
  checkAccountTypeIsSystemOrInfluencer() {
    const ACCOUNT_TYPE = localStorage.getItem(Constant.ACCOUNT_TYPE);
    return ACCOUNT_TYPE === Constant.SUPER_ADMIN || ACCOUNT_TYPE === Constant.INFLUENCER;
  },
  checkRoleIsSystemOrOrganization() {
    const ACCOUNT_TYPE = localStorage.getItem(window.CONSTANT.ACCOUNT_TYPE);
    const COMPANY_ID = localStorage.getItem(window.CONSTANT.COMPANY_ID);
    if (
      COMPANY_ID === 'underfined' ||
      COMPANY_ID === 'null' ||
      ACCOUNT_TYPE === window.CONSTANT.INFLUENCER ||
      ACCOUNT_TYPE === window.CONSTANT.COMPANY ||
      ACCOUNT_TYPE === window.CONSTANT.UNION
    ) {
      return true;
    }
    return false;
  },
  checkValue(value) {
    if (
      value === null ||
      value === undefined ||
      value === '' ||
      value === 'null' ||
      value === 'undefined'
    ) {
      return false;
    }
    return true;
  },
  getValue(value) {
    if (this.checkValue(value)) {
      return value;
    }
    return null;
  },
  trimData(obj) {
    const arrs = Object.keys(obj);
    arrs.forEach((key) => {
      if (typeof obj[key] === 'string' && this.checkValue(obj[key])) {
        obj[key] = obj[key].trim();
      }
      if (typeof obj[key] === 'object') {
        if (Array.isArray(obj[key])) {
          if (this.checkValue(obj[key])) {
            for (let i = 0; i < obj[key].length; i++) {
              let item = obj[key][i];
              if (typeof item === 'string' && this.checkValue(item)) {
                item = item.trim();
              } else {
                this.trimData(item);
              }
            }
          }
        } else {
          if (this.checkValue(obj[key])) {
            this.trimData(obj[key]);
          }
        }
      }
    });
  },
  resetFormValidation(id) {
    const form = document.getElementById(id);
    form.classList.remove('was-validated');
  },
  checkFormValidation(id, event) {
    const form = document.getElementById(id);
    const formElements = form.elements;
    
    // Duyệt qua tất cả các input và trim dữ liệu
    for (let i = 0; i < formElements.length; i++) {
      const element = formElements[i];
      
      if(element.type === 'email') {
        const specialCharRegex = new RegExp(/[!#$%^&*(),?":{}|<>]/g) 
        if(specialCharRegex.test(element.value)){
          this.showMessage('warning', 'MSG_CODE_030', window.I18N('MSG_CODE_030'));        
          return false;
        }
      }
      
      if(element.type === 'number' && element.name === 'phone') {
        const phoneRegexVN = new RegExp(/^(?:\+84|0)(3|5|7|8|9)\d{8}$/) 
        const phoneRegexInternational = new RegExp(/^\+[1-9]\d{1,14}$/)
        const isValidPhoneVN = phoneRegexVN.test(element.value.toString())
        const isValidPhoneInternational = phoneRegexInternational.test(`+${element.value.toString()}`)
        const mergeCoditionValidPhone = isValidPhoneVN ? true : isValidPhoneInternational
        if(element.value.toString().length < 10 || element.value.toString().length > 15 || !mergeCoditionValidPhone){
          this.showMessage('warning', 'MSG_CODE_091', window.I18N('MSG_CODE_091'));        
          return false;
        }
      }
      
      // Nếu là một input hoặc textarea và có thuộc tính value
      if ((element.tagName === "INPUT" || element.tagName === "TEXTAREA") && element.type !== "file" && element.value) {
        element.value = element.value.trim();
      }
    }
    form.classList.add('was-validated');
    if (form.checkValidity() === false) {
      if (event.preventDefault) {
        event.preventDefault();
      }
      if (event.stopPropagation) {
        event.stopPropagation();
      }

      return false;
    }
    return true;
  },
  checkSelectValidation(id, value) {
    const item = document.getElementById(id);
    if (!this.checkValue(value) || value.length === 0) {
      item.classList.add('is-invalid');
      item.classList.remove('is-valid');
      return false;
    }
    item.classList.add('is-valid');
    item.classList.remove('is-invalid');
    return true;
  },
  checkDateValidation(startDate, endDate) {
    if (startDate > endDate) {
      this.showMessage('warning', 'MSG_CODE_011', window.I18N('MSG_CODE_011'));
      return false;
    }
    return true;
  },
  setValueImage(value, isAssert) {
    if (this.checkValue(value)) {
      if (isAssert) {
        const obj = this.getObjectInArrs(value, window.CONSTANT.STICKERS, 'value');
        return obj?.icon || '';
      }
      return value;
    }
    return Image;
  },
  setNewValueImage(value, isAssert) {
    if (this.checkValue(value)) {
      if (isAssert) {
        const obj = this.getObjectInArrs(value, window.CONSTANT.STICKERS, 'value');
        return obj.icon;
      }
      return FILE_URL + value;
    }
    return Image;
  },
  getValueHTML(item, attr) {
    const str = this.getValueFromAttr(item, attr);
    const changeText = str.replace(/(https?:\/\/)([^ ]+)/g, '<a target="_blank" href="$&">https://$2</a>');
    return changeText.replace(/\n/g, '<br>');
  },
  getNewPathFile(value) {
    if (this.checkValue(value)) {
      return FILE_URL + value;
    }
    return '';
  },
  parseArray(value) {
    let result = [];
    if (!this.checkValue(value)) {
      return [];
    }
    try {
      if (typeof value === 'string') {
        result = JSON.parse(value);
      } else {
        result = value;
      }
    } catch (e) {
      result = [];
    }
    return result;
  },
  parseObject(value) {
    let result = null;
    try {
      result = JSON.parse(value);
    } catch (e) {
      result = null;
    }
    return result;
  },
  parseJSONToString(value) {
    let result = '';
    if (!this.checkValue(value)) {
      return result;
    }
    try {
      result = JSON.parse(value);
      if (typeof value === 'object' || Array.isArray(result)) {
        return JSON.stringify(value);
      }
    } catch (e) {
      console.log(e);
    }
    return value;
  },
  parseStringToObject(value) {
    let result = '';
    if (!this.checkValue(value)) {
      return result;
    }
    try {
      if (value.indexOf('[') > -1 || value.indexOf('{') > -1) {
        result = JSON.parse(value);
        result = JSON.parse(result);
      }
      return result;
    } catch (e) {
      console.log(e);
    }
    return value;
  },
  getValueFromAttr(item, attr, defaultValue) {
    if (!this.checkValue(item) || !this.checkValue(attr)) {
      return defaultValue !== undefined ? defaultValue : '';
    }
    if (attr.indexOf('.') > -1) {
      const arrs = attr.split('.');
      let result = item;
      for (let i = 0; i < arrs.length; i++) {
        result = result[arrs[i]];
        if (!this.checkValue(result)) {
          return defaultValue !== undefined ? defaultValue : '';
        }
      }
      return result;
    }
    if (!this.checkValue(item[attr])) {
      return defaultValue !== undefined ? defaultValue : '';
    }
    return item[attr];
  },
  getValueIdFromAttr(item, attr) {
    if (!this.checkValue(item) || !this.checkValue(attr)) {
      return null;
    }
    if (attr.indexOf('.') > -1) {
      const arrs = attr.split('.');
      let result = item;
      for (let i = 0; i < arrs.length; i++) {
        result = result[arrs[i]];
        if (!this.checkValue(result)) {
          return null;
        }
      }
      return result;
    }
    return item[attr];
  },
  getValueWithLanguage(item, attr, code) {
    let languageCode = window.CONSTANT.LANGUAGE_DEFAULT;
    if (this.checkValue(code)) {
      languageCode = code;
    }
    if (!this.checkValue(item) || !this.checkValue(attr)) {
      return '';
    }
    const data = this.getValueFromAttr(item, attr);
    const obj = this.getObjectInArrs(languageCode, data, 'code');
    if (obj !== null) {
      return obj.text;
    } else {
      if (data.length > 0) {
        return this.getValueFromAttr(data, '0.text');
      }
    }
    return '';
  },
  getDataWithLanguage(item, attr) {
    if (!this.checkValue(item) || !this.checkValue(attr)) {
      return [];
    }
    const result = [];
    const data = this.getValueFromAttr(item, attr);
    if (data !== null && data !== undefined) {
      data.forEach((item) => {
        result.push({ code: item.code, text: item.text });
      });
    }
    return result;
  },
  getDataWithLanguageWithDefaultValue(item, attr, defaultValue) {
    if (!this.checkValue(item) || !this.checkValue(attr)) {
      return [];
    }
    const result = [];
    const data = this.getValueFromAttr(item, attr, defaultValue);
    if (data !== null && data !== undefined) {
      data.forEach((item) => {
        result.push({ code: item.code, text: item.text });
      });
    }
    return result;
  },
  getStringArrsWithLanguage(item, attr, attrValue, code) {
    let languageCode = window.CONSTANT.LANGUAGE_DEFAULT;
    if (this.checkValue(code)) {
      languageCode = code;
    }
    if (!this.checkValue(item) || !this.checkValue(attr)) {
      return '';
    }
    const arrs = this.getValueFromAttr(item, attr);
    if (arrs && arrs.length > 0) {
      let str = '';
      arrs.forEach(element => {
        const obj = this.getObjectInArrs(languageCode, element[attrValue] || [], 'code');
        if (obj !== null) {
          str += '<li><small>' + obj.text + '</small></li>';
        } else {
          if (element[attrValue]?.length > 0) {
            str += '<li><small>' + this.getValueFromAttr(element[attrValue], '0.text') + '</small></li>';
          }
        }
      });
      return str;
    }
    return '';
  },
  setValueWithLanguage(item, attr, code, value) {
    if (
      !this.checkValue(item) ||
      !this.checkValue(attr) ||
      !this.checkValue(code)
    ) {
      return;
    }
    const data = this.getValueFromAttr(item, attr, []);
    const obj = this.getObjectInArrs(code, data, 'code');
    if (obj !== null) {
      obj.text = value;
    } else {
      data.push({ code, text: value });
    }
  },
  getArrsInArrs(value, data, attr) {
    const $this = this;
    if (!this.checkValue(data) || !this.checkValue(attr)) {
      return [];
    }
    const arrs = data.filter(function (item) {
      return $this.getValueFromAttr(item, attr) === value;
    });
    return arrs;
  },
  getObjectInArrs(value, data, attr) {
    const arrs = this.getArrsInArrs(value, data, attr);
    if (arrs.length > 0) {
      return arrs[0];
    }
    return null;
  },
  removeObjectInArrs(value, data, attr) {
    for (let i = 0; i < data.length; i++) {
      const element = data[i];
      if (this.getValueFromAttr(element, attr) === value) {
        data.splice(i, 1);
        break;
      }
    }
  },
  splitData(str, chars, index) {
    const arrs = str.trim().split(chars);
    if (index === -1) {
      return arrs;
    }
    return arrs[index];
  },
  createCol(colName, width, className, alignment, attr, type) {
    return { colName, width, className, alignment, attr, type };
  },
  createBlobImage(file) {
    if (this.checkValue(file)) {
      return URL.createObjectURL(file);
    }
    return '';
  },
  bytesToSize(bytes) {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) return '0 Byte';
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return (
      Math.round((bytes / Math.pow(1024, i)) * 100, 2) / 100 + ' ' + sizes[i]
    );
  },
  checkStar(value) {
    const star = parseFloat(value);
    if (star >= 4.8) {
      return '#302E90';
    } else if (star >= 4) {
      return '#4285f4';
    } else if (star >= 3) {
      return '#00c851';
    } else if (star >= 2) {
      return '#fb3';
    } else if (star === 0) {
      return 'black';
    }
    return '#e0e0e0';
  },
  checkTextStar(value) {
    const star = parseFloat(value);
    if (star >= 4.8) {
      return window.I18N('rating_very_hight');
    } else if (star >= 4) {
      return window.I18N('rating_hight');
    } else if (star >= 3) {
      return window.I18N('rating_average');
    } else if (star >= 2) {
      return window.I18N('rating_low');
    } else if (star === 0) {
      return '';
    }
    return window.I18N('rating_very_low');
  },
  checkEvalution(evalution) {
    if (evalution === 'fully_compliance') {
      return '#302E90';
    } else if (evalution === 'partially_compliance') {
      return '#4285f4';
    } else if (evalution === 'none_compliance') {
      return '#00c851';
    } else if (evalution === 'not_acceptible') {
      return '#fb3';
    } 
    return '#e0e0e0';
  },
  getFileType(extention) {
    if (!this.checkValue(extention)) {
      return '';
    }
    if (
      extention === 'mp4' ||
      extention === 'mkv' ||
      extention === 'ts' ||
      extention === '3gp' ||
      extention === 'mov' ||
      extention === 'avi'
    ) {
      return IconVideo;
    } else if (
      extention === 'mp3' ||
      extention === 'm4a' ||
      extention === 'wav' ||
      extention === 'aac' ||
      extention === 'flac' ||
      extention === 'webm'
    ) {
      return IconAudio;
    } else if (
      extention === 'png' ||
      extention === 'jpg' ||
      extention === 'jpeg' ||
      extention === 'gif' ||
      extention === 'bmp'
    ) {
      return IconImage;
    } else if (
      extention === 'zip' ||
      extention === 'rar' ||
      extention === '7z'
    ) {
      return IconWinrar;
    } else if (
      extention === 'xls' ||
      extention === 'xlsx' ||
      extention === 'csv'
    ) {
      return IconExcel;
    } else if (extention === 'doc' || extention === 'docx') {
      return IconWord;
    } else if (extention === 'ppt' || extention === 'pptx') {
      return IconPPT;
    } else if (extention === 'pdf') {
      return IconPDF;
    }
    return IconFile;
  },
  createDataSelect(data, id, name, code, image, type, finalConclusion) {
    const result = [];
    for (let i = 0; i < data.length; i++) {
      result.push({
        value: this.getValueFromAttr(data[i], id),
        label: this.getValueFromAttr(data[i], name),
        code: this.getValueFromAttr(data[i], code),
        image: this.getValueFromAttr(data[i], image),
        type: this.getValueFromAttr(data[i], type),
        finalConclusion: this.getValueFromAttr(data[i], finalConclusion)
      });
    }
    return result;
  },
  createDataSelectWithGroup(data, id, name, code, attrGroup) {
    const result = [];
    const dataGroup = this.sortData(data, attrGroup);
    let obj = null;
    let group = '';
    for (let i = 0; i < dataGroup.length; i++) {
      const label = this.getValueFromAttr(dataGroup[i], attrGroup);
      if (group !== label) {
        obj = { label, options: [] };
        result.push(obj);
        group = label;
      }
      obj.options.push({
        value: this.getValueFromAttr(dataGroup[i], id),
        label: this.getValueFromAttr(dataGroup[i], name),
        code: this.getValueFromAttr(dataGroup[i], code)
      });
    }
    return result;
  },
  createDataSelectWithLanguage(data, id, name, code, isCode) {
    let languageCode = window.CONSTANT.LANGUAGE_DEFAULT;
    if (this.checkValue(code)) {
      languageCode = code;
    }
    const result = [];
    for (let i = 0; i < data.length; i++) {
      const arrs = this.getValueFromAttr(data[i], name);
      let item = this.getObjectInArrs(languageCode, arrs, 'code');
      if (!item) {
        item = this.getObjectInArrs(
          window.CONSTANT.LANGUAGE_DEFAULT,
          arrs,
          'code'
        );
        if (!item) {
          item = arrs[0];
        }
      }
      let label = item.text;
      if (isCode) {
        label = data[i].code + ' - ' + label;
      }
      result.push({ value: this.getValueFromAttr(data[i], id), label });
    }
    return result;
  },
  setDataSelect(data, value) {
    const obj = this.getObjectInArrs(value, data, 'value');
    return obj;
  },
  setDataMultiSelect(data, value, attr) {
    try {
      const result = [];
      value.forEach((item) => {
        const obj = this.getObjectInArrs(
          attr ? this.getValueFromAttr(item, attr) : item,
          data,
          'value'
        );
        result.push(obj);
      });
      return result;
    } catch (error) {
      this.showErrorLogs('Commom.setDataMultiSelect');
    }
    return [];
  },
  setDataMultiSelectWithGroup(data, value, attr) {
    try {
      const result = [];
      value.forEach((item) => {
        data.forEach((element) => {
          if (element.options && element.options.length > 0) {
            element.options.forEach((option) => {
              if (
                option.value ===
                (attr ? this.getValueFromAttr(item, attr) : item)
              ) {
                result.push(option);
              }
            });
          } else {
            if (
              element.value ===
              (attr ? this.getValueFromAttr(item, attr) : item)
            ) {
              result.push(element);
            }
          }
        });
      });
      return result;
    } catch (error) {
      this.showErrorLogs('Commom.setDataMultiSelectWithGroup');
    }
    return [];
  },
  getDataSelect(data, isMulti = false) {
    if (!isMulti) {
      if (!this.checkValue(data)) {
        return null;
      } else {
        return data.value;
      }
    }
    if (!this.checkValue(data)) {
      return [];
    }
    const result = [];
    if (Array.isArray(data)) {
      if (data.length > 0) {
        data.forEach((item) => {
          if (this.checkValue(item.value)) {
            result.push(item.value);
          }
        });
      }
    } else {
      if (this.checkValue(data.value)) {
        result.push(data.value);
      }
    }
    return result;
  },
  createDataSelectHaveGroup(data, id, name, group, group_name) {
    const result = [];
    let str = '';
    let item = {};
    for (let i = 0; i < data.length; i++) {
      const obj = data[i];
      if (str !== this.getValueFromAttr(obj, group)) {
        item = {
          label: this.getValueFromAttr(obj, group_name),
          options: []
        };
        result.push(item);
        str = this.getValueFromAttr(obj, group);
      }
      item.options.push({
        value: this.getValueFromAttr(obj, id),
        label: this.getValueFromAttr(obj, name)
      });
    }
    return result;
  },
  createDataTableTree(arrs, data, parentId, level) {
    arrs.forEach((item) => {
      if ((level === 1 && item.parent === null) || level > 1) {
        item.level = level;
      }
      if (!this.checkValue(item.parent)) {
        item.isParent = true;
      } else {
        item.isParent = false;
      }
      const children = this.getArrsInArrs(item._id, data, parentId);
      if (children.length > 0) {
        item.hasChildren = true;
        item.children = children;
        this.createDataTableTree(children, data, parentId, level + 1);
      } else {
        item.hasChildren = false;
        item.children = [];
      }
    });
  },
  createSelectCompany(data, companies) {
    const code = localStorage.getItem(window.CONSTANT.ACCOUNT_TYPE);
    if (code === 'COMPANY' || code === 'MANAGER') {
      const company = localStorage.getItem(window.CONSTANT.COMPANY_ID);
      return this.getArrsInArrs(company, data, 'value');
    }
    if (code === 'INFLUENCER' && companies !== -1) {
      const result = [];
      companies.forEach((item) => {
        const obj = this.getObjectInArrs(
          this.getValueIdFromAttr(item, 'company._id'),
          data,
          'value'
        );
        if (obj !== null) {
          result.push(obj);
        }
      });
      return result;
    }
    return data;
  },
  sortData(data, attr) {
    const $this = this;
    const result = data.sort(function (a, b) {
      if ($this.getValueFromAttr(a, attr) < $this.getValueFromAttr(b, attr))
        return -1;
      if ($this.getValueFromAttr(a, attr) > $this.getValueFromAttr(b, attr))
        return 1;
      return 0;
    });
    return result;
  },
  sortDescData(data, attr) {
    const $this = this;
    const result = data.sort(function (a, b) {
      if ($this.getValueFromAttr(b, attr) < $this.getValueFromAttr(a, attr))
        return -1;
      if ($this.getValueFromAttr(b, attr) > $this.getValueFromAttr(a, attr))
        return 1;
      return 0;
    });
    return result;
  },
  formatDate(data, attr, format = 'DD/MM/YYYY') {
    return data.map((item) => {
      if (this.checkValue(item[attr])) {
        const date = new Date(+item[attr]);
        item[attr] = moment(date).format(format);
      } else {
        item[attr] = '';
      }
      return item;
    });
  },
  formatMultiDate(data, attrs, format = 'DD/MM/YYYY') {
    const arrs = attrs.split(',');
    return data.map((item) => {
      arrs.forEach((attr) => {
        if (this.checkValue(item[attr])) {
          const date = new Date(+item[attr]);
          item[attr] = moment(date).format(format);
        } else {
          item[attr] = '';
        }
      });
      return item;
    });
  },
  generateCode(str) {
    const strDate = new Date().getTime();
    return str + '-' + strDate;
  },
  generateStatusText(value) {
    if (value === 0) {
      return (
        <label className="label-box label-info p-2 m-0">
          {window.I18N('open')}
        </label>
      );
    }
    if (value === 1) {
      return (
        <label className="label-box label-primary p-2 m-0">
          {window.I18N('running')}
        </label>
      );
    }
    if (value === 3) {
      return (
        <label className="label-box label-success p-2 m-0">
          {window.I18N('completed')}
        </label>
      );
    }
    return (
      <label className="label-box label-danger p-2 m-0">
        {window.I18N('expired')}
      </label>
    );
  },
  genKey(length = 8) {
    let result = '';
    const characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  },
  setDataCell(value) {
    if (window.COMMON.checkValue(value) && (typeof (value) === 'number' || !isNaN(value))) {
      return Number(value);
    }
    return value;
  },
  exportPDF(dataKey) {
    const input = document.getElementById('content-pdf');
    const obj = window.COMMON.getObjectInArrs(dataKey, window.CONSTANT.DATA_TEMPLATE_PDF, 'key');

    const htmlInput = `
      <html>
        <head>
          <title>${obj.name}</title>
          <style>
            @media print {
              @page { margin: 0; margin-top:10px; }
              div.print{
                width: 100%;
              }
            }
            
          </style>
        </head>
        <body>
            ${input.innerHTML}
        </body>
      </html>
    `;

    const windowOb = window.open('', 'PRINT', 'height=650,width=900,top=100,left=150');
    windowOb.document.write(htmlInput);

    windowOb.document.close();
    windowOb.focus();

    windowOb.print();
    windowOb.close();

  },
  async queryAuth(str, variables, isLoading = true) {
    if (isLoading) {
      window.showLoading();
    }
    let result = null;
    try {
      result = await window.API_LOGIN.query({
        query: str,
        variables
      });
    } catch (error) {
      console.log(error);
    }
    window.API_LOGIN.cache.reset();
    if (isLoading) {
      window.hideLoading();
    }
    return result;
  },
  async mutationAuth(str, variables, isLoading = true) {
    if (isLoading) {
      window.showLoading();
    }
    let result = null;
    try {
      result = await window.API_LOGIN.mutate({
        mutation: str,
        variables
      });
    } catch (error) {
      result = {
        isError: true,
        error
      };
      console.log(error);
    }
    window.API_LOGIN.cache.reset();
    if (isLoading) {
      window.hideLoading();
    }
    return result;
  },
  async query(str, variables, isLoading = true) {
    if (isLoading) {
      window.showLoading();
    }
    let result = null;
    try {
      result = await window.API.query({
        query: str,
        variables
      });
    } catch (error) {
      result = {
        isError: true,
        error
      };
      console.log(error);
    }
    window.API.cache.reset();
    if (isLoading) {
      window.hideLoading();
    }
    return result;
  },
  async mutation(str, variables, isLoading = true) {
    if (isLoading) {
      window.showLoading();
    }
    let result = null;
    try {
      result = await window.API.mutate({
        mutation: str,
        variables
      });
    } catch (error) {
      result = {
        isError: true,
        error
      };
      console.log(error);
    }
    window.API.cache.reset();
    if (isLoading) {
      window.hideLoading();
    }
    return result;
  },
  getAgeFromMillisecond(millisecond) {
    const today = new Date();
    const birthDate = new Date(millisecond);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  },
  getDataChart({ types, data, pastDays, itemKey, dateKey }) {
    let dataChart = {
      labels: [],
      data: []
    };
    if (!data) {
      return dataChart;
    }




    if (!pastDays.includes('year') && !isNaN(pastDays)) {
      dataChart = this.getDataChartInPastDays({
        data,
        numberDays: Number(pastDays),
        types,
        itemKey,
        dateKey
      });
    }
    else {
      const yearNumber = pastDays.split(' ')[0];
      dataChart = this.getDataChartInPastYear({
        data,
        numberMonths: (yearNumber * 12),
        types,
        itemKey,
        dateKey,
        formatDateLabel: 'DD/MM/YYYY'
      });
    }
    return dataChart;

  },
  getDataChartInPastDays({ data, numberDays, types, itemKey, dateKey, formatDateLabel = 'DD/MM' }) {
    const dataChart = {
      labels: [],
      data: []
    };
    for (let i = 0; i < types.length; i++) {
      //gom dữ liệu lại theo type i
      const items = this.getArrsInArrs(types[i], data, itemKey);
      dataChart.data[i] = [];
      for (let j = 0; j < numberDays; j++) {

        const date = window.COMMON_DATE.formatDate(
          window.COMMON_DATE.addDaysFromNow(0 - j),
          formatDateLabel
        );
        const dateToGroup = window.COMMON_DATE.formatDate(
          window.COMMON_DATE.addDaysFromNow(0 - j),
          'DD/MM/YYYY'
        );
        if (i === 0) {
          dataChart.labels.unshift(date);
        }
        //gom dữ liệu (đã được gom theo type) theo ngày
        const children = this.getArrsInArrs(
          dateToGroup,
          items,
          dateKey,
        );
        dataChart.data[i].unshift(children.length);

      }
    }
    return dataChart;
  },
  getDataChartInPastYear({ data, numberMonths, types, itemKey, dateKey, formatDateLabel = 'DD/MM' }) {
    const dataChart = {
      labels: [],
      data: []
    };
    for (let i = 0; i < types.length; i++) {
      //gom dữ liệu lại theo type i
      const items = this.getArrsInArrs(types[i], data, itemKey);
      dataChart.data[i] = [];

      for (let j = 0; j < numberMonths; j++) {

        const date = window.COMMON_DATE.formatDate(
          window.COMMON_DATE.addMonthsFromNow(0 - j),
          formatDateLabel
        );
        const dateToGroup = window.COMMON_DATE.formatDate(
          window.COMMON_DATE.addMonthsFromNow(0 - j),
          'MM/YYYY'
        );
        // console.log(dateToGroup)
        if (i === 0) {
          dataChart.labels.unshift(date);
        }
        //gom dữ liệu (đã được gom theo type) theo ngày
        const children = window.COMMON.getArrsInArrs(
          dateToGroup,
          items,
          dateKey,
        );
        dataChart.data[i].unshift(children.length);

      }
    }
    return dataChart;
  },
  async httpRequest({ url, method, input, requireToken = true, handleError = null }) {
    try {
      let initConfig = {};
      if (requireToken === true) {
        const token = localStorage.getItem(window.CONSTANT.AUTHEN_TOKEN);
        initConfig = {
          headers: {
            authorization: `Bearer ${token}`,
            'Access-Control-Allow-Origin' : '*'
          }
        };
      }
      const result = await axios.request({
        url,
        method,
        ...initConfig,
        ...input
      });
      const data = result.data;
      if (data) {
        return data;
      }
      return null;
    }
    catch (error) {
      if (handleError) {
        handleError();
      }
      return null;
    }
  },
  //rangeDay = {min, max}
  //rangeMonth = {min, max}
  //rangeYear = {min, max}
  createDataSetForTestChart: ({ types, length, rangeDay = null, rangeMonth = null, rangeYear = null }) => {
    const max = types.length;
    const min = 0;
    const data = [];
    for (let i = 0; i < length; i++) {
      const randomNumber = Math.floor(Math.random() * (max - min)) + min;
      const type = types[randomNumber];
      const newDate = new Date();
      if (rangeDay !== null) {
        const randomDay = Math.floor(Math.random() * ((rangeDay.max + 1) - rangeDay.min)) + rangeDay.min;
        newDate.setDate(randomDay);
      }
      if (rangeMonth !== null) {
        const randomMonth = Math.floor(Math.random() * ((rangeMonth.max + 1) - rangeMonth.min)) + rangeMonth.min;
        newDate.setMonth(randomMonth);

      }
      if (rangeYear !== null) {
        const randomYear = Math.floor(Math.random() * ((rangeYear.max + 1) - rangeYear.min)) + rangeYear.min;
        newDate.setFullYear(randomYear);
      }

      data.push({
        type,
        createdDate: newDate
      });
    }
    return data;
  },
  getLocalJSONData({ key, defaultValue = [] }) {
    const dataString = localStorage.getItem(key);
    if (dataString) {
      const data = JSON.parse(dataString);
      return data;
    }
    return defaultValue;
  },
  removeDraftSurvey({ key, draftcode }) {
    //remove draft data in local;
    let draftSurveysData = this.getLocalJSONData({ key, defaultValue: [] });
    draftSurveysData = draftSurveysData.filter((item) => item.draftcode !== draftcode);
    localStorage.setItem(window.CONSTANT.DRAFT_SURVEYS, JSON.stringify(draftSurveysData));
  },
  removeDraft({ key, draftcode }) {
    let draftScheduleAssessmentData = this.getLocalJSONData({ key, defaultValue: [] });
    draftScheduleAssessmentData = draftScheduleAssessmentData.filter((item) => item.draftcode !== draftcode);
    localStorage.setItem(key, JSON.stringify(draftScheduleAssessmentData));
  },
  getValueIdFollowCompanyType(item, defaultCompany = false) {
    let value_id;
    if (item.accountType === window.CONSTANT.COMPANY) {
      value_id = this.getValueFromAttr(item, 'company._id');
    }
    else if (item.accountType === window.CONSTANT.UNION) {
      value_id = this.getValueFromAttr(item, 'union._id');
    }
    else if (item.accountType === window.CONSTANT.INFLUENCER) {
      value_id = this.getValueFromAttr(item, 'influencer._id');
    }
    else if (item.company && defaultCompany === true) {
      value_id = this.getValueFromAttr(item, 'company._id');
    }
    return value_id;
  },
  getCurrentUserInformation() {
    return {
      _id: localStorage.getItem(window.CONSTANT.ID),
      id: localStorage.getItem(window.CONSTANT.ID),
      name: localStorage.getItem(window.CONSTANT.FULL_NAME),
      nickname: localStorage.getItem(window.CONSTANT.NICK_NAME),
      avatar: localStorage.getItem(window.CONSTANT.AVATAR),
      email: localStorage.getItem(window.CONSTANT.EMAIL),
      authenToken: localStorage.getItem(window.CONSTANT.AUTHEN_TOKEN),
      access_token: localStorage.getItem('access_token'),
      company: localStorage.getItem(window.CONSTANT.COMPANY_ID),
      companyType: localStorage.getItem(window.CONSTANT.COMPANY_TYPE),
      accountType: localStorage.getItem(window.CONSTANT.ACCOUNT_TYPE)
    };
  },
  getGroupChatInforToShow(groupChat) {
    const newGroupChat = { ...groupChat };
    if (this.checkIndividualChatType(newGroupChat.type) === true) {
      const currentUserId = localStorage.getItem(window.CONSTANT.ID);
      const otherMembers = newGroupChat.members.filter(item => {
        const idToCheck = item._id || item.id;
        return idToCheck !== currentUserId;

      });
      const anotherMember = otherMembers[0];
      if (anotherMember) {
        newGroupChat.groupName = anotherMember.nickname;
        newGroupChat.image = anotherMember.avatar;
      }
    }
    if (newGroupChat.lastMessage) {
      const date = new Date(Number(newGroupChat.lastMessage.timestamp));
      newGroupChat.lastMessage.timestampFormat = moment(Number(date)).fromNow();
    }
    return newGroupChat;
  },
  checkIndividualChatType(type) {
    if (!type) return false;
    const individualChatType = [window.CONSTANT.PRIVATE_CHAT, window.CONSTANT.FRIEND];
    return individualChatType.includes(type);
  },
  formatDateSingleData(data, attr, format = 'DD/MM/YYYY') {
    if (data[attr]) {
      const date = data[attr];
      return moment(+date).format(format);
    }
  },
  formatUnixDateSingleData(data, attr, format = 'DD/MM/YYYY') {
    if (data[attr]) {
      const date = new Date(+data[attr]);
      return moment(date).format(format);
    }
  },
  splitMembersFromIndividualGroupChat(groupChat) {
    //distinguish 2 member: current user and another user in individual group chat
    const currentUser = this.getCurrentUserInformation();
    const otherMembers = groupChat.members.filter((item) => item.id !== currentUser.id);
    const anotherUser = otherMembers.length > 0 ? otherMembers[0] : null;
    return { currentUser, anotherUser };
  },
  checkIncludesText(item, value) {
    let itemToCheck;
    let valueToCheck;
    if (item) {
      itemToCheck = item.toLowerCase();
    }
    if (value) {
      valueToCheck = value.toLowerCase();
    }
    if (!itemToCheck || !valueToCheck) {
      return false;
    }
    if (itemToCheck.includes(valueToCheck)) {
      return true;
    }
    return false;
  },
  convertMessageDataType(type) {
    const texType = [0, 1, 2, 3, 4, 7];
    const arrayType = [6, 8];
    if (texType.includes(type)) {
      return window.CONSTANT.TEXT;
    }
    else if (arrayType.includes(type)) {
      return window.CONSTANT.ARRAY;
    }
    return window.CONSTANT.TEXT;
  },
  //convert type from db to firebase
  convertGroupChatTypeFirebase(type) {
    let newGroupChatType = type;
    if (type === 'PUBLIC') {
      newGroupChatType = 'GROUP';
    }
    else if (type === 'PRIVATE') {
      newGroupChatType = 'PRIVATE_GROUP';
    }
    return newGroupChatType;
  },
  getFileExtension(fileName) {
    let extensionName;
    try {
      const fileNameArr = fileName.split('.');
      extensionName=fileNameArr[1];
    }
    catch (error) {
      extensionName = '';
    }
    return extensionName.toLowerCase();
  },
  async sendMailNotify(props) {
    try {
      const params = {
        receiver: [],
        text: '',
        email: '',
        title: '',
        body: '',
        type: 'GRI_NOTIFY_MESSAGE',
        ...props
      };
	  const result = await window.MAIL_API.sendMailNotify(params);
	  return result;
    } catch (error) {
      this.showErrorLogs('Commom.setDataMultiSelectWithGroup');
    }
    return null;
  },
  getTimeZone(){
    let timeZone
    if (typeof Intl === 'object' && typeof Intl.DateTimeFormat === 'function') {
      // Modern browser with Intl support
      timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    } else {
      // Fallback for older browsers
      const offsetMinutes = new Date().getTimezoneOffset();
      const offsetHours = offsetMinutes / 60;
      const offsetSign = offsetHours > 0 ? '-' : '+';
      const offsetHoursAbs = Math.abs(offsetHours);
      const offsetMinutesAbs = Math.abs(offsetMinutes % 60);
      timeZone = `UTC${offsetSign}${String(offsetHoursAbs).padStart(
        2,
        '0'
      )}:${String(offsetMinutesAbs).padStart(2, '0')}`;
    }
  
    // test
    try {
      new Date().toLocaleString('en-US', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        timeZone,
      });
      return timeZone;
    } catch (e) {
      console.log(e);
      return undefined;
    }
  },
  formatCurrency(value, config = { style: 'currency', currency: 'VND' }, locale = 'vi-VN'){
    const format = new Intl.NumberFormat(locale, config).format(parseFloat(value))
    return format
  },
  isEmpty(value){
    if (value == null) return true; // null or undefined
    if (Array.isArray(value) && value.length === 0) return true; // Empty array
    if (typeof value === 'object' && !(value instanceof Date) && !(value instanceof Map) && !(value instanceof Set) && Object.keys(value).length === 0) return true; // Empty object (not Date, Map, or Set)
    if (typeof value === 'string' && value.trim().length === 0) return true; // Empty string or only whitespace
    if (value instanceof Map || value instanceof Set) return value.size === 0; // Empty Map or Set

    return false; // All other cases are not empty
  },
  convertDataToSelect(data, attrs = { value: "information._id", label: "information.name" }){
    const { label, value } = attrs
    return data.map((item) => ({
      value: this.getValueFromAttr(item, value),
      label: this.getValueFromAttr(item, label)
    }))
  },
  calculateBenchmark(arrayObjectFeedbackQuestion, groupA, groupB){
    /* 
    let arrayObjectFeedbackQuestion = [	
      {company: [0, 1, 1, 0, 0, 0], worker: [157, 213, 481, 25, 7, 0], id: '124' },
      {company: [0, 1, 0, 0], worker: [51,26,7,198] }, 
      {company: [0, 1, 0, 1], worker: [5,16,37,48]  },
      {company: [1, 1, 0, 0], worker: [5,76,27,18]  }
    ] 
    groupA= 'company'
    groupB= 'worker'
    */
    let output = []
    if (arrayObjectFeedbackQuestion?.length <1) return []
    arrayObjectFeedbackQuestion.forEach(feedbackQuestion =>{
        let singleBenchmark = { point: 0 };
        if (feedbackQuestion?.id){
            singleBenchmark.id = feedbackQuestion.id
        }
        if(feedbackQuestion?.mapQuestion){
          singleBenchmark = { ...singleBenchmark, ...feedbackQuestion?.mapQuestion }
        }
        const totalGroupA = feedbackQuestion[groupA]?.reduce((acc,curr)=> acc+curr,0)
        const totalGroupB = feedbackQuestion[groupB]?.reduce((acc,curr)=> acc+curr,0)
        feedbackQuestion[groupB].forEach((el, index)=>{
            const percentWorker = (el/totalGroupB)||0
            const percentCompany = (feedbackQuestion[groupA][index]/totalGroupA)||0
            singleBenchmark.point += 50*Math.abs(percentWorker - percentCompany)
        })
        for (let type in Constant.GROUP_DIFFERENCE_FEEDBACK ) {
            if (singleBenchmark.point <= Constant.GROUP_DIFFERENCE_FEEDBACK[type]){
                singleBenchmark.type = type
                break;
            }
        }
        output.push(singleBenchmark)
    })
    return output
  },
  removeDuplicateElements(data = []){
    if(!Array.isArray(data)){
      return []
    }
    return [...new Set(data)]
  },
  removeElementInArray(data = [], element){
    if(!Array.isArray(data)){
      return []
    }
    return data.filter(item => item !== element)
  },
  validation (obj, rules, formId = '', event = '') {
    const errors = {};
    // handle form
    if(formId && event){
      window.COMMON.checkFormValidation(formId, event)
    }
    for (const [field, ruleSet] of Object.entries(rules)) {
        const value = obj[field];
        for (const [rule, ruleDetail] of Object.entries(ruleSet)) {
            const { value: ruleValue, message } = ruleDetail;
            const defaultMessages = {
                required: `${field} is required.`,
                type: `${field} must be of type ${ruleValue}.`,
                minLength: `${field} must be at least ${ruleValue} characters long.`,
                maxLength: `${field} must be no more than ${ruleValue} characters long.`,
                min: `${field} must be at least ${ruleValue}.`,
                max: `${field} must be no more than ${ruleValue}.`,
                compare: `${field} must be ${ruleValue}.`
            };

            if (rule === 'required' && ruleValue && (value === undefined || value === null || value === '' || window.COMMON.isEmpty(value))) {
                errors[field] = message || defaultMessages[rule];
            } else if (rule === 'type' && typeof value !== ruleValue) {
                errors[field] = message || defaultMessages[rule];
            } else if (rule === 'minLength' && typeof value === 'string' && value.length < ruleValue) {
                errors[field] = message || defaultMessages[rule];
            } else if (rule === 'maxLength' && typeof value === 'string' && value.length > ruleValue) {
                errors[field] = message || defaultMessages[rule];
            } else if (rule === 'min') {
              const numValue = typeof value === 'string' ? Number(value) : value;
              if (typeof numValue !== 'number' || isNaN(numValue) || numValue < ruleValue) {
                errors[field] = message || defaultMessages[rule];
              }
            } else if (rule === 'max') {
              const numValue = typeof value === 'string' ? Number(value) : value;
              if (typeof numValue !== 'number' || isNaN(numValue) || numValue > ruleValue) {
                errors[field] = message || defaultMessages[rule];
              }
            } else if (rule === 'compare' && value === ruleValue) {
                errors[field] = message || defaultMessages[rule];
            }
        }
    }

    if (Object.keys(errors).length > 0) {
        for (const error of Object.values(errors)) {
          window.COMMON.showMessage('warning', 'INVALID_FIELDS', error)
          return false; // Return false if there are validation errors
        }
    }

    return true; // Return true if there are no validation errors
  },
  formatDateForEvent(date = '', lang = 'VI') {
    const daysOfWeek = {
        'VI': ['CN', 'T2', 'T3', 'T4', 'T5', 'T6', 'T7'],
        'EN': ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
    };

    const translations = {
        'VI': {
            'today': 'HÔM NAY',
            'tomorrow': 'NGÀY MAI',
            'month': 'THÁNG',
            'at': 'LÚC'
        },
        'EN': {
            'today': 'TODAY',
            'tomorrow': 'TOMORROW',
            'month': '',
            'at': 'AT'
        }
    };

    const months = {
        'VI': ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
        'EN': ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    };

    const now = moment();
    const targetDate = moment(+date);
    let dayOfWeek = daysOfWeek[lang][targetDate.day()];

    let formattedDate;
    if (targetDate.isSame(now, 'day')) {
        formattedDate = `${dayOfWeek}, ${translations[lang]['today']} ${translations[lang]['at']} ${targetDate.format('HH:mm')}`;
    } else if (targetDate.isSame(moment().add(1, 'days'), 'day')) {
        formattedDate = `${dayOfWeek}, ${translations[lang]['tomorrow']} ${translations[lang]['at']} ${targetDate.format('HH:mm')}`;
    } else {
        if (lang === 'EN') {
            formattedDate = `${dayOfWeek}, ${months[lang][targetDate.month()]} ${targetDate.format('DD')} ${translations[lang]['at']} ${targetDate.format('HH:mm')}`;
        } else {
            formattedDate = `${dayOfWeek}, ${targetDate.format('DD')} ${translations[lang]['month']} ${months[lang][targetDate.month()]} ${translations[lang]['at']} ${targetDate.format('HH:mm')}`;
        }
    }

    return formattedDate;
  },
  isValidObjectId(id){
    const ObjectIdPattern = /^[0-9a-fA-F]{24}$/;
    return ObjectIdPattern.test(id);
  },
  filterListValidObjectId(ids){
    if (!this.isEmpty(ids)){
      return ids.filter(id=> id !== '' && this.isValidObjectId(id))
    }
    return []
  },
  validMultiplePasswords(data){
    for(let i = 0; i < data.length; i++){
      const rulePassword = {
        password: {
          minLength: {
            value: 6,
            message: window.I18N('MSG_CODE_088', { line: i + 1 })
          },
          required: {
            value: true,
            message: window.I18N('MSG_CODE_089', { line: i + 1 })
          }
        }
      }
      if(!this.validation(data[i], rulePassword)){
        return false
      }
    }
    return true
  },
  deepCopyArray(arr) {
    return arr.map(item => {
      if (item === null || typeof item !== 'object') return item
      if (Array.isArray(item)) return this.deepCopyArray(item)
      return this.deepCopyObject(item)
    })
  },
  deepCopyObject(obj) {
    if (obj === null || typeof obj !== 'object') return obj;
    const copy = Array.isArray(obj) ? [] : {};
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        copy[key] = typeof obj[key] === 'object' ? this.deepCopyObject(obj[key]) : obj[key];
      }
    }
    return copy;
  },
  isMobileDevice() {
    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    if (isMobile) {
      // User is accessing the page on a mobile device
      return true
    } else {
      // User is accessing the page on a desktop device
      return false
    }
  },
  validateTextInput(text, maxLength = window.CONSTANT.MAX_TEXT_LENGTH) {
    if(text.startsWith(' ') || text.length > maxLength){
      return false
    }
    return true
  },
  validateNumberInput(value){
    const pattern = /^[0-9]+$/;
    const convertNumber = this.isEmpty(value) ? '' : parseInt(value)
    if(this.isEmpty(convertNumber)){
      return true
    }
    if(!pattern.test(value) || convertNumber < 0 || convertNumber > window.CONSTANT.MAX_INPUT_NUMBER){
      return false
    }
    return true
  },
  isObjectsDifferent(obj1, obj2, diffKey = false) {
    let diffKeys = null;
    if (diffKey) diffKeys = [];

    function compareObjects(o1, o2) {
        // Check for primitive values or null
        if (o1 === o2) return false;
        if (typeof o1 !== "object" || typeof o2 !== "object" || o1 === null || o2 === null) {
            if (diffKey && diffKeys !== null) return true;
            return true;
        }

        // Check if both are arrays
        if (Array.isArray(o1) && Array.isArray(o2)) {
            if (o1.length !== o2.length) return true;
            for (let i = 0; i < o1.length; i++) {
                if (compareObjects(o1[i], o2[i])) return true;
            }
            return false;
        }

        // Check if one is array and the other is not
        if (Array.isArray(o1) || Array.isArray(o2)) return true;

        // Compare object keys and values
        const keys1 = Object.keys(o1), keys2 = Object.keys(o2);
        if (keys1.length !== keys2.length) return true;

        for (const key of new Set([...keys1, ...keys2])) {
            if (!o1.hasOwnProperty(key) || !o2.hasOwnProperty(key) || compareObjects(o1[key], o2[key])) {
                if (diffKey && diffKeys !== null) diffKeys.push(key);
                else return true;
            }
        }

        return false;
    }
    const result = compareObjects(obj1, obj2);
    if (!diffKey) return result;
    return diffKeys;
  },
  async requestGraphQL({ 
    type = 'query', 
    apiName, 
    params = {}, 
    loading = true, 
    mutationMode = '', 
    successCallback = () => {}, 
    errorCallback = () => {},
    logMessage = '' , 
    message = {} 
  }) {
    const callAPIWithType = {
      query: this.query,
      mutation: this.mutation
    }
 
    try {
      const customMessage = this.isEmpty(message) ? {} : { successful: message.successful, failed: message.failed }
      const result = await callAPIWithType[type](apiName, params, loading)
      if(type === 'mutation' && !this.checkResultData(result, mutationMode, customMessage)){
        return
      }
      successCallback(result)
      return result

    } catch (error) {
      errorCallback()
      console.error("🚀 ~ requestGraphQL ~ error: ", error)
      this.showErrorLogs(`🚀 ~ [x] function name error: ${logMessage} ::: ${error.message}`)
    }
  },
  removeDuplicatesObjectInArray(array, key) {
    const seen = new Set();
    return array.filter(obj => {
      if (seen.has(obj[key])) {
        return false; // This field already exists, remove
      }
      seen.add(obj[key]);
      return true; // Does not exist, keep
    });
  }
};

export default Common;
