import { MDBIcon } from "mdbreact";
import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import Select from 'react-select';
import AccountGRAPHQL from "../../../graphql/Account";
import CompanyGRAPHQL from "../../../graphql/Company";
import MetadataGRAPHQL from "../../../graphql/Metadata";
import RoleGRAPHQL from "../../../graphql/Role";
import Pagination from "../../components/Pagination";
import TableCustomize from "../../components/TableCustomize";

function UserStoragePage() {
    const config = [
        window.COMMON.createCol(window.I18N('no'), '5%', '', '', '', 'INDEX'),
        window.COMMON.createCol(window.I18N('name'), '20%', 'p-0 pl-1', '', 'avatar,name', 'IMAGE_TEXT'),
        window.COMMON.createCol(window.I18N('role'), '20%', '', '', 'roleText', 'TEXT'),
        window.COMMON.createCol(`${window.I18N('storage_used')} / ${window.I18N('remaining_storage')}`, '20%', '', '', 'displayUsedStoragePercent', 'TEXT'),
        window.COMMON.createCol(`${window.I18N('storage_used')} (%)`, '15%', '', '', 'displayUsedStorage', 'TEXT'),
    ];
    const COMPANY_TYPE = localStorage.getItem(window.CONSTANT.COMPANY_TYPE);
    const COMPANY_ID = localStorage.getItem(window.CONSTANT.COMPANY_ID);
    const [companyList, setCompanyList] = useState({ company: null });
    const [options, setOptions] = useState({
        company: [],
    });
    const [object, setObject] = useState({ index: -1 });
    const [pagination, setPagination] = useState({ total: 0, pageSize: 10, currentPage: 0 });
    const [data, setData] = useState([]);
    const [storageDisplay, setStorageDisplay] = useState({total: 0,remaining:0 })
    //flag to getAccountData
    const hasUpdated = useRef(false);
    const GB = 1024 * 1024 * 1024

    const getDataCompany = async () => {
        try {
            if (COMPANY_TYPE === window.CONSTANT.COMPANY || COMPANY_TYPE === window.CONSTANT.UNION) {
                companyList.company = { value: COMPANY_ID, label: '', code: COMPANY_TYPE };
                setCompanyList({ ...companyList });
                getDataRole();
                return;
            }
            if (COMPANY_TYPE === window.CONSTANT.INFLUENCER) {
                await window.COMMON.getDataCompany();
                const dataCompany = JSON.parse(localStorage.getItem(window.CONSTANT.DATA_COMPANY));
                options.company = window.COMMON.createDataSelectWithGroup(dataCompany, 'id', 'name', 'accountType', 'accountType');
                options.company.unshift({ value: COMPANY_ID, label: window.I18N('my_organization'), code: COMPANY_TYPE });
                companyList.company = window.COMMON.getValueFromAttr(options.company, '0', null);
                setOptions(options);
                setCompanyList({ ...companyList });
                getDataRole();
                return;
            }
            await window.COMMON.getDataAllCompany();
            let dataCompany = JSON.parse(localStorage.getItem(window.CONSTANT.DATA_ALL_COMPANY));

            if (dataCompany && dataCompany.length > 0) {
                options.company = window.COMMON.createDataSelectWithGroup(dataCompany, '_id', 'information.name', 'type', 'type');

                options.company.push({label:"GOPY INFLUENCER", options:[{code: "GOPY_INFLUENCER",
                    label: "Gopy Influencer",
                    value: "gopy_influencer"}]})
                
                options.company.push({label:"SUPER ADMIN", options:[{code: "SUPER_ADMIN",
                    label: "Super Admin",
                    value: "admin"}]})

                    
                companyList.company = window.COMMON.getValueFromAttr(options.company, '0.options.0', null);
            }
            setOptions(options);
            setCompanyList({ ...companyList });
            getDataRole();
        } catch (error) {
            window.COMMON.showErrorLogs('UserStoragePage.getDataCompany');
        }
    };

    const changePage = useCallback((index) => {
        pagination.currentPage = index;
        setPagination({ ...pagination });
        getDataAccount();
        // eslint-disable-next-line
    }, [object, pagination, companyList, storageDisplay]);
    const showContainerAccount = useCallback((index) => {
        window.COMMON.showContainerSave();
        object.index = index;
        object.item = data[index];
        setObject({ ...object });
        // eslint-disable-next-line
    }, [object, data]);

    const handleFilterSelect = (event, attr) => {
        companyList[attr] = event
        pagination.currentPage = 0;
        setPagination(pagination);
        getDataRole();
    };

    const getDataRole = async () => {
        try {
            if (companyList.company !== null) {
                const params = {
                    company: companyList.company.value
                };
                let result = []

                if(companyList.company.code !== "SUPER_ADMIN" && companyList.company.code !== "GOPY_INFLUENCER"){
                    result = await window.COMMON.query(RoleGRAPHQL.QUERY_ROLE, params);
                }

                if (result && result.data) {
                    options.role = window.COMMON.createDataSelect(result.data.getRoles, 'code', 'name', 'code', '_id');
                    if (companyList.company) {
                        options.role.push({ value: window.CONSTANT.INDIVIDUAL, label: window.I18N('individual'), code: window.CONSTANT.INDIVIDUAL });
                        options.role.push({ value: window.CONSTANT.COMPANY, label: window.I18N('company'), code: window.CONSTANT.COMPANY });
                        options.role.push({ value: window.CONSTANT.INFLUENCER, label: window.I18N('influencer'), code: window.CONSTANT.INFLUENCER });
                        options.role.reverse();
                    }
                    setOptions(options);
                } else {
                    options.role = []
                    if(companyList.company.code === "SUPER_ADMIN"){
                        options.role.push({ value: window.CONSTANT.SUPER_ADMIN, label: window.I18N('SUPER_ADMIN'), code: window.CONSTANT.SUPER_ADMIN });
                    }

                    if(companyList.company.code === "GOPY_INFLUENCER"){
                        options.role.push({ value: window.CONSTANT.GOPY_INFLUENCER, label: window.I18N('GOPY_INFLUENCER'), code: window.CONSTANT.GOPY_INFLUENCER });
                    }

                    setOptions(options)
                }

                if(companyList.company && companyList.company.code !== window.CONSTANT.SUPER_ADMIN && companyList.company.code !== window.CONSTANT.GOPY_INFLUENCER){
                    //flag to getAccountData
                    hasUpdated.current = false
                    
                    let dataCompany = JSON.parse(localStorage.getItem(window.CONSTANT.DATA_ALL_COMPANY));
                    dataCompany = dataCompany && dataCompany.find((company) => company._id === companyList.company.value)
                    let totalStorageDefault = process.env.REACT_APP_DEFAULT_STORAGE
                    if(!dataCompany.information?.package?.storage && dataCompany.information === window.CONSTANT.INFLUENCER){
                        totalStorageDefault = 10
                    }
                    let ownerCompany = await window.COMMON.query(AccountGRAPHQL.QUERY_ACCOUNT_BY_ID, { id: dataCompany.information._id }, true);
                    ownerCompany = ownerCompany.data.findAccountById
                    if(!dataCompany.remainingStorage){
                        const input = {
                            params: {
                                companyId: companyList.company.value,
                                companyType: companyList.company.code,
                                totalStorage: ownerCompany.package?.storage ? ownerCompany.package.storage : totalStorageDefault,
                            }
                        };

                        const result = await window.COMMON.httpRequest({
                            url: process.env.REACT_APP_UPLOAD_URL_API + '/check-storage/company/remaining',
                            method: 'GET',
                            input:{
                                ...input
                            },
                            handleError: () => { window.COMMON.showErrorLogs('UserStoragePage.getDataRole'); }
                        });
                        dataCompany.remainingStorage = result.remainingStorage
                    }
                    setStorageDisplay({
                        total: ownerCompany.package?.storage ? parseFloat(ownerCompany.package.storage).toFixed(1) : totalStorageDefault, 
                        remaining:parseFloat(dataCompany.remainingStorage).toFixed(1)
                    })

                }
                else{
                    setStorageDisplay({total: 0,remaining:0})
                }
            }
        } catch (error) {
            console.log(error);
            window.COMMON.showErrorLogs('UserStoragePage.getDataRole');
        }
    };

    const getDataAccount = async (role) => {
        window.showLoading();
        try {
            if (companyList.company !== null) {
                let input = null
                if(companyList.company.code === "SUPER_ADMIN" || companyList.company.code === "GOPY_INFLUENCER"){
                    input = {
                        params: {
                            accountType: companyList.company.code,
                            pageSize: pagination.pageSize,
                            page: pagination.currentPage + 1
                        }
                    };
                }
                else{
                    input = {
                        params: {
                            organization: companyList.company.value,
                            organizationType: companyList.company.code,
                            pageSize: pagination.pageSize,
                            page: pagination.currentPage + 1
                        }
                    };
                }
                
                
                const result = await window.COMMON.httpRequest({
                    url: process.env.REACT_APP_UPLOAD_URL_API + '/users',
                    method: 'GET',
                    input:{
                        ...input
                    },
                    handleError: () => { window.COMMON.showErrorLogs('UserStoragePage.getDataAccount'); }
                  });
                let data = [];
                if (result && result.data && result.data.data) {
                    const dataRole = role || options.role;
                    data = result.data.data;
                    
                    let adminStorageValue = null
                    if(companyList.company.code === window.CONSTANT.SUPER_ADMIN){
                        const adminStorageParams = {
                            type: ['ADMIN_STORAGE']
                          };
                        const adminStorageResult = await window.COMMON.query(MetadataGRAPHQL.QUERY_METADATA, adminStorageParams);
                        adminStorageValue = adminStorageResult.data.getMetadatas.find((item)=>{
                            return item.code === "admin_storage"
                        })
                    }

                    if(companyList.company.code === window.CONSTANT.GOPY_INFLUENCER){
                        adminStorageValue = {
                            value:  data.reduce((acc, item) => acc + item.package?.storage || 0, 0)
                        }
                    }

                    let usedStorageAdmin = 0
                    //change role to text
                    data.forEach(item => {
                        item.roleText = window.COMMON.getValueFromAttr(window.COMMON.getObjectInArrs(item.accountType, dataRole, 'code'), 'label');
                        if(item.accountType === window.CONSTANT.SUPER_ADMIN){
                            const totalStorage = adminStorageValue?.value ? Number(adminStorageValue.value) * data.length : 2 * data.length 
                            let usedStoragePercent =((item.usedStorage/GB)/totalStorage) * 100
                            if(usedStoragePercent % 1 !== 0){
                                usedStoragePercent = usedStoragePercent.toFixed(2)
                            }
                            item.displayUsedStorage = `${usedStoragePercent} %`
                            const storageRemaining = parseFloat(((totalStorage/data.length) * GB - item.usedStorage)/GB).toFixed(1)
                            item.displayUsedStoragePercent = `${window.COMMON.bytesToSize(item.usedStorage)} / ${storageRemaining} GB`
                            usedStorageAdmin += item.usedStorage
                            
                            setStorageDisplay({
                                total: totalStorage, 
                                remaining: (totalStorage - (usedStorageAdmin/GB)).toFixed(1)
                            })                           
                            item.displayUsedStorage = `${window.COMMON.bytesToSize(item.usedStorage)} / ${usedStoragePercent} %`
                            hasUpdated.current = true
                            return
                        }

                        if(item.accountType === window.CONSTANT.GOPY_INFLUENCER){
                            const totalStorage = adminStorageValue?.value ? Number(adminStorageValue.value) : 0
                            const totalUserStorage = item.package?.storage ? Number(item.package.storage) : 0
                            let usedStoragePercent =((item.usedStorage/GB)/totalUserStorage) * 100
                            if(usedStoragePercent % 1 !== 0){
                                usedStoragePercent = usedStoragePercent.toFixed(2)
                            }
                            item.displayUsedStorage = `${usedStoragePercent} %`
                            const storageRemaining = parseFloat(((totalUserStorage) * GB - item.usedStorage)/GB).toFixed(1)
                            item.displayUsedStoragePercent = `${window.COMMON.bytesToSize(item.usedStorage)} / ${storageRemaining} GB`
                            usedStorageAdmin += item.usedStorage
                            
                            setStorageDisplay({
                                total: totalStorage,
                                remaining: (totalStorage - (usedStorageAdmin/GB)).toFixed(1)
                            })                           
                            item.displayUsedStorage = `${window.COMMON.bytesToSize(item.usedStorage)} / ${usedStoragePercent} %`
                            hasUpdated.current = true
                            return
                        }
                        
                        if(item.accountType === window.CONSTANT.INDIVIDUAL && !item.package){
                            item.displayUsedStorage = '0%'
                            item.displayUsedStoragePercent = `0 Byte / 0 GB`
                            return 
                        }

                        if(item.package && typeof item.package.storage === 'number'){
                            item.package.storage = item.package.storage + " GB"
                        }
                        else{
                            item.package = {storage: item.accountType === window.CONSTANT.INFLUENCER ? "10 GB" : "2 GB"}
                        }
                        
                        item.displayUsedStorage = `${item.usedStoragePercent}`
                        item.displayUsedStoragePercent = `${window.COMMON.bytesToSize(item.usedStorage)} / ${storageDisplay.remaining} GB`
                    });
                    
                    pagination.total = result.data.total;
                }
                setPagination(pagination);
                setData(data);
                if (companyList.company && companyList.company.code === window.CONSTANT.INFLUENCER) {
                    getDataRelation();
                }
            }
        } catch (error) {
            window.COMMON.showErrorLogs('UserStoragePage.getDataAccount');
            console.log(error);
        }
        window.hideLoading();
    };
    const getDataRelation = async () => {
        try {
            if (companyList.company !== null) {
                const params = {
                    user: companyList.company.value,
                    type: window.CONSTANT.INFLUENCER,
                    status: ['ACCEPTED']
                };
                if(companyList.company.code !== "SUPER_ADMIN"){
                    const result = await window.COMMON.query(CompanyGRAPHQL.QUERY_RELATION_COMPANY_BY_ACCOUNT, params);
                    if (result && result.data) {
                        const dataCompany = window.COMMON.getArrsInArrs(window.CONSTANT.COMPANY, result.data.findRelationshipCompanyByAccount, 'company.type');
                        const dataUnion = window.COMMON.getArrsInArrs(window.CONSTANT.UNION, result.data.findRelationshipCompanyByAccount, 'company.type');
                        options.companies = window.COMMON.createDataSelect(dataCompany, 'company._id', 'company.information.name');
                        options.unions = window.COMMON.createDataSelect(dataUnion, 'company._id', 'company.information.name');
                        setOptions(options);
                    } 
                }
            }
        } catch (error) {
            window.COMMON.showErrorLogs('UserStoragePage.getDataRelation');
        }
    };
    useLayoutEffect(() => {
        setTimeout(() => {
            getDataCompany();
        }, 100);
    }, [])

    useEffect(()=>{
        if(!hasUpdated.current){
            getDataAccount(options.role);
        }
    },[storageDisplay])

    return (<>
        <div className="d-flex align-items-center">
            {
                COMPANY_TYPE === window.CONSTANT.COMPANY || COMPANY_TYPE === window.CONSTANT.UNION || COMPANY_TYPE === window.CONSTANT.INFLUENCER ? <div></div> : <div className="bd-highlight mr-2" style={{ width: '20%' }}>
                    <div className="input-group input-group-transparent">
                        <div className="input-group-prepend">
                            <span className="input-group-text">
                                <MDBIcon fa="true" icon="building" className="fa-fw" />
                            </span>
                        </div>
                        <Select className="md-form m-0" placeholder={window.I18N('choose_option')} value={companyList.company} options={options.company} onChange={(event) => handleFilterSelect(event, 'company')} isSearchable />
                    </div>
                </div>
            }
            <div className="d-flex ml-2 align-items-center">
                <div className="mr-2">{window.I18N('total_storage')}:</div>
                <div>{storageDisplay.total} GB</div>
            </div>
            <div className="d-flex ml-2 align-items-center">
                <div className="mr-2">{window.I18N('remaining_storage')}:</div>
                <div>{storageDisplay.remaining} GB</div>
            </div>
        </div>
        <div className="mr-2">
            <TableCustomize id="table-data" className="table" config={config} data={data} handleRowDetailClick={showContainerAccount}></TableCustomize>
            <Pagination total={pagination.total} pageSize={pagination.pageSize} currentPage={pagination.currentPage} changePage={changePage}></Pagination>
        </div>
    </>);
}

export default UserStoragePage;