import React, { useState, useCallback, useLayoutEffect } from 'react';
import { MDBRow, MDBCol, MDBCardImage, MDBInput, MDBBtn, MDBIcon } from 'mdbreact';

import Table from '../../components/Table';
import Modal from '../../components/Modal';
import Tab from '../../components/Tab';
import InputFile from '../../components/InputFile';
import ImportExcel from '../../components/ImportExcel';
import ExportExcel from '../../components/ExportExcel';
import RadioBox from '../../components/RadioBox';

import LanguageGRAPHQL from '../../../graphql/Language';
import { useTracking } from 'react-tracking';
import eventName from '../../../common/events';

export default function LanguagePage(props) {
  const { trackEvent } = useTracking();

  const dataTabs = [
    { icon: 'fa-th', name: window.I18N('languages') },
    { icon: 'fa-th', name: window.I18N('text_languages') }
  ];
  const types = [
    { value: 'MOBILE', label: 'Mobile' },
    { value: 'PC', label: 'Desktop' },
    { value: 'DATA', label: 'Data' }
  ];
  const config = [
    window.COMMON.createCol(window.I18N('no'), '5%', '', '', '', 'INDEX'),
    window.COMMON.createCol(window.I18N('icon'), '40%', 'p-0 pl-1', '', 'icon,name', 'IMAGE_TEXT'),
    window.COMMON.createCol(window.I18N('status'), '10%', '', '', 'status', 'STATUS'),
    window.COMMON.createCol(window.I18N('created_by'), '20%', '', '', 'createdBy.name', 'TEXT'),
    window.COMMON.createCol(window.I18N('created_date'), '10%', '', '', 'createdDate', 'TEXT'),
    window.COMMON.createCol(window.I18N('action'), '10%', '', '', '2', 'BUTTON')
  ];
  const [object, setObject] = useState({ index: -1, radio: 'MOBILE' });
  const [data, setData] = useState([]);
  const [dataSave, setDataSave] = useState({ status: true });
  const [dataText, setDataText] = useState([]);
  const [dataCode, setDataCode] = useState([]);
  const [textConfig, setTextConfig] = useState([]);
  const [dataImport, setDataImport] = useState({});

  useLayoutEffect(() => {
    setTimeout(() => {
      getDataLanguage();
    }, 100);
    // eslint-disable-next-line
  }, []);

  const createTrackingEvent = (event) => {
    return trackEvent({
      name: event,
      createdAt: new Date().toISOString()
    });
  };

  const changeTab = (index) => {
    if (index === 0) {
      setTimeout(() => getDataLanguage(), 500);
    } else {
      setTimeout(() => getDataText(), 500);
    }
  };

  const handleChange = (event) => {
    if (event.target.className.indexOf('input-number') > -1) {
      dataSave[event.target.name] = parseInt(event.target.value);
    } else if (event.target.type === 'checkbox') {
      dataSave[event.target.name] = event.target.checked;
    } else {
      dataSave[event.target.name] = event.target.value;
    }
    setDataSave({ ...dataSave });
  };

  const handleRadio = useCallback((value) => {
    object.radio = value;
    setObject({ ...object });
    getDataText();
    // eslint-disable-next-line
  }, [object]);

  const handleFile = (file) => {
    dataSave.file = file;
    dataSave.icon = window.COMMON.createBlobImage(file);
    setDataSave({ ...dataSave });
  };

  const handleFileImport = (dataImport) => {
    checkImportData(dataImport);
    window.COMMON.showModal('#modal-import');
  };

  // LANGUAGE
  const getDataLanguage = async () => {
    try {
      window.resetDataTable('table-language');
      const params = {
        status: [true, false]
      };
      const result = await window.COMMON.query(LanguageGRAPHQL.QUERY_LANGUAGE, params);
      if (result && result.data) {
        const data = window.COMMON.formatDate(result.data.getLanguages, 'createdDate');
        const headers = [];
        const codes = [];
        const dataActive = window.COMMON.getArrsInArrs(true, data, 'status');
        headers.push(window.COMMON.createCol(window.I18N('no'), '5%', '', '', '', 'INDEX'));
        headers.push(window.COMMON.createCol('Key', '10%', '', '', 'key', 'TEXT'));
        for (let i = 0; i < dataActive.length; i++) {
          const item = dataActive[i];
          codes.push(item.code);
          headers.push(window.COMMON.createCol(item.code, (75 / dataActive.length) + '%', '', '', 'language.' + i + '.text', 'TEXT'));
        }
        headers.push(window.COMMON.createCol(window.I18N('action'), '15%', '', '', '3', 'BUTTON'));
        setDataCode(codes);
        setTextConfig(headers);
        setData(data);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('LanguagePage.getDataLanguage');
    }
    window.initDataTable('table-language');
  };

  const openLanguageDialog = useCallback((index) => {
    window.COMMON.showModal('#modal-language');
    object.index = index;
    const dataSave = { file: null };
    if (index === -1) {
      dataSave.name = '';
      dataSave.code = '';
      dataSave.status = true;
      dataSave.icon = '';
    } else {
      const obj = data[index];
      dataSave._id = window.COMMON.getValueFromAttr(obj, '_id', null);
      dataSave.name = window.COMMON.getValueFromAttr(obj, 'name');
      dataSave.code = window.COMMON.getValueFromAttr(obj, 'code');
      dataSave.status = window.COMMON.getValueFromAttr(obj, 'status');
      dataSave.icon = window.COMMON.getValueFromAttr(obj, 'icon');
      dataSave.file = null;      
    }
    document.getElementById('file-language').value = null;
    setObject(object);
    setDataSave({ ...dataSave });
  }, [object, data]);

  const saveLanguage = async (event) => {
    let dataFiles = [];
    try {
      if (!window.COMMON.checkFormValidation('form-language', event)) {
        return;
      }
      // TODO upload file
      if (dataSave.file) {
        dataFiles = await window.uploadFile([dataSave.file]);
        if (dataFiles.length > 0) {
          dataSave.icon = window.COMMON.getNewPathFile(dataFiles[0]._id);
        }
      }
      dataSave.file = null;
      // TODO save data
      const params = {
        input: dataSave
      };
      window.COMMON.trimData(params);
      if (object.index === -1) {
        const result = await window.COMMON.mutation(LanguageGRAPHQL.MUTATION_ADD_LANGUAGE, params);
        if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_INSERT)) {
          getDataLanguage();
		      createTrackingEvent(eventName.ADD_LANGUAGE);
        }
      } else {
        const result = await window.COMMON.mutation(LanguageGRAPHQL.MUTATION_EDIT_LANGUAGE, params);
        if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_UPDATE)) {
          getDataLanguage();
		      createTrackingEvent(eventName.EDIT_LANGUAGE);
        }
      }
      window.COMMON.hideModal('#modal-language');
    } catch (error) {
      console.error("🚀 ~ saveLanguage ~ error:", error)
      window.UPLOAD_API.deleteMultiFile(dataFiles.map(item => item._id))
      window.COMMON.showErrorLogs('LanguagePage.saveLanguage');
    }
  };

  // TEXT LANGUAGE
  const getDataText = async () => {
    try {
      window.resetDataTable('table-text');
      const params = {
        type: object.radio
      };
      const result = await window.COMMON.query(LanguageGRAPHQL.QUERY_TEXT_LANGUAGE, params);
      if (result && result.data) {
        const dataTextLanguages = result.data.getTextLanguages.map((item) => {
          const filterLanguages = item.language.filter(lang => dataCode.includes(lang.code))
          return {
            ...item,
            language: filterLanguages
          }
        })
        setDataText(dataTextLanguages);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('LanguagePage.getDataText');
    }
    window.initDataTable('table-text');
  };

  const openTextDialog = useCallback((index) => {
    window.COMMON.showModal('#modal-text');
    object.index = index;
    const dataSave = { file: null };
    if (index === -1) {
      dataSave.key = '';
      dataCode.forEach(item => {
        dataSave[item] = '';
      });
    } else {
      const obj = dataText[index];
      dataSave.key = obj.key;
      dataCode.forEach(item => {
        const language = window.COMMON.getObjectInArrs(item, obj.language, 'code');
        dataSave[item] = window.COMMON.getValueFromAttr(language, 'text');
      });
    }
    setObject(object);
    setDataSave({ ...dataSave });
  }, [object, dataCode, dataText]);

  // eslint-disable-next-line
  const openDeleteTextDialog = useCallback((index) => {
    object.index = index;
    setObject(object);
    window.COMMON.showModal('#modal-delete');
    window.deleteMethod = deleteText;
  });

  const saveText = async (event) => {
    try {
      if (!window.COMMON.checkFormValidation('form-text', event)) {
        return;
      }
      const params = {
        input: {
          key: dataSave.key,
          type: object.radio,
          language: []
        }
      };
      dataCode.forEach(item => {
        params.input.language.push({
          code: item,
          text: dataSave[item]
        });
      });
      window.COMMON.trimData(params);
      if (object.index === -1) {
        const result = await window.COMMON.mutation(LanguageGRAPHQL.MUTATION_ADD_TEXT_LANGUAGE, params);
        if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_INSERT)) {
          getDataText();
		      createTrackingEvent(eventName.ADD_TEXT_LANGUAGE);
        }
      } else {
        params.input._id = dataText[object.index]._id;
        const result = await window.COMMON.mutation(LanguageGRAPHQL.MUTATION_EDIT_TEXT_LANGUAGE, params);
        if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_UPDATE)) {
          getDataText();
		      createTrackingEvent(eventName.EDIT_TEXT_LANGUAGE);
        }
      }
      window.COMMON.hideModal('#modal-text');
    } catch (error) {
      window.COMMON.showErrorLogs('LanguagePage.saveText');
    }
  };

  const deleteText = async () => {
    try {
      const params = {
        id: dataText[object.index]._id
      };
      const result = await window.COMMON.mutation(LanguageGRAPHQL.MUTATION_DEL_TEXT_LANGUAGE, params);
      if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_DELETE)) {
        getDataText();
        createTrackingEvent(eventName.DEL_TEXT_LANGUAGE);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('LanguagePage.deleteText');
    }
  };

  // IMPORT
  // eslint-disable-next-line
  const deleteImportRow = useCallback((index) => {
    if (dataImport.data.length > 0) {
      dataImport.data.splice(index, 1);
    }
    setDataImport({ ...dataImport });
  });

  const checkImportData = async (dataImport) => {
    try {
      if (dataImport.data.length === 0) {
        return;
      }
      const data = [];
      dataImport.data.forEach(item => {
        data.push(item.key);
      });
      const params = {
        input: data
      };
      window.COMMON.trimData(params);
      const result = await window.COMMON.mutation(LanguageGRAPHQL.MUTATION_CHECK_IMPORT_TEXT_LANGUAGE, params);
      if (result.data && result.data.checkImportTextLanguage) {
        for (let i = 0; i < result.data.checkImportTextLanguage.length; i++) {
          const arrs = result.data.checkImportTextLanguage[i].split('-.-');
          if (Number(arrs[1]) === 1) {
            const obj = window.COMMON.getObjectInArrs(arrs[0], dataImport.data, 'key');
            obj.isExisted = true;
          }
        }
      }
      setDataImport(dataImport);
	    createTrackingEvent(eventName.CHECK_IMPORT_TEXT_LANGUAGE);
    } catch (error) {
      window.COMMON.showErrorLogs('LanguagePage.checkImportData');
    }
  };
  
  const importData = async () => {
    if (dataImport.data.length === 0) {
      window.COMMON.showMessage('warning', 'MSG_CODE_021', window.MSG.MSG_CODE_021);
      return;
    }
    try {
      const params = {
        input: []
      };
      dataImport.data.forEach(item => {
        const obj = {
          key: item.key,
          type: object.radio,
          language: []
        };
        dataCode.forEach(code => {
          obj.language.push({
            code,
            text: window.COMMON.getValueFromAttr(item, code)
          });
        });
        params.input.push(obj);
      });
      window.COMMON.trimData(params);
      const result = await window.COMMON.mutation(LanguageGRAPHQL.MUTATION_IMPORT_TEXT_LANGUAGE, params);
      if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_IMPORT)) {
        getDataText();
        createTrackingEvent(eventName.IMPORT_TEXT_LANGUAGE);
      }
      window.COMMON.hideModal('#modal-import');
    } catch (error) {
      window.COMMON.showErrorLogs('LanguagePage.importData');
    }
  };

  if (typeof window === 'undefined') {
    return null;
  }
  return (
    <div className="container-row">
      <Tab data={dataTabs} id="container-data" changeTab={changeTab}>
        <div id="tab-1" className="tab-pane fade active show">
          <div className="container-btn mb-2 wow fadeInDown animated" data-wow-delay="0.5s">
            <MDBBtn color="primary" onClick={() => openLanguageDialog(-1)}>
              <MDBIcon fa="true" icon="plus-circle" className="fa-fw" /> {window.I18N('btn_add')}
            </MDBBtn>
          </div>
          <div className="container-table wow fadeIn animated" data-wow-delay="1s">
            <Table id="table-language" className="table" config={config} data={data} handleRowEditClick={openLanguageDialog}></Table>
          </div>
        </div>
        <div id="tab-2" className="tab-pane">
          <div className="container-btn mb-2 wow fadeInDown animated" data-wow-delay="0.5s">
            <div className="d-inline-block mr-2">
              <RadioBox data={types} value={object.radio} className="m-0" handleChange={handleRadio}></RadioBox>
            </div>
            <ExportExcel label={window.I18N('btn_template')} isTemplate={true} dataKey="LANGUAGE_TEMPLATE"></ExportExcel>
            <ImportExcel label={window.I18N('btn_import')} id="file-import" dataKey="IMPORT_LANGUAGE" attrs={dataCode} handleFile={handleFileImport}></ImportExcel>
            <MDBBtn color="primary" onClick={() => openTextDialog(-1)}>
              <MDBIcon fa="true" icon="plus-circle" className="fa-fw" /> {window.I18N('btn_add')}
            </MDBBtn>
          </div>
          <div className="container-table wow fadeIn animated" data-wow-delay="1s">
            <Table id="table-text" className="table" config={textConfig} data={dataText} handleRowEditClick={openTextDialog} handleRowDelClick={openDeleteTextDialog}></Table>
          </div>
        </div>
      </Tab>

      <Modal id="modal-language" title={window.I18N('create_update_data')} saveEvent={saveLanguage}>
        <form id="form-language" className="needs-validation" noValidate>
          <MDBRow>
            <MDBCol>
              <MDBInput outline containerClass="mt-0" type="text" label={window.I18N('name') + ' *'} name="name" value={dataSave.name} onChange={handleChange} maxLength="100" pattern="\S(.*\S)?" required>
                <div className="invalid-feedback">{window.I18N('MSG_CODE_029')}</div>
              </MDBInput>
              <MDBInput outline type="text" label={window.I18N('code') + ' *'} name="code" value={dataSave.code} onChange={handleChange} maxLength="50" pattern="\S(.*\S)?" required>
                <div className="invalid-feedback">{window.I18N('MSG_CODE_029')}</div>
              </MDBInput>
              <MDBInput outline type="checkbox" id="ck-status-language" name="status" label={window.I18N('btn_active')} filled containerClass="p-2 mb-3" checked={dataSave.status} onChange={handleChange}></MDBInput>
              <div className="text-center">
                <MDBCardImage className="input-image-icon" src={window.COMMON.setValueImage(dataSave.icon)} hover overlay="white-slight"/><br></br>
                <InputFile id="file-language" icon="upload" handleFile={handleFile} accept="image/*" description="Icon (64 x 64) pixels"></InputFile>
              </div>
            </MDBCol>
          </MDBRow>
        </form>
      </Modal>

      <Modal id="modal-text" title={window.I18N('create_update_data')} saveEvent={saveText}>
        <form id="form-text" className="needs-validation" noValidate>
          <MDBRow>
            <MDBCol>
              <MDBInput outline containerClass="mt-0 mb-0" type="text" label={window.I18N('answer') + ' *'} name="key" value={dataSave.key} onChange={handleChange} maxLength="100" pattern="\S(.*\S)?" required>
                <div className="invalid-feedback">{window.I18N('MSG_CODE_029')}</div>
              </MDBInput>
              {dataCode.map((item, i) => (
                <MDBInput outline containerClass="mt-3 mb-0" type="text" label={item + ' *'} name={item} key={i} value={dataSave[item]} onChange={handleChange} maxLength="200" pattern="\S(.*\S)?" required>
                  <div className="invalid-feedback">{window.I18N('MSG_CODE_029')}</div>
                </MDBInput>
              ))}
            </MDBCol>
          </MDBRow>
        </form>
      </Modal>

      <Modal id="modal-import" className="modal-xl" title={window.I18N('import_data')} saveEvent={importData}>
        <Table id="table-import" className="table" config={dataImport.config} data={dataImport.data} handleRowDelClick={deleteImportRow} isImported={true}></Table>
      </Modal>
    </div>
  );
}