import { MDBBtn, MDBIcon } from 'mdbreact';
import React, { useState, useEffect, useLayoutEffect, useRef, useImperativeHandle } from 'react';
import { SurveyPagePaperStyled, StepButtonsContainer } from '../../../styles/styled-components/Survey/NewSurveyPageStyled';
import NewSurveyPageSteps from './NewSurveyPageSteps';
import SurveyForm from './SurveyForm';
import { connect } from 'react-redux';
import QuestionGRAPHQL from '../../../graphql/Question';
import RoleGRAPHQL from '../../../graphql/Role';
import MetadataGRAPHQL from '../../../graphql/Metadata';
import GroupChatGRAPHQL from '../../../graphql/GroupChat';
import SoftKillQuestionForm from './GroupQuestionsTemplate/SoftKillQuestionForm';
import QuestionAndAnswerForm from './QuestionAndAnswerForm';
import SurveySchedule from './SurveySchedule';
import { useHistory, useLocation } from 'react-router-dom';
import useUnSaveChangesWarning from '../../../hooks/useUnSaveChangesWarning';
import Common from '../../../utils/Common';
import { groupTypes } from './GroupQuestionsTemplate/SelectGroupsType';
const group_qa_step_name = 'group_qa';
const question_answer_step_name = 'question_answer';
const survey_schedule_step_name = 'survey_schedule';

const NewSurveyPage = React.forwardRef((props, ref) => {
  const STEPS = [
    {
      id: 'step0',
      name: group_qa_step_name,
      status: 'processing'
    },
    {
      id: 'step1',
      name: question_answer_step_name,
      status: 'waiting'
    },
    {
      id: 'step2',
      name: survey_schedule_step_name,
      status: 'waiting'
    }
  ];
  const COMPANY_ID = localStorage.getItem(window.CONSTANT.COMPANY_ID);
  const COMPANY_TYPE = localStorage.getItem(window.CONSTANT.COMPANY_TYPE);
  const [steps, setSteps] = useState(STEPS);
  const [surveyFormFilter, setSurveyFormFilter] = useState({ company: null, radio: 0, text: '' });
  const [surveyFormOptions, setSurveyFormOptions] = useState({
    companies: []

  });

  const [currentStep, setCurrentStep] = useState({
    index: 0,
    isFirstStep: true,
    isLastStep: false
  });
  const [surveyFormData, setSurveyFormData] = useState({});
  const [surveyScheduleFormData, setSurveyScheduleFormData] = useState(null);

  const [options, setOptions] = useState({
    company: [],
    companies: [],
    groupChats: [],
    group: [],
    role: [],
    companyIndustry: [],
    age: [],
    income: [],
    educationBackground: [],
    occupation: []
  });
  const [filter, setFilter] = useState({ company: null, radio: 0, text: '' });
  const [isCompletedGetDraftData, setIsCompletedGetDraftData] = useState(false);
  const [groupQAToCheck, setGroupQAToCheck] = useState({});
  const [only_create_mode] = useState(props.mode === window.CONSTANT.ONLY_CREATE ? true : false);

  //save questions data
  const [dataSaveQuestion, setDataSaveQuestion] = useState([]);
  const [cloneData, setCloneData] = useState(null);

  const surveyFormRef = useRef();
  const questionAndAnswerFormRef = useRef();
  const surveyScheduleFormRef = useRef();
  const dataStateRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const [companiesFullData, setCompaniesFulldata] = useState([]);
  const [questionsType, setQuestionsType] = useState(groupTypes[0].value);

  useImperativeHandle(ref, () => {
    if (only_create_mode === true) {
      return {
        validSurveyForm: () => {
          const validResult = surveyFormRef.current.handleValidForm();
          return validResult;
        },
        validQuestionAndAnswerForm: () => {
          const validResult = questionAndAnswerFormRef.current.handleValidForm();
          return validResult;
        },
        createQA: submitData,
        getDraftCode,
        saveDraft: handleSaveDraft
      };
    }
    return {};

  }, []);

  dataStateRef.current = {
    surveyFormData
  };
  useEffect(() => {
    setSurveyFormData(prev => ({
      ...prev,
      type: questionsType === 'DEFAULT' ? 1 : 2
    }));
  },[questionsType]);
  useEffect(() => {
    setQuestionsType(
      surveyFormData.type === 2 ? groupTypes[1].value : groupTypes[0].value
    )
  },[surveyFormData.type]);
  const getDraftCode = () => {
    return dataStateRef.current.surveyFormData.draftcode;
  };
  const handleSwitchQuestionsType = () => {
    const newType = questionsType === groupTypes[0].value ? groupTypes[1].value : groupTypes[0].value;
    setQuestionsType(newType);
    setDataSaveQuestion([]);
  };
  const handleSaveDraftBeforeUnLoad = () => {
    localStorage.setItem(window.CONSTANT.SURVEY_DRAFT_CODE, dataStateRef.current.surveyFormData.draftcode);
    handleSaveDraft();
  };

  const handleSaveDraftBeforeRedirect = () => {
    localStorage.removeItem(window.CONSTANT.SURVEY_DRAFT_CODE);
    handleSaveDraft();
  };

  //save data to draft data when user intents to redirect to another page
  const handleSaveDraft = () => {


    const currentSurveyFormData = dataStateRef.current.surveyFormData;


    let currentQuestionAndAnswerFormData;
    if (questionAndAnswerFormRef.current) {
      currentQuestionAndAnswerFormData = questionAndAnswerFormRef.current.getQuestionAndAnswerFormData();
    }

    let currentSurveyScheduleFormData;
    if (surveyScheduleFormRef.current) {
      currentSurveyScheduleFormData = surveyScheduleFormRef?.current.getSurveyScheduleFormData();

    }




    //lấy dữ liệu từ local dựa theo draft code
    const draftcode = currentSurveyFormData.draftcode;

    //data from local is a String
    const draftSurveysString = localStorage.getItem(window.CONSTANT.DRAFT_SURVEYS);

    let draftSurveys = draftSurveysString ? JSON.parse(draftSurveysString) : [];
    const surveyIndex = draftSurveys.findIndex(item => item.draftcode === draftcode);

    let currentDraftSurvey = { draftcode };
    if (surveyIndex >= 0) {
      currentDraftSurvey = {
        ...currentDraftSurvey,
        ...draftSurveys[surveyIndex]
      };
    }
    currentDraftSurvey = {
      ...currentDraftSurvey,
      draftcode,
      surveyFormData: currentSurveyFormData,
      questionAndAnswerFormData: currentQuestionAndAnswerFormData,
      surveyScheduleFormData: currentSurveyScheduleFormData
    };

    if (surveyIndex >= 0) {
      draftSurveys[surveyIndex] = currentDraftSurvey;
    }
    else {
      draftSurveys = [
        currentDraftSurvey,
        ...draftSurveys
      ];
    }

    localStorage.setItem(window.CONSTANT.DRAFT_SURVEYS, JSON.stringify(draftSurveys));

  };

  const [WarningPrompt, setUsePrompt, setCancelUsePrompt, setCancelPromptlBeforeSubmit] = useUnSaveChangesWarning({
    message: window.I18N('MSG_CODE_078'),
    defaultIsUsePrompt: true,
    callbackWhenRedirect: only_create_mode === false ? handleSaveDraftBeforeRedirect : () => {
      if (props.onRedirect) {
        props.onRedirect();
      }
    },
    callbackWhenReload: only_create_mode === false ? handleSaveDraftBeforeUnLoad : () => {
      if (props.onReload) {
        props.onReload();
      }
    },
    currentPath: location.pathname,
    isActive: only_create_mode === true ? false : true
    //test
    // isActive:false,

  });


  useLayoutEffect(() => {

    //if don't have state (state will contain company value)
    //then back to question page

    // let draftcode = localStorage.getItem(window.CONSTANT.SURVEY_DRAFT_CODE);
    // if (!location.state && !draftcode) {
    //   history.replace('/question');
    // }

    setTimeout(() => {
      getDataMetadata();
      getDataGroupChat();
      getDataCompany();
    }, 100);
  }, []);


  useEffect(() => {


    getDraftData();



  }, []);

  useEffect(() => {
    getDataCompany();
  }, [surveyFormData._id]);

  useEffect(() => {
    getDataRole();
  }, [surveyFormData.company]);

  useEffect(() => {
    setSteps(() => {
      if (surveyFormData.isPublic) {
        return STEPS.slice(0, 2);
      }

      return STEPS;
    });
  }, [surveyFormData.isPublic]);

  useEffect(() => {
    if (only_create_mode === true) {

      setSteps((current) => {
        const newCurrent = current.filter(item => item.name !== 'survey_schedule');
        return [
          ...newCurrent
        ];
      });
    }
  }, [only_create_mode]);

  useEffect(() => {
    if (only_create_mode === true && props.initCompany) {
      setSurveyFormData((current) => {
        current.company = props.initCompany.value;
        return { ...current };
      });
    }
  }, [props.initCompany]);





  const getDraftData = async () => {
    let draftcode;

    if (only_create_mode === true) {
      draftcode = props.surveyPageDraftCode;
    }
    else {
      draftcode = localStorage.getItem(window.CONSTANT.SURVEY_DRAFT_CODE);
      localStorage.removeItem(window.CONSTANT.SURVEY_DRAFT_CODE);
    }

    if (draftcode) {
      const draftSurveys = window.COMMON.getLocalJSONData({ key: window.CONSTANT.DRAFT_SURVEYS, defaultValue: [] });
      const draftSurvey = draftSurveys.find(item => item.draftcode === draftcode);
      if (draftSurvey) {
        const draftSurveyFormData = draftSurvey.surveyFormData;
        //if has fileBinary then load blob and set to file attr
        if (draftSurveyFormData.fileBinary) {
          try {
            const url = draftSurveyFormData.fileBinary;
            const fetchRes = await fetch(url);
            const blobRes = await fetchRes.blob();
            draftSurveyFormData.file = blobRes;
            draftSurveyFormData.image = window.COMMON.createBlobImage(blobRes);


          }
          catch (e) {
            console.log(e);
            delete draftSurveyFormData['file'];
            delete draftSurveyFormData['image'];
          }

        }
        setSurveyFormData((current) => {
          return {
            ...current,
            ...draftSurvey.surveyFormData
          };
        });
        setDataSaveQuestion((current) => {
          const draftQuestionAndAnswerFormData = draftSurvey.questionAndAnswerFormData;
          if (draftQuestionAndAnswerFormData) {
            return [...draftQuestionAndAnswerFormData];
          }
          return current;
        });
        setSurveyScheduleFormData((current) => {
          const draftSurveyScheduleFormData = draftSurvey.surveyScheduleFormData;
          if (draftSurveyScheduleFormData) {
            return { ...draftSurveyScheduleFormData };
          }
          return current;
        });

      }
      else {
        initData();
      }

    }
    else {
      initData();
    }
    setIsCompletedGetDraftData(true);
  };

  const initData = () => {
    let company = null;
    if (location.state) {
      company = location.state.company || null;
    }
    if (only_create_mode === true && props.initCompany) {
      company = props.initCompany.value;

    }
    const cloneDataString = localStorage.getItem(window.CONSTANT.GROUP_QA_CLONE);
    let dataToInit = {
      name: [],
      image: '',
      description: [],
      hashtag: [],
      type: 1
    };
    let cloneData = {};

    if (cloneDataString) {
      cloneData = JSON.parse(cloneDataString);
      dataToInit = {
        ...dataToInit,
        ...cloneData
      };
      setCloneData(cloneData);
    }
    //345 in question page
    surveyFormData._id = null;
    surveyFormData.name = dataToInit.name;
    surveyFormData.code = window.COMMON.generateCode('GQ');
    surveyFormData.draftcode = window.COMMON.generateCode('DR');
    surveyFormData.image = dataToInit.image;
    surveyFormData.description = dataToInit.description;
    surveyFormData.hashtag = dataToInit.hashtag;
    surveyFormData.company = company;
    surveyFormData.type = dataToInit.type;
    surveyFormData.createdDate = window.COMMON_DATE.formatStringToDate(new Date());
    surveyFormData.isShared = false;
    //Add standard of conclusion for survey data
    surveyFormData.standardOfConclusion = {
        min: window.CONSTANT.MIN_STANDARD_OF_CATEGORY,
        max: window.CONSTANT.MAX_STANDARD_OF_CATEGORY
    }
    surveyFormData.finalConclusion = {
      low: [{
        text: window.I18N('low'),
        code: props.language.code
      }],
      medium: [{
        text: window.I18N('medium'),
        code: props.language.code
      }],
      high: [{
        text: window.I18N('high'),
        code: props.language.code
      }]
    };
    setSurveyFormData((current) => {
      return {
        ...current,
        ...surveyFormData
      };
    });
    localStorage.removeItem(window.CONSTANT.GROUP_QA_CLONE);
  };

  const getDataMetadata = async () => {
    try {
      const params = {
        type: ['COMPANY_INDUSTRY', 'INDIVIDUAL_AGE', 'INDIVIDUAL_OCCUPATION', 'INDIVIDUAL_EDUCATION_BACKGROUND', 'INDIVIDUAL_INCOME']
      };
      const result = await window.COMMON.query(MetadataGRAPHQL.QUERY_SHORT_METADATA, params, false);
      if (result && result.data) {
        const dataIndustry = window.COMMON.getArrsInArrs('COMPANY_INDUSTRY', result.data.getMetadatas, 'type');
        options.companyIndustry = window.COMMON.createDataSelect(dataIndustry, '_id', 'value');
        options.companyIndustry.unshift({ value: null, label: window.I18N('all_industries') });
        const dataAge = window.COMMON.getArrsInArrs('INDIVIDUAL_AGE', result.data.getMetadatas, 'type');
        options.age = window.COMMON.createDataSelect(dataAge, '_id', 'value');
        options.age.unshift({ value: null, label: window.I18N('all_age') });
        const dataOccupation = window.COMMON.getArrsInArrs('INDIVIDUAL_OCCUPATION', result.data.getMetadatas, 'type');
        options.occupation = window.COMMON.createDataSelect(dataOccupation, '_id', 'value');
        options.occupation.unshift({ value: null, label: window.I18N('all_occupation') });
        const dataEducationBackground = window.COMMON.getArrsInArrs('INDIVIDUAL_EDUCATION_BACKGROUND', result.data.getMetadatas, 'type');
        options.educationBackground = window.COMMON.createDataSelect(dataEducationBackground, '_id', 'value');
        options.educationBackground.unshift({ value: null, label: window.I18N('all_education_background') });
        const dataIncome = window.COMMON.getArrsInArrs('INDIVIDUAL_INCOME', result.data.getMetadatas, 'type');
        options.income = window.COMMON.createDataSelect(dataIncome, '_id', 'value');
        options.income.unshift({ value: null, label: window.I18N('all_income') });
        setOptions(options);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('NewSurveyPage.getDataMetadata');
    }
  };


  // ROLE
  const getDataRole = async () => {
    try {
      if (surveyFormData !== null && Object.keys(surveyFormData).length > 0) {
        const params = {
          company: surveyFormData.company
        };
        const result = await window.COMMON.query(RoleGRAPHQL.QUERY_ROLE, params);
        if (result && result.data) {
          const data = result.data.getRoles;
          if (COMPANY_TYPE === window.CONSTANT.INFLUENCER) {
            for (let i = 0; i < data.length; i++) {
              const element = data[i];
              if (element.code === window.CONSTANT.INFLUENCER) {
                data.splice(i, 1);
                break;
              }
            }
          }
          options.role = window.COMMON.createDataSelect(data, 'code', 'name');
          if (COMPANY_TYPE === window.CONSTANT.INFLUENCER) {
            options.role.push({ value: window.CONSTANT.COMPANY, label: window.I18N('company') });
            options.role.push({ value: window.CONSTANT.UNION, label: window.I18N('union') });
            setOptions(options);
          }
          options.role.push({ value: window.CONSTANT.INDIVIDUAL, label: window.I18N('individual') });
          setOptions((current) => {
            return {
              ...current,
              ...options
            };
          });
        }
      }
    } catch (error) {
      window.COMMON.showErrorLogs('NewSurveyPage.getDataRole');
    }
  };


  const getDataCompany = async () => {
    try {

      if (!window.COMMON.checkRoleIsSystem()) {
        filter.company = { value: COMPANY_ID, label: '' };
        setFilter({ ...filter });
        if (COMPANY_TYPE === window.CONSTANT.INFLUENCER) {
          await window.COMMON.getDataCompany();
          const dataCompany = JSON.parse(localStorage.getItem(window.CONSTANT.DATA_COMPANY));
          if (dataCompany && dataCompany.length > 0) {
            options.companies = window.COMMON.createDataSelectWithGroup(dataCompany, 'id', 'name', 'accountType', 'accountType');
          }
          setCompaniesFulldata(dataCompany)
          setOptions(options);
        }
        // getDataRole();

        return;
      }


      await window.COMMON.getDataAllCompany();
      const dataCompany = JSON.parse(localStorage.getItem(window.CONSTANT.DATA_ALL_COMPANY));


      if (dataCompany && dataCompany.length > 0) {

        surveyFormFilter.company = window.COMMON.createDataSelect(dataCompany, '_id', 'information.name');

        surveyFormOptions.companies = window.COMMON.createDataSelectWithGroup(dataCompany, '_id', 'information.name', 'type', 'type');
        options.company = window.COMMON.createDataSelectWithGroup(dataCompany, '_id', 'information.name', 'type', 'type');
        options.companies = JSON.parse(JSON.stringify(options.company));
      }

      if (window.COMMON.checkRoleIsSystem()) {
        options.company.unshift({ value: null, label: 'My group questions' });
        filter.company = window.COMMON.getValueFromAttr(options.company, '0', null);
      } else {
        filter.company = window.COMMON.getValueFromAttr(options.company, '0.options.0', null);
      }
      setCompaniesFulldata(dataCompany)
      setOptions(options);
      setSurveyFormOptions(surveyFormOptions);
      setSurveyFormFilter({ ...surveyFormFilter });
      setFilter({ ...filter });
      // getDataRole();

    } catch (error) {

      window.COMMON.showErrorLogs('NewSurveyPage.getDataCompany');
    }
  };

  // GROUP CHAT
  const getDataGroupChat = async () => {
    try {
      const params = {
        type: '-1',
        organization: !window.COMMON.checkRoleIsSystem() ? COMPANY_ID : null
      };
      const result = await window.COMMON.query(GroupChatGRAPHQL.QUERY_GROUP_CHAT, params);
      if (result && result.data) {
        options.groupChats = window.COMMON.createDataSelect(result.data.getGroupChats, '_id', 'name');
        setOptions(options);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('NewSurveyPage.getDataGroupChat');
    }
  };

  //change step component state
  const handleChangeStep = (indexClick) => {
    setSteps((current) => {
      const newSteps = current.map((item, index) => {
        let status = 'completed';
        if (indexClick === index) {
          status = 'processing';
        }
        else if (indexClick < index) {
          status = 'waiting';
        }
        else if (indexClick > index) {
          status = 'completed';
        }
        return {
          ...item,
          status
        };
      });
      return [
        ...newSteps
      ];
    });
    setCurrentStep({
      index: indexClick,
      isFirstStep: indexClick === 0,
      isLastStep: indexClick === steps.length - 1
    });
  };

  //when user want to move to a new step
  const handleStepClick = async (indexClick) => {
    if (currentStep.index === 0 && indexClick === 2) {
      handleChangeStep(1)
      return
    }
    if (indexClick === currentStep.index) {
      return;
    }
    //when next click > current click
    if (indexClick > currentStep.index) {
      //handle form
      await handleFormBeforeClick(indexClick);
      return;
    }


    handleChangeStep(indexClick);

  };
  //indexClick: index to move
  const handleFormBeforeClick = async (indexClick) => {
    let validResult = true;
    if (currentStep.index === 0) {
      window.COMMON.trimData(surveyFormData);
      setSurveyFormData({ ...surveyFormData })
      validResult = surveyFormRef.current.handleValidForm();
    }
    else if (currentStep.index === 1) {
      window.COMMON.trimData(dataSaveQuestion);
      setDataSaveQuestion([...dataSaveQuestion])
      validResult = questionAndAnswerFormRef.current.handleValidForm();
    }

    if (validResult === true) {
      handleChangeStep(indexClick);
    }
  };

  const saveGroupQuestion = async () => {
    let dataFiles = []
    try {
      const newSurveyFormData = {
        ...dataStateRef.current.surveyFormData,
        type: questionsType === 'DEFAULT' ? 1 : 2
      };

      if (newSurveyFormData.type === 1) {
        delete newSurveyFormData.standardOfConclusion;
        delete newSurveyFormData.finalConclusion;
      }

      const draftcode = newSurveyFormData['draftcode'];
      delete newSurveyFormData['draftcode'];
      delete newSurveyFormData['createdDate'];
      delete newSurveyFormData['fileBinary'];
      newSurveyFormData.company = dataStateRef.current.surveyFormData.company;
      const params = {
        input: newSurveyFormData
      };

      // Upload image to /gdrive API
      if (newSurveyFormData.file) {
        dataFiles = await window.uploadFile([newSurveyFormData.file]);
        if (dataFiles?.length > 0) {
          newSurveyFormData.image = Common.getNewPathFile(dataFiles[0]._id);
        }
        newSurveyFormData.file = null;
      }

      window.COMMON.trimData(params);
      const createResult = await window.COMMON.mutation(QuestionGRAPHQL.MUTATION_ADD_GROUP_QUESTION, params);
      if (createResult && createResult.data) {
        const addGroupQuestionResult = createResult.data.addGroupQuestion;

        if (addGroupQuestionResult === 1) {
          //next
          // await updateGroupQuestionAfterCreateOrUpdate();
          window.COMMON.removeDraftSurvey({ key: window.CONSTANT.DRAFT_SURVEYS, draftcode });
          return true;

        }
      }

    } catch (error) {
      console.error("🚀 ~ saveGroupQuestion ~ error:", error)
      window.UPLOAD_API.deleteMultiFile(dataFiles.map(item => item._id))
      window.COMMON.showErrorLogs('NewSurveyPage.saveGroupQuestion');
    }
    return false;
  };

  // const updateGroupQuestion = async () => {
  //   try {
  //     const params = {
  //       input: surveyFormData
  //     };
  //     window.COMMON.trimData(params);
  //     const updateResult = await window.COMMON.mutation(QuestionGRAPHQL.MUTATION_EDIT_GROUP_QUESTION, params);
  //     if (updateResult && updateResult.data) {
  //       // let updateGroupQuestionResult = updateResult.data.addGroupQuestion;
  //       await updateGroupQuestionAfterCreateOrUpdate();

  //       // if (addGroupQuestionResult === 1) {
  //       //     //next

  //       //     let newGroupQA = await getNewGroupQA();
  //       //     setSurveyFormData((current) => {

  //       //         return {
  //       //             ...current,
  //       //             ...newGroupQA,
  //       //             //data from api return company is null (??)
  //       //             company: surveyFormData.company,
  //       //         }
  //       //     })
  //       // }
  //     }


  //   } catch (error) {
  //     console.log(error);
  //     window.COMMON.showErrorLogs('NewSurveyPage.saveGroupQuestion');
  //   }
  // };


  const getNewGroupQA = async () => {
    try {
      const newSurveyFormData = { ...dataStateRef.current.surveyFormData };
      //find group question was newly created
      const getQAResult = await window.COMMON.query(QuestionGRAPHQL.QUERY_ALL_GROUP_QUESTION);
      if (getQAResult && getQAResult.data) {
        const getAllGroupQuestions = getQAResult.data.getAllGroupQuestions;
        const newGroupQA = getAllGroupQuestions.find((item) => item.code === newSurveyFormData.code);
        newGroupQA.company = dataStateRef.current.surveyFormData.company;
        if (only_create_mode === false) {
          const keysToRemove = [
            '__typename', 'countSchedule', 'createdDate', 'createdBy'
          ];
          keysToRemove.forEach(key => {
            delete newGroupQA[key];
          });
        }

        newGroupQA.name.forEach((item) => {
          delete item['__typename'];
        });
        if (newGroupQA.description) {
          newGroupQA.description.forEach((item) => {
            delete item['__typename'];
          });
        }

        return newGroupQA;
      }
      return null;
    }
    catch (error) {
      console.log(error);
      window.COMMON.showErrorLogs('NewSurveyPage.getNewGroupQA');
    }
    return null;
  };

  const confirmSave = () => {
    if (!surveyFormData.isPublic) {
      const validResult = surveyScheduleFormRef.current.handleValidForm();
      if (validResult === false) {
        return;
      }
    } else {
      const validResult = questionAndAnswerFormRef.current.handleValidForm();
      if (validResult === false) {
        return
      }
    }

    window.COMMON.showModal('#modal-save');
    window.saveMethod = handleSave;
  };

  const submitData = async () => {
    //create groupQA
    await saveGroupQuestion();
    const newGroupQA = await getNewGroupQA();
    if (!newGroupQA) return;

    //create all data in question and answer form
    await questionAndAnswerFormRef.current.handleSaveForm(newGroupQA);

    if (only_create_mode === true) {
      return newGroupQA;
    }
    //create survey schedule
    let finalResult = false;
    if (newGroupQA.isPublic) {
      finalResult = true;
    } else {
      finalResult = await surveyScheduleFormRef.current.handleSaveForm(newGroupQA);
    }
    if(!finalResult) return

    if (finalResult === true) {
      //save id of company after create
      //purpose to question page select company to show
      localStorage.setItem(window.CONSTANT.COMPANY_BACK, surveyFormData.company);
      history.push('/question');
    }
  };

  //final step
  const handleSave = async () => {
    setCancelPromptlBeforeSubmit(submitData);
  };

  //when survey form data change
  const handleSurveyFormChange = (data) => {
    setSurveyFormData({ ...data });
    //save in local

  };

  const cancelForm = () => {
    window.COMMON.showModal('#modal-delete');
    window.deleteMethod = () => {
      props.onCancel({
        draftcode: dataStateRef.current.surveyFormData.draftcode,
        removeStep: true
      });
    };
  };

  return <>
    <div className='container-row'>
      {only_create_mode === false && <NewSurveyPageSteps steps={steps} onStepClick={handleStepClick} />}
      <SurveyPagePaperStyled>
        {only_create_mode === true && <div className='d-flex justify-content-end w-100'>
          <MDBBtn color="primary" onClick={cancelForm}>
            {window.I18N('btn_cancel')}
          </MDBBtn>
        </div>}
        <div style={{ display: (only_create_mode === false ? currentStep.index === 0 : props.stepName === group_qa_step_name) ? 'block' : 'none' }}>
          <SurveyForm
            {...props}
            dataSave={surveyFormData}
            setDataSave={handleSurveyFormChange}
            options={surveyFormOptions}
            companyOptionsNotGroup={surveyFormFilter.company}
            ref={surveyFormRef}
            mode={props.mode}
            questionsType={questionsType}
            onChangeQuestionsType={handleSwitchQuestionsType}
          />
        </div>
        <div style={{ display: (only_create_mode === false ? currentStep.index === 1 : props.stepName === question_answer_step_name) ? 'block' : 'none' }}>
          {
            questionsType === groupTypes[0].value ? (
              <QuestionAndAnswerForm
                {...props}
                ref={questionAndAnswerFormRef}
                groupQA={surveyFormData}
                groupQACloneData={cloneData}
                dataSaveQuestion={dataSaveQuestion || []}
                setDataSaveQuestion={setDataSaveQuestion}
              />
            ) : (
              <SoftKillQuestionForm
                {...props}
                ref={questionAndAnswerFormRef}
                groupQA={surveyFormData}
                groupQACloneData={cloneData}
                dataSaveGroupQuestion={dataSaveQuestion || []}
                setDataSaveGroupQuestion={setDataSaveQuestion}
              />
            )
          }
        </div>
        {!surveyFormData.isPublic && only_create_mode === false && (
          <div style={{ display: currentStep.index === 2 ? 'block' : 'none' }}>
            <SurveySchedule
              {...props}
              ref={surveyScheduleFormRef}
              options={options}
              filter={filter}
              index={-1}
              groupQA={surveyFormData}
              surveyScheduleFormData={surveyScheduleFormData}
              isCompletedGetDraftData={isCompletedGetDraftData}
              companiesFullData={companiesFullData}
            />
          </div>
        )}
      </SurveyPagePaperStyled>


      {only_create_mode === false && <StepButtonsContainer isFull={currentStep.index === 1 ? false : true}>

        <div className='d-flex align-items-center justify-content-end w-100 mt-3'>
          {currentStep.isFirstStep === false && <MDBBtn color='' className='btn-back' onClick={() => handleStepClick(currentStep.index - 1)}>
            <MDBIcon fa="true" icon="arrow-left" className="fa-fw" /> {window.I18N('btn_back')}
          </MDBBtn>}
          {currentStep.isLastStep === false && <MDBBtn color="primary" className="mr-0" type="button" onClick={() => handleStepClick(currentStep.index + 1)}>
            {window.I18N('btn_next')} <i className="fas fa-arrow-right"></i>
          </MDBBtn>}
          {currentStep.isLastStep === true && <MDBBtn color="primary" className="mr-0" type="button" onClick={confirmSave}>
            <MDBIcon fa="true" icon="save" className="fa-fw" /> {window.I18N('btn_save')}
          </MDBBtn>}
        </div>

      </StepButtonsContainer>
      }

    </div>
    {only_create_mode === false && WarningPrompt}
  </>;
});

const mapStateToProps = state => ({
  language: state.language.value
});
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(NewSurveyPage);