import React, { useState, useLayoutEffect, useCallback, useRef } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { NavLink } from 'react-router-dom';
import { MDBRow, MDBCol, MDBInputGroup, MDBIcon, MDBBtn } from 'mdbreact';
import Rating from 'react-rating';
import { useHistory } from 'react-router-dom';

import RadioBox from '../../components/RadioBox';
import Filter from '../../components/Filter';
import TableNetwork from '../../components/TableNetwork';
import Pagination from '../../components/Pagination';

import MetadataGRAPHQL from '../../../graphql/Metadata';
import AccountGRAPHQL from '../../../graphql/Account';
import RelationshipGRAPHQL from '../../../graphql/Relationship';
import CaseAssessmentGRAPHQL from '../../../graphql/CaseAssessment';
import { useChatDataProvider } from '../../../context/ChatDataContext';
import AttributeCompany from '../../components/AttributeCompany';
import SelfAssessmentGRAPHQL from '../../../graphql/SelfAssessment';
import Modal from '../../components/Modal';

const MAX_TEXT_LENGTH = 500

function FindNetworkPage(props) {
  const ACCOUNT_TYPE = localStorage.getItem(window.CONSTANT.ACCOUNT_TYPE);
  const typesUI = [
    { value: 1, label: window.I18N('organizational_chart') },
    { value: 0, label: window.I18N('find_network') },
  ];
  const types = [
    { value: window.CONSTANT.COMPANY, label: window.I18N('company') },
    { value: window.CONSTANT.INFLUENCER, label: window.I18N('influencer') },
    { value: window.CONSTANT.EMPLOYEE, label: window.I18N('employee') },
  ];
  const defaultProps = {
    center: {
      lat: 10.7751764,
      lng: 106.677763
    },
    zoom: 12
  };
  const language = useRef();
  const map = useRef();
  const markers = useRef();
  const infoWindow = useRef();

  const [indexAccordion, setIndexAccordion] = useState(0);
  const [resultData, setResultData] = useState({users:[], relationships:[], isRelationshipsError:false});  
  const [filter, setFilter] = useState({ typeUI: ACCOUNT_TYPE === window.CONSTANT.INFLUENCER ? 1 : 0, accountType: 'COMPANY', text: '', star: 0 });
  const [data, setData] = useState([]);
  const [dataTotal, setDataTotal] = useState([]);
  const [select, setSelect] = useState({});
  const [options, setOptions] = useState({
    nationFilter: [],
    stateFilter: [],
    cityFilter: []
  });
  const [dataAttributeReport, setDataAttributeReport] = useState([])
  const [dataAttributeReportCompany, setDataAttributeReportCompany] = useState({});
  const [object, setObject] = useState({ row: -1, col: -1, item: null, title: '', id: null, checkId: null, isSubmited: false, company: null, companyId: null, idAttribute: null });
  
  const [pagination, setPagination] = useState({ total: 0, pageSize: 10, currentPage: 0 });
  const {setSelectedUserToChat} = useChatDataProvider();
  const history = useHistory();

  useLayoutEffect(() => {
    if (!props.language.code) {
      return;
    }
    language.current = props.language.code;
    setTimeout(() => {
      getDataAttributeReport()
      getDataMetadata();
      getDataSubMetadataGlobal();
      getDataLocationOptions('-1', '-1');
      initMap();
    }, 100);
    // eslint-disable-next-line
  }, [props.language]);

    // ATTRIBUTE REPORT
    const getDataAttributeReport = async () => {
      try {
        const result = await window.COMMON.query(SelfAssessmentGRAPHQL.QUERY_ATTRIBUTE_REPORT, null);
        if (result !=null) {
          let index = 0;
          result.data.getAttributeReports.forEach(item => {
            item.idx = index;
            index++;
          });
          setDataAttributeReport(result.data.getAttributeReports);
        }
      } catch (error) {
        window.COMMON.showErrorLogs('MySelfAssessmentPage.getDataAttributeReport');
      }
    };

      // ATTRIBUTE REPORT COMPANY
  const getDataAttributeReportCompany = async ({ companyId }) => {
    try {
      const params = { company: companyId };
      const result = await window.COMMON.query(SelfAssessmentGRAPHQL.QUERY_ATTRIBUTE_REPORT_COMPANY, params);
      if (result && result.data && result.data.findAttributeReportCompany) {
        const data = result.data.findAttributeReportCompany;
        object.idAttribute = data._id;
        setDataAttributeReportCompany(data);
      } else {
        object.idAttribute = null;
        setDataAttributeReportCompany({});
      }
      setObject((prev) => ({...prev, idAttribute: object.idAttribute}));
    } catch (error) {
      console.error("🚀 ~ getDataAttributeReportCompany ~ error:", error)
      window.COMMON.showErrorLogs('MySelfAssessmentPage.getDataAttributeReportCompany');
    }
  };

  const showAttributeReportCompany = ({item, company, companyId }) => {
    setObject((prev) => ({...prev, item, company, companyId }) )
    getDataAttributeReportCompany({ companyId })
    window.COMMON.showModal('#modal-companying');
  };

  const showAttributeReportCompanyForTableNetwork = (item) => {
    showAttributeReportCompany({ item, company: item, companyId: item?.company?._id })
  }

  const closeAttributeCompany = () => {
    window.COMMON.backContainerData();
    //close view facility info, check is view ItemQA or not 
    if (object.row > -1 && object.col > -1) {
      window.COMMON.showContainerSave();
    }
  };

  window.infoWindowClick = useCallback( (mode, id) => {
      infoWindow.current.close();
      const user = window.COMMON.getObjectInArrs(id, data, '_id')
      if (mode === 0) {
        doAddFriend(user);
      } else if (mode === 1) {
        doSendMessage(user);
      } else {
        showAttributeReportCompany({ item: user, company: user, companyId: user.company._id })
      }
  }, [data, resultData, object])

  const initMap = () => {
    const google = window.google;
    if (google) {
      map.current = new google.maps.Map(document.getElementById('container-map'), defaultProps);
      infoWindow.current = new google.maps.InfoWindow();
      google.maps.event.addListener(map.current, 'click', () => {
        infoWindow.current.close();
      });
    }
  };

  const clearMarkers = () => {
    if (!markers.current || markers.current.length === 0) {
      markers.current = [];
      return;
    }
    for (let i = 0; i < markers.current.length; i++ ) {
      markers.current[i].setMap(null);
    }
    markers.current = [];
  };

  const addMarker = (item) => {
    const lat = item.lat;
    const lng = item.lng;
    if (!lat || !lng) {
      return;
    }
    const marker = new window.google.maps.Marker({
      label: {
        fontSize: '10pt',
        color: '#302E90',
        fontWeight: '900',
        text: item.name
      },
      position: new window.google.maps.LatLng(lat, lng),
      map: map.current
    });

    window.google.maps.event.addListener(marker, 'click', ((marker, item) => {
      return genWindowInfo(marker, item);
    })(marker, item));
    window.google.maps.event.addListener(infoWindow.current,'domready',function() { 
      styleInfoWindow();
    });
    markers.current.push(marker);
  };
  
  function styleInfoWindow() { 
    const containerInfo = document.getElementById('container-info-div');
    containerInfo.style.maxHeight = `calc(${containerInfo.parentElement.parentElement.style.maxHeight} - ${50}px)`;
    containerInfo.style.paddingBottom = "15px"
  }

  const checkNoFacilities = (item) => {
    const accountCompaniesManagedByInfluencer = window.COMMON.parseObject(localStorage.getItem(window.CONSTANT.ACCOUNT_COMPANIES))  
    return !accountCompaniesManagedByInfluencer.includes(item?.company?._id)
  }

  const genWindowInfo = (marker, item) => {
    const type = item.accountType.toLowerCase();
    const star = window.COMMON.getValueFromAttr(item, type + '.averageRating', 0);
    const evalution = window.COMMON.getValueFromAttr(item, type + '.lastConclusion', '');
    let contentString = '<div id="container-info-div" class="container-info-window">' +
      '<h6>' + item.name + '</h6>' +
      '<div class="mb-1 d-flex">' +
        `<div style="width:33%">${window.I18N('user_review')}:</div>`+
        '<div><strong class="mr-1" style="color:' + window.COMMON.checkStar(star) + '">' + star + '</strong>' +
        '<i class="fa fa-fw fa-star mr-3"></i>';
    if (star > 0) {
      contentString += '<span class="badge p-1" style="background:' + window.COMMON.checkStar(star) + '">' + window.COMMON.checkTextStar(star) + ' </span>';
    }
    contentString += '</div></div>'
    contentString += `<div class="mb-3 d-flex"><div style="width:33%">${window.I18N('compliance_level')}:</div>`;
    if (evalution) {
      contentString += '<div class="badge p-1" style="background:' + window.COMMON.checkEvalution(evalution) + '">' + window.I18N(evalution) + ' </div>';
      contentString += `<span class="ml-2 my-auto text-muted" style="font-size:0.75rem">(${window.COMMON.getValueFromAttr(item, type + '.lastConclusionDate', 0)})</span>`
    }
    contentString += '</div>' +
      '<table class="table-infowindow">' +
        '<tbody>';

    let friendButton = '<div class="col"><button type="button" class="btn btn-sm btn-primary w-100 m-0" onclick="infoWindowClick(0, \'' + item._id + '\')">' + window.I18N('add_connection') + '</button></div>';
    let facilityButton = '<div class="col mt-3"><button type="button" class="btn btn-sm btn-primary w-100 m-0" onclick="infoWindowClick(2, \'' + item._id + '\')">' + window.I18N('facility_info') + '</button></div>'
    if (item.friendStatus !== '') {
      friendButton = '';
    }
    if(checkNoFacilities(item)){
      facilityButton = ''
    }
    if (filter.accountType === window.CONSTANT.COMPANY) {
      contentString += '<tr>' +
            '<td width={30}>' + window.I18N('industry') + ': </td>' +
            '<td width={70}>' + window.COMMON.getValueFromAttr(item, 'companyIndustry.value', '') + '</td>' +
          '</tr>' +
          '<tr>' +
            '<td>' + window.I18N('main_industry') + ': </td>' +
            '<td><ul class="m-0 pl-3">' + window.COMMON.getStringArrsWithLanguage(item, 'mainIndustries', 'value', language.current) + '</ul></td>' +
          '</tr>' +
          '<tr>' +
            '<td>' + window.I18N('main_product') + ': </td>' +
            '<td><ul class="m-0 pl-3">' + window.COMMON.getStringArrsWithLanguage(item, 'mainProducts', 'value', language.current) + '</ul></td>' +
          '</tr>' +
          '<tr>' +
            '<td>' + window.I18N('customers') + ': </td>' +
            '<td><ul class="m-0 pl-3">' + window.COMMON.getStringArrsWithLanguage(item, 'mainCustomers', 'value', language.current) + '</ul></td>' +
          '</tr>' +
          '<tr>' +
            '<td>' + window.I18N('market') + ': </td>' +
            '<td><ul class="m-0 pl-3">' + window.COMMON.getStringArrsWithLanguage(item, 'markets', 'value', language.current) + '</ul></td>' +
          '</tr>' +
          '<tr>' +
            '<td>' + window.I18N('headcounts') + ': </td>' +
            '<td>' + window.COMMON.getValueFromAttr(item, 'companySize.value', '') + '</td>' +
          '</tr>';
    }
    
    contentString += '<tr>' +
            '<td width={30}>' + window.I18N('address') + ': </td>' +
            '<td width={70}>' + window.COMMON.getValueFromAttr(item, 'addressText', '') + '</td>' +
          '</tr>' +
          '<tr>' +
            '<td>' + window.I18N('tax_id') + ': </td>' +
            '<td>' + window.COMMON.getValueFromAttr(item, 'taxCode', '') + '</td>' +
          '</tr>' +
          '<tr>' +
            '<td>' + window.I18N('website') + ': </td>' +
            '<td>' + window.COMMON.getValueFromAttr(item, 'website', '') + '</td>' +
          '</tr>' +
          '<tr>' +
            '<td>' + window.I18N('phone_number') + ': </td>' +
            '<td>' + window.COMMON.getValueFromAttr(item, 'phone', '') + '</td>' +
          '</tr>' +
          '<tr>' +
          '<td>' + window.I18N('work_email_address') + ': </td>' +
            '<td>' + window.COMMON.getValueFromAttr(item, 'email', '') + '</td>' +
            '</tr>' +
          '<tr>' +
        '</tbody>' +
      '</table>' +
      '<div class="d-flex mt-3" style="flex-wrap: wrap">' +
        friendButton +
        '<div class="col"><button type="button" class="btn btn-sm btn-primary w-100 m-0" onclick="infoWindowClick(1, \'' + item._id + '\')">' + window.I18N('send_message') + '</button></div>' +
      '</div>' +
        facilityButton +
    '</div>';
    return () => {
      infoWindow.current.setContent(contentString);
      infoWindow.current.open(map.current, marker);
    };
  };

  const handleRadio = useCallback((attr, value) => {
    filter[attr] = value;
    setFilter({ ...filter });
    if (attr === 'typeUI') {
      if (value === 0) {
        setTimeout(() => initMap(), 100)
        setTimeout(() => doSearchData(), 500)
      } else {
        setTimeout(() => getOrganizationChart(), 500)
      }
    }
  }, [filter]);

  const handleFilterChange = (event) => {
    const text = event.target.value
    if(text.startsWith(' ') || text.length >= MAX_TEXT_LENGTH){
      return
    }
    setFilter((prev) => ({ ...prev, text }));
  };

  const handleFilterStar = (value) => {
    filter.star = value;
    setFilter({ ...filter });
  };

  const handleSelect = (event, attr) => {
    select[attr] = event;
    if (attr === 'nation' || attr === 'state') {
      getDataLocationOptions(attr, event.code);
    } else if (attr === 'companyIndustry') {
      getDataSubMetadata(options, select);
    }
    setSelect({ ...select });
  };

  const changePage = useCallback((index) => {
    pagination.currentPage = index;
    setPagination({ ...pagination });
    doSearchData();
    // eslint-disable-next-line
  }, [pagination, filter]);

  const getDataLocationOptions = async (attr, parent) => {
    if (attr === '-1' && parent === '-1') {
      options.nation = await window.COMMON.getDataLocation(parent);
      options.nation.unshift({ value: null, label: window.I18N('all_countries') });
      options.state = [{ value: null, label: window.I18N('all_states') }];
      options.city = [{ value: null, label: window.I18N('all_cities') }];
      select.nation = options.nation[0];
      select.state = options.state[0];
      select.city = options.city[0];
    } else if (attr === 'nation') {
      if (parent === '-1' || !parent) {
        options.state = [];
      } else {
        options.state = await window.COMMON.getDataLocation(parent);
      }
      options.state.unshift({ value: null, label: window.I18N('all_states') });
      options.city = [{ value: null, label: window.I18N('all_cities') }];
      select.state = options.state[0];
      select.city = options.city[0];
    } else if (attr === 'state') {
      if (parent === '-1' || !parent) {
        options.city = [];
      } else {
        options.city = await window.COMMON.getDataLocation(parent);
      }
      options.city.unshift({ value: null, label: window.I18N('all_cities') });
      select.city = options.city[0];
    }
    setOptions(options);
    setSelect({ ...select });
  };

  const getDataMetadata = async () => {
    try {
      const params = {
        type: ['COMPANY_INDUSTRY', 'COMPANY_TYPE', 'COMPANY_SIZE', 'INDIVIDUAL_OCCUPATION', 'INDIVIDUAL_EDUCATION_BACKGROUND']
      };
      const result = await window.COMMON.query(MetadataGRAPHQL.QUERY_SHORT_METADATA, params);
      if (result && result.data) {
        const data = result.data.getMetadatas;
        const dataType = window.COMMON.getArrsInArrs('COMPANY_TYPE', data, 'type');
        const dataSize = window.COMMON.getArrsInArrs('COMPANY_SIZE', data, 'type');
        const dataIndustry = window.COMMON.getArrsInArrs('COMPANY_INDUSTRY', data, 'type');
        const dataOccupation = window.COMMON.getArrsInArrs('INDIVIDUAL_OCCUPATION', data, 'type');
        const dataEducationBackground = window.COMMON.getArrsInArrs('INDIVIDUAL_EDUCATION_BACKGROUND', data, 'type');
        options.companyType = window.COMMON.createDataSelect(dataType, '_id', 'value');
        options.companyType.unshift({ value: null, label: window.I18N('all_company_types') });
        options.companySize = window.COMMON.createDataSelect(dataSize, '_id', 'value');
        options.companySize.unshift({ value: null, label: window.I18N('all_headcounts') });
        options.companyIndustry = window.COMMON.createDataSelect(dataIndustry, '_id', 'value');
        options.occupation = window.COMMON.createDataSelect(dataOccupation, '_id', 'value');
        options.occupation.unshift({ value: null, label: window.I18N('all_occupation') });
        options.educationBackground = window.COMMON.createDataSelect(dataEducationBackground, '_id', 'value');
        options.educationBackground.unshift({ value: null, label: window.I18N('all_education_background') });

        select.companyType = options.companyType && options.companyType.length > 0 ? options.companyType[0] : null;
        select.companySize = options.companySize && options.companySize.length > 0 ? options.companySize[0] : null;
        select.companyIndustry = options.companyIndustry && options.companyIndustry.length > 0 ? options.companyIndustry[0] : null;
        select.occupation = options.occupation && options.occupation.length > 0 ? options.occupation[0] : null;
        select.educationBackground = options.educationBackground && options.educationBackground.length > 0 ? options.educationBackground[0] : null;
        getDataSubMetadata(options, select);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('FindNetworkPage.getDataMetadata');
    }
  };

  const getDataSubMetadata = async (options, select) => {
    try {
      if (!select.companyIndustry || !select.companyIndustry.value) {
        options.companyMainIndustry = [{ value: null, label: window.I18N('all_main_industry') }];
        options.companyMainProduct = [{ value: null, label: window.I18N('all_main_product') }];
        select.mainIndustry = options.companyMainIndustry[0];
        select.mainProduct = options.companyMainProduct[0];
        setOptions(options);
        setSelect({ ...select });
        return;
      }
      const params = {
        type: ['COMPANY_MAIN_INDUSTRY', 'COMPANY_MAIN_PRODUCT'],
        parent: select.companyIndustry && select.companyIndustry.value
      };
      const result = await window.COMMON.query(MetadataGRAPHQL.QUERY_SHORT_SUB_METADATA, params);
      if (result && result.data) {
        const data = result.data.getSubMetadatas;
        data.forEach(item => {
          item.valueLanguage = window.COMMON.getValueWithLanguage(item, 'value', language.current);
        });
        const dataCompanyMainIndustry = window.COMMON.getArrsInArrs('COMPANY_MAIN_INDUSTRY', data, 'type');
        const dataCompanyMainProduct = window.COMMON.getArrsInArrs('COMPANY_MAIN_PRODUCT', data, 'type');
        options.companyMainIndustry = window.COMMON.createDataSelect(dataCompanyMainIndustry, '_id', 'valueLanguage');
        options.companyMainIndustry.unshift({ value: null, label: window.I18N('all_main_industry') });
        options.companyMainProduct = window.COMMON.createDataSelect(dataCompanyMainProduct, '_id', 'valueLanguage');
        options.companyMainProduct.unshift({ value: null, label: window.I18N('all_main_product') });
        select.mainIndustry = options.companyMainIndustry[0];
        select.mainProduct = options.companyMainProduct[0];
        setOptions(options);
        setSelect({ ...select });
      }
    } catch (error) {
      window.COMMON.showErrorLogs('FindNetworkPage.getDataSubMetadata');
    }
  };

  const getDataSubMetadataGlobal = async () => {
    try {
      const params = {
        type: ['COMPANY_MARKERT', 'COMPANY_CUSTOMER'],
        parent: null
      };
      const result = await window.COMMON.query(MetadataGRAPHQL.QUERY_SHORT_SUB_METADATA, params, false);
      if (result && result.data) {
        const data = result.data.getSubMetadatas;
        data.forEach(item => {
          item.valueLanguage = window.COMMON.getValueWithLanguage(item, 'value', language.current);
        });
        const dataCompanyMarket = window.COMMON.getArrsInArrs('COMPANY_MARKERT', data, 'type');
        const dataCompanyCustomer = window.COMMON.getArrsInArrs('COMPANY_CUSTOMER', data, 'type');
        options.companyMarket = window.COMMON.createDataSelect(dataCompanyMarket, '_id', 'valueLanguage');
        options.companyMarket.unshift({ value: null, label: window.I18N('all_market') });
        options.companyCustomer = window.COMMON.createDataSelect(dataCompanyCustomer, '_id', 'valueLanguage');
        options.companyCustomer.unshift({ value: null, label: window.I18N('all_customers') });
        select.market = options.companyMarket[0];
        select.mainCustomer = options.companyCustomer[0];
        setOptions(options);
        setSelect({ ...select });
      }
    } catch (error) {
      window.COMMON.showErrorLogs('FindNetworkPage.getDataSubMetadataGlobal');
    }
    if (filter.typeUI === 0) {
      setTimeout(() => doSearchData(), 1000)
    } else {
      setTimeout(() => getOrganizationChart(), 1000)
    }
  };

  const resetData = () => {
    getDataLocationOptions('-1', '-1');
    select.companyType = options.companyType && options.companyType.length > 0 ? options.companyType[0] : null;
    select.companySize = options.companySize && options.companySize.length > 0 ? options.companySize[0] : null;
    select.companyIndustry = options.companyIndustry && options.companyIndustry.length > 0 ? options.companyIndustry[0] : null;
    select.mainIndustry = options.companyMainIndustry[0];
    select.mainProduct = options.companyMainProduct[0];
    select.market = options.companyMarket[0];
    select.mainCustomer = options.companyCustomer[0];
    select.occupation = options.occupation && options.occupation.length > 0 ? options.occupation[0] : null;
    select.educationBackground = options.educationBackground && options.educationBackground.length > 0 ? options.educationBackground[0] : null;
    filter.star = 0;
    setSelect(select);
    setFilter({ ...filter });
  };

  const doSearchDataWithFilter = () => {
    pagination.currentPage = 0;
    setPagination({ ...pagination });
    window.COMMON.hideFilter();
    doSearchData();
  };

  const doSearchData = async () => {
    try {
      const params = {
        input: {
          text: filter.text.trim(),
          accountType: filter.accountType,
          rating: filter.star,
          nation: window.COMMON.getDataSelect(select.nation, false),
          state: window.COMMON.getDataSelect(select.state, false),
          city: window.COMMON.getDataSelect(select.city, false),
          pageSize: pagination.pageSize,
          page: pagination.currentPage + 1
        }
      };
      if (filter.accountType === window.CONSTANT.COMPANY) {
        params.input.companySize = window.COMMON.getDataSelect(select.companySize, false);
        params.input.companyIndustry = window.COMMON.getDataSelect(select.companyIndustry, false);
        params.input.companyType = window.COMMON.getDataSelect(select.companyType, false);
        params.input.mainIndustry = window.COMMON.getDataSelect(select.mainIndustry, false);
        params.input.mainProduct = window.COMMON.getDataSelect(select.mainProduct, false);
        params.input.market = window.COMMON.getDataSelect(select.market, false);
        params.input.mainCustomer = window.COMMON.getDataSelect(select.mainCustomer, false);
      } else if (filter.accountType === window.CONSTANT.EMPLOYEE) {
        params.input.occupation = window.COMMON.getDataSelect(select.occupation, false);
        params.input.educationBackground = window.COMMON.getDataSelect(select.educationBackground, false);
      }
      clearMarkers();
      let result = null;
      if (filter.accountType !== window.CONSTANT.EMPLOYEE) {
        result = await window.COMMON.query(AccountGRAPHQL.QUERY_ORGANIZATION_NETWORK, params);
      } else {
        result = await window.COMMON.query(AccountGRAPHQL.QUERY_EMPLOYEE_NETWORK, params);
      }
      let data = [];
      if (result && result.data) {
        const arrs = filter.accountType === window.CONSTANT.EMPLOYEE ? result.data.findEmployeeNetwork : result.data.findAccountNetwork;
        pagination.total = arrs.total;
        const dataAccounts = arrs && arrs.data;
        data = window.COMMON.formatMultiDate(dataAccounts, 'createdDate,establishedDate,birthday');
        data.forEach(element => {
          let address = window.COMMON.getValueFromAttr(element, 'address', '');
          const nation = window.COMMON.getValueFromAttr(element, 'nation.name', '');
          const state = window.COMMON.getValueFromAttr(element, 'state.name', '');
          const city = window.COMMON.getValueFromAttr(element, 'city.name', '');
          address = address + (city ? ', ' + city : '') + (state ? ', ' + state : '') + (nation ? ', ' + nation : '');
          element.addressText = address;
        });
      }

      // find last conclusion of companies
      if (filter.accountType === window.CONSTANT.EMPLOYEE) {
        const companyIds = data.map(el=>el.company?._id)
        const resultData = await window.COMMON.query(CaseAssessmentGRAPHQL.QUERY_LAST_CONCLUSION, {companyIds: companyIds});
        if (resultData?.data?.getLastConclusionOfCompanies?.length){
          const formatLastConclusion = window.COMMON.formatDate(resultData.data.getLastConclusionOfCompanies, "createdDate")
          formatLastConclusion.forEach(companyLastConclusion => {
            const indexOfCompany = data.findIndex(el => el.company._id=== companyLastConclusion.companyId)
            if (indexOfCompany!==-1){
              data[indexOfCompany].company.lastConclusion = companyLastConclusion.evalution
              data[indexOfCompany].company.lastConclusionDate = companyLastConclusion.createdDate
            }
          })
        }
        resultData.users = [...data];
      }
      setPagination(pagination);
      setData(data);
      if (data.length > 0) {
        data.forEach(element => {
          addMarker(element);
        });
      }
      setResultData({...resultData});
    } catch (error) {
      console.error("🚀 ~ doSearchData ~ error:", error)
      window.COMMON.showErrorLogs('FindNetworkPage.doSearchData');
    }
  };

  const doAddFriend = async (user) => {
    try {
      const currentUser = window.COMMON.getCurrentUserInformation();
      const result = await window.COMMON.mutation(RelationshipGRAPHQL.MUTATION_ADD_FRIEND, {
        input: {
          id: user._id,
          name: (currentUser.nickname || currentUser.name),
        }
      });
      let status = -1;
      if (result && result.data) {
        status = result.data.addFriend;
      }
      if (status !== 1) return;
      doSearchData()
      window.COMMON.showMessage('success', 'MSG_CODE_053', window.I18N('MSG_CODE_053') + ' ' + user.nickname);
    }
    catch (error) {
      window.COMMON.showErrorLogs('FindNetworkPage.doAddFriend');
    }
  };

  const doSendMessage = (user) => {
    if (user) {
      setSelectedUserToChat(user);
      history.push('/chat');
    }
  };

  // Organization Chart
  const getOrganizationChart = async () => {
    try {
      const params = {
        input: {
          nation: window.COMMON.getDataSelect(select.nation, false),
          state: window.COMMON.getDataSelect(select.state, false),
        }
      };
      const result = await window.COMMON.query(AccountGRAPHQL.QUERY_COUNT_TOTAL_COMPANY_BY_INDUSTRY, params);
      let data = [];
      if (result && result.data) {
        data = result.data.countTotalCompanyByIndustry;
        data.forEach(element => {
          const obj = window.COMMON.getObjectInArrs(element._id, options.companyIndustry, 'value');
          element.name = obj ? obj.label : '';
        });
      }
      setDataTotal(data);
    } catch (error) {
      window.COMMON.showErrorLogs('FindNetworkPage.getOrganizationChart');
    }
  };

  if (typeof window === 'undefined') {
    return null;
  }
  return (
    <div className="container-row">
      <div className="card mb-3  wow fadeInRight animated text-left" data-wow-delay="0.5s" style={{ zIndex: 1000 }}>
        <div className="card-body">
          <div className="d-flex justify-content-between align-items-center">
            {
              ACCOUNT_TYPE === window.CONSTANT.INFLUENCER ? <div className="mr-auto">
                <RadioBox className="mb-0" data={typesUI} value={filter.typeUI} handleChange={(value) => handleRadio('typeUI', value)}></RadioBox>
              </div> : <div className="mr-auto"></div>
            }
            {
              filter.typeUI === 0 ? <>
                <MDBInputGroup type="text" containerClassName="w-30 mr-3 mb-0" value={filter.text} hint={window.I18N('search_name')} onChange={handleFilterChange} maxLength="100" pattern="\S(.*\S)?" required
                  append={
                    <span className="input-group-text cursor-pointer" onClick={() => doSearchData()}>
                      <MDBIcon fa="true" icon="search" className="fa-fw"></MDBIcon>
                    </span>
                  }
                />
                <Filter id="container-filter" className="container-filter-outside" btnClassName="m-0">
                  <MDBRow>
                    <MDBCol xs="12" sm="12" lg="12">
                      <RadioBox className="mb-3" data={types} value={filter.accountType} handleChange={(value) => handleRadio('accountType', value)}></RadioBox>
                    </MDBCol>
                    <MDBCol xs="12" sm="6" lg="4">
                      <strong>{window.I18N('country')}</strong>
                      <div className="input-group input-group-transparent mt-2 mb-3">
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            <MDBIcon fa="true" icon="filter" className="fa-fw" />
                          </span>
                        </div>
                        <Select value={select.nation} options={options.nation} className="md-form m-0" placeholder={window.I18N('choose_option')} onChange={(event) => handleSelect(event, 'nation')} isSearchable />
                      </div>       
                      {
                        filter.accountType !== window.CONSTANT.COMPANY ? <></> : <>
                          <strong>{window.I18N('organization_type')}</strong>
                          <div className="input-group input-group-transparent mt-2 mb-3">
                            <div className="input-group-prepend">
                              <span className="input-group-text">
                                <MDBIcon fa="true" icon="filter" className="fa-fw" />
                              </span>
                            </div>
                            <Select value={select.companyType} options={options.companyType} className="md-form m-0" placeholder={window.I18N('choose_option')} onChange={(event) => handleSelect(event, 'companyType')} isSearchable />
                          </div>
                          <strong>{window.I18N('main_industry')}</strong>
                          <div className="input-group input-group-transparent mt-2 mb-3">
                            <div className="input-group-prepend">
                              <span className="input-group-text">
                                <MDBIcon fa="true" icon="filter" className="fa-fw" />
                              </span>
                            </div>
                            <Select value={select.mainIndustry} options={options.companyMainIndustry} placeholder={window.I18N('main_industry')} className="md-form m-0" onChange={(event) => handleSelect(event, 'mainIndustry')} isSearchable/>
                          </div>
                          <strong>{window.I18N('market')}</strong>
                          <div className="input-group input-group-transparent mt-2 mb-3">
                            <div className="input-group-prepend">
                              <span className="input-group-text">
                                <MDBIcon fa="true" icon="filter" className="fa-fw" />
                              </span>
                            </div>
                            <Select value={select.market} options={options.companyMarket} placeholder={window.I18N('market')} className="md-form m-0" onChange={(event) => handleSelect(event, 'market')} isSearchable/>
                          </div>
                        </>
                      }
                      {
                        filter.accountType === window.CONSTANT.EMPLOYEE ? <>
                          <strong>{window.I18N('occupation')}</strong>
                          <div className="input-group input-group-transparent mt-2 mb-3">
                            <div className="input-group-prepend">
                              <span className="input-group-text">
                                <MDBIcon fa="true" icon="filter" className="fa-fw" />
                              </span>
                            </div>
                            <Select value={select.occupation} options={options.occupation} placeholder={window.I18N('occupation')} className="md-form m-0" onChange={(event) => handleSelect(event, 'occupation')} isSearchable/>
                          </div>
                        </> : <></>
                      }
                    </MDBCol>
                    <MDBCol xs="12" sm="6" lg="4">
                      <strong>{window.I18N('state_city')}</strong>
                      <div className="input-group input-group-transparent mt-2 mb-3">
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            <MDBIcon fa="true" icon="filter" className="fa-fw" />
                          </span>
                        </div>
                        <Select value={select.state} options={options.state} className="md-form m-0" placeholder={window.I18N('choose_option')} onChange={(event) => handleSelect(event, 'state')} isSearchable />
                      </div>        
                      {
                        filter.accountType !== window.CONSTANT.COMPANY ? <></> : <>
                          <strong>{window.I18N('headcounts')}</strong>
                          <div className="input-group input-group-transparent mt-2 mb-3">
                            <div className="input-group-prepend">
                              <span className="input-group-text">
                                <MDBIcon fa="true" icon="filter" className="fa-fw" />
                              </span>
                            </div>
                            <Select value={select.companySize} options={options.companySize} className="md-form m-0" placeholder={window.I18N('choose_option')} onChange={(event) => handleSelect(event, 'companySize')} isSearchable />
                          </div>
                          <strong>{window.I18N('main_product')}</strong>
                          <div className="input-group input-group-transparent mt-2 mb-3">
                            <div className="input-group-prepend">
                              <span className="input-group-text">
                                <MDBIcon fa="true" icon="filter" className="fa-fw" />
                              </span>
                            </div>
                            <Select value={select.mainProduct} options={options.companyMainProduct} placeholder={window.I18N('main_product')} className="md-form m-0" onChange={(event) => handleSelect(event, 'mainProduct')} isSearchable/>
                          </div>
                        </>
                      }
                      {
                        filter.accountType === window.CONSTANT.EMPLOYEE ? <>
                          <strong>{window.I18N('education_background')}</strong>
                          <div className="input-group input-group-transparent mt-2 mb-3">
                            <div className="input-group-prepend">
                              <span className="input-group-text">
                                <MDBIcon fa="true" icon="filter" className="fa-fw" />
                              </span>
                            </div>
                            <Select value={select.educationBackground} options={options.educationBackground} placeholder={window.I18N('education_background')} className="md-form m-0" onChange={(event) => handleSelect(event, 'educationBackground')} isSearchable/>
                          </div>
                        </> : <>
                          <strong>{window.I18N('rating')}</strong>
                          <div className="w-100 mt-3">
                            <Rating 
                              emptySymbol={<MDBIcon fa="true" icon="star" className="fa-fw mr-1"></MDBIcon>}
                              fullSymbol={<MDBIcon fa="true" icon="star" className="fa-fw mr-1 text-warning"></MDBIcon>}
                              initialRating={filter.star}
                              onChange={handleFilterStar} />
                          </div>
                        </>
                      }
                    </MDBCol>
                    <MDBCol xs="12" sm="6" lg="4">
                      <strong>{window.I18N('city_district')}</strong>
                      <div className="input-group input-group-transparent mt-2 mb-3">
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            <MDBIcon fa="true" icon="filter" className="fa-fw" />
                          </span>
                        </div>
                        <Select value={select.city} options={options.city} className="md-form m-0" placeholder={window.I18N('choose_option')} onChange={(event) => handleSelect(event, 'city')} isSearchable />
                      </div>
                      {
                        filter.accountType !== window.CONSTANT.COMPANY ? <></> : <>
                          <strong>{window.I18N('industry')}</strong>
                          <div className="input-group input-group-transparent mt-2 mb-3">
                            <div className="input-group-prepend">
                              <span className="input-group-text">
                                <MDBIcon fa="true" icon="filter" className="fa-fw" />
                              </span>
                            </div>
                            <Select value={select.companyIndustry} options={options.companyIndustry} className="md-form m-0" placeholder={window.I18N('choose_option')} onChange={(event) => handleSelect(event, 'companyIndustry')} isSearchable />
                          </div>
                          <strong>{window.I18N('customers')}</strong>
                          <div className="input-group input-group-transparent mt-2 mb-3">
                            <div className="input-group-prepend">
                              <span className="input-group-text">
                                <MDBIcon fa="true" icon="filter" className="fa-fw" />
                              </span>
                            </div>
                            <Select value={select.mainCustomer} options={options.companyCustomer} placeholder={window.I18N('customers')} className="md-form m-0" onChange={(event) => handleSelect(event, 'mainCustomer')} isSearchable/>            
                          </div>
                        </>
                      }
                      <div className="text-right" style={{ marginTop: '36px' }}>
                        <MDBBtn color="" className="btn" onClick={resetData}>
                          {window.I18N('btn_clear')}
                        </MDBBtn>
                        <MDBBtn color="primary" className="mr-0" onClick={doSearchDataWithFilter}>
                          <MDBIcon fa="true" icon="sync-alt" className="fa-fw" /> {window.I18N('apply')}
                        </MDBBtn>
                      </div>
                    </MDBCol>
                  </MDBRow>
                </Filter>
              </> : <>
                <div className="mr-3" style={{ width: '20%' }}>
                  <div className="input-group input-group-transparent m-0">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <MDBIcon fa="true" icon="filter" className="fa-fw" />
                      </span>
                    </div>
                    <Select value={select.nation} options={options.nation} className="md-form m-0" placeholder={window.I18N('choose_option')} onChange={(event) => handleSelect(event, 'nation')} isSearchable />
                  </div>
                </div>
                <div className="mr-3" style={{ width: '20%' }}>
                  <div className="input-group input-group-transparent m-0">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <MDBIcon fa="true" icon="filter" className="fa-fw" />
                      </span>
                    </div>
                    <Select value={select.state} options={options.state} className="md-form m-0" placeholder={window.I18N('choose_option')} onChange={(event) => handleSelect(event, 'state')} isSearchable />
                  </div>
                </div>
                <MDBBtn color="primary" className="mr-0" onClick={getOrganizationChart}>
                  <MDBIcon fa="true" icon="search" className="fa-fw" /> {window.I18N('search')}
                </MDBBtn>
              </>
            }
          </div>
          {
            filter.typeUI === 0 ? <div className="row mt-4">
              <div className="col-12 col-lg-6">
                <div className="w-100 position-relative">
                  <div id="container-map" className="w-100" style={{ height: '400px' }}></div>
                  <div className="container-map-note p-2">
                    <div className="item mb-2">
                      <MDBIcon fa="true" icon="square" style={{ color: '#302E90' }} className="fa-fw mr-2"></MDBIcon>
                      <span>{ window.I18N('rating_very_hight') }</span>
                    </div>
                    <div className="item mb-2">
                      <MDBIcon fa="true" icon="square text-primary" className="fa-fw mr-2"></MDBIcon>
                      <span>{ window.I18N('rating_hight') }</span>
                    </div>
                    <div className="item mb-2">
                      <MDBIcon fa="true" icon="square text-success" className="fa-fw mr-2"></MDBIcon>
                      <span>{ window.I18N('rating_average') }</span>
                    </div>
                    <div className="item mb-2">
                      <MDBIcon fa="true" icon="square text-warning" className="fa-fw mr-2"></MDBIcon>
                      <span>{ window.I18N('rating_low') }</span>
                    </div>
                    <div className="item">
                      <MDBIcon fa="true" icon="square text-light" className="fa-fw mr-2"></MDBIcon>
                      <span>{ window.I18N('rating_very_low') }</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <div id="container-list" className="w-100">
                  <ul className="list-group">
                    <li className="list-group-item pt-3">
                      <Pagination total={pagination.total} pageSize={pagination.pageSize} currentPage={pagination.currentPage} changePage={changePage}></Pagination>
                    </li>
                    {
                      data.map((item, i) => 
                        <li className="list-group-item p-3" key={i}>
                          <MDBRow>
                            {
                              filter.accountType === window.CONSTANT.EMPLOYEE ? <>
                                <MDBCol>
                                  <p className="font-weight-bold mb-1">
                                    {window.COMMON.getValueFromAttr(item, 'name')}
                                  </p>
                                  <div className="d-flex align-items-center mb-1">
                                    <span>
                                      <small className="text-muted">{window.I18N('address')}</small>:&nbsp;
                                      <small className="font-weight-bold">{window.COMMON.getValueFromAttr(item, 'addressText')}</small>
                                    </span>
                                  </div>
                                  <div className="d-flex align-items-center mb-1">
                                    <span>
                                      <small className="text-muted">{window.I18N('tax_id')}</small>:&nbsp;
                                      <small className="font-weight-bold">{window.COMMON.getValueFromAttr(item, 'taxCode')}</small>
                                    </span>
                                  </div>
                                  <div className="d-flex align-items-center mb-1">
                                    <span>
                                      <small className="text-muted">{window.I18N('bio')}</small>:&nbsp;
                                      <small className="font-weight-bold">{window.COMMON.getValueFromAttr(item, 'bio')}</small>
                                    </span>
                                  </div>
                                </MDBCol>
                                <MDBCol className="col-4">
                                  <div className="d-flex align-items-center mb-1">
                                    <span>
                                      <small><MDBIcon className="fa-fw mr-1" fa="true" icon="transgender-alt"></MDBIcon>{window.COMMON.getValueFromAttr(item, 'gender') === 0 ? window.I18N('female') : window.I18N('male')}</small>
                                    </span>
                                  </div>
                                  <div className="d-flex align-items-center mb-1">
                                    <span>
                                      <small><MDBIcon className="fa-fw mr-1" fa="true" icon="birthday-cake"></MDBIcon>{window.COMMON.getValueFromAttr(item, 'birthday')}</small>
                                    </span>
                                  </div>
                                </MDBCol>
                              </> : <>
                                <MDBCol>
                                  <p className="font-weight-bold mb-1">
                                    {window.COMMON.getValueFromAttr(item, 'name')}
                                  </p>
                                  <div className="d-flex align-items-center mb-1">
                                    <span>
                                      <small className="text-muted">{window.I18N('address')}</small>:&nbsp;
                                      <small className="font-weight-bold">{window.COMMON.getValueFromAttr(item, 'addressText')}</small>
                                    </span>
                                  </div>
                                  <div className="d-flex align-items-center mb-1">
                                    <span>
                                      <small className="text-muted">{window.I18N('tax_id')}</small>:&nbsp;
                                      <small className="font-weight-bold">{window.COMMON.getValueFromAttr(item, 'taxCode')}</small>
                                    </span>
                                  </div>
                                  <div className="d-flex align-items-center mb-1">
                                    <span>
                                      <small className="text-muted">{window.I18N('website')}</small>:&nbsp;
                                      <small className="font-weight-bold">{window.COMMON.getValueFromAttr(item, 'website')}</small>
                                    </span>
                                  </div>
                                </MDBCol>
                                <MDBCol>
                                  <div className="d-flex align-items-center mb-1">
                                    <span>
                                      <small className="text-muted">{window.I18N('work_email_address')}</small>:&nbsp;
                                      <small className="font-weight-bold">{window.COMMON.getValueFromAttr(item, 'email')}</small>
                                    </span>
                                  </div>
                                  <div className="d-flex align-items-center mb-1">
                                    <span>
                                      <small className="text-muted">{window.I18N('industry')}</small>:&nbsp;
                                      <small className="font-weight-bold">{window.COMMON.getValueFromAttr(item, 'companyIndustry.value')}</small>
                                    </span>
                                  </div>
                                  {
                                    filter.accountType === window.CONSTANT.COMPANY ? <>
                                      <div className="mb-1">
                                        <small className="text-muted">{window.I18N('customers')}</small>:&nbsp;
                                        <ul className="m-0 pl-3" dangerouslySetInnerHTML={{__html: window.COMMON.getStringArrsWithLanguage(item, 'mainCustomers', 'value', language.current)}}>
                                        </ul>
                                      </div>
                                      <div className="mb-1">
                                        <small className="text-muted">{window.I18N('market')}</small>:&nbsp;
                                        <ul className="m-0 pl-3" dangerouslySetInnerHTML={{__html: window.COMMON.getStringArrsWithLanguage(item, 'markets', 'value', language.current)}}>
                                        </ul>
                                      </div>
                                    </> : <></>
                                  }
                                </MDBCol>
                                {
                                  filter.accountType === window.CONSTANT.COMPANY ? <MDBCol>
                                    <div className="d-flex align-items-center mb-1">
                                      <span>
                                        <small className="text-muted">{window.I18N('phone_number')}</small>:&nbsp;
                                        <small className="font-weight-bold">{window.COMMON.getValueFromAttr(item, 'phone')}</small>
                                      </span>
                                    </div>
                                    <div className="d-flex align-items-center mb-1">
                                      <span>
                                        <small className="text-muted">{window.I18N('headcount')}</small>:&nbsp;
                                        <small className="font-weight-bold">{window.COMMON.getValueFromAttr(item, 'companySize.value')}</small>
                                      </span>
                                    </div>
                                    <div className="mb-1">
                                      <small className="text-muted">{window.I18N('main_industry')}</small>:&nbsp;
                                      <ul className="m-0 pl-3" dangerouslySetInnerHTML={{__html: window.COMMON.getStringArrsWithLanguage(item, 'mainIndustries', 'value', language.current)}}>
                                      </ul>
                                    </div>
                                    <div className="mb-1">
                                      <small className="text-muted">{window.I18N('main_product')}</small>:&nbsp;
                                      <ul className="m-0 pl-3" dangerouslySetInnerHTML={{__html: window.COMMON.getStringArrsWithLanguage(item, 'mainProducts', 'value', language.current)}}>
                                      </ul>
                                    </div>
                                  </MDBCol>: <></>
                                }
                              </>
                            }
                            <MDBCol xs="2" sm="1" md="1" className="text-right">
                              <div className="dropdown">
                                <NavLink to="#" className="" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-boundary="viewport">
                                  <MDBIcon fa="true" icon="ellipsis-v" className="fa-fw black-text"></MDBIcon>
                                </NavLink>
                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton" data-boundary="viewport">
                                  {item.friendStatus === '' ? <li className="dropdown-item" onClick={() => doAddFriend(item)}>
                                    <MDBIcon fa="true" icon="user-plus" className="fa-fw mr-1"></MDBIcon> {
                                      window.I18N('add_connection')
                                    }
                                  </li> : <li className="dropdown-item disabled">
                                    <MDBIcon fa="true" icon="user-plus" className="fa-fw mr-2"></MDBIcon> 
                                    {
                                      item.friendStatus === window.CONSTANT.PENDING ? window.I18N('pending') : item.friendStatus === window.CONSTANT.ACCEPTED ? window.I18N('friends') : window.I18N('blocked')
                                    }
                                  </li> }
                                  <li className="dropdown-item" onClick= {() => doSendMessage(item)}>
                                    <MDBIcon fa="true" icon="paper-plane" className="fa-fw mr-1"></MDBIcon> {window.I18N('send_message')}
                                  </li>
                                </ul>
                              </div>
                            </MDBCol>
                          </MDBRow>
                          { checkNoFacilities(item) ? <></> : <div>
                              <MDBBtn color="" className="m-0 mt-2" onClick={() => showAttributeReportCompanyForTableNetwork(item)}>
                                <MDBIcon fa="true" icon="info-circle" className="fa-fw" /> {window.I18N('facility_info')}
                             </MDBBtn>
                          </div>}
                        </li>
                      )
                    }
                  </ul>
                </div>
              </div>
            </div> : <></>
          }
        </div>
      </div>
      {
        filter.typeUI === 1 ? <div className="w-100">
          <div id="accordion">
            {
              dataTotal.map((item, i) => <div key={i} className="card mb-3">
                <div className="card-header p-3" data-toggle="collapse" data-target={`#collapse-${i}`} aria-expanded="true" aria-controls={`collapse-${i}`} onClick={() => setIndexAccordion(i)}>
                  <p className="mb-0">{item.name} ({item.count})</p>
                </div>
                <div id={`collapse-${i}`} className="collapse" data-parent="#accordion">
                  <div className="card-body p-3">
                    <TableNetwork checkNoFacilities={checkNoFacilities} onShowFacilities={showAttributeReportCompanyForTableNetwork} companyIndustry={item._id} language={language.current} doAddFriend={doAddFriend} doSendMessage={doSendMessage}></TableNetwork>
                  </div>
                </div>
              </div>)
            }
          </div>
        </div> : <></>
      }
      <Modal id="modal-companying" className="modal-lg" title={window.COMMON.getValueFromAttr(object.item, 'name')} hideSave={true}>
        <AttributeCompany 
          data={dataAttributeReport} 
          company={object.company} 
          dataCompany={dataAttributeReportCompany.items || []} 
          handleSave={() => {}} 
          handleClose={closeAttributeCompany}
          companyId= {object.companyId}
        />
      </Modal>
    </div>
  );
}

const mapStateToProps = state => ({
  language: state.language.value
});
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(FindNetworkPage);