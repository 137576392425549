
import React, { useState, useLayoutEffect, useCallback } from 'react';
import { MDBCol, MDBRow, MDBCardImage, MDBBtn, MDBIcon } from 'mdbreact';
import Panel from '../../components/Panel';
import Modal from '../../components/Modal';
import ListCheck from '../../components/ListCheck';

import AccountGRAPHQL from '../../../graphql/Account';

export default function ReportedAccountPage(props) {

  const [object, setObject] = useState({ index: -1, object: {} });
  const [data, setData] = useState([]);
  const [values, setValues] = useState([]);

  useLayoutEffect(() => {
    setTimeout(() => {
      getDataReportedAccount();
    }, 100);
    // eslint-disable-next-line
  }, []);

  const getDataReportedAccount = async () => {
    try {
      const result = await window.COMMON.query(AccountGRAPHQL.QUERY_REPORTED_ACCOUNT, null);
      if (result && result.data) {
        const data = window.COMMON.formatDate(result.data.getReportedAccounts, 'createdDate');
        setValues([]);
        setData(data.filter(item => !window.COMMON.isEmpty(item.account)))
      }
    } catch (error) {
      window.COMMON.showErrorLogs('ReportedAccountPage.getDataReportedAccount');
    }
  }

  const handleChecklistRequest = useCallback((index, value) => {
    const id = window.COMMON.getValueIdFromAttr(data[index], 'account._id');
    if (value) {
      values.push(id);
    } else {
      for (let i = 0; i < values.length; i++) {
        if (id === values[i]) {
          values.splice(i, 1);
          break;
        }
      }
    }
    setValues([...values]);
  }, [values, data]);

  const handleItemClick = (index) => {
    window.COMMON.showModal('#modal-details');
    const object = data[index].account;
    setObject({ ...object });
  };

  const openAcceptReportDialog = () => {
    window.COMMON.showModal('#modal-save');
    window.saveMethod = acceptReport;
  };

  const openDeclineReportDialog = () => {
    window.COMMON.showModal('#modal-delete');
    window.deleteMethod = declineReport;
  };

  const acceptReport = async () => {
    try {
      const params = {
        accountIds : values
      };
      const result = await window.COMMON.mutation(AccountGRAPHQL.MUTATION_ACCEPT_REPORT_ACCOUNT, params);
      if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_UPDATE)) {
        getDataReportedAccount();
      }
    } catch (error) {
      window.COMMON.showErrorLogs('ReportedAccountPage.acceptReport');
    }
  };

  const declineReport = async () => {
    try {
      const params = {
        accountIds : values
      };
      const result = await window.COMMON.mutation(AccountGRAPHQL.MUTATION_DECLINE_REPORT_ACCOUNT, params);
      if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_DELETE)) {
        getDataReportedAccount();
      }
    } catch (error) {
      window.COMMON.showErrorLogs('ReportedAccountPage.declineReport');
    }
  };

  if (typeof window === 'undefined') {
    return null;
  }
  return (
    <div className="container-row">
      <Panel id="container-data" icon="list-alt">
        <div className="container-btn wow fadeInDown animated" data-wow-delay="0.5s">
          <MDBBtn color="primary" onClick={openAcceptReportDialog} disabled={values.length > 0 ? false : true}>
            <MDBIcon fa="true" icon="check-circle"/> {window.I18N('btn_accept')}
          </MDBBtn>
          <MDBBtn color="danger" onClick={openDeclineReportDialog} disabled={values.length > 0 ? false : true}>
            <MDBIcon fa="true" icon="ban"/> {window.I18N('btn_reject')}
          </MDBBtn>
        </div>
        
        <div className="container-table wow fadeIn animated" data-wow-delay="1s">
          <ListCheck data={data} values={values} attr_id="_id" attr_image="createdBy.avatar" attr_content="description" attr_sub="createdBy.nickname" attr_desc="createdDate" statusButton={true} handleCheckChange={handleChecklistRequest} handleItemClick={handleItemClick}></ListCheck>
        </div>
      </Panel>

      <Modal id="modal-details" className="modal-lg" title={window.I18N('view_details')} hideSave={true}>
        <MDBRow>
          <MDBCol md="3" sm="4" xs="12" className="text-center">
            <MDBCardImage cascade waves src={window.COMMON.setValueImage(window.COMMON.getValueFromAttr(object, 'avatar'))} className="image-logo"/>
          </MDBCol>
          <MDBCol md="9" sm="8" xs="12">
            <table className="table table-view mb-0">
              {
                !object ? <tbody></tbody> : <tbody>
                  <tr>
                    <td width="30%">{window.I18N('name')}</td>
                    <td width="70%">{object.name}</td>
                  </tr>
                  <tr>
                    <td>{window.I18N('nickname')}</td>
                    <td>{object.nickname}</td>
                  </tr>
                  <tr>
                    <td>{window.I18N('email')}</td>
                    <td>{object.email}</td>
                  </tr>
                  <tr>
                    <td>{window.I18N('phone_number')}</td>
                    <td>{object.phone}</td>
                  </tr>
                  <tr>
                    <td>{window.I18N('gender')}</td>
                    <td>{object.gender === 0 ? window.I18N('female') : window.I18N('male')}</td>
                  </tr>
                  <tr>
                    <td>{window.I18N('birthday')}</td>
                    <td>{window.COMMON_DATE.formatDate(object.birthday, 'DD-MM-YYYY')}</td>
                  </tr>
                  <tr>
                    <td>{window.I18N('address')}</td>
                    <td>{object.address}</td>
                  </tr>
                </tbody>
              }
            </table>
          </MDBCol>
        </MDBRow>
      </Modal>
    </div>
  );
}