import React, { useState, useLayoutEffect, useRef } from 'react';
import Select from 'react-select';
import {
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBBtn,
  MDBIcon,
  MDBRow,
  MDBCol,
  MDBCardImage
} from 'mdbreact';

import Table from '../components/Table';
import ListAccount from '../components/ListAccount';



import AccountGRAPHQL from '../../graphql/Account';
import RewardGRAPHQL from '../../graphql/Reward';
import QuestionGRAPHQL from '../../graphql/Question';
import PostGRAPHQL from '../../graphql/Post';
import AccountBalanceGRAPHQL from '../../graphql/AccountBalance';
import SurveyGRAPHQL from '../../graphql/Survey';
import SelfAssessmentGRAPHQL from '../../graphql/SelfAssessment';
import GrievanceGRAPHQL from '../../graphql/Grievance';
import HighLineChart from '../components/HighLineChart';




export default function DashboardPage(props) {
  const COMPANY_ID = localStorage.getItem(window.CONSTANT.COMPANY_ID);
  const COMPANY_TYPE = localStorage.getItem(window.CONSTANT.COMPANY_TYPE);
  // const configUser = [
  //   window.COMMON.createCol(
  //     window.I18N('company_user_id'),
  //     '20%',
  //     '',
  //     '',
  //     'userId',
  //     'TEXT'
  //   ),
  //   window.COMMON.createCol(
  //     window.I18N('nickname'),
  //     '30%',
  //     'p-0 pl-1',
  //     '',
  //     'user.avatar,user.nickname',
  //     'IMAGE_TEXT'
  //   ),
  //   window.COMMON.createCol(
  //     window.I18N('age'),
  //     '15%',
  //     '',
  //     '',
  //     'user.age',
  //     'TEXT'
  //   ),
  //   window.COMMON.createCol(
  //     window.I18N('gender'),
  //     '15%',
  //     '',
  //     '',
  //     'user.genderText',
  //     'TEXT'
  //   ),
  //   window.COMMON.createCol(
  //     window.I18N('start_date'),
  //     '20%',
  //     '',
  //     '',
  //     'updatedDate',
  //     'TEXT'
  //   )
  // ];
  const configUser = [
    window.COMMON.createCol(
      window.I18N('company_user_id'),
      '20%',
      '',
      '',
      'userId',
      'TEXT'
    ),
    window.COMMON.createCol(
      window.I18N('name'),
      '30%',
      'p-0 pl-1',
      '',
      'avatar,name',
      'IMAGE_TEXT'
    ),
    window.COMMON.createCol(
      window.I18N('age'),
      '15%',
      '',
      '',
      'age',
      'TEXT'
    ),
    window.COMMON.createCol(
      window.I18N('gender'),
      '15%',
      '',
      '',
      'genderText',
      'TEXT'
    ),
    window.COMMON.createCol(
      window.I18N('start_date'),
      '20%',
      '',
      '',
      'createdDate',
      'TEXT'
    )
  ];
  const [filter] = useState({
    startDate: window.COMMON_DATE.addDaysFromNow(-30),
    endDate: new Date()
  });
  const [object, setObject] = useState({ item: null, isLoadData: false });
  const [select, setSelect] = useState({ company: null });
  const [options, setOptions] = useState({ company: [] });
  const [count, setCount] = useState({
    numberEmployee: 0,
    numberReward: 0,
    numberActivities: 0,
    numberQA: 0,
    numberPosts: 0,
    numberSurveys: 0,
    numberEcourses: 0,
    numberGrievances: 0,
    numberAssessments: 0
  });
  const dataGroup = useRef();
  const dataReward = useRef();
  const [dataUser, setDataUser] = useState([]);
  // const [dataTopReward, setDataTopReward] = useState([]);
  // const [dataTopQA, setDataTopQA] = useState([]);
  const [dataChart, setDataChart] = useState({
    labels: [],
    data: []
  });
  const [grievanceDataChart, setGrievanceDataChart] = useState({
    labels: [],
    data: []
  });
  const [surveyDataChart, setSurveyDataChart] = useState({
    labels: [],
    data: []
  });

  const [courseDataChart, setCourseDataChart] = useState({
    labels: [],
    data: []
  });

  const [assessmentDataChart, setAssessmentDataChart] = useState({
    labels: [],
    data: []
  });

  const [activityChartDay, setActivityChartDay] = useState('7');
  const [grievanceChartDay, setGrievanceChartDay] = useState('7');
  const [surveyChartDay, setSurveyChartDay] = useState('7');
  const [courseChartDay, setCourseChartDay] = useState('7');
  const [assessmentChartDay, setAssessmentChartDay] = useState('7');

  useLayoutEffect(() => {
    setTimeout(() => {
      getDataReward();
      getDataGroup();
    }, 100);
    // eslint-disable-next-line
  }, []);

  const handleSelect = (event) => {
    select.company = event;
    setSelect({ ...select });
  };



  const getDataReward = async () => {
    try {
      const params = {
        status: true
      };
      const result = await window.COMMON.query(
        RewardGRAPHQL.QUERY_REWARD,
        params,
        false
      );
      dataReward.current = [];
      if (result && result.data) {
        dataReward.current = result.data.getRewards;
      }
    } catch (error) {
      window.COMMON.showErrorLogs('DashboardPage.getDataReward');
    }
  };

  const getDataGroup = async () => {
    try {
      const params = {
        company: null
      };
      const result = await window.COMMON.query(
        QuestionGRAPHQL.QUERY_ALL_GROUP_QUESTION,
        params,
        false
      );
      dataGroup.current = [];
      if (result && result.data) {
        dataGroup.current = result.data.getAllGroupQuestions;
      }
      object.isLoadData = true;
      setObject({ ...object });
      getDataCompany();
    } catch (error) {
      window.COMMON.showErrorLogs('DashboardPage.getDataGroup');
    }
  };

  const getDataCompany = async () => {
    try {
      if (!object.isLoadData) {
        return;
      }
      if (!window.COMMON.checkRoleIsSystemOrInfluencer()) {
        select.company = { value: COMPANY_ID, label: '', code: COMPANY_TYPE };
        setSelect({ ...select });
        reloadData();
        return;
      }
      await window.COMMON.getDataCompany();
      const dataCompany = JSON.parse(
        localStorage.getItem(window.CONSTANT.DATA_COMPANY)
      );
      options.company = window.COMMON.createDataSelectWithGroup(
        dataCompany,
        'id',
        'name',
        'accountType',
        'accountType'
      );
      select.company = window.COMMON.getValueFromAttr(
        options.company,
        '0.options.0',
        null
      );
      setOptions(options);
      setSelect({ ...select });
      reloadData();
    } catch (error) {
      window.COMMON.showErrorLogs('DashboardPage.getDataCompany');
    }
  };

  const reloadData = () => {
    if (window.COMMON_DATE.getDiffDate(filter.startDate, filter.endDate) > 31) {
      window.COMMON.showMessage(
        'warning',
        'MSG_CODE_043',
        window.MSG.MSG_CODE_043
      );
      return;
    }
    getDataCompanyInfo();
    getDataAccount();
    getActivity(activityChartDay);

    countPost();
    countSurvey();
    countAssesment();
    countGrievance();
    countEcourse();
    getGrievanceDataChart(grievanceChartDay);
    getSurveyDataChart(surveyChartDay);
    getCourseDataChart(courseChartDay);
    getAssessmentDataChart(assessmentChartDay);
  };

  const getDataCompanyInfo = async () => {
    try {
      if (select.company !== null) {
        const params = {
          company: select.company.value,
          companyType: select.company.code
        };
        const result = await window.COMMON.query(
          AccountGRAPHQL.QUERY_COMPANY_INFO,
          params
        );
        if (result && result.data) {
          object.item = result.data.getCompanyAccountInfo;
          object.item.createdDate = window.COMMON_DATE.formatDate(
            object.item.createdDate
          );
          const rating = {
            total: 0,
            average: 0,
            five: 0,
            four: 0,
            three: 0,
            two: 0,
            one: 0
          };
          if (object.item && object.item._id) {
            const ratings =
              select.company.code === window.CONSTANT.COMPANY
                ? window.COMMON.getValueFromAttr(
                  object.item,
                  'company.rating',
                  []
                )
                : window.COMMON.getValueFromAttr(
                  object.item,
                  'union.rating',
                  []
                );
            if (ratings.length > 0) {
              rating.total = ratings.length;
              let total = 0;
              ratings.forEach((child) => {
                total += Number(child.star);
                if (child.star === 5) {
                  rating.five += 1;
                } else if (child.star === 4) {
                  rating.four += 1;
                } else if (child.star === 3) {
                  rating.three += 1;
                } else if (child.star === 2) {
                  rating.two += 1;
                } else {
                  rating.one += 1;
                }
              });
              rating.average = Math.round((total / rating.total) * 10) / 10;
              rating.average_five =
                Math.round((rating.five / rating.total) * 100 * 100) / 100;
              rating.average_four =
                Math.round((rating.four / rating.total) * 100 * 100) / 100;
              rating.average_three =
                Math.round((rating.three / rating.total) * 100 * 100) / 100;
              rating.average_two =
                Math.round((rating.two / rating.total) * 100 * 100) / 100;
              rating.average_one =
                Math.round((rating.one / rating.total) * 100 * 100) / 100;
            }
          }
          // setRating(rating);
          setObject(object);
        }
      }
    } catch (error) {
      window.COMMON.showErrorLogs('DashboardPage.getDataCompanyInfo');
    }
  };



  const getDataAccount = async () => {
    try {
      window.resetDataTable('table-user');
      if (select.company !== null) {
        const { value: companyId, code: companytype } = select.company;

        const accountFilter = {

          company: companyId,
          companyType: companytype
        };
        const params = accountFilter;
        const result = await window.COMMON.query(AccountGRAPHQL.QUERY_ACCOUNT, params);

        let data = [];
        if (result && result.data && result.data.getAccounts) {

          data = window.COMMON.formatDate(
            result.data.getAccounts,
            'createdDate'
          );

          data.forEach((item) => {
            item.genderText =
              item.gender === 0
                ? window.I18N('female')
                : window.I18N('male');
            item.userId = item['_id'].substring(0, 16);
            item.age = window.COMMON.getAgeFromMillisecond(
              Number(item.birthday)
            );
          });

        }

        count.numberEmployee = data.length;
        setCount(count);
        setDataUser(data);

      }
    } catch (error) {
      window.COMMON.showErrorLogs('DashboardPage.getDataAccount');
    }
    window.initDataTableScroll('table-user', '240px');
  };

  const getActivity = async (pastDays = '7') => {
    try {
      if (select.company !== null) {
        const startDate = window.COMMON_DATE.getDateFollowPastDate({ pastDays, mode: -1 });
        const params = {
          input: {
            companyIds:
              select.company.code === window.CONSTANT.COMPANY
                ? window.COMMON.getDataSelect(select.company, true)
                : [],
            unionIds:
              select.company.code === window.CONSTANT.UNION
                ? window.COMMON.getDataSelect(select.company, true)
                : [],
            startDate,
            endDate: window.COMMON_DATE.getStrDateFilter(new Date(), 1)
          }
        };
        const result = await window.COMMON.query(
          AccountBalanceGRAPHQL.QUERY_ACTIVITY,
          params
        );

        if (result && result.data) {

          const data = window.COMMON.formatDate(
            result.data.getActivity,
            'createdDate',
            pastDays.includes('year') ? 'MM/YYYY' : 'DD/MM/YYYY'
          );

          const types = ['POST', 'LIKE', 'DISLIKE', 'COMMENT', 'SHARE'];


          const dataChart = window.COMMON.getDataChart({
            types,
            data,
            pastDays,
            itemKey: 'type',
            dateKey: 'createdDate'
          });
          // console.log(dataChart)
          setDataChart(dataChart);
        }
      }
    } catch (error) {
      window.COMMON.showErrorLogs('DashboardPage.getActivity');
    }
  };

  const getCompanyParams = (pastDays = null) => {
    let startDate = window.COMMON_DATE.getStrDateFilter(filter.startDate, -1);
    if (pastDays) {
      startDate = window.COMMON_DATE.getDateFollowPastDate({ pastDays, mode: -1 });
    }

    const params = {
      input: {
        companyIds:
          select.company.code === window.CONSTANT.COMPANY
            ? window.COMMON.getDataSelect(select.company, true)
            : [],
        unionIds:
          select.company.code === window.CONSTANT.UNION
            ? window.COMMON.getDataSelect(select.company, true)
            : [],
        startDate,
        endDate: window.COMMON_DATE.getStrDateFilter(filter.endDate, 1)
      }
    };
    return params;
  };



  const countPost = async () => {
    try {
      if (select.company !== null) {
        const params = getCompanyParams();
        const result = await window.COMMON.query(
          AccountBalanceGRAPHQL.QUERY_ACTIVITY,
          params
        );
        count.numberPosts = 0;
        if (result && result.data) {
          const activityData = result.data.getActivity || [];
          count.numberPosts = activityData.filter((item)=>item.type==='POST').length
        }
        setCount((current) => {
          return {
            ...current,
            ...count
          };
        });
      }
    } catch (error) {
      window.COMMON.showErrorLogs('DashboardPage.countPost');
    }
  };

  const countGrievance = async () => {
    try {
      if (select.company !== null) {
        const params = getCompanyParams();
        const result = await window.COMMON.query(
          GrievanceGRAPHQL.QUERY_COUNT_GRIEVANCE,
          params
        );
        count.numberGrievances = 0;
        if (result && result.data) {
          count.numberGrievances = result.data.countGrievance;
        }
        setCount((current) => {
          return {
            ...current,
            ...count
          };
        });
      }
    } catch (error) {
      window.COMMON.showErrorLogs('DashboardPage.countGrievance');
    }
  };

  const countSurvey = async () => {
    try {
      if (select.company !== null) {
        const params = getCompanyParams();
        const result = await window.COMMON.query(
          SurveyGRAPHQL.QUERY_COUNT_MENU_SUREY,
          params
        );
        count.numberSurveys = 0;
        if (result && result.data) {
          count.numberSurveys = result.data.countSurvey;
        }

        setCount((current) => {
          return {
            ...current,
            ...count
          };
        });
      }
    } catch (error) {
      window.COMMON.showErrorLogs('DashboardPage.countSurvey');
    }
  };

  const countAssesment = async () => {
    try {
      if (select.company !== null) {
        const params = getCompanyParams();

        const result = await window.COMMON.query(
          SelfAssessmentGRAPHQL.QUERY_COUNT_ASSESSMENT,
          params
        );

        if (result && result.data) {
          count.numberAssessments = result.data.countAssessment;
        }

        setCount((current) => {
          return {
            ...current,
            ...count
          };
        });
      }
    } catch (error) {
      window.COMMON.showErrorLogs('DashboardPage.countAssesment');
    }
  };

  const countEcourse = async () => {
    try {
      if (select.company !== null) {
        const { input: { startDate, endDate, ...restParams } } = getCompanyParams();
        const result = await window.COMMON.httpRequest({
          url: process.env.REACT_APP_ELEARNING_API + '/courses/count',
          method: 'POST',
          input: {
            params: {
              startDate,
              endDate
            },
            data: {...restParams}
          },
          requireToken: true,
          handleError: () => { window.COMMON.showErrorLogs('DashboardPage.countEcourse'); }
        });
        count.numberEcourses = 0;
        if (result && result.count) {
          count.numberEcourses = result.count;
        }
        setCount((current) => {
          return {
            ...current,
            ...count
          };
        });
      }
    } catch (error) {
      window.COMMON.showErrorLogs('DashboardPage.countEcourse');
    }
  };

  const getGrievanceDataChart = async (pastDays = '7') => {
    try {
      if (select.company !== null) {
        const params = getCompanyParams(pastDays);
        const result = await window.COMMON.query(
          GrievanceGRAPHQL.QUERY_GRIEVANCE_DATA_CHART,
          params
        );
        let grievanceData = [];
        if (result && result.data) {
          grievanceData = result.data.grievanceDataChart;
        }
        const data = window.COMMON.formatDate(
          grievanceData,
          'createdDate',
          pastDays.includes('year') ? 'MM/YYYY' : 'DD/MM/YYYY'
        );
        const types = ['GRIEVANCE', 'INVESTIGATING', 'FINDING', 'APPEALED', 'CLOSED'];
        const dataChart = window.COMMON.getDataChart({
          types,
          data,
          pastDays,
          itemKey: 'status',
          dateKey: 'createdDate'
        });
        setGrievanceDataChart(dataChart);


      }
    }
    catch (error) {
      window.COMMON.showErrorLogs('DashboardPage.getGrievanceDataChart');
    }
  };

  const getSurveyDataChart = async (pastDays = '7') => {
    try {
      if (select.company !== null) {
        const params = getCompanyParams(pastDays);
        const result = await window.COMMON.query(
          SurveyGRAPHQL.QUERY_SURVEY_DATA_CHART,
          params
        );
        let surveyData = [];
        if (result && result.data) {
          surveyData = result.data.surveyDataChart;
        }
        const data = window.COMMON.formatDate(
          surveyData,
          'createdDate',
          pastDays.includes('year') ? 'MM/YYYY' : 'DD/MM/YYYY'
        );

        const types = ['SURVEY', 'QUESTION', 'ANSWER', 'PARTICIPANT'];
        const dataChart = window.COMMON.getDataChart({
          types,
          data,
          pastDays,
          itemKey: 'type',
          dateKey: 'createdDate'
        });

        setSurveyDataChart(dataChart);


      }
    }
    catch (error) {
      window.COMMON.showErrorLogs('DashboardPage.getSurveyDataChart');
    }
  };

  const getCourseDataChart = async (pastDays = '7') => {
    try {
      if (select.company !== null) {
        const { input: { startDate, endDate, ...restParams } } = getCompanyParams(pastDays);
        const result = await window.COMMON.httpRequest({
          url: process.env.REACT_APP_ELEARNING_API + '/courses/chart',
          method: 'POST',
          input: {
            params: {
              startDate: startDate,
              endDate: endDate
            },
            data: {...restParams}
          },
          requireToken: true,
          handleError: () => { window.COMMON.showErrorLogs('DashboardPage.getCourseDataChart'); }
        });
        let courseData = [];
        if (result && result.courseDataChart) {
          courseData = result.courseDataChart;
        }

        const data = window.COMMON.formatDate(
          courseData,
          'createdAt',
          pastDays.includes('year') ? 'MM/YYYY' : 'DD/MM/YYYY'
        );

        const types = ['COURSE', 'ENROLL_COURSE'];
        const dataChart = window.COMMON.getDataChart({
          types,
          data,
          pastDays,
          itemKey: 'type',
          dateKey: 'createdAt'
        });
        setCourseDataChart(dataChart);
      }
    }
    catch (error) {
      window.COMMON.showErrorLogs('DashboardPage.getCourseDataChart');
    }
  };

  const getAssessmentDataChart = async (pastDays = '7') => {
    try {
      if (select.company !== null) {
        const params = getCompanyParams(pastDays);
        const result = await window.COMMON.query(
          SelfAssessmentGRAPHQL.QUERY_ASSESSMENT_DATA_CHART,
          params
        );
        let assessmentData = [];
        if (result && result.data) {
          assessmentData = result.data.assessmentDataChart;
        }
        const data = window.COMMON.formatDate(
          assessmentData,
          'createdDate',
          pastDays.includes('year') ? 'MM/YYYY' : 'DD/MM/YYYY'
        );

        const types = ['ASSESSMENT', 'COMPANY', 'ASSIGNED', 'REVIEWED', 'COMPLETED'];
        const dataChart = window.COMMON.getDataChart({
          types,
          data,
          pastDays,
          itemKey: 'status',
          dateKey: 'createdDate'
        });

        setAssessmentDataChart(dataChart);




      }
    }
    catch (error) {
      window.COMMON.showErrorLogs('DashboardPage.getAssessmentDataChart');
    }
  };

  if (typeof window === 'undefined') {
    return null;
  }
  return (
    <div className="container-row mb-0 mt-3">
      <div
        className="card mb-4 p-3 wow fadeInDown animated"
        data-wow-delay="0.5s"
        style={{ zIndex: 1 }}
      >
        <div className="d-flex align-items-center justify-content-end">
          {!window.COMMON.checkRoleIsSystemOrInfluencer() ? (
            <div></div>
          ) : (
            <div className="bd-highlight mr-3" style={{ width: '300px' }}>
              <div className="input-group input-group-transparent">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <MDBIcon fa="true" icon="building" className="fa-fw" />
                  </span>
                </div>
                <Select
                  className="md-form m-0"
                  placeholder={window.I18N('choose_option')}
                  value={select.company}
                  options={options.company}
                  onChange={handleSelect}
                  isSearchable
                />{' '}
              </div>
            </div>
          )}
          <div className="bd-highlight">
            <MDBBtn className="m-0" color="primary" onClick={reloadData}>
              <MDBIcon fa="true" icon="search" className="fa-fw" />{' '}
              {window.I18N('search')}
            </MDBBtn>
          </div>
        </div>
      </div>

      <MDBCard
        className="cascading-admin-card mb-4 wow fadeIn animated"
        data-wow-delay="1s"

      >
        <MDBRow>
          <MDBCol md="2">
            <div className="d-flex justify-content-center pt-3 w-100">
              <MDBCardImage
                cascade
                waves
                src={window.CONSTANT.ICON_EMPLOYEE}
                className="icon mr-1"
              />
              <div className="data ml-2 my-auto">
                <h5 className="font-weight-bold my-auto">
                  {count.numberEmployee}
                </h5>
              </div>
            </div>
            <div className="text-center w-100 pb-3">
              <p className="text-muted  mb-0">{window.I18N('employees')}</p>
            </div>
          </MDBCol>
          <MDBCol md="2">
            <div className="d-flex justify-content-center pt-3 w-100">
              <MDBCardImage
                cascade
                waves
                src={window.CONSTANT.ICON_POST}
                className="icon mr-1"
              />
              <div className="data ml-2 my-auto">
                <h5 className="font-weight-bold my-auto">{count.numberPosts}</h5>
              </div>
            </div>
            <div className="text-center w-100 pb-3">
                <p className="text-muted mb-0">{window.I18N('posts')}</p>
            </div>
          </MDBCol>

          <MDBCol md="2">
            <div className="d-flex justify-content-center pt-3 w-100">
              <MDBCardImage
                cascade
                waves
                src={window.CONSTANT.ICON_SURVEY}
                className="icon mr-1"
              />
              <div className="data ml-2 my-auto">
                <h5 className="font-weight-bold my-auto">{count.numberSurveys}</h5>
                
              </div>
            </div>
            <div className="text-center w-100 pb-3">
                <p className="text-muted mb-0">{window.I18N('surveys')}</p>
            </div>
          </MDBCol>
          <MDBCol md="2">
            <div className="d-flex justify-content-center pt-3 w-100">
              <MDBCardImage
                cascade
                waves
                src={window.CONSTANT.ICON_ECOURSE}
                className="icon mr-1"
              />
              <div className="data ml-2 my-auto">
                <h5 className="font-weight-bold my-auto">
                  {count.numberEcourses}
                </h5>
              </div>
            </div>
            <div className="text-center w-100 pb-3">
              <p className="text-muted mb-0">{window.I18N('e_courses')}</p>
            </div>
          </MDBCol>
          <MDBCol md="2">
            <div className="d-flex justify-content-center pt-3 w-100">
              <MDBCardImage
                cascade
                waves
                src={window.CONSTANT.ICON_GRIEVANCE}
                className="icon mr-1"
              />
              <div className="data ml-2 my-auto">
                <h5 className="font-weight-bold my-auto">
                  {count.numberGrievances}
                </h5>
              </div>
            </div>
            <div className="text-center w-100 pb-3">
              <p className="text-muted mb-0">{window.I18N('grievances')}</p>
            </div>
          </MDBCol>
          <MDBCol md="2">
            <div className="d-flex justify-content-center pt-3 w-100">
              <MDBCardImage
                cascade
                waves
                src={window.CONSTANT.ICON_ASSESSMENT}
                className="icon mr-1"
              />
              <div className="data ml-2 my-auto">
                <h5 className="font-weight-bold my-auto">
                  {count.numberAssessments}
                </h5>
              </div>
            </div>
            <div className="text-center w-100 pb-3">
              <p className="text-muted mb-0">{window.I18N('assessments')}</p>
            </div>
          </MDBCol>
        </MDBRow>
      </MDBCard>

      {/* */}
      <MDBRow>
        <MDBCol xl="12" className="mb-4">
          <ListAccount item={object.item} isView={true}></ListAccount>
        </MDBCol>

      </MDBRow>

      <MDBRow>
        <MDBCol
          xl="12"
          className="mb-4 wow fadeInRight animated"
          data-wow-delay="0.5s"
        >
          <MDBCard style={{ height: '450px' }}>
            <MDBCardHeader>{window.I18N('list_users')}</MDBCardHeader>
            <MDBCardBody>
              <Table
                id="table-user"
                className="table table-striped"
                config={configUser}
                data={dataUser}
              ></Table>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>

      <MDBRow>
        <MDBCol
          xl="12"
          className="mb-4 wow fadeInRight animated"
          data-wow-delay="0.5s"
        >
          <HighLineChart

            title={window.I18N('engagement_activities')}
            dataLabels={dataChart}
            seriesName={[
              window.I18N('post'),
              window.I18N('like'),
              window.I18N('dislike'),
              window.I18N('comment'),
              window.I18N('share')
            ]}
            showDateFilter={true}
            onDateFilterChange={(value) => {
              getActivity(value);
              setActivityChartDay(value);
            }}
            showInterFilter={true}
          />
        </MDBCol>
      </MDBRow>

      <MDBRow>
        <MDBCol
          xl="12"
          className="mb-4 wow fadeInRight animated"
          data-wow-delay="0.5s"
        >
          <HighLineChart

            title={window.I18N('survey_activities')}
            dataLabels={surveyDataChart}
            seriesName={[
              window.I18N('survey'),
              window.I18N('question'),
              window.I18N('answer'),
              window.I18N('participant')
            ]}
            showDateFilter={true}
            onDateFilterChange={(value) => {
              getSurveyDataChart(value);
              setSurveyChartDay(value);
            }}
            showInterFilter={true}
          />
        </MDBCol>
      </MDBRow>

      <MDBRow>
        <MDBCol
          xl="12"
          className="mb-4 wow fadeInRight animated"
          data-wow-delay="0.5s"
        >
          <HighLineChart

            title={window.I18N('course_activities')}
            dataLabels={courseDataChart}
            seriesName={[
              window.I18N('e_courses'),
              window.I18N('enroll')
            ]}
            showDateFilter={true}
            onDateFilterChange={(value) => {
              getCourseDataChart(value);
              setCourseChartDay(value);
            }}
            showInterFilter={true}
          />
        </MDBCol>
      </MDBRow>

      <MDBRow>
        <MDBCol
          xl="12"
          className="mb-4 wow fadeInRight animated"
          data-wow-delay="0.5s"
        >
          <HighLineChart

            title={window.I18N('grievance_activities')}
            dataLabels={grievanceDataChart}
            seriesName={[
              window.I18N('grievance'),
              window.I18N('investigating'),
              window.I18N('finding'),
              window.I18N('appealed'),
              window.I18N('closed')
            ]}
            showDateFilter={true}
            onDateFilterChange={(value) => {
              getGrievanceDataChart(value);
              setGrievanceChartDay(value);
            }}
            showInterFilter={true}
          />
        </MDBCol>
      </MDBRow>

      <MDBRow>
        <MDBCol
          xl="12"
          className="mb-4 wow fadeInRight animated"
          data-wow-delay="0.5s"
        >
          <HighLineChart

            title={window.I18N('assessment_activities')}
            dataLabels={assessmentDataChart}
            seriesName={[
              window.I18N('assessment'),
              window.I18N('company'),
              window.I18N('assigned'),
              window.I18N('reviewed'),
              window.I18N('completed')
            ]}
            showDateFilter={true}
            onDateFilterChange={(value) => {
              getAssessmentDataChart(value);
              setAssessmentChartDay(value);
            }}
            showInterFilter={true}
          />
        </MDBCol>
      </MDBRow>


    </div>
  );
}
